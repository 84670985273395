import { createSlice, type PayloadAction } from '@reduxjs/toolkit'
import type { DictionaryEntityType } from 'api/dictionary'
import { commonActions } from 'reducers/common'
import type { PayloadStatus } from 'types/redux'

const initialState = {
	qualifiedSignatureProviders: [] as DictionaryEntityType,
	applicationTypes: [] as DictionaryEntityType,
	status: 'pending' as PayloadStatus,
}

const signatures = createSlice({
	name: 'dictionaries/signatures',
	initialState,
	reducers: {
		set: (
			state,
			{
				payload,
			}: PayloadAction<{
				key: keyof Omit<typeof initialState, 'status'>
				value: DictionaryEntityType
			}>
		) => {
			state[payload.key] = payload.value
		},

		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions: dictionarySignatureReducerActions } = signatures
export default signatures.reducer
