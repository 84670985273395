import { DefaultValues } from 'helpers/convertHelper'

export const application = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		number: model.string(['number']),
		createdDate: model.string(['createdDate']),
	}
}
