import moment from 'moment'

import { utils } from 'helpers'

export default (loan, yearlyInterestRate = false) => {
	if (!loan.oneCFullInfo && !loan.calculation) return {}

	const ruStr = (value) => (value && Number(value) ? utils.RUB(value, true) : '')

	const contract = loan.oneCFullInfo || {}
	const calculation = loan.calculation || {}
	const overdue = utils.take(contract, 'summary') || {}

	const sum = utils.take(contract, 'loan.requisites.loanAmount') || calculation.loan || 0
	const percent =
		utils.take(
			contract,
			!yearlyInterestRate ? 'currentTerms.monthlyInterestRate' : 'currentTerms.yearlyInterestRate'
		) ||
		calculation.percent ||
		0
	const period = utils.take(contract, 'loan.requisites.loanPeriod') || calculation.period || 0
	const payment =
		utils.take(contract, 'schedule.upcomingPayment.amount') || calculation.payment || 0
	let type =
		utils.take(contract, 'loan.requisites.financialProduct.title') || calculation.type || ''

	if (type === 'ANNUITY' || type === 'PERCENT') {
		type = type === 'ANNUITY' ? 'Аннуитетный платеж' : 'Процентный платеж'
	}

	if (type === 'MIXED') {
		type = 'Смешанный платеж'
	}

	const upcomingPaymentDate = utils.take(contract, 'schedule.upcomingPayment.date')
	const totalPayment = ruStr(utils.take(contract, 'totalPayment')) || ''
	const totalPaymentInfo = totalPayment
		? `${totalPayment}${
				upcomingPaymentDate && upcomingPaymentDate !== 'Invalid date'
					? `, ${moment.utc(upcomingPaymentDate, 'YYYY-MM-DD').local().format('D MMM')}`
					: ''
		  }`
		: ''

	return {
		'фин. продукт': type,
		'к оплате': totalPaymentInfo, // totalPayment + upcomingPayment.date
		срок: Number(period) ? `${period} мес.` : '',
		'срок договора': Number(calculation.contractDuration)
			? `${calculation.contractDuration} мес.`
			: '',
		взносы: Number(calculation.membershipFee) ? `${calculation.membershipFee}%` : '',
		ставка: Number(percent) ? `${percent}%` : '',
		'дней просрочки': overdue.overdueDays || '',
		сумма: ruStr(sum),
		платеж: ruStr(payment), // upcomingPayment.amount || calculation.cashbackPayment
		просрочка: ruStr(utils.take(overdue, 'overdueBalance.amount')),
		'просроченная задолженность': ruStr(utils.take(overdue, 'overdueBalance.amount')),
		пени: ruStr(utils.take(contract, 'penalties.penalties')),
		'основной долг': ruStr(utils.take(overdue, 'debtBalance.mainDebt')),
		'остаток на счёте': ruStr(utils.take(contract, 'currentTerms.loanContractBalance')),
		'остаток основного долга': ruStr(utils.take(overdue, 'debtBalance.mainDebt')),
		'по графику': ruStr(utils.take(contract, 'schedule.upcomingPayment.amount')),
		'проценты за использование': ruStr(utils.take(contract, 'schedule.upcomingPayment.percent')),
	}
}
