import { Component } from 'react'
import { connect } from 'react-redux'
import { resetConstructor } from 'actions/document'
import PropTypes from 'prop-types'

import { Loading } from 'components/common'

import Form from './Form'

import './DocumentConstructor.scss'

class DocumentConstructor extends Component {
	static propTypes = {
		data: PropTypes.object.isRequired,
		entityId: PropTypes.string.isRequired,
		constructorSpec: PropTypes.object.isRequired,
		categoryId: PropTypes.string.isRequired,
	}

	componentWillUnmount() {
		const { resetConstructor } = this.props

		resetConstructor()
	}

	renderHint = () => {
		const { documentIdForEdit } = this.props

		return (
			<div className='document-constructor__hint'>
				<span>
					{!documentIdForEdit ? 'Введите необходимые данные' : 'Проверьте правильность данных'}.
				</span>
			</div>
		)
	}

	render() {
		const {
			data,
			formats,
			owners,
			persons,
			entityId,
			entityType,
			warrantors,
			constructorSpec,
			documentIdForEdit,
			categoryId,
		} = this.props

		const formProps = {
			formats,
			owners,
			persons,
			entityId,
			entityType,
			warrantors,
			constructorSpec,
			documentIdForEdit,
			categoryId,
		}

		return (
			<div className='document-constructor'>
				{!data.fetching && this.renderHint()}
				{data.fetching ? <Loading max /> : <Form {...formProps} />}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	data: state.document.documentConstructor,
})

const mapDispatchToProps = {
	resetConstructor,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentConstructor)
