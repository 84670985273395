import cx from 'clsx'
import PropTypes from 'prop-types'

import styles from './Mention.module.scss'

const Mention = ({ worker, user }) => {
	return (
		<span className={cx(styles.mention, { [styles.exact]: worker.id === user.id })}>
			{worker.name} {worker.surname}
		</span>
	)
}

Mention.propTypes = {
	user: PropTypes.object.isRequired,
	worker: PropTypes.object.isRequired,
}

export default Mention
