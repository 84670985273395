import { type Address } from '@creditclubteam/kit/legacy-types'
import { DefaultValues } from 'helpers/convertHelper'

export const person = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		name: model.string(['name']),
		surname: model.string(['surname']),
		patronymic: model.string(['patronymic']),
		phone: model.string(['phone']),
		email: model.string(['email']),
		passport: {
			series: model.string(['passport', 'series']),
			number: model.string(['passport', 'number']),
			issueDate: model.string(['passport', 'issueDate']),
			birthDate: model.string(['passport', 'birthDate']),
			department: model.string(['passport', 'department']),
			registration: model.null<Address>(['passport', 'registration']),
		},
	}
}
