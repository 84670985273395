import api from 'api'
import { actions } from 'reducers/insurance'
import type { TAction } from 'types/redux'

import { utils } from 'helpers'

import { addServerError } from './serverErrors'

export const getInsurance = (): TAction => async (dispatch) => {
	try {
		const insuranceProduct = await api.insurance.searchProducts()
		const insuranceOrganizations = await api.insurance.searchOrganizations()
		dispatch(
			actions.update({
				product: insuranceProduct.content,
				organization: insuranceOrganizations.content,
			})
		)
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось загрузить страховые продукты или компании',
				details: utils.getDetailsFromError(error),
			})
		)
	}
}
