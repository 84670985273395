import { DefaultValues } from 'helpers/convertHelper'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		savingId: model.string(['savingId']),
		number: model.string(['number']),
		ndfl: model.null<number>(['ndfl']),
		interest: model.null<number>(['interest']),
		depositor: {
			id: model.string(['depositor', 'id']),
			shortName: model.string(['depositor', 'shortName']),
		},
	}
}
