import { DefaultValues } from 'helpers/convertHelper'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		name: model.string(['name']),
		issuer: model.string(['issuer']),
		enabled: model.null<boolean>(['enabled']),
	}
}
