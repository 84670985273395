import {
	deleteAllEventInstances,
	deleteEventInList,
	formatRangeDates,
	getEvents,
	updateEventInList,
	updateEventsList,
} from 'actions/calendar'
import { setModalShow } from 'actions/modalDialogs'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { clone, dissoc } from 'ramda'
import { actions as eventFormActions } from 'reducers/calendar/eventForm'
import { actions as eventActions } from 'reducers/calendar/events'
import url from 'routes/urls'
import type { TAction } from 'types/redux'

import { utils } from 'helpers'

import {
	_calendarFormToEvent,
	mutateEventsForCalendar,
	mutateExtensionForMsal,
	mutateFormForMsal,
} from './helpers'

export const calendarEventFormReset = (): TAction => (dispatch) => {
	dispatch(eventFormActions.reset())
	dispatch(setModalShow(false, 'calendarEventForm'))
}

export const calendarEventForm =
	(incForm = {} as Record<string, any>, isDefaultTime = true): TAction =>
	async (dispatch) => {
		const event = _calendarFormToEvent(incForm)

		if (utils.hasObjectLength(incForm)) {
			// Если входящее событие является частью серии получаем данные по ней и клеим
			if (incForm.seriesMasterId) {
				// Включаем загрузку и сразу показываем модалку формы
				dispatch(eventFormActions.setFetchingRecurrenceStatus(true))
				dispatch(setModalShow(true, 'calendarEventForm'))

				try {
					const { data } = await api.calendar.getRecurrence(incForm.seriesMasterId)

					event.recurrence = data.recurrence
				} catch (error) {
					dispatch(
						addServerError({
							details: utils.getDetailsFromErrorMsal(error),
							text: 'Ошибка получения данных о событии',
						})
					)
				}
			}

			if (isDefaultTime && event.start.getHours() === 0 && event.end.getHours() === 0) {
				event.start.setHours(9)
				if (event.end) {
					event.end.setHours(9)
					event.end.setMinutes(30)
				}
			}

			dispatch(eventFormActions.setForm(event))

			if (incForm.seriesMasterId) {
				dispatch(eventFormActions.setFetchingRecurrenceStatus(false))
			}
		}

		dispatch(setModalShow(true, 'calendarEventForm'))
	}

const createExtension =
	(id: string, customParams: Record<string, any>): TAction<Promise<any>> =>
	() => {
		let attempts = 0
		const attemptsLimit = 3
		const create = () => api.calendar.createExtension(id, mutateExtensionForMsal(customParams))

		// eslint-disable-next-line no-async-promise-executor
		return new Promise(async (resolve, reject) => {
			const attempt = () => {
				setTimeout(async () => {
					await create()
						.then(resolve)
						.catch((err) => {
							++attempts

							if (attempts >= attemptsLimit) {
								reject(err)
							} else {
								attempt()
							}
						})
				}, 2500)
			}

			await create().then(resolve).catch(attempt)
		})
	}

/**
 * @param {CalendarEvent} form
 * @returns {Promise<any>}
 */
export const createCalendarEvent =
	(form: Record<string, any>): TAction =>
	async (dispatch, getState) => {
		const isCalendarPage = location.pathname.includes(url.calendar.path)

		dispatch(eventFormActions.setFetchingStatus(true))

		await api.calendar
			.createEvent(mutateFormForMsal(form))
			.then(async ({ data }) => {
				const eventId = data.id
				const extension = await dispatch(createExtension(eventId, form.$$customParams))

				if (isCalendarPage) {
					let events: Record<string, any>[] = []

					if (data.type === 'seriesMaster') {
						const seriesMasterId = data.id
						const { currentDateRange } = getState().calendar.events
						const { start, end } = formatRangeDates(currentDateRange)

						try {
							const instances = await api.calendar.getRepsInstances(seriesMasterId, start, end)
							if (utils.hasObjectLength(instances.data.value)) {
								events = mutateEventsForCalendar(...instances.data.value)
							}
						} catch (error) {
							console.error(error)
						}
					} else {
						events = mutateEventsForCalendar({
							...data,
							extensions: [extension.data],
						})
					}

					dispatch(updateEventsList(events))
				}

				dispatch(setModalShow(false, 'calendarEventForm'))
			})
			.catch((error) => {
				dispatch(
					addServerError({
						details: utils.getDetailsFromErrorMsal(error),
						text: 'Ошибка создания события',
					})
				)
			})
			.finally(() => dispatch(eventFormActions.setFetchingStatus(false)))
	}

export const deleteEvent =
	(event: Record<string, any>, applyToAllInstances?: boolean): TAction<Promise<any>> =>
	async (dispatch) => {
		const isSeveralInstances = event.seriesMasterId && applyToAllInstances

		if (isSeveralInstances) {
			dispatch(deleteAllEventInstances(event.seriesMasterId))
		} else {
			dispatch(deleteEventInList(event.id))
		}

		// Если меняется только одно событие id равно id события если нет то id равно id серии
		const id = isSeveralInstances ? event.seriesMasterId : event.id

		return api.calendar.deleteEvent(id).catch((error) => {
			dispatch(
				addServerError({
					details: utils.getDetailsFromErrorMsal(error),
					text: 'Ошибка удаления события, изменения не будут сохранены',
				})
			)
		})
	}

export const updateEventDate =
	(target: Record<string, any>): TAction =>
	(dispatch) => {
		const newEvent = clone(target.event)
		newEvent.start = target.start
		newEvent.end = target.end
		newEvent.extensions = newEvent.extensions?.map((el: Record<string, any>) =>
			dissoc('@odata.context', el)
		)

		dispatch(updateEventInList(newEvent))

		api.calendar.updateEvent(newEvent.id, mutateFormForMsal(newEvent)).catch((error) => {
			dispatch(
				addServerError({
					text: 'Ошибка переноса события',
					details: utils.getDetailsFromErrorMsal(error),
				})
			)

			dispatch(updateEventInList(target.event))
		})
	}

export const updateCalendarEvent =
	(event: Record<string, any>): TAction =>
	(dispatch, getState) => {
		dispatch(eventFormActions.setFetchingStatus(true))

		const isSeveralInstances = event.seriesMasterId && event.applyToAllInstances

		// Если меняется только одно событие id равно id события если нет то id равно id серии
		const id = isSeveralInstances ? event.seriesMasterId : event.id

		api.calendar
			.updateEvent(id, mutateFormForMsal(event))
			.then(async ({ data }) => {
				const updateExtension = await api.calendar.updateExtension(
					id,
					mutateExtensionForMsal(event.$$customParams)
				)

				if (updateExtension.status !== 200) {
					throw updateExtension.data
				} else {
					if (data.type === 'seriesMaster') {
						const { currentDateRange } = getState().calendar.events

						// В данном случае стор не обновляем а просто перезапрашиваем данные
						dispatch(getEvents(currentDateRange))
					} else {
						dispatch(
							updateEventInList({
								...mutateEventsForCalendar(data)[0],
								$$customParams: event.$$customParams,
							})
						)
					}

					dispatch(setModalShow(false, 'calendarEventForm'))
				}
			})
			.catch((error) => {
				dispatch(
					addServerError({
						details: utils.getDetailsFromErrorMsal(error),
						text: 'Ошибка изменения данных события',
					})
				)
			})
			.finally(() => dispatch(eventFormActions.setFetchingStatus(false)))
	}

export const setEventCompleted =
	(eventId: string): TAction =>
	(dispatch, getState) => {
		const events = getState().calendar.events.data
		const currentEvent: Record<string, any> | undefined = events.find(
			(event) => event.id === eventId
		)
		const eventCompletedStatus = currentEvent?.$$customParams.isCompleted

		if (currentEvent) {
			dispatch(eventActions.setCompletedStatus({ eventId, status: !eventCompletedStatus }))

			const extensionsParams = {
				...currentEvent.$$customParams,
				isCompleted: !eventCompletedStatus,
			}

			api.calendar
				.updateExtension(eventId, mutateExtensionForMsal(extensionsParams))
				.catch((err) => {
					dispatch(
						addServerError({
							details: utils.getDetailsFromErrorMsal(err),
							text: 'Ошибка изменения статуса выполнения, изменения не сохранятся',
						})
					)
				})
		}
	}
