import { DefaultValues } from 'helpers/convertHelper'

export const linkedEntity = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		type: model.string(['type']),
		name: model.string(['name']),
		linkType: model.string(['linkType']),
	}
}
