import { utils } from 'helpers'
import { defaultToStr, DefaultValues } from 'helpers/convertHelper'

type DeclarantTypes = 'PERSON' | 'ORGANIZATION'

export const declarant = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	const parseFullName = (field: 'subject' | 'representative') => {
		const [surname = '', name = '', patronymic = ''] = model.string([field, 'fullName']).split(' ')

		return { surname, name, patronymic }
	}

	return {
		id: model.string(['subject', 'id']),
		type: model.string<DeclarantTypes>(['subject', 'type']),
		roles: model.array<Array<'BORROWER' | 'OWNER' | 'INVESTOR'>>(['roles']),
		subject: {
			id: model.string(['subject', 'id']),
			fullName: model.string(['subject', 'fullName']),
			orgName: model.string(['subject', 'shortName']),
			fullNameShort: defaultToStr(utils.getFullName(parseFullName('subject'), true)),
			phone: model.string(['subject', 'phone']),
			email: model.string(['subject', 'email']),
			type: model.string<DeclarantTypes>(['subject', 'type']),
		},
		representative: data.representative
			? {
					id: model.string(['representative', 'id']),
					fullName: model.string(['representative', 'fullName']),
					orgName: model.string(['representative', 'shortName']),
					fullNameShort: defaultToStr(utils.getFullName(parseFullName('representative'), true)),
					phone: model.string(['representative', 'phone']),
					email: model.string(['representative', 'email']),
					type: model.string<DeclarantTypes>(['representative', 'type']),
			  }
			: null,
	}
}
