import { Component } from 'react'
import { connect } from 'react-redux'
import { calendarEventForm, deleteEvent, setTooltip } from 'actions/calendar'
import cx from 'clsx'
import PropTypes from 'prop-types'

import './Controls.scss'

class Controls extends Component {
	static propTypes = {
		event: PropTypes.object.isRequired,

		setTooltip: PropTypes.func.isRequired,
		deleteEvent: PropTypes.func.isRequired,
		calendarEventForm: PropTypes.func.isRequired,
	}

	state = {
		behavior: '',
		giveChoice: false,
	}

	editEvent = (applyToAllInstances = false) => {
		const { event, setTooltip, calendarEventForm } = this.props

		setTooltip({})
		calendarEventForm({ ...event, applyToAllInstances }, false)
	}

	deleteEvent = (applyToAllInstances = false) => {
		const { event, setTooltip, deleteEvent } = this.props

		setTooltip({})
		deleteEvent(event, applyToAllInstances)
	}

	showChoicePanel = (behavior) => this.setState({ giveChoice: true, behavior })

	render() {
		const { event } = this.props
		const { giveChoice, behavior } = this.state

		const isSeriesEvent = Boolean(event.seriesMasterId)

		return (
			<div className='calendar-event-tooltip__controls'>
				<div className={cx('calendar-event-tooltip__behaviors', { show: !giveChoice })}>
					<div
						onClick={() => (isSeriesEvent ? this.showChoicePanel('edit') : this.editEvent())}
						className='calendar-event-tooltip__controls-item'
					>
						<i className='zmdi zmdi-edit' />
						<span>Изменить</span>
					</div>
					<div
						onClick={() => (isSeriesEvent ? this.showChoicePanel('delete') : this.deleteEvent())}
						className='calendar-event-tooltip__controls-item'
					>
						<i className='zmdi zmdi-delete' />
						<span>Удалить</span>
					</div>
				</div>
				{isSeriesEvent && (
					<div className={cx('calendar-event-tooltip__choice-instances', { show: giveChoice })}>
						<div onClick={() => this[`${behavior}Event`]()}>Это событие</div>
						<div onClick={() => this[`${behavior}Event`](true)}>Всю серию</div>
					</div>
				)}
			</div>
		)
	}
}

const mapDispatchToProps = {
	setTooltip,
	deleteEvent,
	calendarEventForm,
}

export default connect(null, mapDispatchToProps)(Controls)
