import type { DropResult } from 'react-beautiful-dnd'
import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { commonActions } from 'reducers/common'
import type { Maybe } from 'types/util'

interface State {
	dragging: boolean
	entityId: Maybe<string>
	selectedFiles: Array<any>
	updateFileId: Maybe<string>
	currentParticipant: string
	metadata: Maybe<{
		moveFilesParameters?: {
			entityId?: string
			isContextMenuAction?: boolean
		} & DropResult
		files: Array<{ id: string; title: string; metadata?: Record<string, any>; categoryId: string }>
		fields: Array<{
			id: string
			title: string
			fieldType: 'BOOLEAN' | 'DATE' | 'STRING' | 'CONFIDANT_ID' | 'PERSON' | 'ORGANIZATION'
			required: boolean
		}>
		mode: 'edit' | 'move'
	}>
	modals: {
		metadata: boolean
	}
	rangeSelected: {
		to: Record<string, any>
		from: Record<string, any>
	}
	contextMenuOptions: any[]

	[key: string]: any
}

const initialState: State = {
	dragging: false,
	entityId: null,
	selectedFiles: [],
	metadata: null,
	modals: {
		metadata: false,
	},
	updateFileId: null,
	currentParticipant: '',
	rangeSelected: {
		to: {},
		from: {},
	},
	contextMenuOptions: [],
}

const main = createSlice({
	name: 'fileInspector/main',
	initialState,
	reducers: {
		addFileToSelectedList(state, { payload }: PayloadAction<any>) {
			state.selectedFiles.push(payload)
		},

		resetMetadata(state) {
			state.metadata = null
			state.modals.metadata = false
		},

		setModalVisibility(
			state,
			{ payload }: PayloadAction<{ key: keyof State['modals']; value: boolean }>
		) {
			state.modals[payload.key] = payload.value
		},

		setMetadata(state, { payload }: PayloadAction<State['metadata']>) {
			state.metadata = payload
		},

		setRangeList(
			state,
			{ payload: { file, purpose } }: PayloadAction<{ purpose: 'to' | 'from'; file: any }>
		) {
			state.rangeSelected[purpose] = file
		},

		setField(
			state,
			{ payload: { field, value } }: PayloadAction<{ field: keyof State; value: any }>
		) {
			state[field] = value
		},

		resetSelectedList(state) {
			state.selectedFiles = []
			state.rangeSelected = initialState.rangeSelected
		},

		resetFileFromSelectedList(state, { payload }: PayloadAction<string>) {
			state.selectedFiles = state.selectedFiles.filter((el: any) => el.id !== payload)
		},

		reset: commonActions.reset(initialState),
	},
})

export const { actions } = main
export default main.reducer
