import { DefaultValues } from 'helpers/convertHelper'

import { bankAccountStatement } from './documents/bankAccountStatement'
import { bankStatement } from './documents/bankStatement'
import { ilsPfr } from './documents/ilsPfr'
import { knd1122036 } from './documents/knd1122036'
import { ndfl2 } from './documents/ndfl2'
import { ndfl3 } from './documents/ndfl3'
import { paymentEgisso } from './documents/paymentEgisso'
import { pension } from './documents/pension'
import { unconfirmed } from './documents/uncorfimed'

export const confirmationDocument = <T extends Record<string, any>>(
	data: T,
	isTemplate?: boolean
) => {
	const model = new DefaultValues(data)

	return {
		date: model.string(['date']),
		type: {
			id: model.string(['type', 'id']),
			name: model.string(['type', 'name']),
		},
		values:
			{
				BANK_ACCOUNT_STATEMENT: bankAccountStatement(data.bankAccountStatement, isTemplate),
				BANK_STATEMENT: bankStatement(data.bankStatement, isTemplate),
				ILS_PFR: ilsPfr(data.ilsPfr, isTemplate),
				KND1122036: knd1122036(data.knd1122036, isTemplate),
				NDFL2: ndfl2(data.ndfl2, isTemplate),
				NDFL3: ndfl3(data.ndfl3, isTemplate),
				PAYMENT_EGISSO: paymentEgisso(data.paymentEgisso, isTemplate),
				PENSION: pension(data.pension, isTemplate),
				UNCONFIRMED: unconfirmed(data.unconfirmed, isTemplate),
			}[model.string(['type', 'id'])] ?? null,
	}
}
