import { ENTITY_TYPES } from 'const'

import { defaultToNull, DefaultValues } from 'helpers/convertHelper'

import { additionalPhoneNumber } from './additionalPhoneNumber'
import { bankForm } from './bankForm'
import { form } from './form'
import { linkedEntity } from './linkedEntity'
import { participant } from './participant'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	const participants = model.array(['participants']).map(participant)
	const director = defaultToNull(participants.find(({ roles }) => roles.includes('DIRECTOR')))

	return {
		id: model.string(['id']),
		oneCId: model.string(['oneCId']),
		name: model.string(['form', 'shortName']),
		taxId: model.string(['taxId']),
		email: model.string(['email']),
		phone: model.string(['phone']),
		part: model.null<number>(['part']),
		roles: model.array<string[]>(['roles']),
		financialInfo: model.null<Record<string, any>>(['financialInfo']),
		konturInfo: model.null<Record<string, any>>(['konturInfo']),
		lastZchbUpdate: model.string(['lastZchbUpdate']),
		sites: model.array<string[]>(['sites']),
		form: form(data.form),
		director,
		participants,
		bankForm: bankForm(data.bankForm),
		linkedEntities: model.array(['linkedEntities']).map(linkedEntity),
		additionalPhoneNumbers: model.array(['additionalPhoneNumbers']).map(additionalPhoneNumber),
		additionalEmails: model.array<string[]>(['additionalEmails']),
		$$type: ENTITY_TYPES.ORGANIZATION,
	}
}
