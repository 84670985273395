import { useState } from 'react'
import PropTypes from 'prop-types'

import ConfirmDelete from './ConfirmDelete'

import styles from './Controls.module.scss'

const Controls = (props) => {
	const [showConfirmDelete, setShowConfirmDelete] = useState(false)

	const { user, metadata, hideControls, $$isProcessed, onDeleteComment, onShowEditComponent } =
		props
	const currentBrokerComment = metadata.workerId === user.id

	return (
		<div className={styles.wrapper}>
			{$$isProcessed ? (
				<i className='zmdi zmdi-time' />
			) : (
				<div className={styles.controls}>
					{metadata.edited && <div className={styles.edited}>(ред.)</div>}
					{currentBrokerComment && !(hideControls || showConfirmDelete) && (
						<div className='history-comment__controls'>
							<div className={styles.item} onClick={onShowEditComponent}>
								<i className='zmdi zmdi-edit' />
							</div>
							<div className={styles.item} onClick={() => setShowConfirmDelete(true)}>
								<i className='zmdi zmdi-delete' />
							</div>
						</div>
					)}
					{showConfirmDelete && (
						<ConfirmDelete onDelete={onDeleteComment} onClose={() => setShowConfirmDelete(false)} />
					)}
				</div>
			)}
		</div>
	)
}

Controls.propTypes = {
	user: PropTypes.object.isRequired,
	$$isProcessed: PropTypes.bool.isRequired,
	metadata: PropTypes.shape({
		edited: PropTypes.bool.isRequired,
		workerId: PropTypes.string,
	}),
	hideControls: PropTypes.bool.isRequired,

	onDeleteComment: PropTypes.func.isRequired,
	onShowEditComponent: PropTypes.func.isRequired,
}

export default Controls
