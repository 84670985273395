import { DefaultValues } from 'helpers/convertHelper'

export const brokerSpec = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		communicationWithClientEnabled: model.null<boolean>(['communicationWithClientEnabled']),
		loanIssuanceEnable: model.null<boolean>(['loanIssuanceEnable']),
	}
}
