import { DefaultValues } from 'helpers/convertHelper'

import { investor } from './investor'
import { loans } from './loans'
import { status } from './status'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		number: model.string(['number']),
		status: status(data.status),
		currentInvestor: investor(data.currentInvestor),
		newInvestor: investor(data.newInvestor),
		loans: model.array(['loans']).map(loans),
		createdAt: model.string(['createdAt']),
		modifiedAt: model.string(['modifiedAt']),
		version: model.null<number>(['version']),
	}
}
