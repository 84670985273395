import { Component } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { utils } from 'helpers'

import './Controls.scss'

class Controls extends Component {
	static propTypes = {
		user: PropTypes.object.isRequired,
	}

	render() {
		const { user } = this.props

		return (
			<div className='calendar-controls'>
				{user.fetching
					? 'Загрузка пользователя outlook...'
					: utils.hasObjectLength(user) && (
							<>
								<div className='calendar-controls__user'>
									<div className='calendar-controls__user-name'>
										<i className='zmdi zmdi-outlook' />
										{user.displayName}
									</div>
									<div className='calendar-controls__user-mail'>{user.mail}</div>
								</div>
							</>
					  )}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	user: state.microsoft.data,
})

export default connect(mapStateToProps)(Controls)
