import { nanoid } from 'nanoid'

import { DefaultValues } from 'helpers/convertHelper'

export const facility = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		pseudoId: nanoid(),
		cadastralId: model.string(['cadastralId']),
		type: model.string(['type']),
		address: model.string(['address']),
		area: model.null<number>(['area']),
		estimatedPrice: model.null<number>(['estimatedPrice']),
		$$type: 'FACILITY',
	}
}
