import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { getFacilityInfo } from 'components/pages/RosreestrRegistration/Single/Tabs/Rosreestr/helpers'
import { selectFacilityById } from 'reducers/facility/selectors'
import { selectRosreestrRegistration } from 'reducers/rosreestrRegistration/main'
import urls from 'routes/urls'
import type { RootState } from 'types/redux'
import type { Maybe } from 'types/util'

import { utils } from 'helpers'

import styles from './Registration.module.scss'

interface Props {
	id: string
}

export const Registration = ({ id }: Props) => {
	const { facilityId, number, stateRus, operations } = useSelector((state: RootState) =>
		selectRosreestrRegistration.selectById(state, id)
	)!
	const facility = useSelector((state: RootState) => selectFacilityById(state, facilityId))

	const { info } = getFacilityInfo(facility)

	const kuvd = operations.reduce((acc, { statementInfo }) => {
		const value = statementInfo.statements.find(({ kuvd }) => kuvd)?.kuvd ?? null
		return acc || value
	}, null as Maybe<string>)

	const text = utils.join([stateRus && `статус: ${stateRus.toLowerCase()}`, kuvd], ', ')

	return (
		<div className={styles.wrap}>
			{[
				info && `${info}. `,
				<Link key={0} to={urls.rosreestrRegistration.single.path(id)}>
					Регистрация №{number}
				</Link>,
				text && `, ${text}`,
			]}
		</div>
	)
}
