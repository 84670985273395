import { URLBuilder } from 'api/helpers'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import converters from 'converters'
import type { InvestmentLead } from 'converters/investmentLead'
import investmentLead from 'converters/investmentLead'
import type { Pipeline } from 'converters/pipeline'
import { pipeline } from 'converters/pipeline'
import moment from 'moment'
import { mergeDeepRight } from 'ramda'
import { ge, le } from 'rsql-builder'

import { utils } from 'helpers'
import { defaultToStr } from 'helpers/convertHelper'

import type { Options } from './types/Options'
import type { Pageable } from './types/Pageable'

export default {
	get: (id: string) =>
		axios.get(`/v1/investment-leads/${id}`).then((response) =>
			Object.assign({}, response, {
				data: converters.investmentLead.single(response.data),
			})
		),

	search: (options: Options<Record<string, any>>) => {
		const params = new URLBuilder()

		if (options.filter) {
			const { query, worker, status, minCreatedDate, maxCreatedDate, channel } = options.filter

			const re = (value: string) => `=re="(?i)${value.replace(/"/g, '')}"`

			if (query?.match(/\d+$/g)) {
				params.addFilter('contact.phone', query, re)
			} else if (query) {
				params.addFilter('contact.fullName', query, re)
			}

			params
				.addFilter('workers.id', worker, 'inList')
				.addFilter('status', status, 'inList')
				.addFilter('marketingData.channel', channel, 'inList')
				.addFilter(
					'createdAt',
					minCreatedDate && moment.isDate(minCreatedDate) && minCreatedDate.toISOString(),
					ge
				)
				.addFilter(
					'createdAt',
					maxCreatedDate && moment.isDate(maxCreatedDate) && maxCreatedDate.toISOString(),
					le
				)
		}

		return axios
			.get('/v1/investment-leads', {
				params: {
					size: options.size,
					sort:
						utils.join([defaultToStr(options.property), defaultToStr(options.direction)], ',') ||
						undefined,
					page: options.page,
					filter: params.build('RSQL'),
				},
				$$requestName: 'investmentLead.search',
			})
			.then(
				(response) =>
					mergeDeepRight(response, {
						data: {
							content: response.data.content.map(converters.investmentLead.list),
						},
					}) as AxiosResponse<Pageable<InvestmentLead.List>>
			)
	},

	getPipeline: (type: 'ALL' | 'PIPELINE') =>
		axios.get('/v1/investment-leads/statuses', { params: { type } }),

	create: (data: Record<string, any>) =>
		axios.post('/v1/investment-leads', data).then((response) =>
			Object.assign({}, response, {
				data: converters.investmentLead.single(response.data),
			})
		),

	getTransitions: (id: string) =>
		axios.get(`/v1/investment-leads/${id}/transitions`).then(
			(response) =>
				Object.assign({}, response, {
					data: response.data.map(pipeline.transition),
				}) as AxiosResponse<Pipeline.Transition>
		),

	updateStatus: (id: string, data: Record<string, any>) =>
		axios.post(`/v1/investment-leads/${id}/change-status`, data).then((response) =>
			Object.assign({}, response, {
				data: converters.investmentLead.single(response.data),
			})
		),

	update: (id: string, data: Record<string, any>) =>
		axios.patch(`/v1/investment-leads/${id}`, data, {
			headers: { 'Content-Type': 'application/merge-patch+json' },
		}),

	managers: async (options: Options<{ id: string[] }>) => {
		const params = new URLBuilder()

		if (options.filter) {
			const { id } = options.filter

			params.addFilter('managerId', id, 'inList')
		}

		return axios
			.get('/v1/investment-lead-managers', {
				params: {
					size: 100,
					sort:
						utils.join([defaultToStr(options.property), defaultToStr(options.direction)], ',') ||
						undefined,
					page: 0,
					filter: params.build('RSQL'),
				},
				$$requestName: 'investmentLead.managers',
			})
			.then(
				(response) =>
					mergeDeepRight(response, {
						data: {
							content: response.data.content.map(investmentLead.manager),
						},
					}) as AxiosResponse<Pageable<InvestmentLead.Manager>>
			)
	},
}
