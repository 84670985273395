import { Text } from '@creditclubteam/kit/ui-components'
import { Grid } from 'components/common/Grid'
import { Fields, type FieldsProps } from './Fields'
import { FieldArray, useFormikContext } from 'formik'
import { memo } from 'react'
import { Accordion } from './Accordion'
import { hasValue } from './helpers'
import type { FormValues } from './Form'

export interface PolicyProps {
	title: string
	entity: string
}

type ArraySectionProps = {
	title: string
	name: string
} & Pick<PolicyProps, 'entity'> &
	Pick<FieldsProps, 'type'>

const ArraySection = memo(({ title, name, type, entity }: ArraySectionProps) => {
	return (
		<Grid.Section gap='l'>
			<Text weight='bold'>{title}</Text>
			<FieldArray
				name={name}
				render={({ push, remove }) => (
					<Fields push={push} remove={remove} entity={entity} type={type} />
				)}
			/>
		</Grid.Section>
	)
})

export const Policy = memo(({ title, entity }: PolicyProps) => {
	const { values } = useFormikContext<FormValues>()
	const [entityId] = entity.split('@')

	const hasValuesInAdditionalCheck = [
		hasValue({
			values: values.actualValue,
			entityId,
			type: 'ACTUAL_VALUE',
		}),
		hasValue({
			values: values.requiredActions,
			entityId,
			type: 'REQUIRED_ACTION',
		}),
		hasValue({
			values: values.conditions,
			entityId,
			type: 'CONDITION',
		}),
	].some(Boolean)

	const hasValuesInAdditionalCheck_2 = [
		hasValue({
			values: values.actualValue,
			entityId,
			type: 'ACTUAL_VALUE_2',
		}),
		hasValue({
			values: values.requiredActions,
			entityId,
			type: 'REQUIRED_ACTION_2',
		}),
		hasValue({
			values: values.conditions,
			entityId,
			type: 'CONDITION_2',
		}),
	].some(Boolean)

	const hasValuesInStopFactor = hasValue({
		values: values.stopFactorMeeting,
		entityId,
		type: 'STOP_FACTOR_MEETING',
	})

	return (
		<Grid.Section gap='l'>
			<Text weight='bold' font='buttonLarge'>
				{title}
			</Text>
			<Accordion
				label='Дополнительная проверка'
				padding='l'
				isDefaultExpanded={hasValuesInAdditionalCheck}
			>
				<ArraySection
					entity={entity}
					title='Фактическое значение с комментарием'
					type='ACTUAL_VALUE'
					name='actualValue'
				/>
				<ArraySection
					entity={entity}
					title='Требование к клиенту для завершения проверки'
					type='REQUIRED_ACTION'
					name='requiredActions'
				/>
				<ArraySection
					entity={entity}
					title='Условия выхода на сделку'
					type='CONDITION'
					name='conditions'
				/>
			</Accordion>
			<Accordion
				label='Дополнительная проверка — 2'
				hintText='Параметр используется в случае если по результатам дополнительной проверки к одной и той же программе требуется одновременно передать и требования и условия'
				isDefaultExpanded={hasValuesInAdditionalCheck_2}
				padding='l'
			>
				<ArraySection
					entity={entity}
					title='Фактическое значение с комментарием'
					type='ACTUAL_VALUE_2'
					name='actualValue'
				/>
				<ArraySection
					entity={entity}
					title='Требование к клиенту для завершения проверки'
					type='REQUIRED_ACTION_2'
					name='requiredActions'
				/>
				<ArraySection
					entity={entity}
					title='Условия выхода на сделку'
					type='CONDITION_2'
					name='conditions'
				/>
			</Accordion>
			<Accordion
				label='Наличие стоп-фактора при проведении встречи с клиентом'
				padding='l'
				isDefaultExpanded={hasValuesInStopFactor}
			>
				<ArraySection
					entity={entity}
					title='Фактическое значение с комментарием'
					type='STOP_FACTOR_MEETING'
					name='stopFactorMeeting'
				/>
			</Accordion>
		</Grid.Section>
	)
})
