import { Component } from 'react'
import { connect } from 'react-redux'
import { getObjectKeys } from 'actions/document'
import cx from 'clsx'
import iconDoc from 'images/file-types/doc.svg'
import iconDocx from 'images/file-types/docx.svg'
import iconMp3 from 'images/file-types/mp3.svg'
import iconPdf from 'images/file-types/pdf.svg'
import iconUnknownType from 'images/file-types/unknown_doc.svg'
import iconXls from 'images/file-types/xls.svg'
import iconXlsx from 'images/file-types/xlsx.svg'
import iconZip from 'images/file-types/zip.svg'
import PropTypes from 'prop-types'

import { fileUtils } from '@creditclubteam/helpers'
import { Loading } from 'components/common'

import './Preview.scss'

class Preview extends Component {
	static propTypes = {
		objectKey: PropTypes.string.isRequired,
		isDragging: PropTypes.bool.isRequired,
		isSelected: PropTypes.bool.isRequired,
		selectedFiles: PropTypes.array.isRequired,

		onDoubleClick: PropTypes.func.isRequired,
	}

	state = {
		error: false,
		noPreview: true,
		showImage: false,
		url: null,
	}

	async componentDidMount() {
		try {
			const [{ uri }] = await this.props.getObjectKeys([this.props.objectKey])

			this.setState({ url: uri })
		} catch {
			//
		}

		this.nodeElement.addEventListener('dblclick', this.handleDoubleClick)
	}

	componentWillUnmount() {
		if (this.nodeElement) {
			this.nodeElement.removeEventListener('dblclick', this.handleDoubleClick)
		}
	}

	handleDoubleClick = (event) => {
		const { noPreview } = this.state
		const { onDoubleClick } = this.props

		if (!noPreview) onDoubleClick(event)
	}

	onloadImage = () => this.setState({ noPreview: false })

	handleError = () => this.setState({ error: true })

	getTypeIcon = (type) => {
		switch (type.toLowerCase()) {
			case 'doc':
				return iconDoc
			case 'pdf':
				return iconPdf
			case 'mp3':
				return iconMp3
			case 'zip':
				return iconZip
			case 'xls':
				return iconXls
			case 'xlsx':
				return iconXlsx
			case 'docx':
				return iconDocx
			default:
				return iconUnknownType
		}
	}

	render() {
		const { isSelected, title, extension, isDragging, selectedFiles } = this.props

		const { noPreview, error, url } = this.state

		const isNotPreview = !fileUtils.isImage(title.toLowerCase())
		const knowFile = fileUtils.getSimilarExtensions(extension.toLowerCase())

		const blockOptions = {
			ref: (elem) => (this.nodeElement = elem),
			className: cx('files-inspector-files-bucket__item-preview', {
				noPreview: noPreview && !error,
			}),
		}

		const imgOptions = {
			onLoad: this.onloadImage,
			onError: this.handleError,
			src:
				isNotPreview || !knowFile || (isSelected && isDragging && selectedFiles.length !== 1)
					? this.getTypeIcon(extension)
					: url,

			className: cx('files-inspector-files-bucket__item-image', {
				isImage: !isNotPreview,
			}),
			'data-testid': 'img-preview-in-bucket',
		}

		return (
			<div {...blockOptions}>
				{error ? (
					<div className='files-inspector-files-bucket__item-preview--error'>
						<i className='zmdi zmdi-alert-octagon' />
						Ошибка
					</div>
				) : (
					<>
						{noPreview && (
							<Loading min className='files-inspector-files-bucket__item-preview-loading' />
						)}
						<img {...imgOptions} />
					</>
				)}
			</div>
		)
	}
}

const mapDispatchToProps = {
	getObjectKeys,
}

export default connect(null, mapDispatchToProps)(Preview)
