import { DefaultValues } from 'helpers/convertHelper'

export const okved = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		name: model.string(['name']),
		code: model.string(['code']),
	}
}
