import { Component } from 'react'
import { Map as LeafMap, Marker, Popup, TileLayer, Tooltip } from 'react-leaflet'
import Icons from 'images/markers/index'
import PropTypes from 'prop-types'

class LeafletMap extends Component {
	static propTypes = {
		center: PropTypes.array,
		type: PropTypes.string.isRequired,
		markers: PropTypes.array.isRequired,
		isReadOnly: PropTypes.bool.isRequired,
		showOnlyActive: PropTypes.bool.isRequired,

		toggle: PropTypes.func.isRequired,
	}

	render() {
		const { center, markers, type, showOnlyActive, toggle } = this.props

		if (!center && !markers[0]) return null

		return (
			<LeafMap
				center={center || [markers[0].latitude, markers[0].longitude]}
				zoom={14}
				style={{ height: '100%' }}
			>
				<TileLayer
					url='http://{s}.tile.osm.org/{z}/{x}/{y}.png'
					attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
				/>
				{center && (
					<Marker icon={Icons.red} position={center}>
						<Popup>
							<p>
								<a
									href={`https://yandex.ru/maps/?ll=${center[1]}${encodeURIComponent(',')}${
										center[0]
									}&z=18&pt=${center[1]}${encodeURIComponent(',')}${center[0]}`}
									rel='noopener noreferrer'
									target='_blank'
								>
									Открыть на Яндекс.Картах
								</a>
							</p>
						</Popup>
					</Marker>
				)}
				{markers.map((marker, i) => {
					const formatedSum = Number(Math.round(marker.price)).toLocaleString('ru')
					const formatedAv = Number(Math.round(marker.pricePerSqm)).toLocaleString('ru')
					let header = `${marker.room ? `${marker.room}-комн.` : ''} ${(
						type || ''
					).toLowerCase()} ${marker.area || marker.homesteadArea} м² ${
						marker.floor && marker.floors ? `, этаж ${marker.floor} из ${marker.floors}`.trim() : ''
					}`.trim()
					header = header[0].toUpperCase() + header.slice(1)

					if (showOnlyActive && !marker.$$active) return null

					return (
						<Marker
							icon={marker.$$active ? Icons.blue : Icons.grey}
							key={i}
							position={[marker.latitude, marker.longitude]}
						>
							<Popup>
								<a
									target='_blank'
									className='bold'
									href={marker.url}
									rel='noopener noreferrer'
									style={{ padding: 0, margin: 0 }}
								>
									{header}
								</a>
								<p style={{ padding: 0, margin: 0 }}>{marker.address}</p>
								<p style={{ padding: 0, margin: 0 }}>Цена: {formatedSum} руб.</p>
								<p style={{ padding: 0, margin: 0 }}>Цена кв.м: {formatedAv} руб.</p>
								<p>
									<a
										href={`https://yandex.ru/maps/?ll=${marker.longitude}${encodeURIComponent(
											','
										)}${marker.latitude}&z=18&pt=${marker.longitude}${encodeURIComponent(',')}${
											marker.latitude
										}`}
										rel='noopener noreferrer'
										target='_blank'
									>
										Открыть на Яндекс.Картах
									</a>
								</p>
								{!this.props.isReadOnly && (
									<p>
										<span className='red_link' onClick={() => toggle(marker.id)}>
											{marker.$$active ? `Исключить` : 'Вернуть'}
										</span>
									</p>
								)}
							</Popup>
							<Tooltip direction='bottom' opacity={1}>
								{marker.address}
							</Tooltip>
						</Marker>
					)
				})}
			</LeafMap>
		)
	}
}

export default LeafletMap
