import { ROSREESTR_REGISTRATION } from 'const/rosreestrRegistration'

import { DefaultValues } from 'helpers/convertHelper'

import { statementInfo } from './statementInfo'

export const operation = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		createdAt: model.string(['createdAt']),
		type: model.const(data.type, ROSREESTR_REGISTRATION.OPERATION_TYPES),
		typeRus: model.const(data.type, ROSREESTR_REGISTRATION.OPERATION_TYPES_RUS),
		api: model.const(data.api, ROSREESTR_REGISTRATION.API),
		documents: model.array<string[]>(['documents']),
		declarants: model.array<string[]>(['declarants']),
		additionalData: model.null<Record<string, any>>(['additionalData']),
		statementInfo: statementInfo(data.statementInfo),
	}
}
