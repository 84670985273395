import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { CreditProgram } from 'converters/creditProgram'
import { commonActions } from 'reducers/common'
import type { TRedux } from 'types'
import type { PayloadStatus } from 'types/redux'

const creditProgramAdapter = createEntityAdapter<CreditProgram.Main>()

export const creditProgramSelector = creditProgramAdapter.getSelectors<TRedux.RootState>(
	(state) => state.creditProgram
)

const initialState = creditProgramAdapter.getInitialState({
	status: 'pending' as PayloadStatus,
})

const slice = createSlice({
	name: 'creditProgram',
	initialState,
	reducers: {
		setAll: creditProgramAdapter.setAll,
		setMany: creditProgramAdapter.setMany,
		upsertOne: creditProgramAdapter.upsertOne,
		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions } = slice
export default slice.reducer
