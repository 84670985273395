import type { FormEvent } from 'react'
import { useState } from 'react'
import api from 'api'
import type { Maybe } from 'types/util'

import styles from './SendDocumentsToSign.module.scss'

interface Props {
	signer: Maybe<Record<string, any>>
	documentId: string
}

export const Esia = ({ signer, documentId }: Props) => {
	const [status, setStatus] = useState<'idle' | 'submitting' | 'submitted'>('idle')

	const handleSubmit = async (event: FormEvent<HTMLFormElement>) => {
		event.preventDefault()

		setStatus('submitting')

		try {
			await api.person.sendEsiaLinks({
				personId: signer!.payload.customerId,
				payload: { documentId },
			})

			setStatus('submitted')
		} catch {
			setStatus('idle')
		}
	}

	if (signer?.payload?.customerType !== 'PERSON' || status === 'submitted') return null

	return (
		<form autoComplete='off' onSubmit={handleSubmit}>
			{status === 'idle' && (
				<button type='submit' className={styles.blueBtn}>
					Отправить на подпись через Госуслуги
				</button>
			)}
			{status === 'submitting' && <span className={styles.gray}>Отправляем...</span>}
		</form>
	)
}
