import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Organization } from 'converters/organization'
import { commonActions } from 'reducers/common'

export interface OrganizationFilter {
	query: any
	ids: any
}

const filter: OrganizationFilter = {
	query: null,
	ids: [],
}

const initialState = {
	data: [] as Organization.Main[],
	fetching: false,
	filter,
}

const list = createSlice({
	name: 'organization/list',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Organization.Main[]>) {
			state.data = payload
		},

		update(state, { payload }: PayloadAction<{ id: string; value: Partial<Organization.Main> }>) {
			const i = state.data.findIndex((org) => org.id === payload.id)

			state.data[i] = { ...state.data[i], ...payload.value }
		},

		replace(state, { payload }: PayloadAction<{ id: string; value: Organization.Main }>) {
			const i = state.data.findIndex(({ id }) => id === payload.id)

			state.data[i] = payload.value
		},

		delete(state, { payload }: PayloadAction<string>) {
			const index = state.data.findIndex((org) => org.id === payload)

			state.data = state.data.filter((_, i) => i !== index)
		},

		add(state, { payload }: PayloadAction<Organization.Main>) {
			state.data.push(payload)
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		setFilter: commonActions.setFilter(),
		reset: commonActions.reset(initialState),
	},
})

const { actions } = list

export { actions }
export default list.reducer
