import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { actions } from 'reducers/lead/managers'

import { utils } from 'helpers'

export const resetLeadManagers = actions.reset

export const getLeadManagers = (parameters) => async (dispatch) => {
	dispatch(actions.setFetchingStatus(true))

	const requestParameters = Object.assign({ size: 100, page: 0 }, parameters)

	try {
		const { data } = await api.lead.managers(requestParameters)

		dispatch(actions.setAll(data.content))
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось загрузить менеджеров',
				details: utils.getDetailsFromError(error),
			})
		)
	} finally {
		dispatch(actions.setFetchingStatus(false))
	}
}
