import type { EntityId } from 'api/types/EntityId'
import type { Options } from 'api/types/Options'
import type { Pageable } from 'api/types/Pageable'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type { Deposit } from 'converters/deposit'
import { deposit } from 'converters/deposit'
import { mergeDeepRight } from 'ramda'
import type { DepositFilter } from 'reducers/deposit/list'

const baseUrl = '/v4/investment/savings'

declare namespace DepositApi {
	export type Search = Pageable<Deposit.Brief>
	export type Get = Deposit.Main
	export type Update = void
	export type UpdateStatus = Deposit.Main
	export type CreateMoneyMovement = Deposit.Main
	export type Create = Deposit.Main
	export type DeleteMoneyMovement = Deposit.Main
	export type PaymentSchedule = Deposit.PaymentSchedule
}

export default {
	get: (id: EntityId) =>
		axios
			.get<DepositApi.Get>(`${baseUrl}/${id}`, {
				$$requestName: 'deposit.get',
			})
			.then((response) => Object.assign({}, response, { data: deposit.main(response.data) })),

	search: (options: Options<DepositFilter>) =>
		axios
			.get(baseUrl, {
				params: {
					size: options.size,
					sort: options.filter?.sort,
					page: options.page,
					query: options.filter?.query,
					status: options.filter?.status?.join(','),
				},
				$$requestName: 'deposit.search',
			})
			.then(
				(response) =>
					mergeDeepRight(response, {
						data: {
							content: response.data.content.map(deposit.brief),
						},
					}) as AxiosResponse<DepositApi.Search>
			),

	paymentSchedule: (id: EntityId) =>
		axios
			.get<DepositApi.PaymentSchedule>(`${baseUrl}/${id}/payment-schedule`, {
				$$requestName: 'deposit.paymentSchedule',
			})
			.then((response) =>
				Object.assign({}, response, { data: deposit.paymentSchedule(response.data) })
			),

	createMoneyMovement: ({
		id,
		payload,
	}: {
		id: string
		payload: { type: string; date: string; details: Array<{ type: string; amount: number }> }
	}) =>
		axios
			.post<DepositApi.CreateMoneyMovement>(`${baseUrl}/${id}/money-movements`, payload, {
				$$requestName: 'deposit.createMoneyMovement',
			})
			.then((response) => Object.assign({}, response, { data: deposit.main(response.data) })),

	deleteMoneyMovement: ({ entityId, operationId }: { entityId: string; operationId: string }) =>
		axios
			.delete<DepositApi.DeleteMoneyMovement>(
				`${baseUrl}/${entityId}/money-movements/${operationId}`,
				{
					$$requestName: 'deposit.deleteMoneyMovement',
				}
			)
			.then((response) => Object.assign({}, response, { data: deposit.main(response.data) })),

	updateStatus: ({ entityId, payload }: { entityId: string; payload: { transitionId: string } }) =>
		axios
			.post<DepositApi.UpdateStatus>(`${baseUrl}/${entityId}/workflow`, payload, {
				$$requestName: 'deposit.updateStatus',
			})
			.then((response) => Object.assign({}, response, { data: deposit.main(response.data) })),

	create: (payload: Record<string, any>) =>
		axios
			.post<DepositApi.Create>(baseUrl, payload, {
				$$requestName: 'deposit.create',
			})
			.then((response) => Object.assign({}, response, { data: deposit.main(response.data) })),

	update: ({ id, payload }: Record<string, any>) =>
		axios.patch<DepositApi.Update>(`${baseUrl}/${id}`, payload, {
			$$requestName: 'deposit.update',
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),
}
