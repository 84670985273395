import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Representative } from 'converters/representative'
import { commonActions } from 'reducers/common'
import type { RootState } from 'types/redux'
import type { Maybe } from 'types/util'

type State = {
	data: Representative.Main[]
	fetching: boolean
	filter: {
		id: Maybe<string>
		city: Maybe<string>
		name: Maybe<string>
		surname: Maybe<string>
		patronymic: Maybe<string>
		enabled: Maybe<boolean>
		query: Maybe<string>
	}
	sort: string
}

export const representativesSelector = (state: RootState) => state.representative.list.data

export const representativesLoadingSelector = (state: RootState) =>
	state.representative.list.fetching

const initialState: State = {
	data: [],
	fetching: false,
	filter: {
		id: null,
		city: null,
		name: null,
		surname: null,
		patronymic: null,
		enabled: null,
		query: null,
	},
	sort: 'createdAt,desc',
}

type ChangeFilterAction = PayloadAction<{
	field: keyof State['filter']
	value: any
}>

const list = createSlice({
	name: 'representative/list',
	initialState,
	reducers: {
		changeFilterParameter(state, { payload: { field, value } }: ChangeFilterAction) {
			state.filter[field] = value as any
		},

		resetData(state) {
			state.data = []
			state.fetching = false
		},

		resetFilter(state) {
			state.filter = initialState.filter
		},

		setData(state, { payload }: PayloadAction<State['data']>) {
			state.data = payload
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions } = list
export default list.reducer
