import { Component } from 'react'
import PropTypes from 'prop-types'

class ViewDocs extends Component {
	static propTypes = {
		files: PropTypes.array.isRequired,
	}

	render() {
		const { files } = this.props

		return (
			<div className='files-viewer-frame'>
				<iframe
					src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
						files[0].src
					)}`}
				/>
			</div>
		)
	}
}

export default ViewDocs
