import { DefaultValues } from 'helpers/convertHelper'

import { policy } from './policy'

export const program = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		title: model.string(['title']),
		overallState: {
			title: model.string(['overallState', 'title']),
			success: model.null<boolean>(['overallState', 'success']),
		},
		policies: model.array(['policies']).map(policy),
	}
}
