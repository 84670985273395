const getTimeZone = () => {
	try {
		return Intl.DateTimeFormat().resolvedOptions().timeZone
	} catch (err) {
		return 'Asia/Yekaterinburg'
	}
}

export default {
	TIME_ZONE: getTimeZone(),
	EXTENSION_NAME: 'credit.club.event.customParams',

	DEFAULT_CUSTOM_PARAMS: {
		entity: [],
		eventType: '',
		isCompleted: false,
	},

	EVENT_TYPES: [
		{ id: 'meeting', label: 'Встреча' },
		{ id: 'call', label: 'Звонок' },
		{ id: 'task', label: 'Задача' },
		{ id: 'payment', label: 'Оплата' },
	],

	VIEWS_TYPES: [
		{ id: 'month', label: 'Месяц' },
		{ id: 'week', label: 'Неделя' },
		{ id: 'day', label: 'День' },
		{ id: 'agenda', label: 'Списком' },
	],

	RECURRENCE: {
		PATTERN_TYPES: [
			{ id: null, label: 'Никогда' },
			{ id: 'daily', label: 'Каждый день' },
			{ id: 'weekly', label: 'Каждую неделю' },
			{ id: 'absoluteMonthly', label: 'Каждый месяц' },
			{ id: 'absoluteYearly', label: 'Каждый год' },
		],

		DAYS_OF_WEEK: [
			{ id: 'sunday', label: 'Воскресенье' },
			{ id: 'monday', label: 'Понедельник' },
			{ id: 'tuesday', label: 'Вторник' },
			{ id: 'wednesday', label: 'Среда' },
			{ id: 'thursday', label: 'Четверг' },
			{ id: 'friday', label: 'Пятница' },
			{ id: 'saturday', label: 'Суббота' },
		],
	},

	REMINDER_RANGE_MINUTES: [
		{ id: 0, label: 'Не нужно' },
		{ id: 5, label: 'за 5 мин.' },
		{ id: 15, label: 'за 15 мин.' },
		{ id: 30, label: 'за 30 мин.' },
		{ id: 60, label: 'за 1 час' },
		{ id: 120, label: 'за 2 часа' },
		{ id: 720, label: 'за 12 часов' },
		{ id: 1440, label: 'за 1 день' },
		{ id: 10080, label: 'за неделю' },
	],

	EVENT_FIELDS: [
		'id',
		'end',
		'body',
		'start',
		'subject',
		'isAllDay',
		'recurrence',
		'isReminderOn',
		'seriesMasterId',
		'reminderMinutesBeforeStart',
	],
}
