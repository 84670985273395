import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { CreditPolicyReport } from 'converters/creditPolicyReport'
import { commonActions } from 'reducers/common'
import type { PayloadStatus } from 'types/redux'
import type { Maybe } from 'types/util'

interface State extends CreditPolicyReport.Main {
	status: PayloadStatus
	titlePart: Maybe<CreditPolicyReport.TitlePart>
	selection: {
		programs: string[]
		policy: Maybe<string>
	}
}

const initialState: State = {
	entities: {
		policies: {},
		products: {},
		programs: {},
	},
	ids: [],
	titlePart: null,
	selection: {
		programs: [],
		policy: null,
	},
	status: 'pending' as PayloadStatus,
}

const main = createSlice({
	name: 'creditPolicyReport/main',
	initialState,
	reducers: {
		setData: (state, { payload: { entities, ids } }: PayloadAction<CreditPolicyReport.Main>) => {
			state.entities = entities
			state.ids = ids
		},

		setTitlePart: (state, { payload }: PayloadAction<Maybe<CreditPolicyReport.TitlePart>>) => {
			state.titlePart = payload
		},

		setPolicyChecked: (
			state,
			{ payload: { id, checked } }: PayloadAction<{ id: string; checked: boolean }>
		) => {
			state.entities.policies[id].checked = checked
		},

		setSelection: (
			state,
			{ payload }: PayloadAction<{ key: keyof State['selection']; value: Maybe<string> }>
		) => {
			if (payload.key === 'programs') {
				state.selection.programs = payload.value
					? state.selection.programs.includes(payload.value)
						? state.selection.programs.filter((p) => p !== payload.value)
						: [...state.selection.programs, payload.value]
					: []
			} else {
				state.selection[payload.key] =
					state.selection[payload.key] === payload.value || payload.value === null
						? null
						: payload.value

				if (!state.selection[payload.key]) state.titlePart = null
			}
		},

		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions: creditPolicyReportReducerActions } = main
export default main.reducer
