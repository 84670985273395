import { resetUploadFiles } from 'actions/uploadFiles'
import { keycloak } from 'client/keycloak/instance'
import { isEmpty } from 'ramda'
import type { TRedux } from 'types'

export const logout = (): TRedux.TAction => (dispatch, getState) => {
	const uploadFilesQueue = getState().uploadFiles.uploader

	if (!isEmpty(uploadFilesQueue)) {
		const result = confirm('Имеются файлы в очередь на загрузку, вы действительно хотите выйти?')

		if (result) {
			dispatch(resetUploadFiles())
		} else {
			return
		}
	}

	sessionStorage.clear()
	keycloak.logout()
}
