import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { commonActions } from 'reducers/common'

interface State {
	title: string
	comment: string
	error: boolean
	ownerTypes: Array<unknown>
	required: boolean
	postProcessing: boolean

	[key: string]: unknown
}

const initialState: State = {
	title: '',
	comment: '',
	error: false,
	ownerTypes: [],
	required: false,
	postProcessing: false,
}

const addCategoryForm = createSlice({
	name: 'filesInspector',
	initialState,
	reducers: {
		setField(
			state,
			{ payload: { field, value } }: PayloadAction<{ field: keyof State; value: unknown }>
		) {
			state[field] = value
		},

		reset: commonActions.reset(initialState),
	},
})

export const { actions } = addCategoryForm
export default addCategoryForm.reducer
