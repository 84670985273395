import type { SelectProps } from '@creditclubteam/react-components'
import { Select } from '@creditclubteam/react-components'
import type { IOption } from '@creditclubteam/react-components/dist/src/components/Select/Option'

interface ConfidantSelectProps {
	onChange: (value: string) => void
	value: string
	options: IOption[]
	mode?: SelectProps['mode']
	disabled?: boolean
	title?: string
}

export const ConfidantSelect = ({
	onChange,
	value,
	options,
	disabled,
	title,
	mode,
}: ConfidantSelectProps): JSX.Element => {
	const _onChange = (v: IOption) => onChange(v.id)
	return (
		<Select
			onChange={_onChange}
			value={value}
			options={options}
			disabled={disabled}
			title={title}
			mode={mode}
		/>
	)
}
