import { Fragment } from 'react'
import { connect } from 'react-redux'
import { getHistoryParticipants } from 'actions/history'
import { addDownloadFileStatus, hideNotice } from 'actions/notices'
import moment from 'moment'
import PropTypes from 'prop-types'
import { workerSelector } from 'reducers/worker'

import { downloadFile } from '@creditclubteam/helpers'
import { AudioPlayer } from 'components/common'

import Context from '../../Context'

import './VoximplantPhoneConversation.scss'

const VoximplantPhoneConversation = (props) => {
	const { hideNotice, addDownloadFileStatus, state, metadata, $$date, getHistoryParticipants } =
		props

	const workers = workerSelector.selectAll(state)

	const manager = workers?.find((worker) => worker.id === metadata?.workerId)

	const client = getHistoryParticipants().find(
		(participant) => participant.phone === metadata?.phone
	)

	const renderLine = (showManagerLine) => {
		return showManagerLine
			? manager
				? `${manager.surname || ''} ${manager.name || ''}`
				: 'Неизвестно'
			: client
			? `${client.surname || '-'} ${client.name || '-'}`
			: 'Неизвестно'
	}

	const handleDownload = () => {
		const curDateFormatted = moment($$date?.fullDate).format('DD.MM.YYYY hh:mm')
		const title = `${curDateFormatted}_${metadata?.phone}.mp3`

		downloadFile(
			{
				url: metadata?.url,
				onError: () => hideNotice(metadata?.id),
				onDownloadProgress: (e) => addDownloadFileStatus(e, metadata?.id, title),
			},
			title
		)
	}

	const incoming = metadata?.direction === 'INBOUND'
	const contextOptions = {
		date: $$date?.fullDate,
		avatar: (
			<div className='history-phone-conversation__avatar'>
				<i className='zmdi zmdi-phone-in-talk' />
			</div>
		),
		title: (
			<Fragment>
				{renderLine(!incoming)}
				<span style={{ margin: '0 7px' }}>
					<i className='zmdi zmdi-long-arrow-right' />
				</span>
				{renderLine(incoming)}
			</Fragment>
		),
	}

	return (
		<Context {...contextOptions}>
			<AudioPlayer src={metadata?.url} onDownload={handleDownload} />
		</Context>
	)
}

VoximplantPhoneConversation.propTypes = {
	$$date: PropTypes.object.isRequired,
	metadata: PropTypes.shape({
		url: PropTypes.string,
		direction: PropTypes.string.isRequired,
		workerId: PropTypes.string.isRequired,
		phone: PropTypes.string.isRequired,
	}),

	getHistoryParticipants: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	state,
})

const mapDispatchToProps = {
	hideNotice,
	addDownloadFileStatus,
	getHistoryParticipants,
}

export default connect(mapStateToProps, mapDispatchToProps)(VoximplantPhoneConversation)
