import { DefaultValues } from 'helpers/convertHelper'

import { operationDetail } from './operationDetail'

export const operation = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		date: model.string(['date']),
		amount: model.null<number>(['amount']),
		type: model.const(data.type, {
			LOAN_ISSUE: 'Выдача займа',
			LOAN_REPAYMENT: 'Погашение по займу',
			REFILL: 'Поступление на счет',
		} as const),
		loanId: model.string(['loanId']),
		operationDetails: model.array(['operationDetails']).map(operationDetail),
	}
}
