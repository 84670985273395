import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { PartnerDto } from 'converters/partner'
import { commonActions } from 'reducers/common'
import type { PayloadStatus } from 'types/redux'
import type { Maybe } from 'types/util'

const initialState = {
	data: null as Maybe<PartnerDto.Main>,
	status: 'pending' as PayloadStatus,
}

const single = createSlice({
	name: 'partner/single',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<PartnerDto.Main>) {
			state.data = payload
		},

		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions: partnerSingleReducerActions } = single
export default single.reducer
