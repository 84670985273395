import { DefaultValues } from 'helpers/convertHelper'

export const call = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		loanPurpose: model.string(['loanPurpose']),
		persons: model.array<Record<string, any>[]>(['persons']),
		entrepreneurs: model.array<Record<string, any>[]>(['entrepreneurs']),
		organizations: model.array<Record<string, any>[]>(['organizations']),
		facilities: model.array<Record<string, any>[]>(['facilities']),
		loanPurposeAndOkvedInconsistency: model.string(['loanPurposeAndOkvedInconsistency']),
		verificationCallResult: model.string(['verificationCallResult']),
	}
}
