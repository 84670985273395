import { CalendarEvent } from 'builders/calendar'
import CALENDAR from 'const/calendar'
import moment from 'moment'
import { clone } from 'ramda'

import { take } from '@creditclubteam/helpers'
import { utils } from 'helpers'

/**
 * @description Мутируем форму для отправки её на сервисы майкрософта
 * в некоторых случаях нужно удалять лишние параметры так как апи ругается и бросает ошибку
 * Например: мы создаём событие, форма на создание и редактирование одна поэтому в случае создания
 * поле id будет пустое, вроде как пустое и пустое но апи заругает, серии событий так же и пр.
 */
export const mutateFormForMsal = (form: Record<string, any>) => {
	form = clone(form)
	const endDate = new Date(form.end)
	const startDate = new Date(form.start)

	// Если событие идёт весь день подправляем даты для api
	if (form.isAllDay) {
		if (isEqualDates(endDate, startDate)) {
			endDate.setDate(endDate.getDate() + 1)
		}

		;[startDate, endDate].forEach((date) => {
			date.setHours(0)
			date.setMinutes(0)
			date.setSeconds(0)
			date.setMilliseconds(0)
		})
	}

	const setDateForMsal = (date: Date) => {
		return new Date(date.getTime() - date.getTimezoneOffset() * 60000)
			.toISOString()
			.replace('Z', '')
	}

	const newForm: Record<string, any> = Object.assign(
		{},
		{
			...form,
			start: {
				timeZone: CALENDAR.TIME_ZONE,
				dateTime: setDateForMsal(startDate),
			},
			end: {
				timeZone: CALENDAR.TIME_ZONE,
				dateTime: setDateForMsal(endDate),
			},
		}
	)

	// Если событие только создаётся, id нет
	if (!form.id) delete newForm.id
	delete newForm.$$customParams
	delete newForm.applyToAllInstances
	delete newForm.userId

	// Обрабатываем повторение события
	if (!take(form, 'recurrence.pattern.type')) {
		delete newForm.recurrence
	} else {
		// pattern
		if (newForm.recurrence.pattern.type === 'weekly') {
			newForm.recurrence.pattern.daysOfWeek = [
				CALENDAR.RECURRENCE.DAYS_OF_WEEK[form.start.getDay()].id,
			]
		} else if (newForm.recurrence.pattern.type === 'absoluteMonthly') {
			newForm.recurrence.pattern.dayOfMonth = form.start.getDate()
		} else if (newForm.recurrence.pattern.type === 'absoluteYearly') {
			newForm.recurrence.pattern.month = form.start.getMonth() + 1
			newForm.recurrence.pattern.dayOfMonth = form.start.getDate()
		}

		// range
		newForm.recurrence.range.startDate = moment(form.start).format('YYYY-MM-DD')
		newForm.recurrence.range.endDate =
			newForm.recurrence.range.type === 'noEnd'
				? '0001-01-01'
				: moment(newForm.recurrence.range.endDate).format('YYYY-MM-DD')
	}

	return newForm
}

export const mutateEventsForCalendar = (...events: Record<string, any>[]): Record<string, any>[] =>
	events.map((event) => {
		let $$customParams = CALENDAR.DEFAULT_CUSTOM_PARAMS

		// Достаём наши пользовательские данные из расширений
		if (utils.hasObjectLength(event.extensions)) {
			const customParams = event.extensions.find(({ id }: Record<string, any>) =>
				id.includes(CALENDAR.EXTENSION_NAME)
			)

			if (customParams)
				$$customParams = {
					...customParams,
					entity: JSON.parse(quoteToJson(customParams.entity || '[]')),
				}
		}

		const endDate = new Date(event.end.dateTime)
		const startDate = new Date(event.start.dateTime)

		// При создании события возвращается не та таймзона, докручиваем сами
		if (event.end.timeZone !== CALENDAR.TIME_ZONE && !event.isAllDay) {
			;[endDate, startDate].forEach((date) => {
				date.setHours(date.getHours() + 5)
			})
		}

		return {
			...event,
			$$customParams,
			end: endDate,
			start: startDate,
		}
	})

export const mutateExtensionForMsal = (ext: Record<string, any>) => ({
	...ext,
	entity: arrToQuote(ext.entity),
	extensionName: CALENDAR.EXTENSION_NAME,
	['@odata.type']: 'microsoft.graph.openTypeExtension',
})

/**
 * @param inc
 * @returns {CalendarEvent}
 * @private
 */
export const _calendarFormToEvent = (inc = {} as Record<string, any>) => {
	const event = new CalendarEvent()

	event.id = inc.id || event.id
	event.body = inc.body || event.body
	event.start = inc.start || event.start
	event.userId = inc.userId || event.userId
	event.end = inc.end || new Date(event.start)
	event.subject = inc.subject || event.subject
	event.isAllDay = inc.isAllDay || event.isAllDay
	event.isReminderOn = inc.isReminderOn || event.isReminderOn
	event.seriesMasterId = inc.seriesMasterId || event.seriesMasterId
	event.applyToAllInstances = inc.applyToAllInstances || event.applyToAllInstances
	event.reminderMinutesBeforeStart =
		inc.reminderMinutesBeforeStart || event.reminderMinutesBeforeStart

	if (inc.entity) {
		if (Array.isArray(inc.entity)) {
			event.$$customParams.entity = inc.entity as never[]
		} else {
			event.$$customParams.entity.push(inc.entity as never)
		}
	}

	if (inc.eventType) {
		event.$$customParams.eventType = inc.eventType
	}

	if (inc.$$customParams) {
		event.$$customParams = inc.$$customParams
	}

	return event
}

const arrToQuote = (arr: any[]) => {
	const toJSON = JSON.stringify(arr)

	return toJSON.replace(/"/g, '\\"')
}

export const quoteToJson = (quote: string) => quote.replace(/\\"/g, '"')

const isEqualDates = (date1: string | Date, date2: string | Date) => {
	const format = 'DDMMYY'
	return moment(date1).format(format) === moment(date2).format(format)
}
