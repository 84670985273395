import { lazy } from 'react'

import { lazyLoadHandler } from 'helpers/lazyLoadHandler'

const Single = lazy(() => lazyLoadHandler(() => import('./Single')))
const List = lazy(() => lazyLoadHandler(() => import('./List')))
const Create = lazy(() => lazyLoadHandler(() => import('./Create')))

export default {
	Single,
	List,
	Create,
}
