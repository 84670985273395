import { PERSON_INCOMES } from 'const/personIncomes'

import { DefaultValues } from 'helpers/convertHelper'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		period: model.string(['period']),
		proofSource: model.const(data.proofSource, PERSON_INCOMES.PROOF_SOURCE),
		proofSourceRus: model.const(data.proofSource, PERSON_INCOMES.PROOF_SOURCE_RUS),
		amount: model.null<number>(['amount']),
	}
}
