import { list } from './list'
import { manager } from './manager'
import { single } from './single'

export default {
	single,
	list,
	manager,
}

export namespace InvestmentLead {
	export type Single = ReturnType<typeof single>
	export type List = ReturnType<typeof list>
	export type Manager = ReturnType<typeof manager>
}
