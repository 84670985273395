import { utils } from 'helpers'
import { defaultToStr, DefaultValues } from 'helpers/convertHelper'

export const customer = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		// Common
		name: model.string(['name']),
		taxId: model.string(['taxId']),

		// Person
		surname: model.string(['surname']),
		patronymic: model.string(['patronymic']),
		fullName: defaultToStr(utils.getFullName(data)),
		shortName: defaultToStr(utils.getFullName(data, true)),
		phone: model.string(['phone']),
		email: model.string(['email']),

		// Organization
		directorId: model.string(['directorId']),

		// Enterpreneur
		personId: model.string(['personId']),
	}
}
