import { DefaultValues } from 'helpers/convertHelper'

export const relation = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		relativeEntity: {
			id: model.string(['relativeEntity', 'id']),
			type: model.string(['relativeEntity', 'type']),
			title: model.string(['relativeEntity', 'title']),
			roles: model.array<string[]>(['relativeEntity', 'roles']),
		},
		relationTypes: model.array<string[]>(['relationTypes']),
	}
}
