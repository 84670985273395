import { DefaultValues } from 'helpers/convertHelper'

import { analytics } from './analytics'
import { court } from './court'
import { participant } from './participant'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		createdAt: model.string(['createdAt']),
		version: model.null<number>(['version']),
		efrsbNegative: model.null<boolean>(['efrsbNegative']),
		outOfCourtBankruptcy: model.null<boolean>(['outOfCourtBankruptcy']),

		status: model.const(data.status, {
			PROCESSING: 'PROCESSING',
			FINISHED: 'FINISHED',
		} as const),
		statusRus: model.const(data.status, {
			PROCESSING: 'В процессе',
			FINISHED: 'Проверка завершена',
		} as const),

		courts: model.array(['courts']).map(court),
		analytics: analytics(data.analytics),
		participant: participant(data.analytics),
	}
}
