import type { SOURCE } from 'const/externalData'

import { DefaultValues } from 'helpers/convertHelper'

const overdue = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		startDate: model.string(['startDate']),
		endDate: model.string(['endDate']),
	}
}

const monthlyPayment = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		period: {
			year: model.null<number>(['year']),
			month: model.const(data?.month, {
				JANUARY: 'JANUARY',
				FEBRUARY: 'FEBRUARY',
				MARCH: 'MARCH',
				APRIL: 'APRIL',
				MAY: 'MAY',
				JUNE: 'JUNE',
				JULY: 'JULY',
				AUGUST: 'AUGUST',
				SEPTEMBER: 'SEPTEMBER',
				OCTOBER: 'OCTOBER',
				NOVEMBER: 'NOVEMBER',
				DECEMBER: 'DECEMBER',
			} as const),
			monthValue: model.null<number>(['amount']),
			leapYear: model.null<boolean>(['leapYear']),
		},
		amount: model.null<number>(['amount']),
	}
}

export const credit = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		creditType: {
			id: model.string(['creditType', 'id']),
			title: model.string(['creditType', 'title']),
		},
		status: model.string(['status']),
		type: model.string(['type']),
		ownerType: model.string(['ownerType']),
		currentBalance: model.null<number>(['currentBalance']),
		outstandingAmount: model.null<number>(['outstandingAmount']),
		openedAt: model.string(['openedAt']),
		closedAt: model.string(['closedAt']),
		closed: model.null<boolean>(['closed']),
		updatedAt: model.string(['updatedAt']),
		creditLimit: model.null<number>(['creditLimit']),
		pastDueAmount: model.null<number>(['pastDueAmount']),
		instalmentAmount: model.null<number>(['instalmentAmount']),
		overdue1MonthCount: model.null<number>(['overdue1MonthCount']),
		overdue2MonthCount: model.null<number>(['overdue2MonthCount']),
		overdue3PlusMonthCount: model.null<number>(['overdue3PlusMonthCount']),
		overdue1PlusMonthFor6MonthCount: model.null<number>(['overdue1PlusMonthFor6MonthCount']),
		overdue1PlusMonthFor1YearCount: model.null<number>(['overdue1PlusMonthFor1YearCount']),
		overdues: model.array(['overdues']).map(overdue),
		monthlyPayments: model.array(['monthlyPayments']).map(monthlyPayment),
		warnings: model.array<string[]>(['warnings']),
		source: model.string<keyof typeof SOURCE>(['source']),
	}
}
