import { DefaultValues } from 'helpers/convertHelper'

const getIdAndTitle = (data?: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
	}
}

export const workflow = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		status: getIdAndTitle(data?.status),
		pipeline: model.array(['pipeline']).map(({ status }, i) => ({
			status: getIdAndTitle(status),
			active: model.null<boolean>(['pipeline', i, 'active']),
		})),
		transitions: model.array(['transitions']).map(({ status }, i) => ({
			id: model.string(['transitions', i, 'id']),
			name: model.string(['transitions', i, 'name']),
			targetStatus: getIdAndTitle(status),
		})),
	}
}
