export const FINANCIAL_TYPES = new Proxy(
	{
		ANNUITY: 'Аннуитетный платеж',
		PERCENT: 'Процентный платеж',
		MIXED: 'Смешанный платеж',
	},
	{
		get(target: any, p) {
			if (!target[p]) {
				return 'Неизвестный тип'
			}

			return Reflect.get(target, p)
		},
	}
)
