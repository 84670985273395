import { Component } from 'react'
import cx from 'clsx'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import './FilterInput.scss'

function OptionsList(props) {
	const { suggestions, visible, optionsClassName, onSelect, activeIndex, labelKey } = props

	if (!!suggestions.length && visible) {
		return (
			<div className={cx('filter-input__options', { [optionsClassName]: !!optionsClassName })}>
				{suggestions.map((suggestion, i) => (
					<div
						key={i}
						onMouseDown={() => onSelect(i)}
						className={cx('filter-input__option', {
							active: activeIndex === i,
						})}
					>
						{suggestion[labelKey] || suggestion.value}
					</div>
				))}
			</div>
		)
	}
	return null
}

export default class extends Component {
	static propTypes = {
		value: PropTypes.string,
		required: PropTypes.bool,
		disabled: PropTypes.bool,
		component: PropTypes.string,
		placeholder: PropTypes.string,
		disableDigits: PropTypes.bool,
		optionLabelKey: PropTypes.string,
		inputClassName: PropTypes.string,
		optionsClassName: PropTypes.string,

		onChange: PropTypes.func.isRequired,
		onSelect: PropTypes.func,
	}

	static defaultProps = {
		showAllOptionsOnFirstFocus: false,
		required: false,
		optionLabelKey: 'value',
	}

	state = {
		isTouched: false,
		selected: -1,
		showOptions: false,
		value: this.props.value,
		options: this.props.options.filter((e) => e.value.includes(this.props.value)),
	}

	static getDerivedStateFromProps({ value }, state) {
		if (value !== state.value && !state.isTouched) {
			return { value }
		}

		return null
	}

	compareValues = (value1, value2) => {
		if (value1 && value2) return value1.toLowerCase().includes(value2.toLowerCase())
		return false
	}

	handleSelect = (index) => {
		const { selected } = this.state
		const { onChange, onSelect } = this.props

		if (index !== selected) {
			this.selectOption(index)
		}

		const selectedSuggestion = this.state.options[index]

		if (selectedSuggestion) {
			onChange(selectedSuggestion.value, selectedSuggestion)
			onSelect && onSelect(selectedSuggestion)
		}
	}

	selectOption = (index) => {
		this.setState(({ options }) => {
			const selectedOption = options[index]
			const value = selectedOption.value
			const filteredOptions = options.filter((e) => e.value.includes(value || ''))

			return {
				selected: index,
				value,
				options: filteredOptions,
			}
		})
	}

	handleChange = (e) => {
		const value = e.target.value
		const { onChange, disableDigits, options } = this.props

		if (value && disableDigits && !/^[a-zA-ZА-Яа-яЁё\s]+$/.test(value)) return

		const filteredOptions = value
			? options.filter((e) => this.compareValues(e.value, value))
			: options

		this.setState({
			value,
			selected: -1,
			options: filteredOptions,
			showOptions: !isEmpty(filteredOptions),
		})

		if (onChange) {
			onChange(value)
		}
	}

	makeListVisible = () => {
		const { showOptions, options } = this.state

		if (showOptions) {
			return
		}

		this.input.addEventListener('keydown', this.handleKeydown)

		this.setState({ showOptions: !!options.length })
	}

	makeListInvisible = () => {
		const { showOptions, value } = this.state

		if (!showOptions) {
			return
		}

		this.input.removeEventListener('keydown', this.handleKeydown)

		if (value !== this.props.value) {
			this.props.onChange(value)
		}

		this.setState({ showOptions: false })
	}

	handleKeydown = (e) => {
		const { showOptions, options, selected } = this.state
		const inc = options.length - 1 !== selected && options.length - 1 > selected ? selected + 1 : 0
		const dec = 0 !== selected && selected !== -1 ? selected - 1 : options.length - 1

		if (showOptions && !isEmpty(options)) {
			if (e.keyCode === 40) {
				// кнопка вниз
				this.selectOption(inc)
			}

			if (e.keyCode === 38) {
				// кнопка вверх
				this.selectOption(dec)
			}

			if (e.keyCode === 13) this.handleSelect(selected)
		}
	}

	handleBlur = () => {
		const { onBlur } = this.props

		if (onBlur) onBlur()

		this.makeListInvisible()
	}

	handleFocus = () => {
		const { options, selected, isTouched } = this.state
		const { options: propsOptions, value, showAllOptionsOnFirstFocus } = this.props

		if (!isTouched && showAllOptionsOnFirstFocus) {
			this.setState({
				isTouched: true,
				selected: propsOptions.findIndex((item) => this.compareValues(item.value, value)),
				showOptions: !!propsOptions.length,
				options: propsOptions,
			})

			return
		}

		if (!isEmpty(options) && selected === -1) {
			this.makeListVisible()
		}
	}

	render() {
		const {
			placeholder,
			className,
			inputClassName,
			optionsClassName,
			component,
			title = '',
			titleClassName,
			required = false,
			disabled,
			optionLabelKey,
		} = this.props
		const { options, showOptions, value } = this.state

		const boxClass = cx('filter-input-box', {
			[className]: !!className,
		})

		const inputClass = cx('filter-input', {
			disabled,
			[inputClassName]: !!inputClassName,
		})

		const titleClass = cx('filter-input__title', {
			[titleClassName]: !!titleClassName,
		})

		const inputProps = {
			required,
			spellCheck: false,
			value: value || '',
			className: inputClass,
			onBlur: this.handleBlur,
			placeholder: placeholder,
			onFocus: this.handleFocus,
			onSelect: this.makeListVisible,
			onMouseDown: this.makeListVisible,
			ref: (input) => (this.input = input),
			onChange: (e) => this.handleChange(e),
		}

		return (
			<div className={boxClass}>
				{!!title && <div className={titleClass}>{title}</div>}
				{component === 'textarea' ? (
					<textarea {...inputProps} />
				) : (
					<input {...inputProps} type='text' />
				)}
				<OptionsList
					suggestions={options}
					labelKey={optionLabelKey}
					visible={showOptions}
					onSelect={this.handleSelect}
					activeIndex={this.state.selected}
					optionsClassName={optionsClassName}
				/>
			</div>
		)
	}
}
