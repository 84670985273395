import { Component } from 'react'
import outsideClick from 'react-click-outside'
import cx from 'clsx'
import PropTypes from 'prop-types'

import { utils } from 'helpers'

import Option from './Option'

import './Select.scss'

/**
 * @param {boolean} multi - Если true переключается в режим мультиселекта
 */
class Select extends Component {
	static propTypes = {
		multi: PropTypes.bool,
		disabled: PropTypes.bool,
		labelKey: PropTypes.string,
		className: PropTypes.string,
		customTitle: PropTypes.any,
		optionsClassName: PropTypes.string,
		value: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.number,
			PropTypes.string,
			PropTypes.array, // в режиме мульти селекта
		]),
		defaultTitle: PropTypes.string,
		options: PropTypes.array.isRequired,

		onBlur: PropTypes.func,
		onChange: PropTypes.func.isRequired,
	}

	static defaultProps = {
		labelKey: 'label',
		defaultTitle: 'Выберите значение',
	}

	state = {
		showOptions: false,
		multiSelected: [], // Используется только в режиме multi = true
	}

	componentDidMount() {
		const { value, multi } = this.props

		if (utils.hasObjectLength(value) && multi) this.setState({ multiSelected: value })
	}

	static getDerivedStateFromProps(props, state) {
		if (props.value !== state.value && props.multi) {
			return {
				multiSelected: Array.isArray(props.value) ? props.value : [],
			}
		}

		return state
	}

	componentDidUpdate(prevProps) {
		const { multiSelected } = this.state
		const { value, multi } = this.props

		if (!utils.hasObjectLength(value) && utils.hasObjectLength(prevProps.value) && multi) {
			this.setState({ multiSelected: [] })
		}

		if (utils.hasObjectLength(value) && !utils.hasObjectLength(multiSelected)) {
			this.setState({ multiSelected: value })
		}
	}

	handleClickOutside() {
		const { showOptions } = this.state

		if (showOptions) this.setState({ showOptions: false })
	}

	handleSelect = (itemOption) => {
		const { onChange, multi } = this.props

		if (!multi) {
			this.setState({ showOptions: false })
			onChange(itemOption)
		} else this.toggleOptionsToMultiSelected(itemOption)
	}

	toggleOptionsToMultiSelected = (option) => {
		const { onChange } = this.props
		const { multiSelected } = this.state
		const newMultiSelected = Array.from(multiSelected)

		const optionHasAdded = multiSelected.findIndex((item) => item.value === option.value)

		if (optionHasAdded + 1) newMultiSelected.splice(optionHasAdded, 1)
		else newMultiSelected.push(option)

		this.setState({ multiSelected: newMultiSelected })
		onChange(newMultiSelected)
	}

	toggleOpenSelect = () => {
		const { showOptions } = this.state

		this.setState({ showOptions: !showOptions })
	}

	renderSelectedTitle() {
		const { multiSelected } = this.state
		const { defaultTitle, options, value, labelKey, multi, customTitle } = this.props

		const selectedOption = (options || []).find((option) => option.id === value)

		if (!multi && selectedOption)
			return <div className='select-box__title'>{customTitle || selectedOption[labelKey]}</div>
		else if (multi && utils.hasObjectLength(multiSelected)) {
			const title = multiSelected.map((item) => item.label).join(', ')

			return <div className='select-box__title'>{title}</div>
		} else return <div className={cx('select-box__title', 'select-box-empty')}>{defaultTitle}</div>
	}

	render() {
		const { multiSelected } = this.state
		const {
			options,
			labelKey,
			className,
			title,
			value,
			onBlur,
			multi,
			disabled,
			optionsClassName,
		} = this.props

		const { showOptions } = this.state

		const selectClassName = cx('select', {
			showOptions,
			[className]: !!className,
			disabled: disabled || !options || !utils.hasObjectLength(options),
		})

		return (
			<div className={selectClassName} tabIndex={1} onBlur={onBlur}>
				{title && <div className='select-box__label-title'>{title}</div>}
				<div className='select-box' onClick={this.toggleOpenSelect}>
					{this.renderSelectedTitle()}
				</div>
				{showOptions && utils.hasObjectLength(options) && options && (
					<div className={cx('select-options', { [optionsClassName]: !!optionsClassName })}>
						<div className='select-options__list'>
							{options.map((item, index) => (
								<Option
									item={item}
									key={index}
									multi={multi}
									value={value}
									labelKey={labelKey}
									multiSelected={multiSelected}
									onSelectOption={this.handleSelect}
								/>
							))}
						</div>
					</div>
				)}
			</div>
		)
	}
}

export default outsideClick(Select)
