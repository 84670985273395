import { Component } from 'react'
import ReactAudioPlayer from 'react-audio-player'
import PropTypes from 'prop-types'

import './ViewAudio.scss'

class ViewAudio extends Component {
	static propTypes = {
		files: PropTypes.array.isRequired,
	}

	render() {
		const { files } = this.props

		return (
			<div className='files-viewer-audio'>
				<ReactAudioPlayer src={files[0].src} autoPlay controls />
			</div>
		)
	}
}

export default ViewAudio
