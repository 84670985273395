import { CALCULATION } from 'const/calculations'

import { DefaultValues } from 'helpers/convertHelper'

export const floatingByRoe = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		type: 'FLOATING_BY_ROE' as const,
		unit: model.const(data?.unit, CALCULATION.UNIT.ENG),
		unitRus: model.const(data?.unit, CALCULATION.UNIT.RUS),
		payrollPercent: model.null<number>(['payrollPercent']),
		netIncomePercent: model.null<number>(['netIncomePercent']),
		rosreestrCosts: model.null<number>(['rosreestrCosts']),
		fixedCosts: model.null<number>(['fixedCosts']),
		referenceValue: model.null<number>(['referenceValue']),
		maximumInterestRate: model.null<number>(['maximumInterestRate']),
	}
}
