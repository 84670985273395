import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

interface State {
	data: Record<string, unknown>
	fetching: boolean
}

const initialState: State = {
	data: {},
	fetching: false,
}

const microsoft = createSlice({
	name: 'microsoft',
	initialState,
	reducers: {
		update(state, { payload }: PayloadAction<Partial<State>>) {
			state = { ...state, ...payload }
			return state
		},
	},
})

export const { actions } = microsoft
export default microsoft.reducer
