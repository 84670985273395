import { addServerError } from 'actions/serverErrors'
import api from 'api'
import * as actionTypes from 'const/actionTypes'
import converters from 'converters'
import { isEmpty } from 'ramda'

import { utils } from 'helpers'

import { addFiles, deleteFile } from './files'
import { formatForm } from './helpers'

const setFetchingStatus = (status) => ({
	type: actionTypes.DOCUMENTS_CATEGORIES_SET_FETCHING_STATUS,
	status,
})

const setData = (data) => ({
	type: actionTypes.DOCUMENTS_CATEGORIES_SET_DATA,
	data,
})

const add = (data) => ({
	type: actionTypes.DOCUMENTS_CATEGORIES_ADD_CATEGORY,
	data,
})

export const setDocsInCategories =
	(documentId, ...document) =>
	(dispatch) => {
		dispatch(deleteFile(documentId))
		dispatch(addFiles(document))
	}

export const documentSetEntityId = (entityId) => ({
	type: actionTypes.DOCUMENTS_CATEGORIES_SET_ENTITY_ID,
	entityId,
})

export const resetDocumentCategories = () => ({
	type: actionTypes.DOCUMENTS_CATEGORIES_RESET,
})

/**
 * @param {string[]} ownerTypes - массив сущностей
 * @param {bool} shouldUpdateStore - если данный флаг true то работа с данными будет производиться в отношении глобального контейнера
 * если нет то функция вернёт результат
 */
export const fetchCategories =
	(ownerTypes, shouldUpdateStore = true) =>
	async (dispatch) => {
		if (shouldUpdateStore) dispatch(setFetchingStatus(true))

		try {
			const { data } = await api.document.getCategories({
				filter: {
					ownerTypes,
					out: 'ROSREESTR_DOCUMENTS',
				},
			})
			const {
				data: { content: labelsOfGroups },
			} = await api.document.findLabels(ownerTypes)

			if (isEmpty(labelsOfGroups)) {
				throw new Error('Не найдены лейблы для категорий документов')
			}

			const response = converters.documents.categories.getCategories(data, labelsOfGroups)

			if (shouldUpdateStore) {
				dispatch(setData(response))
				dispatch(setFetchingStatus(false))
			} else {
				return response
			}
		} catch (error) {
			dispatch(
				addServerError({
					text: error?.message || `Ошибка загрузки категорий в сущность ${ownerTypes.join(',')}`,
					details: utils.getDetailsFromError(error),
				})
			)

			if (shouldUpdateStore) dispatch(setFetchingStatus(false))
		}
	}

export const updateMetadata = (documentId, title, metadata) => (dispatch) => {
	return api.document
		.updateMetadata(documentId, formatForm(title, metadata))
		.then(() => {
			;(async () => {
				const { data } = await api.document.get(documentId)
				dispatch(setDocsInCategories(documentId, converters.documents.files.file(data)))
			})()
		})
		.catch((error) => {
			dispatch(
				addServerError({
					text: `Ошибка при обновлении данных документа`,
					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		})
}

const postMoveDocument = (documentId, request) => (dispatch) => {
	return api.document
		.replace(documentId, request)
		.then(async () => {
			const { data: file } = await api.document.get(documentId)

			dispatch(setDocsInCategories(documentId, file))

			return file
		})
		.catch((error) => {
			dispatch(
				addServerError({
					text: 'Ошибка переноса документов между категориями, изменения не будут сохранены',
					details: utils.getDetailsFromError(error),
				})
			)
		})
}

const moveDocuments = (destination, draggableId) => (dispatch, getState) => {
	const { categories } = getState().document

	return dispatch(
		postMoveDocument(draggableId, {
			categoryId: destination.droppableId,
			newOwnerId: categories.entityId,
			ownerId: categories.entityId,
		})
	)
}

export const onDragStart =
	({ source }) =>
	() => {
		if (!source) return
	}

export const onDragEnd =
	({ source, destination, draggableId }) =>
	async (dispatch) => {
		if (!destination || !source) return
		if (source.droppableId === destination.droppableId) return

		try {
			const response = await dispatch(moveDocuments(destination, draggableId))

			return response
		} catch {
			//
		}
	}
