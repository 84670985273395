import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Facility } from 'converters/facility'
import { commonActions } from 'reducers/common'
import type { TUtils } from 'types'

const initialState = {
	data: null as TUtils.Maybe<Facility.Main>,
	fetching: false,
	statementFormValues: {},
	rosreestrStatements: [],
}

const single = createSlice({
	name: 'facility/single',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Facility.Main>) {
			state.data = payload
		},

		setField(
			state,
			{ payload: { key, value } }: PayloadAction<{ key: keyof typeof initialState; value: any }>
		) {
			state[key] = value
		},

		setInData(
			state,
			{ payload: { key, value } }: PayloadAction<{ key: keyof Facility.Main; value: any }>
		) {
			if (state.data) {
				// @ts-ignore
				state.data[key] = value
			}
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

const { actions } = single

export { actions }
export default single.reducer
