import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { ExternalDataTypes } from 'const/externalData'
import type { ExternalData } from 'converters/externalData'

const initialState = {
	person: [] as ExternalData.Main[],
	organization: [] as ExternalData.Main[],
	entrepreneur: [] as ExternalData.Main[],
	fetchingStatuses: {} as Record<ExternalDataTypes, boolean>,
}

export type StateKey = 'person' | 'organization' | 'entrepreneur'

type DataPayloadType = PayloadAction<{
	key: StateKey
	data: ExternalData.Main[]
}>

type UpdatePayloadType = PayloadAction<{
	key: StateKey
	data: any
}>

type SetStatusPayload = PayloadAction<{
	type: ExternalDataTypes
	data: boolean
}>

const externalDataSlice = createSlice({
	name: 'externalData',
	initialState,
	reducers: {
		setData(state, actionPayload: DataPayloadType) {
			state[actionPayload.payload.key] = actionPayload.payload.data
		},

		add(state, actionPayload: DataPayloadType) {
			state[actionPayload.payload.key].push(...actionPayload.payload.data)
		},

		updateData(state, { payload }: UpdatePayloadType) {
			state[payload.key] = state[payload.key].map((el) => {
				return payload.data.id === el.id ? payload.data : el
			})
		},

		setFetching(state, { payload }: SetStatusPayload) {
			state.fetchingStatuses[payload.type] = payload.data
		},

		resetExternalData() {
			return initialState
		},
	},
})

export const { setData, setFetching, add, resetExternalData, updateData } =
	externalDataSlice.actions
export default externalDataSlice.reducer
