import { cloneElement, useContext } from 'react'
import { Tab, TabList, TabPanel, Tabs as TabsReact } from 'react-tabs'
import PropTypes from 'prop-types'

import { CurrentTabContext } from 'components/common/EntityContainer/TabContext'

import './Tabs.scss'

const Tabs = ({
	items,
	defaultTabId = 0,
	tabsProps = {},
	tabListProps = {},
	tabPanelProps = {},
}: any) => {
	const { setCurrentTab } = useContext(CurrentTabContext)

	if (defaultTabId !== 0) {
		const index = items.findIndex(({ id } = {} as any) => id === defaultTabId)

		if (index !== -1) {
			tabsProps['defaultIndex'] = index
		}
	}

	const handleClickOnTab = (item: any) => () => setCurrentTab(item?.title)

	return (
		<TabsReact {...tabsProps}>
			<TabList {...tabListProps}>
				{items.filter(Boolean).map(
					(item: any, index: number) =>
						item.customEl ?? (
							<Tab
								key={index}
								onClick={handleClickOnTab(item)}
								disabled={!item.active}
								data-test-id={item?.['data-test-id']}
							>
								{item.title}
							</Tab>
						)
				)}
			</TabList>
			{items
				.filter((item: any) => item && !item.customEl)
				.map((item: any, index: number) => (
					<TabPanel key={index} {...tabPanelProps}>
						{cloneElement(item.component, item?.componentProps && { ...item.componentProps })}
					</TabPanel>
				))}
		</TabsReact>
	)
}

Tabs.defaultProps = {
	items: [],
}

Tabs.propTypes = {
	items: PropTypes.array.isRequired,
}

export default Tabs
