import { DefaultValues } from 'helpers/convertHelper'

export const owner = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		part: model.null<number>(['part']),
		type: model.string<'PERSON' | 'ORGANIZATION'>(['type']),
		roles: model.array<string[]>(['roles']),
		startDate: model.string<string>(['startDate']),
		endDate: model.string<string>(['endDate']),
		$$type: model.string<'PERSON' | 'ORGANIZATION'>(['type']),
	}
}
