import { DefaultValues } from 'helpers/convertHelper'

export const marriageInfo = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		marriageDate: model.string(['marriageDate']),
		divorceDate: model.string(['divorceDate']),
		spouseId: model.string(['spouseId']),
	}
}
