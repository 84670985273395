import axios from 'axios'
import type { CreditPolicyReport } from 'converters/creditPolicyReport'
import { creditPolicyReport } from 'converters/creditPolicyReport'

const baseUrl = '/v4/scoring'

declare namespace CreditPolicyReportsApi {
	export type Search = CreditPolicyReport.Main
	export type GetTitlePart = CreditPolicyReport.TitlePart
	export type UpdatePolicy = void
	export type GetAdditionalRequests = CreditPolicyReport.AdditionalRequest[]
	export type ChangeAdditionalRequests = void
}

interface EntityParameters {
	entityId: string
	entity: 'applications' | 'leads'
}

interface GetTitlePartParameters extends EntityParameters {
	creditPolicyId: string
}

interface DownloadParameters extends EntityParameters {
	creditPolicyId: string
}

interface RefinancingParameters extends EntityParameters {
	creditPolicyId: string
}

interface UpdatePolicyParameters extends EntityParameters {
	creditPolicyIds: string[]
}

interface ChangeAdditionalRequests extends EntityParameters {
	payload: {
		update: Record<string, any>[]
		create: Record<string, any>[]
		delete: Record<string, any>[]
	}
}

export default {
	search: ({ entityId, entity }: EntityParameters) => {
		return axios.get<CreditPolicyReportsApi.Search>(
			`${baseUrl}/${entity}/${entityId}/credit-policy-reports`,
			{ transformResponse: [(response) => creditPolicyReport.main(JSON.parse(response))] }
		)
	},

	download: ({ creditPolicyId, entity, entityId }: DownloadParameters) =>
		axios.get<Blob>(`${baseUrl}/${entity}/${entityId}/credit-policy-reports/${creditPolicyId}`, {
			responseType: 'blob',
		}),

	updatePolicy: ({ entityId, entity, creditPolicyIds }: UpdatePolicyParameters) =>
		axios.post<CreditPolicyReportsApi.UpdatePolicy>(
			`${baseUrl}/${entity}/${entityId}/checked-credit-policies`,
			{ creditPolicyIds }
		),

	refinance: ({ entityId, entity, creditPolicyId }: RefinancingParameters) =>
		axios.post<CreditPolicyReportsApi.UpdatePolicy>(
			`${baseUrl}/${entity}/${entityId}/credit-policy-reports/${creditPolicyId}/refinancing`
		),

	changeAdditionalRequests: ({ entityId, entity, payload }: ChangeAdditionalRequests) =>
		axios.post<CreditPolicyReportsApi.ChangeAdditionalRequests>(
			`${baseUrl}/additional-requests`,
			payload,
			{
				params: { entityType: { applications: 'APPLICATION', leads: 'LEAD' }[entity], entityId },
			}
		),

	getAdditionalRequests: ({ entityId, entity }: EntityParameters) =>
		axios
			.get<CreditPolicyReportsApi.GetAdditionalRequests>(`${baseUrl}/additional-requests`, {
				params: { entityType: { applications: 'APPLICATION', leads: 'LEAD' }[entity], entityId },
			})
			.then((response) =>
				Object.assign({}, response, {
					data: response.data.map(creditPolicyReport.additionalRequest),
				})
			),

	getTitlePart: ({ entityId, entity, creditPolicyId }: GetTitlePartParameters) =>
		axios
			.get<CreditPolicyReportsApi.GetTitlePart>(
				`${baseUrl}/${entity}/${entityId}/credit-policy-reports/${creditPolicyId}/title-part`
			)
			.then((response) =>
				Object.assign({}, response, {
					data: creditPolicyReport.titlePart(response.data),
				})
			),
}
