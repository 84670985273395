import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { PartnerDto } from 'converters/partner'
import { commonActions } from 'reducers/common'
import type { PayloadStatus, RootState } from 'types/redux'

const partnerProductViewAdapter = createEntityAdapter<PartnerDto.ProductView>({
	selectId: ({ product }) => product,
})

export const selectPartnerProductView = partnerProductViewAdapter.getSelectors(
	(state: RootState) => state.partner.productView
)

const initialState = partnerProductViewAdapter.getInitialState({
	status: 'pending' as PayloadStatus,
})

const productView = createSlice({
	name: 'partner/productView',
	initialState,
	reducers: {
		setAll: partnerProductViewAdapter.setAll,
		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions: partnerProductViewReducerActions } = productView
export default productView.reducer
