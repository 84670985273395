import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Person } from 'converters/person'
import { commonActions } from 'reducers/common'
import type { Maybe } from 'types/util'

export interface PersonFilter {
	query: Maybe<string>
	ids: Maybe<string[]>
	phone: Maybe<string>
	taxId: Maybe<string>
}

const filter: PersonFilter = {
	query: null,
	ids: null,
	phone: null,
	taxId: null,
}

const initialState = {
	data: [] as Person.Main[],
	fetching: false,
	filter,
}

const list = createSlice({
	name: 'person/list',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Person.Main[]>) {
			state.data = payload
		},

		update(state, { payload }: PayloadAction<{ id: string; value: Partial<Person.Main> }>) {
			const i = state.data.findIndex(({ id }) => id === payload.id)

			state.data[i] = { ...state.data[i], ...payload.value }
		},

		replace(state, { payload }: PayloadAction<{ id: string; value: Person.Main }>) {
			const i = state.data.findIndex(({ id }) => id === payload.id)

			state.data[i] = payload.value
		},

		delete(state, { payload }: PayloadAction<string>) {
			state.data = state.data.filter(({ id }) => id !== payload)
		},

		add(state, { payload }: PayloadAction<Person.Main>) {
			state.data.push(payload)
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		setFilter: commonActions.setFilter(),
		reset: commonActions.reset(initialState),
	},
})

const { actions } = list

export { actions }
export default list.reducer
