import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Loan } from 'converters/loan'
import { commonActions } from 'reducers/common'
import type { TUtils } from 'types'
import type { PayloadStatus } from 'types/redux'

const initialState = {
	data: null as TUtils.Maybe<Loan.Collection>,
	status: 'pending' as PayloadStatus,
}

const collection = createSlice({
	name: 'loan/collection',
	initialState,
	reducers: {
		setData: (state, { payload }: PayloadAction<TUtils.Maybe<Loan.Collection>>) => {
			state.data = payload
		},

		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions: loanCollectionActions } = collection
export default collection.reducer
