import { DefaultValues } from 'helpers/convertHelper'

export const operationDetail = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		amount: model.null<number>(['version']),
		type: model.const(data.type, {
			MAIN_DEBT: 'Основной долг',
			INTEREST: 'Проценты',
			MEMBERSHIP_FEE: 'Членские взносы',
			PENALTIES: 'Пени',
			COMMISSION: 'Комиссии',
			FINES: 'Штрафы',
		} as const),
	}
}
