import { URLBuilder } from 'api/helpers'
import type { Options } from 'api/types/Options'
import type { Pageable } from 'api/types/Pageable'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type {
	ExternalDataFilter,
	ExternalDataTypes,
	RequestType,
	SubjectTypes,
} from 'const/externalData'
import type { ExternalData } from 'converters/externalData'
import { externalData } from 'converters/externalData'
import { mergeDeepRight } from 'ramda'

import type { EntityId } from './types/EntityId'

export default {
	search: ({ filter, size, sort, page }: Options<ExternalDataFilter>) => {
		const params = new URLBuilder()

		if (filter) {
			params
				.addFilter('subject.id', filter.subject?.id, 'eq')
				.addFilter('subject.type', filter.subject?.type, 'inList')
				.addFilter('type', filter.type, 'inList')
		}

		return axios
			.get('/v1/external-data/requests', {
				$$requestName: 'external-data.search',
				params: {
					sort,
					size,
					page,
					filter: params.build('RSQL'),
				},
			})
			.then(
				(response) =>
					mergeDeepRight(response, {
						data: {
							content: response.data.content.map(externalData.main),
						},
					}) as AxiosResponse<Pageable<ExternalData.Main>>
			)
	},

	getRequest: (id: string) =>
		axios.get(`/v1/external-data/requests/${id}`).then(
			(response) =>
				Object.assign(
					{},
					{
						data: externalData.main(response.data),
					}
				) as AxiosResponse<ExternalData.Main>
		),

	getRequestType: (params: { subject: string; type: string[] | string }) =>
		axios.get<Pageable<RequestType>>(`/v1/external-data/request-types`, { params }),

	createRequest: (
		type: ExternalDataTypes,
		subject: {
			id: string
			type: SubjectTypes
		}
	) =>
		axios.post(`/v1/external-data/requests`, { subject, type }).then((response) =>
			Object.assign({}, {
				data: externalData.main(response.data),
			} as AxiosResponse)
		),

	update: (id: EntityId, data: Record<string, any>) =>
		axios.patch(`/v1/external-data/requests/${id}`, data, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),

	fill: (id: EntityId) => axios.post(`/v1/external-data/requests/${id}/retry`),
}
