import { DefaultValues } from 'helpers/convertHelper'

export const dependent = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		status: model.string(['status']),
		birthDate: model.string(['birthDate']),
	}
}
