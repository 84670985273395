import { combineReducers } from 'redux'

import list from './list'
import managers from './managers'
import single from './single'

export default combineReducers({
	list,
	single,
	managers,
})
