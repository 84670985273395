import { addServerError } from 'actions/serverErrors'
import api from 'api'
import type { ExternalDataTypes, SubjectTypes } from 'const/externalData'
import { EXTERNAL_REQUEST_TYPE } from 'const/externalData'
import type { ExternalData } from 'converters/externalData'
import { mergeDeepRight } from 'ramda'
import type { StateKey } from 'reducers/externalData'
import { add, setData, setFetching, updateData } from 'reducers/externalData'
import type { TAction } from 'types/redux'

import { utils } from 'helpers'

interface SearchRequestProps {
	entityId: string
	entityType: SubjectTypes
	requestTypes: ExternalDataTypes[]
	dataBehavior?: 'push' | 'update' | 'set'
	size?: number
}

export const searchExternalRequest =
	({
		entityId,
		entityType,
		requestTypes,
		dataBehavior = 'set',
		size = 20,
	}: SearchRequestProps): TAction<Promise<void>> =>
	async (dispatch, getState) => {
		try {
			requestTypes.forEach((type) => {
				dispatch(
					setFetching({
						type,
						data: true,
					})
				)
			})

			const { data } = await api.externalData.search({
				filter: {
					subject: {
						id: entityId,
						type: entityType,
					},
					type: requestTypes,
				},
				page: 0,
				size,
				sort: 'createdAt,desc',
			})

			const oldData = getState().externalData[entityType.toLowerCase() as StateKey] || []

			switch (dataBehavior) {
				case 'update':
					dispatch(
						setData({
							key: entityType.toLowerCase() as StateKey,
							data: oldData.map((el) => {
								const newData = data.content.find((newEl: { id: string }) => newEl.id === el.id)
								return newData ? newData : el
							}),
						})
					)
					break
				case 'push':
					dispatch(
						add({
							key: entityType.toLowerCase() as StateKey,
							data: data.content,
						})
					)
					break
				case 'set':
					dispatch(
						setData({
							key: entityType.toLowerCase() as StateKey,
							data: data.content,
						})
					)
			}

			requestTypes.forEach((type) => {
				dispatch(
					setFetching({
						type,
						data: false,
					})
				)
			})
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось найти внешние запросы',
					details: utils.getDetailsFromError(error),
				})
			)

			requestTypes.forEach((type) => {
				dispatch(
					setFetching({
						type,
						data: false,
					})
				)
			})
		}
	}

interface GetRequestProps {
	id: string
	entityType: SubjectTypes
	requestType: ExternalDataTypes
}

export const getExternalRequest =
	({ id, entityType, requestType }: GetRequestProps): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			dispatch(
				setFetching({
					type: requestType,
					data: true,
				})
			)

			const { data } = await api.externalData.getRequest(id)

			dispatch(
				updateData({
					key: entityType.toLowerCase() as StateKey,
					data: data,
				})
			)
			dispatch(
				setFetching({
					type: requestType,
					data: false,
				})
			)
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось получить внешний запрос',
					details: utils.getDetailsFromError(error),
				})
			)
			dispatch(
				setFetching({
					type: requestType,
					data: false,
				})
			)
		}
	}

interface CreateRequestProps {
	entityId: string
	entityType: SubjectTypes
	requestType: ExternalDataTypes
}

export const createExternalRequest =
	({
		entityId,
		entityType,
		requestType,
	}: CreateRequestProps): TAction<Promise<ExternalData.Main>> =>
	async (dispatch) => {
		try {
			dispatch(
				setFetching({
					type: requestType,
					data: true,
				})
			)

			const { data } = await api.externalData.createRequest(requestType, {
				id: entityId,
				type: entityType,
			})

			dispatch(
				add({
					key: entityType.toLowerCase() as StateKey,
					data: [data],
				})
			)
			dispatch(
				setFetching({
					type: requestType,
					data: false,
				})
			)

			return data
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось создать внешний запрос',
					details: utils.getDetailsFromError(error),
				})
			)

			dispatch(
				setFetching({
					type: requestType,
					data: false,
				})
			)
		}
	}

interface UpdateRequestProps {
	id: string
	entityType: SubjectTypes
	form: Record<string, any>
	requestType: ExternalDataTypes
}

export const updateExternalRequest =
	({ id, entityType, form, requestType }: UpdateRequestProps): TAction<Promise<void>> =>
	async (dispatch, getState) => {
		try {
			dispatch(
				setFetching({
					type: requestType,
					data: true,
				})
			)
			const oldData =
				getState().externalData[entityType.toLowerCase() as StateKey].find((el) => el.id === id) ||
				{}

			await api.externalData.update(id, form)

			dispatch(
				updateData({
					key: entityType.toLowerCase() as StateKey,
					data: mergeDeepRight(oldData, {
						...form,
						responseData: {
							[form.type]: {
								...form.responseData,
							},
						},
					}),
				})
			)
			dispatch(
				setFetching({
					type: requestType,
					data: false,
				})
			)
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось обновить внешний запрос',
					details: utils.getDetailsFromError(error),
				})
			)

			dispatch(
				setFetching({
					type: requestType,
					data: false,
				})
			)
		}
	}

export const fillCreditHistory =
	(id: string, entityType: SubjectTypes): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			await api.externalData.fill(id)
			await dispatch(
				getExternalRequest({
					id,
					entityType,
					requestType: EXTERNAL_REQUEST_TYPE.CREDIT_HISTORY,
				})
			)
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось повторить запрос',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}
