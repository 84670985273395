import moment from 'moment'

import { take } from '@creditclubteam/helpers'
import { defaultToApi } from 'helpers/convertHelper'

export const external = (form) => {
	const activeTo = take(form, 'activeTo', null)
	const activeFrom = take(form, 'activeFrom', null)

	return {
		signer: {
			customerId: 'CREDIT',
			customerType: 'EXTERNAL',
		},
		context: {
			contextId: defaultToApi(form?.context?.id),
			contextType: defaultToApi(form?.context?.type),
		},
		activeTo: activeTo ? moment(activeTo).format('YYYY-MM-DD') : null,
		activeFrom: activeFrom ? moment(activeFrom).format('YYYY-MM-DD') : null,
		documentId: defaultToApi(form?.documentId),
		signatureApplication: defaultToApi(form?.signatureApplication),
	}
}

export const addDocumentToSign = (form) => {
	return {
		signatureApplication: defaultToApi(form?.signatureApplication),
		signer: {
			customerId: defaultToApi(form?.signer?.customerId),
			customerType: defaultToApi(form?.signer?.customerType),
		},
		context: {
			contextId: defaultToApi(form?.context?.id),
			contextType: defaultToApi(form?.context?.type),
		},
		documents: defaultToApi(form?.documents),
	}
}

export const sendSms = (form) => {
	return {
		signer: {
			customerId: defaultToApi(form?.signer?.customerId),
			customerType: defaultToApi(form?.signer?.customerType),
		},
		context: {
			contextId: defaultToApi(form?.context?.id),
			contextType: defaultToApi(form?.context?.type),
		},
		phone: defaultToApi(form?.signer?.phone),
	}
}

export const confirmSms = (form) => {
	return {
		signer: {
			customerId: defaultToApi(form?.signer?.customerId),
			customerType: defaultToApi(form?.signer?.customerType),
		},
		context: {
			contextId: defaultToApi(form?.context?.id),
			contextType: defaultToApi(form?.context?.type),
		},
		transactionId: defaultToApi(form?.transactionId),
		code: defaultToApi(form?.code),
		personId: defaultToApi(form?.signer?.customerId),
	}
}
