import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { InterestPayment } from 'converters/interestPayment'

type SetDataAction = PayloadAction<Array<InterestPayment.Main>>

const initialState = {
	data: [] as InterestPayment.Main[],
	isFetching: false,
}

const interestPaymentSlice = createSlice({
	name: 'interestPayment',
	initialState,
	reducers: {
		setData(state, { payload }: SetDataAction) {
			state.data = payload
		},

		setFetching(state, actionPayload) {
			state.isFetching = actionPayload.payload
		},

		reset(_state) {
			_state = initialState
		},
	},
})

export const { setData, setFetching, reset } = interestPaymentSlice.actions
export default interestPaymentSlice.reducer
