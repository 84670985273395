import type { court as courtConverter } from './court'
import { main } from './main'

export const court = {
	main,
}

export namespace Court {
	export type Main = ReturnType<typeof main>
	export type Court = ReturnType<typeof courtConverter>
}
