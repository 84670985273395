import { Component } from 'react'
import { connect } from 'react-redux'
import cx from 'clsx'
import PropTypes from 'prop-types'
import { flatten } from 'ramda'
import { isEmpty } from 'ramda'

import './UploadProgress.scss'

class UploadProgress extends Component {
	static propTypes = {
		uploader: PropTypes.object.isRequired,
	}

	state = { active: false }

	componentDidUpdate(prevProps) {
		const { uploader } = this.props

		if (!isEmpty(uploader) && isEmpty(prevProps.uploader)) {
			setTimeout(() => this.setState({ active: true }), 50)
		} else if (isEmpty(uploader) && !isEmpty(prevProps.uploader)) {
			this.setState({ active: false })
		}
	}

	render() {
		const { active } = this.state
		const { uploader } = this.props

		const files = flatten(Object.values(uploader).map((a) => a.files))

		const progress = files.reduce((acc, cur) => (acc += cur.uploadProgress), 0)
		const countUploadSuccessFiles = files.reduce(
			(acc, cur) => (cur.isUploadSuccess ? ++acc : acc),
			0
		)

		if (!isEmpty(uploader))
			return (
				<div className={cx('upload-progress', { active })}>
					<div className='upload-progress-text'>
						Загружено файлов {countUploadSuccessFiles} из {files.length}
					</div>
					<div style={{ width: `${progress / files.length}%` }} className='upload-progress-bar' />
				</div>
			)

		return null
	}
}

const mapStateToProps = (state) => ({
	...state.uploadFiles,
})

export default connect(mapStateToProps)(UploadProgress)
