import { addServerError } from 'actions/serverErrors'
import api from 'api'
import axios from 'axios'
import moment from 'moment'
import { actions } from 'reducers/calendar/events'
import type { TAction } from 'types/redux'
import type { Maybe } from 'types/util'

import { utils } from 'helpers'

import { mutateEventsForCalendar } from './helpers'

export const setTooltip = actions.setTooltip
export const updateEventInList = actions.updateSingle
export const deleteEventInList = actions.deleteSingle
export const deleteAllEventInstances = actions.deleteAllInstances
export const resetEvents = actions.reset
export const updateEventsList = actions.updateList

export const getEvents =
	(dates: { start?: Maybe<Date>; end?: Maybe<Date> }): TAction =>
	async (dispatch) => {
		const datesRange = dates || getVisibleDates()

		// Сбрасываем все события для плавных переходов
		dispatch(actions.reset())

		// Записываем диапазон дат
		dispatch(actions.setDateRange(datesRange))

		// Собираем корректно обьект интервалов дат, аргумент datesRange
		if (!datesRange) throw new Error('dates range required')
		const range = formatRangeDates(datesRange)

		dispatch(actions.setFetchingStatus(true))

		return api.calendar
			.getEvents(range.start, range.end)
			.then(({ data }) => {
				dispatch(actions.setList(mutateEventsForCalendar(...data.value)))
			})
			.catch((error) => {
				console.error(error)
				// Если ошибка произошла из за сброса
				if (axios.isCancel(error)) return

				dispatch(
					addServerError({
						details: utils.getDetailsFromErrorMsal(error),
						text: 'Ошибка загрузки событий календаря',
					})
				)
			})
			.finally(() => dispatch(actions.setFetchingStatus(false)))
	}

export const showTooltip =
	(event: Record<string, any>, e: Record<string, any>): TAction =>
	(dispatch) => {
		const tooltip = {
			event,
			position: {
				pageX: e.pageX,
				pageY: e.pageY,
			},
		}

		setTimeout(() => dispatch(setTooltip(tooltip)), 0)
	}

interface Dates {
	start?: Maybe<Date>
	end?: Maybe<Date>
}
// helpers
export const formatRangeDates = (rangeDates: Array<Date> | Dates) => {
	const results: {
		start?: Maybe<Date | string>
		end?: Maybe<Date | string>
	} = {}

	// Если получили обьект с ним всё просто, там уже разложено
	if (!Array.isArray(rangeDates)) {
		results.end = rangeDates.end
		results.start = rangeDates.start
	}

	// Если получили массив с интервалом берём первый и последний
	else if (Array.isArray(rangeDates) && rangeDates.length > 1) {
		results.start = rangeDates[0]
		results.end = rangeDates[rangeDates.length - 1]
	}

	// Если получили массив с одной датой, так бывает когда выбран один день
	// то сами докручиваем этот день до конца
	else if (Array.isArray(rangeDates) && rangeDates.length === 1) {
		const endDate = new Date(rangeDates[0])

		endDate.setHours(endDate.getHours() + 23)
		endDate.setMinutes(endDate.getMinutes() + 59)
		endDate.setSeconds(endDate.getSeconds() + 59)

		results.end = endDate
		results.start = rangeDates[0]
	}

	// Преобразуем даты в ISO формат
	for (const range in results) {
		const key = range as keyof Dates
		results[key] = (results[key]! as Date).toISOString().replace('Z', '')
	}

	return results
}

function getVisibleDates() {
	const date = new Date()

	return {
		start: moment(date).startOf('month').startOf('week'),
		end: moment(date).endOf('month').endOf('week'),
	}
}
