import { DefaultValues } from 'helpers/convertHelper'

import { fullName } from './fullName'
import { phoneNumber } from './phoneNumber'

export const contactPerson = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		phoneNumber: phoneNumber(data?.phoneNumber),
		fullName: fullName(data?.fullName),
		relationType: model.const(data?.relationType, {
			HUSBAND: 'HUSBAND',
			WIFE: 'WIFE',
			COHABITANT: 'COHABITANT',
			BROTHER: 'BROTHER',
			SISTER: 'SISTER',
			FATHER: 'FATHER',
			MOTHER: 'MOTHER',
			SON: 'SON',
			DAUGHTER: 'DAUGHTER',
			GRANDFATHER: 'GRANDFATHER',
			GRANDMOTHER: 'GRANDMOTHER',
			GRANDSON: 'GRANDSON',
			GRANDDAUGHTER: 'GRANDDAUGHTER',
			NEPHEW: 'NEPHEW',
			NIECE: 'NIECE',
			OTHER: 'OTHER',
		}),
	}
}
