import clsx from 'clsx'
import { getFacilityInfo } from 'components/pages/RosreestrRegistration/Single/Tabs/Rosreestr/helpers'
import type {
	ROSREESTR_REGISTRATION,
	RosreestrOperationTypeData,
} from 'const/rosreestrRegistration'
import type { Facility as TFacility } from 'converters/facility'

import { useCreateRegistration } from './useCreateRegistration'

import styles from './Facility.module.scss'

interface Props {
	data: TFacility.Main
	type: keyof typeof ROSREESTR_REGISTRATION.TYPE
	operationTypeData: RosreestrOperationTypeData
}

export const Facility = ({ data, operationTypeData, type }: Props) => {
	const { info, address } = getFacilityInfo(data)

	const { text, onClick, disabled } = useCreateRegistration({
		operationTypeData,
		facility: { info, address: address!, id: data.id },
		type,
	})

	return (
		<div className={styles.wrap}>
			{info}.{' '}
			<button
				type='button'
				disabled={disabled}
				className={clsx(styles.btn, { [styles.disabled]: disabled })}
				onClick={onClick}
			>
				{text}
			</button>
		</div>
	)
}
