import { connect } from 'react-redux'
import { calendarEventForm } from 'actions/calendar'
import CALENDAR from 'const/calendar'
import PropTypes from 'prop-types'

import { Select } from 'components/common'

import './Toolbar.scss'

const Toolbar = ({ label, view, onNavigate, onView, calendarEventForm }) => {
	const selectViewsOptions = {
		value: view,
		options: CALENDAR.VIEWS_TYPES,
		onChange: ({ id }) => onView(id),
		className: 'calendar-toolbar__views-select',
	}

	return (
		<div className='calendar-toolbar'>
			<div className='calendar-toolbar__navs'>
				<div
					onClick={() => onNavigate('PREV')}
					className='calendar-toolbar__navs-nav calendar-toolbar__button'
				>
					<i className='zmdi zmdi-chevron-left' />
				</div>
				<div
					onClick={() => onNavigate('TODAY')}
					className='calendar-toolbar__navs-now-date calendar-toolbar__button'
				>
					Сегодня
				</div>
				<div
					onClick={() => onNavigate('NEXT')}
					className='calendar-toolbar__navs-nav calendar-toolbar__button'
				>
					<i className='zmdi zmdi-chevron-right' />
				</div>
			</div>
			<div className='calendar-toolbar__label'>{label}</div>
			<div className='calendar-toolbar__views'>
				<Select {...selectViewsOptions} />
			</div>
			<div onClick={calendarEventForm} className='calendar-toolbar__create-event' />
		</div>
	)
}

Toolbar.propTypes = {
	label: PropTypes.string.isRequired,
	view: PropTypes.string.isRequired,

	onView: PropTypes.func.isRequired,
	onNavigate: PropTypes.func.isRequired,
	calendarEventForm: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
	calendarEventForm,
}

export default connect(null, mapDispatchToProps)(Toolbar)
