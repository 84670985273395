import { DefaultValues } from 'helpers/convertHelper'

import { fileUpload } from './fileUpload'
import { linkButton } from './linkButton'
import { textBlock } from './textBlock'

const blockMapper = <T extends Record<string, any>>(block: T) => {
	switch (block.type) {
		case 'link-button':
			return linkButton(block)
		case 'text-block':
			return textBlock(block)
		case 'file-upload':
			return fileUpload(block)
		default:
			return block
	}
}

export const instructionStep = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		blocks: model.array(['blocks']).map<ReturnType<typeof blockMapper>>(blockMapper),
		title: model.string(['title']),
	}
}
