import { URLBuilder } from 'api/helpers'
import type { Pageable } from 'api/types/Pageable'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type { RosreestrRegistration } from 'converters/rosreestrRegistration'
import { rosreestrRegistration } from 'converters/rosreestrRegistration'
import type { RosreestrRegistrationTemplates } from 'converters/rosreestrRegistrationTemplate'
import { rosreestrRegistrationTemplates } from 'converters/rosreestrRegistrationTemplate'
import { mergeDeepRight } from 'ramda'
import type { RosreestrRegistrationFilter } from 'reducers/rosreestrRegistration/main'
import { comparison, eq, or } from 'rsql-builder'

import type { Options } from './types/Options'

const baseUrl = '/v1/rosreestr-registrations'
const templatesBaseUrl = '/v1/rosreestr-registrations/mortgage-issue/templates'

export default {
	search: ({
		filter,
		size,
		sort,
		direction,
		property,
		page,
		query,
	}: Options<RosreestrRegistrationFilter>) => {
		const params = new URLBuilder()

		if (filter || query) {
			params
				.addFilter('state', filter?.status, 'inList')
				.addFilter('type', filter?.type, 'inList')
				.addFilter('loanId', filter?.loanId, `eq`)
				.addFilter('applicationId', filter?.applicationId, `eq`)
				.addFilter('declarants.subject.id', filter?.participantId, 'eq')
				.addFilter('facilityId', filter?.facilityId, 'eq')
				.addFilter('', query, (value: string) =>
					or(comparison('operations.kuvd', eq(value)), comparison('contractNumber', eq(value)))
				)
		}

		return axios
			.get(baseUrl, {
				params: {
					size,
					sort: property && direction ? [property, direction].join(',') : sort || undefined,
					page,
					filter: params.build('RSQL'),
				},
			})
			.then(
				(response) =>
					mergeDeepRight(response, {
						data: {
							content: response.data.content.map(rosreestrRegistration.main),
						},
					}) as AxiosResponse<Pageable<RosreestrRegistration.Main>>
			)
	},

	get: (id: string) =>
		axios
			.get<RosreestrRegistration.Main>(`${baseUrl}/${id}`)
			.then((response) =>
				Object.assign({}, response, { data: rosreestrRegistration.main(response.data) })
			),

	create: (data: Record<string, any>) =>
		axios
			.post<RosreestrRegistration.Main>(baseUrl, data)
			.then((response) =>
				Object.assign({}, response, { data: rosreestrRegistration.main(response.data) })
			),

	createOperation: (id: string, data: Record<string, unknown>) =>
		axios
			.post<RosreestrRegistration.Main>(`${baseUrl}/${id}/operations`, data)
			.then((response) =>
				Object.assign({}, response, { data: rosreestrRegistration.main(response.data) })
			),

	delete: (registrationId: string, operationId: string) =>
		axios
			.delete<RosreestrRegistration.Main>(
				`/v1/rosreestr-registrations/${registrationId}/operations/${operationId}`
			)
			.then((response) =>
				Object.assign({}, response, { data: rosreestrRegistration.main(response.data) })
			),

	sendToRosreestr: (registrationId: string, operationId: string) =>
		axios
			.post<RosreestrRegistration.Main>(
				`/v1/rosreestr-registrations/${registrationId}/operations/${operationId}/send`
			)
			.then((response) =>
				Object.assign({}, response, { data: rosreestrRegistration.main(response.data) })
			),

	update: (id: string, data: Record<string, any>) =>
		axios.patch(`${baseUrl}/${id}`, data, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),

	compareStatements: (payload: Record<'docId1' | 'docId2', string>) =>
		axios.post<{ differences: Array<{ title: string; nodes: string[] }> }>(
			`${baseUrl}/statements/compare`,
			payload
		),

	templates: {
		search: ({ size = 100, page = 0, filter }: Options<{ enabled?: boolean }>) => {
			const params = new URLBuilder()

			if (filter) {
				params.addFilter('enabled', filter.enabled, 'eq')
			}

			return axios
				.get(templatesBaseUrl, {
					params: { size, page, filter: params.build('RSQL') },
				})
				.then(
					(response) =>
						mergeDeepRight(response, {
							data: {
								content: response.data.content.map(rosreestrRegistrationTemplates.main),
							},
						}) as AxiosResponse<Pageable<RosreestrRegistrationTemplates.Main>>
				)
		},
	},
}
