import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type { Representative } from 'converters/representative'
import { representative } from 'converters/representative'

export default {
	get: (id: string) =>
		axios.get(`/v2/admin/representatives/${id}`).then(
			(response) =>
				Object.assign({}, response, {
					data: representative.main(response.data),
				}) as AxiosResponse<Representative.Main>
		),

	search: (options: Record<string, any>) =>
		axios
			.get('/v2/admin/representatives', {
				params: {
					query: options.filter.query,
					city: options.filter.city,
					size: options.size,
					page: options.page,
					sort: options.sort,
				},
				$$requestName: 'representative.search',
			})
			.then(
				(response) =>
					Object.assign({}, response, {
						content: response.data.map(representative.main),
					}) as AxiosResponse<Array<Representative.Main>>
			),

	create: (data: Partial<Representative.Main>) =>
		axios.post(`/v2/admin/representatives`, data).then(
			(response) =>
				Object.assign({}, response, {
					data: representative.main(response.data),
				}) as AxiosResponse<Representative.Main>
		),

	update: (id: string, data: Partial<Representative.Main>) =>
		axios.patch(`/v2/admin/representatives/${id}`, data, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),
}
