import { isEmpty } from 'ramda'

export const sortTimeInOrder = (data) => {
	if (!isEmpty(data)) {
		return [...data].sort((c, n) => {
			let results = 0

			const cur = c.$$date.fullDate,
				next = n.$$date.fullDate
			;['getHours', 'getMinutes', 'getSeconds'].forEach((dateMethod) => {
				if (results === 0) {
					results =
						cur[dateMethod]() > next[dateMethod]()
							? 1
							: cur[dateMethod]() < next[dateMethod]()
							? -1
							: 0
				}
			})

			return results
		})
	}

	return []
}
