import { addServerError } from 'actions/serverErrors'
import api from 'api'
import axios from 'axios'
import { filter, map, prop, uniq } from 'ramda'
import { actions } from 'reducers/delivery/representative'
import type { TRedux, TUtils } from 'types'

import { utils } from 'helpers'

export const resetRepresentative = actions.reset

export const getRepresentatives =
	(ids: string[]): TRedux.TAction<Promise<void>> =>
	async (dispatch) => {
		dispatch(actions.setFetchingStatus(true))

		try {
			const response = await axios
				.all(
					uniq(ids)
						.filter(Boolean)
						.map((id: string) => api.delivery.getRepresentative(id).catch(() => null))
				)
				.then((data) => data.filter(Boolean) as NonNullable<TUtils.ArrayElement<typeof data>>[])

			const normalizedResponse = map(prop('data'), filter(Boolean, response))

			dispatch(actions.setData(normalizedResponse))
			dispatch(actions.setFetchingStatus(false))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить представителей',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const getRepresentative =
	(id: string): TRedux.TAction<Promise<void>> =>
	async (dispatch, getState) => {
		const isAlreadyAdded = !!getState().delivery.representative.entities[id]

		if (isAlreadyAdded) return

		dispatch(actions.setFetchingStatus(true))

		try {
			const { data } = await api.delivery.getRepresentative(id)

			dispatch(actions.addOne(data))
			dispatch(actions.setFetchingStatus(false))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить представителя',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}
