export const getWorkerProfile = (metadata, workersList) => {
	let title = 'Неизвестный',
		avatarUrl = null

	if (metadata.workerId) {
		const worker = workersList?.data.find((w) => w.id === metadata.workerId)

		if (worker) {
			title = `${worker.surname} ${worker.name}`

			if (worker.avatar) {
				avatarUrl = worker.avatar
			}
		}
	} else if (metadata.author) {
		title = metadata.author
	}

	return {
		title,
		avatar: avatarUrl && <img alt={title} src={avatarUrl} />,
	}
}

export const extractMention = (text, workersList) => {
	const regex = /<@([^>]+)>/g

	if (!text) return []

	const _text = text.split(regex)

	return _text.map((word) => {
		const worker = workersList.data.find(({ id }) => id === word)

		if (worker) {
			return worker
		}

		return word
	})
}
