import { pushCategory } from 'actions/filesInspector'
import { setModalShow } from 'actions/modalDialogs'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { actions } from 'reducers/filesInspector/addCategoryForm'

import { scrollToElement, utils } from 'helpers'

export const addFormReset = actions.reset
export const addFormSetField = actions.setField

export const postCategory = () => (dispatch, getState) => {
	dispatch(addFormSetField({ field: 'postProcessing', value: true }))

	const { title, comment, required, ownerTypes } = getState().filesInspector.addCategoryForm
	const category = { title, comment, required, ownerTypes }

	return api.document.createCategory(category).then(
		({ data }) => {
			if (utils.hasObjectLength(data)) dispatch(pushCategory({ ...data, $$files: [] }))
			dispatch(setModalShow(false, 'addCategory'))
			scrollToElement(data.id, true)
		},
		() => {
			dispatch(addFormSetField({ field: 'postProcessing', value: false }))
			dispatch(
				addServerError({
					text: 'Не удалось создать категорию',
				})
			)
		}
	)
}
