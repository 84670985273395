export const getCategories = ({ content }, labelsOfGroups) => {
	const normalizedCategoriesOfData = content.reduce((acc, categoryData) => {
		return {
			...acc,
			[categoryData.id]: categoryData,
		}
	}, {})

	const response = labelsOfGroups.reduce((acc, { blocks, ...restOfGroup }) => {
		blocks.forEach(({ categoryIds, title }) => {
			const categories = categoryIds.reduce((categories, id) => {
				if (normalizedCategoriesOfData[id]) categories.push(normalizedCategoriesOfData[id])

				return categories
			}, [])

			acc.push({ ...restOfGroup, id: title, title, categories })
		})

		return acc
	}, [])

	return response
}
