import { Component } from 'react'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import PropTypes from 'prop-types'

import './Error.scss'

class Error extends Component {
	state = {
		copied: false,
		showDetails: false,
	}

	static propTypes = {
		text: PropTypes.string,
		id: PropTypes.string.isRequired,

		details: PropTypes.shape({
			text: PropTypes.string,
			message: PropTypes.string,
		}),

		onClick: PropTypes.func.isRequired,
	}

	handleClick = () => {
		const { id, onClick } = this.props

		onClick(id)
	}

	render() {
		const { text, details } = this.props
		const { showDetails } = this.state

		const copyToClipBoardProps = {
			onCopy: () => this.setState({ copied: true }),
			text: `${text}\n${details.text || 'Нет описания'}`.trim(),
		}

		return (
			<div className='server-errors-item'>
				<div className='server-errors-item__close' onClick={this.handleClick} />
				<div className='server-errors-item__title'>Ошибка</div>
				<div className='server-errors-item__text'>
					<span>{text}</span>
					{details.message && (
						<div className='server-errors-item__text-message'>{details.message}</div>
					)}
				</div>
				{details.text && (
					<div className='server-errors-item__more'>
						<span
							className='server-errors-item__more-button'
							onClick={() => this.setState(({ showDetails }) => ({ showDetails: !showDetails }))}
						>
							{showDetails ? 'Скрыть подробности' : 'Показать подробности'}
						</span>
						{showDetails && (
							<div className='server-errors-item__more-text'>
								<div>{details.text}</div>
								<CopyToClipboard {...copyToClipBoardProps}>
									<div className='server-errors-item__more-copy'>
										{this.state.copied ? 'Скопировано' : 'Скопировать текст ошибки'}
									</div>
								</CopyToClipboard>
							</div>
						)}
					</div>
				)}
			</div>
		)
	}
}

export default Error
