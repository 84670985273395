import { DefaultValues } from 'helpers/convertHelper'

export const responseDataCcBlackList = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		result: model.null<boolean>(['result']),
		fullName: model.string(['fullName']),
		taxId: model.string(['taxId']),
		birthDate: model.string(['birthDate']),
		snils: model.string(['snils']),
		phone: model.string(['phone']),
	}
}
