import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { actions } from 'reducers/person/representatives'
import type { TAction } from 'types/redux'

import { utils } from 'helpers'

export const resetPersonRepresentatives = actions.reset

export const getPersonRepresentatives = (): TAction<void> => async (dispatch, getState) => {
	try {
		const ids = getState()
			.document.files.data.filter(
				({ categoryId }: Record<string, any>) => categoryId === 'LETTER_OF_ATTORNEY_FROM_CLIENT'
			)
			.map(({ metadata }: Record<string, any>) => metadata?.representativeId)
			.filter(Boolean)

		if (!ids.length) return

		const { data } = await api.person.search({
			filter: { ids },
			size: 100,
		})

		dispatch(actions.setAll(data.content))
		dispatch(actions.setStatus('fulfilled'))
	} catch (error) {
		dispatch(actions.setStatus('rejected'))

		dispatch(
			addServerError({
				text: 'Не удалось загрузить представителей',
				details: utils.getDetailsFromError(error),
			})
		)
	}
}
