const getText = (num, words) => {
	const count = num % 100
	const _count = count % 10

	if (count > 10 && count < 20) {
		return words[2]
	}

	if (_count > 1 && _count < 5) {
		return words[1]
	}

	if (_count === 1) {
		return words[0]
	}

	return words[2]
}

export default getText
