import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { reject } from 'ramda'

type Error = {
	id: string
	text: string
	details: string
	system: boolean
}

type State = {
	errors: Array<Error>
}

const initialState: State = {
	errors: [],
}

const serverErrors = createSlice({
	name: 'serverErrors',
	initialState,
	reducers: {
		set(state, { payload }: PayloadAction<Error>) {
			state.errors.push(payload)
		},

		remove(state, { payload }: PayloadAction<string>) {
			state.errors = reject((error) => error.id === payload, state.errors)
		},
	},
})

export const { remove, set } = serverErrors.actions
export default serverErrors.reducer
