import { DefaultValues } from 'helpers/convertHelper'

export const proceedings = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		number: model.string(['number']),
		executoryDocument: model.string(['executoryDocument']),
		debtType: model.string(['debtType']),
		debtAmount: model.null<number>(['debtAmount']),
		startDate: model.string(['startDate']),
		endDate: model.string(['endDate']),
		endCause: model.string(['endCause']),
		category: model.const(data?.category, {
			ACTIVE: 'ACTIVE',
			FINISHED: 'FINISHED',
		} as const),
		bailiffOfficeCode: model.null<number>(['bailiffOfficeCode']),
		bailiffOfficeTerotdel: model.string(['bailiffOfficeTerotdel']),
		bailiffOfficeAddress: model.string(['bailiffOfficeAddress']),
		bailiffName: model.string(['bailiffName']),
		bailiffPhone: model.string(['bailiffPhone']),
	}
}
