import { createContext, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { collectApplicationRosreestrEntities } from 'actions/rosreestr'
import { getRosreestrRegistrationList } from 'actions/rosreestrRegistration'
import type { RosreestrOperationTypeData } from 'const/rosreestrRegistration'
import { reset as rosreestrLegacyReset } from 'reducers/rosreestr'
import { actions } from 'reducers/rosreestrRegistration/main'
import type { Union } from 'ts-toolbelt'
import type { RootState, TDispatch } from 'types/redux'
import type { Maybe } from 'types/util'

import { Loading } from '@creditclubteam/react-components'
import ModalDialog from 'components/common/ModalDialog'

import { List } from './List'
import { RegistrationForm } from './RegistrationForm'

import styles from './Rosreestr.module.scss'

interface Entity {
	id: string
	name: 'LOAN' | 'APPLICATION'
}

interface RegistrationFormData {
	title: string
	subTitle: string
	contractNumber: Union.Nullable<string | number>
	facility: {
		info: string
		id: string
		address: string
	}
	operationTypeData: RosreestrOperationTypeData
}

interface Props {
	entity: Entity
}

export interface RosreestrContext {
	registrationFormData: Maybe<RegistrationFormData>
	entity: Maybe<Entity>
	set: React.Dispatch<React.SetStateAction<Maybe<RegistrationFormData>>>
}

export const rosreestrContext = createContext<RosreestrContext>({
	registrationFormData: null,
	entity: null,
	set: null!,
})

export const Rosreestr = ({ entity }: Props) => {
	const status = useSelector((state: RootState) => state.rosreestrRegistration.main.status)

	const dispatch = useDispatch<TDispatch>()

	const [rosreestrContextValue, setRosreestrContextValue] =
		useState<Maybe<RegistrationFormData>>(null)

	const contextValue = useMemo(
		() => ({ registrationFormData: rosreestrContextValue, entity, set: setRosreestrContextValue }),
		[rosreestrContextValue, entity]
	)

	useEffect(() => {
		dispatch(
			getRosreestrRegistrationList({
				size: 100,
				filter: {
					loanId: entity.name === 'LOAN' ? entity.id : null,
					applicationId: entity.name === 'APPLICATION' ? entity.id : null,
				},
			})
		)
		;(async () => {
			try {
				if (entity.name === 'APPLICATION') {
					dispatch(collectApplicationRosreestrEntities(entity.id))
				}
			} catch {
				//
			}
		})()

		return () => {
			dispatch(actions.reset())
			dispatch(rosreestrLegacyReset())
		}
	}, [dispatch, entity.id, entity.name])

	const modalDialogProps = {
		opened: !!rosreestrContextValue,
		subTitle: rosreestrContextValue?.subTitle,
		onClose: useCallback(() => setRosreestrContextValue(null), []),
		title: rosreestrContextValue?.title,
		large: true,
	}

	if (status === 'pending') {
		return <Loading max />
	} else if (status === 'rejected') {
		return <>Регистрации не найдены</>
	}

	return (
		<div className={styles.wrap}>
			<rosreestrContext.Provider value={contextValue}>
				<ModalDialog {...modalDialogProps}>
					<RegistrationForm />
				</ModalDialog>
				<List />
			</rosreestrContext.Provider>
		</div>
	)
}
