import axios from 'axios'
import { cashbackPayment as converter } from 'converters/cashbackPayment'
import moment from 'moment/moment'

const baseUrl = '/v4/investment'

export default {
	get: () =>
		axios
			.get(`${baseUrl}/cashback-payments`, {
				params: {
					date: moment(new Date()).format('YYYY-MM-DD'),
				},
			})
			.then((response) => ({
				...response,
				data: response.data.map(converter.main),
			})),

	getOrder: (ids: string[]) =>
		axios.post(`${baseUrl}/cashback-payment-orders`, {
			orders: ids,
		}),

	downloadDocuments: (startDate: string, endDate: string) =>
		axios.get(`${baseUrl}/cashback-payment-order-documents`, {
			params: {
				startDate,
				endDate,
			},
			headers: {
				Accept: 'application/zip',
			},
			responseType: 'blob',
		}),
}
