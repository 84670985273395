import axios from 'axios'
import documents from 'converters/documents'

import type { DocumentsPackageDto } from '../reducers/sign/types'

import type { EntityId } from './types/EntityId'
import { URLBuilder } from './helpers'
import type { Nullable } from 'ts-toolbelt/out/Union/Nullable'

const actions = {
	getSignatures: (id: string) => {
		const params = new URLBuilder()

		params
			.addFilter('signer.subject.id', id, 'eq')
			.addFilter('qualified', 'true', 'eq')
			.addFilter('signed', 'false', 'eq')

		return axios.post<{ content: Array<DocumentsPackageDto> }>(
			`/v2/documents/signatures/packages`,
			null,
			{
				params: {
					filter: params.build('RSQL'),
					sort: 'createdAt,desc',
				},
			}
		)
	},

	upload: (filenames: string[]) =>
		axios.post(`/v3/edm/object-storage-upload-links`, {
			filenames,
		}),

	addSignature: ({ documentId, ...data }: { documentId: EntityId } & Record<string, any>) =>
		axios
			.post(
				window.__env__.BUILD_MODE !== 'production'
					? `/v1/documents/${documentId}/signature/test`
					: `/v1/documents/${documentId}/signature`,
				{
					...data,
					signer: { ...data.signer },
				}
			)
			.then((response) =>
				Object.assign({}, response, { data: documents.files.file(response.data) })
			),

	getPersonQualifiedSpec: ({ personId }: { personId: string }) =>
		axios.get<{ signatureProvider: Nullable<string>; application: Nullable<string> }>(
			`/v1/signature/qualified/spec/${personId}`
		),

	updatePersonQualifiedSpec: ({
		personId,
		payload,
	}: {
		personId: string
		payload: { signatureProvider: string; application: string }
	}) =>
		axios.patch(`/v1/signature/qualified/spec/${personId}`, payload, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),

	sync: (signaturePackageId: string) =>
		axios.post(`/v1/signatures/qualified/sign/retry/${signaturePackageId}`),
}

export default actions
