import { DefaultValues } from 'helpers/convertHelper'

export const fullName = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		name: model.string(['name']),
		surname: model.string(['surname']),
		patronymic: model.string(['patronymic']),
	}
}
