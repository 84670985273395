import { useDispatch, useSelector } from 'react-redux'
import { createDocument } from 'actions/document'
import { Formik } from 'formik'
import PropTypes from 'prop-types'
import type { RootState, TDispatch } from 'types/redux'

import FormLayout from 'components/common/FormLayout'

import Controls from './Controls'
import { Main } from './Sections'
import type { FormType } from './types'

const Form = ({
	entityId,
	entityType,
	owners,
	persons,
	warrantors,
	documentIdForEdit,
	constructorSpec,
	categoryId,
}: any) => {
	const facilities = useSelector((state: RootState) => state.facility.list.data)
	const documentConstructor = useSelector((state: RootState) => state.document.documentConstructor)

	const dispatch = useDispatch<TDispatch>()

	const handleSubmit = (values: FormType) => {
		return dispatch(
			createDocument({
				entityId,
				values,
				entityType,
				categoryId,
			})
		)
	}

	const formatOptions = constructorSpec.documentFormatSpecs.map(
		({ format }: { format: string }) => ({
			id: format,
			value: format,
			label: format,
		})
	)

	const mainProps = {
		options: {
			formats: formatOptions,
			owners,
			persons,
			facilities: facilities.map(({ address, id }: any) => ({
				label: address?.mergedAddress || '—',
				value: address?.mergedAddress || '—',
				id,
			})),
			warrantors,
		},
		documentConstructor,
	}

	return (
		<Formik
			onSubmit={handleSubmit}
			initialValues={{
				...documentConstructor.formValues,
				formats: [formatOptions[0]],
			}}
		>
			{(form) => (
				<form autoComplete='off' onSubmit={form.handleSubmit}>
					<FormLayout cols={[1]}>
						<FormLayout.Column>
							<Main {...form} {...mainProps} />
						</FormLayout.Column>
					</FormLayout>
					<Controls form={form} buttonLabel={!documentIdForEdit ? 'Создать' : 'Редактировать'} />
				</form>
			)}
		</Formik>
	)
}

Form.propTypes = {
	entityId: PropTypes.string,
	initialValues: PropTypes.object,
	constructorSpec: PropTypes.object,
	documentConstructor: PropTypes.object,

	handleSubmit: PropTypes.func,
	createDocument: PropTypes.func,
}

export default Form
