import type { Draft, PayloadAction } from '@reduxjs/toolkit'
import type { PayloadStatus } from 'types/redux'

type CommonInitialStore<T = any> = {
	data: T
	fetching: boolean
}

export const getCommonInitialState = <T>(initialData: T) =>
	({
		data: initialData,
		fetching: false,
	} as CommonInitialStore<T>)

export const commonActions = {
	setFetchingStatus<T extends { fetching: boolean }>(
		state: Draft<T>,
		{ payload }: PayloadAction<boolean>
	) {
		state.fetching = payload
	},

	setStatus<T extends { status: PayloadStatus }>(
		state: Draft<T>,
		{ payload }: PayloadAction<PayloadStatus>
	) {
		state.status = payload
	},

	getResetActionCreator(initialState: any) {
		return () => initialState
	},

	setFilter:
		<T extends Record<string, any>, K extends keyof T = 'filter'>(key: K = 'filter' as K) =>
		(
			state: T,
			action: PayloadAction<{
				key: keyof T[K]
				value: T[K][keyof T[K]]
			}>
		) => {
			state[key][action.payload.key] = action.payload.value
		},

	reset:
		<T extends Record<string, any>>(initialState: T, keys?: Array<keyof T>) =>
		(state: T, action: PayloadAction<keyof T | Array<keyof T> | undefined>) => {
			if (typeof action.payload === 'string') {
				state[action.payload as keyof T] = initialState[action.payload]

				return state
			}

			if (Array.isArray(action.payload)) {
				action.payload.forEach((key) => {
					state[key] = initialState[key]
				})

				return state
			}

			if (Array.isArray(keys)) {
				keys.forEach((key) => {
					state[key] = initialState[key]
				})

				return state
			}

			return initialState
		},
}
