const PROOF_SOURCE = {
	CLIENT: 'CLIENT',
	PFR: 'PFR',
	NDFL2: 'NDFL2',
	LEASE_CONTRACT: 'LEASE_CONTRACT',
} as const

const PROOF_SOURCE_RUS = {
	CLIENT: 'Клиент',
	PFR: 'Справка ПФР',
	NDFL2: 'Справка 2-НДФЛ',
	LEASE_CONTRACT: 'Договор аренды',
} as const

export const PERSON_INCOMES = {
	PROOF_SOURCE,
	PROOF_SOURCE_RUS,
}
