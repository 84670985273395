import { DefaultValues } from 'helpers/convertHelper'

export const responseDataFnsTaxIdInvalidity = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		invalid: model.null<boolean>(['invalid']),
		invalidationDate: model.string(['invalidationDate']),
		taxId: model.string(['taxId']),
	}
}
