import { loans } from './loans'
import { main } from './main'
import { status } from './status'

export const loanSellApplication = {
	status,
	main,
	loans,
}

export namespace LoanSellApplication {
	export type Main = ReturnType<typeof main>
	export type Status = ReturnType<typeof status>
	export type Loan = ReturnType<typeof loans>
}
