import { Component } from 'react'
import { connect } from 'react-redux'
import { fetchHistory, getHistoryParticipants, setHistoryFilters } from 'actions/history'
import HISTORY from 'const/history'
import PropTypes from 'prop-types'

import { Select } from 'components/common'
import { DatePicker } from 'components/common'
import { utils } from 'helpers'

import './Controls.scss'

class Controls extends Component {
	filterTimer = 0

	static propTypes = {
		displayDate: PropTypes.any,
		allowedTypes: PropTypes.array,
		expanded: PropTypes.bool.isRequired,
		filters: PropTypes.object.isRequired,
		allEntityId: PropTypes.array.isRequired,

		onExpand: PropTypes.func.isRequired,
		fetchHistory: PropTypes.func.isRequired,
		setHistoryFilters: PropTypes.func.isRequired,
		getHistoryParticipants: PropTypes.func.isRequired,
	}

	state = {
		isMobile: utils.isMobileWidth(),
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize)
		clearTimeout(this.filterTimer)
	}

	handleExpand = () => {
		const { onExpand } = this.props

		onExpand()
	}

	handleResize = () => {
		this.setState({
			isMobile: utils.isMobileWidth(),
		})
	}

	handleChangeDate = ({ selection: { startDate, endDate } }) => {
		const { fetchHistory, setHistoryFilters } = this.props
		clearTimeout(this.filterTimer)

		setHistoryFilters({ field: 'endDate', value: endDate })
		setHistoryFilters({ field: 'startDate', value: startDate })

		this.filterTimer = setTimeout(() => fetchHistory(null, true), 500)
	}

	handleChangeTypes = (types) => {
		const { fetchHistory, setHistoryFilters } = this.props
		clearTimeout(this.filterTimer)

		setHistoryFilters({ field: 'types', value: types })

		this.filterTimer = setTimeout(() => fetchHistory(null, true), 500)
	}

	handleClearFilterDate = () => {
		const { setHistoryFilters, fetchHistory } = this.props

		setHistoryFilters({ field: 'endDate', value: null })
		setHistoryFilters({ field: 'startDate', value: null })

		fetchHistory(null, true)
	}

	handleChangeEntity = (entity) => {
		const { fetchHistory, setHistoryFilters } = this.props
		clearTimeout(this.filterTimer)

		setHistoryFilters({ field: 'entityId', value: entity })

		this.filterTimer = setTimeout(() => fetchHistory(null, true), 500)
	}

	getParticipantsOptions = () => {
		const results = []
		const { allEntityId, getHistoryParticipants } = this.props

		allEntityId.forEach((p) => {
			if (p && typeof p === 'object') {
				results.push(p)
			}
		})

		getHistoryParticipants().forEach((p) => {
			if (allEntityId.includes(p.id)) {
				results.push(p)
			}
		})

		return utils.formatParticipantsToSelect(results).map(({ id, label }) => ({
			value: id,
			label: label || 'Без имени',
		}))
	}

	render() {
		const { allowedTypes, allEntityId, expanded, filters, displayDate } = this.props

		const multiEntityMode = allEntityId.length > 1

		const datePickerOptions = {
			ranges: {
				maxDate: filters.endDate,
				minDate: filters.startDate,
			},
			onChange: this.handleChangeDate,
			className: 'history-controls__date-picker',
		}

		const typesSelectOptions = {
			multi: true,
			value: filters.types,
			defaultTitle: 'Все события',
			onChange: this.handleChangeTypes,
			className: 'history-controls__select',
			options: allowedTypes || Object.values(HISTORY.TYPES),
		}

		const entitiesSelectOptions = {
			multi: true,
			value: filters.entityId,
			defaultTitle: 'Все участники',
			onChange: this.handleChangeEntity,
			className: 'history-controls__select',
			options: this.getParticipantsOptions(),
		}

		const hasFilterSelectedDate = filters.endDate || filters.startDate

		return (
			<div className='history-controls'>
				{!this.state.isMobile && (
					<>
						{displayDate && (
							<div className='history-controls__display-date'>
								<span>{displayDate}</span>
							</div>
						)}
						<div className='history-controls__filter_options'>
							<div
								data-test-id='history-datepicker'
								className='history-controls__filter_options-date'
							>
								<DatePicker {...datePickerOptions} />
								{hasFilterSelectedDate && (
									<span
										onClick={this.handleClearFilterDate}
										className='history-controls__filter_options-date__clear'
									>
										<i className='zmdi zmdi-close' />
									</span>
								)}
							</div>
							<div data-test-id='history-filter' className='history-controls__filter_options-types'>
								<Select {...typesSelectOptions} />
							</div>
							{multiEntityMode && (
								<div className='history-controls__filter_options-entity'>
									<Select {...entitiesSelectOptions} />
								</div>
							)}
						</div>
					</>
				)}
				<div className='history-controls__expand'>
					<span onClick={this.handleExpand}>{expanded ? 'Свернуть' : 'Развернуть'}</span>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	...state.history,
})

const mapDispatchToProps = {
	fetchHistory,
	setHistoryFilters,
	getHistoryParticipants,
}

export default connect(mapStateToProps, mapDispatchToProps)(Controls)
