import { Component } from 'react'
import { DefaultPlayer as Video } from 'react-html5video'
import PropTypes from 'prop-types'

import 'react-html5video/dist/styles.css'
import './ViewVideo.scss'

class ViewVideo extends Component {
	static propTypes = {
		files: PropTypes.array.isRequired,
	}

	render() {
		const { files } = this.props

		return (
			<div className='files-viewer-video'>
				<Video autoPlay loop controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}>
					<source src={files[0].src} />
				</Video>
			</div>
		)
	}
}

export default ViewVideo
