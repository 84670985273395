import { URLBuilder } from 'api/helpers'
import type { Options } from 'api/types/Options'
import axios from 'axios'
import type { FinancialDataFilter } from 'reducers/financialData'

import type { FinancialData } from '../converters/financialData'
import { financialData } from '../converters/financialData'

import type { Pageable } from './types/Pageable'

export default {
	search: async ({ filter, size = 10, page = 0 }: Options<FinancialDataFilter>) => {
		const params = new URLBuilder()

		params.addFilter('loanId', filter?.loanId, 'eq')

		return axios
			.get('/v1/loan-financial-data', {
				params: {
					size,
					page,
					filter: params.build('RSQL'),
				},
			})
			.then(
				(response) =>
					Object.assign(
						{},
						{
							content: response.data.content.map(financialData.main),
						}
					) as Pageable<FinancialData.Main>
			)
	},
}
