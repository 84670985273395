import compareAsc from 'date-fns/compareAsc'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

type Params = {
	certificateActiveTo: string
}

/**
 * Проверяет валидность сертификата подписи
 *
 * @param params.certificateActiveTo Дата сертификата. Формат "2022-07-02"
 * @returns {boolean} true, если сертификат валиден
 */
export function isValidCertificate(params: Params) {
	const { certificateActiveTo } = params

	const today = format(new Date(), 'yyyy-MM-dd')

	const result = compareAsc(parseISO(today), parseISO(certificateActiveTo))

	return result === -1 || result === 0
}
