import { lazy } from 'react'

import { lazyLoadHandler } from 'helpers/lazyLoadHandler'

const Bonuses = lazy(() => lazyLoadHandler(() => import('./Bonuses')))
const Payments = lazy(() => lazyLoadHandler(() => import('./Payments')))
const Invest = lazy(() => lazyLoadHandler(() => import('./Invest/Invest')))
const Documents = lazy(() => lazyLoadHandler(() => import('./Documents')))

export default {
	Payments,
	Bonuses,
	Documents,
	Invest,
}
