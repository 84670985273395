import cx from 'clsx'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import Loading from 'components/common/Loading'

import './ListBlock.scss'

const ListBlock = ({ data, notFound, children, className, itemsClassName, fetching, columns }) => {
	const columnsCx = cx('entities-list__columns', {
		[className]: !!className,
	})

	const itemsCx = cx('entities-list__items', {
		[itemsClassName]: !!itemsClassName,
	})

	if (fetching) return <Loading />

	return isEmpty(data) ? (
		<div className='entities-list__not-found' data-test-id='entity-table'>
			{notFound || 'Список пуст'}
		</div>
	) : (
		<div className='entities-list' data-test-id='entity-table'>
			<div className={columnsCx}>
				{columns.map((e, i) => (
					<div key={i}>{e}</div>
				))}
			</div>
			<div className={itemsCx}>{children && children}</div>
		</div>
	)
}

ListBlock.propTypes = {
	children: PropTypes.any,
	notFound: PropTypes.string,
	data: PropTypes.array.isRequired,
	itemsClassName: PropTypes.string,
	columns: PropTypes.array.isRequired,
	className: PropTypes.string.isRequired,
}

export default ListBlock
