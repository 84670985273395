import PropTypes from 'prop-types'

import { AudioPlayer } from 'components/common'

import './Audio.scss'

const Audio = ({ url, caption }) => (
	<div className='history-whatsapp-audio'>
		<AudioPlayer src={url} />
		{caption && <div className='history-whatsapp-audio__caption'>{caption}</div>}
	</div>
)

Audio.propTypes = {
	caption: PropTypes.any,
	url: PropTypes.string,
}

export default Audio
