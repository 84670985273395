import { nanoid } from 'nanoid'
import { add, hide, update } from 'reducers/notices'

export const hideNotice = (id) => (dispatch, getState) => {
	const { list } = getState().notices

	if (!list.find((e) => e.id === id)) return

	dispatch(hide(id))
}

export const pushNotice = (notice) => (dispatch, getState) => {
	const _notice = getState().notices.list.find(({ id }) => notice.id === id)
	const id = notice.id || nanoid()

	if (_notice && id) {
		dispatch(update({ id, notice }))
	} else {
		dispatch(add(Object.assign({}, notice, { id })))
	}

	if (notice.hideAfter) {
		setTimeout(() => dispatch(hide(id)), notice.hideAfter)
	}

	return { id, message: notice.message }
}

export const addDownloadFileStatus =
	({ calculated }, id, fileName) =>
	(dispatch) => {
		const notice = {
			id,
			progress: calculated,
			message: `загрузка файла: ${fileName}`,
		}

		dispatch(pushNotice(notice))

		if (calculated === 100) {
			dispatch(hideNotice(id))
		}
	}
