import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Refinancing } from 'converters/refinancing'
import { commonActions } from 'reducers/common'
import type { PayloadStatus } from 'types/redux'
import type { Maybe } from 'types/util'

const initialState = {
	data: null as Maybe<Refinancing.Main>,
	status: 'pending' as PayloadStatus,
}

const slice = createSlice({
	name: 'refinancing',
	initialState,
	reducers: {
		setData: (state, { payload }: PayloadAction<Maybe<Refinancing.Main>>) => {
			state.data = payload
		},
		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions: refinancingReducerActions } = slice
export default slice.reducer
