import { CALCULATION } from 'const/calculations'

import { DefaultValues } from 'helpers/convertHelper'

export const paymentAmountDetail = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		type: model.const(data.type, CALCULATION.TYPE.ENG),
		typeRus: model.const(data.type, CALCULATION.TYPE.RUS),
		amount: model.null<number>(['amount']),
	}
}
