import { Component, lazy,Suspense } from 'react'
import renderHTML from 'react-render-html'
import PropTypes from 'prop-types'

import { Loading } from '@creditclubteam/react-components'

import 'react-quill/dist/quill.snow.css'
import './BodyContent.scss'

const ReactQuill = lazy(() => import('react-quill'))

class BodyContent extends Component {
	static propTypes = {
		form: PropTypes.object.isRequired,
		fetching: PropTypes.bool.isRequired,

		setBodyContent: PropTypes.func.isRequired,
	}

	state = {
		isEdit: false,
		isReactQuillLoaded: !!ReactQuill._result,
	}

	componentWillUnmount() {
		document.removeEventListener('click', this.onClickDocument)
	}

	componentDidUpdate(prevProps, prevState) {
		const { isEdit } = this.state

		if (!prevState.isEdit && isEdit) {
			document.addEventListener('click', this.onClickDocument)
			if (ReactQuill._result && this.editorNodeElement) {
				this.editorNodeElement.focus()
			}
		} else if (prevState.isEdit && !isEdit) {
			document.removeEventListener('click', this.onClickDocument)
		}
	}

	handleActivateEdit = () => {
		const { fetching } = this.props

		if (!fetching) {
			this.setState({ isEdit: true })
		}
	}

	handleChange = (html) => {
		const { setBodyContent } = this.props

		setBodyContent(html)
	}

	renderBodyContent() {
		const {
			form: {
				body: { content },
			},
		} = this.props

		if (content) {
			try {
				const parser = new DOMParser()
				const html = parser.parseFromString(content, 'text/html')

				if (html.body.innerText.trim()) return <>{renderHTML(html.body.innerHTML)}</>
				else return <span className='calendar-body-content__empty'>Добавить описание...</span>
			} catch (err) {
				return <div>Неверный формат</div>
			}
		} else return <span className='calendar-body-content__empty'>Добавить описание...</span>
	}

	handleSetRef = (el) => {
		this.editorNodeElement = el

		if (this.editorNodeElement) this.editorNodeElement.focus()

		if (!this.state.isReactQuillLoaded) {
			this.setState({ isReactQuillLoaded: true })
		}
	}

	render() {
		const { form } = this.props
		const { isEdit } = this.state

		const quillProps = {
			value: form.body.content,
			onChange: this.handleChange,
			ref: this.handleSetRef,
			preserveWhitespace: false,
			modules: {
				clipboard: {
					matchVisual: false,
				},
			},
		}

		return (
			<div className='calendar-body-content'>
				{isEdit ? (
					<div className='calendar-body-content__edit'>
						<Suspense fallback={<Loading min />}>
							<ReactQuill {...quillProps} />
						</Suspense>
					</div>
				) : (
					<div className='calendar-body-content__view' onClick={this.handleActivateEdit}>
						{this.renderBodyContent()}
					</div>
				)}
			</div>
		)
	}
}

export default BodyContent
