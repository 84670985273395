import axios from 'axios'
import { keycloak } from 'client/keycloak/instance'

export default {
	suggestAddress: (params = { count: 10, query: '' }) => {
		const queryStr = new URLSearchParams()
		const _params = {
			count: params.count || 10,
			query: params.query || '',
		}

		// @ts-expect-error
		Object.keys(_params).forEach((key) => queryStr.append(key, _params[key]))

		return axios({
			method: 'GET',
			url: `/dadata/address?${queryStr.toString()}`,
			$$requestName: 'dadata.suggestAddress',
			$$token: keycloak.token,
		}).then((response) => ({
			...response,
			data: {
				suggestions: response.data.map((e: any) => ({
					value: e.mergedAddress,
					data: e,
				})),
			},
		}))
	},

	getSuggestions: (params: any, service = 'fio') =>
		axios({
			method: 'POST',
			data: {
				...params,
			},
			headers: {
				Accept: 'application/json',
				Authorization: `Token 40255f1ccd5969d50f8fdbfc6e2089c5e684cfa6`,
				'Content-Type': 'application/json',
			},
			$$noInterceptors: true,
			$$notPutApiVersion: true,
			$$requestName: 'dadata.getSuggestions',
			url: `https://dadata.ru/api/v2/suggest/${service}`,
		}),
}
