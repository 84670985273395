import * as actionTypes from 'const/actionTypes'
import Immutable from 'immutable'

import { immutableize } from 'helpers'

const initialState = Immutable.fromJS({
	data: [],
	entityId: '',
	fetching: false,
	error: false,
})

const categories = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.DOCUMENTS_CATEGORIES_SET_DATA:
			return state.set('data', action.data)

		case actionTypes.DOCUMENTS_CATEGORIES_SET_ENTITY_ID:
			return state.set('entityId', action.entityId)

		case actionTypes.DOCUMENTS_CATEGORIES_SET_FETCHING_STATUS:
			return state.set('fetching', action.status)

		case actionTypes.DOCUMENTS_CATEGORIES_ADD_CATEGORY:
			return state.update('data', (data) => data.push(action.data))

		case actionTypes.DOCUMENTS_CATEGORIES_RESET:
			return initialState

		default:
			return state
	}
}

export default immutableize(categories)
