import type { CancelToken, CancelTokenSource } from 'axios'
import axios from 'axios'
import type { Maybe } from 'types/util'

export class PollingRequest {
	private request: Maybe<(cancelToken: CancelToken) => Promise<any>>
	private interval: number
	private intervalTime: number
	private cancelToken: CancelTokenSource
	private isPollingVal: boolean

	constructor() {
		this.request = null
		this.intervalTime = 15000
		this.interval = 0
		this.isPollingVal = false
		this.cancelToken = axios.CancelToken.source()
	}

	private check() {
		if (typeof this.request !== 'function') {
			return false
		}

		return true
	}

	private createAndSetCancelToken() {
		this.cancelToken = axios.CancelToken.source()
	}

	isPolling() {
		return this.isPollingVal
	}

	setRequest(request: PollingRequest['request']) {
		if (this.isPollingVal) {
			this.stopPolling()
		}

		this.request = request
		return this
	}

	setIntervalTime(intervalTime: PollingRequest['intervalTime']) {
		this.intervalTime = intervalTime
		return this
	}

	startPolling() {
		if (this.check()) {
			this.isPollingVal = true

			this.interval = window.setInterval(() => {
				this.request!(this.cancelToken.token)
			}, this.intervalTime)
		}

		return this
	}

	stopPolling() {
		this.isPollingVal = false

		this.cancelToken.cancel()
		clearInterval(this.interval)
		this.createAndSetCancelToken()

		return this
	}
}
