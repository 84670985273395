import { DefaultValues } from 'helpers/convertHelper'

export const analytics = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		loanCourts: model.null<number>(['loanCourts']),
		amountLoanCourts: model.null<number>(['amountLoanCourts']),
		amountNonPropertyCourts: model.null<number>(['amountNonPropertyCourts']),
		amountLegalDebtCourts: model.null<number>(['amountLegalDebtCourts']),
		amountLegalDebtCourtsOver500: model.null<number>(['amountLegalDebtCourtsOver500']),
		negativeNonPropertyCourts: model.null<number>(['negativeNonPropertyCourts']),
		avoidLoanCourts: model.null<number>(['avoidLoanCourts']),
		bankruptcyCourtsAsDefendant: model.null<number>(['bankruptcyCourtsAsDefendant']),
		negativeBankruptcyCourtsAsThird: model.null<number>(['negativeBankruptcyCourtsAsThird']),
		negativeCriminalAdministrativeCourts: model.null<number>([
			'negativeCriminalAdministrativeCourts',
		]),
	}
}
