import { addServerError } from 'actions/serverErrors'
import api from 'api'
import axios from 'axios'
import { nanoid } from 'nanoid'
import { flatten, isEmpty } from 'ramda'
import { actions } from 'reducers/uplaodFiles'
import type { TAction } from 'types/redux'
import type { Maybe } from 'types/util'

import { utils } from 'helpers'

export const resetUploadFiles = actions.reset

const addFilesToUploader =
	(ownerId: string, files: any[]): TAction<void> =>
	(dispatch, getState) => {
		const { uploader } = getState().uploadFiles

		if (uploader[ownerId]) {
			dispatch(actions.addFilesToOwner({ ownerId, files }))
		} else {
			dispatch(actions.createOwner({ ownerId, files }))
		}
	}

const createFiles =
	({
		ownerId,
		ownerType,
	}: {
		ownerId: string
		ownerType: string
	}): TAction<Promise<Maybe<Record<string, any>>>> =>
	async (dispatch, getState) => {
		const { uploader } = getState().uploadFiles

		if (uploader[ownerId]) {
			const listFiles: Record<string, any>[] = uploader[ownerId].files

			if (!isEmpty(listFiles)) {
				try {
					const [...data] = await axios.all(
						listFiles
							.map(({ categoryId, fileOrigin, objectKey, metadata }) => ({
								owner: { id: ownerId, type: ownerType },
								categoryId,
								source: 'OBJECT_STORAGE',
								parameters: {
									objectKey,
									size: fileOrigin.size,
									filename: fileOrigin.name,
								},
								metadata,
							}))
							.map((payload) => api.document.createFile(payload).then(({ data }) => data))
					)

					return flatten(data)
				} catch (error) {
					dispatch(
						addServerError({
							details: utils.getDetailsFromError(error),
							text: `Ошибка при создании файлов в категории у сущности ${ownerId}`,
						})
					)

					return null
				}
			}
		}

		return null
	}

const startUploadFile =
	(
		{ ownerId, ownerType }: { ownerId: string; ownerType: string },
		onSuccess: (...args: any[]) => void
	): TAction =>
	(dispatch, getState) => {
		const { uploader } = getState().uploadFiles
		window.onbeforeunload = () => 'Имеются файлы в очередь на загрузку'

		if (!uploader[ownerId]?.isProcessing) {
			;(async function upload() {
				const currentFile = getState().uploadFiles.uploader[ownerId].files.find(
					({ isUploadSuccess }: Record<string, any>) => !isUploadSuccess
				)

				if (currentFile) {
					if (!getState().uploadFiles.uploader[ownerId].isProcessing) {
						dispatch(actions.setOwnerProgress({ ownerId, status: true }))
					}

					const { hash, uploadUrl, fileOrigin } = currentFile

					api.document
						.uploadFile(uploadUrl, fileOrigin, ({ loaded, total }: Record<string, any>) => {
							const progress = Math.round((loaded * 100) / total)

							dispatch(actions.setFileProgress({ hash, progress, ownerId }))
						})
						.then(() => dispatch(actions.setUploadedStatus({ hash, ownerId, status: true })))
						.catch((error) => {
							dispatch(
								addServerError({
									details: utils.getDetailsFromError(error),
									text: `Ошибка при загрузке файла ${fileOrigin.name}`,
								})
							)

							dispatch(actions.deleteFileFromOwner({ ownerId, hash }))
						})
						.finally(upload)
				} else {
					const createdFiles = await dispatch(createFiles({ ownerId, ownerType }))

					if (createdFiles) {
						dispatch(actions.deleteOwner(ownerId))

						if (onSuccess) {
							onSuccess(createdFiles, ownerId)
						}
					}

					if (isEmpty(getState().uploadFiles.uploader)) {
						window.onbeforeunload = () => null
					}
				}
			})()
		}
	}

export const giveObjectKeys =
	(files: File[]): TAction<Promise<Maybe<Record<string, any>>>> =>
	(dispatch) =>
		api.document.getTemporaryUrls(files.map(({ name }) => name).filter(Boolean)).catch((err) => {
			dispatch(
				addServerError({
					details: utils.getDetailsFromError(err),
					text: 'Ошибка получения токенов доступа от AWS',
				})
			)

			return null
		})

export const uploadFiles =
	({
		files,
		unsupportedFiles,
		ownerId,
		ownerType,
		categoryId,
		metadata,
		onSuccess,
		onFailure,
	}: {
		files: File[]
		unsupportedFiles?: File[]
		ownerId: string
		ownerType: string
		categoryId: string
		onSuccess: () => void
		metadata: Record<string, any>[]
		onFailure?: () => void
	}): TAction =>
	async (dispatch) => {
		if (unsupportedFiles?.length) {
			const names = unsupportedFiles.map((file) => file.name)

			dispatch(
				addServerError(
					{
						text:
							names.length > 1
								? `Файлы ${names.join(', ')} не поддерживаются для загрузки`
								: `Файл ${names[0]} не поддерживается для загрузки`,
					},
					false
				)
			)

			onFailure && onFailure()
		}

		if (files?.length) {
			const validFiles = files.filter((file) => file instanceof File)
			const objectKeysResults = await dispatch(giveObjectKeys(validFiles))
			const objectKeys = objectKeysResults?.data

			if (objectKeys && !isEmpty(objectKeys)) {
				const listToUpload = validFiles.map((file, index) => {
					const keyItem = objectKeys.find(
						({ filename }: Record<string, any>) => filename === file.name
					)

					return {
						ownerId,
						categoryId,
						metadata: metadata?.[index],
						hash: nanoid(),
						fileOrigin: file,
						uploadProgress: 0,
						uploadUrl: keyItem.url,
						isUploadSuccess: false,
						objectKey: keyItem.objectKey,
					}
				})

				dispatch(addFilesToUploader(ownerId, listToUpload))
				dispatch(startUploadFile({ ownerId, ownerType }, onSuccess))
			} else {
				onFailure && onFailure()
			}
		} else {
			onFailure && onFailure()
		}
	}
