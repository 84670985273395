import axios from 'axios'

export default {
	checkCapability: (id: string) => axios.get(`/v4/lending/applications/${id}/capabilities`),

	getLink: (id: string) => axios.post(`/v4/lending/applications/${id}/final-decision-link`),

	sendSms: (id: string) =>
		axios.post(`/v4/lending/applications/${id}/final-decision-delivery`, {
			channel: 'SMS',
		}),

	getLeadPaymentSchedule: (id: string) =>
		axios.get(`/v4/lending/leads/${id}/calculation`, {
			headers: {
				accept: 'application/pdf',
			},
			responseType: 'blob',
		}),

	getApplicationPaymentSchedule: (id: string) =>
		axios.get(`/v4/lending/applications/${id}/calculation`, {
			headers: {
				accept: 'application/pdf',
			},
			responseType: 'blob',
		}),
}
