import { DefaultValues } from 'helpers/convertHelper'

import { component } from './component'
import { parameter } from './parameter'

export const check = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		comment: model.string(['comment']),
		title: model.string(['title']),
		state: {
			title: model.string(['state', 'title']),
			success: model.null<boolean>(['state', 'success']),
			noData: model.null<boolean>(['state', 'noData']),
		},
		parameters: model.array(['parameters']).map(parameter),
		components: model.array(['components']).map(component),
	}
}
