import { useContext } from 'react'
import { useSelector } from 'react-redux'
import type {
	ROSREESTR_REGISTRATION,
	RosreestrOperationTypeData,
} from 'const/rosreestrRegistration'
import { includes } from 'ramda'
import type { RootState } from 'types/redux'

import { rosreestrContext } from 'components/common/Rosreestr'

const SUB_TITLE = 'Выберите участников, объект и документы для создания заявки'
const ALT_SUB_TITLE = 'Выберите участников, объект и документы'

export const getModalTextsByCategory = (
	entityName: keyof typeof ROSREESTR_REGISTRATION.CREATION_TYPES,
	value?: keyof typeof ROSREESTR_REGISTRATION.TYPE
): { title: string; subTitle: string } => {
	if (entityName === 'APPLICATION') {
		switch (value) {
			case 'ENCUMBRANCE_IMPOSITION_WITH_MORTGAGE':
				return {
					title: 'Заявка на выпуск предварительной закладной',
					subTitle: SUB_TITLE,
				}
		}
	}

	switch (value) {
		case 'ENCUMBRANCE_IMPOSITION_WITH_MORTGAGE':
			return {
				title: 'Заявка на регистрацию с закладной',
				subTitle: SUB_TITLE,
			}

		case 'ENCUMBRANCE_IMPOSITION':
			return {
				title: 'Заявка на регистрацию обременения',
				subTitle: SUB_TITLE,
			}

		case 'MORTGAGE':
			return {
				title: 'Заявка на выпуск закладной',
				subTitle: SUB_TITLE,
			}

		case 'ENCUMBRANCE_CESSATION':
			return {
				title: 'Заявка на снятие обременения',
				subTitle: ALT_SUB_TITLE,
			}

		default:
			return {
				title: 'Неизвестный тип заявки',
				subTitle: '',
			}
	}
}

interface UseActionParameters {
	type: keyof typeof ROSREESTR_REGISTRATION.TYPE
	operationTypeData: RosreestrOperationTypeData
	facility: {
		info: string
		address: string
		id: string
	}
	disabled?: boolean
}

export const useCreateRegistration = ({
	operationTypeData,
	facility,
	type,
}: UseActionParameters): { text: string; onClick: () => void; disabled?: boolean } => {
	const { set, entity } = useContext(rosreestrContext)
	const number = useSelector(
		(state: RootState) =>
			state.loan.single.data?.number ?? state.application.single.data?.loan.number
	)
	const applicationStatus = useSelector(
		(state: RootState) => state.application.single.data?.status.id
	)

	const contextValue = {
		facility,
		operationTypeData,
		contractNumber: number,
	}

	if (entity?.name === 'APPLICATION') {
		switch (type) {
			case 'ENCUMBRANCE_IMPOSITION_WITH_MORTGAGE':
				return {
					text: 'Создать заявку на выпуск предварительной закладной',
					disabled: !includes(applicationStatus, [
						'CREATING_CONTRACTS',
						'WAIT_VERIFICATION_CONTRACTS',
						'VERIFICATION_CONTRACTS',
						'WAIT_SIGNING',
					]),
					onClick: () =>
						set({
							...contextValue,
							...getModalTextsByCategory('APPLICATION', type),
						}),
				}
		}
	}

	switch (type) {
		case 'ENCUMBRANCE_IMPOSITION_WITH_MORTGAGE':
			return {
				text: 'Создать заявку на регистрацию с закладной',
				onClick: () =>
					set({
						...contextValue,
						...getModalTextsByCategory('LOAN', type),
					}),
			}

		case 'ENCUMBRANCE_IMPOSITION':
			return {
				text: 'Создать заявку на регистрацию обременения',
				onClick: () =>
					set({
						...contextValue,
						...getModalTextsByCategory('LOAN', type),
					}),
			}

		case 'MORTGAGE':
			return {
				text: 'Создать заявку на выпуск закладной',
				onClick: () => set({ ...contextValue, ...getModalTextsByCategory('LOAN', type) }),
			}

		case 'ENCUMBRANCE_CESSATION':
			return {
				text: 'Создать заявку на снятие обременения',
				onClick: () =>
					set({
						...contextValue,
						...getModalTextsByCategory('LOAN', type),
					}),
			}
	}
}
