import HISTORY from 'const/history'

export default {
	returnScrollInStart() {
		const bodyNodeElement = document.querySelector('.' + HISTORY.SELECTORS.HISTORY_BODY)

		if (bodyNodeElement) {
			bodyNodeElement.scrollTop = bodyNodeElement.scrollHeight - bodyNodeElement.clientHeight
		}
	},
}
