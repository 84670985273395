import { Component } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import {
	defineParticipantsToSign,
	documentSetEntityId,
	onDragEnd,
	onDragStart,
	renameDocument,
	sendExternalSign,
	updateMetadata,
} from 'actions/document'
import { setModalShow } from 'actions/modalDialogs'
import PropTypes from 'prop-types'
import { flatten } from 'ramda'

import { fileUtils } from '@creditclubteam/helpers'
import { Loading, ModalDialog } from 'components/common'

import { Category } from './Category'
import SendDocumentsToSign from './SendDocumentsToSign'
import UpdateDocumentMetadata from './UpdateDocumentMetadata'

import './DocumentModule.scss'

const metadataDefaultState = {
	filesToAttachMetadata: [],
	metadataFields: [],
	metadata: {},
	categoryId: null,
	mode: null, // 'move' | 'upload' | 'edit'
	draggableData: null,
}

/**
 * @param {signable} если true, то документы подписываемые
 * @param {ownerTypes} массив сущностей-владельцев новых категорий
 *
 * @callback onEdit {params}: documentId [string], category [object]
 * @callback onDrop {params}: categoryId [string], acceptedFiles [array], rejectedFiles [array]
 * @callback onDelete {params}: documentId [string]
 * @callback onCreate {params}: category [object]
 * @callback onAddSigners {params}: documentsForSign [object]
 * @callback onManualUpload {params}: categoryId [string], files [array]
 * @callback onSignersController {params}: documentId [string]
 * @callback renameDocument {params}: documentId [string], title [string]
 */

class DocumentModule extends Component {
	static propTypes = {
		participants: PropTypes.shape({
			data: PropTypes.array.isRequired,
			fetching: PropTypes.bool.isRequired,
		}),

		sign: PropTypes.object,
		allowSign: PropTypes.bool,
		files: PropTypes.object.isRequired,
		allowCreateDocuments: PropTypes.bool,
		entityType: PropTypes.string,
		entityId: PropTypes.string.isRequired,
		ownerTypes: PropTypes.array.isRequired,
		categories: PropTypes.object.isRequired,
		modalDialogsShow: PropTypes.object.isRequired,
		documentSetEntityId: PropTypes.func.isRequired,

		onEdit: PropTypes.func,
		onDrop: PropTypes.func,
		onCreate: PropTypes.func,
		onDelete: PropTypes.func,
		addCategory: PropTypes.func,
		onManualUpload: PropTypes.func,
		sendExternalSign: PropTypes.func,
		onDragEnd: PropTypes.func.isRequired,
		onDragStart: PropTypes.func.isRequired,
		// onSignersController: PropTypes.func,
		setModalShow: PropTypes.func.isRequired,
		renameDocument: PropTypes.func.isRequired,
		fetchInitialDataToSign: PropTypes.func.isRequired,
		defineParticipantsToSign: PropTypes.func.isRequired,
		initPolling: PropTypes.func.isRequired,
		disablePolling: PropTypes.func.isRequired,
	}

	static defaultProps = {
		signable: false,
		allowCreateDocuments: true,
	}

	state = {
		fetching: {
			metadata: false,
		},
		selectedFiles: [],
		customersForSimpleSignature: [],
		isCategoryRequired: null,
		metadataUpdates: metadataDefaultState,
		selectedCategoryOnUpload: null,
		documentsToSign: [],
		modals: {
			updateMetadata: false,
			sendExternalSign: false,
		},
	}

	async componentDidMount() {
		const {
			entityId,
			documentSetEntityId,
			fetchInitialDataToSign,
			allowSign,
		} = this.props

		documentSetEntityId(entityId)

		fetchInitialDataToSign(allowSign)
	}

	handleDragEnd = (options) => {
		const {
			onDragEnd,
			files: { data: files },
			categories: { data: categories },
		} = this.props

		const destCategory = categories.reduce((acc, { categories }) => {
			const result = acc ?? categories.find(({ id }) => options.destination.droppableId === id)

			return result
		}, null)

		const document = files.find(({ id }) => options.draggableId === id)

		if (destCategory.metadataFields?.length) {
			this.handleGetMetadata({
				mode: 'move',
				document,
				metadataFields: destCategory.metadataFields,
				categoryId: destCategory.id,
				draggableData: options,
			})
		} else {
			onDragEnd({ ...options, metadata: null })
		}
	}

	handleSelectFiles = (id) => {
		const { files } = this.props

		let selectedFiles = [...this.state.selectedFiles]

		const file = files.data.find((e) => e.id === id) || null

		const isFileAdded = file && !!selectedFiles.find((e) => e.id === file.id)

		if (isFileAdded) {
			selectedFiles = selectedFiles.filter((e) => e.id !== file.id)
		} else {
			file && selectedFiles.push(file)
		}

		if (!isFileAdded && !file) return

		this.setState({ selectedFiles })
	}

	handleAddSigners = (document) => {
		const { selectedFiles } = this.state
		const { categories } = this.props

		const signableDocuments = [document]

		if (selectedFiles.length) {
			const extractedCategories = flatten(categories.data.map((item) => item.categories))

			selectedFiles.forEach((file) => {
				const isSignableDocument = !!extractedCategories.find((category) => {
					const extensionLower = file.extension.toLowerCase()
					return (
						category.id === file.categoryId &&
						category.signable &&
						(extensionLower === 'pdf' || extensionLower === 'xml')
					)
				})

				if (isSignableDocument && file.id !== document.id) signableDocuments.push(file)
			})
		}

		this.setState({ documentsToSign: signableDocuments }, () =>
			this.handleSetModal('sendExternalSign', true)
		)
	}

	handleCloseSendDocumentsToSign = () => {
		this.handleSetModal('sendExternalSign', false)
		this.setState({ selectedFiles: [] })
	}

	// HELPERS BEGIN

	setFetching = (param, value, callback) => {
		this.setState(
			{
				fetching: {
					...this.state.fetching,
					[param]: value,
				},
			},
			callback && callback()
		)
	}

	// HELPERS END

	// RENDER HELPERS BEGIN

	renderCategoryControls(name) {
		return (
			<div className='document-module-files-category__head'>
				<div className='document-module-files-category__head-title'>{name}</div>
			</div>
		)
	}

	matchWithFileWithCategory = (category, file) => {
		const { categories, entityType } = this.props

		const hasCategory = categories.data.some(({ categories }) =>
			categories.some(({ id }) => id === file.categoryId)
		)

		// Если категория найдена, значит матчим как обычно
		if (hasCategory) {
			return category.id === file.categoryId
		}

		// Далее идут "виртуальные категории".
		// Их суть в том, что если документ не принадлежит ни одной
		// категории, которые мы получили из сущности, то матчим документ
		// по виртуальной категории, которая будет совпадать с одной из имеющихся категорий в сущности

		return category.id === file.virtualCategory[entityType]
	}

	renderRequiredCategories() {
		const { selectedFiles, selectedCategoryOnUpload } = this.state
		console.log(this.props);
		const {
			sign,
			files,
			onDrop,
			onEdit,
			onCreate,
			entityId,
			entityType,
			onDelete,
			allowSign,
			ownerTypes,
			categories,
			participants,
			sendExternalSign,
			onManualUpload,
			renameDocument,
			allowCreateDocuments,
		} = this.props

		const callbacks = {
			onEdit,
			onDrop,
			onDelete,
			onCreate,
			onManualUpload,
			renameDocument,
			onClickItem: this.handleSelectFiles,
			onAddSigners: sendExternalSign && this.handleAddSigners,
		}

		const customers = sign?.participants || null

		return categories.data.map((category) => {
			return (
				<div key={category.id} className='document-module-files-category-block'>
					{this.renderCategoryControls(category.title, true)}
					{category.categories.map((category) => (
						<Category
							{...callbacks}
							data={category}
							key={category.id}
							allowSign={allowSign}
							customers={customers}
							entityId={entityId}
							entityType={entityType}
							ownerTypes={ownerTypes}
							participants={participants}
							selectedFiles={selectedFiles}
							customersForSimpleSignature={customers}
							getMetadata={this.handleGetMetadata}
							selectedCategoryOnUpload={selectedCategoryOnUpload}
							setCategoryOnUpload={this.handleSetCategoryOnUpload}
							allowCreateDocuments={allowCreateDocuments}
							filesList={files.data.filter((file) =>
								this.matchWithFileWithCategory(category, file)
							)}
						/>
					))}
				</div>
			)
		})
	}

	handleGetMetadata = ({
		document,
		metadataFields = this.state.metadataUpdates.metadataFields,
		filesToAttachMetadata = this.state.metadataUpdates.filesToAttachMetadata,
		categoryId = null,
		mode = 'upload',
		draggableData = null,
	}) => {
		this.handleSetModal('updateMetadata', true)

		this.setState({
			metadataUpdates: {
				categoryId,
				mode,
				metadata: document
					? {
							id: document.id,
							title: document.title,
							metadata: document.metadata,
					  }
					: {},
				filesToAttachMetadata,
				draggableData,
				metadataFields,
			},
		})
	}

	handleSetModal = (modalType, status) => {
		const { initPolling, disablePolling, enablePolling } = this.props

		if (enablePolling && modalType === 'sendExternalSign') {
			status ? disablePolling() : initPolling()
		}

		this.setState({
			modals: {
				...this.state.modals,
				[modalType]: status,
			},
		})
	}

	handleSetCategoryOnUpload = (category) => {
		this.setState({
			selectedCategoryOnUpload: category,
		})
	}

	handleSuccessUpdateMetadata = () => {
		this.handleSetModal('updateMetadata', false)

		this.setState({
			metadataUpdates: metadataDefaultState,
		})
	}

	render() {
		const { fetching, metadataUpdates, documentsToSign, modals } =
			this.state
		const { onDragStart, categories, sign, entityId, entityType, participantsParent } = this.props

		const modalUpdateDocumentMetadataProps = {
			title:
				metadataUpdates.filesToAttachMetadata.length > 1
					? 'Обновление данных документов'
					: 'Обновление данных документа',
			opened: modals.updateMetadata,
			onClose: () => this.handleSetModal('updateMetadata', false),
		}

		const updateDocumentMetadataFormProps = {
			...metadataUpdates,
			participantsParent,
			entityId,
			entityType,
			fetching: fetching.metadata,
			onSuccess: this.handleSuccessUpdateMetadata,
		}

		const dragDropContextOptions = {
			onDragEnd: this.handleDragEnd,
			onDragStart,
		}

		const sendDocumentsToSignProps = {
			modal: {
				className: 'document-module-sign',
				title: 'Отправка и подписание',
				opened: modals.sendExternalSign,
				subTitle: documentsToSign
					.map((item) => fileUtils.removeFileExtension(item.title))
					.join(', '),
				onClose: this.handleCloseSendDocumentsToSign,
			},
			form: {
				documentsToSign,
				customersForSimpleSignature: sign?.participants || null,
				context: { id: entityId, type: entityType },
				customers: sign?.participants || null,
				onClose: this.handleCloseSendDocumentsToSign,
			},
		}

		return (
			<div className='tabs-block-wrapper'>
				<ModalDialog {...modalUpdateDocumentMetadataProps}>
					<UpdateDocumentMetadata {...updateDocumentMetadataFormProps} />
				</ModalDialog>
				<ModalDialog {...sendDocumentsToSignProps.modal}>
					<SendDocumentsToSign {...sendDocumentsToSignProps.form} />
				</ModalDialog>
				{categories.fetching ? (
					<div className='document-module-loading'>
						<Loading />
					</div>
				) : (
					<DragDropContext {...dragDropContextOptions}>
						{this.renderRequiredCategories()}
					</DragDropContext>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	...state.document,
	modalDialogsShow: state.modalDialogs,
})

const mapDispatchToProps = {
	onDragEnd,
	onDragStart,
	setModalShow,
	renameDocument,
	updateMetadata,
	sendExternalSign,
	defineParticipantsToSign,
	documentSetEntityId,
}

export default connect(mapStateToProps, mapDispatchToProps)(DocumentModule)
