import { brokerSpec } from './brokerSpec'
import { calculation } from './calculation'
import { call } from './call'
import { lead } from './lead'
import { loan } from './loan'
import { main } from './main'
import { participant } from './participant'
import { status } from './status'
import { transition } from './transition'
import { worker } from './worker'

export const application = {
	main,
	call,
	brokerSpec,
	calculation,
	loan,
	participant,
	worker,
	lead,
	transition,
	status,
}

export namespace Application {
	export type Main = ReturnType<typeof main>
	export type Worker = ReturnType<typeof worker>
	export type Call = ReturnType<typeof call>
	export type BrokerSpec = ReturnType<typeof brokerSpec>
	export type Calculation = ReturnType<typeof calculation>
	export type Loan = ReturnType<typeof loan>
	export type Lead = ReturnType<typeof lead>
	export type Participant = ReturnType<typeof participant>
	export type Status = ReturnType<typeof status>
	export type Transition = ReturnType<typeof transition>
}
