import { DefaultValues } from 'helpers/convertHelper'

export const additionalRequest = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		type: model.const(
			data?.type,
			{
				ACTUAL_VALUE: 'ACTUAL_VALUE',
				CONDITION: 'CONDITION',
				REQUIRED_ACTION: 'REQUIRED_ACTION',
				STOP_FACTOR_MEETING: 'STOP_FACTOR_MEETING',
				ACTUAL_VALUE_2: 'ACTUAL_VALUE_2',
				CONDITION_2: 'CONDITION_2',
				REQUIRED_ACTION_2: 'REQUIRED_ACTION_2',
			} as const
		),
		value: model.string(['value']),
		comment: model.string(['comment']),
		evaluationEntity: {
			id: model.string(['evaluationEntity', 'id']),
			type: model.const(data?.evaluationEntity?.type, {
				CREDIT_APPLICATION: 'CREDIT_APPLICATION',
				PERSON: 'PERSON',
				ENTREPRENEUR: 'ENTREPRENEUR',
				ORGANIZATION: 'ORGANIZATION',
				FACILITY: 'FACILITY',
			} as const),
		},
		creditPolicyIds: model.array<string[]>(['creditPolicyIds']),
	}
}
