import cx from 'clsx'
import PropTypes from 'prop-types'

import './Loading.scss'

const Loading = ({ min, max, white, className }) => (
	<div
		className={cx('main-loading', {
			min,
			max,
			white,
			[className]: !!className,
		})}
	>
		<div className='lds-ring-default'>
			<div></div>
			<div></div>
			<div></div>
			<div></div>
		</div>
	</div>
)

Loading.propTypes = {
	min: PropTypes.bool,
	max: PropTypes.bool,
	white: PropTypes.bool,
	className: PropTypes.string,
}

export default Loading
