import { URLBuilder } from 'api/helpers'
import type { EntityId } from 'api/types/EntityId'
import type { Options } from 'api/types/Options'
import type { Pageable } from 'api/types/Pageable'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type { Entrepreneur } from 'converters/entrepreneur'
import { entrepreneur } from 'converters/entrepreneur'
import { mergeDeepRight } from 'ramda'
import type { EntrepreneurFilter } from 'reducers/entrepreneur/list'
import type { OrganizationFilter } from 'reducers/organization/list'

import type { Actions } from './types/Participant'
import type { CommonApi } from './types'

const commonEntrepreneurApi: CommonApi<
	Entrepreneur.Main,
	OrganizationFilter,
	Record<string, any>,
	Record<string, any>
> = {
	get: (id: EntityId) =>
		axios.get<Entrepreneur.Main>(`v2/entrepreneurs/${id}`).then((response) =>
			Object.assign({}, response, {
				data: entrepreneur.main(response.data),
			})
		),

	search: async ({ type = 'RSQL', filter = {}, sort, size, page }: Options<EntrepreneurFilter>) => {
		const { query, ids } = filter
		const params = new URLBuilder()

		const re = (value: string) =>
			`=re="(?i)(?=.*${value.trim().replace(/\s+/g, ')(?=.*').replace(/"/g, '\\"')}).+"
		`

		if (type === 'RSQL') {
			params.addFilter(Number(query) ? 'taxId' : 'name', query, re).addFilter('id', ids, 'inList')
		}

		return axios
			.get('/v2/entrepreneurs', {
				params: {
					sort,
					size,
					page,
					type,
					filter: type === 'TEXT' ? query : params.build('RSQL'),
				},
				$$requestName: 'entrepreneur.search',
			})
			.then(
				(response) =>
					mergeDeepRight(response, {
						data: {
							content: response.data.content.map(entrepreneur.main),
						},
					}) as AxiosResponse<Pageable<Entrepreneur.Main>>
			)
	},

	create: (data: Record<string, any>) =>
		axios.post<Entrepreneur.Main>('v2/entrepreneurs', data).then((response) =>
			Object.assign({}, response, {
				data: entrepreneur.main(response.data),
			})
		),

	update: (id: EntityId, form: Record<string, any>) =>
		axios.patch(`/v2/entrepreneurs/${id}`, form, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),
}

export default {
	...commonEntrepreneurApi,
	actions: (id: EntityId, actions: unknown, contexts?: unknown) =>
		axios.post(`/v2/entrepreneurs/${id}/actions`, { actions, contexts }),
} as typeof commonEntrepreneurApi & Actions
