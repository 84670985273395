import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { commonActions } from 'reducers/common'

interface State {
	categories: any[]
	fetching: boolean

	[key: string]: any
}

const initialState: State = {
	categories: [],
	fetching: false,
}

const inspector = createSlice({
	name: 'fileInspector/inpector',
	initialState,
	reducers: {
		setField(
			state,
			{ payload: { field, value } }: PayloadAction<{ field: keyof State; value: any }>
		) {
			state[field] = value
		},

		pushCategory(state, { payload }: PayloadAction<any>) {
			state.categories.push(payload)
		},

		setFiles(
			state,
			{ payload: { categoryId, files } }: PayloadAction<{ categoryId: string; files: any }>
		) {
			const categoryIndex = state.categories.findIndex((el: any) => el.id === categoryId)

			state.categories[categoryIndex].$$files = files
		},

		setFileInfoAfterPost(
			state,
			{
				payload: { categoryId, fileId, newTitle, metadata = {} },
			}: PayloadAction<{
				categoryId: string
				fileId: string
				newTitle: string
				metadata: Record<string, any>
			}>
		) {
			const categoryIndex = state.categories.findIndex((el: any) => el.id === categoryId)
			const fileIndex = state.categories[categoryIndex].$$files.findIndex(
				(el: any) => el.id === fileId
			)

			state.categories[categoryIndex].$$files[fileIndex].processing = false
			state.categories[categoryIndex].$$files[fileIndex].title = newTitle
			state.categories[categoryIndex].$$files[fileIndex].metadata = metadata
		},

		setFileUrl(
			state,
			{
				payload: { categoryId, fileId, newUrl },
			}: PayloadAction<{ categoryId: string; fileId: string; newUrl: string }>
		) {
			const categoryIndex = state.categories.findIndex((el: any) => el.id === categoryId)
			const fileIndex = state.categories[categoryIndex].$$files.findIndex(
				(el: any) => el.id === fileId
			)

			state.categories[categoryIndex].$$files[fileIndex].url = newUrl
		},

		removeFile(
			state,
			{ payload: { categoryId, fileId } }: PayloadAction<{ categoryId: string; fileId: string }>
		) {
			const categoryIndex = state.categories.findIndex((el: any) => el.id === categoryId)
			const fileIndex = state.categories[categoryIndex].$$files.findIndex(
				(el: any) => el.id === fileId
			)

			state.categories[categoryIndex].$$files = state.categories[categoryIndex].$$files.filter(
				(_: any, i: number) => i !== fileIndex
			)
		},

		reset: commonActions.reset(initialState),
	},
})

export const { actions } = inspector
export default inspector.reducer
