import { DefaultValues } from 'helpers/convertHelper'

export const worker = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		workerId: model.string(['workerId']),
		workerType: model.string(['workerType']),
		assignmentExpression: model.string(['assignmentExpression']),
		active: model.null<boolean>(['active']),
	}
}
