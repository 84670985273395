import { DefaultValues } from 'helpers/convertHelper'

export const program = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		name: model.string(['name']),
		legalName: model.string(['legalName']),
		annualInterestRate: model.null<number>(['annualInterestRate']),
		annualAdditionalAccrualsRate: model.null<number>(['annualAdditionalAccrualsRate']),
	}
}
