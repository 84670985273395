import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { Person } from 'converters/person'
import { commonActions } from 'reducers/common'
import type { Nullable } from 'ts-toolbelt/out/Union/Nullable'
import type { TUtils } from 'types'
import type { RootState } from 'types/redux'
import type { Maybe } from 'types/util'

const initialState = {
	data: null as TUtils.Maybe<Person.Main>,
	fetching: false,
	spec: {
		qualifiedSignatureProvider: null as Nullable<string>,
		application: null as Nullable<string>,
	},
	identificationsOrgNames: {} as Record<string, string>,
	nationalities: [] as Person.Nationality[],
	isPasswordReseted: false,
	LK: null as Maybe<boolean>,
}

export const selectPersonSingle = createSelector(
	(state: RootState) => state.person.single.data,
	(data) => data
)

const single = createSlice({
	name: 'person/single',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Person.Main>) {
			state.data = payload
		},

		setNationalities(state, { payload }: PayloadAction<Person.Nationality[]>) {
			state.nationalities = payload
		},

		setSpec(state, { payload }: PayloadAction<Partial<(typeof initialState)['spec']>>) {
			state.spec = { ...state.spec, ...payload }
		},

		setLK(state, { payload }: PayloadAction<Maybe<boolean>>) {
			state.LK = payload
		},

		setIdentificationsOrgNames(state, { payload }: PayloadAction<Record<string, string>>) {
			state.identificationsOrgNames = payload
		},

		setIsPasswordReseted(state, { payload }: PayloadAction<boolean>) {
			state.isPasswordReseted = payload
		},

		updateCertificate(state, { payload }: PayloadAction<Person.Main['konturInfo']>) {
			if (state.data) state.data.konturInfo = payload
		},

		deleteCertificateForm(state) {
			if (state.data?.konturInfo) state.data.konturInfo.form = null
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

const { actions } = single

export { actions }
export default single.reducer
