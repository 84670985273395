import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { actions } from 'reducers/microsoft'
import { workerSelector } from 'reducers/worker'
import type { TAction } from 'types/redux'

import { utils } from 'helpers'
import { authorizedWorker } from 'helpers/authorizedWorker'

/**
 * @param {string} accountType
 * @param {string=} userId - аккаунт другого пользователя
 * @returns {*}
 */
export const getUserAccount =
	(accountType: string, userId?: string): TAction<string | undefined> =>
	(_, getState) => {
		const accounts = []

		if (userId) {
			const worker = workerSelector.selectById(getState(), userId)

			if (worker) {
				accounts.push(...worker.accounts)
			}
		} else {
			const currentUser = workerSelector.selectById(getState(), authorizedWorker.getId())
			if (currentUser) accounts.push(...currentUser.accounts)
		}

		const id = accounts.find(({ type }) => type === accountType)?.id

		return id
	}

export const getMicrosoftUser = (): TAction<Promise<void>> => async (dispatch) => {
	try {
		dispatch(actions.update({ fetching: true }))

		const { data } = await api.microsoft.getUser()

		dispatch(actions.update({ data, fetching: false }))
	} catch (error) {
		dispatch(actions.update({ fetching: false }))
		dispatch(
			addServerError({
				details: utils.getDetailsFromError(error),
				text: 'Для работы необходима учетная запись microsoft',
			})
		)
	}
}
