import { DefaultValues } from 'helpers/convertHelper'

import { condition } from './condition'
import { option } from './option'
import { requiredAction } from './requiredAction'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		status: model.string(['status']),
		publicationEnabled: model.null<boolean>(['publicationEnabled']),
		syncEnabled: model.null<boolean>(['syncEnabled']),
		selectedVariant: data.selectedVariant
			? {
					id: model.string(['selectedVariant', 'id']),
					status: model.string(['selectedVariant', 'status']),
					creditProgramName: model.string(['selectedVariant', 'creditProgramName']),
					annualInterestRate: model.null<number>(['selectedVariant', 'annualInterestRate']),
					loanAmount: model.null<number>(['selectedVariant', 'loanAmount']),
					maxLoanAmount: model.null<number>(['selectedVariant', 'maxLoanAmount']),
					loanDurationMonths: model.null<number>(['selectedVariant', 'loanDurationMonths']),
					monthlyPaymentAmount: model.null<number>(['selectedVariant', 'monthlyPaymentAmount']),
					servicePayment: data.selectedVariant.servicePayment
						? {
								totalAmount: model.null<number>([
									'selectedVariant',
									'servicePayment',
									'totalAmount',
								]),
								monthlyAmount: model.null<number>([
									'selectedVariant',
									'servicePayment',
									'monthlyAmount',
								]),
								from: model.null<number>(['selectedVariant', 'servicePayment', 'from']),
								to: model.null<number>(['selectedVariant', 'servicePayment', 'to']),
						  }
						: null,
					conditions: model.array(['selectedVariant', 'conditions']).map(condition),
					requiredActions: model.array(['selectedVariant', 'requiredActions']).map(requiredAction),
					options: model.array(['selectedVariant', 'options']).map(option),
			  }
			: null,
	}
}
