import { DefaultValues } from 'helpers/convertHelper'

export const bankForm = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		bankName: model.string(['bankName']),
		accountNumber: model.string(['accountNumber']),
		correspondentAccount: model.string(['correspondentAccount']),
		bic: model.string(['bic']),
	}
}
