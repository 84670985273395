export const CALCULATOR_DEFAULTS = {
	period: 12,
	loan: 1000000,
	type: 'ANNUITY',
	model: 'KPK',
	percent: 0,
	payment: 0,
}

export const ENTITY_TYPES = {
	APPLICATION: 'APPLICATION',
	LOAN: 'LOAN',
	LEAD: 'LEAD',
	DEPOSIT: 'DEPOSIT',
	DELIVERY: 'DELIVERY',
	ORGANIZATION: 'ORGANIZATION',
	ENTREPRENEUR: 'ENTREPRENEUR',
	ROSREESTR_REGISTRATION: 'ROSREESTR_REGISTRATION',
	FACILITY: 'FACILITY',
	PERSON: 'PERSON',
	INVESTMENT_LEAD: 'INVESTMENT_LEAD',
} as const

export const PARTICIPANT_ROLES = {
	APPLICANT: 'Заявитель',
	BORROWER: 'Заёмщик',
	INVESTOR: 'Кредитор',
	OWNER: 'Залогодатель',
	PLEDGEE: 'Залогодержатель',
	WARRANTOR: 'Поручитель',
	DIRECTOR: 'Директор',
	FOUNDER: 'Учредитель',
	SIGNATORY: 'Подписант',
	CONFIDANT: 'Доверенное лицо',
} as const

export const ROLES_VALUES = {
	APPLICANT: 'APPLICANT',
	BORROWER: 'BORROWER',
	INVESTOR: 'INVESTOR',
	OWNER: 'OWNER',
	PLEDGEE: 'PLEDGEE',
	WARRANTOR: 'WARRANTOR',
	DIRECTOR: 'DIRECTOR',
	FOUNDER: 'FOUNDER',
} as const

export const LEAD_ROLES = [
	{ value: 'Заявитель', label: 'Заявитель', id: 'APPLICANT' },
	{ value: 'Заёмщик', label: 'Заёмщик', id: 'BORROWER' },
	{ value: 'Залогодатель', label: 'Залогодатель', id: 'OWNER' },
	{ value: 'Поручитель', label: 'Поручитель', id: 'WARRANTOR' },
]

export const ROLES = [
	{ value: 'Заявитель', label: 'Заявитель', id: 'APPLICANT' },
	{ value: 'Заёмщик', label: 'Заёмщик', id: 'BORROWER' },
	{ value: 'Кредитор', label: 'Кредитор', id: 'INVESTOR' },
	{ value: 'Залогодатель', label: 'Залогодатель', id: 'OWNER' },
	{ value: 'Поручитель', label: 'Поручитель', id: 'WARRANTOR' },
]

export const WORKER_ROLES = {
	LAWYER: 'Юрист',
	MANAGER: 'Менеджер',
	MARKETER: 'Маркетолог',
	COLLECTOR: 'Коллектор',
	SUPERVISOR: 'Супервизор',
} as const

export const ORG_ROLES = [
	{ value: 'Директор', id: 'DIRECTOR', label: 'Директор' },
	{ value: 'Учредитель', id: 'FOUNDER', label: 'Учредитель' },
	{ value: 'Доверенное лицо', id: 'CONFIDANT', label: 'Доверенное лицо' },
	{ value: 'Подписант', id: 'SIGNATORY', label: 'Подписант' },
]

export const SCORING_ACTIVITY_RUS = {
	ACTIVE: 'действующее',
	ELIMINATING: 'в стадии ликвидации',
	ELIMINATED: 'ликвидировано',
	REORGANIZATION: 'в стадии реорганизации',
	UNKNOWN: 'неизвестно',
} as const

export const ACTIVITY_STATUS_RUS = {
	ACTIVE: 'Действующее',
	ELIMINATING: 'В стадии ликвидации',
	ELIMINATED: 'Ликвидировано',
	REORGANIZATION: 'В стадии реорганизации',
	BANKRUPTCY: 'В стадии банкротства',
	ERRONEOUS_REGISTRATION: 'Регистрация признана ошибочной',
	UNKNOWN: 'Неизвестно',
} as const

export const KONTUR_CERTIFICATE_STATUSES = {
	CREATED: 'CREATED',
	VALIDATING: 'VALIDATING',
	VALIDATED: 'VALIDATED',
	VALIDATION_ERROR: 'VALIDATION_ERROR',
	FREEZED: 'FREEZED',
	RELEASING: 'RELEASING',
	RELEASED: 'RELEASED',
} as const

export const ROSREESTR_ENCUMBRANCE_STATUSES = {
	GENERATING_STATEMENTS: 'GENERATING_STATEMENTS',
	WAITING_SIGN_STATEMENTS: 'WAITING_SIGN_STATEMENTS',
	VALIDATION_ERROR: 'VALIDATION_ERROR',
	READY_TO_SEND: 'READY_TO_SEND',
	SENDING: 'SENDING',
	WAITING_SERVICE_PAYMENT: 'WAITING_SERVICE_PAYMENT',
	SENT: 'SENT',
	ACCEPTED: 'ACCEPTED',
	AWAITING_GOV_FEE_PAYMENT: 'AWAITING_GOV_FEE_PAYMENT',
	PROCESSING: 'PROCESSING',
	PROCESSED: 'PROCESSED',
	SUSPEND: 'SUSPEND',
	ERROR: 'ERROR',
	REJECTED: 'REJECTED',
	TIME_OUT: 'TIME_OUT',
	CANCELED: 'CANCELED',
	STATEMENTS_GENERATED: 'STATEMENTS_GENERATED',
	FORCE_STOPPED: 'FORCE_STOPPED',
} as const

export const ROSREESTR_ENCUMBRANCE_STATUSES_RUS = {
	GENERATING_STATEMENTS: 'Для документооборота генерируются заявления',
	WAITING_SIGN_STATEMENTS: 'Заявления для документооборота сгенерированы и ожидают подписи',
	VALIDATION_ERROR: 'В документообороте обнаружены ошибки валидации',
	READY_TO_SEND: 'Документооборот готов к отправке в Росреестр',
	SENDING: 'Документооборот отправляется в Росреестр',
	WAITING_SERVICE_PAYMENT: 'Документооборот ожидает оплаты услуги',
	SENT: 'Документооборот отправлен в Росреестр',
	ACCEPTED: 'Документооборот принят Росреестром',
	AWAITING_GOV_FEE_PAYMENT: 'Для документооборота начислена пошлина и она ожидает оплаты',
	PROCESSING: 'Документооборот находится в обработке у сотрудника Росреестра',
	PROCESSED: 'Сотрудник Росреестра завершил обработку документооборота',
	SUSPEND: 'Сотрудник Росреестра приостановил обработку документооборота',
	ERROR: 'Обработка документооборота завершена с ошибкой. Техническая ошибка',
	REJECTED: 'Обработка документооборота отклонена Росреестром',
	TIME_OUT: 'Истек срок оплаты госпошлины',
	CANCELED: 'Ожидание ответа от Росреестра остановлено. Доступен только для выписок',
	STATEMENTS_GENERATED: 'Заявления сгенерированы',
	FORCE_STOPPED: 'Ожидание ответа от Росреестра принудительно остановлено',
} as const

export const KONTUR_ERRORS_RUS = {
	WrongFieldValue:
		'Значение поля не соответствует требуемому формату. Поле, формат которого не соответствует, указано в описании ошибки',
	AbsenceOfRequiredField: 'Не указано одно из обязательных полей',
	DuplicatedExternalId: 'Указанный в запросе внешний идентификатор уже был использован ранее',
	DuplicateDocument: 'Указано более одного документа, удостоверяющего личность',
	MismatchDocumentRequisites:
		'Реквизиты документа удостоверяющего личность в описании субъекта и в приложенном документе не совпадают',
	UnexistentRepresentative: 'Представитель для пары физическое лицо и организация не был создан',
	UnexistentSubject: 'Не удалось найти указанного субъекта',
	NotAllowedFormState:
		'Статус формы не позволяет удалить форму. Форму нельзя удалить если она находится на проверке, выпуске или уже выпущена',
	BigContentSize: 'Размер контента превышает 10 мб',
	UnsupportedContentType: '	Указан контент, формат которого не поддерживается методом',
	UnexistentContent: 'Не удалось найти контент в базе по указанному идентификатору',
	IncompleteDocumentList: 'В форме указан неполный комплект документов',
	WrongAddress: 'Адрес субъекта указан в неправильном формате',
} as const

export const STATEMENT_TYPE = {
	BASE: 'BASE',
	OWNERSHIP: 'OWNERSHIP',
	INFO: 'INFO',
	UNKNOWN: 'UNKNOWN',
} as const

export const ENCUMBRANCE_TYPE = {
	IMPOSITION: 'ENCUMBRANCE_IMPOSITION',
	MORTGAGE_ISSUE: 'LOAN_MORTGAGE_ISSUE',
	CESSATION: 'ENCUMBRANCE_CESSATION',
} as const

export const ACCOUNT_TYPE = {
	CURRENT: 'CURRENT',
	CHECKING: 'CHECKING',
} as const

export const ACCOUNT_STATUS = {
	REQUESTED: 'REQUESTED',
	RESERVED: 'RESERVED',
	OPENED: 'OPENED',
	CLOSED: 'CLOSED',
} as const

export const SPECIAL_ACCOUNT_ID = '15aa3ee8-9f8f-415d-b769-d6fdff1d781a'
