import { DefaultValues } from 'helpers/convertHelper'

export const referralEntity = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		type: model.string(['type']),
		title: model.string(['title']),
		referralProgramUrl: model.string(['referralProgramUrl']),
		referral: {
			id: model.string(['referral', 'id']),
			fullName: model.string(['referral', 'fullName']),
		},
		accruals: model.array(['accruals']).map((_, i) => ({
			date: model.string(['accruals', i, 'date']),
			amount: model.null<number>(['accruals', i, 'amount']),
		})),
	}
}
