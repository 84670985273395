import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Application } from 'converters/application'
import { commonActions } from 'reducers/common'
import type { TUtils } from 'types'

export interface ApplicationFilter {
	status: TUtils.Maybe<string[]>
	channel: TUtils.Maybe<string[]>
	worker: TUtils.Maybe<string[]>
	broker: TUtils.Maybe<string[]>
	facility: TUtils.Maybe<string[]>
	participant: TUtils.Maybe<string[]>
	maxLoanAmount: TUtils.Maybe<number>
	minLoanAmount: TUtils.Maybe<number>
	minCreatedDate: TUtils.Maybe<string>
	maxCreatedDate: TUtils.Maybe<string>
}

const filter: ApplicationFilter = {
	status: null,
	channel: null,
	worker: null,
	broker: null,
	facility: null,
	participant: null,
	maxLoanAmount: null,
	minLoanAmount: null,
	minCreatedDate: null,
	maxCreatedDate: null,
}

const initialState = {
	data: [] as Application.Main[],
	fetching: false,
	filter,
}

const list = createSlice({
	name: 'application/list',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Application.Main[]>) {
			state.data = payload
		},

		// Обновление записи в списке
		updateRecord(
			state,
			{ payload }: PayloadAction<{ id: string; key: keyof Application.Main; value: unknown }>
		) {
			const { id, key, value } = payload

			const recordIndex = state.data.findIndex((item) => item.id === id)

			if (recordIndex === -1) {
				return
			}

			// @ts-ignore
			// игнор бага, в котором левый операнд вычисляется как never
			state.data[recordIndex][key] = value
		},

		setFilter: commonActions.setFilter(),
		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

const { actions } = list

export { actions }
export default list.reducer
