import * as actionTypes from 'const/actionTypes'
import Immutable from 'immutable'

import { immutableize } from 'helpers'

const initialState = Immutable.fromJS({
	participants: [],
})

const sign = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.DOCUMENTS_SIGN_SET_PARTICIPANTS:
			return state.set('participants', action.data)

		case actionTypes.DOCUMENTS_SIGN_UPDATE_PARTICIPANT_SIGNATURES: {
			const participantIndex = state
				.get('participants')
				.findIndex((item) => item.get('customerId') === action.customerId)

			const documentIndex = state
				.getIn(['participants', participantIndex, 'signableDocuments'])
				.findIndex((item) => item.get('id') === action.documentId)

			return state.setIn(
				['participants', participantIndex, 'signableDocuments', documentIndex, 'signatures'],
				action.data
			)
		}

		case actionTypes.DOCUMENTS_SIGN_RESET:
			return initialState

		default:
			return state
	}
}

export default immutableize(sign)
