import type { Maybe } from 'types/util'

import { DefaultValues } from 'helpers/convertHelper'

export const responseDataAvgCreditPayments = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		avgCreditPayments: model.array(['avgCreditPayments']).map((_, i) => ({
			avgPayment: model.null<number>(['avgCreditPayments', i, 'avgPayment']),
			calculationDate: model.string(['avgCreditPayments', i, 'calculationDate']),
			creditId: model.string(['avgCreditPayments', i, 'creditId']),
			bureauOgrn: model.string(['avgCreditPayments', i, 'bureauOgrn']),
		})),
		avgPayment: model.array(['avgCreditPayments']).reduce((acc, { avgPayment }) => {
			if (avgPayment == null) return acc

			acc = (acc ?? 0) + avgPayment

			return acc
		}, null) as Maybe<number>,
	}
}
