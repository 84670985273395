import { setPagination, solveParamsPagination } from 'actions/listParameters'
import { resetPersonList } from 'actions/person'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import type { Pageable } from 'api/types/Pageable'
import type { Entrepreneur } from 'converters/entrepreneur'
import { prop } from 'ramda'
import { actions } from 'reducers/entrepreneur/list'
import type { TAction } from 'types/redux'

import { utils } from 'helpers'
import { DefaultValues } from 'helpers/convertHelper'

export const addEntrepreneurInList = actions.add
export const resetEntrepreneurList = actions.reset
export const updateEntrepreneurInList = actions.update
export const deleteEntrepreneurInList = actions.delete
export const entrepreneurListChangeFilterParameter = actions.setFilter

// сброс на те случаи, когда юзер на табе
export const resetEntrepreneursPage = (): TAction => (dispatch) => {
	dispatch(actions.reset())
	dispatch(resetPersonList())
}

/**
 * @description - Запрашивает организации и склеивает их с входным обьектом, далее записывает в стор
 * @param {array} minEntrepreneurs - Входной массив
 */
export const fetchEntrepreneursWithMerge =
	(minEntrepreneurs: Record<string, any>[]): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			if (!utils.hasObjectLength(minEntrepreneurs)) return
			dispatch(actions.setFetchingStatus(true))

			const ids: string[] = minEntrepreneurs.map(prop('id'))

			const { data } = await api.entrepreneur.search({ filter: { ids } })

			const entrepreneursList = data.content.map((item) => {
				const matchedItem = minEntrepreneurs.find((ent) => ent.id === item.id)

				const defaultValue = new DefaultValues(matchedItem)

				return Object.assign({}, item, {
					mainContact: defaultValue.null(['mainContact']),
					part: defaultValue.null(['part']),
					roles: defaultValue.array(['roles']),
				})
			})

			dispatch(actions.setData(entrepreneursList))
			dispatch(actions.setFetchingStatus(false))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Ошибка загрузки ИП',
					details: utils.getDetailsFromError(error),
				})
			)

			dispatch(actions.setFetchingStatus(false))
		}
	}

export const getEntrepreneurList =
	(parameters = {}): TAction<Promise<Pageable<Entrepreneur.Main> | void>> =>
	async (dispatch, getState) => {
		try {
			dispatch(actions.setFetchingStatus(true))

			const payload = {
				...dispatch(solveParamsPagination('number')),
				...parameters,
				filter: getState().entrepreneur.list.filter,
			}

			const { data } = await api.entrepreneur.search(payload)

			dispatch(actions.setData(data.content))

			const { totalElements, number, totalPages } = data

			dispatch(
				setPagination({
					totalPages,
					totalElements,
					paged: true,
					currentPage: number,
				})
			)

			dispatch(actions.setFetchingStatus(false))

			return data
		} catch (error) {
			dispatch(actions.setFetchingStatus(false))

			dispatch(
				addServerError({
					text: 'Ошибка загрузки ИП',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}
