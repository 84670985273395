import type { EntityId } from 'api/types/EntityId'
import axios from 'axios'

export default {
	inputs: (payload: { owner: { id: string; type: string }; categoryId: string }) =>
		axios.post(`/v3/edm/constructor-inputs`, payload),

	document: (
		entityId: EntityId,
		variables: unknown,
		type: unknown,
		format: unknown,
		entityType: unknown,
		category: unknown
	) =>
		axios.post(`/v1/documents/constructor`, {
			entityId,
			variables,
			type,
			format,
			entityType,
			category,
		}),

	createDocument: (data: unknown) =>
		axios.post('/v1/constructor/raw-document', data, { responseType: 'blob' }),
}
