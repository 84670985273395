import { main } from './main'
import { source } from './source'

export const person = {
	main,
	source,
}

export namespace ScoringPerson {
	export type Main = ReturnType<typeof main>
	export type Source = ReturnType<typeof source>
}
