import { DefaultValues } from 'helpers/convertHelper'

export const supportedConfirmationDocument = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		name: model.string(['name']),
	}
}
