import { creditObligation } from 'converters/refinancing/creditObligation'
import { enforcementProceedingObligation } from 'converters/refinancing/enforcementProceedingObligation'

import { DefaultValues } from 'helpers/convertHelper'

export const ownerObligations = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		title: model.string(['title']),
		creditObligations: model.array(['creditObligations']).map(creditObligation),
		enforcementProceedingObligations: model
			.array(['enforcementProceedingObligations'])
			.map(enforcementProceedingObligation),
	}
}
