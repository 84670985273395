import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	accuracy: 0,
	averagePrice: 0,
	averageSqmPrice: 0,

	facility: {},
	dirty: false,
	advertisements: [] as any[],
}

type AddOrSetPayloadType = PayloadAction<any>

type UpdatePayloadType = PayloadAction<Record<string, any>>

const assessmentSlice = createSlice({
	name: 'assessment',
	initialState,
	reducers: {
		setAdvertisements(state, action: AddOrSetPayloadType) {
			state.advertisements = action.payload
		},

		setFacility(state, action: AddOrSetPayloadType) {
			state.facility = action.payload
		},

		setDirty(state, action: AddOrSetPayloadType) {
			state.dirty = action.payload
		},

		toggleAdvertisement(state, action: PayloadAction<string>) {
			const advertisement = state.advertisements.find(({ id }) => id === action.payload)
			advertisement.$$active = !advertisement.$$active
		},

		setAssessment(state, action: UpdatePayloadType) {
			const { accuracy, averagePrice, averageSqmPrice } = action.payload

			return {
				...state,
				accuracy,
				averagePrice,
				averageSqmPrice,
			}
		},

		reset() {
			return initialState
		},
	},
})

export const {
	setAdvertisements,
	setFacility,
	setDirty,
	toggleAdvertisement,
	setAssessment,
	reset,
} = assessmentSlice.actions
export default assessmentSlice.reducer
