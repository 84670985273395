import { DefaultValues } from 'helpers/convertHelper'

import { operation } from './operation'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		createdAt: model.string(['createdAt']),
		modifiedAt: model.string(['modifiedAt']),
		loanId: model.string(['loanId']),
		operations: model.array(['operations']).map(operation).reverse(),
		version: model.null<number>(['version']),
	}
}
