import { combineReducers } from 'redux'

import employees from './employees'
import list from './list'
import productView from './productView'
import single from './single'

export default combineReducers({
	list,
	single,
	employees,
	productView,
})
