import { ACTIVITY_STATUS_RUS } from 'const'

import { DefaultValues } from 'helpers/convertHelper'

import { balanceSheetSummary } from './balanceSheetSummary'
import { creditHistoryAgreement } from './creditHistoryAgreement'
import { director } from './director'
import { founder } from './founder'
import { okved } from './okved'
import { publicAccount } from './publicAccount'

export const form = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		actualAddress: model.null<Record<string, any>>(['actualAddress']),
		legalAddress: model.null<Record<string, any>>(['legalAddress']),
		shortName: model.string(['shortName']),
		fullName: model.string(['fullName']),
		shortOpf: model.string(['shortOpf']),
		statusRus: model.const(data.status, ACTIVITY_STATUS_RUS),
		status: model.string<keyof typeof ACTIVITY_STATUS_RUS>(['status']),
		registrationDate: model.string(['registrationDate']),
		kpp: model.string(['kpp']),
		ogrn: model.string(['ogrn']),
		okpo: model.string(['okpo']),
		oktmo: model.string(['registrationDate']),
		registrationNumberPf: model.string(['registrationNumberPf']),
		registrationDatePf: model.string(['registrationDatePf']),
		registrationNumberFss: model.string(['registrationNumberFss']),
		registrationDateFss: model.string(['registrationDateFss']),
		okato: model.string(['okato']),
		okogu: model.string(['okogu']),
		okopf: model.string(['okopf']),
		okfs: model.string(['okfs']),
		taxAuthorityName: model.string(['taxAuthorityName']),
		efrsbNegative: model.null<boolean>(['efrsbNegative']),
		authorizedCapital: model.null<number>(['authorizedCapital']),
		activitiesCount: model.null<number>(['activitiesCount']),
		foundersCount: model.null<number>(['foundersCount']),
		prosecutorOfficeChecksCount: model.null<number>(['prosecutorOfficeChecksCount']),
		employeesCount: model.null<number>(['employeesCount']),
		arrestedAccountsCount: model.null<number>(['arrestedAccountsCount']),
		licencesCount: model.null<number>(['licencesCount']),
		wonTradesCount: model.null<number>(['wonTradesCount']),
		wonTradesAmount: model.null<number>(['wonTradesAmount']),
		sroMembershipsCount: model.null<number>(['sroMembershipsCount']),
		lastReportingYear: model.null<number>(['lastReportingYear']),
		trademarksCount: model.null<number>(['trademarksCount']),
		directorAndFounderSame: model.null<boolean>(['directorAndFounderSame']),
		inconsistencyOfInformation: model.null<boolean>(['inconsistencyOfInformation']),
		upcomingExclusion: model.null<boolean>(['upcomingExclusion']),
		changesInLast30Days: model.null<boolean>(['changesInLast30Days']),
		negativeInPublicAccounts: model.null<boolean>(['negativeInPublicAccounts']),
		negativeFromChanges: model.null<boolean>(['negativeFromChanges']),
		complementaryActivities: model.array(['complementaryActivities']).map(okved),
		founders: model.array(['founders']).map(founder),
		publicAccounts: model.array(['publicAccounts']).map(publicAccount),
		principalActivity: okved(data.principalActivity),
		assets: balanceSheetSummary(data.assets),
		revenue: balanceSheetSummary(data.revenue),
		creditHistoryAgreement: creditHistoryAgreement(data.creditHistoryAgreement),
		director: director(data.director),
	}
}
