import axios from 'axios'
import type { DecisionDto } from 'converters/decision'
import { decision } from 'converters/decision'

const baseUrl = '/v4/scoring'

declare namespace DecisionApi {
	export type Get = DecisionDto.Main
}

interface EntityParameters {
	entityId: string
	entity: 'applications' | 'leads'
}

export default {
	get: ({ entityId, entity }: EntityParameters) =>
		axios
			.get<DecisionApi.Get>(`${baseUrl}/${entity}/${entityId}/application-decision`)
			.then((response) =>
				Object.assign({}, response, {
					data: decision.main(response.data),
				})
			),

	sync: ({ entityId, entity }: EntityParameters) =>
		axios.post<void>(`${baseUrl}/${entity}/${entityId}/application-decision/sync`),

	publish: ({ entityId, entity }: EntityParameters) =>
		axios.post<void>(`${baseUrl}/${entity}/${entityId}/application-decision/publish`),
}
