import { PARTICIPANTS_TYPES } from 'const/participantsTypes'

import { DefaultValues } from 'helpers/convertHelper'

export const participant = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	const p = model.null<Record<string, any>>(['participant'])

	return {
		id: model.string(['participant', 'id']),
		roles: model.string(['roles']),
		post: model.string(['post']),
		type: model.string(['type']),
		participant: Object.assign({}, p, {
			$$type:
				data.type === PARTICIPANTS_TYPES.PERSON
					? PARTICIPANTS_TYPES.PERSON
					: PARTICIPANTS_TYPES.ORGANIZATION,
		}) as Record<string, any>,
	}
}
