import { URLBuilder } from 'api/helpers'
import type { EntityId } from 'api/types/EntityId'
import axios from 'axios'
import { court } from 'converters/court'
import { mergeDeepRight } from 'ramda'

export default {
	get: (id: EntityId) => {
		return axios.get(`/v1/courts/${id}`).then((response) =>
			mergeDeepRight(response, {
				data: {
					content: court.main(response.data.content),
				},
			})
		)
	},

	search: (options: any) => {
		const params = new URLBuilder()

		params.addFilter('participant.id', options.filter.participant?.id, 'eq')
		params.addFilter('participant.type', options.filter.participant?.type, 'eq')

		return axios
			.get('/v1/courts', {
				params: {
					size: options.size,
					sort: [options.property || '', options.direction || ''].join(',') || undefined,
					page: options.page,
					filter: params.build('RSQL'),
				},
			})
			.then((response) =>
				mergeDeepRight(response, {
					data: {
						content: response.data.content.map(court.main),
					},
				})
			)
	},

	createCroup: (data: unknown) => axios.post('/v1/courts', data),

	updateGroup: (id: EntityId, data: unknown) =>
		axios.patch(`/v1/courts/${id}`, data, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),
}
