import { Component } from 'react'
import { Draggable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { fileInspectorMainSetField, updateFileName } from 'actions/filesInspector'
import cx from 'clsx'
import PropTypes from 'prop-types'

import { fileUtils } from '@creditclubteam/helpers'
import { Input } from 'components/common'

import Controls from './Controls'

import './FileItem.scss'

class FileItem extends Component {
	static propTypes = {
		updateFileId: PropTypes.any,
		id: PropTypes.string.isRequired,
		objectKey: PropTypes.string.isRequired,
		title: PropTypes.string.isRequired,
		index: PropTypes.number.isRequired,
		zoneId: PropTypes.string.isRequired,
		dragging: PropTypes.bool.isRequired,
		processing: PropTypes.bool.isRequired,
		selectedFiles: PropTypes.array.isRequired,

		onClick: PropTypes.func.isRequired,
		updateFileName: PropTypes.func.isRequired,
		fileInspectorMainSetField: PropTypes.func.isRequired,
	}

	state = { newTitle: '' }

	handleClick = (event) => {
		const { onClick, id } = this.props

		onClick(event, id)
	}

	handleRename = () => {
		const { id, title, processing, updateFileId, fileInspectorMainSetField } = this.props

		if (!processing) {
			fileInspectorMainSetField({ field: 'updateFileId', value: id })
			if (updateFileId !== id) this.setState({ newTitle: fileUtils.removeFileExtension(title) })
		}
	}

	handleChangeName = (newTitle) => {
		if (!newTitle) {
			this.setState({ newTitle: '' })
			return
		}

		if (newTitle.match(/[.,!@#$%№^&*{};:'"?+=~\]/\\|[<>]/g)) {
			return
		}

		this.setState({ newTitle })
	}

	handleUpdateName = () => {
		const { id, zoneId, updateFileName } = this.props
		const { newTitle } = this.state

		updateFileName(newTitle, id, zoneId)
	}

	renderMultipleDraggingCount(isSelected, isDragging) {
		const { selectedFiles } = this.props

		if (isSelected && isDragging && selectedFiles.length !== 1)
			return (
				<div className='files-inspector-files-category__item-counts'>{selectedFiles.length}</div>
			)
	}

	renderTitle(isSelected, isDragging) {
		const { title, processing, selectedFiles } = this.props

		if (processing) return 'Файл обрабатывается...'
		else if (isSelected && isDragging && selectedFiles.length !== 1) return 'Несколько файлов'

		return fileUtils.removeFileExtension(title)
	}

	render() {
		const {
			id,
			objectKey,
			index,
			title,
			metadataFields,
			zoneId,
			dragging,
			processing,
			updateFileId,
			selectedFiles,
		} = this.props

		const { newTitle } = this.state

		const isUpdated = updateFileId === id
		const isSelected = Boolean(selectedFiles.find((file) => file.id === id))

		const inputRenameOptions = {
			fadeIn: true,
			value: newTitle,
			autoFocus: true,
			noTransform: true,
			placeholder: 'Имя документа',
			onBlur: this.handleUpdateName,
			onChange: this.handleChangeName,
			className: 'files-inspector-files-category__item-rename',
			onKeyUp: (e) => e.key === 'Enter' && this.handleUpdateName(),
		}

		const titleBlockOptions = {
			onClick: this.handleRename,
			className: cx('files-inspector-files-category__item-title', {
				rename: isUpdated,
			}),
		}

		const controlsOptions = {
			id,
			objectKey,
			metadataFields,
			index,
			title,
			zoneId,
		}

		return (
			<Draggable index={index} draggableId={id}>
				{(provided, snapshot) => (
					<div
						id={id}
						data-test-id='file-in-category'
						ref={provided.innerRef}
						onClick={this.handleClick}
						{...provided.dragHandleProps}
						{...provided.draggableProps}
						className={cx('files-inspector-files-category__item', {
							isSelected,
							isDragging: snapshot.isDragging,
							involved: isSelected && dragging && !snapshot.isDragging,
						})}
					>
						<div {...titleBlockOptions}>
							{isUpdated ? (
								<Input {...inputRenameOptions} />
							) : (
								this.renderTitle(isSelected, snapshot.isDragging)
							)}
						</div>
						{!snapshot.isDragging && !processing && <Controls {...controlsOptions} />}
						{this.renderMultipleDraggingCount(isSelected, snapshot.isDragging)}
					</div>
				)}
			</Draggable>
		)
	}
}

const mapStateToProps = (state) => ({
	...state.filesInspector.main,
})

const mapDispatchToProps = {
	updateFileName,
	fileInspectorMainSetField,
}

export default connect(mapStateToProps, mapDispatchToProps)(FileItem)
