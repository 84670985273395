import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { CalendarEvent } from 'builders'
import { commonActions } from 'reducers/common'

interface State {
	form: CalendarEvent
	fetching: boolean
	fetchingRecurrence: boolean
}

const initialState: State = {
	form: new CalendarEvent(),
	fetching: false,
	fetchingRecurrence: false,
}

const eventForm = createSlice({
	name: 'calendar/eventForm',
	initialState,
	reducers: {
		setFetchingRecurrenceStatus(state, { payload }: PayloadAction<boolean>) {
			state.fetchingRecurrence = payload
		},

		setForm(state, { payload }: PayloadAction<State['form']>) {
			state.form = payload
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions } = eventForm
export default eventForm.reducer
