import { DefaultValues } from 'helpers/convertHelper'

export const participant = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		type: model.string(['type']),
		roles: model.array<string[]>(['roles']),
		metadata: model.null<Record<string, any>>(['metadata']),
		$$type: model.string(['type']),
	}
}
