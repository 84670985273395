import { URLBuilder } from 'api/helpers'
import type { Pageable } from 'api/types/Pageable'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type { Delivery } from 'converters/delivery'
import { delivery } from 'converters/delivery'
import type { Pipeline } from 'converters/pipeline'
import { pipeline } from 'converters/pipeline'
import type { Representative } from 'converters/representative'
import { representative } from 'converters/representative'
import { mergeDeepRight } from 'ramda'
import type { DeliveryFilter } from 'reducers/delivery/list'

import type { Options } from './types/Options'

const baseUrl = '/v1/deliveries'

export default {
	search: ({ filter, size = 10, sort, direction, property, page }: Options<DeliveryFilter>) => {
		const params = new URLBuilder()

		if (filter) {
			params.addFilter('status', filter.status, 'inList')
			params.addFilter('participants.id', filter.participant, 'inList')
			filter.externalId && params.addFilter('externalId', filter.externalId, 'eq')
		}

		return axios
			.get(baseUrl, {
				params: {
					size,
					sort: property && direction ? [property, direction].join(',') : sort || undefined,
					page,
					filter: params.build('RSQL'),
				},
			})
			.then(
				(response) =>
					mergeDeepRight(response, {
						data: {
							content: response.data.content.map(delivery.main),
						},
					}) as AxiosResponse<Pageable<Delivery.Main>>
			)
	},

	get: (id: string) =>
		axios
			.get<Delivery.Main>(`${baseUrl}/${id}`)
			.then((response) => Object.assign({}, response, { data: delivery.main(response.data) })),

	getTransitions: (id: string) =>
		axios.get<Pipeline.Transition[]>(`${baseUrl}/${id}/transitions`).then((response) =>
			Object.assign({}, response, {
				data: response.data.map(pipeline.transition),
			})
		),

	create: (data: Record<string, any>) =>
		axios
			.post<Delivery.Main>(baseUrl, data)
			.then((response) => Object.assign({}, response, { data: delivery.main(response.data) })),

	updateStatus: (id: string, data: Record<string, any>) =>
		axios
			.post(`${baseUrl}/${id}/change-status`, data)
			.then((response) => Object.assign({}, response, { data: delivery.main(response.data) })),

	update: (id: string, data: Record<string, any>) =>
		axios.patch(`${baseUrl}/${id}`, data, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),

	getPipeline: (type: 'ALL' | 'PIPELINE') => axios.get(`${baseUrl}/statuses`, { params: { type } }),
	syncDocument: (id: string) => axios.get(`${baseUrl}/${id}/sync-documents`),

	getRepresentative: (id: string) =>
		axios.get(`/v2/admin/representatives/${id}`).then(
			(response) =>
				Object.assign({}, response, {
					data: representative.delivery(response.data),
				}) as AxiosResponse<Representative.Delivery>
		),

	searchRepresentative: (options: Record<string, any>) => {
		return axios
			.get('/v2/admin/representatives', {
				params: {
					query: options.query,
					size: options.size,
					page: options.page,
					enabled: options.filter?.enabled,
					briefRepresentation: options.filter?.briefRepresentation,
					emailVerified: options.filter?.emailVerified,
				},
				$$requestName: 'representative.search',
			})
			.then(
				(response) =>
					Object.assign({}, response, {
						data: response.data.map(representative.delivery),
					}) as AxiosResponse<Representative.Delivery[]>
			)
	},
}
