import { DefaultValues } from 'helpers/convertHelper'

export const moneyMovementOperation = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
		date: model.string(['date']),
		amount: model.null<number>(['amount']),
		details: model.array(['details']).map((_, i) => ({
			title: model.string(['details', i, 'title']),
			amount: model.null<number>(['details', i, 'amount']),
		})),
	}
}
