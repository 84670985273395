import { DefaultValues } from 'helpers/convertHelper'

export const calculation = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		loan: model.null<number>(['loan']),
		period: model.null<number>(['period']),
		percent: model.null<number>(['percent']),
		type: model.string<'ANNUITY' | 'PERCENT' | 'MIXED'>(['type']),
		model: model.string<'KPK' | 'MKK' | 'PLATFORM' | 'KFLZK_22_9' | 'KFLZK_17_5' | 'KFLZK_13_9'>([
			'model',
		]),
		payment: model.null<number>(['payment']),
		membershipFee: model.null<number>(['membershipFee']),
		monthlyInterestRate: model.null<number>(['monthlyInterestRate']),
		increasedPayment: model.null<number>(['increasedPayment']),
		contractDuration: model.null<number>(['contractDuration']),
	}
}
