import { DefaultValues } from 'helpers/convertHelper'

export const eventCategories = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		categories: model.array(['categories']).map((_, i) => ({
			title: model.string(['categories', i, 'title']),
			eventTypes: model.array(['categories', i, 'eventTypes']).map((_, j) => ({
				id: model.string(['categories', i, 'eventTypes', j, 'id']),
				title: model.string(['categories', i, 'eventTypes', j, 'title']),
				jsonSchema: JSON.parse(model.string(['categories', i, 'eventTypes', j, 'jsonSchema'])),
			})),
		})),
	}
}
