import { DefaultValues } from 'helpers/convertHelper'

export const responseDataPassportValidity = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		valid: model.null<boolean>(['valid']),
		series: model.string(['series']),
		number: model.string(['number']),
	}
}
