import { EXTERNAL_REQUEST_TYPE } from 'const/externalData'

import { DefaultValues } from 'helpers/convertHelper'

import { responseDataAvgCreditPayments } from './responseDataAvgCreditPayments'
import { responseDataCbBlackList } from './responseDataCbBlackList'
import { responseDataCcBlackList } from './responseDataCcBlackList'
import { responseDataCourts } from './responseDataCourts'
import { responseDataCreditHistory } from './responseDataCreditHistory'
import { responseDataFnsTaxIdInvalidity } from './responseDataFnsTaxIdInvalidity'
import { responseDataFssp } from './responseDataFssp'
import { responseDataPassportValidity } from './responseDataPassportValidity'
import { responseDataRfmMoneyBlockedList } from './responseDataRfmMoneyBlockedList'
import { responseDataRfmTerroristList } from './responseDataRfmTerroristList'
import { responseDataRfmWeaponDistributorList } from './responseDataRfmWeaponDistributorList'
import { responseDataSanctionList } from './responseDataSanctionList'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		createdAt: model.string(['createdAt']),
		modifiedAt: model.string(['modifiedAt']),
		version: model.null<number>(['version']),
		status: model.const(data.status, {
			CREATED: 'CREATED',
			COMPLETED: 'COMPLETED',
			ERROR: 'ERROR',
		}),
		statusRus: model.const(data.status, {
			CREATED: 'Создан',
			COMPLETED: 'Успешно завершен',
			ERROR: 'Ошибка',
		}),
		type: model.const(data.type, EXTERNAL_REQUEST_TYPE),
		subject: {
			id: model.string(['id']),
			type: model.const(data?.subject?.type, {
				PERSON: 'PERSON',
				ORGANIZATION: 'ORGANIZATION',
				ENTREPRENEUR: 'ENTREPRENEUR',
			}),
		},
		createdBy: {
			id: model.string(['id']),
			username: model.string(['username']),
		},
		errors: model.array<string[]>(['errors']),
		responseData: {
			[EXTERNAL_REQUEST_TYPE.SANCTIONS_LIST]: responseDataSanctionList(data?.responseData),
			[EXTERNAL_REQUEST_TYPE.UN_SECURITY_COUNCIL_LIST]: responseDataSanctionList(
				data?.responseData
			),
			[EXTERNAL_REQUEST_TYPE.RFM_TERRORIST_LIST]: responseDataRfmTerroristList(data?.responseData),
			[EXTERNAL_REQUEST_TYPE.RFM_WEAPON_DISTRIBUTOR_LIST]: responseDataRfmWeaponDistributorList(
				data?.responseData
			),
			[EXTERNAL_REQUEST_TYPE.RFM_MONEY_BLOCKED_LIST]: responseDataRfmMoneyBlockedList(
				data?.responseData
			),
			[EXTERNAL_REQUEST_TYPE.PASSPORT_VALIDITY]: responseDataPassportValidity(data?.responseData),
			[EXTERNAL_REQUEST_TYPE.CB_BLACK_LIST]: responseDataCbBlackList(data?.responseData),
			[EXTERNAL_REQUEST_TYPE.CC_BLACK_LIST]: responseDataCcBlackList(data?.responseData),
			[EXTERNAL_REQUEST_TYPE.COURT]: responseDataCourts(data?.responseData),
			[EXTERNAL_REQUEST_TYPE.FSSP]: responseDataFssp(data?.responseData),
			[EXTERNAL_REQUEST_TYPE.CREDIT_HISTORY]: responseDataCreditHistory(data?.responseData),
			[EXTERNAL_REQUEST_TYPE.FNS_TAX_ID_INVALIDITY]: responseDataFnsTaxIdInvalidity(
				data?.responseData
			),
			[EXTERNAL_REQUEST_TYPE.AVG_CREDIT_PAYMENT]: responseDataAvgCreditPayments(data?.responseData),
		},
	}
}
