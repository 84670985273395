import { fileUpload } from './fileUpload'
import { instructionStep } from './instructionStep'
import { linkButton } from './linkButton'
import { main } from './main'
import { textBlock } from './textBlock'

export const deliveryTypes = {
	main,
	fileUpload,
	textBlock,
	linkButton,
	instructionStep,
}

export namespace DeliveryTypes {
	export type Main = ReturnType<typeof main>
	export type TextBlock = ReturnType<typeof textBlock>
	export type FileUpload = ReturnType<typeof fileUpload>
	export type LinkButton = ReturnType<typeof linkButton>
	export type InstructionStep = ReturnType<typeof instructionStep>
}
