import { DefaultValues } from 'helpers/convertHelper'

export const passportHistory = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		passportType: model.const(data.passportType, {
			RF_PASSPORT: 'RF_PASSPORT',
			FRGN_PASS: 'FRGN_PASS',
			USSR_PASSPORT: 'USSR_PASSPORT',
		} as const),
		department: model.string(['department']),
		divisionCode: model.string(['divisionCode']),
		issueDate: model.string(['issueDate']),
		number: model.string(['number']),
		series: model.string(['series']),
	}
}
