import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'

const initialState: Record<string, any> = {
	type: PARTICIPANTS_TYPES.PERSON,
}

type SetAction = PayloadAction<{ key: string; value: string }>

const filterSlice = createSlice({
	name: 'localFilter/participant',
	initialState,
	reducers: {
		set(state, { payload: { key, value } }: SetAction) {
			state[key] = value
		},

		reset() {
			return initialState
		},
	},
})

export default filterSlice.reducer
export const { set, reset } = filterSlice.actions
