import type { EntityId } from 'api/types/EntityId'
import axios from 'axios'
import type { InvestmentClientDto } from 'converters/investmentClient'
import { investmentClient } from 'converters/investmentClient'

const baseUrl = '/v4/investment/clients'

declare namespace InvestmentClientApi {
	export type Get = InvestmentClientDto.Main
	export type Update = void
}

export default {
	get: (id: EntityId) =>
		axios
			.get<InvestmentClientApi.Get>(`${baseUrl}/${id}`, {
				$$requestName: 'investmentClient.get',
			})
			.then((response) =>
				Object.assign({}, response, { data: investmentClient.main(response.data) })
			),

	update: ({ id, payload }: { id: string; payload: Record<string, any> }) =>
		axios.patch<InvestmentClientApi.Update>(`${baseUrl}/${id}`, payload, {
			$$requestName: 'investmentClient.update',
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),
}
