import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { commonActions } from 'reducers/common'

const initialState = {
	data: [] as any[],
}

const breadcrumbsSlice = createSlice({
	name: 'breadcrumbs',
	initialState,
	reducers: {
		add(state, { payload }: PayloadAction<any>) {
			state.data.push({ id: state.data.length + 1, ...payload })
		},

		gotTo(state, { payload }: PayloadAction<any>) {
			const i = state.data.findIndex((el) => el.id === payload)
			state.data = state.data.slice(0, i)
		},

		goBack(state) {
			state.data = state.data.slice(0, state.data.length - 1)
		},

		setData(state, { payload }: PayloadAction<(typeof initialState)['data']>) {
			state.data = payload
		},

		reset: commonActions.getResetActionCreator(initialState),
	},
})

export default breadcrumbsSlice.reducer
export const { setData, add, goBack, gotTo, reset } = breadcrumbsSlice.actions
