export const Dummy = ({ error }: any) => {
	return (
		<>
			<div
				className={`data-block__column_dummy ${error ? 'data-block__column_dummy-error' : ''}`}
				style={{ width: 200 }}
			></div>
			<div
				className={`data-block__column_dummy ${error ? 'data-block__column_dummy-error' : ''}`}
				style={{ width: 160 }}
			></div>
			<div
				className={`data-block__column_dummy ${error ? 'data-block__column_dummy-error' : ''}`}
				style={{ width: 215 }}
			></div>
		</>
	)
}
