import { URLBuilder } from 'api/helpers'
import type { EntityId } from 'api/types/EntityId'
import type { Options } from 'api/types/Options'
import type { Pageable } from 'api/types/Pageable'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type { Application } from 'converters/application'
import { application } from 'converters/application'
import moment from 'moment'
import { join, mergeDeepRight } from 'ramda'
import type { ApplicationFilter } from 'reducers/application/list'
import { comparison as bind, eq, ge, le, or } from 'rsql-builder'

import { defaultToStr } from 'helpers/convertHelper'

import type { Schedule } from './types/Schedule'

export default {
	get: (id: EntityId) =>
		axios
			.get<Application.Main>(`/v2/applications/${id}`, {
				$$requestName: 'application.getApplication',
			})
			.then((response) =>
				Object.assign({}, response, {
					data: application.main(response.data),
				})
			),

	search: async ({
		filter,
		size = 10,
		page,
		sort,
		direction,
		property,
	}: Options<ApplicationFilter>) => {
		const params = new URLBuilder()

		if (filter) {
			params
				.addFilter('status', filter.status, 'inList')
				.addFilter('workers', filter.worker, 'inList')
				.addFilter('workers', filter.broker, 'eq')
				.addFilter('loan', filter.minLoanAmount, ge)
				.addFilter('loan', filter.maxLoanAmount, le)
				.addFilter('marketingData.channel', filter.channel, 'inList')
				.addFilter('facilities', filter.facility, 'inList')
				.addFilter(
					'createdAt',
					filter.minCreatedDate &&
						moment.isDate(filter.minCreatedDate) &&
						filter.minCreatedDate.toISOString(),
					ge
				)
				.addFilter(
					'createdAt',
					filter.maxCreatedDate &&
						moment.isDate(filter.maxCreatedDate) &&
						filter.maxCreatedDate.toISOString(),
					le
				)
				.addFilter(
					'participants',
					filter.participant?.map((id) => or(bind('id', eq(id)))),
					(value: string) => `=q="${value}"`
				)
		}

		return axios
			.get('/v2/applications', {
				params: {
					sort: sort || join(',', [defaultToStr(property), defaultToStr(direction)]) || undefined,
					size,
					page,
					filter: params.build('RSQL'),
				},
				$$requestName: 'application.search',
			})
			.then(
				(response) =>
					mergeDeepRight(response, {
						data: {
							content: response.data.content.map(application.main),
						},
					}) as AxiosResponse<Pageable<Application.Main>>
			)
	},

	update: (id: EntityId, data: Record<string, any>) =>
		axios.patch(`/v2/applications/${id}`, data, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),

	getPipeline: () =>
		axios.get('/v4/rcs/applications/statuses', {
			$$requestName: 'application.getPipeline',
		}),

	resolveNextStatus: (appId: EntityId, data: unknown) =>
		axios
			.post<Application.Status>(`/v2/applications/${appId}/change-status`, data, {
				$$requestName: 'application.resolveNextStatus',
			})
			.then((response) =>
				Object.assign({}, response, {
					data: application.status(response.data),
				})
			),

	getSchedule: (id: string) => axios.get(`/v2/applications/${id}/preliminary-schedule`),

	uploadSchedule: (id: string, form: Schedule) =>
		axios
			.post<Application.Main>(`/v2/applications/${id}/preliminary-schedule?return=schedule`, form)
			.then((response) =>
				Object.assign({}, response, {
					data: application.main(response.data),
				})
			),

	requestSchedule: (id: string, date: string) =>
		axios.post(`/v2/applications/${id}/requests/preliminary-schedule`, {
			date,
		}),

	getFinPosition: (id: string) =>
		axios.get(`/v2/applications/${id}/fin-position.xlsx`, { responseType: 'blob' }),

	deleteSchedule: (id: string) => axios.delete(`/v2/applications/${id}/preliminary-schedule`),

	createDelivery: (id: string, form: { address: Record<string, any>; mainContactId: string }) =>
		axios.post(`/v2/applications/${id}/delivery`, form),

	sendEsiaAuthLink: ({
		id,
		participantId,
		payload,
	}: {
		id: string
		participantId: string
		payload: { linkType: string }
	}) =>
		axios.post(
			`/v4/lending/applications/${id}/participants/${participantId}/esia-auth-links`,
			payload
		),

	workers: () =>
		axios.get('/v2/application-workers', { params: { page: 0, size: 30 } }).then(
			(response) =>
				mergeDeepRight(response, {
					data: {
						content: response.data.content.map(application.worker),
					},
				}) as AxiosResponse<Pageable<Application.Worker>>
		),
}
