import type { TRedux, TUtils } from 'types'

export const makeCancelable = <T extends Promise<any>>(promise: T) => {
	let _hasCanceled = false

	const wrappedPromise = new Promise<T>((resolve, reject) => {
		promise.then(
			(value) => (_hasCanceled ? reject({ isCanceled: true }) : resolve(value)),
			(err) => (_hasCanceled ? reject({ isCanceled: true }) : reject(err))
		)
	})

	return {
		promise: wrappedPromise as T,
		cancel() {
			_hasCanceled = true
		},
	}
}

export type CancelableThunkPromise<T extends (...args: any) => TRedux.TAction<Promise<any>>> =
	TUtils.Maybe<{
		promise: ReturnType<ReturnType<T>>
		cancel: () => void
	}>

export type CancelablePromise<T extends (...args: any) => Promise<any>> = TUtils.Maybe<{
	promise: ReturnType<T>
	cancel: () => void
}>
