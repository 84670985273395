import { DefaultValues } from 'helpers/convertHelper'

export const bankDetails = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		name: model.string(['name']),
		bic: model.string(['bic']),
		checkingAccount: model.string(['checkingAccount']),
		correspondentAccount: model.string(['correspondentAccount']),
	}
}
