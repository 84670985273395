import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { Representative } from 'converters/representative'
import { commonActions } from 'reducers/common'
import type { TRedux } from 'types'

const representativeAdapter = createEntityAdapter<Representative.Delivery>()

export const representativeSelector = representativeAdapter.getSelectors<TRedux.RootState>(
	(state) => state.delivery.representative
)

const initialState = representativeAdapter.getInitialState({
	fetching: false,
})

const representative = createSlice({
	name: 'delivery/representative',
	initialState,

	reducers: {
		setData: representativeAdapter.setAll,

		addOne: representativeAdapter.addOne,

		setFetchingStatus: commonActions.setFetchingStatus,

		reset: commonActions.reset(initialState),
	},
})

const { actions } = representative

export { actions }
export default representative.reducer
