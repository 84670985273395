import { isEmpty, pickAll } from 'ramda'

export const findDocumentsOnSigning = ({ aggregator, participants, contextId, contextType }) => {
	const result = []

	participants.forEach((participant) => {
		const allDocuments = [].concat(participant.signableDocuments, aggregator)
		const participantDocumentsOnSigning = []

		allDocuments.forEach((item) => {
			const signatureInProgress = item.signatures.find(
				(signature) =>
					contextId === signature.context.id &&
					contextType === signature.context.type &&
					participant.customerId === signature.signer.subject.id &&
					participant.customerType === signature.signer.subject.type
			)

			if (signatureInProgress) {
				participantDocumentsOnSigning.push({
					signature: signatureInProgress,
					...pickAll(['title', 'id'], item),
				})
			}
		})

		if (!isEmpty(participantDocumentsOnSigning)) {
			result.push({
				participant: pickAll(['name', 'roles'], participant),
				documents: participantDocumentsOnSigning,
			})
		}
	})

	return result
}
