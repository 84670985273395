import { DefaultValues } from 'helpers/convertHelper'

export const entityMetadata = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		createdDate: model.string(['createdDate']),
		number: model.null<number>(['number']),
	}
}
