import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { InvestmentLead } from 'converters/investmentLead'
import { commonActions } from 'reducers/common'

import { createFilter } from 'helpers/createFilter'

const initialFilter = createFilter({
	query: null,
	worker: null,
	status: null,
	channel: null,
	minCreatedDate: null,
	maxCreatedDate: null,
})

const initialState = {
	data: [] as InvestmentLead.List[],
	fetching: false,
	filter: initialFilter,
}

const list = createSlice({
	name: 'investmentLead/list',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<InvestmentLead.List[]>) {
			state.data = payload
		},

		setFilter: commonActions.setFilter(),
		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions } = list
export default list.reducer
