import { utils } from 'helpers'
import { defaultToStr, DefaultValues } from 'helpers/convertHelper'

export const participant = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		address: model.null<Record<string, any>>(['address']),
		name: model.string(['name']),
		surname: model.string(['surname']),
		patronymic: model.string(['patronymic']),
		phone: model.string(['phone']),
		email: model.string(['email']),
		fullName: defaultToStr(utils.getFullName(data)),
		fullNameShort: defaultToStr(utils.getFullName(data, true)),
		taxId: model.string(['taxId']),
		mainContact: model.null(['mainContact']) || false,
		type: model.string<'PERSON' | 'ENTREPRENEUR' | 'ORGANIZATION'>(['type']),
	}
}
