import { normalize, schema } from 'normalizr'

import { date } from '@creditclubteam/helpers'
import { DefaultValues } from 'helpers/convertHelper'

export const collectionEvent = (data: Record<string, any>) => {
	const groupTypes = (value: Record<string, any>) => {
		const {
			entities: { types },
		} = normalize<
			void,
			{
				types: Record<
					string,
					{
						id: string
						title: string
						jsonSchema: Record<string, any>
					}
				>
			}
		>(value, [
			new schema.Entity(
				'types',
				{},
				{
					processStrategy: (v) => {
						const model = new DefaultValues(v)

						return {
							id: model.string(['id']),
							title: model.string(['title']),
							jsonSchema: JSON.parse(model.string(['jsonSchema'])),
						}
					},
				}
			),
		])

		return types
	}

	const groupEventsByDate = (value: Record<string, any>) => {
		const {
			entities: { events: entities },
			result: ids,
		} = normalize<
			void,
			{
				events: Record<
					string,
					{
						id: string
						eventDate: string
						eventTypeId: string
						eventData: Record<string, any>
					}[]
				>
			},
			string[]
		>(value, [
			new schema.Entity(
				'events',
				{},
				{
					idAttribute: ({ eventDate }) => date.format(new Date(eventDate), { to: 'yyyy-MM-dd' }),
					mergeStrategy: (a, b) => [...a, ...b],
					processStrategy: (v) => {
						const model = new DefaultValues(v)

						return [
							{
								id: model.string(['id']),
								eventDate: model.string(['eventDate']),
								eventTypeId: model.string(['eventTypeId']),
								eventData: model.null<Record<string, any>>(['eventData']),
							},
						]
					},
				}
			),
		])

		return {
			entities,
			ids: Array.from(new Set(ids)),
			total: value.length as number,
		}
	}

	return {
		events: groupEventsByDate(data?.events ?? []),
		types: groupTypes(data?.types ?? []),
	}
}
