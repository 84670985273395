import cx from 'clsx'
import PropTypes from 'prop-types'

import styles from './ChoiceBox.module.scss'

const ChoiceBox = ({ value, options, onSelect, disabled, className, ...restProps }) => {
	const handleSelect = (option) => {
		onSelect(option)
	}

	return (
		<div
			className={cx(styles.wrapper, { [styles.disabled]: disabled, [className]: !!className })}
			style={{
				gridTemplateColumns: `repeat(${options.length}, auto)`,
			}}
			{...restProps}
		>
			{options.length > 0 &&
				options.map((item, index) => (
					<div
						key={index}
						onClick={() => handleSelect(item)}
						className={cx(styles.item, {
							[styles.active]: item.value === value,
						})}
					>
						{item.label}
					</div>
				))}
		</div>
	)
}

ChoiceBox.propTypes = {
	value: PropTypes.string.isRequired,
	options: PropTypes.array.isRequired,
	disabled: PropTypes.bool,
	className: PropTypes.string,

	onSelect: PropTypes.func.isRequired,
}

ChoiceBox.defaultProps = {
	disabled: false,
}

export default ChoiceBox
