import { DefaultValues } from 'helpers/convertHelper'

export const identification = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		organizationId: model.string(['organizationId']),
		identifiedAt: model.string(['identifiedAt']),
		type: model.string(['type']),
	}
}
