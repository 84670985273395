import { DefaultValues } from 'helpers/convertHelper'

export const requiredAction = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		entity: {
			id: model.string(['entity', 'id']),
			name: model.string(['entity', 'name']),
			type: {
				id: model.string(['entity', 'type', 'id']),
				title: model.string(['entity', 'type', 'title']),
			},
			roles: model.array(['entity', 'roles']).map((_, i) => ({
				id: model.string(['entity', 'roles', i, 'id']),
				title: model.string(['entity', 'roles', i, 'title']),
			})),
		},
		actions: model.array<string[]>(['actions']),
	}
}
