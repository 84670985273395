import moment from 'moment'
import PropTypes from 'prop-types'

import './Event.scss'

const Event = ({ viewName, event: { start, isAllDay, seriesMasterId, subject } }) => {
	const renderSubject = () => (isAllDay && !subject ? 'Без названия' : subject)

	return (
		<div className='calendar-event'>
			{seriesMasterId && <i className='zmdi zmdi-refresh-alt' />}
			{viewName === 'month' && !isAllDay
				? `${moment(start).format('LT')} ${renderSubject()}`
				: renderSubject()}
		</div>
	)
}

Event.propTypes = {
	viewName: PropTypes.string,
	event: PropTypes.shape({
		start: PropTypes.object.isRequired,
		subject: PropTypes.string.isRequired,
		$$customParams: PropTypes.object.isRequired,
	}),
}

export default Event
