import { addBreadcrumb } from 'actions/breadcrumbs'
import {
	fetchCategories,
	fetchFiles,
	resetDocumentCategories,
	resetDocumentFiles,
} from 'actions/document'
import { fetchFacilitiesWithMerge, resetFacilityList } from 'actions/facility'
import { getFinancialData } from 'actions/financialData'
import { setPagination, solveParamsPagination } from 'actions/listParameters'
import { fetchOrganizationsWithMerge } from 'actions/organization'
import { fetchPersonsWithMerge } from 'actions/person'
import {
	searchFacility,
	searchOrganization,
	searchPerson,
	searchRosreestrRegistration,
	setSearchResults,
} from 'actions/search'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import type { Options } from 'api/types/Options'
import axios from 'axios'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import { ROSREESTR_REGISTRATION } from 'const/rosreestrRegistration'
import type { RosreestrRegistration } from 'converters/rosreestrRegistration'
import { flatten, isEmpty } from 'ramda'
import { actions as loanActions } from 'reducers/loan/list'
import type { RosreestrRegistrationFilter } from 'reducers/rosreestrRegistration/main'
import { actions, selectSelectedRosreestrRegistration } from 'reducers/rosreestrRegistration/main'
import type { TRedux } from 'types'

import { utils } from 'helpers'

const STATUSES = Object.values(ROSREESTR_REGISTRATION.STATE)

export const resetRosreestrRegistration = actions.reset
export const setRosreestrRegistrationFilter = actions.setFilter

export const setDefaultRosreestrRegistrationFilter =
	(): TRedux.TAction<void> => (dispatch, getState) => {
		const { preserveFilter } = getState().rosreestrRegistration.main

		if (preserveFilter) return

		// статусы которые отключаем по-умолчанию
		const disabledByDefaultStatuses: string[] = []

		// формируем массив активных статусов
		const defaultStatuses = STATUSES.filter((status) => !disabledByDefaultStatuses.includes(status))

		if (defaultStatuses && !isEmpty(defaultStatuses) && !isEmpty(disabledByDefaultStatuses))
			dispatch(actions.setFilter({ key: 'status', value: defaultStatuses }))
	}

export const getRosreestrRegistrationList =
	(parameters?: Options<RosreestrRegistrationFilter>): TRedux.TAction<Promise<void>> =>
	async (dispatch, getState) => {
		try {
			if (getState().rosreestrRegistration.main.status !== 'pending')
				dispatch(actions.setStatus('pending'))
			dispatch(setDefaultRosreestrRegistrationFilter())

			const requestParameters = Object.assign(
				{},
				dispatch(solveParamsPagination('createdAt', 'desc')),
				{
					filter: getState().rosreestrRegistration.main.filter,
				},
				parameters
			)

			const { data } = await api.rosreestrRegistration.search(requestParameters)

			dispatch(actions.setAll(data.content))

			if (!getState().rosreestrRegistration.main.preserveFilter) {
				dispatch(actions.setPreserveFilter(true))
			}

			const { totalElements, number, totalPages } = data

			dispatch(
				setPagination({
					totalPages,
					totalElements,
					paged: true,
					currentPage: number,
				})
			)

			dispatch(actions.setStatus('fulfilled'))
		} catch (error) {
			dispatch(actions.setStatus('rejected'))

			dispatch(
				addServerError({
					text: 'Не удалось загрузить регистрации',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const searchRosreestrRegistrationByQuery =
	(query: string): TRedux.TAction<Promise<void>> =>
	(dispatch) => {
		const requestOptions = {
			query,
			params: {
				size: 10,
				sort: 'desc',
				page: 0,
			},
		}

		return axios
			.all<any>([
				dispatch(searchPerson(requestOptions)),
				dispatch(searchOrganization(requestOptions)),
				dispatch(searchFacility(requestOptions)),
				dispatch(searchRosreestrRegistration(requestOptions)),
			])
			.then((data) => {
				const normalizedResponse = flatten(data.map((item) => item?.content).filter(Boolean))

				dispatch(setSearchResults('internal', normalizedResponse))
			})
	}

export const createRosreestrRegistration =
	(formData: Record<string, any>): TRedux.TAction<Promise<RosreestrRegistration.Main | void>> =>
	async (dispatch) => {
		try {
			const { data } = await api.rosreestrRegistration.create(formData)

			dispatch(actions.upsertMany([data]))

			return data
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось создать регистрацию',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const resetRosreestrRegistrationPage = (): TRedux.TAction => (dispatch, getState) => {
	dispatch(actions.reset(['entities', 'ids']))
	dispatch(resetFacilityList())
	dispatch(resetDocumentFiles())
	dispatch(resetDocumentCategories())
	if (getState().loan.list.data.length) dispatch(loanActions.reset())
}

export const getRosreestrRegistrationSingle =
	(id: string): TRedux.TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			dispatch(actions.selectId(id))
			dispatch(setDefaultRosreestrRegistrationFilter())

			const { data } = await api.rosreestrRegistration.get(id)

			// Заём нужен для подставления дефолтной суммы в форме выпуска закладной
			if (data.loanId && data.type === 'MORTGAGE' && !data.operations.length) {
				dispatch(getFinancialData(data.loanId))
			}

			if (data.applicationId) {
				try {
					const {
						data: { number },
					} = await api.application.get(data.applicationId)

					if (number) dispatch(actions.setApplicationNumber(number.toString()))
				} catch {
					//
				}
			}

			// Получаем и записываем документы с категориями
			dispatch(fetchCategories([data.$$type]))
			await dispatch(fetchFiles(id))

			const persons = data.declarants.filter(({ type }) => type === PARTICIPANTS_TYPES.PERSON)
			const organizations = data.declarants.filter(
				({ type }) => type === PARTICIPANTS_TYPES.ORGANIZATION
			)

			await dispatch(fetchPersonsWithMerge(persons))
			await dispatch(fetchOrganizationsWithMerge(organizations))
			await dispatch(fetchFacilitiesWithMerge([data.facilityId]))

			dispatch(actions.upsertOne(data))
			dispatch(addBreadcrumb(`Регистрация №${data.number}`))

			dispatch(actions.setStatus('fulfilled'))
		} catch (error) {
			dispatch(actions.setStatus('rejected'))

			dispatch(
				addServerError({
					text: 'Не удалось загрузить регистрацию',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const updateRosreestrRegistration =
	(form: Record<string, unknown>): TRedux.TAction<Promise<void>> =>
	async (dispatch, getState) => {
		const rosreestrRegistrationId = getState().rosreestrRegistration.main.selectedId!

		try {
			await api.rosreestrRegistration.update(rosreestrRegistrationId, form)

			try {
				const { data } = await api.rosreestrRegistration.get(rosreestrRegistrationId)

				dispatch(actions.upsertOne(data))
			} catch {
				//
			}
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось обновить регистрацию',
					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		}
	}

export const createOperation =
	(
		formData: Record<string, any>,
		onBeforeUpsert?: (response: RosreestrRegistration.Main) => any
	): TRedux.TAction<Promise<RosreestrRegistration.Main>> =>
	async (dispatch, getState) => {
		try {
			const registration = selectSelectedRosreestrRegistration(getState())!

			const { data } = await api.rosreestrRegistration.createOperation(registration.id, formData)

			await new Promise((r) => setTimeout(r, 1000))

			dispatch(fetchFiles(registration.id))

			try {
				await onBeforeUpsert?.(data)
			} catch (error) {
				// console.dir(error)
			}

			dispatch(actions.upsertOne(data))

			return data
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось создать операцию',
					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		}
	}

export const deleteOperation =
	(operationId: string): TRedux.TAction<Promise<void>> =>
	async (dispatch, getState) => {
		try {
			const registration = selectSelectedRosreestrRegistration(getState())!

			const { data } = await api.rosreestrRegistration.delete(registration.id, operationId)

			await new Promise((r) => setTimeout(r, 1000))

			dispatch(fetchFiles(registration.id))

			dispatch(actions.upsertOne(data))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось удалить операцию',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const sendOperationToRosreestr =
	(operationId: string): TRedux.TAction<Promise<void>> =>
	async (dispatch, getState) => {
		try {
			const { data } = await api.rosreestrRegistration.sendToRosreestr(
				getState().rosreestrRegistration.main.selectedId!,
				operationId
			)

			dispatch(actions.upsertOne(data))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось отправить операцию в росреестр',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const getComparedStatements =
	(
		payload: Parameters<typeof api.rosreestrRegistration.compareStatements>[0]
	): TRedux.TAction<Promise<any>> =>
	async (dispatch) => {
		try {
			const { data } = await api.rosreestrRegistration.compareStatements(payload)

			return data
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось сравнить заявления',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}
