import { declarant } from './declarant'
import { main } from './main'
import { operation } from './operation'
import { statement } from './statement'
import { statementInfo } from './statementInfo'

export const rosreestrRegistration = {
	main,
	declarant,
	operation,
	statementInfo,
	statement,
}

export namespace RosreestrRegistration {
	export type Main = ReturnType<typeof main>
	export type Declarant = ReturnType<typeof declarant>
	export type Operation = ReturnType<typeof operation>
	export type StatementInfo = ReturnType<typeof statementInfo>
	export type Statement = ReturnType<typeof statement>
}
