export const lazyLoadHandler = (cb: () => Promise<any>): Promise<any> => {
	return new Promise((resolve) => {
		cb()
			.then(resolve)
			.catch(() => {
				if (window.__env__.BUILD_MODE !== 'localhost') {
					window.location.reload()
				}
			})
	})
}
