import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { BonusApplicationDto } from 'converters/bonusApplication'
import { commonActions } from 'reducers/common'
import type { TRedux } from 'types'
import type { PayloadStatus } from 'types/redux'

const bonusApplicationAdapter = createEntityAdapter<BonusApplicationDto.Main>()

export const bonusApplicationSelector = bonusApplicationAdapter.getSelectors<TRedux.RootState>(
	(state: TRedux.RootState) => state.bonusApplication
)

const initialState = bonusApplicationAdapter.getInitialState({
	status: 'pending' as PayloadStatus,
})

const bonusApplication = createSlice({
	name: 'bonusApplication',
	initialState,
	reducers: {
		setAll: bonusApplicationAdapter.setAll,
		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions: bonusApplicationReducerActions } = bonusApplication
export default bonusApplication.reducer
