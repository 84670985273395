import { URLBuilder } from 'api/helpers'
import type { EntityId } from 'api/types/EntityId'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type { Person } from 'converters/person'
import { person } from 'converters/person'
import type { PersonIncomes } from 'converters/personIncomes'
import { personIncomes } from 'converters/personIncomes'
import { mergeDeepRight } from 'ramda'
import type { PersonFilter } from 'reducers/person/list'

import type { Pageable } from './types/Pageable'
import type { Actions } from './types/Participant'
import type { CommonApi } from './types'

const personApi = {
	getShares: ({ clientId }: { clientId: string }) =>
		axios
			.get<Person.ClientShare.Main>(`/v4/investment/clients/${clientId}/shares`)
			.then((response) =>
				Object.assign({}, response, {
					data: person.clientShare.main(response.data),
				})
			),

	addShareOperation: ({ clientId, payload }: { clientId: string; payload: Record<string, any> }) =>
		axios.post(`/v4/investment/clients/${clientId}/shares/operations`, payload),

	updateShareOperation: ({
		clientId,
		payload,
	}: {
		clientId: string
		payload: { balance: number }
	}) =>
		axios.patch(`/v4/investment/clients/${clientId}/shares`, payload, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),

	certificateForm: (id: EntityId) => axios.get(`/v1/persons/${id}/certificate-form/reactivate`),

	sendEsiaLinks: ({ personId, payload }: { personId: string; payload: { documentId: string } }) =>
		axios.post(`/v2/persons/${personId}/sds-esia-links`, payload),

	createIncome: ({ personId, data }: { personId: string; data: Record<string, any> }) =>
		axios.post<PersonIncomes.Main>(`/v2/persons/${personId}/incomes`, data).then((response) =>
			Object.assign({}, response, {
				data: personIncomes.main(response.data),
			})
		),

	sendGosusligiAuthLink: (
		id: string,
		participantId: string,
		data: { linkType: string },
		endpoint: 'leads' | 'applications'
	) =>
		axios.post(`/v4/lending/${endpoint}/${id}/participants/${participantId}/esia-auth-links`, data),

	restorePassword: (username: string) =>
		axios.post(`/uaa/users/restore-password?username=${username}`),

	createOrUpdateCertificate: (personId: EntityId) =>
		axios({
			method: 'POST',
			url: `/v1/persons/${personId}/certificate-form`,
		}),

	createCertificateStatement: (personId: EntityId) =>
		axios({
			method: 'GET',
			url: `/v1/persons/${personId}/certificate-form/statement`,
		}),

	releaseCertificateForm: (personId: EntityId) =>
		axios({
			method: 'POST',
			url: `/v1/persons/${personId}/certificate-form/release`,
		}),

	deleteCertificateForm: (personId: EntityId) =>
		axios({
			method: 'DELETE',
			url: `/v1/persons/${personId}/certificate-form`,
		}),

	updateCertificateFormState: (personId: EntityId) =>
		axios({
			method: 'POST',
			url: `/v1/persons/${personId}/certificate-form/state`,
		}),

	getNationalities: () =>
		axios.get<Person.Nationality[]>('/v2/persons/nationalities').then((response) =>
			Object.assign({}, response, {
				data: response.data.map(person.nationality),
			})
		),

	getBonusAccount: (personId: string) =>
		axios.get<Person.BonusAccount>(`/v4/rcs/persons/${personId}/bonus-account`).then((response) =>
			Object.assign({}, response, {
				data: person.bonusAccount(response.data),
			})
		),

	createBonusAccountOperation: ({
		payload,
		personId,
	}: {
		personId: string
		payload: { operationType: string; date: string; amount: number; title: number }
	}) => axios.post(`/v4/rcs/persons/${personId}/bonus-account-operations`, payload),

	updateBonusAccount: ({
		payload,
		personId,
	}: {
		personId: string
		payload: { entryUrl: string }
	}) =>
		axios
			.patch<Person.BonusAccount>(`/v4/rcs/persons/${personId}/bonus-account`, payload, {
				headers: {
					'Content-Type': 'application/merge-patch+json',
				},
			})
			.then((response) =>
				Object.assign({}, response, {
					data: person.bonusAccount(response.data),
				})
			),
}

const commonPersonApi: CommonApi<
	Person.Main,
	PersonFilter,
	Record<string, any>,
	Record<string, any>
> = {
	get: (id: EntityId) =>
		axios.get<Person.Main>(`/v2/persons/${id}`).then((response) =>
			Object.assign({}, response, {
				data: person.main(response.data),
			})
		),

	search: async ({ type = 'RSQL', filter, size, sort, page }) => {
		const params = new URLBuilder()

		const re = (value: string) => `=re="(?i)${value.replace(/"/g, '')}"`

		if (type === 'RSQL' && filter) {
			params
				.addFilter('person', filter.query, re)
				.addFilter('id', filter.ids, 'inList')
				.addFilter('phone', filter.phone, 'eq')
				.addFilter('taxId', filter.taxId, 'inList')
		}

		return axios
			.get('/v2/persons', {
				params: {
					sort,
					size,
					page,
					type,
					filter: type === 'TEXT' ? filter?.query : params.build('RSQL'),
				},
			})
			.then(
				(response) =>
					mergeDeepRight(response, {
						data: {
							content: response.data.content.map(person.main),
						},
					}) as AxiosResponse<Pageable<Person.Main>>
			)
	},

	create: (data: Record<string, any>) =>
		axios.post<Person.Main>('/v2/persons', data).then((response) =>
			Object.assign({}, response, {
				data: person.main(response.data),
			})
		),

	update: (id: EntityId, data: Record<string, unknown>) =>
		axios.patch(`/v2/persons/${id}`, data, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),
}

export default {
	...personApi,
	...commonPersonApi,
	actions: (id: EntityId, actions: unknown, contexts?: unknown) =>
		axios.post(`/v2/persons/${id}/actions`, { actions, contexts }),
} as typeof commonPersonApi & typeof personApi & Actions
