import { DefaultValues } from 'helpers/convertHelper'

export const creditObligation = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		type: model.string(['type']),
		openedAt: model.string(['openedAt']),
		creditAmount: model.null<number>(['creditAmount']),
		mainDebtAmount: model.null<number>(['mainDebtAmount']),
		overdueAmount: model.null<number>(['overdueAmount']),
		fulfilled: model.null<boolean>(['fulfilled']),
		included: model.null<boolean>(['included']),
	}
}
