import { CALCULATION } from 'const/calculations'
import { interestRate } from 'converters/lead/interestRate'

import { DefaultValues } from 'helpers/convertHelper'

export const paymentInterestRateDetail = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		interestRate: interestRate(data.interestRate),
		type: model.const(data.type, CALCULATION.TYPE.ENG),
		typeRus: model.const(data.type, CALCULATION.TYPE.RUS),
	}
}
