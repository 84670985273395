import { Droppable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import { take } from '@creditclubteam/helpers'

import FileInProgress from './FileInProgress'
import FileItem from './FileItem'

import './FilesBucket.scss'

const FilesBucket = ({ queue, uploader, entityId, onlyBucket, files }) => {
	const downloadableFiles = take(uploader, `${entityId}.files`, [])
	const filesInProgress = [...downloadableFiles, ...queue]

	const dropPableFilesQueueOptions = {
		isDropDisabled: true,
		droppableId: 'bucket',
		direction: 'horizontal',
	}

	return (
		<Droppable {...dropPableFilesQueueOptions}>
			{(provided) => (
				<div
					ref={provided.innerRef}
					{...provided.droppableProps}
					className='files-inspector-files-bucket__list'
					data-test-id='files-bucket'
				>
					{!isEmpty(filesInProgress) &&
						filesInProgress.map((file, index) => <FileInProgress key={index} {...file} />)}
					{!isEmpty(files) &&
						files.map((item, index) => (
							<FileItem {...item} key={item.id} index={index} onlyBucket={onlyBucket} />
						))}
					{provided.placeholder}
				</div>
			)}
		</Droppable>
	)
}

FilesBucket.propTypes = {
	onlyBucket: PropTypes.bool,
	queue: PropTypes.array.isRequired,
	files: PropTypes.array.isRequired,
	uploader: PropTypes.object.isRequired,
	entityId: PropTypes.string.isRequired,
	pagination: PropTypes.object.isRequired,
	fetchBucket: PropTypes.func,
	resetBucket: PropTypes.func,
}

const mapStateToProps = (state) => ({
	...state.filesInspector.bucket,
	uploader: state.uploadFiles.uploader,
	entityId: state.filesInspector.main.entityId,
})

export default connect(mapStateToProps)(FilesBucket)
