import analytics from './analytics'
import application from './application'
import assessment from './assessment'
import broker from './broker'
import calculation from './calculation'
import calendar from './calendar'
import cashbackPayment from './cashbackPayment'
import courts from './courts'
import creditPolicyReport from './creditPolicyReport'
import dadata from './dadata'
import decision from './decision'
import delivery from './delivery'
import deliveryTypes from './deliveryTypes'
import deposit from './deposit'
import document from './document'
import documentConstructor from './documentConstructor'
import entrepreneur from './entrepreneur'
import externalData from './externalData'
import facility from './facility'
import finalDecision from './finalDecision'
import financialData from './financialData'
import history from './history'
import insurance from './insurance'
import interestPayment from './interestPayment'
import investmentClient from './investmentClient'
import investmentLead from './investmentLead'
import lead from './lead'
import loan from './loan'
import loanSellApplication from './loanSellApplication'
import login from './login'
import markers from './markers'
import microsoft from './microsoft'
import moneyMovements from './moneyMovements'
import mortgage from './mortgage'
import organization from './organization'
import partner from './partner'
import person from './person'
import refinancing from './refinancing'
import rejectionReasons from './rejectionReasons'
import representative from './representative'
import dictionary from './dictionary'
import rosreestr from './rosreestr'
import rosreestrRegistration from './rosreestrRegistration'
import scoring from './scoring'
import sign from './sign'
import worker from './worker'
import bonusApplication from './bonusApplication'

export default {
	rejectionReasons,
	cashbackPayment,
	mortgage,
	login,
	dictionary,
	loan,
	creditPolicyReport,
	lead,
	representative,
	documentConstructor,
	bonusApplication,
	decision,
	partner,
	broker,
	calculation,
	worker,
	person,
	investmentClient,
	investmentLead,
	interestPayment,
	scoring,
	markers,
	dadata,
	history,
	entrepreneur,
	deposit,
	calendar,
	rosreestrRegistration,
	document,
	courts,
	facility,
	microsoft,
	analytics,
	refinancing,
	rosreestr,
	assessment,
	application,
	organization,
	delivery,
	deliveryTypes,
	loanSellApplication,
	insurance,
	moneyMovements,
	financialData,
	sign,
	externalData,
	finalDecision,
}
