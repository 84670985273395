import axios from 'axios'

const baseUrl = '/v1/dictionaries'
const endpoints = {
	signatures: 'signatures',
}

export type DictionaryEntityType = Array<{ id: string; title: string }>

declare namespace DictionariesApi {
	namespace Signatures {
		export type GetQualifiedSignatureProviders = DictionaryEntityType
		export type GetApplicationTypes = DictionaryEntityType
	}
}

export default {
	signatures: {
		getQualifiedSignatureProviders: () =>
			axios.get<DictionariesApi.Signatures.GetQualifiedSignatureProviders>(
				`${baseUrl}/${endpoints.signatures}/qualified-signature-providers`
			),

		getApplicationTypes: () =>
			axios.get<DictionariesApi.Signatures.GetApplicationTypes>(
				`${baseUrl}/${endpoints.signatures}/application-types`
			),
	},
}
