import { DefaultValues } from 'helpers/convertHelper'

export const DOC_FOUND_KIND = {
	CULTURAL_HERITAGE_SITE: 'CULTURAL_HERITAGE_SITE',
} as const

export const DOC_FOUND_KIND_RUS = {
	CULTURAL_HERITAGE_SITE: 'Объект культурного наследия',
} as const

export const encumbrance = (data?: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		regDate: model.string(['regDate']),
		kind: model.string(['kind']),
		docFoundKind: model.const(data?.docFoundKind, DOC_FOUND_KIND),
		docFoundKindRus: model.const(data?.docFoundKind, DOC_FOUND_KIND_RUS),
		regNumber: model.string(['regNumber']),
		encumbrancerType: model.const(data?.encumbrancerType, {
			PERSON: 'PERSON',
			ORGANIZATION: 'ORGANIZATION',
			ORGANIZATION_NON_PROFESSIONAL: 'ORGANIZATION_NON_PROFESSIONAL',
		} as const),
		encumbrancer: model.string(['encumbrancer']),
		duration: model.string(['duration']),
		docFound: model.string(['docFound']),
		lenderDocuments: model.null<boolean>(['lenderDocuments']),
		ownershipDocuments: model.array<string[]>(['ownershipDocuments']),
	}
}
