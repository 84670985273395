import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { HistoryType } from 'const/history'
import HISTORY from 'const/history'
import { mergeAll } from 'ramda'
import type { Maybe } from 'types/util'

import { commonActions } from './common'

interface State {
	data: Array<any>
	dates: Array<any>
	fetching: boolean
	isInitFailed: boolean
	isVisible: boolean
	idEditableEvent: Maybe<any>
	allEntityId: Array<any>
	entity: {
		id: string
		type: string
		status: Maybe<unknown>
		number: Maybe<unknown>
	}
	filters: {
		types: Array<
			| HistoryType['TYPES']['COMMENT']
			| HistoryType['TYPES']['NEW_STATUS']
			| HistoryType['TYPES']['CALCULATION']
		>
		entityId: Array<any>
		endDate: Maybe<unknown>
		startDate: Maybe<unknown>
		[key: string]: unknown
	}
	pageParams: {
		page: number
		totalPages: number
		[key: string]: unknown
	}
}

const initialState: State = {
	data: [],
	dates: [],
	fetching: false,
	isInitFailed: false,
	isVisible: false,
	idEditableEvent: null,
	allEntityId: [],
	entity: {
		id: '',
		type: '',
		status: null,
		number: null,
	},
	filters: {
		types: [HISTORY.TYPES.COMMENT, HISTORY.TYPES.NEW_STATUS, HISTORY.TYPES.CALCULATION],
		entityId: [],
		endDate: null,
		startDate: null,
	},
	pageParams: {
		page: 0,
		totalPages: 0,
	},
}

const history = createSlice({
	name: 'history',
	initialState,
	reducers: {
		setData(state, { payload: { data, dates } }: PayloadAction<{ data: any; dates: any }>) {
			state.data = data
			state.dates = dates
			state.fetching = false
		},

		updateData(state, { payload: { data, dates } }: PayloadAction<{ data: any; dates: any }>) {
			state.data.push(...data)
			state.dates.push(...dates)
			state.fetching = false
		},

		setVisible(state, { payload }: PayloadAction<boolean>) {
			state.isVisible = payload
		},

		setPageParams(
			state,
			{
				payload: { field, value },
			}: PayloadAction<{ field: keyof State['pageParams']; value: unknown }>
		) {
			state.pageParams[field] = value
		},

		setEntity(state, { payload }: PayloadAction<State['entity']>) {
			state.entity = mergeAll([state.entity, payload])
		},

		setAllEntityId(state, { payload }: PayloadAction<Array<any>>) {
			state.allEntityId = payload
		},

		setFilters(
			state,
			{
				payload: { field, value },
			}: PayloadAction<{ field: keyof State['filters']; value: unknown }>
		) {
			state.filters[field] = value
		},

		setFetchingStatus(state, { payload }: PayloadAction<boolean>) {
			state.fetching = payload
		},

		setIsInitFailed(state, { payload }: PayloadAction<boolean>) {
			state.isInitFailed = payload
		},

		addComment(
			state,
			{ payload: { comment, dates } }: PayloadAction<{ comment: any; dates: Array<unknown> }>
		) {
			state.data.push(comment)
			state.dates.push(...dates)
		},

		updateComment(
			state,
			{
				payload: { commentId, metadata, params },
			}: PayloadAction<{ commentId: string; params: any; metadata: any }>
		) {
			const commentIndex = state.data.findIndex((el: Record<string, any>) => el.id === commentId)

			const oldComment = state.data[commentIndex]
			const newComment = mergeAll([
				oldComment,
				params,
				{
					metadata: mergeAll([oldComment?.metadata, metadata]),
				},
			])

			state.data[commentIndex] = newComment
		},

		deleteComment(state, { payload }: PayloadAction<string>) {
			state.data = state.data.filter(({ id }: Record<string, any>) => id !== payload)
		},

		resetPageParams(state) {
			state.pageParams = {
				page: 0,
				totalPages: 0,
			}
		},

		reset: commonActions.reset(initialState),
	},
})

export const { actions } = history
export default history.reducer
