import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { investmentClientReducerActions } from 'reducers/person/investmentClient'
import type { TAction } from 'types/redux'
import type { FirstParameter } from 'types/util'

import { utils } from 'helpers'

export const getPersonInvestmentClient =
	(id: string): TAction<Promise<void>> =>
	async (dispatch, getState) => {
		try {
			if (getState().person.investmentClient.status !== 'pending')
				dispatch(investmentClientReducerActions.setStatus('pending'))

			const { data } = await api.investmentClient.get(id)

			dispatch(investmentClientReducerActions.setData(data))
			dispatch(investmentClientReducerActions.setStatus('fulfilled'))
		} catch (error: any) {
			if (error?.response.status === 404) {
				dispatch(investmentClientReducerActions.setStatus('fulfilled'))
			} else {
				dispatch(investmentClientReducerActions.setStatus('rejected'))

				dispatch(
					addServerError({
						text: 'Не удалось загрузить инвестиции',
						details: utils.getDetailsFromError(error),
					})
				)
			}
		}
	}

export const updatePersonInvestmentClient =
	(payload: FirstParameter<typeof api.investmentClient.update>): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			await api.investmentClient.update(payload)
			const { data } = await api.investmentClient.get(payload.id)

			dispatch(investmentClientReducerActions.setData(data))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось обновить инвестиции',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}
