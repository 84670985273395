import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { InvestmentLead } from 'converters/investmentLead'
import type { Worker } from 'converters/worker'
import { commonActions } from 'reducers/common'
import { workerSelector } from 'reducers/worker'
import type { TRedux, TUtils } from 'types'

export const matchedEntityManagersSelector = createSelector(
	[(state) => state, (state: TRedux.RootState) => state.investmentLead.single.data],
	(state, entity) =>
		(entity && workerSelector.selectTotal(state) !== 0
			? entity.workers
					.filter(({ type }) => type === 'MANAGER')
					.map(({ id }) => workerSelector.selectById(state, id))
					.filter(Boolean)
			: []) as Worker.Main[]
)

const initialState = {
	data: null as TUtils.Maybe<InvestmentLead.Single>,
	fetching: false,
}

const single = createSlice({
	name: 'investmentLead/single',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<InvestmentLead.Single>) {
			state.data = payload
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions } = single
export default single.reducer
