import { ROLES_VALUES } from 'const'
import { flip, includes, reject } from 'ramda'

import type { RosreestrContext } from 'components/common/Rosreestr'
import { defaultToApi } from 'helpers/convertHelper'

import type { to } from './to'

type Parameters = ReturnType<typeof to> &
	RosreestrContext['registrationFormData'] &
	Pick<RosreestrContext, 'entity'> & { sourceDeclarants: Record<string, any>[] }

export const from = ({
	entity,
	contractNumber,
	operationTypeData,
	facility,
	sourceDeclarants,
	documents,
	declarants,
}: Parameters) => {
	const selectedDeclarants = Object.keys(declarants)
		.map((id) => declarants[id] && id)
		.filter(Boolean) as string[]

	const declarantsBody = selectedDeclarants.reduce((acc, id) => {
		const declarant = sourceDeclarants.find(({ customerId }) => id === customerId)!

		acc.push({
			subject: {
				id,
				type: declarant.customerType,
			},
			roles: reject(
				flip(includes)([ROLES_VALUES.WARRANTOR, ROLES_VALUES.APPLICANT]),
				declarant.roles
			),
		})

		return acc
	}, [] as Record<string, any>[])

	return {
		operationTypes: operationTypeData.operationTypes,
		loanId: entity?.name === 'LOAN' ? entity.id : null,
		applicationId: entity?.name === 'APPLICATION' ? entity.id : null,
		contractNumber: defaultToApi(contractNumber),
		facilityId: defaultToApi(facility.id),
		documents: Object.keys(documents)
			.map((id) => documents[id] && id)
			.filter(Boolean),
		declarants: declarantsBody,
		type: operationTypeData.type,
	}
}
