import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { LoanSellApplication } from 'converters/loanSellApplication'
import { commonActions } from 'reducers/common'
import type { RootState } from 'types/redux'
import type { Maybe } from 'types/util'

type Bank = {
	bank: string
}

type State = {
	data: (LoanSellApplication.Main & Bank)[]
	fetching: boolean
	filter: {
		id: Maybe<string>
		city: Maybe<string>
		name: Maybe<string>
		surname: Maybe<string>
		patronymic: Maybe<string>
		enabled: Maybe<boolean>
		query: Maybe<string>
	}
	sort: string
}

export const loanSellApplicationSelector = (state: RootState) => state.loanSellApplication.list.data

export const loanSellApplicationLoadingSelector = (state: RootState) =>
	state.loanSellApplication.list.fetching

const initialState: State = {
	data: [],
	fetching: false,
	filter: {
		id: null,
		city: null,
		name: null,
		surname: null,
		patronymic: null,
		enabled: null,
		query: null,
	},
	sort: 'createdAt,desc',
}

const list = createSlice({
	name: 'loanSellApplication/list',
	initialState,
	reducers: {
		resetData(state) {
			state.data = []
			state.fetching = false
		},

		setData(state, { payload }: PayloadAction<State['data']>) {
			state.data = payload
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions } = list
export default list.reducer
