import { useEffect, useRef } from 'react'

const createElement = (id) => {
	const rootContainer = document.createElement('div')

	rootContainer.setAttribute('id', id)

	return rootContainer
}

const usePortal = (id) => {
	const rootElemRef = useRef(createElement(id))

	useEffect(() => {
		const existingElement = document.querySelector(`#${id}`)

		if (existingElement) {
			rootElemRef.current = existingElement
		}

		document.body.appendChild(rootElemRef.current)

		return () => rootElemRef.current.remove()
	}, [id])

	return rootElemRef.current
}

export default usePortal
