import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Court } from 'converters/court'
import type { Maybe } from 'types/util'

import { commonActions } from '../common'

const initialState = {
	data: null as Maybe<Court.Main>,
	fetching: false,
}

const single = createSlice({
	name: 'court/single',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Court.Main>) {
			state.data = payload
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

const { actions } = single

export { actions }
export default single.reducer
