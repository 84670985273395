import { changePage, reset, setParameners } from 'reducers/listParameters/pagination'
import type { TRedux } from 'types'

export const resetPagination = reset
export const setPagination = setParameners
export const paginationChangePage = changePage

export const solveParamsPagination =
	(property: string, direction: 'desc' | 'asc' = 'desc', size = 10): TRedux.TAction<any> =>
	(_, getState) => {
		const storeSortParameters = getState().listParameters.sort
		const { currentPage } = getState().listParameters.pagination

		const sortParameters = {
			size,
			property,
			direction,
		}

		for (const key in storeSortParameters) {
			;(sortParameters as Record<keyof typeof storeSortParameters, any>)[
				key as keyof typeof storeSortParameters
			] = storeSortParameters[key as keyof typeof storeSortParameters]
				? storeSortParameters[key as keyof typeof storeSortParameters]
				: sortParameters[key as keyof typeof storeSortParameters]
		}

		return {
			page: currentPage,
			...sortParameters,
		}
	}
