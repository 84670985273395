import { type Address } from '@creditclubteam/kit/legacy-types'
import { DefaultValues } from 'helpers/convertHelper'

export const organization = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		fullName: model.string(['fullName']),
		shortName: model.string(['shortName']),
		taxId: model.string(['taxId']),
		ogrn: model.string(['ogrn']),
		kpp: model.string(['kpp']),
		phone: model.string(['phone']),
		email: model.string(['email']),
		registrationAddress: model.null<Address>(['registrationAddress']),
		mailingAddress: model.null<Address>(['mailingAddress']),
		representative: data?.representative
			? {
					name: model.string(['representative', 'name']),
					surname: model.string(['representative', 'surname']),
					patronymic: model.string(['representative', 'patronymic']),
					post: model.string(['representative', 'post']),
					phone: model.string(['representative', 'phone']),
					email: model.string(['representative', 'email']),
			  }
			: null,
	}
}
