import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { commonActions } from 'reducers/common'

const initialState = {
	paged: false,
	totalPages: 0,
	currentPage: 0,
	totalElements: 0,
}

const pagination = createSlice({
	name: 'pagination',
	initialState,
	reducers: {
		changePage(state, { payload }: PayloadAction<number>) {
			state.currentPage = payload
		},

		setParameners(state, { payload }: PayloadAction<typeof initialState>) {
			const { currentPage, paged, totalElements, totalPages } = payload

			state.paged = paged
			state.totalElements = totalElements
			state.currentPage = currentPage
			state.totalPages = totalPages
		},

		reset: commonActions.getResetActionCreator(initialState),
	},
})

export type PaginationState = typeof initialState
export const { changePage, reset, setParameners } = pagination.actions
export default pagination.reducer
