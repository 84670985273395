import { DefaultValues } from 'helpers/convertHelper'

export const textBlock = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		title: model.string(['title']),
		type: model.string<'text-block'>(['type']),
	}
}
