import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { actions } from 'reducers/person/spouses'
import type { TAction } from 'types/redux'

import { utils } from 'helpers'

export const resetPersonSpouses = actions.reset

export const getPersonSpouses = (): TAction<void> => async (dispatch, getState) => {
	try {
		const ids = getState().person.single.data!.form.marriageInfos.map(({ spouseId }) => spouseId)

		if (!ids.length) return

		dispatch(actions.setFetchingStatus(true))

		const { data } = await api.person.search({
			filter: { ids },
			size: 100,
		})

		dispatch(actions.setAll(data.content))
		dispatch(actions.setFetchingStatus(false))
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось загрузить данные по бракам',
				details: utils.getDetailsFromError(error),
			})
		)
	}
}
