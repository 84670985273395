import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { CreditPolicyReport } from 'converters/creditPolicyReport'
import { commonActions } from 'reducers/common'
import type { TRedux } from 'types'
import type { PayloadStatus } from 'types/redux'

const additionalRequestAdapter = createEntityAdapter<CreditPolicyReport.AdditionalRequest>()

export const additionalRequestSelector = additionalRequestAdapter.getSelectors<TRedux.RootState>(
	(state: TRedux.RootState) => state.creditPolicyReport.additionalRequest
)

const initialState = additionalRequestAdapter.getInitialState({
	status: 'pending' as PayloadStatus,
})

const additionalRequest = createSlice({
	name: 'creditPolicyReport/additionalRequest',
	initialState,
	reducers: {
		setAll: additionalRequestAdapter.setAll,
		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions: additionalRequestReducerActions } = additionalRequest
export default additionalRequest.reducer
