import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { resetSearch, searchEveryplace } from 'actions/search'
import api from 'api'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import { last } from 'ramda'
import type { RootState, TDispatch } from 'types/redux'

import { Autocomplete as AutocompleteComponent } from 'components/common'
import { utils } from 'helpers'

import styles from './UpdateDocumentMetadata.module.scss'

interface AutocompleteProps {
	value: string
	type: 'ORGANIZATION' | 'PERSON'
	onChange: (value: string) => void
	onReset: () => void
	title: string
	rejectId?: string
}

export const Autocomplete = ({
	type,
	value,
	onChange,
	rejectId,
	onReset,
	title,
}: AutocompleteProps) => {
	const dispatch = useDispatch<TDispatch>()
	const [query, setQuery] = useState<string | null>(null)
	const results = useSelector((state: RootState) => state.search.internal)

	const onChangeQuery = (value: string) => {
		dispatch(
			searchEveryplace(
				{
					methodType: type,
					direction: 'desc',
					searchIn: {
						internal: true,
						external: false,
					},
					params: {
						size: 10,
					},
					query: value,
				},
				'fio'
			)
		)
	}

	const onResetQuery = () => {
		if (onReset) {
			onReset()
		}
		dispatch(resetSearch())
	}

	const onSelectOption = (option: { id: string; label: string }) => {
		onChange(option.id)
		dispatch(resetSearch(true))
	}

	useEffect(() => {
		if (value) {
			const fetchData = async () => {
				if (type === 'ORGANIZATION') {
					const { data } = await api.organization.get(value)
					setQuery(data.form.fullName)
				} else {
					const { data } = await api.person.get(value)
					setQuery(data.fullName)
				}
			}
			fetchData()
		} else {
			setQuery('')
		}
	}, [value, type])

	const autoCompleteProps = {
		parentQuery: query,
		saveQueryOnUnmount: false,
		onReset: onResetQuery,
		onChange: onChangeQuery,
		className: styles.autocomplete,
		options: results
			.map((e) => ({
				id: e.id,
				info: utils.parsePhone(e.phone) || e.taxId,
				query: utils.getFullName(e) || e.phone || e.taxId || '—',
				label: utils.defaultOr(
					e.$$type === PARTICIPANTS_TYPES.PERSON
						? utils.join([
								e.fullName,
								!!e.previousFullNames.length &&
									`(${utils.getFullName(last(e.previousFullNames), true)})`,
						  ])
						: utils.getFullName(e)
				),
			}))
			.filter(({ id }) => (rejectId ? rejectId !== id : true)),
		onSelectOption: (selected: { id: string; label: string }) => onSelectOption(selected),
	}

	return (
		<div className={styles.row}>
			{query !== null && (
				<>
					<span className={styles.autocompleteTitle}>{title}</span>
					<AutocompleteComponent {...autoCompleteProps} />
				</>
			)}
		</div>
	)
}
