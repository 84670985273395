import { DefaultValues } from 'helpers/convertHelper'

import { accrual } from './accrual'
import { interestRateSpec } from './interestRateSpec'

type InterestRateSpecTypes = ReturnType<
	(typeof interestRateSpec)[keyof typeof interestRateSpec]
>['type']

const getInterestRateSpec = (data: Record<string, any>) => {
	switch (data?.type as InterestRateSpecTypes) {
		case 'FIXED':
			return interestRateSpec.fixed(data)
		case 'FIXED_INTEREST':
			return interestRateSpec.fixedInterest(data)
		case 'FLOATING_BY_TERM':
			return interestRateSpec.floatingByTerm(data)
		case 'FLOATING_BY_ROE':
			return interestRateSpec.floatingByRoe(data)
		default:
			return null
	}
}

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		name: model.string(['name']),
		description: model.string(['description']),
		product: model.string(['product']),
		interestRateSpec: getInterestRateSpec(data.interestRateSpec),
		maxLoanAmountFormula: model.string<'ROOM_AMOUNT_FALLBACK_TO_LTV'>(['maxLoanAmountFormula']),
		enabled: model.null<boolean>(['enabled']),
		representativePaymentNumber: model.null<number>(['representativePaymentNumber']),
		representativeIncreasedPaymentNumber: model.null<number>([
			'representativeIncreasedPaymentNumber',
		]),
		accruals: model.array(['accruals']).map(accrual),
		applicantTypes: model.array<string[]>(['applicantTypes']),
		defaultForApplicantTypes: model.array<string[]>(['defaultForApplicantTypes']),
	}
}
