import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Delivery } from 'converters/delivery'
import { commonActions } from 'reducers/common'
import type { Maybe } from 'types/util'

const initialState = {
	data: null as Maybe<Delivery.Main>,
	fetching: false,
}

const single = createSlice({
	name: 'delivery/single',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Delivery.Main>) {
			state.data = payload
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

const { actions } = single

export { actions }
export default single.reducer
