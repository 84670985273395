import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Organization } from 'converters/organization'
import { commonActions } from 'reducers/common'
import type { TUtils } from 'types'

const initialState = {
	data: null as TUtils.Maybe<Organization.Main>,
	fetching: false,
}

const single = createSlice({
	name: 'organization/single',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Organization.Main>) {
			state.data = payload
		},

		setFetchingStatus: commonActions.setFetchingStatus,

		updateCertificate(state, { payload }: PayloadAction<Organization.Main['konturInfo']>) {
			if (state.data) state.data.konturInfo = payload
		},

		deleteCertificateForm(state) {
			if (state.data?.konturInfo) state.data.konturInfo.form = null
		},

		reset: commonActions.reset(initialState),
	},
})

const { actions } = single

export { actions }
export default single.reducer
