export default async (store, request) => {
	const { $$noInterceptors, $$isMsal } = request

	// Не обрабатываем запросы если вызваны методы авторизации
	// или обновления токена или не сервисы майкрософт
	if ($$noInterceptors || !$$isMsal) return request

	// Меняем базовый url на сервисы майкрософт
	request.baseURL = 'https://test.api.office.credit.club'

	return request
}
