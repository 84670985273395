import type { FormikProps } from 'formik'

import { Button } from '@creditclubteam/react-components'
import FormLayout from 'components/common/FormLayout'

type Props = {
	form: FormikProps<any>
	buttonLabel: string
}

const Controls: React.FC<Props> = ({ form, buttonLabel }) => {
	const submitButton: React.ComponentProps<typeof Button> = {
		title: buttonLabel,
		buttonType: 'submit',
		disabled: form.isSubmitting,
	}

	return (
		<div className={FormLayout.styles.controls}>
			<Button {...submitButton} />
		</div>
	)
}

export default Controls
