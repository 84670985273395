import { useSelector } from 'react-redux'
import type { RootState } from 'types/redux'

import type { SelectProps } from '@creditclubteam/react-components'

export const useConfidantOptions = () => {
	const participants = useSelector((state: RootState) => state.organization.list.data)

	const confidantOptions = participants.reduce((acc, { participants }) => {
		const confidants = participants
			.filter(({ roles, type }) => roles.includes('CONFIDANT') && type === 'PERSON')
			.map(({ participant }) => ({
				id: participant.id,
				label: participant.fullName,
				value: participant.id,
			}))

		return [...acc, ...confidants]
	}, [] as SelectProps['options'])

	return { confidantOptions }
}
