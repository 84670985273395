import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Person } from 'converters/person'
import { commonActions } from 'reducers/common'
import type { TRedux } from 'types'
import type { Maybe } from 'types/util'

const initialState = {
	data: null as Maybe<Person.BonusAccount>,
	status: 'pending' as TRedux.PayloadStatus,
}

const bonuses = createSlice({
	name: 'person/bonuses',
	initialState,
	reducers: {
		setData: (state, { payload }: PayloadAction<Maybe<Person.BonusAccount>>) => {
			state.data = payload
		},

		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions: personBonusesReducerActions } = bonuses
export default bonuses.reducer
