import { URLBuilder } from 'api/helpers'
import type { EntityId } from 'api/types/EntityId'
import type { CreateRosreestrMortgageDto, RosreestrMortgageDto } from 'api/types/Rosreestr'
import axios from 'axios'

export default {
	add: (forms: unknown) =>
		axios({
			method: 'POST',
			url: 'v1/rosreestr/encumbrances',
			data: forms,
		}),

	find: (options: Record<string, any>) => {
		const params = new URLBuilder({
			size: options.size,
			sort: options.sort,
			page: options.page,
		})

		const { filter: { loanId } = {} as Record<string, any> } = options

		params.addFilter('loanId', Array.isArray(loanId) ? loanId : loanId && [loanId], 'inList')

		return axios({
			method: 'GET',
			url: `v1/rosreestr/encumbrances/search?${params.build()}`,
		})
	},

	edit: (packageId: EntityId, body: unknown) =>
		axios({
			method: 'PUT',
			data: body,
			url: `/v1/rosreestr/encumbrances/${packageId}`,
		}),

	sendToRosreestr: (packageId: EntityId) =>
		axios({
			method: 'POST',
			url: `/v1/rosreestr/encumbrances/${packageId}/rosreestr`,
		}),

	delete: (packageId: EntityId) =>
		axios({
			method: 'DELETE',
			url: `/v1/rosreestr/encumbrances/${packageId}`,
		}),

	createMortgage: (data: CreateRosreestrMortgageDto) =>
		axios.post<RosreestrMortgageDto>('/v1/rosreestr/mortgages', data),

	searchMortgages: (options: Record<string, any>) => {
		const params = new URLBuilder()

		const { filter: { loanId } = {} as Record<string, any> } = options

		params.addFilter('loanId', Array.isArray(loanId) ? loanId : loanId && [loanId], 'inList')

		return axios.get<{ content: Array<RosreestrMortgageDto> }>('/v1/rosreestr/mortgages/search', {
			params: {
				size: options.size,
				sort: options.sort,
				page: options.page,
				filter: params.build('RSQL'),
			},
		})
	},

	sendRosreestrMortgage: (id: string) => axios.post(`/v1/rosreestr/mortgages/${id}/rosreestr`),
}
