import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Loan } from 'converters/loan'
import { commonActions } from 'reducers/common'
import type { TUtils } from 'types'

const initialState = {
	data: null as TUtils.Maybe<Loan.Main>,
	fetching: false,
	fetchingParticipants: false,
}

const single = createSlice({
	name: 'loan/single',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Loan.Main>) {
			state.data = payload
		},

		setFetchingByField(
			state,
			{
				payload: { field, value },
			}: PayloadAction<{ field: 'fetchingParticipants'; value: boolean }>
		) {
			state[field] = value
		},

		updateData(state, { payload }: PayloadAction<Partial<Loan.Main>>) {
			if (state.data) {
				state.data = Object.assign(state.data, payload)

				return state
			}
		},

		resolveNextStatus(state, { payload }: PayloadAction<Loan.Status>) {
			if (state.data) {
				state.data.status = payload
			}
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions } = single
export default single.reducer
