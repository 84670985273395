import { DefaultValues } from 'helpers/convertHelper'

export const commission = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		participantId: model.string(['participantId']),
		participantType: model.string(['participantType']),
		accruedAmount: model.null<number>(['accruedAmount']),
		paidAmount: model.null<number>(['paidAmount']),
		remainAmount: model.null<number>(['remainAmount']),
	}
}
