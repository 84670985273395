import { FINANCIAL_TYPES } from 'const/financialProductTypes'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import { utils } from 'helpers'

const CalculationShort = ({ calculationValue }) => {
	if (calculationValue && !isEmpty(calculationValue)) {
		const calc = {
			loan: calculationValue.loan ? utils.RUB(calculationValue.loan, true) : 0,
			period: calculationValue.period || 0,
			percent: calculationValue.percent || 0,
			type: FINANCIAL_TYPES[calculationValue.type],
			payment: calculationValue.payment
				? `${utils.RUB(calculationValue.payment, true)} в мес. `
				: '',

			increasedPayment: calculationValue.increasedPayment,
		}

		return (
			<div>
				<>
					{calc.loan && calc.period && calc.percent ? (
						<p>
							{calc.loan} на {calc.period} мес. под {calc.percent}%
						</p>
					) : (
						<p>Неизвестно</p>
					)}
					<p className='grey'>
						{calc.payment}{' '}
						{calc.increasedPayment && `(${utils.RUB(calc.increasedPayment)} c 1 по 3 мес.), `}{' '}
						{calc.type}
					</p>
				</>
			</div>
		)
	}

	return (
		<div>
			<p className='grey'>Нет расчета</p>
		</div>
	)
}

CalculationShort.propTypes = {
	calculationValue: PropTypes.object.isRequired,
}

export default CalculationShort
