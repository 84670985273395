import { combineReducers } from 'redux'

import bonuses from './bonuses'
import incomes from './incomes'
import investmentClient from './investmentClient'
import shares from './shares'
import list from './list'
import representatives from './representatives'
import single from './single'
import spouses from './spouses'

export default combineReducers({
	list,
	single,
	incomes,
	investmentClient,
	shares,
	bonuses,
	spouses,
	representatives,
})
