import { Link } from 'react-router-dom'
import cx from 'clsx'
import PropTypes from 'prop-types'

import { Actions } from './Actions'

import styles from './ListRow.module.scss'

const ListRow = ({
	children,
	id,
	className,
	getRef,
	noHoverEffects,
	to,
	actions,
	enableActions = true,
	header,
	onClick,
}) => (
	<div
		id={id}
		ref={(el) => getRef && getRef(el)}
		onClick={onClick}
		className={cx(styles.wrapper, styles.hover, {
			[className]: !!className,
			[styles.noHoverEffects]: noHoverEffects,
		})}
	>
		{to && <Link to={to} />}
		<div className={styles.wrap}>
			{!!header && <div className={styles.head}>{header}</div>}
			<div className={styles.body}>
				<div className={styles.info}>{children}</div>
				{enableActions && !!actions && (
					<Actions hoverClass={styles.actionsHover} actions={actions} />
				)}
			</div>
		</div>
	</div>
)

ListRow.propTypes = {
	id: PropTypes.string,
	getRef: PropTypes.func,
	onClick: PropTypes.func,
	className: PropTypes.string,
	noHoverEffects: PropTypes.bool,
	children: PropTypes.any.isRequired,
}

export default ListRow
