import {
	fetchBucket,
	fetchInspector,
	getFilesList,
	resetBucket,
	resetInspector,
} from 'actions/filesInspector'
import api from 'api'
import { INSPECTOR_CATEGORY_ORDER } from 'const/filesInspector'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import { flatten } from 'ramda'
import { actions } from 'reducers/filesInspector/main'

import { FACILITY_TYPES } from '@creditclubteam/helpers'
import { utils } from 'helpers'

export const resetSelectedList = actions.resetSelectedList

// Возвращает true если такой id уже есть в списке выбранных файлов
const fileIsSelected = (fileId) => (_, getState) => {
	const { selectedFiles } = getState().filesInspector.main

	return Boolean(selectedFiles.find((file) => file.id === fileId))
}

// Возвращает true если в списке выбранных есть другие зоны
const wasOthersZone = (zoneId) => (_, getState) => {
	const { selectedFiles } = getState().filesInspector.main

	return selectedFiles.some((file) => file.zoneId !== zoneId)
}

export const fileInspectorMainSetField = actions.setField

export const resetFilesInspector = () => (dispatch) => {
	dispatch(actions.reset())
	dispatch(resetBucket())
	dispatch(resetInspector())
}

export const getParticipantsFetchingStatus = () => (_, getState) => {
	const { person, facility, entrepreneur, organization } = getState()

	return [person, entrepreneur, facility, organization].find((item) => item.list.fetching)
}

export const getParticipantsList = () => (_, getState) => {
	const { person, entrepreneur, facility, organization } = getState()

	return [].concat(
		person.list.data,
		entrepreneur.list.data,
		facility.list.data,
		organization.list.data
	)
}

export const activateParticipant = (id) => (dispatch, getState) => {
	const { currentParticipant } = getState().filesInspector.main

	// При активации уже выбранного участника
	if (currentParticipant === id) return

	const participantData = dispatch(getParticipantsList()).find(
		(participant) => participant.id === id
	)

	if (participantData) {
		dispatch(fileInspectorMainSetField({ field: 'currentParticipant', value: id }))
		dispatch(
			fetchInspector(id, participantData.$$type, (categories) => {
				if (INSPECTOR_CATEGORY_ORDER[participantData.$$type]) {
					return categories
						.filter((category) =>
							INSPECTOR_CATEGORY_ORDER[participantData.$$type].includes(category.id)
						)
						.sort((a, b) => {
							if (
								INSPECTOR_CATEGORY_ORDER[participantData.$$type].indexOf(a.id) >
								INSPECTOR_CATEGORY_ORDER[participantData.$$type].indexOf(b.id)
							) {
								return 1
							}

							if (
								INSPECTOR_CATEGORY_ORDER[participantData.$$type].indexOf(a.id) <
								INSPECTOR_CATEGORY_ORDER[participantData.$$type].indexOf(b.id)
							) {
								return -1
							}

							return 0
						})
				}

				return categories
			})
		)
	}
}

export const toggleSelectionFiles = (fileId, zoneId) => (dispatch, getState) => {
	const { updateFileId } = getState().filesInspector.main

	// Если функция вызывается без аргументов скидываем список
	if (!fileId || !zoneId) {
		dispatch(resetSelectedList())
		return
	}

	if (updateFileId) dispatch(fileInspectorMainSetField({ field: 'updateFileId', value: null }))

	if (!dispatch(fileIsSelected(fileId))) {
		// Смотрим есть ли в списке другие зоны
		if (dispatch(wasOthersZone(zoneId))) dispatch(resetSelectedList())
		dispatch(actions.addFileToSelectedList({ id: fileId, zoneId }))
	} else {
		dispatch(actions.resetFileFromSelectedList(fileId))
	}
}

export const rangeSelectionFiles = (fileId, zoneId) => (dispatch, getState) => {
	const { updateFileId, rangeSelected } = getState().filesInspector.main

	if (updateFileId) dispatch(fileInspectorMainSetField({ field: 'updateFileId', value: null }))

	// Cмотрим если ли другие выбранные зоны, если есть сбрасываем и начинаем сначала
	if (dispatch(wasOthersZone(zoneId))) {
		dispatch(resetSelectedList())
		dispatch(rangeSelectionFiles(fileId, zoneId))
		return
	}

	// Записываем первый промежуток
	if (!utils.hasObjectLength(rangeSelected.from)) {
		dispatch(actions.setRangeList({ purpose: 'from', file: { id: fileId, zoneId } }))
		if (!dispatch(fileIsSelected(fileId)))
			dispatch(actions.addFileToSelectedList({ id: fileId, zoneId }))
		return
	}

	// Записываем второй промежуток
	if (!utils.hasObjectLength(rangeSelected.to)) {
		const fromFile = getState().filesInspector.main.rangeSelected.from

		// Если у промежутков не совпадает категория, скидываем и начинаем сначала
		if (fromFile.zoneId !== zoneId) {
			dispatch(resetSelectedList())
			dispatch(rangeSelectionFiles(fileId, zoneId))
			return
		}

		dispatch(actions.setRangeList({ purpose: 'to', file: { id: fileId, zoneId } }))
		if (!dispatch(fileIsSelected(fileId)))
			dispatch(actions.addFileToSelectedList({ id: fileId, zoneId }))

		// Получаем список в котором происходит выделение
		const filesList = Array.from(
			zoneId === 'bucket' ? getState().filesInspector.bucket.files : dispatch(getFilesList(zoneId))
		)

		// Собираем индексы промежутков
		const indexS = [
			filesList.findIndex((item) => item.id === fromFile.id),
			filesList.findIndex((item) => item.id === fileId),
		]

		// Определяем от куда начинать
		const toIndex = Math.max(...indexS)
		const fromIndex = Math.min(...indexS)

		filesList.forEach(({ id }, index) => {
			if (index > fromIndex && index < toIndex && !dispatch(fileIsSelected(id))) {
				dispatch(actions.addFileToSelectedList({ id, zoneId }))
			}
		})

		return
	}

	dispatch(resetSelectedList())
	dispatch(rangeSelectionFiles(fileId, zoneId))
}

export const getContextMenuOptions = () => async (dispatch, getState) => {
	const { person, entrepreneur, facility, organization } = getState()

	const list = [
		...person.list.data,
		...entrepreneur.list.data,
		...facility.list.data,
		...organization.list.data,
	]

	const arrOptions = utils.hasObjectLength(list)
		? list.map((item) => {
				const { $$type, type, id, surname, patronymic, name } = item

				switch ($$type) {
					case PARTICIPANTS_TYPES.PERSON:
						return { id, label: utils.getFullName({ name, surname, patronymic }), type: $$type }
					case PARTICIPANTS_TYPES.ENTREPRENEUR:
						return { id, label: name, type: $$type }
					case PARTICIPANTS_TYPES.ORGANIZATION:
						return { id, label: name, type: $$type }
					case PARTICIPANTS_TYPES.FACILITY:
						return {
							id,
							label: utils.join(
								[FACILITY_TYPES[type], item.form?.area && `${item.form?.area} м²`],
								' '
							),
							type: $$type,
						}
					case 'APPLICATION':
						return { id, label: 'Заявка', type: $$type }
					case 'LOAN':
						return { id, label: 'Заём', type: $$type }
					case 'LEAD':
						return { id, label: 'Лид', type: $$type }
					default:
						return {}
				}
		  })
		: []

	const data = await Promise.all(
		arrOptions.map(async (option) => {
			const options = {
				filter: {
					ownerTypes: [option.type],
				},
			}

			const categories = await api.document.getCategories(options).then(({ data }) =>
				data.content.map(({ id, title, metadataFields }) => ({
					id,
					title,
					metadataFields: metadataFields?.length ? metadataFields : null,
				}))
			)

			if (INSPECTOR_CATEGORY_ORDER[option.type]) {
				const filteredCategories = categories
					.filter((category) => INSPECTOR_CATEGORY_ORDER[option.type].includes(category.id))
					.sort((a, b) => {
						if (
							INSPECTOR_CATEGORY_ORDER[option.type].indexOf(a.id) >
							INSPECTOR_CATEGORY_ORDER[option.type].indexOf(b.id)
						) {
							return 1
						}

						if (
							INSPECTOR_CATEGORY_ORDER[option.type].indexOf(a.id) <
							INSPECTOR_CATEGORY_ORDER[option.type].indexOf(b.id)
						) {
							return -1
						}

						return 0
					})

				return {
					...option,
					categories: filteredCategories,
				}
			}

			return {
				...option,
				categories,
			}
		})
	)

	dispatch(actions.setField({ field: 'contextMenuOptions', value: data }))
}

export const init = (entityId) => (dispatch) => {
	dispatch(fileInspectorMainSetField({ field: 'entityId', value: entityId }))
	dispatch(fetchBucket(entityId))
}

export const initMetadataForm =
	({ destinationCategory, mode = 'move', selectedFilesIds, moveFilesParameters }) =>
	(dispatch, getState) => {
		dispatch(actions.setModalVisibility({ key: 'metadata', value: true }))

		const files = flatten([
			...getState().filesInspector.bucket.files,
			...getState().filesInspector.inspector.categories.map(({ $$files }) => $$files),
		])
			.filter(({ id }) => selectedFilesIds.includes(id))
			.map(({ id, title, metadata, categoryId }) => ({ id, title, metadata, categoryId }))

		dispatch(
			actions.setMetadata({
				moveFilesParameters,
				files,
				mode,
				fields: destinationCategory.metadataFields,
			})
		)
	}
