import { DefaultValues } from 'helpers/convertHelper'

export const paymentSchedule = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		savingId: model.string(['savingId']),
		createdAt: model.string(['createdAt']),
		payments: model.array(['payments']).map((_, i) => ({
			date: model.string(['payments', i, 'date']),
			totalAmount: model.null<number>(['payments', i, 'totalAmount']),
			interestAmount: model.null<number>(['payments', i, 'interestAmount']),
			cashbackAmount: model.null<number>(['payments', i, 'cashbackAmount']),
		})),
	}
}
