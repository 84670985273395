import { addServerError } from 'actions/serverErrors'
import api from 'api'
import type { Worker } from 'converters/worker'
import { actions } from 'reducers/worker'
import type { TRedux } from 'types'

import { utils } from 'helpers'

export const getWorkers =
	(parameters?: Parameters<typeof api.worker.search>[0]): TRedux.TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			dispatch(actions.setFetchingStatus(true))

			const workers: Worker.Main[] = []

			const getParameters = (page: number = 0) => Object.assign({ size: 100, page }, parameters)

			for (let i = 0; i < 10; ++i) {
				const { data } = await api.worker.search(getParameters(i))

				workers.push(...data)

				if (workers.length % 100 !== 0) {
					break
				}
			}

			dispatch(actions.setAll(workers))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить сотрудников',
					details: utils.getDetailsFromError(error),
				})
			)
		} finally {
			dispatch(actions.setFetchingStatus(false))
		}
	}
