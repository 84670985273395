import { Component } from 'react'
import cx from 'clsx'
import PropTypes from 'prop-types'
// https://react-component.github.io/slider/
import Slider from 'rc-slider'

import { downloadFile } from '@creditclubteam/helpers'
import { utils } from 'helpers'

import './AudioPlayer.scss'

class AudioPlayer extends Component {
	static propTypes = {
		className: PropTypes.string,
		src: PropTypes.string.isRequired,

		/**
		 * Если передана то загрузка не будет осуществленна
		 * так кк компонент будет ожидать этого от внешнего кода.
		 * Необходимо в случая когда нужно сформировать пользовательское
		 * название файлу исходя из каких то данных.
		 */
		onDownload: PropTypes.func,
	}

	constructor(props) {
		super(props)

		if (props.src) {
			this.audio = new Audio(props.src)
		}

		this.state = {
			error: false,
			played: false,
			duration: null,
			currentTime: 0,
		}
	}

	componentDidMount() {
		if (this.audio) {
			this.audio.addEventListener('ended', this.handleEnded)
			this.audio.addEventListener('error', this.handleError)
			this.audio.addEventListener('timeupdate', this.playningProcess)
			this.audio.addEventListener('loadedmetadata', this.audioLoaded)
		}
	}

	componentWillUnmount() {
		if (this.audio) {
			if (this.state.played) this.audio.pause()

			this.audio.removeEventListener('ended', this.handleEnded)
			this.audio.removeEventListener('error', this.handleError)
			this.audio.removeEventListener('timeupdate', this.playningProcess)
			this.audio.removeEventListener('loadedmetadata', this.audioLoaded)
		}
	}

	audioLoaded = () => this.setState({ duration: this.audio.duration })

	handleError = () => this.setState({ error: true })

	handleDownload = () => {
		const { onDownload, src } = this.props

		if (onDownload) {
			onDownload(src)
		} else {
			downloadFile(src)
		}
	}

	handleEnded = () => {
		const { played } = this.state

		if (played) this.audio.pause()
		this.setState({ played: false })
	}

	handleStart = () => {
		const { played } = this.state

		if (!played && this.audio) {
			this.audio.play()
			this.setState({ played: true })
		}
	}

	toggleStream = () => {
		const { played } = this.state

		if (played) this.handleEnded()
		else this.handleStart()
	}

	playningProcess = () => this.setState({ currentTime: this.audio.currentTime })

	render() {
		const { className } = this.props
		const { played, duration, currentTime, error } = this.state

		const progressBarOptions = {
			min: 0,
			step: 0.01,
			max: duration,
			value: currentTime,
			className: 'cc-audioplayer-progress-bar',
			onChange: (timePoint) => (this.audio.currentTime = timePoint),
		}

		return (
			<div
				className={cx('cc-audioplayer', {
					[className]: !!className,
					'cc-audioplayer--error': error,
				})}
			>
				<div className='cc-audioplayer-stream-controls'>
					<div className='cc-audioplayer-stream-controls-item' onClick={this.toggleStream}>
						<div
							className={cx('cc-audioplayer-stream-controls-play', {
								'cc-audioplayer-stream-controls-play--error': error,
							})}
						>
							{error ? (
								<i className='zmdi zmdi-alert-octagon' />
							) : (
								<i className={`zmdi zmdi-${played ? 'pause' : 'play'}-circle`} />
							)}
						</div>
					</div>
					<div className='cc-audioplayer-stream-controls-item' onClick={this.handleDownload}>
						<div className='cc-audioplayer-stream-controls-download'>
							<i className='zmdi zmdi-download' />
						</div>
					</div>
				</div>
				<div className='cc-audioplayer-duration'>
					{utils.formatToHHMMSS(currentTime)}
					<span> - </span>
					{duration ? (
						utils.formatToHHMMSS(duration)
					) : (
						<span className='cc-audioplayer-duration__loading'>00:00</span>
					)}
				</div>
				<div className='cc-audioplayer-track'>
					{duration ? (
						<Slider {...progressBarOptions} />
					) : (
						<div className='cc-audioplayer-track__loading' />
					)}
				</div>
			</div>
		)
	}
}

export default AudioPlayer
