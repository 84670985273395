import { PARTICIPANTS_TYPES } from 'const/participantsTypes'

import { utils } from 'helpers'
import { defaultToApi } from 'helpers/convertHelper'

const { PERSON, ENTREPRENEUR, ORGANIZATION } = PARTICIPANTS_TYPES

const INTERNAL = 'internal'
const EXTERNAL = 'external'

/**
 * @description - Приводит все результаты в читабельный вид для
 * атокомплита
 * @param {string} type
 * @param {object} searchResults
 */
export const formatSearchData = (type, searchResults) => {
	const categories = []
	const { internal, external } = searchResults

	// Проходимся по категориям, смотрим если там что есть
	// формируем результат и выдаём массив categories
	for (const i in searchResults) {
		if (i === INTERNAL && utils.hasObjectLength(searchResults[i]))
			categories.push({
				id: i,
				label: 'По базе',
			})

		if (i === EXTERNAL && utils.hasObjectLength(searchResults[i])) {
			let label = ''

			if (type === PERSON) label = 'Подсказка по ФИО'
			else if (type === ENTREPRENEUR) label = 'Подсказка по ИП'
			else if (type === ORGANIZATION) label = 'Подсказка по организации'

			categories.push({ id: i, label })
		}
	}

	// Формируем список подсказок и склеиваем их в один массив options
	const options = [].concat(internal, external).reduce((acc, item) => {
		const category = item.$$category
		let label = null
		let info = null

		if (type === PERSON) {
			info = utils.parsePhone(item.phone) || null
			label = category === INTERNAL ? utils.getFullName(item) : item.value
		}

		if (type === ENTREPRENEUR) {
			info = `ИНН ${category === INTERNAL ? item.taxId : item.data.inn}`
			label = category === INTERNAL ? item.name : item.value
		}

		if (type === ORGANIZATION) {
			label = category === INTERNAL ? item.name : item.value
			info = `ИНН ${category === INTERNAL ? item.taxId : item.data.inn}`
		}

		acc.push({ ...item, id: item.id || null, info, label })

		return acc
	}, [])

	return { options, categories }
}

export const createPersonRequestBodyForAdding = (options) => ({
	phone: defaultToApi(utils.unparsePhone(options.phone)),
	name: defaultToApi(options.subject?.data ? options.subject.data.name : options.query),
	surname: defaultToApi(options.subject?.data?.surname),
	patronymic: defaultToApi(options.subject?.data?.patronymic),
	gender: defaultToApi(options.subject?.data?.gender),
})

export const createOrganizationRequestBodyForAdding = (options) => ({
	taxId: defaultToApi(options.subject?.data.inn),
	name: defaultToApi(options.subject?.value),
	director: options.subject?.director
		? {
				id: defaultToApi(options.subject.director.id),
				type: defaultToApi(options.subject.director.type),
		  }
		: null,
})

export const createEntrepreneurRequestBodyForAdding = (options) => ({
	taxId: defaultToApi(options.subject?.data?.inn),
	name: defaultToApi(options.subject?.value),
})

export const requestsBodies = {
	[PARTICIPANTS_TYPES.PERSON]: createPersonRequestBodyForAdding,
	[PARTICIPANTS_TYPES.ORGANIZATION]: createOrganizationRequestBodyForAdding,
	[PARTICIPANTS_TYPES.ENTREPRENEUR]: createEntrepreneurRequestBodyForAdding,
}
