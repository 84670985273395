import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import type { RosreestrRegistration } from 'converters/rosreestrRegistration'
import { commonActions } from 'reducers/common'
import type { PayloadStatus, RootState } from 'types/redux'
import type { Maybe } from 'types/util'

const rosreestrRegistrationAdapter = createEntityAdapter<RosreestrRegistration.Main>()

export const selectRosreestrRegistration = rosreestrRegistrationAdapter.getSelectors(
	(state: RootState) => state.rosreestrRegistration.main
)

export const selectSelectedRosreestrRegistration = (state: RootState) =>
	selectRosreestrRegistration.selectById(state, state.rosreestrRegistration.main.selectedId!)

export const selectFacilityFromRosreestrRegistration = createSelector(
	[
		(state: RootState) => selectSelectedRosreestrRegistration(state)!.facilityId,
		(state: RootState) => state.facility.list.data,
	],
	(facilityId, list) => list.find(({ id }) => id === facilityId)
)

export const selectApplicationNumberFromRosreestrRegistration = createSelector(
	(state: RootState) => state.rosreestrRegistration.main.applicationNumber,
	(v) => v
)

export const selectStatementDeclarantsFromRosreestrRegistration = createSelector(
	(state: RootState) => selectSelectedRosreestrRegistration(state)!.operations,
	(operations) =>
		operations.find(({ type }) => type === 'LOAN_MORTGAGE_ISSUE')?.declarants.map((id) => id) ??
		null
)

export const selectMortgageOperationFromRosreestrRegistration = createSelector(
	(state: RootState) => selectSelectedRosreestrRegistration(state)!.operations,
	(operations) => operations.find(({ type }) => type === 'MORTGAGE')
)

export interface RosreestrRegistrationFilter {
	status: Maybe<string[]>
	type: Maybe<string[]>
	loanId: Maybe<string>
	applicationId: Maybe<string>
	initOperationType: Maybe<string>
	facilityId: Maybe<string>
	participantId: Maybe<string>
}

export const filter: RosreestrRegistrationFilter = {
	status: null,
	facilityId: null,
	type: null,
	loanId: null,
	applicationId: null,
	initOperationType: null,
	participantId: null,
}

export const initialState = rosreestrRegistrationAdapter.getInitialState({
	status: 'pending' as PayloadStatus,
	preserveFilter: false,
	selectedId: null as Maybe<string>,
	applicationNumber: null as Maybe<string>,
	filter,
})

const main = createSlice({
	name: 'rosreestrRegistration/main',
	initialState,
	reducers: {
		upsertMany: rosreestrRegistrationAdapter.upsertMany,
		upsertOne: rosreestrRegistrationAdapter.upsertOne,
		setAll: rosreestrRegistrationAdapter.setAll,

		selectId: (state, { payload }: PayloadAction<Maybe<string>>) => {
			state.selectedId = payload
		},
		// для понимания когда надо ставить дефолтные фильтры добавил этот флаг,
		// т.к. у этой сущности отсутствует pipeline
		setPreserveFilter(state, { payload }: PayloadAction<boolean>) {
			state.preserveFilter = payload
		},

		setApplicationNumber: (state, { payload }: PayloadAction<Maybe<string>>) => {
			state.applicationNumber = payload
		},

		setStatus: (state, { payload }: PayloadAction<PayloadStatus>) => {
			state.status = payload
		},

		setFilter: commonActions.setFilter(),
		reset: commonActions.reset(initialState),
	},
})

const { actions } = main

export { actions }
export default main.reducer
