import type { ScoringDocuments } from 'converters/scoring/person/documents/types'
import type { TUtils } from 'types'

import { date, moneyUtils } from '@creditclubteam/helpers'
import { DefaultValues } from 'helpers/convertHelper'

const getPeriods = (data?: Record<string, any>): ScoringDocuments.Field[] => {
	const model = new DefaultValues(data)

	const amount = model.null<number>(['amount'])
	const quarters = model.array<number[]>(['quarters'])
	const startDate = date.format(model.string(['startDate']))
	const endDate = date.format(model.string(['endDate']))

	return [
		{
			collection: 'periods',
			type: 'DATE',
			key: 'startDate',
			value: startDate,
			readonlyValue: startDate,
			title: 'Начало периода',
		},
		{
			type: 'DATE',
			key: 'endDate',
			value: endDate,
			readonlyValue: endDate,
			title: 'Конец периода',
		},
		{
			type: 'MULTI-SELECT',
			key: 'quarters',
			value: quarters,
			options: [
				{ id: 1, label: 1 },
				{ id: 2, label: 2 },
				{ id: 3, label: 3 },
				{ id: 4, label: 4 },
			],
			readonlyValue: quarters.join(', '),
			title: 'Кварталы',
		},
		{
			key: 'amount',
			value: model.string(['amount']),
			readonlyValue: moneyUtils.RUB(amount),
			type: 'NUM',
			title: 'Сумма',
		},
	]
}

export const ilsPfr = <T extends Record<string, any>>(
	data: T,
	isTemplate?: boolean
): TUtils.Maybe<ScoringDocuments.SourceDocument> => {
	if (!isTemplate && !data) return null

	const model = new DefaultValues(data)

	const periods = model.array(['periods'])

	return {
		id: 'ilsPfr',
		collections: [['periods', getPeriods]],
		rows: [
			[
				{
					type: 'TEXT',
					key: 'employer.name',
					span: '1/4',
					value: model.string(['employer', 'name']),
					title: 'Наименование организации',
				},
			],

			...periods.reduce((acc: ScoringDocuments.Field[][], curr) => {
				acc.push(getPeriods(curr))

				return acc
			}, []),
		],
	}
}
