import type { TUtils } from 'types'

import { fileUtils } from '@creditclubteam/helpers'
import { DefaultValues } from 'helpers/convertHelper'

import { owner } from './owner'
import { signature } from './signature'
import { simpleSignature } from './simpleSignature'

export const file = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
		titleWithoutExtension: fileUtils.removeFileExtension(model.string(['title'])),
		extension: model.string(['extension']),
		categoryId: model.string(['categoryId']),
		objectKey: model.string(['objectKey']),
		createdAt: model.string(['createdAt']),
		size: model.null<number>(['size']),
		owners: model.array(['owners']).map(owner),
		signatures: model.array(['signatures']).map(signature),
		metadata: data.metadata as TUtils.Maybe<Record<string, any>>,
		simpleSignatures: model.array(['simpleSignatures']).map(simpleSignature),
		konturInfo: data.konturInfo as TUtils.Maybe<Record<string, any>>,
		virtualCategory: {
			ROSREESTR_REGISTRATION: 'ROSREESTR_REGISTRATION',
			DELIVERY: 'DOCUMENTS_FOR_DELIVERY',
		} as const,
	}
}
