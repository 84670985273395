import { ACTIVITY_STATUS_RUS } from 'const'

import { DefaultValues } from 'helpers/convertHelper'

import { okved } from './okved'

export const form = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		status: model.string<keyof typeof ACTIVITY_STATUS_RUS>(['status']),
		statusRus: model.const(data.status, ACTIVITY_STATUS_RUS),
		registrationDate: model.string(['registrationDate']),
		ogrnip: model.string(['ogrnip']),
		registrationNumberPf: model.string(['registrationNumberPf']),
		registrationDatePf: model.string(['registrationDatePf']),
		okpo: model.string(['okpo']),
		oktmo: model.string(['oktmo']),
		okato: model.string(['okato']),
		okogu: model.string(['okogu']),
		okopf: model.string(['okopf']),
		okfs: model.string(['okfs']),
		entrepreneurType: model.string(['entrepreneurType']),
		taxAuthorityName: model.string(['taxAuthorityName']),
		complementaryActivities: model.array(['complementaryActivities']).map(okved),
		actualAddress: model.null<Record<string, any>>(['actualAddress']),
		legalAddress: model.null<Record<string, any>>(['legalAddress']),
		activitiesCount: model.null<number>(['activitiesCount']),
		prosecutorOfficeChecksCount: model.null<number>(['prosecutorOfficeChecksCount']),
		licencesCount: model.null<number>(['licencesCount']),
		arrestedAccountsCount: model.null<number>(['arrestedAccountsCount']),
		sroMembershipsCount: model.null<number>(['sroMembershipsCount']),
		wonTradesCount: model.null<number>(['wonTradesCount']),
		wonTradesAmount: model.null<number>(['wonTradesAmount']),
		trademarksCount: model.null<number>(['trademarksCount']),
		negativeFromEgripChangesLast30Days: model.null<boolean>(['negativeFromEgripChangesLast30Days']),
		egripUpcomingExclusion: model.null<boolean>(['egripUpcomingExclusion']),
		efrsbNegative: model.null<boolean>(['efrsbNegative']),
		lackOfRequiredOkvedLicenses: model.null<boolean>(['lackOfRequiredOkvedLicenses']),
		siteInProhibitedSitesRegistry: model.null<boolean>(['siteInProhibitedSitesRegistry']),
		principalActivity: okved(data.principalActivity),
	}
}
