import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { syncDocument } from 'actions/delivery'
import cx from 'clsx'
import type { TRedux } from 'types'

import styles from './SynchronizationDocuments.module.scss'

type TextBtnSynch = 'Синхронизировать документы' | 'Синхронизируем…'

export const SynchronizationDocuments = () => {
	const dispatch = useDispatch<TRedux.TDispatch>()
	const [isLoading, setIsLoading] = useState<boolean>(false)
	const [textBtn, setTextBtn] = useState<TextBtnSynch>('Синхронизировать документы')

	const handleSyncDocuments = async () => {
		setIsLoading(true)
		setTextBtn('Синхронизируем…')
		try {
			await dispatch(syncDocument())
			setIsLoading(false)
			setTextBtn('Синхронизировать документы')
		} finally {
			setIsLoading(false)
			setTextBtn('Синхронизировать документы')
		}
	}

	return (
		<>
			<div className={cx(styles.wrap)}>
				<button disabled={isLoading} className={cx(styles.button)} onClick={handleSyncDocuments}>
					<i className={cx('zmdi zmdi-refresh', styles.icon)} />
					<span>{textBtn}</span>
				</button>
			</div>
		</>
	)
}
