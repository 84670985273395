import { brief } from './brief'
import { main } from './main'
import { moneyMovementOperation } from './moneyMovementOperation'
import { paymentSchedule } from './paymentSchedule'

export const deposit = {
	main,
	brief,
	moneyMovementOperation,
	paymentSchedule,
}

export namespace Deposit {
	export type Main = ReturnType<typeof main>
	export type Brief = ReturnType<typeof brief>
	export type MoneyMovementOperation = ReturnType<typeof moneyMovementOperation>
	export type PaymentSchedule = ReturnType<typeof paymentSchedule>
}
