import { URLBuilder } from 'api/helpers'
import type { EntityId } from 'api/types/EntityId'
import axios from 'axios'
import { REQUEST_VERSION } from 'const/api'
import moment from 'moment'
import { ge, le } from 'rsql-builder'

import { utils } from 'helpers'
import { defaultToStr } from 'helpers/convertHelper'

export default {
	search(options: Record<string, any>) {
		const { entityId, types, startDate, endDate } = options.filter
		const params = new URLBuilder()

		params
			.addFilter('entityId', entityId, 'inList')
			.addFilter('type', types, 'inList')
			.addFilter('createdAt', startDate && moment.isDate(startDate) && startDate.toISOString(), ge)
			.addFilter('createdAt', endDate && moment.isDate(endDate) && endDate.toISOString(), le)

		return axios.get('/history/search', {
			$$requestName: 'history.search',
			params: {
				size: options.size,
				page: options.page,
				sort:
					utils.join([defaultToStr(options.property), defaultToStr(options.direction)], ',') ||
					undefined,
				filter: params.build('RSQL'),
			},
			headers: {
				[REQUEST_VERSION]: '1.1',
			},
		})
	},

	/**
	 * @param {string} data.text
	 * @param {object} data.entity
	 */
	addComment: (data: unknown) =>
		axios.post('/history/comment', data, {
			headers: {
				'Content-Type': 'application/json',
				[REQUEST_VERSION]: '1.1',
			},
		}),

	editComment: (commentId: EntityId, newText: unknown) =>
		axios.post(`/history/comment/${commentId}`, newText, {
			headers: {
				'Content-Type': 'text/html',
				[REQUEST_VERSION]: '1.1',
			},
		}),

	deleteComment: (commentId: EntityId) =>
		axios.delete(`/history/comment/${commentId}`, {
			headers: {
				[REQUEST_VERSION]: '1.1',
			},
		}),
}
