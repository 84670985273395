import { DefaultValues } from 'helpers/convertHelper'

import { incomeSourceType } from './incomeSourceType'
import { source } from './source'

export const main = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	return {
		personId: model.string(['personId']),
		incomeSourceType: incomeSourceType(data.incomeSourceType),
		sources: model.array(['sources']).map(source),
	}
}
