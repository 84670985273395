import CALENDAR from 'const/calendar'
import { clone } from 'ramda'

export class CalendarEvent {
	id = ''
	subject = ''

	/**
	 * @description если событие требуется создать у другого пользователя
	 * @type {string | null}
	 */
	userId = null

	body = {
		content: '',
		contentType: 'HTML',
	}
	recurrence = {
		pattern: {
			type: null,
			month: 0,
			interval: 1,
			dayOfMonth: 0,
			index: 'first',
			firstDayOfWeek: 'sunday',
		},
		range: {
			type: 'noEnd',
			startDate: null,
			numberOfOccurrences: 0,
			endDate: '0001-01-01',
			recurrenceTimeZone: clone(CALENDAR.TIME_ZONE),
		},
	}
	end = new Date()
	isAllDay = false
	start = new Date()
	isReminderOn = true
	seriesMasterId = null
	applyToAllInstances = false
	reminderMinutesBeforeStart = 15
	$$customParams = clone(CALENDAR.DEFAULT_CUSTOM_PARAMS)
}
