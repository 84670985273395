import type { EntityId } from '@reduxjs/toolkit'
import api from 'api'
import { actions } from 'reducers/financialData'
import type { TAction } from 'types/redux'

import { utils } from 'helpers'

import { addServerError } from './serverErrors'

export const getFinancialData =
	(loanId: EntityId): TAction =>
	async (dispatch) => {
		const requestParameters = {
			size: 10,
			page: 0,
			filter: {
				loanId,
			},
		}

		try {
			const financialData = await api.financialData.search(requestParameters)
			dispatch(actions.setData(financialData.content[0]))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить финансовую информацию по займу',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}
