import clsx from 'clsx'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import { utils } from 'helpers'

import styles from './ValidationErrors.module.scss'

const ValidationErrors = (props) => {
	const { validationResult, type } = props

	let hasNoDocError = false
	validationResult.subjects.forEach((e) => {
		hasNoDocError =
			!isEmpty(e.errors.other) ||
			(e.$$type === PARTICIPANTS_TYPES.ORGANIZATION && !isEmpty(e.errors.participants)) ||
			type === PARTICIPANTS_TYPES.ORGANIZATION
	})

	return (
		<div className={styles.validationErrors}>
			{validationResult && (
				<>
					{hasNoDocError && (
						<div className={styles.title}>Заполните данные, чтобы выпустить подпись</div>
					)}
					{validationResult.subjects.map((e) => (
						<div key={e.subject.id} className={styles.subject}>
							{type === PARTICIPANTS_TYPES.ORGANIZATION && (
								<div className={styles.subTitle}>{utils.getFullName(e.subject)}:</div>
							)}
							{!isEmpty(e.errors.other) && (
								<div className={styles.line}>Анкета: {e.errors.other.join(', ')}</div>
							)}
							{e.$$type === PARTICIPANTS_TYPES.ORGANIZATION && !isEmpty(e.errors.participants) && (
								<div className={styles.line}>Участники: {e.errors.participants.join(', ')}</div>
							)}
							{!isEmpty(e.errors.documents) && (
								<>
									<div className={clsx(styles.title, hasNoDocError && styles.marginTop)}>
										Для выпуска подписи потребуются документы
									</div>
									<div className={styles.line}>Документы: {e.errors.documents.join(', ')}</div>
								</>
							)}
						</div>
					))}
				</>
			)}
		</div>
	)
}

ValidationErrors.propTypes = {
	validationResult: PropTypes.object,
	type: PropTypes.string,
}

export default ValidationErrors
