import { DefaultValues } from 'helpers/convertHelper'

export const signature = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		objectKey: model.string(['objectKey']),
		signedAt: model.string(['signedAt']),
		signer: {
			subject: {
				id: model.string(['signer', 'subject', 'id']),
				phone: model.string(['signer', 'subject', 'phone']),
				signatory: model.string(['signer', 'subject', 'signatory']),
				type: model.string(['signer', 'subject', 'type']),
			},
			representative: data.signer.representative
				? {
						id: model.string(['signer', 'representative', 'id']),
						phone: model.string(['signer', 'representative', 'phone']),
						signatory: model.string(['signer', 'representative', 'signatory']),
						type: model.string(['signer', 'representative', 'type']),
				  }
				: null,
		},
		context: {
			id: model.string(['context', 'id']),
			type: model.string(['context', 'type']),
			description: model.string(['context', 'description']),
		},
		certificate: {
			id: model.string(['certificate', 'id']),
			activeFrom: model.string(['certificate', 'activeFrom']),
			activeTo: model.string(['certificate', 'activeTo']),
		},
	}
}
