import { PureComponent } from 'react'
import { hasActiveCertificate } from 'actions/document/sign'
import clsx from 'clsx'
import { ENTITY_TYPES, LEAD_ROLES, ORG_ROLES, PARTICIPANT_ROLES, ROLES } from 'const'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import PropTypes from 'prop-types'
import { invertObj, isEmpty } from 'ramda'
import url from 'routes/urls'

import { date as dateUtils } from '@creditclubteam/helpers'
import { Input, ListRow, Select } from 'components/common'
import { utils } from 'helpers'
import { numberOrNull } from 'helpers/convertHelper'

import './Participant.scss'

const ROLES_VALUES = invertObj(PARTICIPANT_ROLES)
const { PERSON } = PARTICIPANTS_TYPES

class Participant extends PureComponent {
	static propTypes = {
		name: PropTypes.string,
		surname: PropTypes.string,
		patronymic: PropTypes.string,
		pathOut: PropTypes.string,
		form: PropTypes.object,
		isEdit: PropTypes.bool,
		roles: PropTypes.array,
		part: PropTypes.any,
		phone: PropTypes.string,
		taxId: PropTypes.string,
		fullName: PropTypes.string,
		getLink: PropTypes.func,
		id: PropTypes.string.isRequired,
		$$type: PropTypes.string,
		type: PropTypes.string,
		startDate: PropTypes.string,
		endDate: PropTypes.string,
		isGosuslugiAuthLinkVisible: PropTypes.bool,
		isGosuslugiWithInstructionAuthLinkVisible: PropTypes.bool,
		entity: PropTypes.object.isRequired,
		konturInfo: PropTypes.object,
		allowChanges: PropTypes.bool,
		isCertificateStatusVisible: PropTypes.bool,
		isApplicantSet: PropTypes.bool,
		isLead: PropTypes.bool,
		isApplication: PropTypes.bool,
		availbablePart: PropTypes.number,
		setAvailablePartForOwners: PropTypes.func,
		sendGosusligiAuthLink: PropTypes.func,
		sendApplicationEsiaAuthLink: PropTypes.func,
		// Только в режиме isEdit
		onChange: PropTypes.func,
		onDelete: PropTypes.func,
		onSuccessEdit: PropTypes.func,
	}

	static defaultProps = {
		isGosuslugiAuthLinkVisible: true,
		isGosuslugiWithInstructionAuthLinkVisible: true,
		isCertificateStatusVisible: true,
	}

	constructor(props) {
		super(props)

		const {
			entity: { name },
			participantsData,
		} = props

		this.cancelableDeletePromise = null
		this.cancelableSavePromise = null

		this.state = {
			isFetching: false,
			changeMode: false,
			isGosuslugiSubmitted: false,
			localPart: this.props.part || '',
			localRoles: utils.matchRoles(this.props.roles),
			isApplicantSet: !!participantsData?.find(({ roles }) => roles.includes('APPLICANT')),

			isFacility: name === ENTITY_TYPES.FACILITY,
			isApplication: name === ENTITY_TYPES.APPLICATION,
			isOrganization: name === ENTITY_TYPES.ORGANIZATION,
			isEntrepreneur: name === ENTITY_TYPES.ENTREPRENEUR,
			isLead: name === ENTITY_TYPES.LEAD,
			isRosreestrRegistration: name === ENTITY_TYPES.ROSREESTR_REGISTRATION,
		}
	}

	componentDidMount() {
		this.nodeElement.addEventListener('keyup', this.handleEnterSubmit)
	}

	componentWillUnmount() {
		this.cancelableDeletePromise?.cancel()
		this.cancelableSavePromise?.cancel()

		this.nodeElement.removeEventListener('keyup', this.handleEnterSubmit)
	}

	// Если добавляемый пользователь уже есть в списке
	// то мы просто доклеиваем ему новые данные
	// так как мы работаем с локальным состоянием который
	// записывется в момент инициализации нам необходимо
	// проверять пропсы на новые данные и обновлять их
	componentDidUpdate(prevProps) {
		const { roles, part } = this.props
		const { isOrganization, isApplication, isLead, isFacility } = this.state

		if ((isLead || isApplication || isOrganization) && prevProps.roles.length !== roles.length) {
			this.setState({ localRoles: utils.matchRoles(roles) })
		}

		if (isFacility && prevProps.part !== part) {
			this.setState({ localPart: part })
		}
	}

	handleActivateChangeMode = () => this.setState({ changeMode: true })

	handleEnterSubmit = ({ key }) => {
		const { changeMode } = this.state

		if (changeMode && key === 'Enter') this.handleSave()
	}

	handleSave = async () => {
		const { localRoles, localPart, isFacility } = this.state
		const { id, $$type, type, onChange, onSuccessEdit, roles, part, entity, startDate, endDate } =
			this.props
		const results = {
			subject: { id },
			type: $$type || type,
			part: numberOrNull(localPart),
			roles: utils.matchRoles(localRoles, true),
			startDate,
			endDate,
		}

		this.setState({ changeMode: false })

		// Если сбросили все роли то возвращаем как было
		if (!isFacility && !utils.hasObjectLength(localRoles)) {
			this.setState({ localRoles: utils.matchRoles(roles) })
			return
		}

		// Если удалили полностью долю то возвращаем обратно
		if (isFacility && !+localPart) {
			this.setState({ localPart: part })
			return
		}

		this.setState({ isFetcing: true })

		this.cancelableSavePromise = utils.makeCancelable(
			onChange({
				target: { id: entity.id, type: entity.name },
				entity: results,
			})
		)

		this.cancelableSavePromise.promise
			.then(() => onSuccessEdit?.())
			.then(() => this.setState({ isFetcing: false }))
			.catch((error) => {
				if (error.isCanceled) return
				this.setState({ isFetcing: false })
			})
	}

	handleToggleSelectRole = (roles) => {
		this.setState({ localRoles: roles.map((role) => role.value) })
	}

	handleChangePart = (value = 1) => {
		const inputValue = Number(value)
		const availablePart = Number(this.props.availablePart)

		// Запрещаем значения 012, 0123. Но значение 0 остается допустимым
		if (inputValue.length > 1 && inputValue[0] == 0) return
		if (inputValue > 100) return
		if (inputValue < 0) return

		const availableOwnerPart = availablePart + this.props.part

		this.setState({
			localPart: inputValue >= availableOwnerPart ? availableOwnerPart : inputValue,
		})
	}

	handleDelete = async () => {
		const { id, $$type, type, onDelete, entity, onSuccessEdit } = this.props

		this.setState({ isFetcing: true })

		try {
			await onDelete({
				target: { id: entity.id, type: entity.name },
				entity: { subject: { id }, type: $$type || type },
			})

			onSuccessEdit?.()
		} catch (error) {
			if (error.isCanceled) return
			this.setState({ isFetcing: false })
		}
	}

	formatLinkUrl = () => {
		const { id, $$type, type, pathOut, entity } = this.props
		const urlProp = `${$$type?.toLowerCase() || type?.toLowerCase()}Out`

		return url[urlProp]?.path(
			(pathOut?.replace(/\//g, '') || entity.name).toLowerCase(),
			entity.id,
			id
		)
	}

	renderRoles() {
		const { localRoles } = this.state
		let rolesToString = localRoles.join(', ')
		rolesToString = utils.cutText(rolesToString, 45)

		return rolesToString.toLowerCase()
	}

	renderCertificateStatus() {
		const { konturInfo, $$type, isCertificateStatusVisible } = this.props

		if ($$type === PARTICIPANTS_TYPES.ENTREPRENEUR || !isCertificateStatusVisible) return null

		const isActive = hasActiveCertificate({ konturInfo })

		return (
			<div
				className={clsx('participants-item__certificate', {
					'participants-item__certificate--active': isActive,
				})}
			>
				{isActive ? 'Подпись выпущена' : 'Подпись не выпущена'}
			</div>
		)
	}

	renderInfo() {
		const { $$type, type, phone, taxId, form, customer = {} } = this.props

		if ($$type === PERSON || type === PERSON) {
			const tel = utils.parsePhone(phone || customer.phone)

			return (
				<div data-test-id='participant-info'>
					<span>{tel}</span>
					{form?.email && (
						<>
							{tel && <span style={{ paddingRight: '7px' }}>,</span>}
							<span>{form.email}</span>
						</>
					)}
					{customer?.email && (
						<>
							{tel && <span style={{ paddingRight: '7px' }}>,</span>}
							<span>{customer.email}</span>
						</>
					)}
				</div>
			)
		} else return <span>{`ИНН: ${taxId || 'Не указан'}`}</span>
	}

	renderInputPart() {
		const { localPart } = this.state

		const inputPartOptions = {
			onlyNum: true,
			value: localPart,
			placeholder: '%',
			onChange: this.handleChangePart,
			type: 'number',
			min: 1,
			max: 100,
		}

		return (
			<div className='participants-item__input-part'>
				<Input {...inputPartOptions} />
			</div>
		)
	}

	renderName() {
		const { name, surname, patronymic, customer } = this.props

		if (customer) {
			return utils.getFullName({
				name: customer.name,
				surname: customer.surname,
				patronymic: customer.patronymic,
			})
		}

		return utils.getFullName({ name, surname, patronymic })
	}

	getRoles() {
		const { isApplicantSet } = this.props

		if (this.state.isRosreestrRegistration)
			return ROLES.filter(({ id }) => id !== 'WARRANTOR' && id !== 'APPLICANT')
		if (this.state.isOrganization) return ORG_ROLES
		if (this.state.isLead) return LEAD_ROLES
		if (this.state.isApplication)
			return isApplicantSet ? ROLES.filter(({ id }) => id !== 'APPLICANT') : ROLES

		return ROLES.filter(({ id }) => id !== 'APPLICANT')
	}

	submitVerification = async () => {
		const {
			isLead,
			isApplication,
			parentId,
			$$type,
			id,
			sendLeadEsiaAuthLink,
			sendApplicationEsiaAuthLink,
		} = this.props

		this.setState({ isFetching: true })

		const payload = {
			id: parentId,
			participantId: `${id}@${$$type}`,
			payload: {
				linkType: 'VERIFICATION',
			},
		}

		try {
			isApplication && (await sendApplicationEsiaAuthLink(payload))
			isLead && (await sendLeadEsiaAuthLink(payload))

			this.setState({ isVerificationSubmitted: true, isFetching: false })
		} catch {
			this.setState({ isVerificationSubmitted: false, isFetching: false })
		}
	}
	submitGosuslugiWithInstruction = async () => {
		const { isLead, isApplication, parentId, $$type, id, sendGosusligiAuthLink } = this.props

		this.setState({ isFetching: true })

		try {
			await sendGosusligiAuthLink({
				id: parentId,
				entityType: $$type,
				entityId: id,
				linkType: 'WITH_INSTRUCTION',
				endpoint: (isLead && 'leads') || (isApplication && 'applications'),
			})

			this.setState({ isGosuslugiWithInstructionSubmitted: true, isFetching: false })
		} catch {
			this.setState({ isGosuslugiWithInstructionSubmitted: false, isFetching: false })
		}
	}

	submitGosuslugi = async () => {
		const { isLead, isApplication, parentId, $$type, id, sendGosusligiAuthLink } = this.props

		this.setState({ isFetching: true })

		try {
			await sendGosusligiAuthLink({
				id: parentId,
				entityType: $$type,
				entityId: id,
				linkType: 'DIRECT',
				endpoint: (isLead && 'leads') || (isApplication && 'applications'),
			})

			this.setState({ isGosuslugiSubmitted: true, isFetching: false })
		} catch {
			this.setState({ isGosuslugiSubmitted: false, isFetching: false })
		}
	}

	render() {
		const { isEdit, roles, id, allowChanges, $$type, startDate, endDate, getLink } = this.props
		const { changeMode, localRoles, localPart, isEntrepreneur, isFacility } = this.state

		const selectProps = {
			options: this.getRoles(),
			onChange: this.handleToggleSelectRole,
			value: localRoles.map((role) => ({ id: ROLES_VALUES[role], value: role, label: role })),
			multi: true,
			className: 'participants-item__select',
		}

		return (
			<ListRow
				id={id}
				to={getLink ?? this.formatLinkUrl()}
				getRef={(el) => (this.nodeElement = el)}
				header={
					<div data-test-id='participant-head' className='participants-item__head'>
						<span>
							{`${this.renderName()}${isEntrepreneur || isEmpty(roles) || isFacility ? '' : ' — '}`}
							{changeMode ? (
								<div data-test-id='participant-select-role'>
									<Select {...selectProps} />
								</div>
							) : (
								!isFacility && this.renderRoles()
							)}
							{startDate &&
								endDate &&
								`, ${dateUtils.format(startDate, { to: 'dd.MM.yyyy' })}-${dateUtils.format(
									endDate,
									{ to: 'dd.MM.yyyy' }
								)}`}
						</span>
						{isEdit && allowChanges && !isEntrepreneur && (
							<div
								data-test-id='participant-change'
								className='participants-item__change'
								onClick={changeMode ? this.handleSave : this.handleActivateChangeMode}
							>
								{changeMode ? 'Сохранить' : 'Изменить'}
							</div>
						)}
					</div>
				}
				actions={[
					{
						isVisible:
							$$type === 'PERSON' && this.props.isGosuslugiAuthLinkVisible && !!this.props.parentId,
						title: this.state.isVerificationSubmitted
							? 'Ссылка отправлена'
							: 'Ссылка на верификацию',
						isDisabled: this.state.isVerificationSubmitted || this.state.isFetching,
						onClick: this.submitVerification,
					},
					{
						isVisible:
							$$type === 'PERSON' && this.props.isGosuslugiAuthLinkVisible && !!this.props.parentId,
						title: this.state.isGosuslugiSubmitted ? 'Ссылка отправлена' : 'Ссылка на Госуслуги',
						isDisabled: this.state.isGosuslugiSubmitted || this.state.isFetching,
						onClick: this.submitGosuslugi,
					},
					{
						isVisible:
							$$type === 'PERSON' && this.props.isGosuslugiAuthLinkVisible && !!this.props.parentId,
						title: this.state.isGosuslugiWithInstructionSubmitted
							? 'Ссылка отправлена'
							: 'Ссылка на Госуслуги c инструкцией',
						isDisabled: this.state.isGosuslugiWithInstructionSubmitted || this.state.isFetching,
						onClick: this.submitGosuslugiWithInstruction,
					},
					{
						isVisible: isEdit,
						color: 'transparent-red',
						title: 'Удалить',
						isDisabled: this.state.isFetching,
						onClick: this.handleDelete,
					},
				]}
			>
				{this.renderInfo()}
				{this.renderCertificateStatus()}
			</ListRow>
		)
	}
}

export default Participant
