import './LoadingBlock.scss'

const LoadingBlock = () => (
	<div className='participant-loading-block'>
		<div className='participant-loading-block__separator' />
		<div className='participant-loading-block__separator' />
		<div className='participant-loading-block__separator' />
	</div>
)

export default LoadingBlock
