import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Delivery } from 'converters/delivery'
import { commonActions } from 'reducers/common'
import type { TUtils } from 'types'

export interface DeliveryFilter {
	status?: TUtils.Maybe<string[]>
	participant?: TUtils.Maybe<string[]>
	externalId?: TUtils.Maybe<string>
}

export const filter: DeliveryFilter = {
	status: null,
	participant: null,
	externalId: null,
}

export const initialState = {
	data: [] as Delivery.Main[],
	fetching: false,
	filter,
}

const list = createSlice({
	name: 'delivery/list',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Delivery.Main[]>) {
			state.data = payload
		},

		setFilter: commonActions.setFilter(),
		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

const { actions } = list

export { actions }
export default list.reducer
