import api from 'api'
import { store } from 'client/store'
import { actions as applicationListActions } from 'reducers/application/list'
import { actions as leadListActions } from 'reducers/lead/list'
import { actions as loanListActions } from 'reducers/loan/list'

const updaters = {
	lead: leadListActions.updateRecord,
	application: applicationListActions.updateRecord,
	loan: loanListActions.updateRecord,
}

/**
 *	fetchBrokerData
 *
 * 	Waterfall for fetching data about broker
 * 	fetch worker -> fetch organization -> fetch broker
 *	Invocation in thunk like ``` fetchBrokerData({ data: data.content, key }) ```
 */
export async function fetchBrokersNames({ data, key }) {
	const recordsForUpdateBroker = {}

	const forFetch = new Set()

	data.forEach(({ id, workers = [], marketingData = {} }) => {
		const isBrokerChannel = marketingData?.channel === 'Брокер'

		if (isBrokerChannel) {
			const brokerId = Array.isArray(workers)
				? workers.find(({ type }) => type === 'BROKER')?.id
				: workers?.BROKER

			if (brokerId) {
				forFetch.add(brokerId)
				recordsForUpdateBroker[id] = {
					workerOfBrokerId: brokerId,
				}
			}
		}
	})

	try {
		const workersResults = await Promise.allSettled(
			Array.from(forFetch.values()).map((id) => api.broker.getEmployees(id))
		)

		if (workersResults.length) {
			const extract = (data) =>
				data.filter(({ status }) => status === 'fulfilled').map(({ value }) => value.data)
			const findById = (v, data) => data.find(({ id }) => v === id)

			const workersOfBrokers = extract(workersResults)

			for (const id in recordsForUpdateBroker) {
				const finded = findById(recordsForUpdateBroker[id].workerOfBrokerId, workersOfBrokers)

				recordsForUpdateBroker[id].organizationOfBrokerId = finded?.organizationId
			}

			const res = await Promise.allSettled(
				workersOfBrokers.map(({ organizationId }) => api.broker.get(organizationId, true))
			)

			if (res) {
				const brokers = extract(res)

				for (const id in recordsForUpdateBroker) {
					const finded = findById(recordsForUpdateBroker[id].organizationOfBrokerId, brokers)

					recordsForUpdateBroker[id].brokerName = finded?.nameForClient
				}

				for (const id in recordsForUpdateBroker) {
					store.dispatch(
						updaters[key]({
							id,
							key: 'brokerNameForClient',
							value: recordsForUpdateBroker[id].brokerName,
						})
					)
				}
			}
		}
	} catch (error) {
		//
	}
}
