import { URLBuilder } from 'api/helpers'
import type { EntityId } from 'api/types/EntityId'
import type { Pageable } from 'api/types/Pageable'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import { REQUEST_VERSION } from 'const/api'
import documents from 'converters/documents'
import { nanoid } from 'nanoid'
import { mergeDeepRight } from 'ramda'

export default {
	get: (id: string) =>
		axios
			.get<ReturnType<typeof documents.files.file>>(`/v3/edm/documents/${id}`)
			.then((response) =>
				Object.assign({}, response, { data: documents.files.file(response.data) })
			),

	search: (options: any) => {
		const params = new URLBuilder()

		const { owners, categories, id, extension } = options.filter || {}

		params
			.addFilter('extension', extension, 'eq')
			.addFilter('owners', owners, 'inList')
			.addFilter('category', categories, 'inList')
			.addFilter('id', Array.isArray(id) ? id : id && [id], 'inList')

		return axios
			.get('/v3/edm/documents', {
				params: {
					sort: 'createdAt,desc',
					size: options.size || 100,
					page: options.page,
					filter: params.build('RSQL'),
				},
				cancelToken: options.cancelToken,
			})
			.then(
				(response) =>
					mergeDeepRight(response, {
						data: {
							content: response.data.content.map(documents.files.file),
						},
					}) as AxiosResponse<Pageable<ReturnType<typeof documents.files.file>>>
			)
	},

	getCategories: (options: any) => {
		const params = new URLBuilder()

		const { ownerTypes, signable, out, id } = options.filter || {}

		params
			.addFilter('ownerTypes', ownerTypes, 'inList')
			.addFilter('signable', signable, 'eq')
			.addFilter('id', out, (value: string) => `=out=${value}`)
			.addFilter('id', Array.isArray(id) ? id : id && [id], 'inList')

		return axios({
			method: 'GET',
			params: {
				sort: 'desc',
				size: 100,
				page: options.page,
				filter: params.build('RSQL'),
			},
			url: `/v3/edm/document-categories`,
		})
	},

	recognizePassport: (id: EntityId) => axios.get(`/v1/documents/${id}/recognition`),

	downloadKonturDocument: (id: EntityId) =>
		axios.get(`v1/signatures/qualified/certificate-forms/${id}/file`, {
			responseType: 'blob',
			params: { type: 'RECEIPT' },
		}),

	replace: (documentId: EntityId, data: unknown) =>
		axios({
			method: 'POST',
			url: `/document/${documentId}/replace`,
			data,
			headers: {
				[REQUEST_VERSION]: '1.1',
			},
		}),

	delete: (ownerId: EntityId, documentId: EntityId) =>
		axios.delete(`document/${documentId}`, {
			data: ownerId,
			headers: {
				[REQUEST_VERSION]: '1.1',
			},
		}),

	rename: (id: EntityId, title: unknown) =>
		axios({
			method: 'POST',
			url: `/document/${id}/rename`,
			data: title,
			headers: {
				'Content-Type': 'text/html',
				[REQUEST_VERSION]: '1.1',
			},
		}),

	getPersonSpec: (personId: string) =>
		axios.get<{ personId: string; signatureProvider: string }>(
			`/v1/signature/qualified/spec/${personId}`
		),

	updatePersonSpec: ({
		personId,
		payload,
	}: {
		personId: string
		payload: { signatureProvider: string }
	}) =>
		axios.patch(`/v1/signature/qualified/spec/${personId}`, payload, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),

	addSigner: (signerInfo: unknown) =>
		axios
			.post<ReturnType<typeof documents.files.file>[]>('v1/documents/signature/signer', signerInfo)
			.then((response) =>
				Object.assign({}, response, { data: response.data.map(documents.files.file) })
			),

	addSimpleSignatureSigner: (signerInfo: unknown) =>
		axios({
			method: 'POST',
			url: `/v1/documents/simple-signature/signer`,
			data: signerInfo,
		}),

	sendSms: (data: Record<string, any>) => axios.post('/v1/documents/simple-signature', data),

	confirmSms: (data: Record<string, any>) =>
		axios.post('/v1/documents/simple-signature/confirm', data),

	deleteSimpleSignatureSigner: (data: Record<string, any>) =>
		axios.delete('/v1/documents/simple-signature/signer', { data }),

	deleteSigner: (signerInfo: unknown) =>
		axios({
			method: 'DELETE',
			url: `v1/documents/signature/signer`,
			data: signerInfo,
		}),

	rotateImage: (id: EntityId, degree: unknown) =>
		axios({
			method: 'POST',
			data: degree,
			url: `document/${id}/rotate`,
			headers: {
				[REQUEST_VERSION]: '1.1',
				'Content-Type': 'application/json',
			},
		}),

	getTemporaryUrls: (filenames: unknown) =>
		axios.post('/document/url', filenames, {
			headers: {
				[REQUEST_VERSION]: '1.1',
			},
		}),

	uploadFile: (url: string, file: any, uploadProgress?: any) =>
		axios.put(url, file, {
			$$noInterceptors: true,
			$$notPutApiVersion: true,
			onUploadProgress: uploadProgress,
			headers: {
				'Content-Type': file.type || 'application/octet-stream',
			},
		}),

	/**
	 * @param {Object[]} files
	 * @param {string} files[].categoryId
	 * @param {string} files[].filename
	 * @param {string} files[].objectKey
	 * @param {string} files[].ownerId
	 * @param {number} files[].size
	 */
	createFile: (file: Record<string, any>) =>
		axios.post('/v3/edm/documents', file).then(
			(response) =>
				Object.assign({}, response, {
					data: response.data.map(documents.files.file),
				}) as AxiosResponse<ReturnType<typeof documents.files.file>>
		),

	updateMetadata: (documentId: EntityId, form: unknown) => {
		return axios.patch(`/v3/edm/documents/${documentId}`, form, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		})
	},

	findLabels: (ownerType: string[]) =>
		axios.get(`/v3/edm/document-category-layouts`, {
			params: {
				size: 100,
				filter: new URLBuilder().addFilter('ownerType', ownerType, 'inList').build('RSQL'),
			},
		}),

	addSignature: ({ documentId, ...data }: { documentId: EntityId } & Record<string, any>) =>
		axios
			.post(`/v1/documents/${documentId}/signature`, {
				...data,
				signer: { ...data.signer, customerId: nanoid() },
			})
			.then((response) =>
				Object.assign({}, response, { data: documents.files.file(response.data) })
			),

	getDownloadLinks: (payload: { objectKeys: string[]; lifeTime: number }) =>
		axios.post<Array<{ uri: string; objectKey: string }>>(
			'/v3/edm/object-storage-download-links',
			payload
		),

	getAgreementSharedUrl: (id: string) =>
		axios.post<{ url: string }>(`/v4/edm/simple-signature/agreements/${id}/share-url`),

	signAgreement: (id: string) =>
		axios
			.post<{ url: string }>(`/v4/edm/simple-signature/agreements/${id}/sign`)
			.then((response) =>
				Object.assign({}, response, { data: documents.files.file(response.data) })
			),
}
