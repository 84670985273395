import api from 'api'
import axios from 'axios'
import { WORKER_TYPES } from 'const/workerTypes'
import { flatten } from 'ramda'
import { actions } from 'reducers/search'

import { utils } from 'helpers'

export const setSearchField = actions.setField
export const resetSearch = actions.resetSearch

export const searchPerson = (data) => () =>
	api.person
		.search({ type: 'TEXT', filter: { query: data.query }, size: data.params.size })
		.then(({ data }) => ({ content: data.content }))
		.catch(() => null)

export const searchBroker = (data) => () =>
	api.broker
		.search({ size: data.params.size, query: data.query, view: data.view })
		.then(({ data }) => ({ content: data.content }))
		.catch(() => null)

export const searchLead = (data) => () =>
	api.lead
		.search({ filter: { query: data.query }, size: data.params.size })
		.then(({ data }) => data)
		.catch(() => null)

export const searchInvestmentlead = (options) => () =>
	api.investmentLead
		.search(options)
		.then(({ data }) => data)
		.catch(() => null)

export const searchDelivery = (options) => () =>
	api.delivery
		.search(options)
		.then(({ data }) => ({ content: data.content }))
		.catch(() => null)

export const searchRosreestrRegistration = (options) => () =>
	api.rosreestrRegistration
		.search(options)
		.then(({ data }) => ({ content: data.content }))
		.catch(() => null)

export const searchDeposit = (options) => () =>
	api.deposit
		.search(options)
		.then(({ data }) => data)
		.catch(() => null)

export const searchOrganization = (data) => () =>
	api.organization
		.search({ type: 'TEXT', filter: { query: data.query }, size: data.params.size })
		.then(({ data }) => ({ content: data.content }))
		.catch(() => null)

export const searchBrokerEmployee = (data) => () =>
	api.broker
		.searchBrokerEmployees({ query: data.query })
		.then(({ data }) => ({ content: data }))
		.then(async (response) => {
			if (!response.content.length) {
				return response
			}

			const organizationMap = response.content.reduce((acc, el) => {
				return {
					...acc,
					[el.organizationId]: acc[el.organizationId] ? [...acc[el.organizationId], el] : [el],
				}
			}, {})

			try {
				const {
					data: { content: organizations },
				} = await api.broker.search({ filter: { ids: Object.keys(organizationMap) } })

				organizations.forEach(({ id, shortName }) => {
					organizationMap[id] = Object.values(organizationMap[id]).map((el) => {
						return {
							...el,
							organizationShortName: shortName,
							$$type: WORKER_TYPES.EMPLOYEE,
						}
					})
				})
			} catch {
				//
			}

			return { content: [...Object.values(organizationMap)] }
		})
		.catch(() => null)

export const searchEntrepreneur = (data) => () =>
	api.entrepreneur
		.search({ type: 'TEXT', filter: { query: data.query }, size: data.params.size })
		.then(({ data }) => ({ content: data.content }))
		.catch(() => null)

export const searchFacility = (data) => async () => {
	const options = {
		size: data.params.size,
		filter: {
			address: !utils.isCadaster(data.query) ? data.query : null,
			cadastralId: utils.isCadaster(data.query) ? data.query : null,
		},
	}

	return api.facility
		.search(options)
		.then(({ data }) => ({ content: data.content }))
		.catch(() => null)
}

export const searchRepresentative = (data) => () =>
	api.representative
		.search({ type: 'TEXT', filter: { query: data.query }, size: data.params.size })
		.catch(() => null)

const searchMethods = {
	searchLead,
	searchDeposit,
	searchInvestmentlead,
	searchPerson,
	searchOrganization,
	searchEntrepreneur,
	searchBroker,
	searchRepresentative,
}

/**
 * @param options: object {
 *   query: string,
 *   type: string, (Используется если searchBy = party (INDIVIDUAL || LEGAL))
 *   methodType: string, (Используется для методов поиска по своей базе)
 *   searchIn: object {
 *     internal: boolean,
 *     external: boolean,
 *   },
 *   params?: {
 *     size: int,
 *   }
 * }
 * @param searchBy: string (fio | party | bank)
 */
export const searchEveryplace = (options, searchBy = null) => async (dispatch) => {
	const searchInternal = options.searchIn?.internal || false
	const searchExternal = options.searchIn?.external || false

	// Поиск по своей базе
	if (searchInternal) {
		/** @namespace options.methodType */
		const methods = options.methodType
			.split(',')
			.map((value) => searchMethods[`${formattedTypeToMethod(value)}`])

		const internal = await axios.all(methods.map((method) => dispatch(method(options))))
		const flatted = flatten(internal.map((value) => (value ? value.content : null)).filter(Boolean))
		if (flatted.length) dispatch(setSearchResults('internal', flatted))
	}

	// Поиск по дадате
	if (searchExternal) {
		const externalApiParams = {
			query: options.query,
			count: options.params.size,
		}

		if (options.type) externalApiParams.type = options.type

		const request =
			searchBy === 'address'
				? () => api.dadata.suggestAddress(externalApiParams).catch(() => null)
				: () => api.dadata.getSuggestions(externalApiParams, searchBy).catch(() => null)

		const external = await request()

		if (external?.data?.suggestions) {
			dispatch(setSearchResults('external', external.data.suggestions))
		}
	}
}

export const setSearchResults =
	(category = 'internal', data) =>
	(dispatch) => {
		dispatch(
			actions.setField({
				field: category,
				value: data.map((item) => ({
					...item,
					$$category: category,
				})),
			})
		)
	}

// helpers
const formattedTypeToMethod = (type) => {
	const formattedType = type[0].toUpperCase() + type.slice(1).toLowerCase()

	return `search${formattedType}`
}
