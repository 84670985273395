import { utils } from 'helpers'
import { defaultToStr, DefaultValues } from 'helpers/convertHelper'

import { account } from './account'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		username: model.string(['username']),
		name: model.string(['name']),
		surname: model.string(['surname']),
		patronymic: model.string(['patronymic']),
		email: model.string(['email']),
		phone: model.string(['phone']),
		avatar: model.string(['avatar']),
		fullName: defaultToStr(utils.getFullName(data)),
		fullNameShort: defaultToStr(utils.getFullName(data, true)),
		enabled: model.null<boolean>(['enabled']),
		roles: model.array<string[]>(['roles']),
		accounts: model.array(['accounts']).map(account),
	}
}
