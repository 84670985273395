import { Component } from 'react'
import { connect } from 'react-redux'
import { createCalendarEvent, updateCalendarEvent } from 'actions/calendar'
import cx from 'clsx'
import CALENDAR from 'const/calendar'
import PropTypes from 'prop-types'

import { InputDateTimePicker } from '@creditclubteam/react-components'
import { Button, Input, Radio, Select } from 'components/common'
import { utils } from 'helpers'

import BodyContent from './BodyContent'
import Entities from './Entities'
import SeriesAndRepeats from './SeriesAndRepeats'

import './Form.scss'

class Form extends Component {
	static propTypes = {
		form: PropTypes.object.isRequired,
		fetching: PropTypes.bool.isRequired,

		updateCalendarEvent: PropTypes.func.isRequired,
		createCalendarEvent: PropTypes.func.isRequired,
	}

	state = this.props.form

	componentDidMount() {
		const form = this.state

		// Если поле end (дата окончания) при монтаже пустое
		// заполняем его сами на пол часа вперёд
		if (!form.end) {
			this.fixEndDate(form.start)
		}

		if (form.isAllDay) {
			const newEndDate = new Date(form.end)
			newEndDate.setDate(newEndDate.getDate() - 1)

			this.setState({ end: newEndDate })
		}

		if (this.nodeElement) {
			this.nodeElement.addEventListener('keyup', this.onKeyUp)
		}
	}

	onKeyUp = (event) => {
		const isQlEditorFocus = event.target.className === 'ql-editor'

		if (event.key === 'Enter' && !isQlEditorFocus && !this.recurrenceIsInvalidRangeDates()) {
			this.handleSubmit()
		}
	}

	recurrenceIsInvalidRangeDates = () => {
		const { end, start, recurrence } = this.state
		const recurrenceType = utils.take(recurrence, 'pattern.type')

		return (
			Boolean(recurrenceType) &&
			((recurrenceType === 'daily' && utils.getDifferenceBetweenDates(end, start) > 1) ||
				(recurrenceType === 'weekly' && utils.getDifferenceBetweenDates(end, start) > 7) ||
				(recurrenceType === 'absoluteMonthly' &&
					utils.getDifferenceBetweenDates(end, start) > 10) ||
				(recurrenceType === 'absoluteYearly' && utils.getDifferenceBetweenDates(end, start) > 365))
		)
	}

	handleSubmit = () => {
		const { updateCalendarEvent, createCalendarEvent } = this.props

		if (this.state.id) {
			updateCalendarEvent(this.state)
		} else {
			createCalendarEvent(this.state)
		}
	}

	setField = (field, value) =>
		this.setState({
			...this.state,
			[field]: value,
		})

	setCustomParams = (field, value) =>
		this.setState({
			...this.state,
			$$customParams: {
				...this.state.$$customParams,
				[field]: value,
			},
		})

	setRecurrencePattern = (field, value) =>
		this.setState({
			...this.state,
			recurrence: {
				...this.state.recurrence,
				pattern: {
					...this.state.recurrence.pattern,
					[field]: value,
				},
			},
		})

	setRecurrenceRange = (field, value) =>
		this.setState({
			...this.state,
			recurrence: {
				...this.state.recurrence,
				range: {
					...this.state.recurrence.range,
					[field]: value,
				},
			},
		})

	setBodyContent = (value) =>
		this.setState({
			...this.state,
			body: {
				...this.state.body,
				content: value,
			},
		})

	fixEndDate = (start) => {
		const end = new Date(start)
		end.setMinutes(start.getMinutes() + 30)
		setTimeout(() => this.setField('end', end), 0)
	}

	changeDate = (field, value) => {
		const { end } = this.state
		this.setField(field, value)

		// Если указана дата начала которая превышает текущую дату завершения
		if (field === 'start' && value > end) {
			this.fixEndDate(value)
		}
	}

	render() {
		const { fetching } = this.props
		const { id, subject, $$customParams, start, end, isAllDay } = this.state

		const inputSubjectOptions = {
			value: subject,
			autoFocus: true,
			disabled: fetching,
			placeholder: 'Название события',
			onChange: (value) => this.setField('subject', value),
		}

		const selectEventTypeOptions = {
			disabled: fetching,
			defaultTitle: 'Тип события',
			options: CALENDAR.EVENT_TYPES,
			value: $$customParams.eventType,
			className: 'calendar-event-form__event-type',
			onChange: ({ id }) => this.setCustomParams('eventType', id),
		}

		const startDateOptions = {
			value: start,
			disabled: fetching,
			onlyDate: isAllDay,
			className: 'calendar-event-form__event-date',
			onChange: (date) => this.changeDate('start', date),
		}

		const endDateOptions = {
			value: end,
			minDate: start,
			disabled: fetching,
			onlyDate: isAllDay,
			className: 'calendar-event-form__event-date',
			onChange: (date) => this.changeDate('end', date),
		}

		const allDayRadioOptions = {
			value: isAllDay,
			disabled: fetching,
			label: 'Весь день',
			className: 'calendar-event-form__allday-radio',
			onChange: (value) => this.setField('isAllDay', value),
		}

		const seriesAndRepeatsOptions = {
			fetching,
			form: this.state,
			setField: this.setField,
			setRecurrenceRange: this.setRecurrenceRange,
			setRecurrencePattern: this.setRecurrencePattern,
		}

		const entitiesOptions = {
			form: this.state,
			setCustomParams: this.setCustomParams,
		}

		const bodyContentOptions = {
			fetching,
			form: this.state,
			setBodyContent: this.setBodyContent,
		}

		const buttonSubmitOptions = {
			loading: fetching,
			onClick: this.handleSubmit,
			title: id ? 'Сохранить' : 'Создать',
			disabled: this.recurrenceIsInvalidRangeDates(),
			className: cx('calendar-event-form__controls-button', {
				[$$customParams.eventType]: !!$$customParams.eventType,
			}),
		}

		return (
			<>
				<div className='calendar-event-form' ref={(el) => (this.nodeElement = el)}>
					<div className='calendar-event-form__group'>
						<Input {...inputSubjectOptions} />
						<Select {...selectEventTypeOptions} />
					</div>
					<div className='calendar-event-form__group'>
						<InputDateTimePicker {...startDateOptions} />
						<div className='calendar-event-form__date-range'> —</div>
						<InputDateTimePicker {...endDateOptions} />
						<Radio {...allDayRadioOptions} />
					</div>
					<SeriesAndRepeats {...seriesAndRepeatsOptions} />
					{utils.hasObjectLength(this.state.$$customParams.entity) && (
						<Entities {...entitiesOptions} />
					)}
					<BodyContent {...bodyContentOptions} />
				</div>
				<div className='calendar-event-form__controls'>
					<Button {...buttonSubmitOptions} />
					{this.recurrenceIsInvalidRangeDates() && (
						<div className='calendar-event-form__err'>
							События серии не должны пересекаться друг с другом
						</div>
					)}
				</div>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	...state.calendar.eventForm,
})

const mapDispatchToProps = {
	updateCalendarEvent,
	createCalendarEvent,
}

export default connect(mapStateToProps, mapDispatchToProps)(Form)
