import { DefaultValues } from 'helpers/convertHelper'

import { paymentAmountDetail } from './paymentAmountDetail'
import { paymentInterestRate } from './paymentInterestRate'
import { paymentInterestRateDetail } from './paymentInterestRateDetail'

export const payment = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		from: model.null<number>(['from']),
		to: model.null<number>(['to']),
		paymentAmount: model.null<number>(['paymentAmount']),
		paymentInterestRate: paymentInterestRate(data.paymentInterestRate),
		paymentAmountDetails: model.array(['paymentAmountDetails']).map(paymentAmountDetail),
		paymentInterestRateDetails: model
			.array(['paymentInterestRateDetails'])
			.map(paymentInterestRateDetail),
	}
}
