import { combineReducers } from 'redux'

import list from './list'
import single from './single'
import workers from './workers'

export default combineReducers({
	list,
	single,
	workers,
})
