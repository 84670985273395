import { DefaultValues } from 'helpers/convertHelper'

import { program } from './program'

export const product = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		title: model.string(['title']),
		programs: model.array(['programs']).map(program),
	}
}
