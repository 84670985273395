import moment from 'moment'

import { fileUtils } from '@creditclubteam/helpers'

export const formatForm = (title: string, metadata: Record<string, unknown>) => {
	const _title = fileUtils.removeFileExtension(title)

	const _metadata = Object.entries(metadata).reduce((acc, [key, value]) => {
		if (moment.isDate(value)) {
			acc[key] = moment(value).format('YYYY-MM-DD')
		} else {
			acc[key] = value
		}

		return acc
	}, {} as Record<string, unknown>)

	return {
		title: _title,
		metadata: _metadata,
	}
}

export const getCategoryTypes = (categoryType: string) => {
	return categoryType.split(',')
}

export const isManyCategoryTypes = (categoryType: string) => {
	if (!categoryType) return false

	const categories = getCategoryTypes(categoryType)

	return categories.length > 1
}
