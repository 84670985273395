import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { EntityId } from 'api/types/EntityId'
import type { MoneyMovements } from 'converters/moneyMovements'
import type { TUtils } from 'types'

export interface MoneyMovementsFilter {
	loanId: TUtils.Maybe<EntityId>
}

const filter: MoneyMovementsFilter = {
	loanId: null,
}

interface State {
	data: MoneyMovements.Main | null
	fetching: boolean
	filter: MoneyMovementsFilter
}

const initialState: State = {
	data: null,
	fetching: false,
	filter,
}

const financialData = createSlice({
	name: 'moneyMovements',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<MoneyMovements.Main>) {
			state.data = payload
		},
	},
})

export const { actions } = financialData
export default financialData.reducer
