import ReactTooltip from 'react-tooltip'

import { Checkbox, FormLayout } from 'components/common'

import styles from './Section.module.scss'

export type SectionItem = {
	id: string
	label: string
	subText?: string
	checked: boolean
	onChange?: (value: boolean) => void
	disabled?: boolean
	hint?: string
	hintTooltipText?: string
}

type Props = {
	title: string
	items: SectionItem[]
}

const Section = ({ title, items }: Props) => {
	if (!items.length) return null

	return (
		<FormLayout.Section className={styles.wrap}>
			<FormLayout.Title>{title}</FormLayout.Title>
			{items.map(({ id, label, subText, onChange, disabled, hint, hintTooltipText, checked }) => (
				<FormLayout.Row key={id} className={styles.row}>
					<ReactTooltip id={id} effect='solid' className='tooltip' disable={!hintTooltipText} />
					<Checkbox
						disabled={disabled}
						customLabel={
							<div className={subText && styles.label}>
								<span>{label}</span>
								<span>{subText}</span>
							</div>
						}
						checked={checked}
						onChange={onChange}
					/>
					{hint && (
						<div className={styles.hint} data-for={id} data-tip={hintTooltipText}>
							{hint}
						</div>
					)}
				</FormLayout.Row>
			))}
		</FormLayout.Section>
	)
}

export default Section
