import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { breadcrumbsGoTo } from 'actions/breadcrumbs'

import styles from './Breadcrumbs.module.scss'

class Breadcrumbs extends Component {
	renderCrumbs = () => {
		const { data, breadcrumbsGoTo } = this.props

		const items = []

		data.forEach((crumb, index) => {
			let title = crumb.title || ''

			if (index === data.length - 1) return null
			if (title.length > 20) title = title.slice(0, 20) + '...'

			const itemProps = {
				key: index,
				to: crumb.path,
				className: styles.item,
				onClick: () => breadcrumbsGoTo(crumb.id === 1 ? 2 : crumb.id),
			}

			items.push(<Link {...itemProps}>{title}</Link>, ' / ')
		})

		return items
	}

	render() {
		return <div className={styles.wrap}>{this.renderCrumbs()}</div>
	}
}

const mapStateToProps = (state) => ({
	...state.breadcrumbs,
})

const mapDispatchToProps = {
	breadcrumbsGoTo,
}

export default connect(mapStateToProps, mapDispatchToProps)(Breadcrumbs)
