import { DefaultValues } from 'helpers/convertHelper'

export const loans = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		mainDebtAmount: model.null<number>(['mainDebtAmount']),
	}
}
