import { DefaultValues } from 'helpers/convertHelper'

export const enforcementProceedingObligation = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		date: model.string(['date']),
		amount: model.null<number>(['amount']),
		fulfilled: model.null<boolean>(['fulfilled']),
		included: model.null<boolean>(['included']),
	}
}
