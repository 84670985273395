import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { EntityId } from 'api/types/EntityId'
import type { FinancialData } from 'converters/financialData'
import { commonActions } from 'reducers/common'
import type { TUtils } from 'types'

export interface FinancialDataFilter {
	loanId: TUtils.Maybe<EntityId>
}

const filter: FinancialDataFilter = {
	loanId: null,
}

interface State {
	data: FinancialData.Main | null
	fetching: boolean
	filter: FinancialDataFilter
}

const initialState: State = {
	data: null,
	fetching: false,
	filter,
}

const financialData = createSlice({
	name: 'financialData',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<FinancialData.Main>) {
			state.data = payload
		},
		setFilter: commonActions.setFilter(),
	},
})

export const { actions } = financialData
export default financialData.reducer
