import { DefaultValues } from 'helpers/convertHelper'

export const share = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		participantId: model.string(['participantId']),
		participantType: model.string(['participantType']),
		amount: model.null<number>(['amount']),
		mkkGroupInvestor: model.null<boolean>(['mkkGroupInvestor']),
	}
}
