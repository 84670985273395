import api from 'api'
import { actions } from 'reducers/person/incomes'
import type { TAction } from 'types/redux'
import type { FirstParameter } from 'types/util'

export const getPersonIncomeGroups =
	(id: string): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			dispatch(actions.setStatus('pending'))

			const { data } = await api.scoring.getPersonIncomeSourceGroups(id)

			dispatch(actions.setAll(data))
			dispatch(actions.setStatus('fulfilled'))
		} catch {
			dispatch(actions.setStatus('rejected'))
		}
	}

export const createPersonIncomeSource =
	(
		parameters: FirstParameter<typeof api.scoring.createPersonIncomeSource>
	): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			dispatch(actions.setStatus('pending'))

			await api.scoring.createPersonIncomeSource(parameters)
			const { data } = await api.scoring.getPersonIncomeSourceGroups(parameters.personId)

			dispatch(actions.setAll(data))
		} catch {
			dispatch(actions.setStatus('rejected'))

			throw new Error()
		}
	}

export const updatePersonIncomeSource =
	(
		parameters: FirstParameter<typeof api.scoring.updatePersonIncomeSource>
	): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			dispatch(actions.setStatus('pending'))

			await api.scoring.updatePersonIncomeSource(parameters)
			const { data } = await api.scoring.getPersonIncomeSourceGroups(parameters.personId)

			dispatch(actions.setAll(data))
		} catch {
			dispatch(actions.setStatus('rejected'))

			throw new Error()
		}
	}

export const deletePersonIncomeSource =
	(
		parameters: FirstParameter<typeof api.scoring.deletePersonIncomeSource>
	): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			dispatch(actions.setStatus('pending'))

			await api.scoring.deletePersonIncomeSource(parameters)
			const { data } = await api.scoring.getPersonIncomeSourceGroups(parameters.personId)

			dispatch(actions.setAll(data))
		} catch {
			dispatch(actions.setStatus('rejected'))

			throw new Error()
		}
	}
