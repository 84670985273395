import type { PropsWithChildren } from 'react'
import { useState } from 'react'
import clsx from 'clsx'

import { join } from '@creditclubteam/kit/helpers'
import { Button } from '@creditclubteam/react-components'
import ModalDialog from 'components/common/ModalDialog'
import { utils } from 'helpers'

import styles from './CommentHints.module.scss'

const commonOpts = [
	'Хочет ставку ниже',
	'Не подходит платёж',
	'Не устроил процесс',
	'Не проходит по кредитной истории',
	'Не проходит по ФССП',
	'Не проходит по возрасту',
	'Получил деньги у другого кредитора',
	'Неактуально',
	'Сбрасывает',
	'Недозвон',
	'Не проходит по военному билету',
	'Не может подтвердить доход',
	'Есть подтверждение дохода',
	'Нет дохода из выписки ИЛС',
	'Банкрот',
	'Нет «Госуслуг»',
	'Отказался рефинансироваться из-за ставки',
]

const HASHTAGS = new Map(
	Object.entries({
		Объект: [
			'Квартира',
			'Комната',
			'Земельный участок',
			'Авто',
			'Без залога',
			'Коммерческая',
			'Загородная',
			'Доля',
		],
		'Дополнительно по объекту': [
			'Отличный объект',
			'Не подходит город',
			'Не проходит по требованиям',
		],
		'Передан партнёру': [
			'Святошенко',
			'Кредео',
			'Альфаплюс',
			'Ваш инвестор',
			'Красный Джин',
			'МосИнвестФинанс',
		],
		'Текущая работа': [
			'Пишу в WA, возвращаю на кол-центр',
			'Не наше ГЕО — пишу в WA для передачи',
			'Не подходящий объект — пишу в WA для передачи',
			'Нет согласий',
			'Запросил информацию о собственниках',
			'Есть обременение на объекте',
			'Объект приобретался в браке',
		],
		'Информация по заёмщику': commonOpts,
		'Информация по залогодателю': commonOpts,
	})
)

interface LabelProps {
	hint?: string
	onSelectHint?: (value: string) => void
	value?: string
	category?: string
	subCategory?: string
}

const Label = ({
	subCategory,
	value,
	hint,
	category,
	onSelectHint,
	children,
}: PropsWithChildren<LabelProps>) => {
	const formattedHint = hint?.replace(/[\s]/g, '_').replace(/[,.]/g, '')
	const fullHint = `#${utils.join([category, subCategory, formattedHint], '/')}\n`

	const handleSelect = () => {
		onSelectHint?.(
			value?.includes(fullHint!)
				? value.replaceAll(fullHint, '')
				: utils.join([value, fullHint], '')
		)
	}

	if (!hint && subCategory) {
		return <div className={clsx(styles.label, styles.secondary)}>{subCategory}</div>
	}

	if (!hint && !subCategory) {
		return <div className={clsx(styles.label, styles.bold)}>{children}</div>
	}

	return (
		<button
			className={clsx(styles.label, styles.hint, {
				[styles.indent]: !!subCategory,
				[styles.active]: value?.includes(`${category}/${formattedHint}`),
			})}
			onClick={handleSelect}
		>
			{hint}
		</button>
	)
}

export interface CommentHintsProps {
	isOpen: boolean
	text: string
	onAddHints: (value: string) => void
	onClose: () => void
}

export const CommentHints = ({ isOpen, onAddHints, text, onClose }: CommentHintsProps) => {
	const [value, setValue] = useState('')

	const modalProps = {
		large: true,
		opened: isOpen,
		onAfterClose: () => {
			setValue('')
		},
		title: 'Подсказки к комментариям',
		subTitle: 'Выберите подсказки через клик и подтвердите выбор, чтобы вставить их в комментарий',
		onClose,
	}

	const handleAddHints = () => {
		onAddHints(
			join(
				[
					text,
					text && '\n',
					Object.entries(
						value.match(/#.+\n/g)?.reduce((acc, curr) => {
							const [category, ...rest] = curr.split('/')

							if (acc[category]) {
								acc[category].push(...rest)
							} else {
								acc[category] = [...rest]
							}

							return acc
						}, {} as Record<string, string[]>) ?? {}
					).reduce((acc, [category, hashtags]) => {
						acc = join(
							[
								acc,
								acc && '\n',
								category.replace('#', ''),
								join(
									hashtags.map((v) => `#${v}`.replace('\n', '')),
									' '
								),
							],
							'\n'
						)
						return acc
					}, ''),
				],
				'\n'
			)
		)
		onClose()
	}

	const commonHintProps: (v: string) => Pick<LabelProps, 'onSelectHint' | 'value'> = (
		category
	) => ({
		onSelectHint: setValue,
		value,
		category,
	})

	return (
		<ModalDialog {...modalProps}>
			<div className={styles.border} />
			<div className={styles.content}>
				<div className={styles.col}>
					<div className={styles.block}>
						<Label>Объект</Label>
						{HASHTAGS.get('Объект')?.map((v) => (
							<Label key={v} hint={v} {...commonHintProps('Объект')} />
						))}
					</div>
					<div className={styles.block}>
						<Label>Дополнительно по объекту</Label>
						{HASHTAGS.get('Дополнительно по объекту')?.map((v) => (
							<Label key={v} hint={v} {...commonHintProps('Дополнительно по объекту')} />
						))}
					</div>
					<div className={styles.block}>
						<Label>Передан партнёру</Label>
						{HASHTAGS.get('Передан партнёру')?.map((v) => (
							<Label key={v} hint={v} {...commonHintProps('Передан партнёру')} />
						))}
					</div>
					<div className={styles.block}>
						<Label>Текущая работа</Label>
						{HASHTAGS.get('Текущая работа')?.map((v) => (
							<Label key={v} hint={v} {...commonHintProps('Текущая работа')} />
						))}
					</div>
				</div>
				<div className={styles.col}>
					<div className={styles.block}>
						<Label>Информация по заёмщику</Label>
						{HASHTAGS.get('Информация по заёмщику')?.map((v) => (
							<Label key={v} hint={v} {...commonHintProps('Информация по заёмщику')} />
						))}
					</div>
					<div className={styles.block}>
						<Label>Информация по залогодателю</Label>
						{HASHTAGS.get('Информация по залогодателю')?.map((v) => (
							<Label key={v} hint={v} {...commonHintProps('Информация по залогодателю')} />
						))}
					</div>
				</div>
			</div>
			<div className={styles.border} />
			<div className={styles.controls}>
				<Button mode='min' title='Добавить' onClick={handleAddHints} buttonType='button' />
				<Button mode='min' theme='text-red' title='Отмена' onClick={onClose} buttonType='button' />
			</div>
		</ModalDialog>
	)
}
