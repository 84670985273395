import { DefaultValues } from 'helpers/convertHelper'

export const passport = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		birthDate: model.string(['birthDate']),
		series: model.string(['series']),
		number: model.string(['number']),
		divisionCode: model.string(['divisionCode']),
		department: model.string(['department']),
		birthplace: model.string(['birthplace']),
		issueDate: model.string(['issueDate']),
		documentType: model.string(['documentType']),
		marriageYear: model.null<number>(['marriageYear']),
		married: model.null<boolean>(['married']),
		registration: model.null<Record<string, any>>(['registration']),
		registrationMissing: model.string(['registrationMissing']),
		registrationDate: model.string(['registrationDate']),
		ownershipStatus: model.string(['ownershipStatus']),
	}
}
