import * as actionTypes from 'const/actionTypes'
import Immutable from 'immutable'

import { immutableize } from 'helpers'

const initialState = Immutable.fromJS({
	data: [],
	fetching: false,
})

const files = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.DOCUMENTS_FILES_SET_DATA:
			return state.set('data', action.data)

		case actionTypes.DOCUMENTS_FILES_SET_FETCHING_STATUS:
			return state.set('fetching', action.status)

		case actionTypes.DOCUMENTS_FILES_ADD_FILES:
			return state.update('data', (data) => [...data, ...action.data])

		case actionTypes.DOCUMENTS_FILES_SET_SIGNATURE: {
			const itemIndex = state.get('data').findIndex((item) => item.get('id') === action.id)

			return state.setIn(['data', itemIndex, 'signatures'], action.data)
		}

		case actionTypes.DOCUMENTS_FILES_SET_SIMPLE_SIGNATURE: {
			const itemIndex = state.get('data').findIndex((item) => item.get('id') === action.id)

			return state.setIn(['data', itemIndex, 'simpleSignatures'], action.data)
		}

		case actionTypes.DOCUMENTS_FILES_SET_FILE_URL: {
			const itemIndex = state.get('data').findIndex((item) => item.get('id') === action.id)

			return state.setIn(['data', itemIndex, 'url'], action.newUrl)
		}

		case actionTypes.DOCUMENTS_FILES_REPLACE_FILE: {
			const fileIndex = state.get('data').findIndex((file) => file.get('id') === action.documentId)

			return state.setIn(['data', fileIndex], action.data)
		}

		case actionTypes.DOCUMENTS_FILES_DELETE_FILE: {
			const fileIndex = state.get('data').findIndex((file) => file.get('id') === action.id)

			if (fileIndex < 0) return state

			return state.deleteIn(['data', fileIndex])
		}

		case actionTypes.DOCUMENTS_FILES_RESET:
			return initialState

		default:
			return state
	}
}

export default immutableize(files)
