interface FormatParameters {
	value: string | number | null | undefined
	allowDecimals?: boolean
}

interface UnformatParameters {
	value: string
}

const formatLocale = (value: string) => Number(value).toLocaleString('ru-RU')

export const inputFormatting = {
	format: <T extends FormatParameters>({ value, allowDecimals = true }: T) => {
		if (value == null || value === '') return ''

		const _v = (typeof value === 'number' ? value.toString() : value).replace(/[a-zа-я]/gi, '')

		return _v
			? _v.includes('.') && allowDecimals
				? `${formatLocale(_v.split('.')[0])},${_v.split('.')[1]}`
				: formatLocale(_v.replace(/[,.].*/, ''))
			: ''
	},

	unformat: (options: UnformatParameters) => {
		const { value } = options

		const formatted = value.replace(/\s/g, '').replace(/[,.]/g, '.')

		if (formatted === '.') {
			return ''
		}

		const containsTwoDots = (formatted.match(/[.]/g) || [])?.length > 1
		const validNumber = !formatted.match(/[^0-9.]/g)
		const [integer, decimals] = formatted.split('.')

		if (containsTwoDots && formatted.endsWith('.')) {
			return formatted.slice(0, -1)
		} else if (!validNumber) return formatted.replace(/[^0-9,.\s]/gi, '')

		return decimals ? `${integer}.${decimals}` : formatted
	},
}
