import { getApplicationSingle } from 'actions/application'
import { getLeadSingle } from 'actions/lead'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { decisionReducerActions } from 'reducers/decision'
import type { TAction } from 'types/redux'
import type { FirstParameter } from 'types/util'

import { utils } from 'helpers'

export const get =
	(params: FirstParameter<typeof api.decision.get>): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			dispatch(decisionReducerActions.setStatus('pending'))

			const { data } = await api.decision.get(params)

			dispatch(decisionReducerActions.setData(data))
			dispatch(decisionReducerActions.setStatus('fulfilled'))
		} catch (error: unknown) {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить решение',
					details: utils.getDetailsFromError(error),
				})
			)
			dispatch(decisionReducerActions.setStatus('rejected'))
		}
	}

export const sync =
	(params: FirstParameter<typeof api.decision.sync>): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			await api.decision.sync(params)
			const { data } = await api.decision.get(params)

			dispatch(decisionReducerActions.setData(data))

			if (params.entity === 'applications') dispatch(getApplicationSingle(params.entityId))
			if (params.entity === 'leads') dispatch(getLeadSingle(params.entityId))
		} catch (error: unknown) {
			dispatch(
				addServerError({
					text: 'Не удалось синхронизировать решение',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const publish =
	(params: FirstParameter<typeof api.decision.publish>): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			await api.decision.publish(params)
			const { data } = await api.decision.get(params)
			dispatch(decisionReducerActions.setData(data))
		} catch (error: unknown) {
			dispatch(
				addServerError({
					text: 'Не удалось опубликовать решение',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}
