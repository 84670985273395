import { createContext, useContext } from 'react'

export type KcStatuses = 'pending' | 'success' | 'error'

export const kcCtx = createContext<{ kcStatus: KcStatuses; authenticated: boolean }>({
	kcStatus: 'pending',
	authenticated: false,
})

export const useKcCtx = () => useContext(kcCtx)
