import { DefaultValues } from 'helpers/convertHelper'

import { ownerObligations } from './ownerObligations'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		ownersObligations: model.array(['ownersObligations']).map(ownerObligations),
	}
}
