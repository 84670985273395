import { DefaultValues } from 'helpers/convertHelper'

export const mainContact = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		name: model.string(['name']),
		surname: model.string(['surname']),
		patronymic: model.string(['patronymic']),
		phone: model.string(['phone']),
		email: model.string(['email']),
	}
}
