import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { commonActions } from 'reducers/common'

import { utils } from 'helpers'

interface Pagination {
	paged: boolean
	totalPages: number
	currentPage: number
	totalElements: number
}

interface State {
	files: Array<Record<string, any>>
	queue: Array<Record<string, any>>
	fetching: boolean
	pagination: Pagination

	[key: string]: any
}

const initialState: State = {
	files: [],
	queue: [],
	fetching: false,
	pagination: {
		paged: false,
		totalPages: 0,
		currentPage: 0,
		totalElements: 0,
	},
}

const bucket = createSlice({
	name: 'fileInspector/bucket',
	initialState,
	reducers: {
		setFetchingStatus(state, { payload }: PayloadAction<boolean>) {
			state.fetching = payload
		},

		setData(state, { payload }: PayloadAction<Array<Record<string, any>>>) {
			state.files = utils.sortByKey(payload, 'createdAt') as Record<string, any>[]
		},

		deleteFile(state, { payload }: PayloadAction<string>) {
			state.files = state.files.filter(({ id }) => id !== payload)
		},

		carryFromQueue(state, { payload }: PayloadAction<Record<string, unknown>>) {
			const fileIndex = state.queue.findIndex((el: any) => el.id === payload.id)

			state.queue = state.queue.filter((_, i) => i !== fileIndex)
			state.files.push(payload)
		},

		setFileAfterPost(
			state,
			{ payload: { fileId, newTitle } }: PayloadAction<{ fileId: string; newTitle: string }>
		) {
			const fileIndex = state.files.findIndex((el: any) => el.id === fileId)

			state.files[fileIndex].processing = false
			state.files[fileIndex].title = newTitle
		},

		setUrl(
			state,
			{ payload: { fileId, newUrl } }: PayloadAction<{ fileId: string; newUrl: string }>
		) {
			const fileIndex = state.files.findIndex((el: any) => el.id === fileId)

			state.files[fileIndex].url = newUrl
		},

		update(
			state,
			{ payload: { files, purpose } }: PayloadAction<{ purpose: string; files: Array<unknown> }>
		) {
			state[purpose].push(...files)
		},

		paginationSetParamenters(state, { payload }: PayloadAction<Pagination>) {
			state.pagination = payload
		},

		paginationChangePage(state, { payload }: PayloadAction<number>) {
			state.pagination.currentPage = payload
		},

		paginationReset(state) {
			state.pagination = initialState.pagination
		},

		reset: commonActions.reset(initialState),
	},
})

export const { actions } = bucket
export default bucket.reducer
