import { isValidElement } from 'react'
import { PARTICIPANT_ROLES } from 'const'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import moment from 'moment'
import { forEachObjIndexed, invertObj, isEmpty, isNil } from 'ramda'

import { FACILITY_TYPES } from '@creditclubteam/helpers'
import { adaptText } from '@creditclubteam/kit/helpers'
import { utils } from 'helpers'

const { PERSON, ENTREPRENEUR, ORGANIZATION, FACILITY } = PARTICIPANTS_TYPES

const mappedBoolValues = new Map().set(true, 'Да').set(false, 'Нет').set(null, 'Нет данных')
const mappedMarriedBoolValues = new Map().set(true, 'В браке').set(false, 'Не женат / не замужем')

export default {
	defaultOr: (value, defaultValue = '—') => {
		if (
			(typeof value === 'string' && value !== '') ||
			(typeof value === 'object' &&
				value !== null &&
				!Array.isArray(value) &&
				isValidElement(value)) ||
			Number.isFinite(value) ||
			(Array.isArray(value) && value.length)
		)
			return value

		return defaultValue
	},

	join: function (data, separator = ' ') {
		return data.filter(Boolean).join(separator)
	},

	random: (min, max, fractionDigits) => {
		if (fractionDigits) {
			min = Math.floor(min)
			return +(Math.random() * (max - min) + min).toFixed(fractionDigits)
		}
		min = Math.ceil(min)
		max = Math.floor(max)
		return Math.floor(Math.random() * (max - min + 1)) + min // Максимум и минимум включаются
	},

	strToBool: (string) => {
		if ((string === '') | (string === 'null')) return null

		return string === 'true'
	},

	formatDuration: (value) => {
		if (value == null) return null
		if (value <= 11) return `${value} ${adaptText(value, ['месяц', 'месяца', 'месяцев'])}`
		if (value % 12 === 0) {
			const years = Math.floor(value / 12)
			return `${years} ${adaptText(years, ['год', 'года', 'лет'])}`
		} else {
			const years = Math.floor(value / 12)
			const months = value % 12
			return `${years} ${adaptText(years, ['год', 'года', 'лет'])} и ${months} ${adaptText(months, [
				'месяц',
				'месяца',
				'месяцев',
			])}`
		}
	},

	strToNullOrDefault: (string) => {
		if (string === 'null') {
			return null
		}
		return string
	},

	objPropsToBool(obj) {
		const res = {}

		for (const key in obj) {
			if (obj[key] === 'true' || obj[key] === 'false' || obj[key] === 'null') {
				res[key] = this.strToBool(obj[key])
			}
		}

		return res
	},

	getTitleForField: (answers) => (value) => {
		if (!value) return answers.empty ?? 'Нет данных'

		const stringed = value.toString()

		return stringed === 'true' ? answers.positive : answers.negative
	},

	boolToText: (value) => mappedBoolValues.get(value),

	marriedToText: (value) => mappedMarriedBoolValues.get(value),

	renderBlankTitle: (title) => `${title || 'Загрузка...'} | Credit.Club - менеджер`,

	ctrlClick: (event) => {
		return event.ctrlKey || event.metaKey
	},

	shiftClick: (event) => {
		return event.shiftKey
	},

	doubleClick: (event) => {
		const isUsingWindows = navigator.platform.indexOf('Win') >= 0
		return isUsingWindows ? event.detail === 2 : false
	},

	cutText: (text, countLetters) => {
		if (text.length <= countLetters) return text
		else {
			const wrap = text.split('')
			wrap.splice(countLetters)

			return `${wrap.join('')}...`
		}
	},

	getMedia: (width) => {
		if (width > 1200) {
			return 'DESKTOP'
		}
		return 'MOBILE'
	},

	takeWithMessage(object = {}, path = '', entity = 'UNKNOWN', isResponse = true) {
		const value = this.take(object, path)

		if (value === undefined) {
			console.error(
				`${
					isResponse ? 'RESPONSE' : 'REQUEST'
				}_PROPERTY_ACCESS_ERROR: propeprty "${path}" does not exist in "${entity}" object.`
			)
		}

		return value
	},

	sortByKey: (data, key) =>
		Array.isArray(data) &&
		[...data].sort((cur, next) => {
			if (cur[key] < next[key]) return 1
			if (cur[key] > next[key]) return -1
			return 0
		}),

	sortDate: (data, key, reverse = false) =>
		utils.hasObjectLength(data) &&
		[...data].sort((cur, next) => (reverse ? cur[key] - next[key] : next[key] - cur[key])),

	getFullName: (data, cutFull = false) => {
		if (!data) return null

		const { name, surname, patronymic } = data

		let results = ''

		if (!surname && !patronymic) {
			results = name || ''
		} else {
			const willName = {
				surname,
				name,
				patronymic,
			}

			Object.keys(willName).forEach((key, i) => {
				if (willName[key]) {
					results += `${i !== 0 && cutFull ? willName[key][0] + '.' : willName[key]} `
				}
			})
		}

		return results.trim()
	},

	getDetailsFromError(error) {
		const details = []
		const data = error?.response?.data ?? {}

		if (!isEmpty(data)) {
			details.push(JSON.stringify(data, null, 2))
		}

		return {
			text: details.join('\n'),
			message: data?.message,
			originError: error,
		}
	},

	async getDetailsFromErrorBlob(error) {
		const details = []
		const data = (await error?.response?.data.text().then((data) => JSON.parse(data))) ?? {}

		if (!isEmpty(data)) {
			details.push(JSON.stringify(data, null, 2))
		}

		return {
			text: details.join('\n'),
			message: data?.message,
			originError: error,
		}
	},

	getDetailsFromErrorMsal: function (errData) {
		const err = this.take(errData, 'response.data.error')

		if (err)
			return {
				text: err.message || '—',
				message: err.code || '—',
			}
		else
			return {
				text: 'Неизвестно',
				message: 'Неизвестно',
			}
	},

	adaptText: (num, words) => {
		const count = num % 100
		const _count = count % 10
		if (count > 10 && count < 20) {
			return words[2]
		}
		if (_count > 1 && _count < 5) {
			return words[1]
		}
		if (_count === 1) {
			return words[0]
		}
		return words[2]
	},

	// оставил на случай когда путь слишком длинный и его удобней разбить на мультилайн
	getNestedValue: (object, pathArray) => {
		return pathArray.reduce((obj, key) => {
			if (obj && ![undefined, null].includes(obj[key])) return obj[key]
			else return undefined
		}, object)
	},

	defaultsDeep: function ({ initial, condition, defaultValue = null }) {
		const clone = { ...initial }

		const defaultCondition = (value) => value !== 0 && value !== false && !value

		const shouldReplaceToDefault = condition || defaultCondition

		forEachObjIndexed((value, key) => {
			if (shouldReplaceToDefault(value)) {
				clone[key] = defaultValue
			}

			if (typeof value === 'object' && !Array.isArray(value) && !isNil(value)) {
				clone[key] = this.defaultsDeep({
					initial: clone[key],
					condition,
					defaultValue,
				})
			}
		}, clone)

		return clone
	},

	makeCancelable: (promise) => {
		let _hasCanceled = false

		const wrappedPromise = new Promise((resolve, reject) => {
			promise.then(
				(value) => (_hasCanceled ? reject({ isCanceled: true }) : resolve(value)),
				(err) => (_hasCanceled ? reject({ isCanceled: true }) : reject(err))
			)
		})

		return {
			promise: wrappedPromise,
			cancel() {
				_hasCanceled = true
			},
		}
	},

	take: function (object = {}, path = '', defaultValue) {
		if (path.split) {
			return path.split('.').reduce((obj, key) => {
				if (obj && obj[key] !== undefined) {
					if (obj[key]) {
						return obj[key]
					} else {
						return defaultValue !== undefined ? defaultValue : obj[key]
					}
				} else {
					return defaultValue
				}
			}, object)
		} else if (typeof path !== 'string') {
			console.warn(`path is not a string`)
		}

		return undefined
	},

	parsePhone: (unparsedPhone) => {
		if (!unparsedPhone || !/^[0-9/+-\s]*$/.test(unparsedPhone)) return null

		let phone, phone1, phone2, phone3, phone4
		unparsedPhone = unparsedPhone.toString()

		if (!isNil(unparsedPhone)) {
			if (unparsedPhone.length === 7) {
				phone1 = unparsedPhone.slice(0, 3)
				phone2 = unparsedPhone.slice(3, 5)
				phone3 = unparsedPhone.slice(5, 7)
				phone = `+7 ${phone1}-${phone2}-${phone3}`
			} else if (unparsedPhone.length === 10) {
				phone1 = unparsedPhone.slice(0, 3)
				phone2 = unparsedPhone.slice(3, 6)
				phone3 = unparsedPhone.slice(6, 8)
				phone4 = unparsedPhone.slice(8, 10)
				phone = `+7 ${phone1} ${phone2}-${phone3}-${phone4}`
			} else {
				phone = `+7 ${unparsedPhone}`
			}
		}

		return phone
	},

	matchRoles: (roles, toEnglish = false) => {
		if (!roles || isEmpty(roles)) return []

		let allRoles = { ...PARTICIPANT_ROLES }

		allRoles = toEnglish ? invertObj(allRoles) : allRoles

		const result = []

		for (let i = 0; i < roles.length; i++) {
			result.push(allRoles[roles[i]] || roles[i])
		}

		return result
	},

	formatToHHMMSS: (num) => {
		let hours = Math.floor(num / 3600)
		let minutes = Math.floor((num - hours * 3600) / 60)
		let seconds = Math.floor(num - hours * 3600 - minutes * 60)

		if (minutes < 10) minutes = '0' + minutes
		if (seconds < 10) seconds = '0' + seconds

		if (hours) {
			if (hours < 10) hours = '0' + hours
			return `${hours}:${minutes}:${seconds}`
		} else return `${minutes}:${seconds}`
	},

	isEmail: (value) => {
		if (!value || typeof value !== 'string' || value.length > 320) return false

		return /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i.test(
			value
		)
			? true
			: false
	},

	unparsePhone: (phone) => {
		if (!phone || typeof phone !== 'string') return ''

		return phone.replace(/^(\D*?[78](\D*|\s)?){1}|\D/g, '')
	},

	isValidPhone(phone) {
		if (phone) {
			return this.unparsePhone(phone).length === 10
		}

		return false
	},

	findAndParseFioFromDadata: (query, { suggestions }) => {
		if (!isEmpty(suggestions)) {
			const suggests = suggestions.filter((suggestion) => {
				if (query.toLowerCase() === suggestion.value.toLowerCase()) return suggestion
			})

			return suggests.length > 0
				? {
						gender: suggests[0].data.gender !== 'UNKNOWN' ? suggests[0].data.gender : null,
						name: suggests[0].data.name,
						patronymic: suggests[0].data.patronymic,
						surname: suggests[0].data.surname,
				  }
				: {
						fullName: query,
						gender: null,
						name: query,
						patronymic: null,
						surname: null,
				  }
		} else {
			return {
				fullName: query,
				gender: null,
				name: query,
				patronymic: null,
				surname: null,
			}
		}
	},

	blink: (elementId, className = 'blink', blinkDelay = 300) => {
		const nodeElement = document.getElementById(elementId)

		if (nodeElement) {
			nodeElement.classList.add(className)
			setTimeout(() => nodeElement.classList.remove(className), blinkDelay)
		}
	},

	isNumber: (value) => {
		value = +value

		return typeof value === 'number' && !isNil(value)
	},

	isJSON: (data) => {
		try {
			JSON.parse(data)
			return true
		} catch {
			return false
		}
	},

	isDate: (value) => Boolean((value || {}).getDate),

	isCadaster: (value) => {
		if (!value || typeof value !== 'string') return false

		// проверка на наличие букв и пробелов
		if (/[a-z]|\s+/gi.test(value)) return false

		// находим кадастр
		const cadaster = value.match(/(\d+:\d+)$/g)

		return !!cadaster
	},

	getFillHeightNodeElement: (nodeElement) => {
		const computedStyles = ['margin-top', 'margin-bottom']
		let totalHeight = nodeElement.offsetHeight

		computedStyles.forEach((property) => {
			let height = getComputedStyle(nodeElement).getPropertyValue(property)
			height = parseInt(height)

			if (height) totalHeight += height
		})

		return totalHeight
	},

	formatParticipantsToSelect(list) {
		return utils.hasObjectLength(list)
			? list.map((item) => {
					const { $$type, type, id, surname, patronymic, name } = item

					switch ($$type) {
						case PERSON:
							return { id, label: this.getFullName({ name, surname, patronymic }) }
						case ENTREPRENEUR:
							return { id, label: name }
						case ORGANIZATION:
							return { id, label: name }
						case FACILITY:
							return {
								id,
								label: this.join(
									[FACILITY_TYPES[type], item.form?.area && `${item.form?.area} м²`],
									' '
								),
							}
						case 'APPLICATION':
							return { id, label: 'Заявка' }
						case 'LOAN':
							return { id, label: 'Заём' }
						case 'LEAD':
							return { id, label: 'Лид' }
						default:
							return {}
					}
			  })
			: []
	},

	formatDateTime: (date) => moment.utc(date, ['YYYY MM DD HH:m']).local().format('D MMMM H:mm'),

	formatDate: (date) => (date ? moment(date).format('DD.MM.YYYY') : null),

	formatApiDate: (date) => {
		const [day, month, year] = date.split('.')

		return `${year}-${month}-${day}`
	},

	/**
	 * @description Возвращает бул равны ли друг другу переданные даты без учёта времени
	 */
	datesIsEqual: (...args) => {
		const dates = []

		args.forEach((arg) => {
			if (arg instanceof Date) dates.push(`${arg.getDate()}${arg.getMonth()}${arg.getFullYear()}`)
		})

		return Boolean(dates.reduce((acc, cur) => (cur !== acc ? 1 : 0), 0))
	},

	getDifferenceBetweenDates: (d1, d2) => {
		const diffTime = Math.abs(d1.getTime() - d2.getTime())

		return Math.ceil(diffTime / (1000 * 60 * 60 * 24))
	},

	timeIsEqual: (d1, d2) => {
		const times = []
		;[d1, d2].forEach((arg) => {
			if (arg instanceof Date) {
				times.push(parseInt(`${arg.getHours()}${arg.getMinutes()}`))
			}
		})

		return times[0] === times[1]
	},

	checkingAccount: {
		parse: (value) => (value || '').replace(/[^0-9]/g, ''),
	},

	RUB: (value, withoutNullFraction = false) => {
		if ((!Number(value) || !value) && value !== 0) return ''
		const num = Number(value)
		let result = ''

		if (withoutNullFraction) {
			result = `${num.toLocaleString('ru-RU')} ₽`
		} else {
			result = num.toLocaleString('ru-RU', {
				currency: 'RUB',
				style: 'currency',
				maximumFractionDigits: 2,
			})
		}

		if (result === '-0 ₽') {
			result = result.replace(/-/, '')
		}

		return result
	},

	isMobileWidth: () => window.innerWidth <= 1200,

	getWorkerName(worker) {
		return worker
			? `${this.take(worker, 'surname', '')} ${this.take(worker, 'name', '')}`.trim()
			: '—'
	},

	getBrokerNameWithOrg(broker) {
		return this.join(
			[
				this.getFullName(broker),
				broker.organizationShortName && `(${broker.organizationShortName})`,
			],
			' '
		)
	},

	hasObjectLength: (value) => {
		if (value && typeof value === 'object') {
			return Array.isArray(value) ? Boolean(value.length) : Boolean(Object.keys(value).length)
		}

		return false
	},

	cropTextByCount: (text, count) => text.slice(0, count) + '...',
}
