import { useCallback, useEffect, useRef, useState } from 'react'

import { utils } from 'helpers'

function resolvePromise(promise, ...args) {
	if (typeof promise === 'function') {
		return promise(...args)
	}

	return promise
}

const useFetch = (request, options) => {
	const [response, setResponse] = useState(undefined)
	const [isFetching, setFetching] = useState(false)

	const {
		noFetchingStatus = false,
		customSetFetchingStatus,
		onFailure,
		onSuccess,
		transformResponse,
	} = options || {}

	// для асинхронных коллбэков в then/catch
	const isMounted = useRef(true)

	const cancelableRequest = useCallback(
		(...args) => {
			return utils.makeCancelable(resolvePromise(request, ...args))
		},
		[request]
	)

	const cancelablePromise = useRef(undefined)

	const changeFetchingStatus = useCallback(
		(value) => {
			if (noFetchingStatus) return

			customSetFetchingStatus ? customSetFetchingStatus(value) : setFetching(value)
		},
		[noFetchingStatus, customSetFetchingStatus]
	)

	const callback = useCallback(
		(...args) => {
			changeFetchingStatus(true)

			cancelablePromise.current = cancelableRequest(...args)

			return cancelablePromise.current.promise
				.then(async (data) => {
					if (transformResponse) {
						const transformedData = transformResponse(data)
						setResponse(transformedData)
					} else {
						setResponse(data)
					}

					if (onSuccess) await onSuccess(data, isMounted.current)

					if (isMounted.current) {
						changeFetchingStatus(false)
					}

					cancelablePromise.current = undefined

					return data
				})
				.catch(async (err) => {
					if (err && err.isCanceled) {
						cancelablePromise.current = undefined
						return err
					}

					if (onFailure) await onFailure(err, isMounted.current)

					if (isMounted.current) {
						changeFetchingStatus(false)
					}

					cancelablePromise.current = undefined

					return err
				})
		},
		[cancelableRequest, transformResponse, onSuccess, onFailure, changeFetchingStatus]
	)

	useEffect(() => {
		return () => {
			isMounted.current = false

			if (cancelablePromise.current) {
				cancelablePromise.current.cancel()
			}
		}
	}, [])

	return {
		response,
		isFetching,
		request: callback,
	}
}

export { useFetch }
