import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Pipeline } from 'converters/pipeline'
import { commonActions } from 'reducers/common'

const initialState = {
	statuses: {
		application: [] as Record<string, any>[],
		loan: [] as Record<string, any>[],
		lead: [] as Record<string, any>[],
	},
	list: [] as Pipeline.Stage[],
	single: [] as Pipeline.Stage[],
	transitions: [] as Pipeline.Transition[],
	isFetching: false,
}

type Key = Exclude<keyof (typeof initialState)['statuses'], 'fetching'>

const pipelineSlice = createSlice({
	name: 'pipeline',
	initialState,
	reducers: {
		setPipeline(
			state,
			{
				payload: { key, value },
			}: PayloadAction<{ key: 'list' | 'single'; value: Pipeline.Stage[] }>
		) {
			state[key] = value
		},

		setStatuses(state, { payload: { key, value } }: PayloadAction<{ key: Key; value: any[] }>) {
			state.statuses[key] = value
		},

		setFetchingStatus(state, action: PayloadAction<boolean>) {
			state.isFetching = action.payload
		},

		setTransitions(state, { payload }: PayloadAction<Pipeline.Transition[]>) {
			state.transitions = payload
		},

		reset: commonActions.reset(initialState),
	},
})

export const { actions } = pipelineSlice
export default pipelineSlice.reducer
