import axios from 'axios'
import type { InsuranceOrganization, InsuranceProduct } from 'reducers/insurance'

import type { Pageable } from './types/Pageable'

export default {
	searchOrganizations: async () => {
		return axios
			.get('/v1/insurance/organizations', {
				params: {
					size: 50,
					page: 0,
				},
			})
			.then(
				(response) =>
					Object.assign({}, response.data, {
						content: response.data.content.map((el: InsuranceOrganization) => el),
					}) as Pageable<InsuranceOrganization>
			)
	},
	searchProducts: async () => {
		return axios
			.get('/v1/insurance/products', {
				params: {
					size: 50,
					page: 0,
				},
			})
			.then(
				(response) =>
					Object.assign({}, response.data, {
						content: response.data.content.map((el: InsuranceProduct) => el),
					}) as Pageable<InsuranceProduct>
			)
	},
}
