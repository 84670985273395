import { main } from './main'


export const bonusApplication = {
	main,
}

export namespace BonusApplicationDto {
	export type Main = ReturnType<typeof main>
}
