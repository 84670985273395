import { combineReducers } from 'redux'

import categories from './categories'
import documentConstructor from './documentConstructor'
import files from './files'
import sign from './sign'

export default combineReducers({
	sign,
	files,
	categories,
	documentConstructor,
})
