import { addFiles } from 'actions/document/files'
import { isManyCategoryTypes } from 'actions/document/helpers'
import { setModalShow } from 'actions/modalDialogs'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { ENTITY_TYPES, ROLES_VALUES } from 'const'
import * as actionTypes from 'const/actionTypes'
import fileSaver from 'file-saver'
import moment from 'moment'
import { isEmpty, omit, uniq } from 'ramda'

import { utils } from 'helpers'
import { defaultToApi } from 'helpers/convertHelper'

const productTypesForMatching = ['UL_LOAN_LINE', 'UL_MICRO_LOAN_LINE']

const setFetchingStatus = (status) => ({
	type: actionTypes.DOCUMENTS_CONSTRUCTOR_SET_FETCHING_STATUS,
	status,
})

const initConstructor = (variables = {}, formValues = {}, inputsOrder = []) => ({
	type: actionTypes.DOCUMENTS_CONSTRUCTOR_INIT,
	variables,
	formValues,
	inputsOrder,
})

const setCategoryType = (categoryType = '') => ({
	type: actionTypes.DOCUMENTS_CONSTRUCTOR_CATEGORY_TYPE,
	categoryType,
})

export const resetConstructor = () => ({
	type: actionTypes.DOCUMENTS_CONSTRUCTOR_RESET,
})

export const initDocument =
	({ entityId, category, entityType }) =>
	async (dispatch, getState) => {
		dispatch(setFetchingStatus(true))
		dispatch(setCategoryType(category.constructorSpec?.templateType))

		if (entityType === ENTITY_TYPES.APPLICATION) {
			const application = getState().application.single.data
			const hasCreditPolicy = application.activeCreditPolicies.length !== 0

			if (!hasCreditPolicy) {
				dispatch(
					addServerError({
						text: `Необходимо выбрать кредитную политику`,
					})
				)

				dispatch(setModalShow(false, 'documentConstructor'))
				return
			}
		}

		if (!isManyCategoryTypes(category.constructorSpec?.templateType)) {
			try {
				const { data } = await api.documentConstructor.inputs({
					owner: {
						id: entityId,
						type: entityType,
					},
					categoryId: category.id,
				})

				if (!isEmpty(data.inputs)) {
					dispatch(setModalShow(true, 'documentConstructor'))
				}

				// асинхронное автозаполнение данных формы
				const formValues = await autofillValues(
					category.constructorSpec,
					formatInputs(data.inputs),
					getState
				)
				// сохраняем оригинальный порядок свойств объекта,
				// т.к. в сторе они перемешиваются
				const inputsOrder = getInitialInputsOrder(data.inputs)

				const variables = normalizeArrToObj(data.inputs)

				dispatch(initConstructor(variables, formValues, inputsOrder))

				if (isEmpty(data.inputs)) {
					dispatch(createDocument({ entityId, values: null, entityType, categoryId: category.id }))
				}

				if (!data) {
					throw new Error('data is null or undefined')
				}
			} catch (error) {
				dispatch(
					addServerError({
						text: `Ошибка при инициализации документа "${category.title}"`,
						details: utils.getDetailsFromError(error),
					})
				)

				dispatch(setModalShow(false, 'documentConstructor'))
			} finally {
				dispatch(setFetchingStatus(false))
			}

			return
		}

		let allInputs = []

		try {
			const { data } = await api.documentConstructor.inputs({
				owner: {
					id: entityId,
					type: entityType,
				},
				categoryId: category.id,
			})

			allInputs.push(...data.inputs)
		} catch (error) {
			dispatch(
				addServerError({
					text: `Ошибка при инициализации документа "${category.title}"`,
					details: utils.getDetailsFromError(error),
				})
			)

			dispatch(setModalShow(false, 'documentConstructor'))
		} finally {
			dispatch(setFetchingStatus(false))
		}

		allInputs = uniq(allInputs)

		if (allInputs && !isEmpty(allInputs)) {
			dispatch(setModalShow(true, 'documentConstructor'))
		}

		const formValues = await autofillValues(
			category.constructorSpec?.templateType,
			formatInputs(allInputs),
			getState
		)

		const inputsOrder = getInitialInputsOrder(allInputs)

		const variables = normalizeArrToObj(allInputs)

		dispatch(initConstructor(variables, formValues, inputsOrder))
	}

export const createDocument =
	({ entityId, values, entityType, categoryId }) =>
	(dispatch, getState) => {
		const { variables } = getState().document.documentConstructor

		dispatch(setFetchingStatus(true))

		return api.document
			.createFile({
				source: 'CONSTRUCTOR',
				owner: {
					id: entityId,
					type: entityType,
				},
				parameters: {
					variables: formatForm(variables, values, ['formats']),
					formats: values?.formats?.map(({ value }) => value) ?? ['PDF'],
				},
				categoryId,
			})
			.then(({ data }) => {
				dispatch(addFiles(data))
				dispatch(setModalShow(false, 'documentConstructor'))

				dispatch(resetConstructor())
			})
			.catch((error) => {
				let message = ''

				if (
					utils.take(error, 'response.data.errorCode') === 'financial_product_illegal_or_not_found'
				) {
					message =
						'В займе не определен или указан старый финансовый продукт. Определите финансовый продукт и передайте эту информацию вместе с номером займа разработчикам.'
				}

				dispatch(
					addServerError({
						text: 'Не удалось создать документ',
						details: { ...utils.getDetailsFromError(error), message },
					})
				)

				dispatch(setFetchingStatus(false))
			})
	}

export const downloadDocument = (data, title) => async (dispatch) => {
	try {
		const response = await api.documentConstructor.createDocument(data)

		fileSaver.saveAs(response.data, title)
	} catch (err) {
		dispatch(
			addServerError({
				text: 'Не удалось скачать отчёт',
				details: utils.getDetailsFromError(err),
			})
		)
	}
}

// helpers

function normalizeArrToObj(data) {
	return data.reduce((acc, item) => {
		acc[item.id] = item

		return acc
	}, {})
}

function formatInputs(inputs) {
	const form = {}

	inputs.forEach((key) => {
		if (key.type === 'date') {
			form[key.id] = moment(key.value, ['YYYY-MM-DD']).format('DD.MM.YYYY')
		} else {
			form[key.id] = key.value ?? null
		}
	})

	return form
}

function getInitialInputsOrder(data) {
	const inputsOrder = []

	data.forEach(({ id }) => inputsOrder.push(id))

	return inputsOrder
}

function formatForm(variables, form, omitFields = []) {
	const formatedForm = omit(omitFields, { ...form })

	if (isEmpty(form)) return {}

	Object.keys(variables).forEach((key) => {
		if (variables[key].type === 'date') {
			if (moment(form[key], ['DD.MM.YYYY']).isValid()) {
				formatedForm[key] = moment(form[key], ['DD.MM.YYYY']).format('YYYY-MM-DD')
			} else {
				formatedForm[key] = defaultToApi(form[key])
			}
		} else if (variables[key].type === 'double') {
			formatedForm[key] = defaultToApi(form[key]?.replace(/,/g, '.'))
		} else {
			formatedForm[key] = defaultToApi(form[key])
		}
	})

	return formatedForm
}

// смотрим на тип создаваемого документа и понимаем какие поля заполнять
async function autofillValues(constructorSpec, form, getState) {
	if (
		constructorSpec?.templateType === 'LOAN_CONTRACT' ||
		constructorSpec?.templateType === 'LINE_LOAN_CONTRACT'
	) {
		const filledForm = await autofillLoanContract(form, getState)

		return filledForm
	}

	return form
}

// fillers

async function autofillLoanContract(formValues, getState) {
	const person = getState().person.list.data.find((e) => e.roles?.includes(ROLES_VALUES.BORROWER))

	if (person) {
		const bank = await api.dadata
			.getSuggestions({ query: person?.form?.bic }, 'bank')
			.then(({ data }) => (isEmpty(data.suggestions) ? null : data.suggestions[0]))
			.catch(() => null)

		if (bank) {
			formValues = {
				...formValues,
				BANK_NAME: bank.data.name.payment || formValues.BANK_NAME,
				BANK_CORR_ACCOUNT: bank.data.correspondent_account || formValues.BANK_CORR_ACCOUNT,
			}
		}
	}

	return formValues
}
