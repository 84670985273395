import { DefaultValues } from 'helpers/convertHelper'

export const passport = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		series: model.string(['series']),
		number: model.string(['number']),
		issueDate: model.string(['issueDate']),
		birthDate: model.string(['birthDate']),
		birthplace: model.string(['birthplace']),
		divisionCode: model.string(['divisionCode']),
		department: model.string(['department']),
		registration: model.null<Record<string, any>>(['registration']),
	}
}
