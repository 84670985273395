import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { actions } from 'reducers/creditProgram'
import type { TAction } from 'types/redux'

import type { ApplicantType } from 'components/common/Calculator/types'
import { utils } from 'helpers'

export const getCreditProgram =
	(id: string): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			dispatch(actions.setStatus('pending'))

			const { data } = await api.calculation.program.get(id)

			dispatch(actions.upsertOne(data))
			dispatch(actions.setStatus('fulfilled'))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить программу',
					details: utils.getDetailsFromError(error),
				})
			)

			dispatch(actions.setStatus('rejected'))
		}
	}

export const getCreditProgramByType =
	(type: ApplicantType): TAction<Promise<any>> =>
	async (dispatch) => {
		try {
			dispatch(actions.setStatus('pending'))
			const { data } = await api.calculation.program.search(type)

			dispatch(actions.setMany(data.content))
			dispatch(actions.setStatus('fulfilled'))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить программы',
					details: utils.getDetailsFromError(error),
				})
			)

			dispatch(actions.setStatus('rejected'))
		}
	}
