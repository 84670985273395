import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { Worker } from 'converters/worker'
import { commonActions } from 'reducers/common'
import type { TRedux } from 'types'

const workerAdapter = createEntityAdapter<Worker.Main>()

export const workerSelector = workerAdapter.getSelectors<TRedux.RootState>((state) => state.worker)

const initialState = workerAdapter.getInitialState({
	fetching: false,
})

const worker = createSlice({
	name: 'worker',
	initialState,
	reducers: {
		setAll: workerAdapter.setAll,
		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

const { actions } = worker

export { actions }
export default worker.reducer
