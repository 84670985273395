export const CALCULATION = {
	PAYMENT_TYPE: {
		ENG: {
			ANNUITY: 'ANNUITY',
			PERCENT: 'PERCENT',
			MIXED: 'MIXED',
		},
		RUS: {
			ANNUITY: 'Аннуитетный',
			PERCENT: 'Процентный',
			MIXED: 'Смешанный',
		},
	},
	UNIT: {
		ENG: {
			ANNUAL: 'ANNUAL',
			MONTHLY: 'MONTHLY',
		},
		RUS: {
			ANNUAL: 'годовых',
			MONTHLY: 'в месяц',
		},
	},
	TYPE: {
		ENG: {
			MAIN_DEBT: 'MAIN_DEBT',
			INTEREST: 'INTEREST',
			CONSTANT_MEMBERSHIP_FEE: 'CONSTANT_MEMBERSHIP_FEE',
			VARIABLE_MEMBERSHIP_FEE: 'VARIABLE_MEMBERSHIP_FEE',
			CONSTANT_COMMISSION: 'CONSTANT_COMMISSION',
			VARIABLE_COMMISSION: 'VARIABLE_COMMISSION',
		},
		RUS: {
			MAIN_DEBT: 'Основной долг',
			INTEREST: 'Проценты',
			CONSTANT_MEMBERSHIP_FEE: 'Постоянный членский взнос',
			VARIABLE_MEMBERSHIP_FEE: 'Переменный членский взнос',
			CONSTANT_COMMISSION: 'Постоянная комиссия',
			VARIABLE_COMMISSION: 'Переменная комиссия',
		},
	},
} as const
