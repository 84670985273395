import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Entrepreneur } from 'converters/entrepreneur'
import { commonActions } from 'reducers/common'
import type { Maybe } from 'types/util'

export interface EntrepreneurFilter {
	query: Maybe<string>
	ids: Maybe<string[]>

	[key: string]: unknown
}

interface State {
	data: Entrepreneur.Main[]
	fetching: boolean
	filter: EntrepreneurFilter
}

const initialState: State = {
	data: [],
	fetching: false,
	filter: {
		ids: null,
		query: null,
	},
}

const list = createSlice({
	name: 'enterpreneur/list',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Array<any>>) {
			state.data = payload
		},

		delete(state, { payload }: PayloadAction<string>) {
			state.data = state.data.filter(({ id }) => id !== payload)
		},

		update(state, { payload }: PayloadAction<{ id: string; value: Partial<Entrepreneur.Main> }>) {
			const i = state.data.findIndex(({ id }) => id === payload.id)

			state.data[i] = { ...state.data[i], ...payload.value }
		},

		replace(state, { payload }: PayloadAction<{ id: string; value: Entrepreneur.Main }>) {
			const i = state.data.findIndex(({ id }) => id === payload.id)

			state.data[i] = payload.value
		},

		add(state, { payload }: PayloadAction<Entrepreneur.Main>) {
			state.data.push(payload)
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		setFilter: commonActions.setFilter(),
		reset: commonActions.reset(initialState),
	},
})

export const { actions } = list
export default list.reducer
