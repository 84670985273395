import { Component } from 'react'
import { connect } from 'react-redux'
import HISTORY from 'const/history'
import moment from 'moment'
import PropTypes from 'prop-types'

import { utils } from 'helpers'

import { sortTimeInOrder } from './helpers'
import {
	Beeline,
	Calculation,
	Comment,
	StatusChange,
	VoximplantPhoneConversation,
	WhatsApp,
} from './HistoryTypes'

import './Category.scss'

const historyTypesComponents = {
	[HISTORY.TYPES.WHATS_APP_MESSAGE.value]: WhatsApp,
	[HISTORY.TYPES.VOXIMPLANT_PHONE_CONVERSATION.value]: VoximplantPhoneConversation,
	[HISTORY.TYPES.BEELINE_PHONE_CONVERSATION.value]: Beeline,
	[HISTORY.TYPES.NEW_STATUS.value]: StatusChange,
	[HISTORY.TYPES.CALCULATION.value]: Calculation,
	[HISTORY.TYPES.COMMENT.value]: Comment,
}

class Category extends Component {
	static propTypes = {
		day: PropTypes.number.isRequired,
		month: PropTypes.number.isRequired,
		year: PropTypes.number.isRequired,
		fullDate: PropTypes.object.isRequired,
		historyData: PropTypes.array.isRequired,
		isHistoryScrolled: PropTypes.bool.isRequired,
	}

	render() {
		const { fullDate, historyData, day, month, year, isHistoryScrolled } = this.props

		const dateFormat = new Date().getFullYear() === year ? 'dddd, D MMM' : 'dddd, D MMM YYYY'

		const currentDataHistory = historyData.filter((item) =>
			item.$$date.isEqualTo({ day, month, year })
		)

		return utils.hasObjectLength(currentDataHistory) ? (
			<div className='history-category'>
				<div className='history-category__header'>
					<div className='history-category__header-date' id={HISTORY.SELECTORS.HISTORY_DATE}>
						{moment(fullDate).format(dateFormat)}
					</div>
				</div>
				<div className='history-category__body'>
					{sortTimeInOrder(currentDataHistory).map((item) => {
						const HistoryTypeComponent = historyTypesComponents[item.type]

						if (HistoryTypeComponent) {
							return (
								<HistoryTypeComponent
									key={item.id}
									{...item}
									isHistoryScrolled={isHistoryScrolled}
								/>
							)
						} else {
							console.error(`${item.type} is not supported in history`)
						}
					})}
				</div>
			</div>
		) : null
	}
}

const mapStateToProps = (state) => ({
	historyData: state.history.data,
})

export default connect(mapStateToProps)(Category)
