import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { commonActions } from 'reducers/common'
import type { Maybe } from 'types/util'

const initialState = {
	size: null as Maybe<number>,
	property: null as Maybe<string>,
	direction: null as Maybe<string>,
}

const sort = createSlice({
	name: 'sort',
	initialState,
	reducers: {
		changeParameter(
			state,
			{
				payload: { key, value },
			}: PayloadAction<{ key: keyof typeof initialState; value: string | number | null }>
		) {
			state[key] = value as any
		},
		reset: commonActions.getResetActionCreator(initialState),
	},
})

export type SortState = typeof initialState
export const { changeParameter, reset } = sort.actions
export default sort.reducer
