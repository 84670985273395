import { DefaultValues } from 'helpers/convertHelper'

import { bankDetails } from './bankDetails'
import { entrepreneur } from './entrepreneur'
import { mainContact } from './mainContact'
import { organization } from './organization'
import { person } from './person'

export const profile = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		legalType: model.string<'PERSON' | 'ORGANIZATION' | 'ENTREPRENEUR'>(['legalType']),
		nameForClient: model.string(['nameForClient']),
		legalInfo: {
			PERSON: data?.legalType === 'PERSON' ? person(data?.legalInfo) : null,
			ORGANIZATION: data?.legalType === 'ORGANIZATION' ? organization(data?.legalInfo) : null,
			ENTREPRENEUR: data?.legalType === 'ENTREPRENEUR' ? entrepreneur(data?.legalInfo) : null,
		},
		site: model.string(['site']),
		mainContact: mainContact(data?.mainContact),
		bankDetails: bankDetails(data?.bankDetails),
		verificationStatus: model.string<
			'NEW' | 'IN_PROGRESS' | 'ON_VERIFICATION' | 'VERIFIED' | 'REJECTED' | 'BLOCKED'
		>(['verificationStatus']),
		rejectionReason: model.string(['rejectionReason']),
	}
}
