import { combineReducers } from 'redux'

import list from './list'
import representative from './representative'
import single from './single'
import types from './types'

export default combineReducers({
	single,
	list,
	representative,
	types,
})
