import type { TAction } from 'types/redux'

import { createCalendarEvent } from './eventForm'
import { _calendarFormToEvent } from './helpers'

/**
 * @param {Date} date
 * @param {object} form
 * @param {string} form.subject
 * @param {string=} form.eventType
 * @param {CalendarEventEntity | Array<CalendarEventEntity>=} form.entity
 */
export const createCalendarScheduledOneDayEvent =
	(date: unknown, form: Record<string, any>): TAction<Promise<any>> =>
	async (dispatch) => {
		const event = _calendarFormToEvent({
			...form,
			end: date,
			start: date,
			isAllDay: true,
		})

		await dispatch(createCalendarEvent(event))
	}
