import { connect } from 'react-redux'
import { hideNotice } from 'actions/notices'
import PropTypes from 'prop-types'

import { Portal } from 'components/common'

import Item from './Item'

import styles from './Notices.module.scss'

const Notices = ({ list, hideNotice }) => {
	return (
		<Portal id='notices'>
			<div className={styles.wrapper}>
				{list.map((notice) => (
					<Item {...notice} key={notice.id} onClick={() => hideNotice(notice.id)} />
				))}
			</div>
		</Portal>
	)
}

Notices.propTypes = {
	list: PropTypes.array.isRequired,
	hideNotice: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	...state.notices,
})

const mapDispatchToProps = {
	hideNotice,
}

export default connect(mapStateToProps, mapDispatchToProps)(Notices)
