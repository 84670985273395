import type { useSections } from '../useSections'

interface FormData {
	documents: Record<string, boolean>
	declarants: Record<string, boolean>
}

export const to = ({ documents, declarants }: ReturnType<typeof useSections>): FormData => {
	return {
		documents: documents.reduce((acc, { id, checked }) => {
			acc[id] = checked
			return acc
		}, {} as FormData['documents']),
		declarants: declarants.reduce((acc, { id, checked }) => {
			acc[id] = checked
			return acc
		}, {} as FormData['declarants']),
	}
}
