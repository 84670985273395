import { Component } from 'react'
import classNames from 'clsx'
import PropTypes from 'prop-types'

import { Loading } from 'components/common'

import './Button.scss'

class Button extends Component {
	static propTypes = {
		type: PropTypes.string,
		loading: PropTypes.bool,
		disabled: PropTypes.bool,
		className: PropTypes.string,
		buttonType: PropTypes.string,
		title: PropTypes.string.isRequired,

		onClick: PropTypes.func,
	}

	static defaultProps = {
		disabled: false,
		type: 'buttonBlue',
	}

	handleClick = () => {
		const { onClick } = this.props

		if (onClick) onClick()
	}

	render() {
		const { type, title, loading, disabled, className, buttonType = 'button' } = this.props

		const buttonClassName = classNames('button-box', {
			loading,
			disabled,
			[type]: !!type,
			[className]: !!className,
		})

		return (
			<button
				type={buttonType}
				disabled={disabled}
				onClick={this.handleClick}
				className={buttonClassName}
			>
				{title}
				{loading && (
					<div className='button-box__loading'>
						<Loading min white />
					</div>
				)}
			</button>
		)
	}
}

export default Button
