import cx from 'clsx'
import { KONTUR_CERTIFICATE_STATUSES } from 'const'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import moment from 'moment'
import PropTypes from 'prop-types'

import { utils } from 'helpers'

import CertificateActions from './CertificateActions'

import styles from './CertificateBlock.module.scss'
import { Text } from '@creditclubteam/kit/ui-components'
import { useSelector } from 'react-redux'

const getStateText = (state) => {
	if (!state) return ''

	const text = {
		[KONTUR_CERTIFICATE_STATUSES.CREATED]: 'Ожидание загрузки заявления',
		[KONTUR_CERTIFICATE_STATUSES.VALIDATING]: 'Валидация документов',
		[KONTUR_CERTIFICATE_STATUSES.VALIDATION_ERROR]: 'Ошибка валидации',
		[KONTUR_CERTIFICATE_STATUSES.VALIDATED]: 'Валидация завершена',
		[KONTUR_CERTIFICATE_STATUSES.RELEASING]: 'Подготовка к выпуску',
		[KONTUR_CERTIFICATE_STATUSES.FREEZED]: 'Выпуск приостановлен',
		[KONTUR_CERTIFICATE_STATUSES.RELEASED]: 'Подпись выпущена',
	}

	return text[state]
}

const FormState = (props) => {
	const { konturInfo, onOpenErrorModal } = props

	const state = konturInfo?.form?.state
	const hasError = konturInfo?.form?.error

	const isActive = moment().isBetween(
		moment(utils.take(konturInfo, 'certificate.activeFrom'), 'YYYY-MM-DD'),
		moment(utils.take(konturInfo, 'certificate.activeTo'), 'YYYY-MM-DD')
	)

	return (
		<div className={styles.state}>
			<Text
				as='div'
				variant={cx({
					success: state === KONTUR_CERTIFICATE_STATUSES.RELEASED && isActive,
					error: state === KONTUR_CERTIFICATE_STATUSES.VALIDATION_ERROR,
					secondary: state === KONTUR_CERTIFICATE_STATUSES.RELEASED && !isActive,
				})}
			>
				{getStateText(state)}
			</Text>
			{state === KONTUR_CERTIFICATE_STATUSES.VALIDATION_ERROR && hasError && (
				<div className={styles.showError} onClick={onOpenErrorModal}>
					Показать
				</div>
			)}
		</div>
	)
}

FormState.propTypes = {
	konturInfo: PropTypes.object,
	onOpenErrorModal: PropTypes.func,
}

const CertificateBlock = (props) => {
	const {
		hasReleaseCertStatement,
		entity,
		type,
		addServerError,
		onCreateStatement,
		onPreview,
		onOpenErrorModal,
		onSetKonturErrors,
		onDeleteCertificateForm,
		onDownloadReleaseCertStatement,
	} = props

	const signatureProvider = useSelector(
		(state) => state.person.single.qualifiedSignatureProvider
	)
	const qualifiedSignatureProviders = useSelector(
		(state) => state.dictionary.signatures.qualifiedSignatureProviders
	)

	const certificateActionsProps = {
		konturInfo: entity.konturInfo,
		addServerError,
		onCreateStatement,
		onPreview,
		onDeleteCertificateForm,
		onSetKonturErrors,
		hasReleaseCertStatement,
		onDownloadReleaseCertStatement,
	}

	const formStateProps = {
		konturInfo: entity.konturInfo,
		onOpenErrorModal,
	}

	const provider =
		qualifiedSignatureProviders.find(({ id }) => id === signatureProvider)
			?.title ??
		(signatureProvider ? signatureProvider : null)

	const RUSType = type === PARTICIPANTS_TYPES.PERSON ? 'ФЛ' : 'ЮЛ'

	return (
		<div className={styles.certificate}>
			<div className={styles.top}>
				<Text>
					Подпись {provider} для {RUSType}: {utils.getFullName(entity) || '—'}
				</Text>
				<FormState {...formStateProps} />
			</div>
			<CertificateActions {...certificateActionsProps} />
		</div>
	)
}

CertificateBlock.propTypes = {
	entity: PropTypes.object,
	type: PropTypes.string,
	hasReleaseCertStatement: PropTypes.bool,

	onCreateStatement: PropTypes.func,
	onSetKonturErrors: PropTypes.func,
	onPreview: PropTypes.func,
	onOpenErrorModal: PropTypes.func,
	addServerError: PropTypes.func,
	onDeleteCertificateForm: PropTypes.func,
	onDownloadReleaseCertStatement: PropTypes.func,
}

export default CertificateBlock
