import { isEmpty, mergeRight } from 'ramda'
import { and, comparison as bind, eq, inList } from 'rsql-builder'

class URLBuilder {
	defaultParameters = {
		size: 100,
		sort: 'createdAt,number,desc',
		page: 0,
	}

	RSQL = []

	query

	constructor(params = {}) {
		const queryBuilder = new URLSearchParams()

		const withDefaults = mergeRight(this.defaultParameters, params)

		Object.keys(withDefaults).forEach(
			(key) =>
				(params[key] || this.defaultParameters[key]) &&
				queryBuilder.append(key, params[key] || this.defaultParameters[key])
		)

		this.query = queryBuilder.toString()
	}

	getFilter = (value, filter) => {
		if (filter === 'inList') {
			return inList(...value)
		}

		if (filter === 'eq') {
			return eq(value)
		}

		return eq(value)
	}

	addFilter = (key, value, filter) => {
		if (value == null || !filter || isEmpty(value)) return this

		if (typeof filter === 'function') {
			this.RSQL.push(`${key}${filter(value)}`)
		} else {
			if (filter === 'eq' && !['number', 'string', 'boolean'].includes(typeof value)) {
				return this
			}

			if (filter === 'inList' && !Array.isArray(value)) {
				return this
			}

			this.RSQL.push(`${bind(key, this.getFilter(value, filter))}`)
		}

		return this
	}

	build = (parameter /* 'query' | 'RSQL' | undefined */) => {
		if (isEmpty(this.RSQL) && parameter !== 'RSQL') return this.query

		if (!parameter) {
			return `filter=${encodeURIComponent(and(...this.RSQL))}&${this.query}`
		}

		return parameter === 'RSQL' ? (this.RSQL.length ? and(...this.RSQL) : undefined) : this.query
	}
}

export default URLBuilder
