import { DefaultValues } from 'helpers/convertHelper'

export const founder = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		type: model.string<'ENTITY' | 'INDIVIDUAL'>(['type']),
		taxId: model.string(['taxId']),
		date: model.string(['date']),
		fullName: model.string(['fullName']),
		shareAmount: model.null<number>(['shareAmount']),
		ownershipPercentage: model.null<number>(['ownershipPercentage']),
	}
}
