import type { CREDIT_HISTORY } from 'const/externalData'
import type { TUtils } from 'types'

import { DefaultValues } from 'helpers/convertHelper'

import { credit } from './credit'
import { customerData } from './customerData'
import { request } from './request'

const scorePoint = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	const scoreType = model.string(['scoreType'])
	const source = model.const(data?.source, {
		CREDIT_CLUB: 'Credit Club',
		NBCH: 'NBCH',
		OKB: 'OKB',
	})
	let scoreTypeText = scoreType
	if (source === 'OKB' && scoreType === '7') {
		scoreTypeText = 'Скоринг Бюро 5 Поколения'
	}

	return {
		scoreType,
		scoreTypeText,
		score: model.null<number>(['score']),
		source,
		sourceRus: model.const(data?.source, {
			CREDIT_CLUB: 'Кредит Клаб',
			NBCH: 'НБКИ',
			OKB: 'ОКБ',
		}),
	}
}

export const creditsOverdue = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		count: model.null<number>(['count']),
		period: model.const(data?.period, {
			ONE_MONTH: 'ONE_MONTH',
			TWO_MONTHS: 'TWO_MONTHS',
			MORE_THREE_MONTHS: 'MORE_THREE_MONTHS',
		} as const),
		periodRus: model.const(data?.period, {
			ONE_MONTH: '30-59 дней (1 месяц)',
			TWO_MONTHS: '60-89 дней (2 месяца)',
			MORE_THREE_MONTHS: 'более 90 дней (3+ месяца)',
		} as const),
	}
}

export const totalCreditsOverdue = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		count: model.null<number>(['count']),
		period: model.const(data?.period, {
			ONE_MONTH: 'ONE_MONTH',
			TWO_MONTHS: 'TWO_MONTHS',
			MORE_THREE_MONTHS: 'MORE_THREE_MONTHS',
			MORE_ONE_MONTH_SIX_MONTH_PERIOD: 'MORE_ONE_MONTH_SIX_MONTH_PERIOD',
			MORE_ONE_MONTH_ONE_YEAR_PERIOD: 'MORE_ONE_MONTH_ONE_YEAR_PERIOD',
		} as const),
		periodRus: model.const(data?.period, {
			ONE_MONTH: '30-59 дней (1 месяц)',
			TWO_MONTHS: '60-89 дней (2 месяца)',
			MORE_THREE_MONTHS: 'более 90 дней (3+ месяца)',
			MORE_ONE_MONTH_SIX_MONTH_PERIOD: 'более 30 дней (1+ месяца) за предыдущие 180 дней',
			MORE_ONE_MONTH_ONE_YEAR_PERIOD: 'более 30 дней (1+ месяца) за предыдущие 365 дней',
		} as const),
	}
}

type SourceKeys = keyof (typeof CREDIT_HISTORY)['SOURCE']
type NormalizedCustomerData = Record<SourceKeys, TUtils.Maybe<ReturnType<typeof customerData>>>
type NormalizedRequestDto = Record<SourceKeys, TUtils.Maybe<ReturnType<typeof request>>>

const normalizeBySource = <T extends { source: SourceKeys | '' }>(data: T[]) =>
	data.reduce(
		(acc, curr) => {
			if (curr.source) acc[curr.source] = curr
			return acc
		},
		{ NBCH: null, OKB: null } as Record<SourceKeys, TUtils.Maybe<T>>
	)

export const responseDataCreditHistory = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	const normalizedCustomerData: NormalizedCustomerData = normalizeBySource(
		model.array(['customerData']).map(customerData)
	)
	const normalizedRequest: NormalizedRequestDto = normalizeBySource(
		model.array(['requests']).map(request)
	)

	const sortedCredits = model
		.array(['credits'])
		.map(credit)
		.sort((a, b) => {
			if (new Date(a.openedAt).getTime() < new Date(b.openedAt).getTime()) {
				return 1
			}

			if (new Date(a.openedAt).getTime() > new Date(b.openedAt).getTime()) {
				return -1
			}

			return 0
		})

	return {
		credits: sortedCredits,
		customerData: normalizedCustomerData,
		requests: normalizedRequest,
		scorePoints: model.array(['scorePoints']).map(scorePoint),
		totalCreditLimit: model.null<number>(['totalCreditLimit']),
		totalCurrentBalance: model.null<number>(['totalCurrentBalance']),
		totalPastDueAmount: model.null<number>(['totalPastDueAmount']),
		totalOutstandingAmount: model.null<number>(['totalOutstandingAmount']),
		totalInstalmentAmount: model.null<number>(['totalInstalmentAmount']),
		activeCreditsOverdue: model.array(['activeCreditsOverdue']).map(creditsOverdue),
		closedCreditsOverdue: model.array(['closedCreditsOverdue']).map(creditsOverdue),
		totalCreditsOverdue: model.array(['totalCreditsOverdue']).map(totalCreditsOverdue),
		requestsFor30DaysAggregation: model.string(['requestsFor30DaysAggregation']),
		totalCreditLimitAggregation: model.string(['totalCreditLimitAggregation']),
		totalCurrentBalanceAggregation: model.string(['totalCurrentBalanceAggregation']),
		totalOutstandingAmountAggregation: model.string(['totalOutstandingAmountAggregation']),
		totalInstalmentAmountAggregation: model.string(['totalInstalmentAmountAggregation']),
		totalPastDueAmountAggregation: model.string(['totalPastDueAmountAggregation']),
		activeCreditsOverdueAggregation: model.string(['activeCreditsOverdueAggregation']),
		closedCreditsOverdueAggregation: model.string(['closedCreditsOverdueAggregation']),
		warnings: model.array<string[]>(['warnings']),
	}
}
