import { Component } from 'react'
import { connect } from 'react-redux'
import { removeServerError, removeServerErrorsFromUi } from 'actions/serverErrors'
import cx from 'clsx'
import PropTypes from 'prop-types'

import { utils } from 'helpers'

import Error from './Error'

import './ServerErrors.scss'

class ServerErrors extends Component {
	static propTypes = {
		errors: PropTypes.array.isRequired,

		removeServerError: PropTypes.func.isRequired,
		removeServerErrorsFromUi: PropTypes.func.isRequired,
	}

	handleCloseError = (id) => {
		const { removeServerError } = this.props

		removeServerError(id)
	}

	handleCloseAll = () => {
		const { removeServerErrorsFromUi } = this.props

		removeServerErrorsFromUi()
	}

	render() {
		const { errors } = this.props

		const activeErrors = utils.hasObjectLength(errors) && errors.find((error) => error.system)

		return (
			<div className={cx('server-errors', { activeErrors })}>
				{activeErrors && (
					<>
						{errors.length > 1 && (
							<div className='server-errors-closeall' onClick={this.handleCloseAll}>
								Скрыть
							</div>
						)}
						{errors
							.map(
								(item) =>
									item.system && <Error key={item.id} {...item} onClick={this.handleCloseError} />
							)
							.reverse()}
					</>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	...state.serverErrors,
})

const mapDispatchToProps = {
	removeServerError,
	removeServerErrorsFromUi,
}

export default connect(mapStateToProps, mapDispatchToProps)(ServerErrors)
