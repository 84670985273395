import icon from '../images/logo.png'

export default {
	init: function () {
		if (this.isSupported() && Notification.permission !== 'denied') {
			Notification.requestPermission()
		}
	},

	isSupported: () => 'Notification' in window,

	hasPermission: function () {
		return this.isSupported() && Notification.permission === 'granted'
	},

	send: function (title = '', options = {}) {
		if (this.hasPermission()) new Notification(title, { ...options, icon })
	},
}
