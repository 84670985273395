import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { Person } from 'converters/person'
import { commonActions } from 'reducers/common'
import type { TRedux } from 'types'

const spousesAdapter = createEntityAdapter<Person.Main>()

export const spousesSelector = spousesAdapter.getSelectors<TRedux.RootState>(
	(state) => state.person.spouses
)

const initialState = spousesAdapter.getInitialState({
	fetching: false,
})

const spouses = createSlice({
	name: 'person/spouses',
	initialState,
	reducers: {
		setAll: spousesAdapter.setAll,
		upsertOne: spousesAdapter.upsertOne,
		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

const { actions } = spouses

export { actions }
export default spouses.reducer
