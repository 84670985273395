import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Lead } from 'converters/lead'
import { commonActions } from 'reducers/common'
import type { TUtils } from 'types'

export type LeadFilter = {
	query: TUtils.Maybe<string>
	worker: TUtils.Maybe<string[]>
	facility: TUtils.Maybe<string[]>
	participant: TUtils.Maybe<string[]>
	statuses: TUtils.Maybe<string[]>
	channel: TUtils.Maybe<string[]>
	minCreatedDate: TUtils.Maybe<string>
	maxCreatedDate: TUtils.Maybe<string>
}

const filter: LeadFilter = {
	query: null,
	worker: null,
	facility: null,
	participant: null,
	statuses: null,
	channel: null,
	minCreatedDate: null,
	maxCreatedDate: null,
}

const initialState = {
	data: [] as Lead.Main[],
	fetching: false,
	filter,
}

const list = createSlice({
	name: 'lead/list',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Lead.Main[]>) {
			state.data = payload
		},

		// Обновление записи в списке
		updateRecord(
			state,
			{ payload }: PayloadAction<{ id: string; key: keyof Lead.Main; value: unknown }>
		) {
			const { id, key, value } = payload

			const recordIndex = state.data.findIndex((item) => item.id === id)

			if (recordIndex === -1) {
				return
			}

			state.data[recordIndex][key] = value
		},

		setFilter: commonActions.setFilter(),
		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

const { actions } = list

export { actions }
export default list.reducer
