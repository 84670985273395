import { ENTITY_TYPES } from 'const'

import { DefaultValues } from 'helpers/convertHelper'

import { brokerSpec } from './brokerSpec'
import { calculation } from './calculation'
import { calculationV2 } from './calculationV2'
import { call } from './call'
import { insurance } from './insurance'
import { lead } from './lead'
import { loan } from './loan'
import { participant } from './participant'
import { preliminarySchedule } from './preliminarySchedule'
import { status } from './status'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		traceNumber: model.string(['traceNumber']),
		financialProduct: model.string(['financialProduct']),
		createdAt: model.string(['createdAt']),
		oneCId: model.string(['oneCId']),
		detailedPurpose: model.string(['detailedPurpose']),
		loanIssueMethod: model.string<'MKK_CHECKING_ACCOUNT' | 'NOMINAL_ACCOUNT' | 'KPK'>([
			'loanIssueMethod',
		]),
		product: model.string(['product']),
		creditPolicyReportId: model.string(['creditPolicyReportId']),
		contractDate: model.string(['contractDate']),
		contractDurationMonths: model.string(['contractDurationMonths']),
		participants: model.array(['participants']).map(participant),
		calculations: model.array(['calculations']).map(calculation),
		facilities: model.array<string[]>(['facilities']),
		activeCreditPolicies: model.array<string[]>(['activeCreditPolicies']),
		guarantees: model.array<string[]>(['guarantees']),
		marketingData: model.null<Record<string, any>>(['marketingData']),
		mortgageIssueVariant: model.null(['mortgageIssueVariant']),
		number: model.null<number>(['number']),
		firstPayment: model.null<number>(['firstPayment']),
		workers: model.null<Record<string, string>>(['workers']),
		insurance: model.array(['insurance']).map(insurance),
		call: call(data.call),
		lead: lead(data.lead),
		loan: loan(data.loan),
		status: status(data.status),
		calculation: calculation(data.calculation),
		calculationV2: calculationV2(data.calculationV2),
		brokerSpec: brokerSpec(data.brokerSpec),
		preliminarySchedule: data.preliminarySchedule
			? preliminarySchedule(data.preliminarySchedule)
			: null,
		consentForSubsequentPledge: model.null<boolean>(['consentForSubsequentPledge']),
		registrationInMfc: model.null<boolean>(['registrationInMfc']),
		$$type: ENTITY_TYPES.APPLICATION,
	}
}
