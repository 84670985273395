import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

export interface InsuranceProduct {
	id: string
	name: string
}

export interface InsuranceOrganization {
	id: string
	name: string
	taxId: string
	products: string[]
}

export interface Insurance {
	product: InsuranceProduct[]
	organization: InsuranceOrganization[]
}

interface State {
	data: Insurance | null
	fetching: boolean
}

const initialState: State = {
	data: null,
	fetching: false,
}

const insurance = createSlice({
	name: 'insurance',
	initialState,
	reducers: {
		update(state, { payload }: PayloadAction<Insurance>) {
			state = { ...state, data: payload }
			return state
		},
	},
})

export const { actions } = insurance
export default insurance.reducer
