import { Component } from 'react'
import cx from 'clsx'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'
import Pagination from 'rc-pagination'
import localeInfo from 'rc-pagination/lib/locale/ru_RU'

import { Button } from 'components/common'

import './PopupForm.scss'

class PopupForm extends Component {
	static propTypes = {
		area: PropTypes.string,
		address: PropTypes.string,
		base: PropTypes.array.isRequired,
		fetching: PropTypes.bool.isRequired,
		rosreestr: PropTypes.array.isRequired,

		onSubmit: PropTypes.func.isRequired,
	}

	state = {
		from: '',
		selected: {},

		basePagination: {
			offset: 0,
			currentPage: 1,
			show: this.props.base.length > 10,
			totalElements: this.props.base.length,
		},
		rosreestrPagination: {
			offset: 0,
			currentPage: 1,
			show: this.props.rosreestr.length > 10,
			totalElements: this.props.rosreestr.length,
		},
	}

	getOffsetObjects = (place, array) => {
		const pagination = { ...this.state[`${place}Pagination`] }

		return array.slice(pagination.offset, pagination.offset + 10)
	}

	onSelect = (facility, from) => {
		this.setState({
			from,
			selected: facility === this.state.selected ? {} : facility,
		})
	}

	handleChangePage = (place, value) => {
		this.setState({
			[`${place}Pagination`]: {
				...this.state[`${place}Pagination`],
				currentPage: value,
				offset: (value - 1) * 10,
			},
		})
	}

	handleSubmit = () => {
		const { onSubmit } = this.props

		const { selected, from } = this.state

		onSubmit(selected, from, selected.cadastralId)
	}

	render() {
		const { selected, basePagination, rosreestrPagination } = this.state
		const { base, rosreestr, address, area, onSubmit, fetching } = this.props

		const submitProps = {
			onClick: this.handleSubmit,
			title: 'Добавить выбранный',
			disabled: isEmpty(selected) || fetching,
		}

		const noMatchingProps = {
			disabled: fetching,
			type: 'buttonLight',
			title: 'Нет подходящих',
			onClick: () => onSubmit(null, 'initial'),
		}

		const basePaginationProps = {
			pageSize: 10,
			locale: localeInfo,
			className: 'rc-custom',
			total: basePagination.totalElements,
			current: basePagination.currentPage,
			onChange: (value) => this.handleChangePage('base', value),
		}

		const rosreestrPaginationProps = {
			pageSize: 10,
			locale: localeInfo,
			className: 'rc-custom',
			total: rosreestrPagination.totalElements,
			current: rosreestrPagination.currentPage,
			onChange: (value) => this.handleChangePage('rosreestr', value),
		}

		return (
			<div className='facility-popup-form'>
				<div className='facility-popup-form__hint'>
					Вы добавляете: {address}. {area && `${area} м²`}
				</div>
				{base && !isEmpty(base) && (
					<div className='facility-popup-form__option'>
						<div className='facility-popup-form__option-category'>В базе</div>
						{this.getOffsetObjects('base', base).map((facility) => (
							<div
								key={facility.id}
								data-test-id='facility-in-base'
								onClick={() => this.onSelect(facility, 'base')}
								className={cx('facility-popup-form__option-item', {
									disabled: fetching,
									'facility-popup-form__option-item_selected': facility === selected,
								})}
							>
								<p>{facility.address.mergedAddress}</p>
								<p>Площадь: {facility.form?.area ? `${facility.form.area} м²` : '—'}</p>
							</div>
						))}
						{basePagination.show && (
							<div className='facility-popup-form__pagination'>
								<Pagination {...basePaginationProps} />
							</div>
						)}
					</div>
				)}
				{rosreestr && !isEmpty(rosreestr) && (
					<div className='facility-popup-form__option'>
						<div className='facility-popup-form__option-category'>В Росреестре</div>
						{this.getOffsetObjects('rosreestr', rosreestr).map((facility, index) => (
							<div
								data-test-id='facility-in-rosreestr'
								key={index}
								onClick={() => this.onSelect(facility, 'rosreestr')}
								className={cx('facility-popup-form__option-item', {
									disabled: fetching,
									'facility-popup-form__option-item_selected': facility === selected,
								})}
							>
								<p>{facility.address}</p>
								<p>Кадастровый номер: {facility.cadastralId || '—'}</p>
							</div>
						))}
						{rosreestrPagination.show && (
							<div className='facility-popup-form__pagination'>
								<Pagination {...rosreestrPaginationProps} />
							</div>
						)}
					</div>
				)}
				<div className='facility-popup-form__buttons'>
					<Button {...submitProps} />
					<Button {...noMatchingProps} />
				</div>
			</div>
		)
	}
}

export default PopupForm
