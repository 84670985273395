import { useEffect, useRef, useState } from 'react'
import { Calendar as BigCalendar, momentLocalizer, Views } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import { connect } from 'react-redux'
import {
	calendarEventForm,
	getEvents,
	resetEvents,
	showTooltip,
	updateEventDate,
} from 'actions/calendar'
import { getMicrosoftUser } from 'actions/microsoft'
import cx from 'clsx'
import moment from 'moment'
import PropTypes from 'prop-types'

import { utils } from 'helpers'

import Event from './Event'
import Toolbar from './Toolbar'
import Tooltip from './Tooltip'

import './Wrapper.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css'
import 'react-big-calendar/lib/css/react-big-calendar.css'

const currentTime = new Date()
const DragCalendar = withDragAndDrop(BigCalendar)

const Wrapper = ({
	resetEvents,
	data,
	fetching,
	getEvents,
	showTooltip,
	tooltip,
	calendarEventForm,
	updateEventDate,
}) => {
	const wrapperNodeEl = useRef()
	const [currentViewName, setCurrentViewName] = useState(null)

	useEffect(() => {
		getEvents()

		const solveOverlayVisible = (e) => {
			const overlayNodeEl = document.querySelector('.rbc-overlay')
			const target = e.target
			if (overlayNodeEl && !target.classList.contains('calendar-event')) {
				e.stopPropagation()
			}
		}

		const wrapperNode = wrapperNodeEl.current

		wrapperNode?.addEventListener('mousedown', solveOverlayVisible)

		return () => {
			resetEvents()
			wrapperNode?.removeEventListener('mousedown', solveOverlayVisible)
		}
	}, [getEvents, resetEvents])

	const solveDayClassName = (day) => ({
		className: cx('', {
			'is-output-day': day.getDay() === 6 || day.getDay() === 0,
		}),
	})

	const solveEventClassName = (event) => {
		const {
			$$customParams: { eventType, isCompleted },
		} = event

		const shortView =
			!!(
				event.end &&
				Math.abs(moment.duration(moment(event.start).diff(event.end)).asMinutes()) <= 30
			) ||
			currentViewName === 'month' ||
			(event.end &&
				Math.abs(moment.duration(moment(event.start).diff(event.end)).asMinutes()) > 30 &&
				currentViewName === 'week')

		return {
			className: cx('', {
				[eventType]: !!eventType,
				'is-completed': isCompleted,
				short: shortView,
			}),
		}
	}

	const getDrilldownView = (target, viewName) => {
		if (viewName !== currentViewName) {
			setCurrentViewName(viewName)
		}
	}

	const handleSelectSlot = ({ start, end }) => {
		const _end = moment(end).subtract({ day: 1 }).toDate()

		calendarEventForm({
			start,
			end: _end !== start ? _end : null,
		})
	}

	const customComponents = {
		toolbar: Toolbar,
		event: (props) => <Event {...props} viewName={currentViewName} />,
	}

	const customMessages = {
		date: 'Дата',
		time: 'Время',
		event: 'Событие',
		allDay: 'Весь день',
		showMore: (total) => `+${total} События`,
		noEventsInRange: fetching ? 'Загрузка событий...' : 'Нет событий',
	}

	const bigCalendarOptions = {
		popup: true,
		events: data,
		resizable: true,
		titleAccessor: 'subject',
		messages: customMessages,
		allDayAccessor: 'isAllDay',
		components: customComponents,
		className: 'calendar-content',
		selectable: !utils.hasObjectLength(tooltip),
		dayPropGetter: solveDayClassName,
		eventPropGetter: solveEventClassName,
		localizer: momentLocalizer(moment),
		scrollToTime: currentTime,

		defaultDate: new Date(),
		defaultView: Views.MONTH,
		onRangeChange: getEvents,
		onSelectEvent: showTooltip,
		onEventDrop: updateEventDate,
		onEventResize: updateEventDate,
		onSelectSlot: handleSelectSlot,
		getDrilldownView: getDrilldownView,
	}

	return (
		<div className='calendar-wrapper' ref={wrapperNodeEl}>
			{utils.hasObjectLength(tooltip) && <Tooltip />}
			<div className={cx('calendar-wrapper__loading', { show: fetching })} />
			<DragCalendar {...bigCalendarOptions} />
		</div>
	)
}

Wrapper.propTypes = {
	data: PropTypes.array.isRequired,
	fetching: PropTypes.bool.isRequired,
	tooltip: PropTypes.object.isRequired,

	getEvents: PropTypes.func.isRequired,
	resetEvents: PropTypes.func.isRequired,
	showTooltip: PropTypes.func.isRequired,
	updateEventDate: PropTypes.func.isRequired,
	calendarEventForm: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	...state.calendar.events,
})

const mapDispatchToProps = {
	getEvents,
	resetEvents,
	showTooltip,
	updateEventDate,
	calendarEventForm,
	getMicrosoftUser,
}

export default connect(mapStateToProps, mapDispatchToProps)(Wrapper)
