import { connect } from 'react-redux'
import { showFilesPreview } from 'actions/filesViewer'
import PropTypes from 'prop-types'

import { fileUtils } from '@creditclubteam/helpers'

import './Image.scss'

const Image = ({ url, caption, showFilesPreview }) => (
	<div className='history-whatsapp-image'>
		<div className='history-whatsapp-image__wrapper'>
			<img
				src={url}
				onClick={() =>
					showFilesPreview([{ url: url, title: fileUtils.getFileNameFromAWSUrl(url) }])
				}
			/>
		</div>
		{caption && <div className='history-whatsapp-image__caption'>{caption}</div>}
	</div>
)

Image.propTypes = {
	caption: PropTypes.any,
	url: PropTypes.string,

	showFilesPreview: PropTypes.func.isRequired,
}

const mapDispatchToProps = {
	showFilesPreview,
}

export default connect(null, mapDispatchToProps)(Image)
