import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { Lead } from 'converters/lead'
import type { Worker } from 'converters/worker'
import { commonActions } from 'reducers/common'
import { workerSelector } from 'reducers/worker'
import type { TRedux, TUtils } from 'types'

export const matchedEntityManagersSelector = createSelector(
	[(state) => state, (state: TRedux.RootState) => state.lead.single.data],
	(state, entity) =>
		(entity && workerSelector.selectTotal(state) !== 0
			? entity.workers
					.filter(({ type }) => type === 'MANAGER')
					.map(({ id }) => workerSelector.selectById(state, id))
					.filter(Boolean)
			: []) as Worker.Main[]
)

const initialState = {
	data: null as TUtils.Maybe<Lead.Main>,
	fetching: false,
	mosInvestFetching: false,
}

const single = createSlice({
	name: 'lead/single',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Lead.Main>) {
			state.data = payload
		},

		updateFacilities(state, { payload }: PayloadAction<Lead.Facility[]>) {
			if (state.data?.facilities) {
				state.data.facilities = payload
			}
		},

		deleteFacility(
			state,
			{ payload: { key, value } }: PayloadAction<{ key: keyof Lead.Facility; value: unknown }>
		) {
			if (state.data?.facilities) {
				state.data.facilities = state.data.facilities.filter((item) => item[key] !== value)
			}
		},

		updateParticipants(state, { payload }: PayloadAction<Lead.Participant[]>) {
			if (state.data?.participants) {
				state.data.participants = payload
			}
		},

		deleteParticipant(state, { payload }: PayloadAction<string>) {
			if (state.data?.participants) {
				state.data.participants = state.data.participants.filter(({ id }) => id !== payload)
			}
		},

		updateWorkers(state, { payload }: PayloadAction<Lead.Worker[]>) {
			if (state.data?.workers) {
				state.data.workers = payload
			}
		},

		resolveNextStatus(state, { payload }: PayloadAction<Lead.Status>) {
			if (state.data?.status) {
				state.data.status = payload
			}
		},

		addCalculationV2(state, { payload }: PayloadAction<Lead.CalculationV2>) {
			if (state.data?.calculationV2) {
				state.data.calculationV2 = payload
			}
		},

		setMosInvestFetching(state, { payload }: PayloadAction<boolean>) {
			state.mosInvestFetching = payload
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

const { actions } = single

export { actions }
export default single.reducer
