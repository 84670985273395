import styled from 'styled-components'

import type { PreparedTheme } from '@creditclubteam/kit/styled'
import { mixins } from '@creditclubteam/kit/styled'

export const Grid = {
	Section: styled.div<{ gap?: keyof PreparedTheme['spaces'] | 0 }>(({ theme, gap = 'xl' }) => ({
		display: 'grid',
		gridTemplateColumns: '1fr',
		position: 'relative',
		gap: gap === 0 ? gap : theme.spaces[gap],
	})),
	Divider: styled.div(({ theme }) => ({
		width: '100%',
		backgroundColor: theme.colors.backgroundSecondary,
		height: 1,
	})),
	Row: styled.div<{
		sizes?: string
		gap?: keyof PreparedTheme['spaces']
		controls?: boolean
		indent?: keyof PreparedTheme['spaces']
	}>(({ theme, indent, controls, gap = 'xl', sizes = '1fr 1fr' }) => ({
		display: 'grid',
		gap: theme.spaces[gap],
		overflowWrap: 'break-word',
		height: 'min-content',
		gridTemplateColumns: controls ? 'repeat(auto-fit, minmax(0, min-content))' : '1fr',
		gridAutoColumns: 'min-content',

		...mixins.media(theme.breakpoints.mobile, {
			gridTemplateColumns: controls
				? 'repeat(auto-fit, minmax(0, min-content))'
				: sizes.replace(/1fr/g, 'minmax(0, 1fr)'),
		}),

		...mixins.when(indent, mixins.indent(theme.spaces[indent!])),
	})),
}
