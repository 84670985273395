import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { actions } from 'reducers/delivery/types'
import type { TRedux } from 'types'

import { utils } from 'helpers'

export const resetDeliveryTypes = actions.reset

export const getDeliveryTypes = (): TRedux.TAction<Promise<void>> => async (dispatch) => {
	dispatch(actions.setFetchingStatus(true))

	try {
		const { data } = await api.deliveryTypes.search()

		dispatch(actions.setData(data.content))
		dispatch(actions.setFetchingStatus(false))
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось загрузить типы доставок',
				details: utils.getDetailsFromError(error),
			})
		)
	}
}

export const getDeliveryType =
	(id: string): TRedux.TAction<Promise<void>> =>
	async (dispatch) => {
		dispatch(actions.setFetchingStatus(true))

		try {
			const { data } = await api.deliveryTypes.get(id)

			dispatch(actions.setData([data]))
			dispatch(actions.setFetchingStatus(false))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить тип доставки',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}
