import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'
import { updateMetadataWithinCategory, updateMetadataWithMovingFiles } from 'actions/filesInspector'
import { useFormik } from 'formik'
import { isEmpty } from 'ramda'
import { actions } from 'reducers/filesInspector/main'
import type { TRedux, TUtils } from 'types'
import type { RootState } from 'types/redux'
import * as yup from 'yup'

import { date } from '@creditclubteam/helpers'
import { Button } from '@creditclubteam/react-components'
import { DatePicker, Input, Select } from '@creditclubteam/react-components'
import { Checkbox } from 'components/common'
import { Autocomplete } from 'components/common/Documents/DocumentModule/UpdateDocumentMetadata/Autocomplete'
import ModalDialog from 'components/common/ModalDialog'

import { useConfidantOptions } from './useConfidantOptions'

import styles from './MetadataForm.module.scss'

type Field = TUtils.ArrayElement<
	NonNullable<RootState['filesInspector']['main']['metadata']>['fields']
>

const COMPONENTS: Record<Field['fieldType'], TUtils.Maybe<React.ComponentType<any>>> = {
	STRING: Input,
	DATE: DatePicker,
	BOOLEAN: Checkbox,
	CONFIDANT_ID: Select,
	ORGANIZATION: Autocomplete,
	PERSON: Autocomplete,
}

export const MetadataForm = () => {
	const { main } = useSelector((state: RootState) => state.filesInspector)

	const { confidantOptions } = useConfidantOptions()

	const [index, setIndex] = useState(0)

	const { mode = 'move', fields = [], files = [] } = main.metadata ?? {}

	const dispatch = useDispatch<TRedux.AppDispatch>()

	const increaseIndex = useCallback(() => setIndex((p) => p + 1), [])
	const decreaseIndex = useCallback(() => setIndex((p) => p - 1), [])

	const getInitialValues = () => {
		if (mode === 'move') {
			return files.reduce((files, { id }) => {
				files[id] = fields.reduce((field, curr) => {
					field[curr.id] = curr.fieldType === 'BOOLEAN' ? false : ''

					return field
				}, {} as Record<string, string | boolean>)

				return files
			}, {} as Record<string, Record<string, string | boolean>>)
		}

		if (mode === 'edit') {
			return files.reduce((files, { id, metadata = {} }) => {
				files[id] = fields.reduce((field, curr) => {
					if (metadata[curr.id] != null) {
						field[curr.id] = metadata[curr.id]
					} else {
						field[curr.id] = curr.fieldType === 'BOOLEAN' ? false : ''
					}

					return field
				}, {} as Record<string, string | boolean>)

				return files
			}, {} as Record<string, Record<string, string | boolean>>)
		}

		return {}
	}

	const getValidationSchema = () =>
		yup.object(
			files.reduce((files, { id }) => {
				files[id] = yup.object(
					fields.reduce((acc, curr) => {
						if (curr.fieldType === 'BOOLEAN') {
							acc[curr.id] = yup.bool()
						} else {
							acc[curr.id] = yup.string()
						}

						if (curr.required) acc[curr.id] = acc[curr.id].required()

						return acc
					}, {} as Record<string, yup.AnySchema>)
				)

				return files
			}, {} as Record<string, ReturnType<typeof yup.object>>)
		)

	const { values, setFieldValue, handleSubmit, isValid, isSubmitting, resetForm } = useFormik({
		enableReinitialize: true,
		initialValues: getInitialValues(),
		validationSchema: getValidationSchema(),
		validateOnChange: true,
		validateOnMount: true,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)

			return new Promise(() =>
				mode === 'move'
					? dispatch(updateMetadataWithMovingFiles(values))
					: dispatch(
							updateMetadataWithinCategory({
								id: files[0].id,
								categoryId: files[0].categoryId,
								metadata: values[files[0].id],
							})
					  )
			)
		},
	})

	useEffect(() => {
		return () => {
			if (!main.modals.metadata) {
				resetForm()
				setIndex(0)
			}
		}
	}, [main.modals.metadata, resetForm])

	const getComponentProps = ({ fieldType, id: fieldId, title }: Field) => {
		const fileId = files[index]!.id

		switch (fieldType) {
			case 'STRING':
				return {
					value: values[fileId][fieldId],
					onChange: (value) => setFieldValue(`${fileId}.${fieldId}`, value),
					title,
					mode: 'min',
				} as React.ComponentProps<typeof Input>

			case 'DATE':
				return {
					value: values[fileId][fieldId] ? new Date(values[fileId][fieldId] as string) : null,
					onChange: (value) =>
						setFieldValue(`${fileId}.${fieldId}`, date.format(value, { to: 'yyyy-MM-dd' })),
					title,
					mode: 'min',
				} as React.ComponentProps<typeof DatePicker>

			case 'BOOLEAN':
				return {
					checked: values[fileId][fieldId],
					label: title,
					onChange: (value: boolean) => setFieldValue(`${fileId}.${fieldId}`, value),
				}

			case 'CONFIDANT_ID':
				return {
					title,
					mode: 'min',
					options: confidantOptions,
					value: values[fileId][fieldId],
					onChange: ({ value }) => setFieldValue(`${fileId}.${fieldId}`, value),
				} as React.ComponentProps<typeof Select>

			case 'ORGANIZATION':
				return {
					title,
					type: 'ORGANIZATION',
					onReset: () => setFieldValue(`${fileId}.${fieldId}`, ''),
					onChange: (value) => setFieldValue(`${fileId}.${fieldId}`, value),
					value: values[fileId][fieldId],
				} as React.ComponentProps<typeof Autocomplete>

			case 'PERSON':
				return {
					title,
					type: 'PERSON',
					onReset: () => setFieldValue(`${fileId}.${fieldId}`, ''),
					onChange: (value) => setFieldValue(`${fileId}.${fieldId}`, value),
					value: values[fileId][fieldId],
				} as React.ComponentProps<typeof Autocomplete>
		}
	}

	const modalProps = {
		title: files.length === 1 ? 'Обновление данных документа' : 'Обновление данных документов',
		opened: main.modals.metadata,
		subTitle: `Имя документа: ${files[index]?.title}`,
		onClose: () => {
			dispatch(actions.resetMetadata())
		},
	}

	const filesAmount = files.length

	const prevBtn: React.ComponentProps<typeof Button> = {
		title: 'Назад',
		mode: 'min',
		onClick: decreaseIndex,
		disabled: index === 0,
	}

	const nextBtn: React.ComponentProps<typeof Button> = {
		title: 'Далее',
		mode: 'min',
		onClick: increaseIndex,
		disabled: index === filesAmount - 1,
	}

	const submitBtn: React.ComponentProps<typeof Button> = {
		title: 'Сохранить',
		mode: 'min',
		buttonType: 'submit',
		disabled: !isValid || isSubmitting,
	}

	return (
		<ModalDialog {...modalProps}>
			<form autoComplete='off' onSubmit={handleSubmit}>
				<div className={styles.fields}>
					{fields.map((field) => {
						const Component = COMPONENTS[field.fieldType]

						if (!main.metadata || !main.modals.metadata || isEmpty(values)) return null

						return Component && <Component key={field.id} {...getComponentProps(field)} />
					})}
				</div>
				{filesAmount > 1 ? (
					<div className={styles.controls}>
						<div className={styles.movement}>
							<Button {...prevBtn} />
							<Button {...nextBtn} />
							<span>
								{index + 1} / {main.metadata!.files.length}
							</span>
						</div>
						<Button {...submitBtn} />
					</div>
				) : (
					<Button {...submitBtn} />
				)}
			</form>
		</ModalDialog>
	)
}
