import { useCallback, useEffect, useRef } from 'react'

const useOutsideClick = (onOutsideClick) => {
	const ref = useRef()
	const target = useRef()

	const onMouseUp = useCallback(() => {
		if (!target.current) {
			onOutsideClick()
		}

		target.current = null
	}, [onOutsideClick])

	const onMouseDown = (event) => {
		const nodeElement = ref.current
		let currentTarget = event.target

		if (nodeElement && currentTarget) {
			while (currentTarget) {
				if (currentTarget) {
					if (currentTarget === nodeElement) {
						target.current = currentTarget
						break
					} else {
						currentTarget = currentTarget.parentNode
					}
				}
			}
		}
	}

	useEffect(() => {
		document.addEventListener('mouseup', onMouseUp)
		document.addEventListener('mousedown', onMouseDown)

		return () => {
			document.removeEventListener('mouseup', onMouseUp)
			document.removeEventListener('mousedown', onMouseDown)
		}
	}, [onMouseUp])

	return ref
}

export { useOutsideClick }
