import { URLBuilder } from 'api/helpers'
import type { EntityId } from 'api/types/EntityId'
import type { Options } from 'api/types/Options'
import axios from 'axios'
import broker from 'converters/broker'
import employee from 'converters/employee'
import moment from 'moment'
import { mergeDeepRight } from 'ramda'
import { ge, le } from 'rsql-builder'

interface BrokerEmployeeFilter {
	organizationId?: string
	query?: string
}

export default {
	search: ({
		filter = {},
		query,
		size = 10,
		sort,
		page = 0,
		view = 'COLLECTION',
	}: Options<Record<string, any>>) => {
		const params = new URLBuilder()

		const { state, channel, type, workers, ids, minCreatedDate, maxCreatedDate, nameForClient } =
			filter

		const _query = query || filter?.query

		params
			.addFilter('state', state, 'inList')
			.addFilter('channel', channel, 'inList')
			.addFilter('id', ids, 'inList')
			.addFilter('workers.id', workers, 'inList')
			.addFilter('type', type, 'eq')
			.addFilter('nameForClient', nameForClient, 'eq')
			.addFilter(
				'createdAt',
				minCreatedDate && moment.isDate(minCreatedDate) && minCreatedDate.toISOString(),
				ge
			)
			.addFilter(
				'createdAt',
				maxCreatedDate && moment.isDate(maxCreatedDate) && maxCreatedDate.toISOString(),
				le
			)

		return axios
			.get(`/v2/admin/organizations`, {
				params: {
					size,
					sort,
					page,
					filter: _query ? _query : params.build('RSQL'),
					searchType: _query ? 'TEXT' : 'RSQL', // TODO: возможность отправлять без лишних параметров
					view: view || 'COLLECTION',
				},
				$$requestName: 'broker.search',
			})
			.then((response) =>
				mergeDeepRight(response, {
					data: {
						content: response.data.content.map(broker.list),
					},
				})
			)
	},

	getOrganizationsBatch(ids: string[]) {
		const params = new URLBuilder().addFilter('id', ids, 'inList')
		return axios.get('/v1/brokers/organizations', {
			params: {
				size: 50,
				filter: params.build('RSQL'),
				searchType: 'RSQL',
				view: 'COLLECTION',
			},
			$$requestName: 'broker.getListBatch',
		})
	},

	get: (id: EntityId, withoutCancel?: boolean) =>
		axios.get(
			`/v2/admin/organizations/${id}`,
			withoutCancel
				? undefined
				: {
						$$requestName: 'broker.getSingle',
				  }
		),

	getEmployees: (brokerId: EntityId) =>
		axios({
			method: 'GET',
			url: `/v2/admin/employees/${brokerId}`,
		}),

	getEmployeesAccesGroup: () =>
		axios({
			method: 'GET',
			url: `/v2/admin/access-groups`,
		}),

	getEmployeesAccesGroupById: (employeesId: string) =>
		axios({
			method: 'GET',
			url: `/v2/admin/employees/${employeesId}/access-groups`,
		}),

	createAccesGroup: (brokerId: EntityId, accessGroupId: string) =>
		axios({
			method: 'POST',
			url: `/v2/admin/employees/${brokerId}/access-groups/${accessGroupId}`,
		}),

	delAccesGroup: (brokerId: EntityId, accessGroupId: string) =>
		axios({
			method: 'DELETE',
			url: `/v2/admin/employees/${brokerId}/access-groups/${accessGroupId}`,
		}),

	updateEmployee: (form: unknown, brokerId: EntityId) =>
		axios({
			method: 'PATCH',
			data: form,
			url: `/v2/admin/employees/${brokerId}`,
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),

	createEmployees: (form: Record<string, any>) =>
		axios({
			method: 'POST',
			data: form,
			url: `/v2/admin/employees`,
		}),

	searchBrokerEmployees: async ({
		query,
		organizationId,
		size = 50,
		page = 0,
	}: Options<BrokerEmployeeFilter>) => {
		const params = {
			query,
			organizationId,
			size,
			page,
		}
		return axios.get('/v2/admin/employees', { params }).then((response) =>
			mergeDeepRight(response, {
				data: response.data.map(employee.list),
			})
		)
	},

	update: (form: unknown, id: EntityId) =>
		axios({
			method: 'PATCH',
			data: form,
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
			url: `/v2/admin/organizations/${id}`,
		}),

	saveLogo: (id: EntityId, logo: any) =>
		axios.post(`/v2/admin/organizations/${id}/logo`, logo, {
			headers: {
				'Content-Type': 'multipart/form-data',
			},
		}),

	create: (form: unknown) =>
		axios({
			method: 'POST',
			data: form,
			url: `/v2/admin/organizations`,
		}),
}
