import { useCallback, useMemo, useState } from 'react'
import cx from 'clsx'
import PropTypes from 'prop-types'

import { ModalDialog } from 'components/common'

import AddSigners from './AddSigners'
import DocumentsOnSigning from './DocumentsOnSigning'
import { findDocumentsOnSigning } from './helpers'

import styles from './SignersController.module.scss'

const SignersController = (props) => {
	const { aggregator, participants, contextId, addSigner, contextType } = props

	const [isAddSignersShowing, setAddSignersShowing] = useState(false)
	const [isDocumentsOnSigningShowing, setDocumentsOnSigningShowing] = useState(false)

	const handleOpenAddSigners = useCallback(() => {
		setAddSignersShowing(true)
	}, [])

	const handleCloseAddSigners = useCallback(() => {
		setAddSignersShowing(false)
	}, [])

	const handleOpenDocumentsOnSigning = useCallback(() => {
		setDocumentsOnSigningShowing(true)
	}, [])

	const handleCloseDocumentsOnSigning = useCallback(() => {
		setDocumentsOnSigningShowing(false)
	}, [])

	const documentsOnSigning = useMemo(
		() =>
			findDocumentsOnSigning({
				aggregator,
				participants,
				contextId,
				contextType,
			}),
		[aggregator, participants, contextId, contextType]
	)

	const addSignersModalDialogProps = {
		large: true,
		title: 'Отправка на подписание',
		opened: isAddSignersShowing,
		onClose: handleCloseAddSigners,
	}

	const documentsOnSigningModalDialogProps = {
		large: true,
		title: 'Документы на подписании',
		opened: isDocumentsOnSigningShowing,
		onClose: handleCloseDocumentsOnSigning,
	}

	const addSignersProps = {
		aggregator,
		participants,
		contextId,
		contextType,

		addSigner,
		onClose: handleCloseAddSigners,
	}

	const documentsOnSigningProps = {
		data: documentsOnSigning,
	}

	const documentsOnSigningCount = documentsOnSigning
		.map((item) => item.documents.filter((e) => !e.signature.signedAt).length)
		.reduce((a, b) => a + b, 0)

	return (
		<>
			<ModalDialog {...addSignersModalDialogProps}>
				<AddSigners {...addSignersProps} />
			</ModalDialog>
			<ModalDialog {...documentsOnSigningModalDialogProps}>
				<DocumentsOnSigning {...documentsOnSigningProps} />
			</ModalDialog>
			<div className={cx(styles.wrap)}>
				<button className={cx(styles.button)} onClick={handleOpenAddSigners}>
					<i className={cx('zmdi zmdi-check', styles.icon)} />
					<span>Отправить на подпись</span>
				</button>
				{!!documentsOnSigning.length && (
					<div className={styles.simpleButton} onClick={handleOpenDocumentsOnSigning}>
						Список документов на подписании ({documentsOnSigningCount})
					</div>
				)}
			</div>
		</>
	)
}

SignersController.propTypes = {
	aggregator: PropTypes.array,
	contextId: PropTypes.string,
	contextType: PropTypes.string,
	participants: PropTypes.array,

	addSigner: PropTypes.func,
}

export default SignersController
