import { brief } from './brief'
import { employeeBrief } from './employeeBrief'
import { employeeMain } from './employeeMain'
import { main } from './main'
import { productView } from './productView'

export const partnerConverter = {
	main,
	brief,
	productView,
	employeeMain,
	employeeBrief,
}

export namespace PartnerDto {
	export type Main = ReturnType<typeof main>
	export type Brief = ReturnType<typeof brief>
	export type EmployeeMain = ReturnType<typeof employeeMain>
	export type ProductView = ReturnType<typeof productView>
	export type EmployeeBrief = ReturnType<typeof employeeBrief>
}
