import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Entrepreneur } from 'converters/entrepreneur'
import { commonActions } from 'reducers/common'
import type { Maybe } from 'types/util'

interface State {
	data: Maybe<Entrepreneur.Main>
	fetching: boolean
}

const initialState: State = {
	data: null,
	fetching: false,
}

const single = createSlice({
	name: 'enterpreneur/single',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Entrepreneur.Main>) {
			state.data = payload
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions } = single
export default single.reducer
