import { setPagination, solveParamsPagination } from 'actions/listParameters'
import { getPipeline } from 'actions/pipeline'
import {
	searchEntrepreneur,
	searchOrganization,
	searchPerson,
	setSearchResults,
} from 'actions/search'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import axios from 'axios'
import { flatten, isEmpty } from 'ramda'
import { actions } from 'reducers/delivery/list'
import type { TRedux, TUtils } from 'types'

import { utils } from 'helpers'

import { getRepresentatives } from './representative'

export const resetDeliveryList = actions.reset
export const setDeliveryListFilter = actions.setFilter

export const setDefaultDeliveryFilter = (): TRedux.TAction<void> => (dispatch, getState) => {
	const pipeline = getState().pipeline.list

	if (isEmpty(pipeline)) return

	// всевозможные статусы для фильтра доставок
	const allStatuses: string[] = pipeline.map(({ status }) => status.id)

	// статусы которые отключаем по-умолчанию
	const disabledByDefaultStatuses = ['CLOSED', 'CANCEL']

	// формируем массив активных статусов
	const defaultStatuses = allStatuses.filter(
		(status) => !disabledByDefaultStatuses.includes(status)
	)

	if (defaultStatuses && !isEmpty(defaultStatuses))
		dispatch(actions.setFilter({ key: 'status', value: defaultStatuses }))
}

export const getDeliveryList =
	(parameters: Record<string, any> = {}): TRedux.TAction<Promise<void>> =>
	async (dispatch, getState) => {
		try {
			const pipeline = getState().pipeline.list

			dispatch(actions.setFetchingStatus(true))

			/**
			 * ставим стандартный фильтр при отсутсвии пайплайна
			 */
			if (isEmpty(pipeline)) {
				await dispatch(getPipeline('delivery', 'ALL'))
				dispatch(setDefaultDeliveryFilter())
			}

			const requestParameters = Object.assign(
				{},
				dispatch(solveParamsPagination('createdAt', 'desc')),
				parameters,
				{
					filter: getState().delivery.list.filter,
				}
			)

			const { data } = await api.delivery.search(requestParameters)

			dispatch(getRepresentatives(data.content.map(({ representativeId }) => representativeId)))

			dispatch(actions.setData(data.content))

			const { totalElements, number, totalPages } = data

			dispatch(
				setPagination({
					totalPages,
					totalElements,
					paged: true,
					currentPage: number,
				})
			)
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить доставки',
					details: utils.getDetailsFromError(error),
				})
			)
		} finally {
			dispatch(actions.setFetchingStatus(false))
		}
	}

export const searchDeliveryByQuery =
	(query: string): TRedux.TAction<Promise<void>> =>
	(dispatch) => {
		const requestOptions = {
			query,
			params: {
				size: 10,
				sort: 'desc',
				page: 0,
			},
		}

		return axios
			.all([
				dispatch(searchPerson(requestOptions)),
				dispatch(searchOrganization(requestOptions)),
				dispatch(searchEntrepreneur(requestOptions)),
			] as Array<TUtils.Maybe<{ content?: Record<string, any>[] }>>)
			.then((data) => {
				const normalizedResponse = flatten(data.map((item) => item?.content).filter(Boolean))

				dispatch(setSearchResults('internal', normalizedResponse))
			})
	}
