import { Component, createContext } from 'react'
import cx from 'clsx'

import { utils } from 'helpers'

import styles from './FormLayout.module.scss'

interface Props {
	className?: string
	dataTestId?: string
}

const state: { media: 'DESKTOP' | 'MOBILE' } = {
	media: utils.getMedia(window.innerWidth),
}

const { Consumer, Provider } = createContext(state)

const Column: React.FC<Props> = ({ children, className }) => (
	<div className={cx(styles.column, className)}>{children}</div>
)

const Section: React.FC<Props> = ({ children, className, dataTestId }) => (
	<div data-test-id={dataTestId} className={cx(styles.section, className)}>
		{children}
	</div>
)

const Title: React.FC<Props> = ({ children, className }) => (
	<div className={cx(styles.title, className)}>{children}</div>
)

const Row: React.FC<{ cols?: number; disableMedia?: boolean } & Props> = ({
	children,
	disableMedia,
	className,
	cols,
}) => (
	<Consumer>
		{({ media }) => (
			<div
				className={cx(styles.row, { [styles.media]: !disableMedia }, className)}
				style={
					!className && cols
						? {
								gridTemplateColumns: media === 'MOBILE' ? '1fr' : `repeat(${cols}, minmax(0, 1fr))`,
						  }
						: undefined
				}
			>
				{children}
			</div>
		)}
	</Consumer>
)

const ReadField: React.FC<
	{
		title?: React.ReactNode
		gridColumn?: string
		childrenClassName?: string
		testId?: string
	} & Props
> = ({ title, testId, children, className, gridColumn, childrenClassName }) => (
	<Consumer>
		{({ media }) => (
			<div
				className={cx(styles.readField, className)}
				style={!className && gridColumn && media === 'DESKTOP' ? { gridColumn } : undefined}
			>
				<div className={styles.fieldTitle}>{title}</div>
				<div
					{...(testId ? { 'data-test-id': testId } : {})}
					className={cx(styles.fieldValue, childrenClassName)}
				>
					{utils.defaultOr(children)}
				</div>
			</div>
		)}
	</Consumer>
)

const Field: React.FC<
	{
		title: React.ReactNode
		readOnly?: boolean
		value?: React.ReactNode
		gridColumn?: string
		childrenClassName?: string
		testId?: string
	} & Props
> = ({ className, testId, children, gridColumn, title, value, readOnly, childrenClassName }) => (
	<ReadField
		className={className}
		gridColumn={gridColumn}
		childrenClassName={childrenClassName}
		title={title}
		testId={testId}
	>
		{readOnly ? value : children}
	</ReadField>
)

class FormLayout extends Component<
	{ className?: string; disableMarginBottom?: boolean; cols?: number[] },
	typeof state & { cols: number }
> {
	static Column = Column
	static Section = Section
	static Title = Title
	static Row = Row
	static ReadField = ReadField
	static Field = Field

	static styles = { ...styles }

	static defaultProps = {
		cols: [1, 0.5],
	}

	constructor(props: any) {
		super(props)

		this.state = {
			media: utils.getMedia(window.innerWidth),
			cols: props.cols ?? [1, 0.5],
		}
	}

	componentDidMount() {
		window.addEventListener('resize', this.handleResize)
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.handleResize)
	}

	handleResize = () => {
		const currentMedia = utils.getMedia(window.innerWidth)

		if (currentMedia !== this.state.media) {
			this.setState({ media: currentMedia })
		}
	}

	render() {
		const gridTemplateColumns =
			this.state.media === 'DESKTOP'
				? this.props.cols!.map((value) => `${value}fr`).join(' ')
				: '1fr'

		return (
			<div
				style={{
					gridTemplateColumns,
				}}
				className={cx(
					styles.layout,
					!this.props.disableMarginBottom && styles.layoutMargin,
					this.props.className
				)}
			>
				<Provider value={this.state}>{this.props.children}</Provider>
			</div>
		)
	}
}

export { FormLayout }
