import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

const initialState = {
	list: [] as any[],
}

const noticesSlice = createSlice({
	name: 'notices',
	initialState,
	reducers: {
		add(state, { payload }: PayloadAction<any>) {
			state.list.push(payload)
		},

		hide(state, { payload }: PayloadAction<string>) {
			const itemIndex = state.list.findIndex((item) => item.id === payload)
			state.list.splice(itemIndex, 1)
		},

		update(state, { payload }: PayloadAction<any>) {
			const itemIndex = state.list.findIndex((item) => item.id === payload.id)
			state.list[itemIndex] = payload.notice
		},
	},
})

export const { add, hide, update } = noticesSlice.actions

export default noticesSlice.reducer
