import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Loading } from 'components/common'
import { utils } from 'helpers'

import FilesCategory from './FilesCategory'
import { MetadataForm } from './MetadataForm'

class Inspector extends Component {
	static propTypes = {
		fetching: PropTypes.bool.isRequired,
		categories: PropTypes.array.isRequired,
	}

	renderCategoryControls(name) {
		return (
			<div className='files-inspector-files-category__head'>
				<div className='files-inspector-files-category__head-title'>{name}</div>
			</div>
		)
	}

	renderRequiredCategories() {
		const { categories } = this.props

		if (categories.find((category) => category.required))
			return (
				<div className='files-inspector-files-category__wrap'>
					{this.renderCategoryControls('Основные документы')}
					{categories.map(
						(item, index) =>
							item.required && (
								<FilesCategory
									{...item}
									key={item.id}
									index={index}
									zoneId={item.id}
									listFiles={item.$$files}
									categoryName={item.title}
								/>
							)
					)}
				</div>
			)
	}

	renderNotRequiredCategories() {
		const { categories } = this.props

		if (categories.find((category) => !category.required))
			return (
				<div className='files-inspector-files-category__wrap'>
					{this.renderCategoryControls('Дополнительные документы')}
					{categories.map(
						(item, index) =>
							!item.required && (
								<FilesCategory
									{...item}
									key={item.id}
									index={index}
									zoneId={item.id}
									listFiles={item.$$files}
									categoryName={item.title}
								/>
							)
					)}
				</div>
			)
	}

	render() {
		const { fetching, categories } = this.props

		return (
			<Fragment>
				{fetching ? (
					<div className='files-inspector-files-inspector-loading'>
						<Loading />
					</div>
				) : (
					utils.hasObjectLength(categories) && (
						<Fragment>
							<MetadataForm />
							{this.renderRequiredCategories()}
							{this.renderNotRequiredCategories()}
						</Fragment>
					)
				)}
			</Fragment>
		)
	}
}

const mapStateToProps = (state) => ({
	...state.filesInspector.inspector,
})

export default connect(mapStateToProps, null)(Inspector)
