import { configureStore } from '@reduxjs/toolkit'
import { browserHistory } from 'client/browserHistory'
import { routerMiddleware } from 'connected-react-router'
import type { History } from 'history'
import rootReducer from 'reducers'
import thunkMiddleware from 'redux-thunk'

// Формируем главное хранилище
function createStore(_: void, _browserHistory: History<unknown>) {
	const store = configureStore({
		reducer: rootReducer(_browserHistory),
		middleware: (getDefaultMiddleware) =>
			getDefaultMiddleware({
				serializableCheck: false,
				immutableCheck: { ignoredPaths: ['uploadFiles'] },
			}).concat(routerMiddleware(_browserHistory), thunkMiddleware),
	})

	// if (process.env.NODE_ENV !== "production" && module.hot) {
	//   module.hot.accept('reducers', () => {
	//     const nextRootReducer = require('reducers').default;
	//     store.replaceReducer(nextRootReducer);
	//   });
	// }

	return store
}

const initialState = window.__INITIAL_STATE__ || {}
export const store = createStore(initialState, browserHistory)
