import { Component } from 'react'
import outsideClick from 'react-click-outside'
import { connect } from 'react-redux'
import { resetSearch, setSearchField } from 'actions/search'
import cx from 'clsx'
import PropTypes from 'prop-types'

import { Input } from 'components/common'
import { utils } from 'helpers'

import Option from './Option'

import './Autocomplete.scss'

/**
 * @description Компонент умеет выводить как список по категориям так и без них
 * @param {array} categories - список категорий {id, label}
 * @param {resetSearchWhileFocus} - сбрасывать поиск в сторе во время фокуса
 * используется когда на странице несколько автокомплитов
 * @param {array} options - если передается массив категорий то обьязательно
 * в каждом итеме должна быть категория category которая совпадает с id
 * @param {bool} saveQueryOnUnmount - если указана компонент работает со стором | default: false
 */
class Autocomplete extends Component {
	static propTypes = {
		error: PropTypes.bool,
		query: PropTypes.string,
		disabled: PropTypes.bool,
		options: PropTypes.array,
		selected: PropTypes.object,
		className: PropTypes.string,
		categories: PropTypes.array,
		placeholder: PropTypes.string,
		parentQuery: PropTypes.string,
		selectedIsRequired: PropTypes.bool,
		resetSearchWhileFocus: PropTypes.bool,
		replaceOptionsIfNotFound: PropTypes.any,

		onBlur: PropTypes.func,
		onReset: PropTypes.func,
		setSearchField: PropTypes.func,
		onChange: PropTypes.func.isRequired,
		resetSearch: PropTypes.func.isRequired,
		onSelectOption: PropTypes.func.isRequired,
	}

	static defaultProps = {
		selected: {},
		saveQueryOnUnmount: false,
		placeholder: 'Введите текст',
	}

	state = {
		query: this.props.saveQueryOnUnmount ? this.props.query : this.props.parentQuery || '',
		active: false,
		showOptions: false,
		prevOptionIndex: -1,
	}

	componentDidUpdate(prevProps) {
		const { options, selected, parentQuery } = this.props

		// Условия отображения опций
		if (!prevProps.options.length && options.length) {
			this.setState({ showOptions: true })
		} else if (prevProps.options.length && !options.length) {
			this.setState({ showOptions: false })
		}

		if (
			(utils.hasObjectLength(prevProps.selected) && !utils.hasObjectLength(selected)) ||
			(prevProps.parentQuery && !parentQuery)
		) {
			this.setState({ prevOptionIndex: -1 })
			this.changeQuery('')
		}
	}

	handleClickOutside() {
		const { active } = this.state

		if (active) this.handleDeactivate()
	}

	changeQuery = (query) => {
		const { saveQueryOnUnmount, setSearchField } = this.props

		return saveQueryOnUnmount
			? setSearchField({ field: 'query', value: query })
			: this.setState({ query })
	}

	handleActivate = () => {
		this.setState({ active: true })

		this.input.addEventListener('keydown', this.handleKeydown)
	}

	handleDeactivate = () => {
		this.setState({ active: false, prevOptionIndex: -1 })

		this.input.removeEventListener('keydown', this.handleKeydown)
	}

	handleKeydown = (e) => {
		const { options } = this.props
		const { active, showOptions, prevOptionIndex } = this.state

		const inc = options.length - 1 !== prevOptionIndex ? prevOptionIndex + 1 : 0
		const dec =
			0 !== prevOptionIndex && prevOptionIndex > 0 ? prevOptionIndex - 1 : options.length - 1

		if (active && showOptions) {
			if (e.keyCode === 40) {
				// кнопка вниз
				this.changeQuery(options[inc].label)
				this.setState({ prevOptionIndex: inc })
			}

			if (e.keyCode === 38) {
				// кнопка вверх
				this.changeQuery(options[dec].label)
				this.setState({ prevOptionIndex: dec })
			}

			if (e.keyCode === 13 && prevOptionIndex >= 0)
				this.handleSelectOption({
					// enter
					index: prevOptionIndex,
					...options[prevOptionIndex],
				})
		}
	}

	handleBlur = () => {
		const { onBlur } = this.props

		setTimeout(() => {
			if (onBlur) onBlur()
			this.handleDeactivate()
		}, 200)
	}

	handleFocus = ({ target: { value } }) => {
		const { onChange, selected, resetSearch, resetSearchWhileFocus } = this.props

		this.handleActivate()

		if (resetSearchWhileFocus) resetSearch()
		if ((value || '').length && !utils.hasObjectLength(selected)) onChange(value)
	}

	handleChange = (query) => {
		const { onChange, selected, onSelectOption, selectedIsRequired } = this.props

		if (utils.hasObjectLength(selected) && selectedIsRequired) onSelectOption({})

		this.handleActivate()
		this.setState({ prevOptionIndex: -1 })
		this.changeQuery(query)

		onChange(query)
	}

	handleSelectOption = (option) => {
		const { onSelectOption } = this.props
		this.changeQuery(option.label)

		onSelectOption(option, option.label)
		this.handleDeactivate()
	}

	handleReset = () => {
		const { query } = this.state
		const { onReset } = this.props

		onReset(query)
		this.changeQuery('')
	}

	renderOptionsInCategories() {
		const { prevOptionIndex } = this.state
		const { options, categories, selected } = this.props

		return categories.map(({ id, label }, index) => (
			<div className='autocomplete-category' key={index}>
				<div className='autocomplete-category__label'>{label}</div>
				{options.map(
					(item, index) =>
						item.$$category === id && (
							<Option
								{...item}
								key={index}
								item={item}
								index={index}
								prev={prevOptionIndex === index}
								active={selected?.index === index}
								onClick={this.handleSelectOption}
							/>
						)
				)}
			</div>
		))
	}

	render() {
		const { active, showOptions, prevOptionIndex } = this.state

		const {
			error,
			onReset,
			options,
			selected,
			disabled,
			className,
			categories,
			placeholder,
			replaceOptionsIfNotFound,
			saveQueryOnUnmount,
		} = this.props

		const queryValue = saveQueryOnUnmount ? this.props.query : this.state.query

		const inputOptions = {
			error,
			disabled,
			placeholder,
			value: queryValue,
			onBlur: this.handleBlur,
			onFocus: this.handleFocus,
			onChange: this.handleChange,
			getRef: (input) => (this.input = input),
			className: (queryValue || '').length ? 'autocomplete-closeicon-active' : '',
		}

		return (
			<div
				ref={(el) => (this.nodeElement = el)}
				className={cx('autocomplete', {
					active,
					disabled,
					[className]: !!className,
				})}
			>
				<Input {...inputOptions} />
				{active && showOptions ? (
					<div className='autocomplete-dropdown'>
						{utils.hasObjectLength(categories)
							? this.renderOptionsInCategories()
							: options.map((item, index) => (
									<Option
										{...item}
										key={index}
										item={item}
										index={index}
										prev={prevOptionIndex === index}
										active={selected.index === index}
										onClick={this.handleSelectOption}
									/>
							  ))}
					</div>
				) : (
					replaceOptionsIfNotFound &&
					active &&
					!!queryValue && <div className='autocomplete-dropdown'>{replaceOptionsIfNotFound}</div>
				)}
				{(queryValue || '').length > 0 && onReset && (
					<div onClick={this.handleReset} className='autocomplete-reset' />
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	query: state.search.query,
})

const mapDispatchToProps = {
	setSearchField,
	resetSearch,
}

export default connect(mapStateToProps, mapDispatchToProps)(outsideClick(Autocomplete))
