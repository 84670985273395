import type { Maybe } from 'types/util'

interface NewParticipant {
	id: string
	type: string
}

interface WithRoles {
	roles: string[]
}

interface WithPart {
	part: Maybe<number>
	startDate: Maybe<string>
	endDate: Maybe<string>
}

type Participant<T extends WithPart | WithRoles> = Record<string, any> & {
	id: string
	$$type: string
} & T

interface Parameters<T extends WithPart | WithRoles> {
	newParticipant: NewParticipant & T
	participants: Participant<T>[]
}

// TODO: убрать дублирование логики

export const transformParticipantsWithRolesToRequest = ({
	newParticipant,
	participants,
}: Parameters<WithRoles>) => {
	const participantIndex = participants.findIndex(({ id }) => id === newParticipant.id)

	return participantIndex >= 0
		? participants.map(({ id, roles, $$type, type }, index) =>
				index === participantIndex
					? {
							id,
							roles: roles.includes('APPLICANT')
								? ['APPLICANT', ...newParticipant.roles]
								: newParticipant.roles,
							type: newParticipant.type,
					  }
					: { id, roles, type: $$type || type }
		  )
		: [
				...participants.map(({ id, roles, $$type, type }) => ({ id, roles, type: $$type || type })),
				newParticipant,
		  ]
}

export const transformParticipantsWithPartToRequest = ({
	newParticipant,
	participants,
}: Parameters<WithPart>) => {
	const participantIndex = participants.findIndex(({ id }) => id === newParticipant.id)

	return participantIndex >= 0
		? participants.map(({ id, part, $$type, type, startDate, endDate }, index) =>
				index === participantIndex
					? { id, part: newParticipant.part, type: newParticipant.type, startDate, endDate }
					: { id, part, type: $$type || type, startDate, endDate }
		  )
		: [
				...participants.map(({ id, part, $$type, type, startDate, endDate }) => ({
					id,
					part,
					type: $$type || type,
					startDate,
					endDate,
				})),
				newParticipant,
		  ]
}
