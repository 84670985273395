import { event } from './event'
import { stage } from './stage'
import { status } from './status'
import { targetState } from './targetState'
import { transition } from './transition'

export const pipeline = {
	status,
	transition,
	targetState,
	event,
	stage,
}

export namespace Pipeline {
	export type Transition = ReturnType<typeof transition>
	export type Status = ReturnType<typeof status>
	export type Stage = ReturnType<typeof stage>
	export type TargetState = ReturnType<typeof targetState>
	export type Event = ReturnType<typeof event>
}
