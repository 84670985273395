import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { type PartnerDto } from 'converters/partner'
import { commonActions } from 'reducers/common'
import type { PayloadStatus, RootState } from 'types/redux'
import type { Maybe } from 'types/util'

const partnerAdapter = createEntityAdapter<PartnerDto.Brief>()

export const selectPartner = partnerAdapter.getSelectors((state: RootState) => state.partner.list)

export interface PartnerFilter {
	legalType: Maybe<PartnerDto.Brief['legalType'][]>
	statuses: Maybe<PartnerDto.Brief['verificationStatus'][]>
	managersIds: Maybe<string[]>
	query: Maybe<string>
}

const filter: PartnerFilter = {
	legalType: null,
	statuses: null,
	managersIds: null,
	query: null,
}

const initialState = partnerAdapter.getInitialState({
	status: 'pending' as PayloadStatus,
	filter,
})

const list = createSlice({
	name: 'partner/list',
	initialState,
	reducers: {
		setAll: partnerAdapter.setAll,
		setStatus: commonActions.setStatus,
		setFilter: commonActions.setFilter(),
		reset: commonActions.reset(initialState),
	},
})

export const { actions: partnerListReducerActions } = list
export default list.reducer
