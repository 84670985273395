import { Component } from 'react'
import cx from 'clsx'
import PropTypes from 'prop-types'

class Option extends Component {
	static propTypes = {
		id: PropTypes.any,
		info: PropTypes.string,
		prev: PropTypes.bool.isRequired,
		item: PropTypes.object.isRequired, // полностью весь обьект option
		active: PropTypes.bool.isRequired,
		label: PropTypes.string.isRequired,

		onClick: PropTypes.func.isRequired,
	}

	static defaultProps = {
		id: null,
		info: null,
	}

	handleClick = () => {
		const { onClick, item, index } = this.props

		onClick({ ...item, index })
	}

	render() {
		const { label, info, active, prev } = this.props

		return (
			<div className={cx('autocomplete-option', { active, prev })} onClick={this.handleClick}>
				<div className='autocomplete-option__label'>{label}</div>
				{info && <div className='autocomplete-option__info'>{info}</div>}
			</div>
		)
	}
}

export default Option
