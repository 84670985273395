import { DefaultValues } from 'helpers/convertHelper'

import { proceedings } from './proceedings'

export const responseDataFssp = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		proceedings: model.array(['proceedings']).map(proceedings),
		analytics: {
			debtAmount: model.null<number>(['analytics', 'debtAmount']),
			creditPaymentsDebtAmount: model.null<number>(['analytics', 'creditPaymentsDebtAmount']),
			taxesAndDutiesDebtAmount: model.null<number>(['analytics', 'taxesAndDutiesDebtAmount']),
			completedProceedingsCount: model.null<number>(['analytics', 'completedProceedingsCount']),
		},
	}
}
