import { Component, useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import Viewer from 'react-viewer'
import { changeImageIndex } from 'actions/filesViewer'
import cx from 'clsx'
import { useFetch } from 'components/hooks'
import PropTypes from 'prop-types'

import './ViewImages.scss'

class SrcErrorHandler extends Component {
	componentDidCatch() {
		//
	}

	static getDerivedStateFromError() {
		return
	}

	render() {
		return <>{this.props.children}</>
	}
}

function customToolbar({ actions, callbacks, addition, clsx, currentImage }) {
	const customActions = actions.map((action) => {
		let onClick = {}

		if (action.key !== 'rotateLeft' && action.key !== 'rotateRight' && action.key !== 'reset') {
			return action
		}

		if (action.key === 'rotateLeft') {
			onClick = {
				onClick: () => callbacks.handleRotate(addition.rotateDegree - 90),
			}
		} else if (action.key === 'rotateRight') {
			onClick = {
				onClick: () => callbacks.handleRotate(addition.rotateDegree + 90),
			}
		} else if (action.key === 'reset') {
			onClick = {
				onClick: () => callbacks.handleRotate(0),
			}
		}

		return {
			...action,
			...onClick,
		}
	})

	customActions.push({
		key: 'applyRotation',
		render: <i className={clsx.applyRotationClassName} />,
		onClick:
			!addition.isFetching && !addition.disabled && addition.rotateDegree % 360 !== 0
				? callbacks.handleRotationApply
				: null,
	})

	customActions.push({
		key: 'downloadFile',
		render: <i className='zmdi zmdi-download' />,
		onClick: !addition.isFetching && callbacks.handleDownloadImage,
	})

	customActions.push({
		key: 'deleteFile',
		render: <i className='zmdi zmdi-delete' />,
		onClick: !addition.isFetching && callbacks.handleDelete,
	})

	return customActions
}

const timeout = 0

const ViewImages = ({
	opened,
	images,
	index = 0,
	allowSaveRotation,
	rotateImage,
	downloadImage,
	deleteImage,
	entityId,
}) => {
	const [isRotationApplied, setRotateApply] = useState(false)
	const [rotateDegree, setRotateDegree] = useState(0)
	const [disabled, setDisable] = useState(true)

	const currentImage = images[index]

	const dispatch = useDispatch()

	useEffect(
		() => () => {
			clearTimeout(timeout)
		},
		[]
	)

	const handleRotate = useCallback((degree) => {
		setRotateDegree(degree)
		setRotateApply(false)

		if (degree % 360 === 0) {
			setDisable(true)
		} else {
			setDisable(false)
		}
	}, [])

	const getImageIndex = useCallback(
		(imageId) => images.findIndex((e) => e.id === imageId),
		[images]
	)

	const handleDownloadImage = useCallback(() => {
		downloadImage(index)
	}, [index, downloadImage])

	const { request: handleRotationApply, isFetching } = useFetch(
		() => rotateImage(index, currentImage.id, rotateDegree, entityId),
		{
			onSuccess: () => {
				const resetAction = document.querySelector('[data-key=reset]')
				if (resetAction) resetAction.click()

				setRotateApply(true)
				setRotateDegree(0)
			},
		}
	)

	const handleChangeImage = useCallback(
		(image) => {
			handleRotate(0)
			dispatch(changeImageIndex(getImageIndex(image.id)))
		},
		[handleRotate, dispatch, getImageIndex]
	)

	const applyRotationClassName = cx('zmdi', {
		disabled,
		'zmdi-spinner': isFetching,
		'zmdi-check': !isRotationApplied,
		'zmdi-check-all': isRotationApplied,
	})

	return (
		<SrcErrorHandler>
			<Viewer
				noClose
				images={images}
				scalable={false}
				visible={opened}
				activeIndex={index}
				onChange={handleChangeImage}
				customToolbar={(toolbar) =>
					allowSaveRotation
						? customToolbar({
								currentImage,
								actions: toolbar,
								callbacks: {
									handleRotate,
									handleRotationApply,
									handleDownloadImage,
									handleDelete: deleteImage,
								},
								clsx: {
									applyRotationClassName,
								},
								addition: {
									disabled,
									isFetching,
									rotateDegree,
								},
						  })
						: toolbar
				}
			/>
		</SrcErrorHandler>
	)
}

ViewImages.propTypes = {
	index: PropTypes.number,
	opened: PropTypes.bool.isRequired,
	images: PropTypes.array.isRequired,
	entityId: PropTypes.string.isRequired,

	rotateImage: PropTypes.func.isRequired,
	downloadImage: PropTypes.func.isRequired,
	allowSaveRotation: PropTypes.bool.isRequired,
	deleteImage: PropTypes.func.isRequired,
}

export default ViewImages
