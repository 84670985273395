import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Lead } from 'converters/lead'

type InitialState = {
	calc: Lead.CalculationV2[] | null
}

const initialState: InitialState = {
	calc: null,
}

const calculatorSchedule = createSlice({
	name: 'calculatorSchedule',
	initialState,
	reducers: {
		setCalc(state, { payload }: PayloadAction<any>) {
			state.calc = payload
		},
	},
})

export const { setCalc } = calculatorSchedule.actions
export default calculatorSchedule.reducer
