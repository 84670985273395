import PropTypes from 'prop-types'

import { Loading } from 'components/common'

import './FileInProgress.scss'

const FileInProgress = ({ uploadProgress, url }) => (
	<div className='files-inspector-files-bucket__progress-wrap'>
		<div className='files-inspector-files-bucket__progress'>
			{uploadProgress && uploadProgress === 100 ? (
				<div className='files-inspector-files-bucket__progress-wait'>
					<i className='zmdi zmdi-hourglass-alt' />
				</div>
			) : !url ? (
				<div className='files-inspector-files-bucket__progress-bar'>
					<div
						style={{ width: `${uploadProgress}%` }}
						className='files-inspector-files-bucket__progress-bar-status'
					/>
				</div>
			) : (
				<Loading min />
			)}
		</div>
	</div>
)

FileInProgress.propTypes = {
	url: PropTypes.string,
	uploadProgress: PropTypes.number,
}

export default FileInProgress
