import { DefaultValues } from 'helpers/convertHelper'

export const collection = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		collectionStage: model.string(['collectionStage']),
		overdueDays: model.null<number>(['overdueDays']),
		debtAmount: model.null<number>(['debtAmount']),
		unsecuredAmount: model.null<number>(['unsecuredAmount']),
		interestPeriods: {
			value: model.null<number>(['interestPeriods', 'value']),
			calculatedAt: model.string(['interestPeriods', 'calculatedAt']),
		},
		collectionStageHistory: model.array(['collectionStageHistory']).map((_, i) => ({
			stage: model.string(['collectionStageHistory', i, 'stage']),
			startedAt: model.string(['collectionStageHistory', i, 'startedAt']),
		})),
		ltv: {
			value: model.null<number>(['ltv', 'value']),
			calculatedAt: model.string(['ltv', 'calculatedAt']),
		},
		finDataModifiedAt: model.string(['finDataModifiedAt']),
	}
}
