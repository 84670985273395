import { isEmpty } from 'ramda'

export const findDocumentsToSign = ({
	aggregator,
	participants,
	contextId,
	selectedParticipant,
	contextType,
}) => {
	if (!selectedParticipant) {
		return {
			aggregator: [],
			participant: [],
		}
	}

	const aggregatorDocuments = aggregator.filter((item) => {
		if (!isEmpty(item.signatures)) {
			const hasSignature = item.signatures.find(
				(signature) =>
					selectedParticipant.customerId === signature.signer.subject.id &&
					selectedParticipant.customerType === signature.signer.subject.type &&
					contextId === signature.context.id &&
					contextType === signature.context.type
			)

			return !hasSignature
		}

		return true
	})

	const participant = participants.find(
		(participant) => participant.customerId === selectedParticipant.customerId
	)

	if (!participant) {
		return {
			aggregator: [],
			participant: [],
		}
	}

	const documentsWithoutContextSignature = participant.signableDocuments.filter((item) => {
		if (!isEmpty(item.signatures)) {
			const hasSignature = item.signatures.find(
				(signature) =>
					selectedParticipant.customerId === signature.signer.subject.id &&
					selectedParticipant.customerType === signature.signer.subject.type &&
					contextId === signature.context.id &&
					contextType === signature.context.type
			)

			return !hasSignature
		}

		return true
	})

	return {
		aggregator: aggregatorDocuments,
		participant: documentsWithoutContextSignature,
	}
}
