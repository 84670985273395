import { DefaultValues } from 'helpers/convertHelper'

export const productView = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		product: model.string<'APPLICATION'>(['product']),
		metaInfo: {
			APPLICATION: {
				documentId: model.string(['metaInfo', 'documentId']),
				agreementSignDate: model.string(['metaInfo', 'agreementSignDate']),
			},
		},
		plugged: model.null<boolean>(['plugged']),
	}
}
