import { createSelector } from '@reduxjs/toolkit'
import type { RootState } from 'types/redux'

const selectAllProducts = createSelector(
	[
		(state: RootState) => state.creditPolicyReport.main.entities.products,
		(state: RootState) => state.creditPolicyReport.main.ids,
	],
	(list, ids) => ids.map((id) => list[id])
)

const selectTitlePart = (state: RootState) => state.creditPolicyReport.main.titlePart

const selectRequiredActions = (state: RootState) =>
	state.creditPolicyReport.main.selection.policy
		? state.creditPolicyReport.main.entities.policies[
				state.creditPolicyReport.main.selection.policy
		  ].requiredActions
		: null

const selectPoliciesByProgram = (programId: string) =>
	createSelector(
		[
			(state: RootState) => state.creditPolicyReport.main.entities.programs[programId],
			(state: RootState) => state.creditPolicyReport.main.entities.policies,
		],
		(program, policies) => program.policies.map((policyId) => policies[policyId])
	)

const selectProgramById = (id: string) => (state: RootState) =>
	state.creditPolicyReport.main.entities.programs[id]

const selectByPolicyId = (id: string) => (state: RootState) =>
	state.creditPolicyReport.main.entities.policies[id]

const selectSelection = (state: RootState) => state.creditPolicyReport.main.selection

const selectCheckResults = (state: RootState) =>
	state.creditPolicyReport.main.selection.policy
		? state.creditPolicyReport.main.entities.policies[
				state.creditPolicyReport.main.selection.policy
		  ].checkResults
		: null

const selectConditions = (state: RootState) =>
	state.creditPolicyReport.main.selection.policy
		? state.creditPolicyReport.main.entities.policies[
				state.creditPolicyReport.main.selection.policy
		  ].conditions
		: null

const selectActiveCreditPolicies = createSelector(
	(state: RootState) => state.creditPolicyReport.main.entities.policies,
	(policies) =>
		Object.values(policies)
			.filter(({ checked }) => checked)
			.map(({ id }) => id)
)

const selectAllCreditPolicies = createSelector(
	(state: RootState) => state.creditPolicyReport.main.entities.policies,
	(policies) => Object.values(policies).map(({ id, title }) => [id, title]) as [string, string][]
)

const selectPoliciesForAdditionalRequests = createSelector(
	(state: RootState) => state.creditPolicyReport.main.entities.policies,
	(policies) => {
		return Object.entries(
			Object.values(policies).reduce((acc, curr) => {
				curr.checkResults.forEach(({ entity }) => {
					acc[`${entity.id}@${entity.type}`] = entity.title
				})

				return acc
			}, {} as Record<string, string>)
		) as [string, string][]
	}
)

const selectActivePoliciesForAdditionalRequests = createSelector(
	(state: RootState) => state.creditPolicyReport.main.entities.policies,
	(policies) => {
		return Object.entries(
			Object.values(policies)
				.filter(({ checked }) => checked)
				.reduce((acc, curr) => {
					acc[curr.id] = curr.title

					return acc
				}, {} as Record<string, string>)
		) as [string, string][]
	}
)

export const creditPolictySelectors = {
	selectAllProducts,
	selectTitlePart,
	selectConditions,
	selectActivePoliciesForAdditionalRequests,
	selectCheckResults,
	selectPoliciesForAdditionalRequests,
	selectPoliciesByProgram,
	selectAllCreditPolicies,
	selectSelection,
	selectRequiredActions,
	selectProgramById,
	selectByPolicyId,
	selectActiveCreditPolicies,
}
