import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { commonActions } from 'reducers/common'

type State = {
	fullNameConflict: {
		inBase: Record<string, unknown>
		source: Record<string, unknown>
		optionals: Record<string, unknown>
	}
	incompleteData: Array<unknown>

	[key: string]: unknown
}

const initialState: State = {
	fullNameConflict: {
		inBase: {},
		source: {},
		optionals: {},
	},
	incompleteData: [],
}

const interceptor = createSlice({
	name: 'interceptor',
	initialState,
	reducers: {
		setData(
			state,
			{ payload: { data, interceptor } }: PayloadAction<{ interceptor: keyof State; data: any }>
		) {
			state[interceptor] = data
		},

		reset: commonActions.reset(initialState),
	},
})

const { actions } = interceptor
export { actions }
export default interceptor.reducer
