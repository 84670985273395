import { DefaultValues } from 'helpers/convertHelper'

export const upcomingPayment = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		amount: model.null<number>(['amount']),
		date: model.string(['date']),
	}
}
