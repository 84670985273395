import { DefaultValues } from 'helpers/convertHelper'

import { component } from './component'

export const parameter = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
		value: model.string(['value']),
		components: model.array(['components']).map(component),
	}
}
