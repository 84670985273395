import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { commonActions } from 'reducers/common'
import type { Maybe } from 'types/util'

interface State {
	data: Record<string, any>[]
	tooltip: Record<string, any>
	fetching: boolean
	currentDateRange: {
		start?: Maybe<Date>
		end?: Maybe<Date>
	}
}

const initialState: State = {
	data: [],
	tooltip: {},
	fetching: false,
	currentDateRange: {
		start: null,
		end: null,
	},
}

const events = createSlice({
	name: 'calendar/events',
	initialState,
	reducers: {
		setList(state, { payload }: PayloadAction<State['data']>) {
			state.data = payload
		},

		deleteSingle(state, { payload }: PayloadAction<string>) {
			state.data = state.data.filter(({ id }) => id !== payload)
		},

		updateSingle(state, { payload }: PayloadAction<Record<string, any>>) {
			const i = state.data.findIndex(({ id }) => id === payload.id)

			state.data[i] = payload
		},

		setCompletedStatus(
			state,
			{ payload: { eventId, status } }: PayloadAction<{ eventId: string; status: boolean }>
		) {
			const i = state.data.findIndex((el) => el.id === eventId)

			state.data[i] = { ...state.data[i], $$customParams: { isCompleted: status } }
		},

		deleteAllInstances(state, { payload }: PayloadAction<string>) {
			state.data = state.data.filter((event) => event.seriesMasterId !== payload)
		},

		updateList(state, { payload }: PayloadAction<Record<string, any>[]>) {
			state.data = [...state.data, ...payload]
		},

		setTooltip(state, { payload }: PayloadAction<Record<string, unknown>>) {
			state.tooltip = payload
		},

		setDateRange(state, { payload }: PayloadAction<State['currentDateRange']>) {
			state.currentDateRange = payload
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions } = events
export default events.reducer
