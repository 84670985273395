import axios from 'axios'
import type { Refinancing } from 'converters/refinancing'
import { refinancing } from 'converters/refinancing'

const baseUrl = '/v4/scoring'

declare namespace RefinancingApi {
	export type Get = Refinancing.Main
	export type Update = Refinancing.Main
}

interface EntityParameters {
	entityId: string
	entity: 'applications' | 'leads'
}

export default {
	refinance: ({ entityId, entity }: EntityParameters) =>
		axios.get<RefinancingApi.Get>(`${baseUrl}/${entity}/${entityId}/refinancing`).then((response) =>
			Object.assign({}, response, {
				data: refinancing.main(response.data),
			})
		),

	update: ({
		entityId,
		entity,
		refinancingId,
		obligationId,
		payload,
	}: EntityParameters & {
		refinancingId: string
		obligationId: string
		payload: { fulfilled?: boolean; included?: boolean }
	}) =>
		axios
			.patch<RefinancingApi.Update>(
				`${baseUrl}/${entity}/${entityId}/refinancing/${refinancingId}/obligations/${encodeURIComponent(
					obligationId
				)}`,
				payload,
				{ headers: { 'Content-Type': 'application/merge-patch+json' } }
			)
			.then((response) =>
				Object.assign({}, response, {
					data: refinancing.main(response.data),
				})
			),
}
