import {
	Button,
	Input,
	Select,
	Text,
	useAutocomplete,
	type ButtonProps,
	type InputProps,
	type SelectOption,
	type SelectProps,
} from '@creditclubteam/kit/ui-components'
import { Grid } from 'components/common/Grid'
import {
	ACTUAL_VALUE_OPTIONS,
	RISK,
	type RISK_KEYS,
	STOP_FACTOR_MEETING_OPTIONS,
	getMappedType,
	useFormCtx,
} from './helpers'
import type { CreditPolicyReport } from 'converters/creditPolicyReport'
import { TextareaV2, type TextareaV2Props } from 'components/common/TextareaV2'
import { defaultOr } from '@creditclubteam/kit/helpers'
import { Icon } from '@iconify/react/dist/iconify.js'
import type { FieldsProps } from './Fields'
import { memo, useCallback } from 'react'
import { useTheme } from 'styled-components'
import { useField, useFormikContext } from 'formik'
import type { FormValues } from './Form'
import type { Nullable } from 'ts-toolbelt/out/Union/Nullable'
import { useSelector } from 'react-redux'
import { creditPolictySelectors } from 'reducers/creditPolicyReport/selectors'
import { flatten, includes, update } from 'rambda'

interface ValuesProps {
	data: CreditPolicyReport.AdditionalRequest
	remove: (idx: number) => void
}

export interface FieldInputProps extends Pick<FieldsProps, 'type'> {
	value: string
	index: number
}

const getIdx = (list: CreditPolicyReport.AdditionalRequest[], id: string) =>
	list.findIndex((v) => v.id === id)

const FieldInput = memo(({ type, index, value }: FieldInputProps) => {
	const name = `${getMappedType(type)}[${index}].value`
	const [{ onBlur, onChange }, { error, touched }, { setValue }] = useField(name)

	const props: InputProps = {
		label: 'Введите текст',
		hideLabelOnActive: true,
		name,
		onBlur,
		onChange,
		value,
		scale: 'min',
		error: touched && !!error,
		autocompleteConfig: useAutocomplete<string[], string>({
			request: {
				url: '/v4/scoring/additional-requests/messages',
				method: 'GET',
				params: {
					type,
					filter: value,
				},
			},
			getOptionItem: useCallback((payload) => ({ id: payload, label: payload }), []),
			onSelect: useCallback(({ id }) => setValue(id, true), [setValue]),
			query: value.length >= 3 ? value : '',
		}),
	}

	return <Input {...props} />
})

type GetOptionsParameters = {
	acc: SelectOption[]
	policyId: string
	label: string
}

const useCreditPolicyOptions = ({
	creditPolicyIds,
	id,
	type,
	evaluationEntity,
}: Pick<
	CreditPolicyReport.AdditionalRequest,
	'creditPolicyIds' | 'id' | 'type' | 'evaluationEntity'
>): SelectOption[] => {
	const { values } = useFormikContext<FormValues>()
	const allPolicies = useSelector(creditPolictySelectors.selectAllCreditPolicies)
	const activePolicies = useSelector(
		creditPolictySelectors.selectActivePoliciesForAdditionalRequests
	)

	const getActualValueOrStopFactorOptions = ({ acc, policyId, label }: GetOptionsParameters) => {
		const valuesByEntity = values[getMappedType(type)].filter(
			(v) => v.evaluationEntity.id === evaluationEntity.id && v.type === type
		)
		const allSelectedCreditPolicyIds = flatten<string>(
			valuesByEntity.map(({ creditPolicyIds }) => creditPolicyIds)
		)

		if (!allSelectedCreditPolicyIds.length || creditPolicyIds.includes(policyId)) {
			acc.push({ id: policyId, label })
			return acc
		}

		if (
			valuesByEntity
				.filter((v) => v.id !== id)
				.every(({ creditPolicyIds }) => !creditPolicyIds.includes(policyId))
		) {
			acc.push({ id: policyId, label })
		}

		return acc
	}

	const getRestOptions = ({
		acc,
		policyId,
		label,
		targetValue,
	}: GetOptionsParameters & { targetValue: keyof typeof RISK }) => {
		const [typeIdx] = type.match(/\d+/) ?? []

		const availableCreditPoliciesIds = flatten<string>(
			values.actualValue
				.filter(
					(v) =>
						evaluationEntity.id === v.evaluationEntity.id &&
						(typeIdx ? v.type === `ACTUAL_VALUE_${typeIdx}` : v.type === 'ACTUAL_VALUE') &&
						v.value === targetValue
				)
				.map(({ creditPolicyIds }) => creditPolicyIds)
		)

		if (availableCreditPoliciesIds.includes(policyId)) acc.push({ id: policyId, label })

		return acc
	}

	return allPolicies.reduce((acc, [policyId, label]) => {
		const isActive = activePolicies.some(([activePolicyId]) => activePolicyId === policyId)

		if (!isActive) {
			if (creditPolicyIds.includes(policyId)) acc.push({ id: policyId, label, disabled: true })
			return acc
		}

		if (includes(type, ['ACTUAL_VALUE', 'ACTUAL_VALUE_2', 'STOP_FACTOR_MEETING'] as const)) {
			return getActualValueOrStopFactorOptions({ acc, policyId, label })
		}

		if (includes(type, ['REQUIRED_ACTION', 'REQUIRED_ACTION_2'] as const)) {
			return getRestOptions({ acc, policyId, label, targetValue: 'NO_DATA' })
		}

		if (includes(type, ['CONDITION', 'CONDITION_2'] as const)) {
			return getRestOptions({ acc, policyId, label, targetValue: 'FOUND' })
		}

		return acc
	}, [] as SelectOption[])
}

export const Values = memo(
	({
		data: { id, type, value, comment, creditPolicyIds, evaluationEntity },
		remove,
	}: ValuesProps) => {
		const formik = useFormikContext<FormValues>()
		const mappedType = getMappedType(type)
		const theme = useTheme()
		const allPolicies = useSelector(creditPolictySelectors.selectAllCreditPolicies)
		const { isEditing, commentOptions } = useFormCtx()
		const isActualOrStopFactor = includes(type, [
			'ACTUAL_VALUE',
			'ACTUAL_VALUE_2',
			'STOP_FACTOR_MEETING',
		] as const)
		const idx = getIdx(formik.values[mappedType], id)
		const path = `${mappedType}[${idx}]`
		const options = useCreditPolicyOptions({
			creditPolicyIds,
			id,
			type,
			evaluationEntity,
		})
		// @ts-expect-error
		const errors: Nullable<Record<keyof (typeof filteredValuesByEntity)[number], string>> =
			formik.errors![mappedType]?.[idx]
		// @ts-expect-error
		const touched: Nullable<Record<keyof (typeof filteredValuesByEntity)[number], boolean>> =
			formik.touched![mappedType]?.[idx]

		const actualOrStopFactorSelectProps: SelectProps<false, string> = {
			hideLabelOnActive: true,
			label: 'Выберите значения',
			error: touched?.value && !!errors?.value,
			name: `${path}.value`,
			onBlur: formik.handleBlur,
			scale: 'min',
			onChange: ({ value }) => {
				if (!includes(type, ['ACTUAL_VALUE', 'ACTUAL_VALUE_2'] as const)) {
					return formik.setFieldValue(`${path}.value`, value, true)
				}

				const updatedActualValue = update(
					idx,
					{ ...formik.values.actualValue[idx], value: value as string },
					formik.values.actualValue
				)

				const [typeIdx] = type.match(/\d+/) ?? []

				const getValues = ({
					acc,
					curr,
					type,
					matchValue,
				}: {
					acc: CreditPolicyReport.AdditionalRequest[]
					curr: CreditPolicyReport.AdditionalRequest
					type: CreditPolicyReport.AdditionalRequest['type']
					matchValue: keyof typeof RISK_KEYS
				}) => {
					if (curr.evaluationEntity.id !== evaluationEntity.id || curr.type !== type) {
						acc.push(curr)
						return acc
					}

					const getFilteredValues = (targetType: (typeof type)[]) =>
						updatedActualValue.filter(
							(v) =>
								v.evaluationEntity.id === evaluationEntity.id &&
								includes(v.type, targetType) &&
								v.value === matchValue
						)

					const actualValueCPIds = flatten<string>(
						getFilteredValues([typeIdx ? `ACTUAL_VALUE_2` : 'ACTUAL_VALUE']).map(
							({ creditPolicyIds }) => creditPolicyIds
						)
					)

					const newCreditPoliciesIds = curr.creditPolicyIds.reduce((newCPAcc, id) => {
						if (actualValueCPIds.includes(id)) newCPAcc.push(id)
						return newCPAcc
					}, [] as typeof curr.creditPolicyIds)

					acc.push({ ...curr, creditPolicyIds: newCreditPoliciesIds })

					return acc
				}

				return formik.setValues(
					({ conditions, requiredActions, ...rest }) => ({
						...rest,
						actualValue: updatedActualValue,
						requiredActions: requiredActions.reduce(
							(acc, curr) =>
								getValues({
									acc,
									curr,
									type: typeIdx ? 'REQUIRED_ACTION_2' : 'REQUIRED_ACTION',
									matchValue: 'NO_DATA',
								}),
							[] as FormValues['conditions']
						),
						conditions: conditions.reduce(
							(acc, curr) =>
								getValues({
									acc,
									curr,
									type: typeIdx ? 'CONDITION_2' : 'CONDITION',
									matchValue: 'FOUND',
								}),
							[] as FormValues['conditions']
						),
					}),
					true
				)
			},
			value,
			options: type.includes('ACTUAL_VALUE') ? ACTUAL_VALUE_OPTIONS : STOP_FACTOR_MEETING_OPTIONS,
		}

		const creditPolicyIdsSelectProps: SelectProps<true, string> = {
			hideLabelOnActive: true,
			multi: true,
			hideOptionsAfterSelect: false,
			label: 'Выберите кредитную политику',
			error: touched?.creditPolicyIds && !!errors?.creditPolicyIds,
			name: `${path}.creditPolicyIds`,
			onBlur: formik.handleBlur,
			scale: 'min',
			disabled: !options.length,
			onChange: useCallback(
				({ value }) => formik.setFieldValue(`${path}.creditPolicyIds`, value, true),
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[path]
			),
			value: creditPolicyIds,
			options,
		}

		const textareaProps: TextareaV2Props = {
			value: comment,
			error: touched?.comment && !!errors?.comment,
			onBlur: formik.handleBlur,
			name: `${path}.comment`,
			placeholder: 'Текст комментария',
			resize: 'vertical',
			onChange: useCallback(
				({ target: { value: v } }) => formik.setFieldValue(`${path}.comment`, v),
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[path]
			),
		}

		const commentProps: SelectProps<true, string> = {
			label: 'Выберите комментарий',
			multi: true,
			hideLabelOnActive: true,
			onBlur: formik.handleBlur,
			name: `${path}.comment`,
			error: touched?.comment && !!errors?.comment,
			scale: 'min',
			disabled: !options.length,
			hideOptionsAfterSelect: false,
			onChange: useCallback(
				({ value: v }) => formik.setFieldValue(`${path}.comment`, v.join('\n'), true),
				// eslint-disable-next-line react-hooks/exhaustive-deps
				[path]
			),
			value: comment.split('\n').filter(Boolean),
			options: commentOptions,
		}

		const removeProps: ButtonProps = {
			type: 'button',
			size: 'inline',
			styleOverride: { paddingTop: 6, paddingBottom: 0, height: 'fit-content' },
			variant: 'transparent-blue',
			onClick: useCallback(() => remove(idx), [idx, remove]),
		}

		return (
			<Grid.Row sizes={isEditing ? '1fr auto' : '1fr'} gap='s'>
				{isEditing ? (
					<Grid.Section gap='s'>
						<Grid.Row gap='l'>
							{isActualOrStopFactor ? (
								<Select {...actualOrStopFactorSelectProps} />
							) : (
								<FieldInput index={idx} type={type} value={value} />
							)}
							<Select {...creditPolicyIdsSelectProps} />
						</Grid.Row>
						{isActualOrStopFactor && (
							<Grid.Row gap='l' sizes='1fr 1fr'>
								{type.includes('ACTUAL_VALUE') && <TextareaV2 {...textareaProps} />}
								{type === 'STOP_FACTOR_MEETING' && <Select {...commentProps} />}
							</Grid.Row>
						)}
					</Grid.Section>
				) : (
					<Grid.Row gap='l'>
						<Grid.Section gap='l' style={{ height: 'min-content' }}>
							<Text as='div'>
								{defaultOr(isActualOrStopFactor ? RISK[value as keyof typeof RISK] : value)}
							</Text>
							{comment && (
								<Text as='div' styleOverride={{ marginLeft: theme.spaces.l }}>
									{comment}
								</Text>
							)}
						</Grid.Section>
						<Grid.Section gap='l' style={{ height: 'min-content' }}>
							{creditPolicyIds.map((v) => {
								const [, title] = allPolicies.find(([id]) => id === v) ?? []

								return title ? (
									<Text key={title} as='div'>
										{title}
									</Text>
								) : null
							})}
						</Grid.Section>
					</Grid.Row>
				)}
				{isEditing && (
					<Button {...removeProps}>
						<Icon icon='zmdi:minus-circle' />
					</Button>
				)}
			</Grid.Row>
		)
	}
)
