import { Component } from 'react'
import Modal from 'react-modal'
import cx from 'clsx'
import PropTypes from 'prop-types'

import './ModalDialog.scss'

class ModalDialog extends Component {
	static propTypes = {
		small: PropTypes.bool,
		large: PropTypes.bool,
		children: PropTypes.any,
		className: PropTypes.string,
		portalClassName: PropTypes.string,
		opened: PropTypes.bool.isRequired,
		title: PropTypes.string,
		subTitle: PropTypes.string,
		overlayClassName: PropTypes.string,

		onOpen: PropTypes.func,
		onAfterClose: PropTypes.func,
		onClose: PropTypes.func,
	}

	static defaultProps = {
		onClose: () => ({}),
	}

	state = { show: false }

	componentDidMount() {
		const { opened } = this.props

		if (opened) {
			// lol
			setTimeout(
				() =>
					this.setState({ show: opened }, () => {
						document.body.style.overflow = 'hidden'
					}),
				5
			)
		}
	}

	componentDidUpdate(prevProps) {
		const { opened } = this.props

		if (prevProps.opened !== opened) {
			setTimeout(
				() =>
					this.setState({ show: opened }, () => {
						if (!opened) {
							document.body.style.overflow = ''
						} else {
							document.body.style.overflow = 'hidden'
						}
					}),
				5
			)
		}
	}

	componentWillUnmount() {
		document.body.style.overflow = ''
	}

	render() {
		const {
			small,
			large,
			title,
			opened,
			onOpen,
			onClose,
			children,
			subTitle,
			className,
			onAfterClose,
			portalClassName,
			overlayClassName,
		} = this.props
		const { show } = this.state

		if (!opened) return null

		const modalClassName = cx('modal-dialog-content', {
			[className]: !!className,
			small,
			large,
		})

		const modalPortalClassName = cx('modal-dialog-portal', {
			[portalClassName]: !!portalClassName && opened,
		})

		const modalOverlayClassName = cx('modal-dialog-overlay', {
			[overlayClassName]: !!overlayClassName,
			show,
		})

		const modalOptions = {
			ariaHideApp: false,
			onAfterOpen: onOpen,
			isOpen: show || opened,
			onRequestClose: onClose,
			onAfterClose,
			className: modalClassName,
			portalClassName: modalPortalClassName,
			overlayClassName: modalOverlayClassName,
		}

		return (
			<Modal {...modalOptions}>
				<div className='modal-dialog-cross' onClick={onClose}>
					<i className='zmdi zmdi-close zmdi-hc-lg' />
				</div>
				{title && <div className='modal-dialog-title'>{title}</div>}
				{subTitle && <div className='modal-dialog-subtitle'>{subTitle}</div>}
				{children}
			</Modal>
		)
	}
}

export default ModalDialog
