import { DefaultValues } from 'helpers/convertHelper'

export const smsBrand = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		senderName: model.string(['senderName']),
		verificationStatus: model.string<'NOT_STARTED' | 'VERIFYING' | 'VERIFIED' | 'FAILED'>([
			'verificationStatus',
		]),
	}
}
