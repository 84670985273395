import { Component } from 'react'
import classNames from 'clsx'
import PropTypes from 'prop-types'

import styles from './TextArea.module.scss'

class TextArea extends Component {
	static propTypes = {
		value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
		disabled: PropTypes.bool,
		resize: PropTypes.string,
		maxHeight: PropTypes.number,
		minHeight: PropTypes.number,
		className: PropTypes.string,
		placeholder: PropTypes.string,

		onBlur: PropTypes.func,
		onFocus: PropTypes.func,
		onChange: PropTypes.func.isRequired,
	}

	static defaultProps = {
		minHeight: 100,
	}

	state = { active: false }

	handleChange = (e) => {
		const { onChange } = this.props

		onChange(e.target.value, e)
	}

	handleFocus = (e) => {
		const { onFocus } = this.props

		this.setState({ active: true })
		if (onFocus) onFocus(e)
	}

	handleBlur = (e) => {
		const { onBlur } = this.props

		this.setState({ active: false })
		if (onBlur) onBlur(e)
	}

	render() {
		const { value, resize, disabled, onArrowClick, maxHeight, minHeight, className, placeholder } =
			this.props

		const { active } = this.state

		const classNameTextArea = classNames(styles.textarea, {
			[className]: !!className,
			[styles.active]: active,
		})

		const textAreaOptions = {
			value,
			disabled,
			placeholder,
			onBlur: this.handleBlur,
			onFocus: this.handleFocus,
			onChange: this.handleChange,
			style: {
				resize: resize ? [resize] : '',
				maxHeight: maxHeight ? maxHeight : '',
				minHeight: minHeight ? minHeight : '',
			},
		}

		// fix grammaly editor dom elem bug
		textAreaOptions['data-gramm_editor'] = false

		return (
			<div className={classNameTextArea}>
				<textarea {...textAreaOptions} />
			</div>
		)
	}
}

export default TextArea
