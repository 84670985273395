import { Component } from 'react'
import cx from 'clsx'
import PropTypes from 'prop-types'

import './TitleInput.scss'

class TitleInput extends Component {
	static propTypes = {
		id: PropTypes.any,
		error: PropTypes.any,
		value: PropTypes.any,
		type: PropTypes.string,
		children: PropTypes.any,
		className: PropTypes.string,
		name: PropTypes.string,
		placeholder: PropTypes.string,
		disabled: PropTypes.bool,
		title: PropTypes.any.isRequired,

		onBlur: PropTypes.func,
		onChange: PropTypes.func,
	}

	render() {
		const {
			id = '',
			title,
			type,
			className,
			value,
			placeholder,
			children,
			disabled,
			style,
			onChange,
			onBlur,
			error,
			name,
			required = false,
		} = this.props

		const classNames = cx('title-input', {
			[className]: !!className,
		})

		const inputClassName = cx('title-input__input', {
			'title-input__input_error': !!error,
		})

		const styleProps = {
			style: style ? style : {},
		}

		return (
			<div className={classNames} {...styleProps}>
				<div className='title-input__title'>
					{title}{' '}
					{typeof error === 'string' && error && <span className='title-input_error'>{error}</span>}
				</div>
				{children ? (
					children
				) : (
					<input
						id={id}
						name={name}
						onBlur={onBlur}
						required={required}
						disabled={disabled}
						onChange={onChange}
						value={value || ''}
						type={type || 'text'}
						className={inputClassName}
						placeholder={placeholder}
					/>
				)}
			</div>
		)
	}
}

export default TitleInput
