import { setPagination, solveParamsPagination } from 'actions/listParameters'
import { searchFacility, searchOrganization, searchPerson, setSearchResults } from 'actions/search'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import axios from 'axios'
import { ENTITY_TYPES } from 'const'
import { append, flatten, uniq } from 'ramda'
import { actions } from 'reducers/facility/list'
import { actions as organizationActions } from 'reducers/organization/list'
import { actions as personActions } from 'reducers/person/list'

import { utils } from 'helpers'

export const mergeFacilityListFilter = actions.mergeFilter
export const deleteFacility = actions.delete
export const resetFacilityList = actions.reset
export const setFacilityListFilter = actions.setFilter

/**
 * @description - Запрашивает обьекты и склеивает их с входным обьектом, далее записывает в стор
 * @param {array} minFacilities - Входной массив
 */
export const fetchFacilitiesWithMerge = (minFacilities) => (dispatch) => {
	if (!utils.hasObjectLength(minFacilities)) return
	dispatch(actions.setFetchingStatus(true))

	const requestParameters = {
		filter: {
			id: minFacilities,
		},
	}

	return api.facility
		.search(requestParameters)
		.then(({ data }) => {
			dispatch(actions.setData(data.content))
			dispatch(actions.setFetchingStatus(false))

			return data
		})
		.catch((error) => {
			dispatch(
				addServerError({
					text: 'Ошибка загрузки списка обьектов',
					details: utils.getDetailsFromError(error),
				})
			)

			dispatch(actions.setFetchingStatus(false))
		})
}

export const addFacilityToEntity = (request, throwError) => async (dispatch, getState) => {
	const onError = (error) => {
		dispatch(
			addServerError({
				text: 'Не удалось добавить объект',
				details: utils.getDetailsFromError(error),
			})
		)

		if (throwError) throw error
	}

	try {
		const isFacilityAdded = !!request.facility
		const facilities = getState().facility.list.data
		const participants = [...getState().person.list.data, ...getState().organization.list.data]

		const addFacilityToStore = (facility) => {
			dispatch(actions.add(facility))

			return facility
		}

		const { name, id } = request.entity

		const facilitiesIds = facilities.map(({ id }) => id)
		const owners = request?.facility?.owners || []
		const participantsIds = participants.map(({ id }) => id)

		const [participantsPayload, participantsToUpdate, participantsToAdd] = participants.reduce(
			([acc, update, add], { id, roles, $$type: type }) => {
				const getIds = (data) => data.map(({ id }) => id)

				owners.forEach((owner) => {
					if (owner.id === id && !getIds(acc).includes(owner.id)) {
						const rolesWithOwner = uniq([...roles, 'OWNER'])

						update.push(() =>
							dispatch(
								(type === ENTITY_TYPES.PERSON ? personActions : organizationActions).update({
									id,
									value: { roles: rolesWithOwner },
								})
							)
						)

						acc.push({ id, roles: rolesWithOwner, type })
					} else if (!participantsIds.includes(owner.id) && !getIds(acc).includes(owner.id)) {
						add.push(() => api[owner.$$type.toLowerCase()].get(owner.id))
						acc.push({ id: owner.id, roles: ['OWNER'], type: owner.type })
					}
				})

				if (!getIds(acc).includes(id)) acc.push({ id, roles, type })

				return [acc, update, add]
			},
			[[], [], []]
		)

		if (!participants.length) {
			participantsPayload.push(
				...owners.map((owner) => ({ id: owner.id, roles: ['OWNER'], type: owner.type }))
			)

			participantsToAdd.push(
				...owners.map((owner) => () => api[owner.$$type.toLowerCase()].get(owner.id))
			)
		}

		if (isFacilityAdded) {
			await api[name].update(id, {
				participants: participantsPayload,
				facilities: append(request.facility.id, facilitiesIds),
			})

			addFacilityToStore(request.facility)

			if (name.toUpperCase() === ENTITY_TYPES.APPLICATION) {
				participantsToUpdate.map((update) => update())

				const response = await axios
					.all(participantsToAdd.map((request) => request()))
					.then((response) => response.map(({ data }) => data))

				response.forEach((participant) =>
					dispatch(
						(participant.$$type === ENTITY_TYPES.PERSON ? personActions : organizationActions).add({
							...participant,
							roles: participantsPayload.find(({ id }) => id === participant.id).roles,
						})
					)
				)
			}
		} else {
			const { data } = await api.facility.create(request.requestData)

			await api[name].update(id, {
				facilities: append(data.id, facilitiesIds),
			})

			addFacilityToStore(data)
		}
	} catch (error) {
		onError(error)
	}
}

export const deleteFacilityFromEntity = (entity, facilityId) => async (dispatch, getState) => {
	try {
		await api[entity.name].update(entity.id, {
			facilities: getState()
				.facility.list.data.filter(({ id }) => facilityId !== id)
				.map(({ id }) => id),
		})

		dispatch(deleteFacility(facilityId))
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось удалить объект',
				details: utils.getDetailsFromError(error),
			})
		)

		throw error
	}
}

export const getFacilityList =
	(parameters = {}) =>
	(dispatch, getState) => {
		dispatch(actions.setFetchingStatus(true))

		const requestParameters = Object.assign(
			{},
			dispatch(solveParamsPagination('type', 'asc')),
			parameters,
			{ filter: getState().facility.list.filter }
		)

		return api.facility
			.search(requestParameters)
			.then(({ data }) => {
				dispatch(actions.setFetchingStatus(false))
				dispatch(actions.setData(data.content))

				return data
			})
			.then((data) => {
				const { totalElements, number, totalPages } = data

				dispatch(
					setPagination({
						totalPages,
						totalElements,
						paged: true,
						currentPage: number,
					})
				)

				return data
			})
			.catch((e) => {
				dispatch(actions.setFetchingStatus(false))

				dispatch(
					addServerError({
						text: 'Не удалось загрузить объекты',
						details: utils.getDetailsFromError(e),
					})
				)
			})
	}

export const searchFacilities = (query) => (dispatch) => {
	const requestOptions = {
		query,
		params: {
			size: 10,
			sort: 'desc',
			page: 0,
		},
	}

	return axios
		.all([
			dispatch(searchPerson(requestOptions)),
			dispatch(searchOrganization(requestOptions)),
			dispatch(searchFacility(requestOptions)),
		])
		.then((data) => {
			const participants = flatten([
				data[0] && data[0].content,
				data[1] && data[1].content,
				data[2] && data[2].content,
			]).filter(Boolean)

			dispatch(setSearchResults('internal', participants))
		})
}
