import { DefaultValues } from 'helpers/convertHelper'

export const loan = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		createdAt: model.string(['createdAt']),
		number: model.null<number>(['number']),
	}
}
