const HISTORY = {
	TYPES: {
		NEW_STATUS: {
			value: 'NEW_STATUS',
			label: 'Смена статуса',
		},
		WHATS_APP_MESSAGE: {
			value: 'WHATS_APP_MESSAGE',
			label: 'WhatsApp',
		},
		CALCULATION: {
			value: 'CALCULATION',
			label: 'Расчёт',
		},
		COMMENT: {
			value: 'COMMENT',
			label: 'Комментарий',
		},
		VOXIMPLANT_PHONE_CONVERSATION: {
			value: 'VOXIMPLANT_PHONE_CONVERSATION',
			label: 'Телефонный звонок',
		},
		BEELINE_PHONE_CONVERSATION: {
			value: 'BEELINE_PHONE_CONVERSATION',
			label: 'Звонки Билайн',
		},
	},

	SELECTORS: {
		HISTORY_BODY: 'history-body',
		HISTORY_DATE: 'historyDate',
		HISTORY_ADD_COMMENT_WRAPPER: 'historyAddCommentTextarea',
	},
}

export type HistoryType = typeof HISTORY

export default HISTORY
