import { CALCULATION } from 'const/calculations'

import { DefaultValues } from 'helpers/convertHelper'

export const accrual = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		name: model.string(['name']),
		maxPayments: model.null<number>(['maxPayments']),
		skipPayments: model.null<number>(['skipPayments']),
		overdue: model.null<boolean>(['overdue']),
		gracePeriodDays: model.null<number>(['gracePeriodDays']),
		type: model.const(data?.type, {
			MEMBERSHIP_FEE: 'MEMBERSHIP_FEE',
			COMMISSION: 'COMMISSION',
		} as const),
		typeRus: model.const(data?.type, {
			MEMBERSHIP_FEE: 'Начисление членских взносов',
			COMMISSION: 'Начисление комиссии',
		} as const),
		variant: model.const(data?.variant, {
			CONSTANT: 'CONSTANT',
			VARIABLE: 'VARIABLE',
		} as const),
		interestRate: {
			unit: model.const(data?.interestRate?.unit, CALCULATION.UNIT.ENG),
			unitRus: model.const(data?.interestRate?.unit, CALCULATION.UNIT.RUS),
			value: model.null<number>(['interestRate', 'value']),
		},
	}
}
