import { DefaultValues } from 'helpers/convertHelper'

export const brief = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		number: model.string(['number']),
		channel: model.string(['channel']),
		city: model.string(['city']),
		createdAt: model.string(['createdAt']),
		loanAmount: model.null<number>(['loanAmount']),
		borrower: model.string(['borrower']),
		brokerOrganizationName: model.string(['brokerOrganizationName']),
		status: model.string(['status']),
		collectionStage: model.string(['collectionStage']),
		interestPeriodsValue: model.null<number>(['interestPeriodsValue']),
		ltvValue: model.null<number>(['ltvValue']),
	}
}
