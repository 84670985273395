import { DefaultValues } from 'helpers/convertHelper'

export const preliminarySchedule = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		payloads: model.array(['payloads']).map((_, i) => ({
			date: model.string(['payloads', i, 'date']),
			number: model.null<number>(['payloads', i, 'number']),
			totalPaymentAmount: model.null<number>(['payloads', i, 'totalPaymentAmount']),
			mainDebtBalanceBeforeAmount: model.null<number>([
				'payloads',
				i,
				'mainDebtBalanceBeforeAmount',
			]),
			mainDebtPaymentAmount: model.null<number>(['payloads', i, 'mainDebtPaymentAmount']),
			percentPaymentAmount: model.null<number>(['payloads', i, 'percentPaymentAmount']),
			membershipFeesPaymentAmount: model.null<number>([
				'payloads',
				i,
				'membershipFeesPaymentAmount',
			]),
			mainDebtBalanceAmount: model.null<number>(['payloads', i, 'mainDebtBalanceAmount']),
		})),
	}
}
