import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Court } from 'converters/court'

type SetDataAction = PayloadAction<Array<Court.Main>>
type UpdateInGroupAction = PayloadAction<{
	id: string
	data: {
		courts?: Array<Court.Court>
		status?: Court.Main['status']
		efrsbNegative?: Court.Main['efrsbNegative']
		outOfCourtBankruptcy?: Court.Main['outOfCourtBankruptcy']
	}
}>

const initialState = {
	data: [] as Court.Main[],
	fetching: false,
}

const list = createSlice({
	name: 'court/list',
	initialState,
	reducers: {
		setData(state, { payload }: SetDataAction) {
			state.data = payload
		},

		updateInGroup(state, { payload: { id, data } }: UpdateInGroupAction) {
			const itemIndex = state.data.findIndex((item) => item.id === id)
			if (data.courts) state.data[itemIndex].courts = data.courts
			if (data.status) state.data[itemIndex].status = data.status
			if (data.efrsbNegative !== null && data.efrsbNegative !== undefined)
				state.data[itemIndex].efrsbNegative = data.efrsbNegative
			if (data.outOfCourtBankruptcy !== null && data.outOfCourtBankruptcy !== undefined)
				state.data[itemIndex].outOfCourtBankruptcy = data.outOfCourtBankruptcy
		},

		setFetchingStatus(state, actionPayload) {
			state.fetching = actionPayload.payload
		},

		reset(state) {
			state.data = []
		},
	},
})

export const { setData, reset, setFetchingStatus, updateInGroup } = list.actions
export default list.reducer
