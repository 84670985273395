import { DefaultValues } from 'helpers/convertHelper'

export const client = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		fullName: model.string(['fullName']),
		phone: model.string(['phone']),
	}
}
