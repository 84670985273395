import type { PayloadAction } from '@reduxjs/toolkit'
import { createSelector, createSlice } from '@reduxjs/toolkit'
import type { Loan } from 'converters/loan'
import { flatten } from 'ramda'
import { commonActions } from 'reducers/common'
import type { PayloadStatus, RootState } from 'types/redux'

export const selectCollectionEventsByTypes = createSelector(
	[
		(state: RootState) => state.loan.collectionEvents.data.events.entities,
		(state: RootState) => state.loan.collectionEvents.data.events.ids,
		(state: RootState) => state.loan.collectionEvents.data.types,
	],
	(entities, ids, types) => {
		const typesEntries = Object.entries(types)

		const [[aId] = [], [bId] = [], [cId] = [], [dId] = []] = [
			typesEntries.find(([, { title }]) => title === 'Требование о досрочном возврате'),
			typesEntries.find(([, { title }]) => title === 'Направление искового заявления в суд'),
			typesEntries.find(
				([, { title }]) =>
					title === 'Направление заявления о возбуждении исполнительного производства'
			),
			typesEntries.find(([, { title }]) =>
				[
					'Введение процедуры реструктуризации долгов',
					'Признание должника банкротом и введение процедуры реализации имущества',
				].includes(title)
			),
		]

		const flattenEntities = flatten(ids.map((id) => entities[id]))

		return [aId, bId, cId, dId].map(
			(id) => flattenEntities.find(({ eventTypeId }) => eventTypeId === id) ?? null
		)
	}
)

const initialState = {
	data: {
		events: {
			entities: {},
			ids: [],
			total: 0,
		},
		types: {},
	} as Loan.CollectionEvent,
	currentPage: 0,
	isEventsEndReached: false,
	categories: [] as Loan.EventCategories['categories'],
	status: 'pending' as PayloadStatus,
}

const events = createSlice({
	name: 'loan/collection/events',
	initialState,
	reducers: {
		addEvents: (
			state,
			{ payload: { data, page } }: PayloadAction<{ page: number; data: Loan.CollectionEvent }>
		) => {
			state.data.types = { ...state.data.types, ...data.types }

			state.data.events.total = state.data.events.total + data.events.total

			if (data.events.total < 20 || data.events.total === 0) {
				state.isEventsEndReached = true
			} else {
				state.isEventsEndReached = false
			}

			data.events.ids.forEach((id) => {
				if (state.data.events.entities[id]) {
					const sortedEventsByDate = [
						...data.events.entities[id],
						...state.data.events.entities[id],
					].sort(
						({ eventDate: a }, { eventDate: b }) => new Date(b).valueOf() - new Date(a).valueOf()
					)

					state.data.events.entities[id] = sortedEventsByDate
				} else {
					state.data.events.entities[id] = data.events.entities[id]
				}

				if (!state.data.events.ids.includes(id)) {
					state.data.events.ids.push(id)
					state.data.events.ids.sort((a, b) => new Date(b).valueOf() - new Date(a).valueOf())
				}

				state.currentPage = page
			})
		},

		setEvents: (state, { payload }: PayloadAction<Loan.CollectionEvent>) => {
			state.data = payload
		},

		setCategories: (state, { payload }: PayloadAction<Loan.EventCategories['categories']>) => {
			state.categories = payload
		},

		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions: loanCollectionEventsActions } = events
export default events.reducer
