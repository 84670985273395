import type { ComponentPropsWithoutRef } from 'react'
import PRODUCT_TYPES from 'const/productTypes'
import { omit } from 'ramda'
import type { Maybe } from 'types/util'

import { ChoiceBox } from 'components/common'

import styles from './ProductType.module.scss'

const defaultProductOptions = Object.keys(omit(['SAVING', 'DIRECT_INVESTMENT'], PRODUCT_TYPES)).map(
	(type) => ({
		value: type,
		label: (
			<div className={styles.item}>
				<i className={`zmdi ${PRODUCT_TYPES[type].zmdiIconClass}`} />
				<span>{PRODUCT_TYPES[type].title}</span>
			</div>
		),
	})
)

const productOptions = Object.keys(PRODUCT_TYPES).map((type) => ({
	value: type,
	label: (
		<div className={styles.item}>
			<i className={`zmdi ${PRODUCT_TYPES[type].zmdiIconClass}`} />
			<span>{PRODUCT_TYPES[type].title}</span>
		</div>
	),
}))

interface Props {
	value: Maybe<string>
	onSelect: (...args: any[]) => void
	isEditing: boolean
	[key: string]: unknown
}

const ProductType = (props: Props) => {
	const { value, onSelect, isEditing, ...restProps } = props

	const productTypeProps: ComponentPropsWithoutRef<typeof ChoiceBox> = {
		value,
		onSelect,
		options: isEditing ? defaultProductOptions : productOptions,
		...restProps,
	}

	return (
		<div className={styles.wrapper}>
			<ChoiceBox {...({} as any)} {...productTypeProps} />
		</div>
	)
}

export default ProductType
