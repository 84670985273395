import { DefaultPlayer } from 'react-html5video'
import PropTypes from 'prop-types'

import './Video.scss'
import 'react-html5video/dist/styles.css'

const Video = ({ url, caption }) => (
	<div className='history-whatsapp-video'>
		<DefaultPlayer loop controls={['PlayPause', 'Seek', 'Time', 'Volume', 'Fullscreen']}>
			<source src={url} />
		</DefaultPlayer>
		{caption && <div className='history-whatsapp-video__caption'>{caption}</div>}
	</div>
)

Video.propTypes = {
	caption: PropTypes.any,
	url: PropTypes.string,
}

export default Video
