import { normalize, schema } from 'normalizr'
import type { Object } from 'ts-toolbelt'

import { DefaultValues } from 'helpers/convertHelper'

import type { policy } from './policy'
import { product } from './product'
import type { program } from './program'

namespace CreditPolicyReportNormalized {
	export type Product = Object.Update<ReturnType<typeof product>, 'programs', string[]>
	export type Program = Object.Update<ReturnType<typeof program>, 'policies', string[]>
	export type Policy = ReturnType<typeof policy>
}

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	const { entities, result: ids } = normalize<
		void,
		{
			policies: Record<string, CreditPolicyReportNormalized.Policy>
			products: Record<string, CreditPolicyReportNormalized.Product>
			programs: Record<string, CreditPolicyReportNormalized.Program>
		},
		string[]
	>(model.array(['products']).map(product), [
		new schema.Entity(
			'products',
			{
				programs: [
					new schema.Entity(
						'programs',
						{
							policies: [new schema.Entity('policies', {})],
						},
						{ idAttribute: ({ title }) => title }
					),
				],
			},
			{
				idAttribute: ({ title }) => title,
			}
		),
	])

	return {
		entities,
		ids,
	}
}
