import type { ScoringDocuments } from 'converters/scoring/person/documents/types'
import type { TUtils } from 'types'

import { date, moneyUtils } from '@creditclubteam/helpers'
import { defaultToApi, DefaultValues } from 'helpers/convertHelper'

export const unconfirmed = <T extends Record<string, any>>(
	data: T,
	isTemplate?: boolean
): TUtils.Maybe<ScoringDocuments.SourceDocument> => {
	if (!isTemplate && !data) return null

	const model = new DefaultValues(data)

	const amount = model.null<number>(['income', 'amount'])
	const from = date.format(model.string(['income', 'period', 'from']), {
		from: 'yyyy-MM',
		to: 'MM.yyyy',
	})
	const to = date.format(model.string(['income', 'period', 'to']), {
		from: 'yyyy-MM',
		to: 'MM.yyyy',
	})

	return {
		id: 'unconfirmed',
		rows: [
			[
				{
					type: 'NUM',
					key: 'income.amount',
					readonlyValue: moneyUtils.RUB(amount),
					span: '1/3',
					value: amount,
					title: 'Среднемесячный доход',
				},
				{
					type: 'MASK',
					key: 'income.period.from',
					mask: '99.9999',
					transformValue: (value) =>
						defaultToApi(date.format(value, { from: 'MM.yyyy', to: 'yyyy-MM' })),
					value: from,
					title: 'Начало периода',
				},
				{
					type: 'MASK',
					key: 'income.period.to',
					mask: '99.9999',
					transformValue: (value) =>
						defaultToApi(date.format(value, { from: 'MM.yyyy', to: 'yyyy-MM' })),
					value: to,
					title: 'Конец периода',
				},
			],
		],
	}
}
