import type { EntityId } from 'api/types/EntityId'
import type { AxiosRequestConfig } from 'axios'
import axios from 'axios'
import CALENDAR from 'const/calendar'

let abortingXHR: any

export default {
	createEvent: (event: any) =>
		axios({
			data: event,
			method: 'POST',
			url: `/microsoft/calendar/events`,
			headers: {
				Prefer: `outlook.timezone="${CALENDAR.TIME_ZONE}"`,
			},
		}),

	createExtension: (eventId: EntityId, extension: unknown) =>
		axios({
			method: 'POST',
			data: extension,
			url: `/microsoft/events/${eventId}/extensions`,
		}),

	deleteEvent: (id: EntityId) =>
		axios({
			// or series events
			method: 'DELETE',
			url: `/microsoft/events/${id}`,
		}),

	getEvents: (startDate: unknown, endDate: unknown) => {
		if (abortingXHR != undefined) abortingXHR('Aborting xhr')

		let url = '/microsoft/calendarView?'
		url += `startDateTime=${startDate}&endDateTime=${endDate}`
		url += '&$top=5000'
		url += `&$expand=Extensions($filter=Id eq '${CALENDAR.EXTENSION_NAME}')`
		url += `&$select=${CALENDAR.EVENT_FIELDS.join(',')}`

		const requestParams = {
			url,
			method: 'GET',
			headers: {
				Prefer: `outlook.timezone="${CALENDAR.TIME_ZONE}"`,
			},
			cancelToken: new axios.CancelToken((c) => (abortingXHR = c)),
		}

		// @ts-expect-error
		return axios(requestParams)
	},

	getRepsInstances: (seriesMasterId: EntityId, startDate: unknown, endDate: unknown) => {
		if (abortingXHR != undefined) abortingXHR('Aborting xhr')

		let url = `/microsoft/events/${seriesMasterId}/instances?`
		url += `startDateTime=${startDate}&endDateTime=${endDate}`
		url += '&$top=5000'
		url += `&$expand=Extensions($filter=Id eq '${CALENDAR.EXTENSION_NAME}')`
		url += `&$select=${CALENDAR.EVENT_FIELDS.join(',')}`

		const requestParams: AxiosRequestConfig = {
			url,
			method: 'GET',
			headers: {
				Prefer: `outlook.timezone="${CALENDAR.TIME_ZONE}"`,
			},
			$$requestName: 'calendar.getRepsInstances',
		}

		return axios(requestParams)
	},

	getRecurrence: (eventId: EntityId) =>
		axios({
			method: 'GET',
			url: `/microsoft/calendar/events/${eventId}/?$select=recurrence`,
		}),

	updateEvent: (id: EntityId, newEvent: unknown) =>
		axios({
			data: newEvent,
			method: 'PATCH',
			url: `/microsoft/calendar/events/${id}`,
		}),

	updateExtension: (id: EntityId, newExtension: unknown) =>
		axios({
			method: 'PATCH',
			data: newExtension,
			url: `/microsoft/events/${id}/extensions/${CALENDAR.EXTENSION_NAME}`,
		}),
}
