import { Link } from 'react-router-dom'
import cx from 'clsx'
import PRODUCT_TYPES from 'const/productTypes'
import PropTypes from 'prop-types'

import { take } from '@creditclubteam/helpers'
import { defaultOr } from '@creditclubteam/kit/helpers'

import styles from './Item.module.scss'

const Item = ({ className, columns, linkTo, id, product, zmdiIconClass }) => {
	const desktopItemCx = cx(styles.desktop, {
		[className]: !!className,
	})

	return (
		<div className={styles.wrap}>
			<Link to={linkTo(id)} className={styles.link} />
			<div className={desktopItemCx}>
				{columns.map((item, index) => {
					const content = item?.content || item

					return (
						<div
							key={index}
							title={typeof content === 'string' ? content : ''}
							className={cx(index === 0 ? styles.withZmdi : styles.column, {
								[styles.center]: take(item, 'alignCenter'),
							})}
						>
							{index === 0 && (product ? PRODUCT_TYPES[product].zmdiIconClass : zmdiIconClass) && (
								<i
									className={`zmdi ${
										product ? PRODUCT_TYPES[product].zmdiIconClass : zmdiIconClass
									}`}
								/>
							)}
							{defaultOr(content)}
						</div>
					)
				})}
			</div>
		</div>
	)
}

Item.propTypes = {
	id: PropTypes.string.isRequired,
	columns: PropTypes.array.isRequired,
	className: PropTypes.string,
	product: PropTypes.string,
	zmdiIconClass: PropTypes.string,

	linkTo: PropTypes.func.isRequired,
}

export default Item
