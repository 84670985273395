import type { TUtils } from 'types'

export const USER_IDENTIFICATION = {
	UPRID: 'УПРИД',
	ESIA: 'ЕСИА',
	PASSPORT_SELFIE: 'Селфи с паспортом',
	VIDEO_CALL: 'Видеозвонок',
	PERSONAL: 'Личная встреча',
}

export const AVAILABLE_USER_IDENTIFICATION = {
	PASSPORT_SELFIE: 'Селфи с паспортом',
	VIDEO_CALL: 'Видеозвонок',
	PERSONAL: 'Личная встреча',
}

export const SOURCE = {
	OKB: 'ОКБ',
	NBCH: 'НБКИ',
} as const

const PERIOD = {
	ONE_MONTH: 'ONE_MONTH',
	TWO_MONTHS: 'TWO_MONTHS',
	MORE_THREE_MONTHS: 'MORE_THREE_MONTHS',
} as const

export const CREDIT_HISTORY = {
	SOURCE,
	PERIOD,
}

export type SubjectTypes = 'PERSON' | 'ORGANIZATION' | 'ENTREPRENEUR'

export type ExternalDataTypes =
	| 'AVG_CREDIT_PAYMENT'
	| 'FNS_TAX_ID_INVALIDITY'
	| 'UN_SECURITY_COUNCIL_LIST'
	| 'ILS_PFR'
	| 'CREDIT_HISTORY'
	| 'FSSP'
	| 'COURT'
	| 'PASSPORT_VALIDITY'
	| 'RFM_TERRORIST_LIST'
	| 'RFM_WEAPON_DISTRIBUTOR_LIST'
	| 'RFM_MONEY_BLOCKED_LIST'
	| 'CB_BLACK_LIST'
	| 'CC_BLACK_LIST'
	| 'AFFILIATED_ORGANIZATIONS'
	| 'SANCTIONS_LIST'

export interface ExternalDataFilter {
	id: TUtils.Maybe<string[]>
	type: TUtils.Maybe<ExternalDataTypes[]>
	status: TUtils.Maybe<string[]>
	createdAt: TUtils.Maybe<string>
	subject: {
		id: TUtils.Maybe<string>
		type: TUtils.Maybe<SubjectTypes>
	}
}

export interface RequestType {
	id: string
	title: string
	manualEdit: string
	subjectTypes: Array<SubjectTypes>
	availability: {
		available: boolean
		unavailabilityReason: string
	}
	metadata: Record<string, any>
}

export enum RequestStatus {
	CREATED = 'CREATED',
	COMPLETED = 'COMPLETED',
	ERROR = 'ERROR',
}

export const EXTERNAL_REQUEST_TYPE = {
	ILS_PFR: 'ILS_PFR',
	CREDIT_HISTORY: 'CREDIT_HISTORY',
	FSSP: 'FSSP',
	COURT: 'COURT',
	PASSPORT_VALIDITY: 'PASSPORT_VALIDITY',
	RFM_TERRORIST_LIST: 'RFM_TERRORIST_LIST',
	RFM_WEAPON_DISTRIBUTOR_LIST: 'RFM_WEAPON_DISTRIBUTOR_LIST',
	RFM_MONEY_BLOCKED_LIST: 'RFM_MONEY_BLOCKED_LIST',
	CB_BLACK_LIST: 'CB_BLACK_LIST',
	CC_BLACK_LIST: 'CC_BLACK_LIST',
	AVG_CREDIT_PAYMENT: 'AVG_CREDIT_PAYMENT',
	AFFILIATED_ORGANIZATIONS: 'AFFILIATED_ORGANIZATIONS',
	SANCTIONS_LIST: 'SANCTIONS_LIST',
	FNS_TAX_ID_INVALIDITY: 'FNS_TAX_ID_INVALIDITY',
	UN_SECURITY_COUNCIL_LIST: 'UN_SECURITY_COUNCIL_LIST',
} as const
