import { bonusAccount } from './bonusAccount'
import { contactPerson } from './contactPerson'
import { creditHistoryAgreement } from './creditHistoryAgreement'
import type { dependent } from './dependent'
import { form } from './form'
import { fullName } from './fullName'
import { identification } from './identification'
import { linkedEntity } from './linkedEntity'
import { main } from './main'
import { marriageInfo } from './marriageInfo'
import { passportHistory } from './passportHistory'
import { nationality } from './nationality'
import { passport } from './passport'
import { phoneNumber } from './phoneNumber'
import { publicAccount } from './publicAccount'
import { clientShare } from './clientShare'

export const person = {
	clientShare,
	main,
	form,
	creditHistoryAgreement,
	linkedEntity,
	publicAccount,
	passportHistory,
	nationality,
	passport,
	fullName,
	phoneNumber,
	bonusAccount,
	identification,
	contactPerson,
	marriageInfo,
}

export namespace Person {
	export type Main = ReturnType<typeof main>
	export type Form = ReturnType<typeof form>
	export type CreditHistoryAgreement = ReturnType<typeof creditHistoryAgreement>
	export type LinkedEntity = ReturnType<typeof linkedEntity>
	export type PublicAccount = ReturnType<typeof publicAccount>
	export type Passport = ReturnType<typeof passport>
	export type Nationality = ReturnType<typeof nationality>
	export type FullName = ReturnType<typeof fullName>
	export type PhoneNumber = ReturnType<typeof phoneNumber>
	export type Identification = ReturnType<typeof identification>
	export type ContactPerson = ReturnType<typeof contactPerson>
	export type PassportHistory = ReturnType<typeof passportHistory>
	export type MarriageInfo = ReturnType<typeof marriageInfo>
	export type Dependent = ReturnType<typeof dependent>
	export type BonusAccount = ReturnType<typeof bonusAccount>

	export namespace ClientShare {
		export type Main = ReturnType<typeof clientShare.main>
	}
}
