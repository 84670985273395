import { setModalShow } from 'actions/modalDialogs'
import { actions } from 'reducers/interceptor'
import type { RootState, TAction } from 'types/redux'

export const resetInterceptor = actions.reset

export const interceptor =
	(
		interceptor: keyof RootState['interceptor'],
		data: Parameters<typeof actions.setData>[0]['data']
	): TAction =>
	(dispatch, getState) => {
		const interceptors = getState().interceptor

		if (interceptors[interceptor]) {
			dispatch(actions.setData({ interceptor, data }))
			dispatch(setModalShow(true, interceptor as keyof RootState['modalDialogs']))
		} else {
			throw new Error('Interceptor is not found in store')
		}
	}
