import cx from 'clsx'
import PropTypes from 'prop-types'

import './Checkbox.scss'

const Checkbox = (props) => {
	const { customLabel, label, checked, onChange, className, disabled } = props

	const checkboxProps = {
		onClick: (event) => !disabled && onChange && onChange(!checked, event),
		className: cx('_cc__checkbox', className, {
			_cc__checkbox__disabled: disabled,
		}),
	}

	const boxClassName = cx({
		'zmdi zmdi-square-o': !checked,
		'zmdi zmdi-check-square': checked,
	})

	return (
		<div {...checkboxProps}>
			<i className={boxClassName} />
			{label ? <span>{label}</span> : customLabel ? customLabel : <span>—</span>}
		</div>
	)
}

Checkbox.defaultProps = {
	disabled: false,
}

Checkbox.propTypes = {
	customLabel: PropTypes.any,
	label: PropTypes.any,
	checked: PropTypes.bool,
	onChange: PropTypes.func,
	className: PropTypes.string,
	disabled: PropTypes.bool,
}

export default Checkbox
