import { DefaultValues } from 'helpers/convertHelper'

export const investor = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		type: model.string(['type']),
	}
}
