import {
	fetchCategories,
	fetchFiles,
	resetDocumentCategories,
	resetDocumentFiles,
} from 'actions/document'
import { searchExternalRequest } from 'actions/externalData'
import { resetHistory } from 'actions/history'
import { pushNotice } from 'actions/notices'
import { getPersonInvestmentClient } from 'actions/person/investmentClient'
import { resetSearch } from 'actions/search'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { PERSON_ERRORS_CODES } from 'const/errorsCodes'
import { EXTERNAL_REQUEST_TYPE } from 'const/externalData'
import { OWNER_TYPES } from 'const/ownerTypes'
import { PARTICIPANTS_TYPES, PERSON_USERNAME_POSTFIX } from 'const/participantsTypes'
import { resetExternalData } from 'reducers/externalData'
import { actions as incomesActions } from 'reducers/person/incomes'
import { investmentClientReducerActions } from 'reducers/person/investmentClient'
import { actions } from 'reducers/person/single'
import type { TAction } from 'types/redux'

import { notifications, utils } from 'helpers'
import { defaultToApi } from 'helpers/convertHelper'

import { getPersonIncomeGroups } from './incomes'
import { getPersonRepresentatives, resetPersonRepresentatives } from './representatives'
import { getPersonSpouses, resetPersonSpouses } from './spouses'
import type { FirstParameter } from 'types/util'
import { assoc } from 'rambda'

export const updateCertificateForPerson = actions.updateCertificate
export const deleteCertificateFormForPerson = actions.deleteCertificateForm

export const resetPerson = (): TAction => (dispatch) => {
	dispatch(actions.reset())
	dispatch(resetSearch(true))
	dispatch(resetPersonRepresentatives())
	dispatch(resetPersonSpouses())
	dispatch(resetHistory())
	dispatch(resetDocumentFiles())
	dispatch(resetDocumentCategories())
	dispatch(investmentClientReducerActions.reset())
	dispatch(incomesActions.reset())
	dispatch(resetExternalData())
}

export const makeReactivateRequest =
	(id: string): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			await api.person.certificateForm(id)

			dispatch(
				pushNotice({
					message: 'Шаг по перевыпуску подписи успешно выполнен',
					hideAfter: 5000,
				})
			)
		} catch (error: any) {
			dispatch(
				addServerError({
					details: utils.getDetailsFromError(error),
					test: error.message || '',
				})
			)
		}
	}

export const checkPersonLK =
	(phone: string): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			const { data } = await api.login.hasAccount(phone)

			dispatch(actions.setLK(data.enabled))
		} catch {
			//
		}
	}

export const getNationalities = (): TAction<Promise<void>> => async (dispatch) => {
	try {
		const { data } = await api.person.getNationalities()

		const russian = data.find(({ russian }) => russian)!

		dispatch(
			actions.setNationalities([
				russian,
				...data
					.filter((item) => !item.russian)
					.sort((a, b) => a.title.toLowerCase().localeCompare(b.title.toLowerCase())),
			])
		)
	} catch (error) {
		dispatch(
			addServerError({
				details: utils.getDetailsFromError(error),
				text: 'Не удалось загрузить национальности',
			})
		)
	}
}

export const getPersonSingle =
	(id: string): TAction<Promise<void>> =>
	async (dispatch) => {
		dispatch(actions.setFetchingStatus(true))

		try {
			const { data } = await api.person.get(id)

			dispatch(actions.setData(data))
			dispatch(checkPersonLK(data.phone))

			await dispatch(getPersonIncomeGroups(id))
			await dispatch(getNationalities())
			dispatch(getPersonQualifiedSpec({ personId: id }))

			await dispatch(fetchCategories([OWNER_TYPES.PERSON]))
			await dispatch(fetchFiles(id))
			dispatch(getPersonRepresentatives())
			dispatch(
				getIdentificationsOrgNames(data.identifications.map(({ organizationId }) => organizationId))
			)
			dispatch(getPersonInvestmentClient(id))
			dispatch(getPersonSpouses())

			await dispatch(
				searchExternalRequest({
					entityId: id,
					entityType: PARTICIPANTS_TYPES.PERSON,
					requestTypes: [EXTERNAL_REQUEST_TYPE.COURT],
					size: 50,
					dataBehavior: 'push',
				})
			)
			await dispatch(
				searchExternalRequest({
					entityId: id,
					entityType: PARTICIPANTS_TYPES.PERSON,
					requestTypes: [EXTERNAL_REQUEST_TYPE.FSSP],
					size: 50,
					dataBehavior: 'push',
				})
			)
			await dispatch(
				searchExternalRequest({
					entityId: id,
					entityType: PARTICIPANTS_TYPES.PERSON,
					requestTypes: [EXTERNAL_REQUEST_TYPE.CREDIT_HISTORY],
					size: 50,
					dataBehavior: 'push',
				})
			)
			await dispatch(
				searchExternalRequest({
					entityId: id,
					entityType: PARTICIPANTS_TYPES.PERSON,
					requestTypes: [EXTERNAL_REQUEST_TYPE.AVG_CREDIT_PAYMENT],
					size: 50,
					dataBehavior: 'push',
				})
			)
			dispatch(actions.setFetchingStatus(false))
		} catch (error: any) {
			dispatch(actions.setFetchingStatus(false))

			dispatch(
				addServerError({
					details: utils.getDetailsFromError(error),
					text: 'Не удалось загрузить данные человека',
				})
			)
		}
	}

export const changePersonalData =
	(id: string, form: Record<string, any>, shouldUpdateStore = true): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			const body = {
				name: form.name,
				surname: form.surname,
				patronymic: form.patronymic,
				phone: form.phone,
			}

			for (const prop in body) {
				const value = defaultToApi(body[prop as keyof typeof body])

				if (value && typeof value === 'string') {
					body[prop as keyof typeof body] = value.trim()
				}
			}

			await api.person.update(id, body)

			if (shouldUpdateStore) {
				try {
					const { data } = await api.person.get(id)

					dispatch(actions.setData(data))
				} catch {
					//
				}
			}
		} catch (error: any) {
			if ((error?.response?.data?.errorCode).includes(PERSON_ERRORS_CODES.DUPLICATE_PHONE)) {
				dispatch(
					addServerError({
						text: 'Человек с таким телефоном уже есть в базе',
					})
				)
			} else {
				dispatch(
					addServerError({
						text: 'Не удалось обновить данные человека',
						details: utils.getDetailsFromError(error),
					})
				)
			}

			throw error
		}
	}

export const updatePerson =
	(form: Record<string, any>): TAction<Promise<any>> =>
	async (dispatch, getState) => {
		const { id } = getState().person.single.data!

		try {
			await api.person.update(id, form)

			try {
				const { data } = await api.person.get(id)

				dispatch(actions.setData(data))
			} catch {
				//
			}
		} catch (error: any) {
			dispatch(
				addServerError({
					text: error?.response?.data?.errorCode.includes(PERSON_ERRORS_CODES.DUPLICATE_TAX_ID)
						? 'Человек с таким ИНН уже есть в базе'
						: 'Не удалось обновить анкету',
					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		}
	}

export const requestTaxId =
	(id?: string): TAction<Promise<void>> =>
	async (dispatch, getState) => {
		const personId = id || getState().person.single.data!.id

		try {
			await api.person.actions(personId, ['UPDATE_TAX_ID'])

			notifications.send('Человек добавлен в очередь на обновление')
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось заполнить ИНН для этой персоны',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const recognizePassport =
	({ documentId, entityId }: Record<string, any>): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			await api.person.actions(entityId, ['RECOGNIZE_DOCUMENT'], [{ type: 'PASSPORT', documentId }])

			dispatch(
				pushNotice({
					message: 'Запрос распознавания паспорта принят в работу',
					hideAfter: 5000,
				})
			)
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Ошибка при распознавании паспорта',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const restorePersonPassword =
	(phone: string): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			const username = `${phone}${PERSON_USERNAME_POSTFIX}`

			await api.person.restorePassword(username)

			dispatch(pushNotice({ message: 'Пароль успешно сброшен', hideAfter: 5000 }))
			dispatch(actions.setIsPasswordReseted(true))
		} catch (error: any) {
			const errorCode = error?.response?.data?.errorCode

			dispatch(
				addServerError({
					text:
						errorCode === 'no_such_element'
							? 'Не найден личный кабинет'
							: 'Не удалось сбросить пароль',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

interface sendGosusligiAuthLinkProps {
	id: string
	entityType: string
	entityId: string
	linkType: 'DIRECT' | 'WITH_INSTRUCTION'
	endpoint: 'leads' | 'applications'
}

export const sendGosusligiAuthLink =
	({
		id,
		entityType,
		entityId,
		linkType,
		endpoint,
	}: sendGosusligiAuthLinkProps): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			await api.person.sendGosusligiAuthLink(
				id,
				`${entityId}@${entityType}`,
				{ linkType },
				endpoint
			)
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось отправить ссылку',
					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		}
	}

export const getPersonQualifiedSpec =
	(payload: FirstParameter<typeof api.sign.getPersonQualifiedSpec>): TAction =>
	async (dispatch) => {
		try {
			const { data } = await api.sign.getPersonQualifiedSpec(payload)

			dispatch(
				actions.setSpec({
					qualifiedSignatureProvider: data.signatureProvider,
					application: data.application,
				})
			)
		} catch (error) {
			dispatch(
				addServerError({
					text: `Не удалось загрузить провайдера подписания`,
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const updatePersonQualifiedSpec =
	(payload: FirstParameter<typeof api.sign.updatePersonQualifiedSpec>): TAction =>
	async (dispatch) => {
		try {
			await api.sign.updatePersonQualifiedSpec(payload)

			dispatch(
				actions.setSpec({
					qualifiedSignatureProvider: payload.payload.signatureProvider,
					application: payload.payload.application,
				})
			)
		} catch (error) {
			dispatch(
				addServerError({
					text: `Не удалось обновить провайдера подписания`,
					details: utils.getDetailsFromError(error),
				})
			)
		}}

export const getIdentificationsOrgNames =
	(ids: string[]): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			if (!ids.length) return

			const { data } = await api.organization.search({ filter: { ids }, size: 100 })

			dispatch(
				actions.setIdentificationsOrgNames(
					data.content.reduce(
						(acc, { id, form: { shortName } }) => assoc(id, shortName, acc),
						{} as Record<string, string>
					)
				)
			)
		} catch {
			//
		}
	}
