const OPERATION_TYPES = {
	ENCUMBRANCE_IMPOSITION: 'ENCUMBRANCE_IMPOSITION',
	ENCUMBRANCE_CESSATION: 'ENCUMBRANCE_CESSATION',
	MORTGAGE: 'MORTGAGE',
	ADDITIONAL_PACKAGE: 'ADDITIONAL_PACKAGE',
	LOAN_MORTGAGE_ISSUE: 'LOAN_MORTGAGE_ISSUE',
	PRELIMINARY_MORTGAGE_ISSUE: 'PRELIMINARY_MORTGAGE_ISSUE',
	RESUME: 'RESUME',
	SUSPEND: 'SUSPEND',
	CANCEL: 'CANCEL',
} as const

const OPERATION_TYPES_RUS = {
	ENCUMBRANCE_IMPOSITION: 'Регистрация обременения',
	ENCUMBRANCE_CESSATION: 'Снятие обременения',
	MORTGAGE: 'Отправка закладной',
	ADDITIONAL_PACKAGE: 'Дополнительный пакет',
	LOAN_MORTGAGE_ISSUE: 'Выпуск закладной',
	PRELIMINARY_MORTGAGE_ISSUE: 'Выпуск предварительной закладной',
	RESUME: 'Возобновить',
	SUSPEND: 'Приостановить',
	CANCEL: 'Отменить',
} as const

const API = {
	CREDIT_CLUB: 'CREDIT_CLUB',
	KONTUR: 'KONTUR',
} as const

const STATE = {
	NEW: 'NEW',
	PROCESSING: 'PROCESSING',
	ERROR: 'ERROR',
	CANCELED: 'CANCELED',
	REGISTERED: 'REGISTERED',
} as const

const STATE_RUS = {
	NEW: 'Новая',
	PROCESSING: 'В работе',
	ERROR: 'Ошибка',
	REGISTERED: 'Зарегистрировано',
	CANCELED: 'Отменено',
} as const

const TYPE = {
	ENCUMBRANCE_IMPOSITION: 'ENCUMBRANCE_IMPOSITION',
	ENCUMBRANCE_IMPOSITION_WITH_MORTGAGE: 'ENCUMBRANCE_IMPOSITION_WITH_MORTGAGE',
	MORTGAGE: 'MORTGAGE',
	ENCUMBRANCE_CESSATION: 'ENCUMBRANCE_CESSATION',
} as const

const TYPE_RUS = {
	MORTGAGE: 'Закладная',
	ENCUMBRANCE_IMPOSITION_WITH_MORTGAGE: 'Регистрация с закладной',
	ENCUMBRANCE_IMPOSITION: 'Регистрация обременения',
	ENCUMBRANCE_CESSATION: 'Снятие обременения',
} as const

export interface RosreestrOperationTypeData {
	type: keyof typeof TYPE
	title: RosreestrRegistrationCategory
	operationTypes: Array<keyof typeof OPERATION_TYPES>
}

export type RosreestrRegistrationCategory =
	| 'Регистрация с закладной'
	| 'Регистрация обременения'
	| 'Снятие обременения'
	| 'Закладная'
	| 'Предварительная закладная'

interface CreationTypes {
	LOAN: Record<keyof typeof TYPE, RosreestrOperationTypeData>
	APPLICATION: Record<
		keyof Pick<typeof TYPE, 'ENCUMBRANCE_IMPOSITION_WITH_MORTGAGE'>,
		RosreestrOperationTypeData
	>
}

const CREATION_TYPES: CreationTypes = {
	LOAN: {
		ENCUMBRANCE_IMPOSITION_WITH_MORTGAGE: {
			title: 'Регистрация с закладной',
			type: TYPE.ENCUMBRANCE_IMPOSITION_WITH_MORTGAGE,
			operationTypes: [OPERATION_TYPES.LOAN_MORTGAGE_ISSUE, OPERATION_TYPES.ENCUMBRANCE_IMPOSITION],
		},
		ENCUMBRANCE_IMPOSITION: {
			title: 'Регистрация обременения',
			type: TYPE.ENCUMBRANCE_IMPOSITION,
			operationTypes: [OPERATION_TYPES.ENCUMBRANCE_IMPOSITION],
		},
		ENCUMBRANCE_CESSATION: {
			title: 'Снятие обременения',
			type: TYPE.ENCUMBRANCE_CESSATION,
			operationTypes: [OPERATION_TYPES.ENCUMBRANCE_CESSATION],
		},
		MORTGAGE: {
			title: 'Закладная',
			type: TYPE.MORTGAGE,
			operationTypes: [OPERATION_TYPES.LOAN_MORTGAGE_ISSUE, OPERATION_TYPES.MORTGAGE],
		},
	},
	APPLICATION: {
		ENCUMBRANCE_IMPOSITION_WITH_MORTGAGE: {
			title: 'Предварительная закладная',
			type: TYPE.ENCUMBRANCE_IMPOSITION_WITH_MORTGAGE,
			operationTypes: [
				OPERATION_TYPES.PRELIMINARY_MORTGAGE_ISSUE,
				OPERATION_TYPES.LOAN_MORTGAGE_ISSUE,
				OPERATION_TYPES.ENCUMBRANCE_IMPOSITION,
			],
		},
	},
}

const OWNER_TYPES = {
	ENCUMBRANCE_IMPOSITION: 'ROSREESTR_REGISTRATION_ENCUMBRANCE_IMPOSITION',
	ENCUMBRANCE_CESSATION: 'ROSREESTR_REGISTRATION_ENCUMBRANCE_CESSATION',
	LOAN_MORTGAGE_ISSUE: 'ROSREESTR_REGISTRATION_LOAN_MORTGAGE_ISSUE',
} as const

export const ROSREESTR_REGISTRATION = {
	OPERATION_TYPES,
	OPERATION_TYPES_RUS,
	OWNER_TYPES,
	API,
	CREATION_TYPES,
	STATE,
	STATE_RUS,
	TYPE,
	TYPE_RUS,
}
