import { DefaultValues } from 'helpers/convertHelper'

import { bankForm } from './bankForm'
import { contractInfo } from './contractInfo'
import { passport } from './passport'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		keycloakId: model.string(['keycloakId']),
		name: model.string(['name']),
		surname: model.string(['surname']),
		patronymic: model.string(['patronymic']),
		fullName: model.string(['fullName']),
		phone: model.string(['phone']),
		email: model.string(['email']),
		taxId: model.string(['taxId']),
		snils: model.string(['snils']),
		city: model.string(['city']),
		enabled: model.null<boolean>(['enabled']),
		passport: passport(data.passport),
		residenceAddress: model.null<Record<string, any>>(['residenceAddress']),
		bankForm: bankForm(data.bankForm),
		contractInfo: contractInfo(data.contractInfo),
	}
}
