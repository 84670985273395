import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { CashbackPayment } from 'converters/cashbackPayment'

type SetDataAction = PayloadAction<Array<CashbackPayment.Main>>

const initialState = {
	data: [] as CashbackPayment.Main[],
	isFetching: false,
}

const cashbackPaymentSlice = createSlice({
	name: 'cashbackPayment',
	initialState,
	reducers: {
		setData(state, { payload }: SetDataAction) {
			state.data = payload
		},

		setFetching(state, actionPayload) {
			state.isFetching = actionPayload.payload
		},

		reset(_state) {
			_state = initialState
		},
	},
})

export const { setData, setFetching, reset } = cashbackPaymentSlice.actions
export default cashbackPaymentSlice.reducer
