import { fixed } from './fixed'
import { fixedInterest } from './fixedInterest'
import { floatingByRoe } from './floatingByRoe'
import { floatingByTerm } from './floatingByTerm'

export const interestRateSpec = {
	fixed,
	fixedInterest,
	floatingByRoe,
	floatingByTerm,
}
