import type { SelectOption } from '@creditclubteam/kit/ui-components'
import type { CreditPolicyReport } from 'converters/creditPolicyReport'
import { createContext, useContext } from 'react'

export const hasValue = ({
	values,
	entityId,
	type,
}: {
	values: CreditPolicyReport.AdditionalRequest[]
	entityId: string
} & Pick<CreditPolicyReport.AdditionalRequest, 'type'>) => {
	return values.some(
		({ evaluationEntity, type: t }) => evaluationEntity.id === entityId && t === type
	)
}

export const getInitialValues = ({
	additionalRequests,
}: {
	additionalRequests: CreditPolicyReport.AdditionalRequest[]
}) => {
	return {
		stopFactorMeeting: additionalRequests.filter(({ type }) => type === 'STOP_FACTOR_MEETING'),
		actualValue: additionalRequests.filter(
			({ type }) => type === 'ACTUAL_VALUE' || type === 'ACTUAL_VALUE_2'
		),
		requiredActions: additionalRequests.filter(
			({ type }) => type === 'REQUIRED_ACTION' || type === 'REQUIRED_ACTION_2'
		),
		conditions: additionalRequests.filter(
			({ type }) => type === 'CONDITION' || type === 'CONDITION_2'
		),
	}
}

export const RISK = {
	FOUND: 'Да, риск есть',
	NOT_FOUND: 'Нет, риск отсутствует',
	NO_DATA: 'Нет данных',
}

export const RISK_KEYS = {
	FOUND: 'FOUND',
	NOT_FOUND: 'NOT_FOUND',
	NO_DATA: 'NO_DATA',
} as const

export const ACTUAL_VALUE_OPTIONS: SelectOption[] = Object.entries(RISK).map(([id, label]) => ({
	id,
	label,
}))

export const STOP_FACTOR_MEETING_OPTIONS: SelectOption[] = Object.entries(RISK)
	.filter(([id]) => id !== 'NO_DATA')
	.map(([id, label]) => ({ id, label }))

export const getMappedType = (type: CreditPolicyReport.AdditionalRequest['type']) =>
	((
		{
			REQUIRED_ACTION: 'requiredActions',
			REQUIRED_ACTION_2: 'requiredActions',
			CONDITION: 'conditions',
			CONDITION_2: 'conditions',
			ACTUAL_VALUE: 'actualValue',
			ACTUAL_VALUE_2: 'actualValue',
			STOP_FACTOR_MEETING: 'stopFactorMeeting',
		} as const
	)[type])

interface FormCtx {
	isEditing: boolean
	commentOptions: SelectOption[]
	setIsEditing: React.Dispatch<React.SetStateAction<boolean>>
}

export const formCtx = createContext<FormCtx>({
	isEditing: false,
	commentOptions: [],
	setIsEditing: null!,
})
export const useFormCtx = () => useContext<FormCtx>(formCtx)
