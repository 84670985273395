import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import cx from 'clsx'
import { useOutsideClick } from 'components/hooks'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import { Input, Loading } from '@creditclubteam/react-components'

import { workerFilter } from './_workerFilter'
import Item from './Item'

import styles from './WorkersPicker.module.scss'

const WorkersList = ({ style, onBlur, workers, onFocus, fetching, onChange, positions }) => {
	const inc = useRef(-1)
	const [value, setValue] = useState('')
	const nodeRef = useOutsideClick(onBlur)

	const arrayOfRefs = useMemo(() => [], [])

	const workersFiltered = useMemo(() => {
		return workerFilter(
			value.trim(),
			workers.filter(({ enabled }) => enabled)
		)
	}, [value, workers])

	const handleSetValue = useCallback((value) => {
		inc.current = 0

		setValue(value)
	}, [])

	const handleKeyDown = useCallback(
		(e) => {
			if (['ArrowUp', 'ArrowDown', 'Enter'].includes(e.code)) {
				if (isEmpty(workersFiltered)) return

				e.preventDefault()

				if (e.code === 'ArrowUp') {
					inc.current <= 0 ? (inc.current = workersFiltered.length - 1) : inc.current--
				}

				if (e.code === 'ArrowDown') {
					inc.current++
				}

				const currentIndexOfWorker = inc.current % workersFiltered.length

				if (e.code === 'ArrowUp' || e.code === 'ArrowDown') {
					arrayOfRefs[currentIndexOfWorker].focus()
				}

				if (e.code === 'Enter') {
					onChange(workersFiltered[currentIndexOfWorker])
				}
			}
		},
		[onChange, workersFiltered, arrayOfRefs]
	)

	useEffect(() => {
		document.body.addEventListener('keydown', handleKeyDown)

		return () => {
			document.body.removeEventListener('keydown', handleKeyDown)
		}
	}, [handleKeyDown])

	// eslint-disable-next-line
	const { noStyles, ...restOfStyle } = style

	return (
		<div tabIndex={1} ref={nodeRef} onFocus={onFocus} className={styles.wrapper}>
			<div
				className={cx(styles.list, {
					[styles.bottom]: positions.isBiggerThanWindowHeight,
				})}
			>
				<Input
					mode='min'
					value={value}
					autoFocus={true}
					placeholder='Начните вводить имя'
					className={cx(styles.input, {
						[styles.order]: positions.isBiggerThanWindowHeight,
					})}
					onChange={handleSetValue}
				/>
				{fetching ? (
					<Loading />
				) : (
					<div className={styles.inner}>
						{!isEmpty(workersFiltered) ? (
							workersFiltered.map((worker, index) => (
								<Item
									{...worker}
									key={worker.id}
									onClick={() => onChange(worker)}
									getRef={(ref) => (arrayOfRefs[index] = ref)}
								/>
							))
						) : (
							<div className={styles.empty}>Ничего не нашлось</div>
						)}
					</div>
				)}
			</div>
		</div>
	)
}

WorkersList.propTypes = {
	workers: PropTypes.array.isRequired,
	fetching: PropTypes.bool.isRequired,
	positions: PropTypes.object.isRequired,

	onBlur: PropTypes.func.isRequired,
	onFocus: PropTypes.func.isRequired,
	onChange: PropTypes.func.isRequired,
}

export default WorkersList
