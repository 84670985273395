import { solveParamsPagination } from 'actions/listParameters'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import axios from 'axios'
import HISTORY from 'const/history'
import { nanoid } from 'nanoid'
import { actions } from 'reducers/history'
import type { TAction } from 'types/redux'
import type { Maybe } from 'types/util'

import { historyUtils, utils } from 'helpers'
import { authorizedWorker } from 'helpers/authorizedWorker'

export const historySetVisibleStatus = actions.setVisible
export const setHistoryEntity = actions.setEntity
export const setHistoryFilters = actions.setFilters
export const resetHistory = actions.reset

/**
 * @param entitiesList {array} - массив участников истории, как правило используем для инициализации
 * далее он записывает эти данные в allEntityId в сторе.
 * @param shouldUpdate - Обновить ли полностью стор или только вставить данные
 */
export const fetchHistory =
	(
		entitiesList: Array<string | { id: string; $$type: string }>,
		shouldUpdate = false
	): TAction<Promise<any>> =>
	async (dispatch, getState) => {
		try {
			const { filters, dates, pageParams, allEntityId } = getState().history
			dispatch(actions.setFetchingStatus(true))

			// Записываем данные к себе в стор
			if (utils.hasObjectLength(entitiesList)) {
				dispatch(actions.setAllEntityId(entitiesList))
			}

			// Если полностью обновляем то сбрасываем и фильтры и отправляем первую страницу
			if (shouldUpdate) {
				dispatch(actions.resetPageParams())
			}

			const requestParameters = {
				filter: {
					...filters,
					types: utils.hasObjectLength(filters.types)
						? filters.types.map(({ value }) => value)
						: [],
					entityId: utils.hasObjectLength(entitiesList)
						? getIds(entitiesList)
						: utils.hasObjectLength(filters.entityId)
						? filters.entityId.map(({ value }) => value)
						: getIds(allEntityId),
				},

				...dispatch(solveParamsPagination('createdAt', 'desc', 20)),
				page: shouldUpdate ? 0 : pageParams.page,
			}

			const { data } = await api.history.search(requestParameters)

			const preData: Record<string, any>[] = []
			const preDates: HistoryDate[] = []
			let page = 0
			let totalPages = 0

			if (utils.hasObjectLength(data.content)) {
				data.content
					.filter(
						(comment: string) => shouldUpdate || notOnCurrentList(comment, getState().history.data)
					)
					.forEach((item: Record<string, any>) => {
						const date = new HistoryDate(new Date(item.createdAt))

						// Если обновляем полностью то проверяем что бы не было дублей у входящих дат
						if (shouldUpdate && !preDates.find((item) => item.isEqualTo(date))) {
							preDates.push(date)
						}

						// Если обновляем частично то проверяем что бы не было дублей у входящих дат и у стора
						else if (
							!shouldUpdate &&
							!preDates.find((item) => item.isEqualTo(date)) &&
							!dates.find((item) => item.isEqualTo(date))
						) {
							preDates.push(date)
						}

						// Записываем данные
						preData.push({
							...item,
							$$date: date,
							$$isProcessed: false,
						})
					})
			}

			page = ++data.number
			totalPages = data.totalPages

			const recordUrls = preData.map((data) => data.metadata?.recordUrl).filter(Boolean)

			const { data: response } = await api.document.getDownloadLinks({
				objectKeys: recordUrls,
				lifeTime: 3600000,
			})

			if (response.length > 0) {
				response.forEach((ans) => {
					const item = preData.find((item) => item.metadata?.recordUrl === ans.objectKey)

					item!.metadata.url = ans.uri
				})
			}

			const dispatchMethod = shouldUpdate ? actions.setData : actions.updateData
			dispatch(dispatchMethod({ data: preData, dates: preDates }))

			dispatch(actions.setPageParams({ field: 'page', value: page }))
			dispatch(actions.setPageParams({ field: 'totalPages', value: totalPages }))

			// Если полностью обновляем то прокручиваем до низу тело
			if (shouldUpdate) {
				historyUtils.returnScrollInStart()
			}
		} catch (error) {
			if (axios.isCancel(error)) return

			dispatch(actions.setFetchingStatus(false))
			dispatch(actions.setIsInitFailed(true))
			dispatch(
				addServerError({
					text: 'Не удалось загрузить историю',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const getHistoryParticipants = (): TAction<any> => (_, getState) => {
	const { person, application, entrepreneur, facility, organization, loan, lead, delivery } =
		getState()

	return ([] as Maybe<unknown>[])
		.concat(
			loan.single.data,
			delivery.single.data,
			lead.single.data,
			person.list.data,
			person.single.data,
			facility.list.data,
			facility.single.data,
			entrepreneur.list.data,
			organization.list.data,
			application.single.data,
			organization.single.data,
			entrepreneur.single.data
		)
		.filter(Boolean)
}

export const getHistoryFetchingParticipantsStatus = (): TAction<any> => (_, getState) => {
	const { person, application, entrepreneur, facility, organization, loan, lead, delivery } =
		getState()

	return [lead, loan, person, facility, application, organization, entrepreneur, delivery].find(
		({ list, single }) => list.fetching || list.fetching || single.fetching || single.fetching
	)
}

export const addCommentToEntity =
	({ text, entity }: Record<string, any>): TAction<Promise<any>> =>
	() =>
		api.history.addComment({
			text,
			entity,
		})

/**
 * @param {string} text
 */
export const addHistoryComment =
	(text: string): TAction<any> =>
	async (dispatch, getState) => {
		try {
			const dateForStore = []
			const addedDate = new HistoryDate(new Date())
			const { dates, entity, isVisible } = getState().history

			const entityId = entity.id

			/**
			 * @param {string} id - При формировании обьекта комментария
			 * назначаем ему в качестве id uuid, для того что бы
			 * после добавления его в базу и получения о нём настоящих данных
			 * отыскать его в среди других записей
			 * @param {boolean} $$isProcessed - Мы сразу добавляем комментарий без ожидания бекенда,
			 * он появляется в списке но не имеет ряд возможностей, например редактирование или удаление
			 * данный параметр позволит нам в компоненте это разрулить
			 */
			const comment = {
				entityId,
				id: nanoid(),
				$$date: addedDate,
				$$isProcessed: true,
				type: HISTORY.TYPES.COMMENT.value,
				metadata: {
					text,
					edited: false,
					workerId: authorizedWorker.getId(),
				},
			}

			/**
			 * Проверяем есть ли уже данная дата в сторе
			 * если нет добавляем её в dateForStore
			 */
			if (!dates.find((date) => date.isEqualTo(addedDate))) {
				dateForStore.push(addedDate)
			}

			if (isVisible) {
				dispatch(actions.addComment({ comment, dates: dateForStore }))
				setTimeout(historyUtils.returnScrollInStart)
			}

			const { data } = await api.history.addComment({ entity, text: comment.metadata.text })

			if (getState().history.isVisible) {
				dispatch(
					actions.updateComment({
						commentId: comment.id,
						params: {
							$$isProcessed: false,
							id: data.id,
						},
						metadata: undefined,
					})
				)
			}
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Ошибка добавления комментария',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const editHistoryComment =
	(commentId: string, newText: string): TAction<void> =>
	(dispatch) => {
		dispatch(
			actions.updateComment({
				commentId,
				params: undefined,
				metadata: {
					edited: true,
					text: newText,
				},
			})
		)

		api.history.editComment(commentId, newText).catch((error) => {
			dispatch(
				addServerError({
					text: 'Ошибка изменения комментария',
					details: utils.getDetailsFromError(error),
				})
			)
		})
	}

export const deleteHistoryComment =
	(id: string): TAction<void> =>
	(dispatch) => {
		dispatch(actions.deleteComment(id))

		api.history.deleteComment(id).catch((error) => {
			dispatch(
				addServerError({
					text: 'Ошибка удаления комментария',
					details: utils.getDetailsFromError(error),
				})
			)
		})
	}

// helpers
class HistoryDate {
	fullDate: Date
	day: number
	month: number
	year: number

	constructor(date: Date) {
		this.fullDate = date
		this.day = date.getDate()
		this.month = date.getMonth()
		this.year = date.getFullYear()
	}

	isEqualTo<T extends { day: number; month: number; year: number }>({ day, month, year }: T) {
		return this.day === day && this.month === month && this.year === year
	}
}

/**
 * @description Проверяет есть ли комментарий уже в загруженном списке
 */
function notOnCurrentList<T extends { id: string }>(comment: T, list: T[]): boolean {
	return !list.some((ct) => ct.id === comment.id)
}

/**
 * @description Преобразует массив участвующих в истории сущностей
 * в список с id вида [id, id, id]
 */
function getIds<T extends Array<string | { id: string }>>(list: T) {
	return list.map((j) => (j && typeof j === 'object' ? j.id : j))
}
