import { DefaultValues } from 'helpers/convertHelper'

export const phoneNumber = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		countryCode: model.string(['countryCode']),
		nationalNumber: model.string(['nationalNumber']),
		extension: model.string(['extension']),
	}
}
