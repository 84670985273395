import {
	fetchCategories,
	fetchFiles,
	resetDocumentCategories,
	resetDocumentFiles,
} from 'actions/document'
import { searchExternalRequest } from 'actions/externalData'
import { resetOrganizationList, setOrganizationListData } from 'actions/organization/list'
import { resetPersonList, setPersonListData } from 'actions/person'
import { resetSearch } from 'actions/search'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { EXTERNAL_REQUEST_TYPE } from 'const/externalData'
import { OWNER_TYPES } from 'const/ownerTypes'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import type { Organization } from 'converters/organization'
import { organization } from 'converters/organization'
import { person } from 'converters/person'
import { resetExternalData } from 'reducers/externalData'
import { actions } from 'reducers/organization/single'
import type { TAction } from 'types/redux'

import { notifications, utils } from 'helpers'

const { PERSON, ORGANIZATION } = PARTICIPANTS_TYPES

export const updateCertificateForOrganization = actions.updateCertificate
export const deleteCertificateFormForOrganization = actions.deleteCertificateForm

export const resetOrganizationPage = (): TAction => (dispatch) => {
	dispatch(actions.reset())
	dispatch(resetSearch(true))
	dispatch(resetPersonList())
	dispatch(resetDocumentFiles())
	dispatch(resetOrganizationList('data'))
	dispatch(resetDocumentCategories())
	dispatch(resetExternalData())
}

export const fetchOrganization =
	(id: string): TAction<Promise<Organization.Main | void>> =>
	async (dispatch) => {
		dispatch(actions.setFetchingStatus(true))

		try {
			const { data } = await api.organization.get(id)

			const persons = data.participants
				.filter(({ type, participant }) => type === PERSON && participant)
				.map((item) => Object.assign({}, person.main({ ...item.participant!, roles: item.roles })))
			const organizations = data.participants
				.filter(({ type, participant }) => type === ORGANIZATION && participant)
				.map((item) =>
					Object.assign({}, organization.main({ ...item.participant!, roles: item.roles }))
				)

			// Get external-data-requests
			await dispatch(
				searchExternalRequest({
					entityId: id,
					entityType: PARTICIPANTS_TYPES.ORGANIZATION,
					requestTypes: [EXTERNAL_REQUEST_TYPE.COURT],
					size: 50,
					dataBehavior: 'push',
				})
			)

			await dispatch(
				searchExternalRequest({
					entityId: id,
					entityType: PARTICIPANTS_TYPES.ORGANIZATION,
					requestTypes: [EXTERNAL_REQUEST_TYPE.FSSP],
					size: 50,
					dataBehavior: 'push',
				})
			)

			await dispatch(
				searchExternalRequest({
					entityId: id,
					entityType: PARTICIPANTS_TYPES.ORGANIZATION,
					requestTypes: [EXTERNAL_REQUEST_TYPE.CREDIT_HISTORY],
					size: 50,
					dataBehavior: 'push',
				})
			)

			dispatch(actions.setData(data))
			dispatch(actions.setFetchingStatus(false))

			// Получаем и записываем документы с категориями
			dispatch(fetchCategories([OWNER_TYPES.ORGANIZATION]))
			dispatch(fetchFiles(id))

			// Собираем и записываем участников по их местам в сторе
			dispatch(setPersonListData(persons))
			dispatch(setOrganizationListData(organizations))

			return data
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить организацию',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const updateOrganization =
	(form: Record<string, unknown>): TAction<Promise<void>> =>
	async (dispatch, getState) => {
		const organization = getState().organization.single.data!

		try {
			await api.organization.update(organization.id, form)

			try {
				const { data } = await api.organization.get(organization.id)

				dispatch(actions.setData(data))
			} catch (error) {
				//
			}
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось обновить анкету',
					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		}
	}

export const refreshOrganizationInfo = (): TAction<Promise<void>> => async (dispatch, getState) => {
	const id = getState().organization.single.data!.id

	try {
		await api.organization.actions(id, ['UPDATE_FORM'])
		notifications.send('Организация добавлена в очередь на обновление')
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось обновить карту',
				details: utils.getDetailsFromError(error),
			})
		)
	}
}
