import { CALCULATION } from 'const/calculations'

import { DefaultValues } from 'helpers/convertHelper'

export const floatingByTerm = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		type: 'FLOATING_BY_TERM' as const,
		unit: model.const(data?.unit, CALCULATION.UNIT.ENG),
		unitRus: model.const(data?.unit, CALCULATION.UNIT.RUS),
		intervalUnit: model.const(data?.intervalUnit, { MONTH: 'MONTH', YEAR: 'YEAR' } as const),
		intervals: model.array(['intervals']).map((_, i) => ({
			from: model.null<number>(['intervals', i, 'from']),
			to: model.null<number>(['intervals', i, 'to']),
			value: model.null<number>(['intervals', i, 'value']),
		})),
	}
}
