import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { Person } from 'converters/person'
import { commonActions } from 'reducers/common'
import type { TRedux } from 'types'
import type { PayloadStatus } from 'types/redux'

const representativesAdapter = createEntityAdapter<Person.Main>()

export const personRepresentativesSelector = representativesAdapter.getSelectors<TRedux.RootState>(
	(state) => state.person.representatives
)

const initialState = representativesAdapter.getInitialState({
	status: 'pending' as TRedux.PayloadStatus,
})

const representatives = createSlice({
	name: 'person/representatives',
	initialState,
	reducers: {
		setAll: representativesAdapter.setAll,
		setStatus: (state, { payload }: PayloadAction<PayloadStatus>) => {
			state.status = payload
		},
		reset: commonActions.reset(initialState),
	},
})

export const { actions } = representatives
export default representatives.reducer
