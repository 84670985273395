import { DefaultValues } from 'helpers/convertHelper'

import { taxResidentPeriod } from './taxResidentPeriod'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		acquisitionChannel: model.string(['acquisitionChannel']),
		taxResidentPeriods: model.array(['taxResidentPeriods']).map(taxResidentPeriod),
	}
}
