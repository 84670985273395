import { DefaultValues } from 'helpers/convertHelper'

export const publicAccount = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		link: model.string(['link']),
		comment: model.string(['comment']),
		rating: model.string<'POSITIVE' | 'NEUTRAL' | 'NEGATIVE'>(['rating']),
	}
}
