import { useMemo } from 'react'
import { useFetch } from 'components/hooks'

import { Button } from 'components/common'

import styles from './StatementPreview.module.scss'

type Props = {
	fileToPreview?: File
	onSubmit: () => Promise<any>
	onClose: () => void
}

const specialFiles = ['image/tiff', 'image/heic', 'image/heif']

const StatementPreview = (props: Props) => {
	const { fileToPreview, onClose, onSubmit } = props

	const urlObject = useMemo(
		() => (fileToPreview ? URL.createObjectURL(fileToPreview) : ''),
		[fileToPreview]
	)

	const { request, isFetching } = useFetch(onSubmit, {
		onSuccess: onClose,
	})

	const submitProps = {
		title: 'Отправить',
		loading: isFetching,
		onClick: request,
	}

	const cancelProps = {
		title: 'Отмена',
		onClick: onClose,
		type: 'buttonTextRed',
	}

	return (
		<div>
			<div className={styles.previewWrapper}>
				{specialFiles.includes(fileToPreview?.type || '') ? (
					<img src={urlObject} />
				) : (
					<iframe src={urlObject} frameBorder='0' />
				)}
			</div>
			<div className={styles.buttons}>
				<Button {...cancelProps} />
				<Button {...submitProps} />
			</div>
		</div>
	)
}

export default StatementPreview
