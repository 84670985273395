import { ENTITY_TYPES } from 'const'

import { DefaultValues } from 'helpers/convertHelper'

export const brief = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		number: model.string(['number']),
		startingDate: model.string(['startingDate']),
		maturityDate: model.string(['maturityDate']),
		status: model.string(['status']),
		clientName: model.string(['clientName']),
		amount: model.null<number>(['amount']),
		savingProgramName: model.string(['savingProgramName']),
		$$type: ENTITY_TYPES.DEPOSIT,
	}
}
