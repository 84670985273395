import { Component } from 'react'
import { connect } from 'react-redux'
import { downloadFile } from 'actions/document'
import {
	deleteFile,
	getSimilarFilesInCategory,
	initMetadataForm,
	onRefreshFile,
	returnFileToBucket,
} from 'actions/filesInspector'
import { showFilesPreview } from 'actions/filesViewer'
import PropTypes from 'prop-types'

import { utils } from 'helpers'

import './Controls.scss'

class Controls extends Component {
	static propTypes = {
		id: PropTypes.string.isRequired,
		objectKey: PropTypes.string.isRequired,
		metadataFields: PropTypes.array,
		index: PropTypes.number.isRequired,
		title: PropTypes.string.isRequired,
		zoneId: PropTypes.string.isRequired,

		deleteFile: PropTypes.func.isRequired,
		onRefreshFile: PropTypes.func.isRequired,
		downloadFile: PropTypes.func.isRequired,
		showFilesPreview: PropTypes.func.isRequired,
		returnFileToBucket: PropTypes.func.isRequired,
		initMetadataForm: PropTypes.func.isRequired,
		getSimilarFilesInCategory: PropTypes.func.isRequired,
	}

	handleMetadata = () => {
		const { initMetadataForm, metadataFields, zoneId, id } = this.props

		initMetadataForm({
			destinationCategory: { id: zoneId, metadataFields },
			selectedFilesIds: [id],
			mode: 'edit',
		})
	}

	handleMoveToBucket = () => {
		const { id, index, zoneId, returnFileToBucket } = this.props

		returnFileToBucket(zoneId, id, index)
	}

	handleShow = () => {
		const {
			id,
			objectKey,
			title,
			zoneId,
			onRefreshFile,
			showFilesPreview,
			getSimilarFilesInCategory,
		} = this.props

		const file = { id, objectKey, title }
		let similar = getSimilarFilesInCategory(zoneId, file)

		if (utils.hasObjectLength(similar)) {
			similar = similar.map(({ id, objectKey, title }) => ({ id, objectKey, title }))
			similar.push(file)
			similar.reverse()

			showFilesPreview(similar, undefined, (file) => onRefreshFile(file, zoneId), id, zoneId)
			return
		}

		showFilesPreview(file, undefined, (file) => onRefreshFile(file, zoneId), id, zoneId)
	}

	handleDownload = () => {
		const { objectKey, title, downloadFile } = this.props

		downloadFile({ objectKey, filename: title })
	}

	handleDelete = () => {
		const { deleteFile, zoneId, id } = this.props

		deleteFile(id, zoneId)
	}

	render() {
		const { metadataFields } = this.props

		return (
			<div className='files-inspector-files-category__controls'>
				{metadataFields && (
					<div
						onClick={this.handleMetadata}
						className='files-inspector-files-category__controls-item'
					>
						<i className='zmdi zmdi-assignment' data-testid='metadata-btn' />
					</div>
				)}
				<div
					onClick={this.handleMoveToBucket}
					className='files-inspector-files-category__controls-item'
				>
					<i className='zmdi zmdi-arrow-right' data-testid='back-to-bucket-btn' />
				</div>
				<div onClick={this.handleShow} className='files-inspector-files-category__controls-item'>
					<i className='zmdi zmdi-eye' data-testid='view-file-btn' />
				</div>
				<div
					onClick={this.handleDownload}
					className='files-inspector-files-category__controls-item'
				>
					<i className='zmdi zmdi-cloud-download' data-testid='download-file-btn' />
				</div>
				<div onClick={this.handleDelete} className='files-inspector-files-category__controls-item'>
					<i className='zmdi zmdi-delete' data-testid='delete-file-btn' />
				</div>
			</div>
		)
	}
}

const mapDispatchToProps = {
	deleteFile,
	onRefreshFile,
	downloadFile,
	showFilesPreview,
	returnFileToBucket,
	getSimilarFilesInCategory,
	initMetadataForm,
}

export default connect(null, mapDispatchToProps)(Controls)
