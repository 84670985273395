import { URLBuilder } from 'api/helpers'
import axios from 'axios'

import type { MortgageDto, MortgageFormDto } from './types/Mortgage'

const baseUrl = '/v1/mortgages'

export default {
	// Получение закладной
	get: (id: string) => axios.get<MortgageDto>(`${baseUrl}/${id}`),

	// Редактирование закладной
	edit({ id, data }: { id: string; data: MortgageFormDto }) {
		return axios.put<MortgageDto>(`${baseUrl}/${id}`, data)
	},

	// Обновление статуса закладной
	update: (id: string) => axios.post<MortgageDto>(`${baseUrl}/${id}`),

	// Удаление закладной
	delete: (id: string) => axios.delete(`${baseUrl}/${id}`),

	// Создание закладной
	create: (data: MortgageFormDto) => axios.post<MortgageDto>(baseUrl, data),

	// Отправка в росреестр
	sendToRosreestr: (id: string) =>
		axios.post<{ content: Array<MortgageDto> }>(`${baseUrl}/${id}/rosreestr`),

	search(options: Record<string, any>) {
		const params = new URLBuilder({
			size: options.size,
			sort: options.sort,
			page: options.page,
		})

		const { filter: { loanId, facilityId } = {} as Record<string, any> } = options
		params.addFilter('loanId', Array.isArray(loanId) ? loanId : loanId && [loanId], 'inList')
		params.addFilter('facilityId', facilityId, 'eq')

		return axios.get<{ content: Array<MortgageDto> }>(`${baseUrl}/search`, {
			params: {
				filter: params.build('RSQL'),
			},
		})
	},
}
