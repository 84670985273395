import { useState } from 'react'
import { useOutsideClick } from 'components/hooks'
import PropTypes from 'prop-types'

import { Button, Mentions } from '@creditclubteam/react-components'

import styles from './Styles.module.scss'

const EditComment = ({ oldText, onEdit, onClose, workers }) => {
	const [newText, setText] = useState(oldText)
	const [originalValue, setOriginalValue] = useState(oldText)
	const nodeEl = useOutsideClick(cancel)

	function cancel() {
		onClose()
	}

	function save() {
		const text = originalValue.trim()

		if (text.length && originalValue !== oldText) {
			onEdit(text)
		}

		cancel()
	}

	const textareaProps = {
		minHeight: 40,
		value: newText,
		originalValue,
		autoSize: true,
		maxHeight: 250,
		autoFocus: true,
		mentionsData: workers,
		className: styles.textarea,

		onPressEnter: save,
		onChange: setText,
		onInput: setOriginalValue,
	}

	const cancelButtonProps = {
		mode: 'min',
		theme: 'text-red',
		title: 'Отменить',

		onClick: cancel,
	}

	const saveButtonProps = {
		mode: 'min',
		title: 'Сохранить',
		className: styles.saveButton,

		onClick: save,
	}

	return (
		<div className={styles.wrapper} ref={nodeEl}>
			<Mentions {...textareaProps} />
			<div className={styles.controls}>
				<Button {...cancelButtonProps} />
				<Button {...saveButtonProps} />
			</div>
		</div>
	)
}

EditComment.propTypes = {
	oldText: PropTypes.string.isRequired,

	onEdit: PropTypes.func.isRequired,
	onClose: PropTypes.func.isRequired,
}

export default EditComment
