import { Component } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import { rangeSelectionFiles, toggleSelectionFiles } from 'actions/filesInspector'
import cx from 'clsx'
import PropTypes from 'prop-types'

import { utils } from 'helpers'

import FileItem from './FileItem'

import './FilesCategory.scss'

class FilesCategory extends Component {
	static propTypes = {
		comment: PropTypes.string,
		zoneId: PropTypes.string.isRequired,
		listFiles: PropTypes.array.isRequired,
		categoryName: PropTypes.string.isRequired,

		rangeSelectionFiles: PropTypes.func.isRequired,
		toggleSelectionFiles: PropTypes.func.isRequired,
	}

	state = {
		minimize: false,
	}

	componentDidMount() {
		const { listFiles } = this.props

		this.setState({
			minimize: listFiles.length > 1,
		})
	}

	handleClickItem = (event, id) => {
		const { zoneId, rangeSelectionFiles, toggleSelectionFiles } = this.props

		if (utils.ctrlClick(event)) toggleSelectionFiles(id, zoneId)
		else if (utils.shiftClick(event)) rangeSelectionFiles(id, zoneId)
		else toggleSelectionFiles()
	}

	toggleMinimizeCategory = () => {
		const { minimize } = this.state

		this.setState({ minimize: !minimize })
	}

	renderMinimize() {
		const { comment, categoryName, listFiles } = this.props

		const miniInfoBlockOptions = {
			onClick: this.toggleMinimizeCategory,
			className: 'files-inspector-files-category__mininfo',
		}

		return (
			<div {...miniInfoBlockOptions}>
				<div className='files-inspector-files-category__mininfo-text'>
					<span>{categoryName}</span>
					{comment && comment}
				</div>
				<div className='files-inspector-files-category__mininfo-count'>{listFiles.length}</div>
			</div>
		)
	}

	render() {
		const { minimize } = this.state
		const { zoneId, listFiles, comment, categoryName, metadataFields } = this.props

		const blockCategoryOptions = {
			id: zoneId,
			className: cx('files-inspector-files-category', { minimize }),
		}

		const blockTitleCategoryOptions = {
			onClick: this.toggleMinimizeCategory,
			className: cx('files-inspector-files-category__title', { comment }),
		}

		return (
			<div {...blockCategoryOptions}>
				{minimize ? (
					this.renderMinimize()
				) : (
					<Droppable droppableId={zoneId}>
						{(provided, snapshot) => {
							const listBlockOptions = {
								ref: provided.innerRef,
								...provided.droppableProps,
								className: cx('files-inspector-files-category__list', {
									isDraggingOver: snapshot.isDraggingOver,
								}),
							}

							return (
								<div {...listBlockOptions}>
									<div {...blockTitleCategoryOptions}>
										<span>{categoryName}</span>
										{comment && comment}
									</div>
									{utils.hasObjectLength(listFiles) &&
										listFiles.map((item, index) => (
											<FileItem
												{...item}
												key={item.id}
												index={index}
												metadataFields={metadataFields}
												zoneId={zoneId}
												onClick={this.handleClickItem}
											/>
										))}
									{provided.placeholder}
								</div>
							)
						}}
					</Droppable>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	...state.filesInspector.inspector,
})

const mapDispatchToProps = {
	rangeSelectionFiles,
	toggleSelectionFiles,
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesCategory)
