import { ENTITY_TYPES } from 'const'
import { person } from 'converters/person'

import { DefaultValues } from 'helpers/convertHelper'

import { bankForm } from './bankForm'
import { form } from './form'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		oneCId: model.string(['oneCId']),
		taxId: model.string(['taxId']),
		name: model.string(['name']),
		email: model.string(['email']),
		createdAt: model.string(['createdAt']),
		lastZchbUpdate: model.string(['lastZchbUpdate']),
		form: form(data.form),
		bankForm: bankForm(data.bankForm),
		person: data.person ? person.main(data.person) : null,
		mainContact: model.null(['mainContact']),
		part: model.null(['part']),
		roles: model.array(['roles']),
		$$type: ENTITY_TYPES.ENTREPRENEUR,
	}
}
