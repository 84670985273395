import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Application } from 'converters/application'
import type { Delivery } from 'converters/delivery'
import { commonActions } from 'reducers/common'
import type { TUtils } from 'types'

const initialState = {
	data: null as TUtils.Maybe<Application.Main>,
	deliveries: [] as Array<Delivery.Main>,
	fetching: false,
	fetchingParticipants: false,
	fetchingFinancialProducts: false,
}

const single = createSlice({
	name: 'application/single',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Application.Main>) {
			state.data = payload
		},

		setFetchingByField(
			state,
			{
				payload: { field, value },
			}: PayloadAction<{
				field: 'fetchingParticipants' | 'fetchingFinancialProducts'
				value: boolean
			}>
		) {
			state[field] = value
		},

		updateData(state, { payload }: PayloadAction<Partial<Application.Main>>) {
			if (state.data) {
				state.data = Object.assign(state.data, payload)

				return state
			}
		},

		updatePreliminarySchedule(state, { payload }) {
			if (state.data) {
				state.data.preliminarySchedule = payload
			}
		},

		delPreliminarySchedule(state) {
			if (state.data) {
				state.data.preliminarySchedule = null
			}
		},

		resolveNextStatus(state, { payload }: PayloadAction<Application.Status>) {
			if (state.data) {
				state.data.status = payload
			}
		},

		setDeliveries(state, { payload }: PayloadAction<Array<Delivery.Main>>) {
			state.deliveries = payload
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

const { actions } = single

export { actions }
export default single.reducer
