import { DefaultValues } from 'helpers/convertHelper'

export const fileUpload = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		title: model.string(['title']),
		type: model.string<'file-upload'>(['type']),
		url: model.string(['url']),
		fileType: model.string<'PHOTO' | 'FILE'>(['fileType']),
	}
}
