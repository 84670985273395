import { URLBuilder } from 'api/helpers'
import type { EntityId } from 'api/types/EntityId'
import type { Options } from 'api/types/Options'
import type { Pageable } from 'api/types/Pageable'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type { Organization } from 'converters/organization'
import { organization } from 'converters/organization'
import { mergeDeepRight } from 'ramda'
import type { OrganizationFilter } from 'reducers/organization/list'

import type { Actions } from './types/Participant'
import type { CommonApi } from './types'

const organizationApi = {
	createOrUpdateCertificate: (orgId: EntityId) =>
		axios.post(`/v1/organizations/${orgId}/certificate-form`),

	createCertificateStatement: (orgId: EntityId) =>
		axios.get(`/v1/organizations/${orgId}/certificate-form/statement`),

	releaseCertificateForm: (orgId: EntityId) =>
		axios.post(`/v1/organizations/${orgId}/certificate-form/release`),

	deleteCertificateForm: (orgId: EntityId) =>
		axios.delete(`/v1/organizations/${orgId}/certificate-form`),

	updateCertificateFormState: (orgId: EntityId) =>
		axios.post(`/v1/organizations/${orgId}/certificate-form/state`),

	syncSignatureOfRepresentative: (orgId: EntityId) =>
		axios.post(`/v1/organizations/${orgId}/representative/signature`),
}

const commonOrganizationApi: CommonApi<
	Organization.Main,
	OrganizationFilter,
	Record<string, any>,
	unknown
> = {
	get: (id: EntityId) =>
		axios.get<Organization.Main>(`v2/organizations/${id}`).then((response) =>
			Object.assign({}, response, {
				data: organization.main(response.data),
			})
		),

	search({
		filter,
		size = 10,
		page,
		sort,
		property,
		disableCancelToken = false,
		direction,
		type = 'RSQL',
	}: Options<OrganizationFilter> & { type?: 'RSQL' | 'TEXT' }) {
		const params = new URLBuilder()

		if (type === 'RSQL' && filter) {
			const re = (value: string) =>
				`=re="(?i)(?=.*${value.trim().replace(/\s+/g, ')(?=.*').replace(/"/g, '\\"')}).+"
				`

			params
				.addFilter(Number(filter.query) ? 'taxId' : 'name', filter.query, re)
				.addFilter('id', filter.ids, 'inList')
		}

		return axios
			.get('/v2/organizations', {
				params: {
					sort: property && direction ? [property, direction].join(',') : sort || undefined,
					size,
					page,
					type,
					filter: type === 'TEXT' ? filter?.query : params.build('RSQL'),
				},
				$$requestName: !disableCancelToken ? 'organization.search' : undefined,
			})
			.then(
				(response) =>
					mergeDeepRight(response, {
						data: {
							content: response.data.content.map(organization.main),
						},
					}) as AxiosResponse<Pageable<Organization.Main>>
			)
	},

	create: (data: Record<string, any>) =>
		axios.post<Organization.Main>('v2/organizations', data).then((response) =>
			Object.assign({}, response, {
				data: organization.main(response.data),
			})
		),

	update: (id: EntityId, data: unknown) =>
		axios.patch(`/v2/organizations/${id}`, data, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),
}

export default {
	...commonOrganizationApi,
	...organizationApi,
	actions: (id: EntityId, actions: unknown, contexts?: unknown) =>
		axios.post(`/v2/organizations/${id}/actions`, { actions, contexts }),
} as typeof commonOrganizationApi & typeof organizationApi & Actions
