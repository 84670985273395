import { dropdownTabs, tabs } from 'components/Layout/Navigation/Navigation'
import { isEmpty, mergeDeepRight } from 'ramda'
import { add, goBack, gotTo, reset, setData } from 'reducers/breadcrumbs'
import urls from 'routes/urls'

export const resetBreadcrumbs = reset
export const breadcrumbsGoTo = gotTo
export const breadcrumbsGoBack = goBack

export const addBreadcrumb = (title) => (dispatch, getState) => {
	const location = getState().router.location.pathname
	const breadcrumbs = getState().breadcrumbs.data

	const prevCrumb = breadcrumbs.slice(-2)[0]
	const prevPath = (prevCrumb || {}).path

	// детект кнопки назад
	if (prevPath === location) {
		dispatch(breadcrumbsGoBack())
		return
	}

	dispatch(add({ title, path: location }))
}

export const initBreadcrumbs = (routes) => (dispatch, getState) => {
	const location = getState().router.location.pathname

	const routesSorted = routes
		.map((route) => ({ title: route.title, path: route.path }))
		.filter((e) => e.title)
		.sort((a, b) => a.path.split('/').slice(1).length - b.path.split('/').slice(1).length)

	let breadcrumbs = getBreadcrumbsByCurrentLocation(location, routesSorted)

	breadcrumbs = breadcrumbs.map((e, i) => ({ id: i + 1, ...e }))

	dispatch(setData(breadcrumbs))
}

// helpers

function isParam(str) {
	if (!str) return false
	if (str.includes(':') || /\d/.test(str)) return true

	return false
}

function getBreadcrumbsByCurrentLocation(location, routes) {
	let crumbs = []
	const current = location.split('/').slice(1)
	const rootBreadcrumb = defineRootBreadcrumb(current[0])

	const isInitNeeded = current.filter(isParam).length >= 2

	if (!isInitNeeded) return [rootBreadcrumb]

	routes.forEach((route) => {
		const routePath = route.path.split('/').slice(1)

		const routeLength = routePath.length >= current.length ? routePath.length : current.length

		let matches = Array.from({ length: routeLength }, () => false)

		let cut = 0

		/**
		 * routePath.length >= 2 добавил для крошек выше 1-го уровня
		 */

		if (routePath.length <= current.length && routePath.length >= 2) {
			for (let i = 0; i < routeLength; i++) {
				if (routePath[i] && current[i]) {
					const condition = routePath[i].includes('|')
						? routePath[i].includes(current[i])
						: routePath[i] === current[i]

					if (condition) {
						matches[i] = true
						cut = i
					} else if (isParam(routePath[i]) && isParam(current[i])) {
						matches[i] = true
						cut = i
					} else {
						matches = []
						break
					}
				}
			}

			if (matches.filter(Boolean).length) {
				crumbs.push({
					title: route.title,
					path: `/${current.slice(0, cut + 1).join('/')}`,
				})
			}
		}
	})

	if (crumbs.length > 1) crumbs = crumbs.slice(0, crumbs.length - 1)

	if (isEmpty(rootBreadcrumb)) return crumbs
	else return [rootBreadcrumb, ...crumbs]
}

function defineRootBreadcrumb(rootPath) {
	const rootBreadcrumb = {}

	const paths = mergeDeepRight(
		{
			...tabs,
			...dropdownTabs,
		},
		{
			leads: {
				path: location.pathname.includes(urls.investmentLead.list.path)
					? urls.investmentLead.list.path
					: urls.leads.path,
			},
		}
	)

	Object.keys(paths).forEach((key) => {
		if (paths[key].values.includes(rootPath) && isEmpty(rootBreadcrumb)) {
			rootBreadcrumb.title = paths[key].title
			rootBreadcrumb.path = paths[key].path
		}
	})

	return rootBreadcrumb
}
