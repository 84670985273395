import { ROSREESTR_ENCUMBRANCE_STATUSES, ROSREESTR_ENCUMBRANCE_STATUSES_RUS } from 'const'

import { DefaultValues } from 'helpers/convertHelper'

import { statement } from './statement'

export const statementInfo = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		state: model.const(data.state, ROSREESTR_ENCUMBRANCE_STATUSES),
		// state: 'ERROR' as const,
		stateRus: model.const(data.state, ROSREESTR_ENCUMBRANCE_STATUSES_RUS),
		statements: model.array(['statements']).map(statement),
		govFee: model.array<Record<string, any>[]>(['govFee']),
		paymentServiceInfo: model.array<Record<string, any>[]>(['paymentServiceInfo']),
		responses: model.array<Record<string, any>[]>(['responses']),
		error: model.null<Record<string, any>>(['error']),
	}
}
