import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'
import { workerSelector } from 'reducers/worker'

import Context from '../../Context'

import { Audio, Chat, Document, Image, Video } from './ChatTypes'

import './WhatsApp.scss'

class WhatsApp extends Component {
	static propTypes = {
		workers: PropTypes.array.isRequired,
		$$date: PropTypes.object.isRequired,
		metadata: PropTypes.object.isRequired,
	}

	renderChatTypes() {
		const { metadata } = this.props

		switch (metadata.type) {
			case 'document':
				return <Document {...metadata} />

			case 'video':
				return <Video {...metadata} />

			case 'chat':
				return <Chat {...metadata} />

			case 'image':
				return <Image {...metadata} />

			case 'ptt':
			case 'audio':
				return <Audio {...metadata} />

			default:
				return <div>{`${metadata.type} Не известный тип`}</div>
		}
	}

	render() {
		const {
			$$date,
			workers,
			metadata: { workerId, fromMe },
		} = this.props

		let currentWorker = workers.find(({ id }) => id === workerId)
		currentWorker = `${currentWorker.surname || ''} ${currentWorker.name || ''}`

		const contextOptions = {
			date: $$date.fullDate,
			avatar: (
				<div className='history-whatsapp__avatar'>
					<i className='zmdi zmdi-whatsapp' />
				</div>
			),
			title: (
				<Fragment>
					{fromMe ? currentWorker : 'Клиент'}
					<span style={{ margin: '0 7px' }}>
						<i className='zmdi zmdi-long-arrow-right' />
					</span>
					{fromMe ? 'Клиент' : currentWorker}
				</Fragment>
			),
		}

		return <Context {...contextOptions}>{this.renderChatTypes()}</Context>
	}
}

const mapStateToProps = (state) => ({
	workers: workerSelector.selectAll(state),
})

export default connect(mapStateToProps)(WhatsApp)
