import axios from 'axios'
import type { ScoringPerson } from 'converters/scoring/person'
import { person } from 'converters/scoring/person'

const baseUrl = 'v4/scoring/persons'

declare namespace ScoringApi {
	export type GetPersonIncomeSourceGroups = ScoringPerson.Main[]
	export type CreatePersonIncomeSource = void
	export type UpdatePersonIncomeSource = void
	export type DeletePersonIncomeSource = void
}

export default {
	createPersonIncomeSource: ({
		personId,
		payload,
	}: {
		personId: string
		payload: {
			type: string
			confirmationDocument: { type: string; date: string } & Record<string, any>
		}
	}) =>
		axios.post<ScoringApi.CreatePersonIncomeSource>(
			`${baseUrl}/${personId}/income-sources`,
			payload
		),

	updatePersonIncomeSource: ({
		personId,
		incomeSourceId,
		payload,
	}: {
		personId: string
		incomeSourceId: string
		payload: {
			deletionMark?: boolean
			confirmationDocument?: { date: string } & Record<string, any>
		}
	}) =>
		axios.patch<ScoringApi.UpdatePersonIncomeSource>(
			`${baseUrl}/${personId}/income-sources/${incomeSourceId}`,
			payload,
			{ headers: { 'Content-Type': 'application/merge-patch+json' } }
		),

	deletePersonIncomeSource: ({
		personId,
		incomeSourceId,
	}: {
		personId: string
		incomeSourceId: string
	}) =>
		axios.delete<ScoringApi.DeletePersonIncomeSource>(
			`${baseUrl}/${personId}/income-sources/${incomeSourceId}`
		),

	getPersonIncomeSourceGroups: (personId: string) =>
		axios.get<ScoringApi.GetPersonIncomeSourceGroups>(
			`${baseUrl}/${personId}/income-source-groups`,
			{ transformResponse: [(r) => JSON.parse(r).map(person.main)] }
		),
}
