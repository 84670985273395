import { DefaultValues } from 'helpers/convertHelper'

export const manager = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		managerId: model.string(['managerId']),
		assignmentExpression: model.string(['assignmentExpression']),
		active: model.null<boolean>(['active']),
	}
}
