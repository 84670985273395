import { DefaultValues } from 'helpers/convertHelper'

export const court = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		courtNumber: model.string(['courtNumber']),
		nameOfCourt: model.string(['nameOfCourt']),
		link: model.string(['link']),
		decisionDate: model.string(['decisionDate']),
		dispute: model.string(['dispute']),
		decisionEnteredIntoForce: model.string(['decisionEnteredIntoForce']),
		avoidCollect: model.string(['avoidCollect']),
		earlyDebtRepaid: model.string(['earlyDebtRepaid']),
		negativeEffect: model.string(['negativeEffect']),
		debt: model.null<number>(['debt']),

		proceedingCategory: model.const(data.proceedingCategory, {
			LOAN: 'LOAN',
			NON_PROPERTY: 'NON_PROPERTY',
			LEGAL_DEBT: 'LEGAL_DEBT',
			BANKRUPTCY_INFO: 'BANKRUPTCY_INFO',
			CRIMINAL: 'CRIMINAL',
			ADMINISTRATIVE: 'ADMINISTRATIVE',
		} as const),
		proceedingCategoryRus: model.const(data.proceedingCategory, {
			LOAN: 'Суды по договорам займа, кредитные (за все время)',
			NON_PROPERTY: 'Неимущественные суды и/или суды, где лицо является Истцом',
			LEGAL_DEBT: 'Наличие текущей судебной задолженности, кроме кредитной',
			BANKRUPTCY_INFO: 'Наличие информации о банкротстве',
			CRIMINAL: 'Уголовные суды',
			ADMINISTRATIVE: 'Административные суды',
		} as const),

		participantStatus: model.const(data.participantStatus, {
			PLAINTIFF: 'PLAINTIFF',
			DEFENDANT: 'DEFENDANT',
			THIRD_PARTY: 'THIRD_PARTY',
		} as const),
		participantStatusRus: model.const(data.participantStatus, {
			PLAINTIFF: 'Истец',
			DEFENDANT: 'Ответчик',
			THIRD_PARTY: 'Третье лицо',
		} as const),
	}
}
