import CriticalError from 'components/Layout/CriticalError'
import { Component, type ErrorInfo } from 'react'

export class ErrorBoundary extends Component {
	state = {
		error: false,
	}

	componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
		// eslint-disable-next-line no-console
		console.dir({ error, errorInfo });

		this.setState({ error: true })
	}

	render() {
		const { children } = this.props
		const { error } = this.state

		return <>{error ? <CriticalError /> : children}</>
	}
}
