export default new Proxy(
	{
		REAL_ESTATE_LOAN: {
			name: 'REAL_ESTATE_LOAN',
			zmdiIconClass: 'zmdi-home',
			title: 'Заём под залог недвижимости',
		},
		SAVING: {
			name: 'SAVING',
			zmdiIconClass: '',
			title: 'Сбережение',
		},
		DIRECT_INVESTMENT: {
			name: 'DIRECT_INVESTMENT',
			zmdiIconClass: '',
			title: 'Прямые инвестиции',
		},
	},
	{
		get(target: any, p) {
			if (!target[p]) {
				return {
					name: 'UNKNOWN',
					zmdiIconClass: 'zmdi-triangle-up',
					title: 'Неизвестно',
				}
			}

			return Reflect.get(target, p)
		},
	}
)
