import { DefaultValues } from 'helpers/convertHelper'

import { check } from './check'

export const checkGroup = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		title: model.string(['title']),
		checks: model.array(['checks']).map(check),
	}
}
