import { Component, Fragment } from 'react'
import { DragDropContext } from 'react-beautiful-dnd'
import { connect } from 'react-redux'
import {
	getParticipantsFetchingStatus,
	init,
	onDragEnd,
	onDragStart,
	toggleSelectionFiles,
} from 'actions/filesInspector'
import { setModalShow } from 'actions/modalDialogs'
import PropTypes from 'prop-types'

import { Loading } from 'components/common'
import { utils } from 'helpers'

import Bucket from './Bucket'
import Inspector from './Inspector'

import './FilesInspector.scss'

/**
 * @description Сброс компонента делать в родительском методом resetFilesInspector в main
 * @param {string} initParticipantId - Пользователь чьи данные загружать изначально
 * @param {string} id - entityId
 * @param {boolean} onlyBucket - показывает только корзину без возможности выбора участников
 */
class FilesInspector extends Component {
	static propTypes = {
		onlyBucket: PropTypes.bool,
		id: PropTypes.string,
		initParticipantId: PropTypes.string,
		dragging: PropTypes.bool,
		selectedFiles: PropTypes.array,
		modalDialogsShow: PropTypes.object,

		// from store
		entityId: PropTypes.string,
		currentParticipant: PropTypes.string,

		init: PropTypes.func,
		onDragEnd: PropTypes.func,
		onDragStart: PropTypes.func,
		setModalShow: PropTypes.func,
		toggleSelectionFiles: PropTypes.func,
		getParticipantsFetchingStatus: PropTypes.func,
	}

	componentDidMount() {
		const { id, init, entityId, onlyBucket } = this.props

		if (!entityId && id) init(id)
		if (!onlyBucket) document.body.addEventListener('click', this.toggleSelectionFiles)
	}

	componentWillUnmount() {
		const { onlyBucket } = this.props

		if (!onlyBucket) document.body.removeEventListener('click', this.toggleSelectionFiles)
	}

	toggleSelectionFiles = (event) => {
		if (event.target.dataset?.isContextMenuItem === 'true') return

		const { dragging, selectedFiles, toggleSelectionFiles } = this.props

		if (utils.hasObjectLength(selectedFiles) && !dragging) {
			const bucketItem = 'files-inspector-files-bucket__item'
			const categoryItem = 'files-inspector-files-category__item'

			let classNamesList = []

			if (event && Array.isArray(event.path)) {
				event.path.forEach((selector) => classNamesList.push(selector.className))
			}

			classNamesList = classNamesList.join(' ')

			if (!classNamesList.includes(bucketItem) && !classNamesList.includes(categoryItem)) {
				toggleSelectionFiles()
			}
		}
	}

	render() {
		const {
			entityId,
			onDragEnd,
			onlyBucket,
			onDragStart,
			initParticipantId,
			getParticipantsFetchingStatus,
		} = this.props

		const dragDropContextOptions = {
			onDragEnd,
			onDragStart,
		}

		return (
			<div className='tabs-block-wrapper'>
				{entityId ? (
					<Fragment>
						{getParticipantsFetchingStatus() ? (
							<Loading />
						) : (
							<DragDropContext {...dragDropContextOptions}>
								<Bucket onlyBucket={onlyBucket} initParticipantId={initParticipantId} />
								{!onlyBucket && <Inspector />}
							</DragDropContext>
						)}
					</Fragment>
				) : (
					<div className='files-inspector__empty'>Не указаны обязательные параметры</div>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	...state.filesInspector.main,
	modalDialogsShow: state.modalDialogs,
})

const mapDispatchToProps = {
	init,
	onDragEnd,
	onDragStart,
	setModalShow,
	toggleSelectionFiles,
	getParticipantsFetchingStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesInspector)
