import { Component } from 'react'
import Modal from 'react-modal'
import cx from 'clsx'
import PropTypes from 'prop-types'

import { Button } from '@creditclubteam/react-components'

import './ConfirmDialog.scss'

class ConfirmDialog extends Component {
	static propTypes = {
		text: PropTypes.any,
		title: PropTypes.any,
		confirmButtonText: PropTypes.string,
		className: PropTypes.string,
		opened: PropTypes.bool.isRequired,
		overlayClassName: PropTypes.string,
		useFetching: PropTypes.bool,

		onOpen: PropTypes.func,
		onClose: PropTypes.func.isRequired,
		onResult: PropTypes.func.isRequired,
	}

	state = { show: false, isFetching: false }

	_isMounted = true

	componentDidMount() {
		const { opened } = this.props

		if (opened) {
			setTimeout(() => this.setState({ show: opened }), 5)
		}
	}

	componentWillUnmount() {
		this._isMounted = false
	}

	componentDidUpdate(prevProps) {
		const { opened } = this.props

		if (prevProps.opened !== opened) {
			setTimeout(() => this.setState({ show: opened }), 5)
		}
	}

	handleClick = async (answer) => {
		const { onResult, onClose, useFetching } = this.props

		if (useFetching) this.setState({ isFetching: true })

		await onResult(answer)

		if (useFetching && this._isMounted) this.setState({ isFetching: false })

		onClose()
	}

	handleClose = () => {
		const { isFetching } = this.state
		const { onClose } = this.props

		if (!isFetching) {
			onClose()
		}
	}

	render() {
		const { text, opened, onOpen, className, overlayClassName, confirmButtonText, title } =
			this.props
		const { show, isFetching } = this.state

		if (!opened) return null

		const confirmClassName = cx('confirm-dialog-content', {
			[className]: !!className,
		})

		const confirmOverlayClassName = cx('confirm-dialog-overlay', {
			[overlayClassName]: !!overlayClassName,
			show,
		})

		const confirmOptions = {
			ariaHideApp: false,
			onAfterOpen: onOpen,
			isOpen: show || opened,
			className: confirmClassName,
			portalClassName: 'confirm-dialog-portal',
			overlayClassName: confirmOverlayClassName,
			onRequestClose: this.handleClose,
		}

		const buttonCancelOptions = {
			title: 'Отмена',
			theme: 'light',
			onClick: this.handleClose,
			disabled: isFetching,
			className: 'confirm-dialog-buttons__cancel',
		}

		const buttonConfirmOptions = {
			type: 'buttonBlue',
			title: confirmButtonText || 'Подтвердить',
			onClick: () => this.handleClick(true),
			disabled: isFetching,
			className: 'confirm-dialog-buttons__confirm',
		}

		return (
			<Modal {...confirmOptions}>
				<div className='confirm-dialog-title'>{title || 'Подтверждение'}</div>
				{text && <div className='confirm-dialog-text'>{text}</div>}
				<div className='confirm-dialog-buttons'>
					<Button {...buttonCancelOptions} />
					<Button {...buttonConfirmOptions} />
				</div>
			</Modal>
		)
	}
}

export default ConfirmDialog
