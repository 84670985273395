import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { MortgageDto } from 'api/types/Mortgage'
import type { RosreestrMortgageDto } from 'api/types/Rosreestr'
import { commonActions } from 'reducers/common'
import type { KeysOfType } from 'types/util'

interface Encumbrace {
	id: string
	facilityId: string
	info: string
	owners: Array<string>
	address: string
	encumbrance: {
		facility: {
			facilityId: string
			encumbranceRecord: {
				number: number | string
				date: string
			}
		}
		declarants: Array<{
			declarantId: string
			participant: {
				customerType: string
				customerId: string
			}
			representative: unknown | null
		}>
		registrationInfo: unknown | null
		loanId: string
		konturInfo: {
			docFlowId: string
			docFlowState: string
			resreestrId: string
			statements: Array<{
				statementId: string
				statementType: string
				declarant: {
					declarantId: string
					participant: {
						customerId: string
						customerType: string
					}
					representative: unknown | null
				}
			}>
			govFees: Array<unknown>
			paymentServiceInfo: Array<unknown>
			resreestrResponses: Array<unknown>
		}
		error: {
			code: string
			message: string
			target: string
			context: unknown | null
			errors: Array<{ code: string; message: string; target: string; context: unknown | null }>
		}
		documents: Array<unknown>
		type: string
		id: string
		createdAt: string
	}
}

interface Statement {
	hasSignatureInProgress: boolean
	encumbranceId: string
	facilityId: string
	signed: true
	participant: {
		name: string
		roles: Array<string>
		customerType: string
		subjectId: string
		customerId: string
		activeCertificate: true
		director: null
	}
	statement: {
		statementId: string
		statementType: string
		kuvd: unknown | null
		declarant: {
			declarantId: string
			participant: {
				customerId: string
				customerType: string
			}
			representative: unknown | null
		}
		document: {
			documentId: string
			downloadPath: string
			title: string
		}
	}
}

interface ResponseSign {
	signed: boolean
	hasSignatureInProgress: boolean
}

interface State {
	encumbrances: Array<Encumbrace>
	participants: Array<any>
	documents: Array<any>
	statements: Array<Statement>
	mortgages: {
		data: Array<MortgageDto>
		isLoading: boolean

		// Сервис документооборота
		rosreestrMortgage: {
			data: Array<RosreestrMortgageDto>
			isLoading: boolean
		}

		responseSign: Record<
			string, // documentId
			Record<string, ResponseSign>
		>
	}
}

const initialState: State = {
	encumbrances: [],
	participants: [],
	documents: [],
	statements: [],
	mortgages: {
		data: [],
		isLoading: false,
		rosreestrMortgage: {
			data: [],
			isLoading: false,
		},
		responseSign: {},
	},
}

type SetDataInAction = PayloadAction<{ key: KeysOfType<State, any[]>; data: Array<any> }>
type UpdateEncumbranceAction = PayloadAction<{ id: string; data: any }>
type UpdateStatementStatusAction = PayloadAction<{ id: string; status: boolean }>

const rosreestr = createSlice({
	name: 'rosreestr',
	initialState,
	reducers: {
		updateEncumbrance(state, { payload: { id, data } }: UpdateEncumbranceAction) {
			const i = state.encumbrances.findIndex(({ id: v }) => v === id)

			state.encumbrances[i].encumbrance = data
		},

		addEncumbrance(state, { payload }: PayloadAction<any>) {
			state.encumbrances.push(payload)
		},

		updateStatementStatus(state, { payload: { id, status } }: UpdateStatementStatusAction) {
			const i = state.statements.findIndex((el) => el.statement.document.documentId === id)

			state.statements[i].hasSignatureInProgress = status
		},

		setDataIn(state, { payload: { key, data } }: SetDataInAction) {
			state[key] = data
		},

		setMortgages(state, { payload }: PayloadAction<Array<MortgageDto>>) {
			state.mortgages.data = payload
		},

		updateMortgage(state, { payload }: PayloadAction<MortgageDto>) {
			const index = state.mortgages.data.findIndex((el) => el.id === payload.id)

			if (index) {
				state.mortgages.data[index] = payload
			}
		},

		setMortgageLoading(state, { payload }: PayloadAction<boolean>) {
			state.mortgages.isLoading = payload
		},

		appendMortgages(state, { payload }: PayloadAction<MortgageDto>) {
			state.mortgages.data.push(payload)
		},

		setResponseSign(
			state,
			{
				payload: { key, data },
			}: PayloadAction<{
				key: string
				data: {
					declarantId: string
					signed: boolean
					hasSignatureInProgress: boolean
				}
			}>
		) {
			if (state.mortgages.responseSign[key]) {
				state.mortgages.responseSign[key][data.declarantId] = {
					signed: data.signed,
					hasSignatureInProgress: data.hasSignatureInProgress,
				}
			} else {
				state.mortgages.responseSign[key] = {
					[data.declarantId]: {
						signed: data.signed,
						hasSignatureInProgress: data.hasSignatureInProgress,
					},
				}
			}
		},

		setRosreestrMortgages(state, { payload }: PayloadAction<Array<RosreestrMortgageDto>>) {
			state.mortgages.rosreestrMortgage.data = payload
		},

		appendRosreestrMortgages(state, { payload }: PayloadAction<RosreestrMortgageDto>) {
			state.mortgages.rosreestrMortgage.data.push(payload)
		},

		reset: commonActions.getResetActionCreator(initialState),
	},
})

export const {
	addEncumbrance,
	reset,
	setDataIn,
	updateEncumbrance,
	updateStatementStatus,

	setMortgageLoading,
	setMortgages,
	appendMortgages,
	updateMortgage,
	setResponseSign,
	setRosreestrMortgages,
	appendRosreestrMortgages,
} = rosreestr.actions
export default rosreestr.reducer
