import { addDownloadFileStatus, hideNotice, pushNotice } from 'actions/notices'
import { addServerError } from 'actions/serverErrors'
import { uploadFiles } from 'actions/uploadFiles'
import api from 'api'
import * as actionTypes from 'const/actionTypes'
import { flatten, isEmpty } from 'ramda'

import { downloadFile as utilDownloadFile, fileUtils } from '@creditclubteam/helpers'
import { utils } from 'helpers'

const setFetchingStatus = (status) => ({
	type: actionTypes.DOCUMENTS_FILES_SET_FETCHING_STATUS,
	status,
})

const setData = (data) => ({
	type: actionTypes.DOCUMENTS_FILES_SET_DATA,
	data,
})

export const replaceFile = (documentId, data) => ({
	type: actionTypes.DOCUMENTS_FILES_REPLACE_FILE,
	documentId,
	data,
})

const setFileUrl = (id, newUrl) => ({
	type: actionTypes.DOCUMENTS_FILES_SET_FILE_URL,
	id,
	newUrl,
})

export const addFiles = (data) => ({
	type: actionTypes.DOCUMENTS_FILES_ADD_FILES,
	data,
})

const setSignature = (id, data) => ({
	type: actionTypes.DOCUMENTS_FILES_SET_SIGNATURE,
	id,
	data,
})

const setSimpleSignature = (id, data) => ({
	type: actionTypes.DOCUMENTS_FILES_SET_SIMPLE_SIGNATURE,
	id,
	data,
})

const updateParticipantSignatures = (customerId, documentId, data) => ({
	type: actionTypes.DOCUMENTS_SIGN_UPDATE_PARTICIPANT_SIGNATURES,
	customerId,
	documentId,
	data,
})

export const deleteFile = (id) => ({
	type: actionTypes.DOCUMENTS_FILES_DELETE_FILE,
	id,
})

export const resetDocumentFiles = () => ({
	type: actionTypes.DOCUMENTS_FILES_RESET,
})

export const onFileRefresh = (file) => (dispatch, getState) => {
	const listFiles = getState().document.files.data
	const fileIndex = listFiles.findIndex((f) => f.id === file.id)

	if (fileIndex >= 0) {
		dispatch(setFileUrl(file.id, file.url))
	}
}

/**
 * @param {string} ownerId - id владельца
 * @param {bool} shouldUpdateStore - если данный флаг true то работа с данными будет производиться в отношении глобального контейнера
 * если нет то функция вернёт результат
 */
export const fetchFiles =
	(ownerId, shouldUpdateStore = true) =>
	(dispatch) => {
		if (shouldUpdateStore) {
			dispatch(setFetchingStatus(true))
		}

		let page = 0
		const content = []

		const fetch = () =>
			api.document.search({ filter: { owners: [ownerId] }, page }).then(({ data }) => {
				content.push(...data.content)

				if (!data.last) {
					page += 1
					return fetch()
				}
			})

		return fetch()
			.then(() => {
				if (shouldUpdateStore) {
					dispatch(setData(content))
					dispatch(setFetchingStatus(false))
				} else {
					return content
				}
			})
			.catch((error) => {
				dispatch(
					addServerError({
						text: `Ошибка загрузки файлов для ${ownerId}`,
						details: utils.getDetailsFromError(error),
					})
				)

				if (shouldUpdateStore) {
					dispatch(setFetchingStatus(false))
				}
			})
	}

export const addDocuments =
	({ ownerId, files, categoryId, unsupportedFiles = null, metadata = [], ownerType }) =>
	(dispatch, getState) => {
		return dispatch(
			uploadFiles({
				files,
				ownerId,
				ownerType,
				categoryId,
				unsupportedFiles,
				metadata,
				onFailure: () => {
					//
				},
				onSuccess: (documents, uploadOwnerId) => {
					const { entityId } = getState().document.categories

					if (entityId === uploadOwnerId) {
						dispatch(addFiles(documents))
					}
				},
			})
		)
	}

export const deleteDocument = (ownerId, id) => (dispatch) => {
	return api.document
		.delete(ownerId, id)
		.then(() => {
			dispatch(deleteFile(id))
		})
		.catch((e) => {
			dispatch(
				addServerError({
					text: `Не удалось удалить файл`,
					details: utils.getDetailsFromError(e),
				})
			)
		})
}

export const addSigner = (signerInfo) => (dispatch, getState) => {
	return api.document
		.addSigner(signerInfo)
		.then(async ({ data: response }) => {
			const { data } = await api.document.search({ filter: { id: response.map(({ id }) => id) } })

			const { files, sign } = getState().document

			const customerId = signerInfo.signer.customerId
			const participant = sign.participants.find(
				(participant) => participant.customerId === customerId
			)

			data.content.forEach((item) => {
				if (!isEmpty(files.data)) {
					const document = files.data.find((e) => e.id === item.id)

					if (document) {
						dispatch(setSignature(item.id, item.signatures))
						dispatch(setSimpleSignature(item.id, item.simpleSignatures))
					}
				}

				const hasParticipantDocument = !!participant.signableDocuments.find((e) => e.id === item.id)

				if (hasParticipantDocument) {
					dispatch(updateParticipantSignatures(customerId, item.id, item.signatures))
				}
			})

			return data
		})
		.catch((error) => {
			dispatch(
				addServerError({
					text: `Не удалось отправить на подпись`,
					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		})
}

export const deleteSimpleSignatureSigner = (signerInfo) => (dispatch) => {
	return api.document
		.deleteSimpleSignatureSigner(signerInfo)
		.then(async ({ data: [{ id }] }) => {
			const { data } = await api.document.get(id)

			dispatch(setSimpleSignature(data.id, data.simpleSignatures))

			return data
		})
		.catch((e) => {
			dispatch(
				addServerError({
					text: `Не удалось удалить подписчиков`,
					details: utils.getDetailsFromError(e),
				})
			)
		})
}

export const deleteStandardSignatureSigner = (signerInfo) => (dispatch) => {
	return api.document
		.deleteSigner(signerInfo)
		.then(async ({ data: [{ id }] }) => {
			const { data } = await api.document.get(id)

			dispatch(setSignature(data.id, data.signatures))

			return data
		})
		.catch((e) => {
			dispatch(
				addServerError({
					text: `Не удалось удалить подписчиков`,
					details: utils.getDetailsFromError(e),
				})
			)
		})
}

export const renameDocument = (documentId, title) => (dispatch) => {
	return api.document
		.rename(documentId, title)
		.then(async () => {
			const { data } = await api.document.get(documentId)

			dispatch(replaceFile(documentId, data))
		})
		.catch((err) => {
			dispatch(
				addServerError({
					text: `Не удалось переименовать файл`,
					details: utils.getDetailsFromError(err),
				})
			)

			throw err
		})
}

export const getObjectKeys = (keys) => async (dispatch) => {
	try {
		const { data } = await api.document.getDownloadLinks({ objectKeys: keys.filter(Boolean) })

		return data
	} catch (error) {
		dispatch(
			addServerError({
				text: `Ошибка при скачивании файла`,
				details: utils.getDetailsFromError(error),
			})
		)

		return []
	}
}

export const downloadFile = (parameters) => async (dispatch) => {
	const { objectKey, lifeTime, onError, filename, disableActions = false } = parameters

	try {
		const {
			data: [file],
		} = await api.document.getDownloadLinks({ objectKeys: [objectKey], lifeTime })

		const title = filename || fileUtils.getFileNameFromAWSUrl(file.uri)

		const options = { url: file.uri }

		if (!disableActions) {
			options.onError = onError
				? onError
				: (err) => {
						dispatch(
							addServerError({
								text: `Ошибка при скачивании файла`,
								details: utils.getDetailsFromError(err),
							})
						)
						dispatch(hideNotice(file.objectKey))
				  }
			options.onDownloadProgress = (e) => dispatch(addDownloadFileStatus(e, file.objectKey, title))
		}

		utilDownloadFile(options, title)
	} catch {
		return Promise.reject()
	}
}

export const pollingDocuments = (ownerId, cancelToken) => (dispatch, getState) =>
	Promise.all([
		api.document.search({
			filter: {
				owners: [ownerId],
			},
			cancelToken,
		}),
		api.document.search({
			filter: {
				owners: [ownerId],
			},
			page: 1,
			cancelToken,
		}),
		api.document.search({
			filter: {
				owners: [ownerId],
			},
			page: 2,
			cancelToken,
		}),
		api.document.search({
			filter: {
				owners: [ownerId],
			},
			page: 3,
			cancelToken,
		}),
	]).then((data) => {
		const content = flatten(data.map(({ data }) => data.content))
		const listFiles = getState().document.files.data

		if (listFiles.length !== content.length) {
			dispatch(setData(content))
			dispatch(pushNotice({ message: 'Список файлов был измененён' }))
			return true
		}
	})
