import { ENTITY_TYPES } from 'const'

import { DefaultValues } from 'helpers/convertHelper'

import { participant } from './participant'
import { status } from './status'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		address: model.null<Record<string, any>>(['address']),
		deliveryTypeId: model.string(['deliveryTypeId']),
		externalId: model.string(['externalId']),
		loanId: model.string(['loanId']),
		managerId: model.string(['managerId']),
		representativeId: model.string(['representativeId']),
		meetingDate: model.string(['meetingDate']),
		createdAt: model.string(['createdAt']),
		number: model.null<number>(['number']),
		status: status(data.status),
		participants: model.array(['participants']).map(participant),
		$$type: ENTITY_TYPES.DELIVERY,
	}
}
