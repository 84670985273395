import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { Deposit } from 'converters/deposit'
import { commonActions } from 'reducers/common'
import type { PayloadStatus, RootState } from 'types/redux'
import type { Maybe } from 'types/util'

const depositAdapter = createEntityAdapter<Deposit.Brief>()

export const selectDeposit = depositAdapter.getSelectors((state: RootState) => state.deposit.list)
export interface DepositFilter {
	status: Maybe<string[]>
	query: Maybe<string>
	sort: 'newest' | 'oldest' | 'maturest' | 'youngest'
}

export const filter: DepositFilter = {
	status: null,
	query: null,
	sort: 'youngest',
}

export const initialState = depositAdapter.getInitialState({
	status: 'pending' as PayloadStatus,
	preserveFilter: false,
	filter,
})

const list = createSlice({
	name: 'deposit/list',
	initialState,
	reducers: {
		setAll: depositAdapter.setAll,

		// для понимания когда надо ставить дефолтные фильтры добавил этот флаг,
		// т.к. у этой сущности отсутствует pipeline
		setPreserveFilter(state, { payload }: PayloadAction<boolean>) {
			state.preserveFilter = payload
		},

		setStatus: commonActions.setStatus,
		setFilter: commonActions.setFilter(),
		reset: commonActions.reset(initialState),
	},
})

export const { actions: depositListReducerActions } = list
export default list.reducer
