import { ENTITY_TYPES } from 'const'

import { DefaultValues } from 'helpers/convertHelper'

import { client } from './client'
import { manager } from './manager'
import { moneyMovementOperation } from './moneyMovementOperation'
import { program } from './program'
import { stats } from './stats'
import { upcomingPayment } from './upcomingPayment'
import { workflow } from './workflow'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		number: model.string(['number']),
		startingDate: model.string(['startingDate']),
		maturityDate: model.string(['maturityDate']),
		closingDate: model.string(['closingDate']),
		amount: model.null<number>(['amount']),
		termMonths: model.null<number>(['termMonths']),
		upcomingPayment: upcomingPayment(data?.upcomingPayment),
		stats: stats(data?.stats),
		manager: manager(data?.manager),
		program: program(data?.program),
		workflow: workflow(data?.workflow),
		moneyMovementOperations: model.array(['moneyMovementOperations']).map(moneyMovementOperation),
		paymentType: model.const(data?.paymentType, {
			MONTHLY: 'MONTHLY',
			AT_MATURITY: 'AT_MATURITY',
		} as const),
		paymentTypeRus: model.const(data?.paymentType, {
			MONTHLY: 'Ежемесячно',
			AT_MATURITY: 'В конце срока',
		} as const),
		client: client(data?.client),
		$$type: ENTITY_TYPES.DEPOSIT,
	}
}
