import { Component } from 'react'
import InputMask from 'react-input-mask'
import classNames from 'clsx'
import PropTypes from 'prop-types'

import './Input.scss'

/**
 * @param {string} mask - https://github.com/sanniassin/react-input-mask
 */
class Input extends Component {
	static propTypes = {
		// TODO выпилить и переехать на inputRef
		getRef: PropTypes.any,

		error: PropTypes.bool,
		inputRef: PropTypes.any,
		onlyNum: PropTypes.bool,
		value: PropTypes.oneOfType([PropTypes.array, PropTypes.number, PropTypes.string]),
		type: PropTypes.string,
		fadeIn: PropTypes.bool,
		mask: PropTypes.string,
		disabled: PropTypes.bool,
		autoFocus: PropTypes.bool,
		maskChar: PropTypes.string,
		className: PropTypes.string,
		noTransform: PropTypes.bool,
		placeholder: PropTypes.string,

		onBlur: PropTypes.func,
		onKeyUp: PropTypes.func,
		onFocus: PropTypes.func,
		onChange: PropTypes.func.isRequired,
	}

	static defaultProps = {
		fadeIn: false,
		maskChar: '_',
	}

	constructor(props) {
		super(props)

		this.state = {
			active: false,
			show: !props.fadeIn,
		}
	}

	componentDidMount() {
		const { getRef, fadeIn } = this.props

		if (getRef) getRef(this.nodeElement)

		if (fadeIn) setTimeout(() => this.setState({ show: true }), 55)
		else this.setState({ show: true })
	}

	componentWillUnmount() {
		this.setState({ show: false })
	}

	handleChange = (e) => {
		const { onChange, onlyNum } = this.props

		if (onChange) {
			if (onlyNum) {
				if (!isNaN(e.target.value) || e.target.value === '') onChange(e.target.value, e)
				return
			}

			onChange(e.target.value, e)
		}
	}

	handleKeyUp = (e) => {
		const { onKeyUp } = this.props

		if (onKeyUp) onKeyUp(e)
	}

	handleBlur = () => {
		const { onBlur } = this.props

		this.setState({ active: false })
		if (onBlur) onBlur()
	}

	handleFocus = (e) => {
		const { onFocus } = this.props

		this.setState({ active: true })
		if (onFocus) onFocus(e)
	}

	render() {
		const {
			type,
			mask,
			value,
			error,
			title,
			disabled,
			inputRef,
			maskChar,
			className,
			autoFocus,
			placeholder,
			noTransform,
		} = this.props

		const { show, active } = this.state

		const boxClassName = classNames('input-box', {
			show,
			['input-error']: error,
			[className]: !!className,
			active: !noTransform && active,
		})

		const inputOptions = {
			autoFocus,
			placeholder,
			name,
			type,
			disabled,
			value: Array.isArray(value) ? value.join(', ') : value,

			onBlur: this.handleBlur,
			onKeyUp: this.handleKeyUp,
			onFocus: this.handleFocus,
			onChange: this.handleChange,
			ref: inputRef ? inputRef : (input) => (this.nodeElement = input),
		}

		if (mask) inputOptions.mask = mask

		return (
			<div className={boxClassName}>
				{title && <div className='input-box__title'>{title}</div>}
				{mask ? <InputMask {...inputOptions} maskChar={maskChar} /> : <input {...inputOptions} />}
			</div>
		)
	}
}

export default Input
