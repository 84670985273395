import 'moment/dist/locale/ru'

import { StrictMode } from 'react'
import ReactDOM from 'react-dom'
import { Root } from 'client/Root'

import 'normalize.css'
import 'rc-dropdown/assets/index.css'
import 'rc-pagination/assets/index.css'
import 'react-image-gallery/styles/css/image-gallery.css'
import 'scss/common.scss'

// Монтируем приложение
ReactDOM.render(
	<StrictMode>
		<Root />
	</StrictMode>,
	document.getElementById('root')
)
