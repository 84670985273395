import PropTypes from 'prop-types'

import styles from './Item.module.scss'

const Item = ({ message, onClick, progress }) => {
	return (
		<div className={styles.wrapper}>
			<div onClick={onClick} className={styles.close} />
			<div className={styles.inner}>
				<div className={styles.message}>{message}</div>
				{Boolean(progress) && <div style={{ width: `${progress}%` }} className={styles.progress} />}
			</div>
		</div>
	)
}

Item.propTypes = {
	message: PropTypes.string.isRequired,

	onClick: PropTypes.func.isRequired,
	progress: PropTypes.number,
}

export default Item
