import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import type { DeliveryTypes } from 'converters/deliveryTypes'
import { commonActions } from 'reducers/common'
import type { TRedux } from 'types'

const typesAdapter = createEntityAdapter<DeliveryTypes.Main>()

export const typesSelector = typesAdapter.getSelectors<TRedux.RootState>(
	(state) => state.delivery.types
)

const initialState = typesAdapter.getInitialState({
	fetching: false,
})

const types = createSlice({
	name: 'delivery/types',
	initialState,

	reducers: {
		setData: typesAdapter.setAll,
		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

const { actions } = types

export { actions }
export default types.reducer
