import { DefaultValues } from 'helpers/convertHelper'

import { analytics } from './analytics'
import { court } from './court'

export const responseDataCourts = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		efrsbNegative: model.null<boolean>(['efrsbNegative']),
		outOfCourtBankruptcy: model.null<boolean>(['outOfCourtBankruptcy']),

		analytics: analytics(data?.analytics),
		courts: model.array(['courts']).map(court),
	}
}
