import { type Options } from 'api/types/Options'
import type { Pageable } from 'api/types/Pageable'
import axios, { type AxiosResponse } from 'axios'
import { type PartnerDto, partnerConverter } from 'converters/partner'
import { mergeDeepRight } from 'rambda'
import { type PartnerFilter } from 'reducers/partner/list'

import { join } from '@creditclubteam/kit/helpers'

declare namespace PartnerApi {
	export type Get = PartnerDto.Main
	export type Update = PartnerDto.Main
	export type Search = Pageable<PartnerDto.Brief>

	namespace Employees {
		export type Get = PartnerDto.EmployeeMain
		export type Search = Pageable<PartnerDto.EmployeeBrief>
	}
}

const baseUrl = '/v4/partners'
const employeesBaseUrl = '/v4/partners/employees'

export default {
	get: (partnerId: string) =>
		axios
			.get<PartnerApi.Get>(`${baseUrl}/${partnerId}`)
			.then((response) =>
				Object.assign({}, response, { data: partnerConverter.main(response.data) })
			),

	products: (partnerId: string) =>
		axios
			.get(`${baseUrl}/${partnerId}/products`)
			.then((response) =>
				Object.assign({}, response, { data: response.data.map(partnerConverter.productView) })
			),

	plugProduct: ({
		params,
		partnerId,
	}: {
		partnerId: string
		params: { enabled: boolean; product: string }
	}) => axios.post<void>(`${baseUrl}/${partnerId}`, null, { params }),

	update: ({ id, payload }: { id: string; payload: Record<string, any> }) =>
		axios
			.patch<PartnerApi.Update>(`${baseUrl}/${id}`, payload, {
				headers: {
					'Content-Type': 'application/merge-patch+json',
				},
			})
			.then((response) =>
				Object.assign({}, response, { data: partnerConverter.main(response.data) })
			),

	search: (options: Options<PartnerFilter>) => {
		const params = new URLSearchParams()

		if (options.filter) {
			options.filter.legalType?.forEach((v) => params.append('legalType', v))
			options.filter.managersIds?.forEach((v) => params.append('managersIds', v))
			options.filter.statuses?.forEach((v) => params.append('statuses', v))
		}

		return axios
			.get(join([baseUrl, params.toString()], '?'), {
				params: {
					size: options.size,
					sort: options?.sort,
					page: options.page,
					query: options.filter?.query,
				},
			})
			.then((response) =>
				mergeDeepRight(response, {
					data: {
						content: response.data.content.map(partnerConverter.brief),
					},
				})
			) as Promise<AxiosResponse<PartnerApi.Search>>
	},

	updateStatus: ({ partnerId, params }: { partnerId: string; params: Record<string, any> }) =>
		axios.patch<void>(`${baseUrl}/${partnerId}/status`, null, { params }),

	employees: {
		get: (employeeId: string) =>
			axios
				.get<PartnerApi.Employees.Get>(`${employeesBaseUrl}/${employeeId}`)
				.then((response) =>
					Object.assign({}, response, { data: partnerConverter.employeeMain(response.data) })
				),

		search: (options: Options<{ partnerId: string }>) =>
			axios
				.get(employeesBaseUrl, {
					params: {
						size: options.size,
						sort: options?.sort,
						page: options.page,
						partnerId: options.filter?.partnerId,
					},
				})
				.then(
					(response) =>
						mergeDeepRight(response, {
							data: {
								content: response.data.content.map(partnerConverter.employeeBrief),
							},
						}) as AxiosResponse<PartnerApi.Employees.Search>
				),

		create: ({ payload }: { payload: Record<string, any> }) =>
			axios.post<void>(employeesBaseUrl, payload),

		update: ({
			employeeId,
			payload,
		}: {
			employeeId: string
			partnerId: string
			payload: Record<string, any>
		}) =>
			axios.patch(`${employeesBaseUrl}/${employeeId}`, payload, {
				headers: {
					'Content-Type': 'application/merge-patch+json',
				},
			}),
	},
}
