export const WORKER_TYPES = {
	ADMIN: 'ADMIN',
	AGENT: 'AGENT',
	LAWYER: 'LAWYER',
	BROKER: 'BROKER',
	EMPLOYEE: 'EMPLOYEE',
	SUPERBROKER: 'SUPERBROKER',
	MANAGER: 'MANAGER',
	COLLECTOR: 'COLLECTOR',
	SUPERVISOR: 'SUPERVISOR',
}
