import { CALCULATION } from 'const/calculations'

import { DefaultValues } from 'helpers/convertHelper'

import { payment } from './payment'

export const calculationV2 = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		loanAmount: model.null<number>(['loanAmount']),
		loanDurationMonths: model.null<number>(['loanDurationMonths']),
		paymentType: model.const(data?.paymentType, CALCULATION.PAYMENT_TYPE.ENG),
		paymentTypeRus: model.const(data?.paymentType, CALCULATION.PAYMENT_TYPE.RUS),
		creditProgramId: model.string(['creditProgramId']),
		payments: model.array(['payments']).map(payment),
	}
}
