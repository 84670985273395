import { DefaultValues } from 'helpers/convertHelper'

export const transition = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		name: model.string(['name']),
		needComment: model.null<boolean>(['needComment']),
		hidden: model.null<boolean>(['hidden']),
	}
}
