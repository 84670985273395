import { Component } from 'react'
import outsideClick from 'react-click-outside'
import cx from 'clsx'
import PropTypes from 'prop-types'

import styles from './ConfirmDelete.module.scss'

class ConfirmDelete extends Component {
	static propTypes = {
		onClose: PropTypes.func.isRequired,
		onDelete: PropTypes.func.isRequired,
	}

	state = {
		show: false,
	}

	componentDidMount() {
		setTimeout(() => {
			this.setState({ show: true })
		})
	}

	handleClickOutside = () => {
		this.cancel()
	}

	handleDelete = () => {
		const { onDelete } = this.props

		onDelete()
	}

	cancel = () => {
		const { onClose } = this.props
		this.setState({ show: false })

		setTimeout(onClose, 100)
	}

	render() {
		const { show } = this.state

		return (
			<div
				className={cx(styles.wrapper, {
					[styles.show]: show,
				})}
			>
				<span className={styles.question}>Удалить комментарий?</span>
				<span onClick={this.cancel} className={styles.option}>
					Нет
				</span>
				<span className={styles.option} onClick={this.handleDelete}>
					Да
				</span>
			</div>
		)
	}
}

export default outsideClick(ConfirmDelete)
