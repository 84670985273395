import { combineReducers } from 'redux'

import addCategoryForm from './addCategoryForm'
import bucket from './bucket'
import inspector from './inspector'
import main from './main'

export default combineReducers({
	main,
	bucket,
	inspector,
	addCategoryForm,
})
