import type { ScoringDocuments } from 'converters/scoring/person/documents/types'
import type { TUtils } from 'types'

import { moneyUtils } from '@creditclubteam/helpers'
import { DefaultValues } from 'helpers/convertHelper'

const getPeriods = (data: Record<string, any>): ScoringDocuments.Field[] => {
	const model = new DefaultValues(data)

	const [year = '', month = '']: string[] = model.string(['period']).split('-')
	const amount = model.null<number>(['amount'])

	return [
		{
			collection: 'periods',
			type: 'MASK',
			key: 'period-year',
			value: year,
			title: 'Год',
			mask: '9999',
		},
		{
			type: 'MASK',
			key: 'period-month',
			value: month.startsWith('0') ? month.slice(1) : month,
			title: 'Номер месяца',
			transformValue: (v) => (v && Number(v) < 10 ? `0${v}` : v),
			mask: '99',
		},
		{
			type: 'NUM',
			key: 'amount',
			value: amount,
			readonlyValue: moneyUtils.RUB(amount),
			title: 'Сумма дохода',
		},
	]
}

export const bankAccountStatement = <T extends Record<string, any>>(
	data: T,
	isTemplate?: boolean
): TUtils.Maybe<ScoringDocuments.SourceDocument> => {
	if (!isTemplate && !data) return null

	const model = new DefaultValues(data)

	const rows = model.array(['periods'])

	return {
		id: 'bankAccountStatement',
		collections: [['periods', getPeriods]],
		rows: rows.map(getPeriods),
	}
}
