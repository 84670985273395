import { combineReducers } from 'redux'

import collection from './collection'
import collectionEvents from './collectionEvents'
import list from './list'
import single from './single'

export default combineReducers({
	list,
	single,
	collection,
	collectionEvents,
})
