import { setPagination, solveParamsPagination } from 'actions/listParameters'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import type { Options } from 'api/types/Options'
import { DEPOSIT_STATUSES_RUS } from 'const/deposit'
import { isEmpty } from 'ramda'
import type { DepositFilter } from 'reducers/deposit/list'
import { depositListReducerActions } from 'reducers/deposit/list'
import type { TRedux } from 'types'
import type { TAction } from 'types/redux'

import { utils } from 'helpers'

export const resetDepositList = depositListReducerActions.reset

export const setDefaultDepositFilter = (): TRedux.TAction<void> => (dispatch, getState) => {
	const { preserveFilter } = getState().deposit.list

	if (preserveFilter) return

	// статусы которые отключаем по-умолчанию
	const disabledByDefaultStatuses: string[] = ['CLOSED', 'REFUSED']

	// формируем массив активных статусов
	const defaultStatuses = Object.keys(DEPOSIT_STATUSES_RUS).filter(
		(status) => !disabledByDefaultStatuses.includes(status)
	)

	if (defaultStatuses && !isEmpty(defaultStatuses) && !isEmpty(disabledByDefaultStatuses))
		dispatch(depositListReducerActions.setFilter({ key: 'status', value: defaultStatuses }))
}

export const getDepositList =
	(parameters: Options<DepositFilter> = {}): TAction<Promise<void>> =>
	async (dispatch, getState) => {
		if (getState().deposit.list.status !== 'pending')
			dispatch(depositListReducerActions.setStatus('pending'))

		try {
			dispatch(setDefaultDepositFilter())

			if (!getState().deposit.list.preserveFilter) {
				dispatch(depositListReducerActions.setPreserveFilter(true))
			}

			const { data } = await api.deposit.search({
				...dispatch(solveParamsPagination('number')),
				...parameters,
				filter: Object.assign({}, getState().deposit.list.filter, parameters?.filter),
			})

			const { totalElements, number, totalPages } = data

			dispatch(
				setPagination({
					totalPages,
					totalElements,
					paged: true,
					currentPage: number,
				})
			)

			dispatch(depositListReducerActions.setAll(data.content))
			dispatch(depositListReducerActions.setStatus('fulfilled'))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить сбережения',
					details: utils.getDetailsFromError(error),
				})
			)

			dispatch(depositListReducerActions.setStatus('rejected'))
		}
	}
