import { useEffect } from 'react'
import { connect } from 'react-redux'
import { calendarEventFormReset } from 'actions/calendar'
import PropTypes from 'prop-types'

import { Loading, ModalDialog } from 'components/common'

import Form from './Form'

const CalendarEventForm = ({
	form,
	fetchingRecurrence,
	modalDialogsShow,
	calendarEventFormReset,
}) => {
	useEffect(() => {
		if (!modalDialogsShow.calendarEventForm) {
			calendarEventFormReset()
		}
	}, [calendarEventFormReset, modalDialogsShow.calendarEventForm])

	const modalDialogProps = {
		large: true,
		onClose: calendarEventFormReset,
		opened: modalDialogsShow.calendarEventForm,
		title: fetchingRecurrence
			? 'Загрузка...'
			: form.id
			? 'Редактировать событие'
			: 'Создать событие',
	}

	return (
		<ModalDialog {...modalDialogProps}>
			{fetchingRecurrence ? <Loading max /> : <Form />}
		</ModalDialog>
	)
}

CalendarEventForm.propTypes = {
	form: PropTypes.object.isRequired,
	fetchingRecurrence: PropTypes.bool.isRequired,
	modalDialogsShow: PropTypes.object.isRequired,

	calendarEventFormReset: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	...state.calendar.eventForm,
	modalDialogsShow: state.modalDialogs,
})

const mapDispatchToProps = {
	calendarEventFormReset,
}

export default connect(mapStateToProps, mapDispatchToProps)(CalendarEventForm)
