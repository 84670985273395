import type { Pageable } from 'api/types/Pageable'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type { DeliveryTypes } from 'converters/deliveryTypes'
import { deliveryTypes } from 'converters/deliveryTypes'
import { mergeDeepRight } from 'ramda'

const baseUrl = '/v1/delivery-types/'

export default {
	search: () => {
		return axios
			.get(baseUrl, {
				params: {
					size: 100,
					page: 0,
				},
			})
			.then(
				(response) =>
					mergeDeepRight(response, {
						data: {
							content: response.data.content.map(deliveryTypes.main),
						},
					}) as AxiosResponse<Pageable<DeliveryTypes.Main>>
			)
	},

	get: (id: string) =>
		axios
			.get<DeliveryTypes.Main>(`${baseUrl}/${id}`)
			.then((response) => Object.assign({}, response, { data: deliveryTypes.main(response.data) })),
}
