import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { DecisionDto } from 'converters/decision'
import { commonActions } from 'reducers/common'
import type { PayloadStatus } from 'types/redux'
import type { Maybe } from 'types/util'

interface State {
	data: Maybe<DecisionDto.Main>
	status: PayloadStatus
}

const initialState: State = {
	data: null,
	status: 'pending',
}

const decision = createSlice({
	name: 'decision',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<State['data']>) {
			state.data = payload
		},

		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

export default decision.reducer
export const { actions: decisionReducerActions } = decision
