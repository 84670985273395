import { Component } from 'react'
import { connect } from 'react-redux'
import { activateParticipant, getParticipantsList } from 'actions/filesInspector'
import PropTypes from 'prop-types'

import { Select } from 'components/common'
import { utils } from 'helpers'

import './Controls.scss'

class Controls extends Component {
	static propTypes = {
		currentParticipant: PropTypes.string,

		getParticipantsList: PropTypes.func.isRequired,
		activateParticipant: PropTypes.func.isRequired,
	}

	handleChangeParticipant = ({ id }) => {
		const { activateParticipant } = this.props

		activateParticipant(id)
	}

	render() {
		const { currentParticipant, getParticipantsList } = this.props

		const options = utils.formatParticipantsToSelect(getParticipantsList())

		return (
			<div className='files-inspector-controls'>
				<div className='files-inspector-controls__select'>
					<Select
						options={options}
						value={currentParticipant}
						defaultTitle='Участник не выбран'
						onChange={this.handleChangeParticipant}
					/>
				</div>
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	...state.filesInspector.main,
})

const mapDispatchToProps = {
	activateParticipant,
	getParticipantsList,
}

export default connect(mapStateToProps, mapDispatchToProps)(Controls)
