import { DefaultValues } from 'helpers/convertHelper'
import { encumbrance } from './encumbrance';

export const right = (data?: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		type: model.string(['type']),
		regDate: model.string(['regDate']),
		regNumber: model.string(['regNumber']),
		ownershipDocuments: model.array<string[]>(['ownershipDocuments']),
		encumbrance: model.array(['encumbrance']).map(encumbrance),
		share: {
			numerator: model.null<number>(['share', 'numerator']),
			denominator: model.null<number>(['share', 'denominator']),
		},
		owners: model.array<string[]>(['owners']),
	}
}
