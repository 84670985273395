import { DefaultValues } from 'helpers/convertHelper'

export const balanceSheetSummary = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		amountForLastReportingYear: model.null<number>(['amountForLastReportingYear']),
		amountForPenultimateReportingYear: model.null<number>(['amountForPenultimateReportingYear']),
		amountFor2YearsBeforeReportingYear: model.null<number>(['amountFor2YearsBeforeReportingYear']),
	}
}
