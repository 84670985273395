import { DefaultValues } from 'helpers/convertHelper'

export const titlePart = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		loanAmount: model.null<number>(['loanAmount']),
		maxLoanAmount: model.null<number>(['maxLoanAmount']),
		loanDurationMonths: model.null<number>(['loanDurationMonths']),
		creditProgram: {
			name: model.string(['creditProgram', 'name']),
			marketingName: model.string(['creditProgram', 'marketingName']),
		},
		interestRate: {
			normalizedRate: model.null<number>(['interestRate', 'normalizedRate']),
			additionalRates: model.array(['interestRate', 'additionalRates']).map((_, i) => ({
				from: model.null<number>(['interestRate', 'additionalRates', i, 'from']),
				to: model.null<number>(['interestRate', 'additionalRates', i, 'to']),
				rate: model.null<number>(['interestRate', 'additionalRates', i, 'rate']),
				category: model.string<'ONE_TIME_PAYMENT' | 'INCREASED_PAYMENT'>([
					'interestRate',
					'additionalRates',
					i,
					'category',
				]),
			})),
		},
		monthlyPayment: {
			normalizedPayment: model.null<number>(['monthlyPayment', 'normalizedPayment']),
			additionalPayments: model.array(['monthlyPayment', 'additionalPayments']).map((_, i) => ({
				from: model.null<number>(['monthlyPayment', 'additionalPayments', i, 'from']),
				to: model.null<number>(['monthlyPayment', 'additionalPayments', i, 'to']),
				payment: model.null<number>(['monthlyPayment', 'additionalPayments', i, 'payment']),
				category: model.string<'ONE_TIME_PAYMENT' | 'INCREASED_PAYMENT'>([
					'monthlyPayment',
					'additionalPayments',
					i,
					'category',
				]),
			})),
		},
		paymentType: {
			id: model.string(['paymentType', 'id']),
			title: model.string(['paymentType', 'title']),
		},
	}
}
