import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import type { InvestmentLead } from 'converters/investmentLead'
import { commonActions } from 'reducers/common'
import { workerSelector } from 'reducers/worker'
import type { TRedux } from 'types'

const managersAdapter = createEntityAdapter<InvestmentLead.Manager>({
	selectId: ({ managerId }) => managerId,
})

export const managersSelector = managersAdapter.getSelectors<TRedux.RootState>(
	(state) => state.investmentLead.managers
)

export const matchedManagersSelector = createSelector(
	[workerSelector.selectAll, managersSelector.selectIds],
	(workers, managers) => workers.filter(({ id }) => managers.includes(id))
)

const initialState = managersAdapter.getInitialState({
	fetching: false,
})

const managers = createSlice({
	name: 'investmentLead/managers',
	initialState,
	reducers: {
		setAll: managersAdapter.setAll,
		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions } = managers
export default managers.reducer
