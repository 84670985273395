import { DefaultValues } from 'helpers/convertHelper'

export const option = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
		selected: model.null<boolean>(['selected']),
	}
}
