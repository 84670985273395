import { resetEntrepreneurList } from 'actions/entrepreneur'
import { setPagination, solveParamsPagination } from 'actions/listParameters'
import { resetPersonList } from 'actions/person'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import { actions } from 'reducers/organization/list'
import type { TAction } from 'types/redux'

import { utils } from 'helpers'
import { defaultToApi } from 'helpers/convertHelper'
import { DefaultValues } from 'helpers/convertHelper'

export const addOrganizationInList = actions.add
export const setOrganizationListData = actions.setData
export const resetOrganizationList = actions.reset
export const updateOrganizationInList = actions.update
export const deleteOrganizationInList = actions.delete
export const organizationListChangeFilterParameter = actions.setFilter

// сброс на те случаи, когда юзер на табе
export const resetOrganizationsPage = (): TAction => (dispatch) => {
	dispatch(resetOrganizationList('data'))
	dispatch(resetPersonList())
	dispatch(resetEntrepreneurList())
}

/**
 * @description - Запрашивает организации и склеивает их с входным обьектом, далее записывает в стор
 * @param {array} minOrganizations - Входной массив
 */
export const fetchOrganizationsWithMerge =
	(minOrganizations: Record<string, any>[]): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			if (!utils.hasObjectLength(minOrganizations)) return
			dispatch(actions.setFetchingStatus(true))

			const ids = minOrganizations.map(({ id }) => id)

			const { data } = await api.organization.search({ filter: { ids }, disableCancelToken: true })

			const organizationsList = data.content.map((org) => {
				const minOrganization = minOrganizations.find(({ id }) => id === org.id)
				const defaultValue = new DefaultValues(minOrganization)

				return Object.assign({}, org, {
					mainContact: defaultValue.null(['mainContact']),
					part: defaultValue.null(['part']),
					roles: defaultValue.array(['roles']),
				})
			})

			dispatch(setOrganizationListData(organizationsList))
			dispatch(actions.setFetchingStatus(false))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Ошибка загрузки ЮЛ',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const getOrganizationList =
	(parameters = {}): TAction<Promise<void>> =>
	async (dispatch, getState) => {
		try {
			dispatch(actions.setFetchingStatus(true))

			const requestParameters = {
				...dispatch(solveParamsPagination('number')),
				...parameters,
				filter: getState().organization.list.filter,
			}

			const { data } = await api.organization.search(requestParameters)
			const { totalElements, number, totalPages } = data

			dispatch(setOrganizationListData(data.content))

			dispatch(
				setPagination({
					totalPages,
					totalElements,
					paged: true,
					currentPage: number,
				})
			)
			dispatch(actions.setFetchingStatus(false))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Ошибка загрузки ЮЛ',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const addOrganizationPreviousOwnerToFacility =
	(data: Record<string, any>): TAction<Promise<void>> =>
	async (dispatch, getState) => {
		const facility = getState().facility.single.data!

		try {
			const participant = data.entity.dataForCreatingEntity
				? await api.organization.create(data.entity.dataForCreatingEntity)
				: await api.organization.get(data.entity.subject.id)

			await api.facility.update(facility.id, {
				previousOwners: ([] as any[]).concat(facility.previousOwners, {
					id: participant.data.id,
					part: defaultToApi(data.entity.part),
					type: PARTICIPANTS_TYPES.ORGANIZATION,
					startDate: data.startDate,
					endDate: data.endDate,
				}),
			})
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Ошибка добавления собственника',
					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		}
	}
