import { DefaultValues } from 'helpers/convertHelper'

export const requiredAction = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		actions: model.array<string[]>(['actions']),
		entity: {
			id: model.string(['entity', 'id']),
			title: model.string(['entity', 'title']),
		},
	}
}
