import { useEffect } from 'react'
import { Helmet } from 'react-helmet-async'
import { useDispatch, useSelector } from 'react-redux'
import { getMicrosoftUser } from 'actions/microsoft'
import { isEmpty } from 'ramda'

import { utils } from 'helpers'

import Controls from './Controls'
import Wrapper from './Wrapper'

import './Calendar.scss'

const Calendar = ({ route }) => {
	const dispatch = useDispatch()

	const isLoaded = useSelector((state) => !isEmpty(state.microsoft.data))

	useEffect(() => {
		dispatch(getMicrosoftUser())
	}, [dispatch])

	return (
		<>
			<Helmet>
				<title>{utils.renderBlankTitle(route.title)}</title>
			</Helmet>
			{isLoaded ? (
				<div className='calendar'>
					<Controls />
					<Wrapper />
				</div>
			) : (
				'Не найден корпоративный аккаунт Microsoft'
			)}
		</>
	)
}

export default Calendar
