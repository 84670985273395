import { useContext } from 'react'
import { useSelector } from 'react-redux'
import { ROSREESTR_REGISTRATION } from 'const/rosreestrRegistration'
import { sort } from 'ramda'
import { selectRosreestrRegistration } from 'reducers/rosreestrRegistration/main'
import type { RootState } from 'types/redux'

import { rosreestrContext } from 'components/common/Rosreestr/Rosreestr'

import { Facility } from './Facility'
import { Registration } from './Registration'

import styles from './List.module.scss'

export const List = () => {
	const { entity } = useContext(rosreestrContext)
	const facilities = useSelector((state: RootState) => state.facility.list.data)
	const registrations = useSelector(selectRosreestrRegistration.selectAll)

	const sortedRegistrationsByDate = sort(
		({ createdAt: a }, { createdAt: b }) => new Date(a).valueOf() - new Date(b).valueOf(),
		registrations
	)

	const sortedRegistrationsByFacilityAndDate = sort(({ facilityId: a }, { facilityId: b }) => {
		const fA = facilities.findIndex(({ id }) => id === a)
		const fB = facilities.findIndex(({ id }) => id === b)

		return fA - fB
	}, sortedRegistrationsByDate)

	if (!facilities.length) return <>Объекты не найдены</>

	return (
		<div className={styles.wrap}>
			{Object.entries(ROSREESTR_REGISTRATION.CREATION_TYPES[entity!.name]).map(
				([type, operationTypeData]) => (
					<div className={styles.category} key={operationTypeData.title}>
						<div className={styles.title}>{operationTypeData.title}</div>
						{sortedRegistrationsByFacilityAndDate.map(
							({ id, type }) =>
								operationTypeData.type === type && <Registration key={id} id={id as string} />
						)}
						{facilities.map((facility) => (
							<Facility
								key={facility.id}
								type={type as keyof typeof ROSREESTR_REGISTRATION.TYPE}
								operationTypeData={operationTypeData}
								data={facility}
							/>
						))}
					</div>
				)
			)}
		</div>
	)
}
