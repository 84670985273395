import { DefaultValues } from 'helpers/convertHelper'

import { customer } from './customer'

export const participant = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		type: model.string(['type']),
		roles: model.array(['roles']),
		customer: customer(data.customer),
	}
}
