import { createEntityAdapter, createSelector, createSlice } from '@reduxjs/toolkit'
import type { Application } from 'converters/application'
import { commonActions } from 'reducers/common'
import { workerSelector } from 'reducers/worker'
import type { TRedux } from 'types'

const applicationWorkersAdapter = createEntityAdapter<Application.Worker>({
	selectId: ({ workerId }) => workerId,
})

export const applicationWorkersSelector = applicationWorkersAdapter.getSelectors<TRedux.RootState>(
	(state) => state.application.workers
)

export const matchedWorkersSelector = createSelector(
	[workerSelector.selectAll, applicationWorkersSelector.selectIds],
	(v1, v2) => v1.filter(({ id }) => v2.includes(id))
)

export const matchedWorkersByIdsSelector = createSelector(
	[workerSelector.selectAll, (_, ids: string[]) => ids],
	(v1, v2) => v1.filter(({ id }) => v2.includes(id))
)

const initialState = applicationWorkersAdapter.getInitialState({
	fetching: false,
})

const workers = createSlice({
	name: 'application/workers',
	initialState,
	reducers: {
		setAll: applicationWorkersAdapter.setAll,
		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions } = workers
export default workers.reducer
