import { application } from './application'
import broker from './broker'
import { delivery } from './delivery'
import documents from './documents'
import { entrepreneur } from './entrepreneur'
import { facility } from './facility'
import investmentLead from './investmentLead'
import { lead } from './lead'
import { loan } from './loan'
import { organization } from './organization'
import { person } from './person'
import { personIncomes } from './personIncomes'
import { representative } from './representative'
import { rosreestrRegistrationTemplates } from './rosreestrRegistrationTemplate'
import { worker } from './worker'

export default {
	delivery,
	application,
	representative,
	rosreestrRegistrationTemplates,
	loan,
	person,
	documents,
	investmentLead,
	facility,
	personIncomes,
	organization,
	entrepreneur,
	worker,
	lead,
	broker,
}
