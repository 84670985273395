import { Component } from 'react'
import cx from 'clsx'
import PropTypes from 'prop-types'

class Option extends Component {
	static propTypes = {
		multi: PropTypes.bool,
		labelKey: PropTypes.string,
		value: PropTypes.oneOfType([
			PropTypes.bool,
			PropTypes.number,
			PropTypes.string,
			PropTypes.array, // в режиме мультиселекта
		]),
		multiSelected: PropTypes.array,
		item: PropTypes.object.isRequired,

		onSelectOption: PropTypes.func.isRequired,
	}

	handleClick = () => {
		const { item, onSelectOption } = this.props

		onSelectOption(item)
	}

	render() {
		const { item, labelKey, value, multi, multiSelected } = this.props

		const classNameOption = cx('select-options__item', {
			active: !multi && item.id === value,
			multiSelected: multi && Boolean(multiSelected.find((val) => val.value === item.value)),
		})

		return (
			<div className={classNameOption} data-test-id='select-option' onClick={this.handleClick}>
				{item[labelKey]}
			</div>
		)
	}
}

export default Option
