import { DefaultValues } from 'helpers/convertHelper'

import { instructionStep } from './instructionStep'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		name: model.string(['name']),
		instructionSteps: model.array(['instructionSteps']).map(instructionStep),
	}
}
