import axios from 'axios'
import { REQUEST_VERSION } from 'const/api'

export default {
	getToken: () =>
		axios.get('/worker/internal/microsoft-access-token', {
			headers: {
				[REQUEST_VERSION]: '1.1',
			},
		}),

	getUser: () => axios.get('microsoft', { $$Msal: true }),
}
