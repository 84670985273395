import { DefaultValues } from 'helpers/convertHelper'

import { checkResult } from './checkResult'
import { condition } from './condition'
import { requiredAction } from './requiredAction'

export const policy = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		title: model.string(['title']),
		checked: model.null<boolean>(['checked']),
		lender: {
			id: model.string(['lender', 'id']),
			title: model.string(['lender', 'title']),
		},
		state: {
			title: model.string(['state', 'title']),
			success: model.null<boolean>(['state', 'success']),
		},
		requiredActions: model.array(['requiredActions']).map(requiredAction),
		conditions: model.array(['conditions']).map(condition),
		checkResults: model.array(['checkResults']).map(checkResult),
	}
}
