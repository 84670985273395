import { Component } from 'react'
import PropTypes from 'prop-types'

class ViewPDF extends Component {
	static propTypes = {
		file: PropTypes.object,
	}

	render() {
		const { file } = this.props

		if (!file) return null

		return (
			<div className='files-viewer-frame'>
				<iframe src={file.src} />
			</div>
		)
	}
}

export default ViewPDF
