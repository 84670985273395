import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { commonActions } from 'reducers/common'

const initialState = {
	error: false,
	opened: false,
	filesList: [],
	filesType: '',
	index: 0,
	onRefresh: undefined,
	entityId: '',
	zoneId: null,
}

type SetInfoAction = PayloadAction<{
	filesList: any
	onRefresh: any
	filesType: any
	index: any
	entityId: any
	zoneId: any
}>

const filesViewer = createSlice({
	name: 'filesViewer',
	initialState,
	reducers: {
		setInfo(state, { payload }: SetInfoAction) {
			const { entityId, filesList, filesType, index, onRefresh, zoneId } = payload
			state.opened = true
			state.filesList = filesList
			state.onRefresh = onRefresh
			state.filesType = filesType
			state.index = index
			state.entityId = entityId
			state.zoneId = zoneId
		},

		changeIndex(state, { payload }: PayloadAction<number>) {
			state.index = state.filesList.length - 1 < payload ? state.filesList.length - 1 : payload
		},

		removeFile(state, { payload }: PayloadAction<number>) {
			state.filesList = state.filesList.filter((_, i) => i !== payload)

			state.index = payload - 1 >= 0 ? payload - 1 : 0

			return state
		},

		reset: commonActions.getResetActionCreator(initialState),
	},
})

export default filesViewer.reducer
export const { changeIndex, removeFile, reset, setInfo } = filesViewer.actions
