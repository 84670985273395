import { application } from './application'
import { brokerSpec } from './brokerSpec'
import { calculation } from './calculation'
import { calculationV2 } from './calculationV2'
import { customer } from './customer'
import { facility } from './facility'
import { interestRate } from './interestRate'
import { main } from './main'
import { manager } from './manager'
import { participant } from './participant'
import { payment } from './payment'
import { paymentInterestRateDetail } from './paymentInterestRateDetail'
import { status } from './status'
import { transition } from './transition'
import { worker } from './worker'

export const lead = {
	main,
	application,
	participant,
	facility,
	worker,
	payment,
	interestRate,
	calculation,
	paymentInterestRateDetail,
	brokerSpec,
	transition,
	manager,
	customer,
	status,
	calculationV2,
}

export namespace Lead {
	export type Main = ReturnType<typeof main>
	export type CalculationV2 = ReturnType<typeof calculationV2>
	export type InterestRate = ReturnType<typeof interestRate>
	export type Payment = ReturnType<typeof payment>
	export type PaymentInterestRateDetail = ReturnType<typeof paymentInterestRateDetail>
	export type Application = ReturnType<typeof application>
	export type Participant = ReturnType<typeof participant>
	export type Facility = ReturnType<typeof facility>
	export type Worker = ReturnType<typeof worker>
	export type Calculation = ReturnType<typeof calculation>
	export type BrokerSpec = ReturnType<typeof brokerSpec>
	export type Transition = ReturnType<typeof transition>
	export type Customer = ReturnType<typeof customer>
	export type Status = ReturnType<typeof status>
	export type Manager = ReturnType<typeof manager>
}
