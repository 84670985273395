import { delivery } from './delivery'
import { main } from './main'
import { passport } from './passport'

export const representative = {
	main,
	passport,
	delivery,
}

export namespace Representative {
	export type Main = ReturnType<typeof main>
	export type Delivery = ReturnType<typeof delivery>
	export type Passport = ReturnType<typeof passport>
}
