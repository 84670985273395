import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import { dissoc } from 'ramda'

import { commonActions } from './common'

interface State {
	uploader: Record<string, Record<string, any>>
}

const initialState: State = {
	uploader: {},
}

type SetOwnerProgressAction = PayloadAction<{ ownerId: string; status: boolean }>

type CreateOwnerAction = PayloadAction<{ ownerId: string; files: Array<any> }>

type AddFilesToOwnerAction = PayloadAction<{ ownerId: string; files: Array<any> }>

type DeleteFilesFromOwnerAction = PayloadAction<{ ownerId: string; hash: string }>

type SetFileProgressAction = PayloadAction<{ ownerId: string; hash: string; progress: number }>

type SetUploadedStatus = PayloadAction<{ ownerId: string; hash: string; status: boolean }>

const uploadFiles = createSlice({
	name: 'uploadFiles',
	initialState,
	reducers: {
		setOwnerProgress(state, { payload: { ownerId, status } }: SetOwnerProgressAction) {
			state.uploader[ownerId].isProcessing = status
		},

		deleteOwner(state, { payload }: PayloadAction<string>) {
			state.uploader = dissoc(payload, state.uploader)
		},

		createOwner(state, { payload: { ownerId, files } }: CreateOwnerAction) {
			state.uploader[ownerId] = {
				files,
				isProcessing: false,
			}
		},

		addFilesToOwner(state, { payload: { files, ownerId } }: AddFilesToOwnerAction) {
			state.uploader[ownerId].files = [...state.uploader[ownerId].files, ...files]
		},

		deleteFileFromOwner(state, { payload: { hash, ownerId } }: DeleteFilesFromOwnerAction) {
			state.uploader[ownerId].files = state.uploader[ownerId].files.filter(
				(el: Record<string, any>) => el.hash !== hash
			)
		},

		setFileProgress(state, { payload: { hash, ownerId, progress } }: SetFileProgressAction) {
			const i = state.uploader[ownerId].files.findIndex(
				(el: Record<string, any>) => el.hash === hash
			)

			state.uploader[ownerId].files[i].uploadProgress = progress
		},

		setUploadedStatus(state, { payload: { hash, ownerId, status } }: SetUploadedStatus) {
			const i = state.uploader[ownerId].files.findIndex(
				(el: Record<string, any>) => el.hash === hash
			)

			state.uploader[ownerId].files[i].isUploadSuccess = status
		},

		reset: commonActions.reset(initialState),
	},
})

export const { actions } = uploadFiles
export default uploadFiles.reducer
