import type { ScoringDocuments } from 'converters/scoring/person/documents/types'
import type { TUtils } from 'types'

import { date, moneyUtils } from '@creditclubteam/helpers'
import type { SelectOption } from '@creditclubteam/kit/ui-components'
import { DefaultValues } from 'helpers/convertHelper'

const typeOptions: SelectOption[] = [
	{
		id: 'RETIRED_INSURANCE_PENSION',
		label: 'Страховая пенсия по старости',
	},
	{
		id: 'DISABILITY_INSURANCE_PENSION',
		label: 'Страховая пенсия по инвалидности',
	},
	{
		id: 'SURVIVOR_INSURANCE_PENSION',
		label: 'Страховая пенсия по случаю потери кормильца',
	},
	{ id: 'OTHER', label: 'Иная' },
]

const getPension = (data: Record<string, any>): ScoringDocuments.Field[] => {
	const model = new DefaultValues(data)

	const startDate = date.format(model.string(['startDate']))
	const endDate = date.format(model.string(['endDate']))
	const amount = model.null<number>(['amount'])

	return [
		{
			collection: 'pensions',
			type: 'SELECT',
			key: 'type',
			span: '1/4',
			options: typeOptions,
			value: model.string(['type']),
			readonlyValue: typeOptions.find(({ id }) => id === model.string(['type']))?.label as string,
			title: 'В соответствии с',
		},
		{
			type: 'DATE',
			key: 'startDate',
			value: startDate,
			readonlyValue: startDate,
			title: 'Дата с',
		},
		{
			type: 'DATE',
			key: 'endDate',
			value: endDate,
			readonlyValue: endDate,
			notRequired: true,
			title: 'Дата по',
		},
		{
			type: 'NUM',
			key: 'amount',
			value: amount,
			readonlyValue: moneyUtils.RUB(amount),
			title: 'Сумма',
		},
	]
}

export const pension = <T extends Record<string, any>>(
	data: T,
	isTemplate?: boolean
): TUtils.Maybe<ScoringDocuments.SourceDocument> => {
	if (!isTemplate && !data) return null

	const model = new DefaultValues(data)

	const rows = model.array(['pensions'])

	return {
		id: 'pension',
		collections: [['pensions', getPension]],
		rows: rows.map(getPension),
	}
}
