import type { ScoringDocuments } from 'converters/scoring/person/documents/types'
import type { TUtils } from 'types'

import { moneyUtils } from '@creditclubteam/helpers'
import { DefaultValues, numberOrNull } from 'helpers/convertHelper'

const getPeriods = (data?: Record<string, any>): ScoringDocuments.Field[] => {
	const model = new DefaultValues(data)

	const [year = '', month = '']: string[] = model.string(['period']).split('-')
	const amount = model.null<number>(['amount'])

	return [
		{
			collection: 'periods',
			type: 'MASK',
			key: 'period-year',
			value: year,
			title: 'Год',
			mask: '9999',
		},
		{
			type: 'MASK',
			key: 'period-month',
			value: month.startsWith('0') ? month.slice(1) : month,
			transformValue: (v) => (v && Number(v) < 10 ? `0${v}` : v),
			title: 'Месяц дохода',
			mask: '99',
		},
		{
			type: 'NUM',
			key: 'amount',
			value: amount,
			readonlyValue: moneyUtils.RUB(amount),
			title: 'Сумма дохода',
		},
	]
}

export const bankStatement = <T extends Record<string, any>>(
	data: T,
	isTemplate?: boolean
): TUtils.Maybe<ScoringDocuments.SourceDocument> => {
	if (!isTemplate && !data) return null

	const model = new DefaultValues(data)

	const periods = model.array(['periods'])

	return {
		id: 'bankStatement',
		collections: [['periods', getPeriods]],
		rows: [
			[
				{
					type: 'TEXT',
					key: 'employer.name',
					span: '1/4',
					value: model.string(['employer', 'name']),
					title: 'Наименование организации',
				},
				{
					type: 'MASK',
					mask: '9999999999',
					key: 'employer.taxId',
					transformValue: numberOrNull,
					span: '4/5',
					value: model.string(['employer', 'taxId']),
					title: 'ИНН',
				},
			],

			...periods.reduce((acc: ScoringDocuments.Field[][], curr) => {
				acc.push(getPeriods(curr))

				return acc
			}, []),
		],
	}
}
