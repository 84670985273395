import { additionalPhoneNumber } from './additionalPhoneNumber'
import { balanceSheetSummary } from './balanceSheetSummary'
import { bankForm } from './bankForm'
import { creditHistoryAgreement } from './creditHistoryAgreement'
import { director } from './director'
import { form } from './form'
import { founder } from './founder'
import { linkedEntity } from './linkedEntity'
import { main } from './main'
import { okved } from './okved'
import { participant } from './participant'
import { publicAccount } from './publicAccount'

export const organization = {
	main,
	form,
	participant,
	bankForm,
	founder,
	director,
	creditHistoryAgreement,
	balanceSheetSummary,
	additionalPhoneNumber,
	okved,
	linkedEntity,
	publicAccount,
}

export namespace Organization {
	export type Main = ReturnType<typeof main>
	export type Participant = ReturnType<typeof participant>
	export type Form = ReturnType<typeof form>
	export type Founder = ReturnType<typeof founder>
	export type Director = ReturnType<typeof director>
	export type CreditHistoryAgreement = ReturnType<typeof creditHistoryAgreement>
	export type BalanceSheetSummary = ReturnType<typeof balanceSheetSummary>
	export type AdditionalPhoneNumber = ReturnType<typeof additionalPhoneNumber>
	export type Okved = ReturnType<typeof okved>
	export type LinkedEntity = ReturnType<typeof linkedEntity>
	export type BankForm = ReturnType<typeof bankForm>
	export type PublicAccount = ReturnType<typeof publicAccount>
}
