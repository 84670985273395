import { DefaultValues } from 'helpers/convertHelper'

export const workInfo = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		orgName: model.string(['orgName']),
		orgTaxId: model.string(['orgTaxId']),
		position: model.string(['position']),
		startDate: model.string(['startDate']),
		organizationType: model.const(data?.organizationType, {
			GOVERNMENTAL_ORGANIZATION: 'GOVERNMENTAL_ORGANIZATION',
			ENTREPRENEUR: 'ENTREPRENEUR',
			OOO: 'OOO',
			OAO: 'OAO',
			PAO: 'PAO',
			ZAO: 'ZAO',
			OTHER: 'OTHER',
		}),
		activityField: model.const(data?.activityField, {
			BANK: 'BANK',
			COMMUNICATION: 'COMMUNICATION',
			SCIENCE_AND_CULTURE: 'SCIENCE_AND_CULTURE',
			EDUCATION: 'EDUCATION',
			AGRICULTURE: 'AGRICULTURE',
			FINANCE: 'FINANCE',
			HEALTHCARE: 'HEALTHCARE',
			INDUSTRY: 'INDUSTRY',
			OIL_AND_GAS: 'OIL_AND_GAS',
			PUBLIC_SERVICE: 'PUBLIC_SERVICE',
			RAILWAY_TRANSPORT: 'RAILWAY_TRANSPORT',
			TRANSPORT: 'TRANSPORT',
			TRADE: 'TRADE',
			SERVICES: 'SERVICES',
			OTHER: 'OTHER',
		}),
		positionLevel: model.const(data?.positionLevel, {
			OWNER: 'OWNER',
			HEAD_OR_MAIN_ACCOUNTANT: 'HEAD_OR_MAIN_ACCOUNTANT',
			EMPLOYEE: 'EMPLOYEE',
			OTHER: 'OTHER',
		}),
		employeeCount: model.const(data?.employeeCount, {
			LESS_OR_EQUAL_TEN: 'LESS_OR_EQUAL_TEN',
			MORE_TEN: 'MORE_TEN',
		}),
		legalAddress: model.null<Record<string, any>>(['legalAddress']),
		residenceAddress: model.null<Record<string, any>>(['residenceAddress']),
	}
}
