import { DefaultValues } from 'helpers/convertHelper'

export const participant = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		type: model.const(data.type, {
			PERSON: 'PERSON',
			ORGANIZATION: 'ORGANIZATION',
			ENTREPRENEUR: 'ENTREPRENEUR',
		} as const),
		typeRus: model.const(data.type, {
			PERSON: 'ФЛ',
			ORGANIZATION: 'ЮЛ',
			ENTREPRENEUR: 'ИП',
		} as const),
	}
}
