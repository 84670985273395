import { useContext, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createRosreestrRegistration } from 'actions/rosreestrRegistration'
import { push } from 'connected-react-router'
import { ROSREESTR_REGISTRATION } from 'const/rosreestrRegistration'
import { useFormik } from 'formik'
import urls from 'routes/urls'
import type { RootState, TDispatch } from 'types/redux'

import { Button } from '@creditclubteam/react-components'
import { rosreestrContext } from 'components/common/Rosreestr'
import Section from 'components/common/Section/Section'
import type { CancelableThunkPromise } from 'helpers/cancelablePromise'
import { makeCancelable } from 'helpers/cancelablePromise'

import { formConverter } from './formConverter'
import { useSections } from './useSections'
import { validationSchema } from './validation'

import styles from './RegistrationForm.module.scss'

export const RegistrationForm = () => {
	const dispatch = useDispatch<TDispatch>()

	const { registrationFormData, entity } = useContext(rosreestrContext)

	const documents: Record<string, any>[] = useSelector(
		(state: RootState) => state.rosreestr.documents
	)
	const declarants: Record<string, any>[] = useSelector(
		(state: RootState) => state.rosreestr.participants
	)

	const submit = useRef<CancelableThunkPromise<typeof createRosreestrRegistration>>(null)

	const operationTypes = registrationFormData?.operationTypeData.operationTypes ?? []

	const sections = useSections({
		documents,
		declarants,
		/**
		 * Исключаем заемщика, если заемщик и залогодатель — разные лица
		 */
		excludeBorrowerRule:
			(operationTypes.length === 1 &&
				operationTypes.includes(ROSREESTR_REGISTRATION.OPERATION_TYPES.ENCUMBRANCE_IMPOSITION)) ||
			operationTypes.includes(ROSREESTR_REGISTRATION.OPERATION_TYPES.ENCUMBRANCE_CESSATION),
		facilityId: registrationFormData?.facility.id,
	})

	const { values, setFieldValue, handleSubmit, isSubmitting, isValid } = useFormik({
		initialValues: formConverter.to(sections),
		enableReinitialize: true,
		validationSchema,
		onSubmit: async (values, { setSubmitting }) => {
			setSubmitting(true)

			submit.current = makeCancelable(
				dispatch(
					createRosreestrRegistration(
						formConverter.from({
							...values,
							entity,
							...registrationFormData!,
							sourceDeclarants: declarants,
						})
					)
				)
			)

			return submit.current.promise
				.then((data) => dispatch(push(urls.rosreestrRegistration.single.path(data!.id))))
				.catch((error) => {
					if (error?.isCanceled) return
					setSubmitting(false)
				})
		},
	})

	useEffect(() => {
		return () => {
			submit.current?.cancel()
		}
	}, [])

	const submitProps: React.ComponentProps<typeof Button> = {
		title: 'Создать заявку',
		buttonType: 'submit',
		className: styles.submit,
		mode: 'min',
		disabled: isSubmitting || !isValid,
	}

	const declarantsSection: React.ComponentProps<typeof Section> = {
		title: 'Участники',
		items: sections.declarants.map((item) => ({
			...item,
			onChange: (value) => setFieldValue(`declarants.${item.id}`, value),
			checked: values.declarants[item.id],
		})),
	}

	const facilitySection: React.ComponentProps<typeof Section> = {
		title: 'Объекты',
		items: [
			{
				checked: true,
				id: registrationFormData!.facility.id,
				label: registrationFormData!.facility.info,
				subText: registrationFormData!.facility.address,
			},
		],
	}

	const documentsSection: React.ComponentProps<typeof Section> = {
		title: 'Документы',
		items: sections.documents.map((item) => ({
			...item,
			onChange: (value) => setFieldValue(`documents.${item.id}`, value),
			checked: values.documents[item.id],
		})),
	}

	return (
		<form autoComplete='off' onSubmit={handleSubmit} className={styles.wrap}>
			<div className={styles.sections}>
				<Section {...declarantsSection} />
				<Section {...facilitySection} />
				<Section {...documentsSection} />
			</div>
			<Button {...submitProps} />
		</form>
	)
}
