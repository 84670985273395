import { DefaultValues } from 'helpers/convertHelper'

export const statement = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		declarantId: model.string(['declarantId']),
		printDocumentId: model.string(['printDocumentId']),
		documentId: model.string(['documentId']),
		kuvd: model.string(['kuvd']),
		request: model.null<boolean>(['request']),
		signed: model.null<boolean>(['signed']),
	}
}
