import { Component } from 'react'
import cx from 'clsx'
import CALENDAR from 'const/calendar'
import PropTypes from 'prop-types'

import { InputDateTimePicker } from '@creditclubteam/react-components'
import { Select } from 'components/common'
import { utils } from 'helpers'

import './SeriesAndRepeats.scss'

class SeriesAndRepeats extends Component {
	static propTypes = {
		form: PropTypes.object.isRequired,

		fetching: PropTypes.bool.isRequired,
		setField: PropTypes.func.isRequired,
		setRecurrenceRange: PropTypes.func.isRequired,
		setRecurrencePattern: PropTypes.func.isRequired,
	}

	componentDidMount() {
		const { setRecurrenceRange } = this.props

		setRecurrenceRange('endDate', this.getEndDate())
	}

	toggleRangeDate = () => {
		const { form, setRecurrenceRange } = this.props

		const rangeType = utils.take(form, 'recurrence.range.type') || null

		setRecurrenceRange('type', rangeType === 'endDate' ? 'noEnd' : 'endDate')
	}

	getEndDate = () => {
		const { form } = this.props
		let endDate = form.recurrence.range.endDate

		if (endDate.getDate) {
			return endDate
		} else {
			endDate = new Date(endDate)

			if (endDate.getFullYear() > 1) {
				return endDate
			} else {
				const newEndDate = new Date()
				newEndDate.setDate(newEndDate.getDate() + 60)

				return newEndDate
			}
		}
	}

	changeReminder = ({ id }) => {
		const { setField } = this.props

		setField('isReminderOn', Boolean(id))
		setField('reminderMinutesBeforeStart', id)
	}

	render() {
		const { form, fetching, setRecurrenceRange, setRecurrencePattern } = this.props

		const rangeType = utils.take(form, 'recurrence.range.type', null)
		const patternType = utils.take(form, 'recurrence.pattern.type', null)
		const hideRecurrence = Boolean(form.seriesMasterId) && !form.applyToAllInstances
		const hasEndDate = rangeType === 'endDate'

		const selectRecurrencePatternOptions = {
			value: patternType,
			disabled: fetching,
			defaultTitle: 'Повтор события',
			options: CALENDAR.RECURRENCE.PATTERN_TYPES,
			onChange: ({ id }) => setRecurrencePattern('type', id),
		}

		const selectReminderOptions = {
			disabled: fetching,
			defaultTitle: 'Напомнить за',
			onChange: this.changeReminder,
			value: form.reminderMinutesBeforeStart,
			options: CALENDAR.REMINDER_RANGE_MINUTES,
		}

		const inputEndDateOptions = {
			onlyDate: true,
			disabled: fetching,
			minDate: form.start,
			className: 'calendar-event-form__event-date',
			value: utils.take(form, 'recurrence.range.endDate'),
			onChange: (date) => setRecurrenceRange('endDate', date),
		}

		return (
			<>
				<div className='calendar-event-form__recandrem'>
					{!hideRecurrence && (
						<>
							<div className='calendar-event-form__recurrence'>
								<div className='calendar-event-form__recandrem-label'>
									<div className='calendar-event-form__recandrem-label-title'>Повтор:</div>
									{patternType && (
										<div
											onClick={this.toggleRangeDate}
											className='calendar-event-form__recandrem-label-enddate'
										>
											{hasEndDate ? '- Удалить дату окончания' : '+ Добавить дату окончания'}
										</div>
									)}
								</div>
								<div className='calendar-event-form__recandrem-controls'>
									<Select {...selectRecurrencePatternOptions} />
								</div>
							</div>
							{hasEndDate && (
								<div className='calendar-event-form__recurrence-settings'>
									<div className='calendar-event-form__recurrence in-settings'>
										<div className='calendar-event-form__recandrem-label'>
											<div className='calendar-event-form__recandrem-label-title'>Выбрать:</div>
										</div>
										<div className='calendar-event-form__recandrem-controls'>
											<InputDateTimePicker {...inputEndDateOptions} />
										</div>
									</div>
								</div>
							)}
						</>
					)}
					<div
						className={cx('calendar-event-form__reminder', {
							small: hasEndDate && !hideRecurrence,
						})}
					>
						<div className='calendar-event-form__recandrem-label'>
							<div className='calendar-event-form__recandrem-label-title'>Напомнить за:</div>
						</div>
						<div className='calendar-event-form__recandrem-controls'>
							<Select {...selectReminderOptions} />
						</div>
					</div>
				</div>
			</>
		)
	}
}

export default SeriesAndRepeats
