import { CALCULATION } from 'const/calculations'

import { DefaultValues } from 'helpers/convertHelper'

export const interestRate = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		unit: model.const(data.unit, CALCULATION.UNIT.ENG),
		unitRus: model.const(data.unit, CALCULATION.UNIT.RUS),
		value: model.null<number>(['value']),
	}
}
