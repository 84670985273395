import axios from 'axios'
import { interestPayment as converter } from 'converters/interestPayment'
import moment from 'moment/moment'

const baseUrl = '/v4/investment'

export default {
	get: () =>
		axios
			.get(`${baseUrl}/interest-payments`, {
				params: {
					date: moment(new Date()).format('YYYY-MM-DD'),
				},
			})
			.then((response) => ({
				...response,
				data: response.data.map(converter.main),
			})),

	getOrder: (ids: string[]) =>
		axios.post(`${baseUrl}/interest-payment-orders`, {
			orders: ids,
		}),

	cberAuth: () =>
		axios.post(
			`${baseUrl}/sber-business-id-auth`,
			{
				backUrl: window.location.href,
			},
			{ withCredentials: true }
		),

	checkToken: () => axios.get(`${baseUrl}/sber-business-id-token`, { withCredentials: true }),
}
