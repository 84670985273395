import type { ChangeEvent } from 'react'
import { useCallback, useEffect, useState } from 'react'
import { Droppable } from 'react-beautiful-dnd'
import Dropzone from 'react-dropzone'
import { useDispatch, useSelector } from 'react-redux'
import { addDocuments } from 'actions/document'
import api from 'api'
import cx from 'clsx'
import { useModalParams } from 'components/hooks'
import { ROSREESTR_REGISTRATION } from 'const/rosreestrRegistration'
import { isEmpty } from 'ramda'
import { selectSelectedRosreestrRegistration } from 'reducers/rosreestrRegistration/main'
import type { RootState } from 'types/redux'
import type { Maybe } from 'types/util'

import { fileUtils, take } from '@creditclubteam/helpers'
import ModalDialog from 'components/common/ModalDialog'
import { getText, utils } from 'helpers'

import DocumentItem from './DocumentItem'
import DownloadableDocument from './DownloadableDocument'
import { SelectCategoryForm } from './SelectCategoryForm'

import './Category.scss'

interface CategoryProps {
	data: {
		id: string
		title: string
		signable: boolean
		comment: string
		metadataFields: any
		constructorSpec?: Record<string, any>
	}
	participants?: any
	allowSign?: boolean
	entityId: string
	entityType?: string
	ownerTypes?: Array<any>
	allowCreateDocuments?: boolean
	filesList: Array<any>
	selectedFiles: Array<any>
	customers: any
	customersForSimpleSignature: Array<any>
	uploadFiles: any
	categoryType: string
	documentsCategory: Array<any>
	selectedCategoryOnUpload: Maybe<Record<string, any>>
	fetchingDocumentConstructor: boolean
	onEdit?: (documentId: string, data: any) => void
	onDrop?: (id: string, acceptedFiles: any, rejectedFiles: any) => void
	onDelete?: () => void
	onCreate?: (data: any) => void
	onAddSigners?: () => void
	onManualUpload?: (id: string, files: any) => void
	onSignersController?: () => void
	setCategoryOnUpload: (category: Maybe<Record<string, any>>) => void
	getMetadata: (value: Record<string, any>) => void
	onClickItem: (id: string) => void
	addDocuments: () => void
	renameDocument: () => void
}

export const Category = ({
	data,
	participants,
	allowSign,
	entityId,
	entityType,
	ownerTypes,
	allowCreateDocuments,
	filesList,
	selectedFiles,
	customers,
	customersForSimpleSignature,
	onEdit,
	onDrop,
	onDelete,
	onCreate,
	onAddSigners,
	onManualUpload,
	onSignersController,
	setCategoryOnUpload,
	getMetadata,
	onClickItem,
	renameDocument,
}: CategoryProps) => {
	const dispatch = useDispatch<any>()
	const [minimize, setMinimize] = useState(filesList.length > 1)
	const [isDragging, setIsDragging] = useState(false)
	const [fetchedOptions, setFetchedOptions] = useState<Array<any>>([])
	const [inputFiles, setInputFiles] = useState<Maybe<any>>(null)
	const {
		isOpen: isSelectCategoryOpen,
		onClose: selectCategoryOnClose,
		onOpen: selectCategoryOnOpen,
	} = useModalParams()
	const uploadFiles = useSelector((state: RootState) => state.uploadFiles)
	const categoryType = useSelector(
		(state: RootState) => state.document.documentConstructor.categoryType
	)
	const fetchingDocumentConstructor = useSelector(
		(state: RootState) => state.document.documentConstructor.fetching
	)
	const rosreestrRegistration = useSelector(selectSelectedRosreestrRegistration)!

	const handleClickItem = (event: Event, id: string) => {
		if (data.signable && utils.ctrlClick(event)) onClickItem(id)
	}

	const toggleMinimizeCategory = useCallback(() => {
		if (isEmpty(filesList)) return

		setMinimize(!minimize)
	}, [minimize, filesList])

	const handleAddingFiles = ({ files, cb, rejectedFiles }: Record<string, any>) => {
		const shouldAttachMetadata = !!data.metadataFields?.length

		if (cb) {
			cb(data.id, files, rejectedFiles)
		} else if (rosreestrRegistration) {
			setInputFiles(files)
			selectCategoryOnOpen()
		} else if (shouldAttachMetadata) {
			getMetadata({
				filesToAttachMetadata: files,
				metadataFields: data.metadataFields,
				categoryId: data.id,
				mode: 'upload',
			})
		} else {
			dispatch(
				addDocuments({ ownerId: entityId, files, categoryId: data.id, ownerType: entityType })
			)
		}
	}

	const handleManualUpload = async (event: ChangeEvent<HTMLInputElement>) => {
		const fileList = event.target.files
		if (!fileList) return

		const files = Object.keys(fileList).map((key) => {
			return fileList[key as keyof typeof fileList]
		})

		handleAddingFiles({ cb: onManualUpload, files })
		event.target.value = ''
	}

	const handleCreateDocument = () => {
		const validOwnerTypes = data.constructorSpec?.validOwnerTypes
			? !!data.constructorSpec?.validOwnerTypes.find((e: any) => ownerTypes?.includes(e))
			: false

		onCreate && allowCreateDocuments && validOwnerTypes && onCreate(data)
	}

	const handleEditDocument = (documentId: string) => {
		onEdit && allowCreateDocuments && onEdit(documentId, data)
	}

	// DRAG & DROP BEGIN

	const handleDrop = (acceptedFiles: any, rejectedFiles: any) => {
		handleAddingFiles({ cb: onDrop, files: acceptedFiles, rejectedFiles })

		setIsDragging(false)
	}

	const onDrag = (isDragging: boolean) => {
		setIsDragging(isDragging)
	}

	// DRAG & DROP END

	const isManualUploadDisabled = () => {
		return !(onManualUpload || entityId)
	}

	const isDropzoneDisabled = () => {
		return !(onDrop || entityId)
	}

	// RENDER HELPERS BEGIN

	const renderActions = () => {
		const validOwnerTypes = data.constructorSpec?.validOwnerTypes
			? !!data.constructorSpec?.validOwnerTypes.find((e: any) => ownerTypes?.includes(e))
			: false

		const isFetching =
			data.constructorSpec?.templateType === categoryType && fetchingDocumentConstructor

		const selectCategoryFormProps = {
			options: fetchedOptions.map((category: { title: string; id: string }) => ({
				label: category.title,
				value: category.id,
				id: category.id,
			})),
			onSubmit: (value: string) => {
				const selectedCategory = fetchedOptions.find((category) => category.id === value)
				setCategoryOnUpload(selectedCategory)

				if (selectedCategory.metadataFields.length > 0) {
					getMetadata({
						filesToAttachMetadata: inputFiles,
						metadataFields: selectedCategory.metadataFields,
						categoryId: selectedCategory.id,
						mode: 'upload',
					})
				} else {
					dispatch(
						addDocuments({
							ownerId: entityId,
							files: inputFiles,
							categoryId: selectedCategory.id,
							ownerType: entityType,
						})
					)
				}

				selectCategoryOnClose()
			},
			onClose: selectCategoryOnClose,
		}

		const selectCategoryModal = {
			title: 'Выберете категорию загружаемого документа',
			opened: isSelectCategoryOpen,
			onClose: selectCategoryOnClose,
		}

		return (
			<>
				<ModalDialog {...selectCategoryModal}>
					<SelectCategoryForm {...selectCategoryFormProps} />
				</ModalDialog>
				{allowCreateDocuments &&
					validOwnerTypes &&
					!!data.constructorSpec?.templateType &&
					onCreate && (
						<span
							onClick={handleCreateDocument}
							className={cx({
								'document-module-files-category--fetching': isFetching,
							})}
						>
							{isFetching ? 'Загрузка...' : 'Создать'}
						</span>
					)}

				{!isManualUploadDisabled() && (
					<label>
						<span>Загрузить</span>
						<input multiple type='file' onChange={handleManualUpload} />
					</label>
				)}
			</>
		)
	}

	const renderMinimize = () => (
		<div className='document-module-files-category__mininfo-wrap'>
			<div className='document-module-files-category__mininfo'>
				<div
					className='document-module-files-category__mininfo-text'
					onClick={toggleMinimizeCategory}
				>
					<span>{data.title}</span>
					{data.comment}
				</div>
				<div className='document-module-files-category__mininfo-actions'>{renderActions()}</div>
			</div>
			<div className='document-module-files-category__mininfo-count'>
				{filesList.length} {getText(filesList.length, ['файл', 'файла', 'файлов'])}.{' '}
				<a onClick={toggleMinimizeCategory}>Раскрыть</a>
			</div>
		</div>
	)

	// RENDER HELPERS END

	const blockCategoryProps = {
		id: data.id,
		className: cx('document-module-files-category', { minimize }),
	}

	const documentItemCallbacks = {
		onDelete,
		onAddSigners,
		renameDocument,
		onSignersController,
		onClick: handleClickItem,
		onEdit: onEdit && handleEditDocument,
	}

	const blockTitleCategoryProps = {
		onClick: toggleMinimizeCategory,
		className: cx('document-module-files-category__title', {
			dragging: isDragging,
			comment: !!data.comment,
		}),
	}

	const dropZoneProps = {
		multiple: true,
		disableClick: true,
		onDrop: handleDrop,
		disabled: isDropzoneDisabled(),
		accept: fileUtils.getAllowMimeTypes(),
		onDragEnter: () => onDrag(true),
		onDragLeave: () => onDrag(false),
		className: 'document-module-dropzone',
		activeClassName: 'document-module-dropzone--active',
	}

	const downloadableFiles = take(uploadFiles.uploader, `${entityId}.files`, []).filter(
		(file: any) => file.categoryId === data.id
	)

	const filesListSorted = filesList.slice().sort((a, b) => b.createdAt.localeCompare(a.createdAt))

	const getMetadataFieldsByCategory = useCallback(
		(fileCategory: string) => {
			return fetchedOptions.find((category) => category.id === fileCategory)?.metadataFields
		},
		[fetchedOptions]
	)

	const getOwnerTypes = useCallback(() => {
		switch (rosreestrRegistration.type) {
			case ROSREESTR_REGISTRATION.TYPE.ENCUMBRANCE_CESSATION:
				return [rosreestrRegistration.$$type, ROSREESTR_REGISTRATION.TYPE.ENCUMBRANCE_IMPOSITION]
			case ROSREESTR_REGISTRATION.OPERATION_TYPES.ENCUMBRANCE_CESSATION:
				return [rosreestrRegistration.$$type, ROSREESTR_REGISTRATION.TYPE.ENCUMBRANCE_CESSATION]
			case ROSREESTR_REGISTRATION.TYPE.ENCUMBRANCE_IMPOSITION_WITH_MORTGAGE:
				return [
					rosreestrRegistration.$$type,
					ROSREESTR_REGISTRATION.TYPE.ENCUMBRANCE_IMPOSITION_WITH_MORTGAGE,
				]
			case ROSREESTR_REGISTRATION.TYPE.MORTGAGE:
				return [rosreestrRegistration.$$type, ROSREESTR_REGISTRATION.TYPE.MORTGAGE]
			default:
				return [rosreestrRegistration.$$type]
		}
	}, [rosreestrRegistration])

	useEffect(() => {
		if (rosreestrRegistration) {
			api.document
				.getCategories({
					filter: {
						ownerTypes: getOwnerTypes(),
						out: 'ROSREESTR_DOCUMENTS',
					},
				})
				.then(({ data }) => setFetchedOptions(data.content))
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	return (
		<div {...blockCategoryProps}>
			{minimize ? (
				renderMinimize()
			) : (
				<Droppable droppableId={data.id}>
					{(provided, snapshot) => (
						<div ref={provided.innerRef} {...provided.droppableProps}>
							<Dropzone {...dropZoneProps}>
								<div
									className={cx('document-module-files-category__list', {
										isDragging: isDragging || snapshot.isDraggingOver,
									})}
								>
									<div className='document-module-files-category__list-top'>
										<div {...blockTitleCategoryProps}>
											<span>{data.title}</span>
											{data.comment}
										</div>
										<div
											data-testid='document-actions'
											className='document-module-files-category__actions'
										>
											{renderActions()}
										</div>
									</div>
									{!isEmpty(downloadableFiles) &&
										downloadableFiles.map((file: any, i: number) => (
											<DownloadableDocument {...file} key={i} />
										))}
									{filesListSorted.map((item, index) => (
										<DocumentItem
											{...documentItemCallbacks}
											data={item}
											index={index}
											key={item.id}
											isVirtualCategory={item.categoryId !== data.id}
											entityId={entityId}
											customers={customers}
											subTitle={data.title}
											entityType={entityType}
											category={data}
											filesList={filesListSorted}
											allowSign={allowSign}
											ownerTypes={ownerTypes}
											signable={data.signable}
											getMetadata={getMetadata}
											participants={participants}
											metadataFields={
												rosreestrRegistration
													? getMetadataFieldsByCategory(item.categoryId)
													: data.metadataFields
											}
											constructorSpec={data.constructorSpec}
											allowCreateDocuments={allowCreateDocuments}
											customersForSimpleSignature={customersForSimpleSignature}
											isSelected={!!selectedFiles.find((e) => e.id === item.id)}
										/>
									))}
								</div>
								{provided.placeholder}
							</Dropzone>
						</div>
					)}
				</Droppable>
			)}
		</div>
	)
}
