import { addBreadcrumb } from 'actions/breadcrumbs'
import {
	fetchCategories,
	fetchFiles,
	resetDocumentCategories,
	resetDocumentFiles,
} from 'actions/document'
import { fetchEntrepreneursWithMerge } from 'actions/entrepreneur'
import { pushNotice } from 'actions/notices'
import { fetchOrganizationsWithMerge } from 'actions/organization'
import { fetchPersonsWithMerge } from 'actions/person'
import { getPipeline, getTransitions } from 'actions/pipeline'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import type { Delivery } from 'converters/delivery'
import { isEmpty } from 'ramda'
import { actions } from 'reducers/delivery/single'
import type { TRedux } from 'types'

import { utils } from 'helpers'

import { setDefaultDeliveryFilter } from './list'
import { getRepresentative } from './representative'
import { resetRepresentative } from './representative'
import { getDeliveryTypes } from './types'
import { resetDeliveryTypes } from './types'

export const resetDeliverySingle = actions.reset

export const resetDeliveryPage = (): TRedux.TAction => (dispatch) => {
	dispatch(resetDeliverySingle())
	dispatch(resetDocumentFiles())
	dispatch(resetDocumentCategories())
	dispatch(resetRepresentative())
	dispatch(resetDeliveryTypes())
}

export const getDeliverySingle =
	(id: string): TRedux.TAction<Promise<void>> =>
	async (dispatch, getState) => {
		dispatch(actions.setFetchingStatus(true))

		const pipeline = getState().pipeline.single
		const deliveryTypesIds = getState().delivery.types.ids

		if (isEmpty(deliveryTypesIds)) {
			await dispatch(getDeliveryTypes())
		}

		try {
			if (isEmpty(pipeline)) {
				await dispatch(getPipeline('delivery', 'PIPELINE'))
				dispatch(setDefaultDeliveryFilter())
			}

			await dispatch(getTransitions('delivery', id))

			const { data } = await api.delivery.get(id)

			// Получаем и записываем документы с категориями
			dispatch(fetchCategories([data.$$type]))
			dispatch(fetchFiles(id))

			const persons = data.participants.filter(({ type }) => type === PARTICIPANTS_TYPES.PERSON)
			const organizations = data.participants.filter(
				({ type }) => type === PARTICIPANTS_TYPES.ORGANIZATION
			)
			const entrepreneurs = data.participants.filter(
				({ type }) => type === PARTICIPANTS_TYPES.ENTREPRENEUR
			)

			if (data.representativeId) {
				dispatch(getRepresentative(data.representativeId))
			}

			dispatch(fetchPersonsWithMerge(persons))
			dispatch(fetchOrganizationsWithMerge(organizations))
			dispatch(fetchEntrepreneursWithMerge(entrepreneurs))

			dispatch(actions.setData(data))
			dispatch(addBreadcrumb(`Заявка №${data.number}`))

			dispatch(actions.setFetchingStatus(false))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить доставку',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const addDelivery =
	(form: Record<string, any>): TRedux.TAction<Promise<Delivery.Main>> =>
	async (dispatch) => {
		try {
			const { data } = await api.delivery.create(form)

			await dispatch(getTransitions('delivery', data.id))

			dispatch(actions.setData(data))

			return data
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось добавить доставку',
					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		}
	}

export const updateDelivery =
	(form: Record<string, unknown>): TRedux.TAction<Promise<void>> =>
	async (dispatch, getState) => {
		const deliveryId = getState().delivery.single.data!.id

		try {
			await api.delivery.update(deliveryId, form)

			try {
				const { data } = await api.delivery.get(deliveryId)

				dispatch(actions.setData(data))
			} catch {
				//
			}
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось обновить доставку',
					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		}
	}

export const syncDocument = (): TRedux.TAction<Promise<void>> => async (dispatch, getState) => {
	const deliveryId = getState().delivery.single.data!.id

	try {
		await api.delivery.syncDocument(deliveryId)

		const { data } = await api.delivery.get(deliveryId)
		dispatch(actions.setData(data))
		dispatch(
			pushNotice({
				message: 'Документы успешно синхронизированы',
			})
		)
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось синхронизировать документы',
				details: utils.getDetailsFromError(error),
			})
		)

		throw error
	}
}
