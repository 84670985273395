import { DefaultValues } from 'helpers/convertHelper'

export const debtBalance = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		amount: model.null<number>(['amount']),
		mainDebt: model.null<number>(['mainDebt']),
		interest: model.null<number>(['interest']),
		membershipFee: model.null<number>(['membershipFees']),
		penalties: model.null<number>(['penalties']),
		fines: model.null<number>(['fines']),
		commissions: model.null<number>(['commissions']),
	}
}
