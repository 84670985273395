import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Representative } from 'converters/representative'
import { commonActions } from 'reducers/common'
import type { RootState } from 'types/redux'
import type { Maybe } from 'types/util'

type State = {
	data: Maybe<Representative.Main>
	fetching: boolean
}

export const representativeSelector = (state: RootState) => state.representative.single.data

export const representativeLoadingSelector = (state: RootState) =>
	state.representative.list.fetching

const initialState: State = {
	data: null,
	fetching: false,
}

const single = createSlice({
	name: 'representative/single',
	initialState,
	reducers: {
		resetData(state) {
			state.data = null
			state.fetching = false
		},

		setData(state, { payload }: PayloadAction<State['data']>) {
			state.data = payload
		},

		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions } = single
export default single.reducer
