import type { EntityId } from 'api/types/EntityId'
import type { Options } from 'api/types/Options'
import type { Pageable } from 'api/types/Pageable'
import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type { Loan } from 'converters/loan'
import { loan } from 'converters/loan'
import type { Pipeline } from 'converters/pipeline'
import { pipeline } from 'converters/pipeline'
import { mergeDeepRight } from 'ramda'
import type { LoanFilter } from 'reducers/loan/list'

import { join } from '@creditclubteam/kit/helpers'

const baseUrl = '/v1/loans'

export default {
	get: (id: EntityId) =>
		axios
			.get<Loan.Main>(`${baseUrl}/${id}`, { $$requestName: 'loan.get' })
			.then((response) => Object.assign({}, response, { data: loan.main(response.data) })),

	search: ({ filter, size, page, noCancel }: Options<LoanFilter> & { noCancel?: boolean }) => {
		const url = new URLSearchParams()

		if (filter) {
			const [minInterestPeriods = null, maxInterestPeriods = null] = filter.interestPeriods ?? []

			filter.status?.map((v) => url.append('status', v))
			filter.channel?.map((v) => url.append('channel', v))
			filter.collectionStage?.map((v) => url.append('collectionStage', v))
			filter.participantId && url.append('participantId', filter.participantId)
			filter.workerId && url.append('workerId', filter.workerId)
			filter.facilityId && url.append('facilityId', filter.facilityId)
			Number.isFinite(minInterestPeriods) &&
				url.append('minInterestPeriods', `${minInterestPeriods}`)
			Number.isFinite(maxInterestPeriods) &&
				url.append('maxInterestPeriods', `${maxInterestPeriods}`)
			Number.isFinite(filter.minLtv) && url.append('minLtv', `${filter.minLtv}`)
		}

		return axios
			.get(join(['/v4/rcs/loans', !!url.size && decodeURIComponent(url.toString())], '?'), {
				params: Object.assign({
					size,
					page,
				}),
				$$requestName: !noCancel ? 'loan.search' : undefined,
			})
			.then(
				(response) =>
					mergeDeepRight(response, {
						data: {
							content: response.data.content.map(loan.brief),
						},
					}) as AxiosResponse<Pageable<Loan.Main>>
			)
	},

	update: (id: EntityId, form: unknown) =>
		axios.patch(`${baseUrl}/${id}`, form, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),

	getEventCategories: () =>
		axios
			.get<Loan.EventCategories>(`/v4/collection/event-categories`)
			.then((response) =>
				Object.assign({}, response, { data: loan.eventCategories(response.data) })
			),

	createEvent: ({ loanId, payload }: { loanId: string; payload: any }) =>
		axios.post(`/v4/collection/loans/${loanId}/events`, payload),

	updateCollectionStage: ({
		loanId,
		payload,
	}: {
		loanId: string
		payload: { collectionStage: string }
	}) =>
		axios.patch(`/v4/collection/loans/${loanId}/collection`, payload, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),

	updateCollectionEvent: ({
		loanId,
		payload,
		eventId,
	}: {
		loanId: string
		eventId: string
		payload: { eventDate: string; eventData: Record<string, any>; eventType: string }
	}) =>
		axios.patch(`/v4/collection/loans/${loanId}/events/${eventId}`, payload, {
			headers: {
				'Content-Type': 'application/merge-patch+json',
			},
		}),

	deleteCollectionEvent: ({ loanId, eventId }: { loanId: string; eventId: string }) =>
		axios.delete(`/v4/collection/loans/${loanId}/events/${eventId}`),

	searchCollectionEvents: ({
		id,
		disableCancel,
		page = 0,
		size = 20,
	}: Options & { id?: string; disableCancel?: boolean }) => {
		return axios
			.get<Loan.CollectionEvent>(`/v4/collection/loans/${id}/events`, {
				params: { page, size },
				$$requestName: disableCancel ? undefined : 'loan.searchCollectionEvents',
			})
			.then((response) =>
				Object.assign({}, response, { data: loan.collectionEvent({ ...response.data, page }) })
			)
	},

	supplementaryAgreements: (loanId: EntityId, data: unknown) =>
		axios.post(`${baseUrl}/${loanId}/supplementary-agreements`, data),

	rebind: (loanId: EntityId, number: string) =>
		axios.post(`${baseUrl}/${loanId}/rebind`, { number }),

	resolveNextStatus: (id: EntityId, transitionId: string, comment: string) =>
		axios
			.post<Loan.Status>(`${baseUrl}/${id}/change-status`, null, {
				params: { comment, transitionId },
			})
			.then((response) => Object.assign({}, response, { data: loan.status(response.data) })),

	getTransitions: (id: string) =>
		axios.get(`${baseUrl}/${id}/transitions`).then(
			(response) =>
				Object.assign({}, response, {
					data: response.data.map(pipeline.transition),
				}) as AxiosResponse<Pipeline.Transition>
		),

	getPipeline: () =>
		axios.get(`${baseUrl}/statuses`, {
			params: { pipeline: 'INTERNAL' },
			$$requestName: 'loan.getPipeline',
		}),

	issueLoan: (loanId: EntityId) =>
		axios
			.post<Loan.Main>(`${baseUrl}/${loanId}/issue`)
			.then((response) => Object.assign({}, response, { data: loan.main(response.data) })),

	getCollectionInfo: (loanId: EntityId) =>
		axios
			.get<Loan.Collection>(`/v4/collection/loans/${loanId}/collection`)
			.then((response) => Object.assign({}, response, { data: loan.collection(response.data) })),

	uploadSchedule: (id: string, b64: string) =>
		axios
			.post<Loan.Main>(`${baseUrl}/${id}/preliminary-schedule`, { csvBase64: b64 })
			.then((response) =>
				Object.assign({}, response, {
					data: loan.main(response.data),
				})
			),

	deleteSchedule: (id: string) => axios.delete(`${baseUrl}/${id}/preliminary-schedule`),
}
