import type { PayloadAction } from '@reduxjs/toolkit'
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { scoring } from 'converters/scoring'
import type { ScoringPerson } from 'converters/scoring/person'
import { nanoid } from 'nanoid'
import { insert } from 'ramda'
import { commonActions } from 'reducers/common'
import type { TRedux } from 'types'
import type { PayloadStatus } from 'types/redux'

const incomesAdapter = createEntityAdapter<ScoringPerson.Main>({
	selectId: ({ incomeSourceType }) => incomeSourceType.id,
})

export const incomePersonSelector = incomesAdapter.getSelectors<TRedux.RootState>(
	(state) => state.person.incomes
)

const initialState = incomesAdapter.getInitialState({
	status: 'pending' as PayloadStatus,
})

const incomes = createSlice({
	name: 'person/incomes',
	initialState,
	reducers: {
		addTemplateToGroup: (
			state,
			{
				payload,
			}: PayloadAction<{
				incomeSourceTypeId: string
				confirmationDocument: Pick<ScoringPerson.Source['confirmationDocument'], 'date' | 'type'>
			}>
		) => {
			state.entities[payload.incomeSourceTypeId]!.sources = insert(
				0,
				scoring.person.source({
					id: nanoid(),
					isTemplate: true,
					confirmationDocument: payload.confirmationDocument,
				}),
				state.entities[payload.incomeSourceTypeId]!.sources
			)
		},

		setAll: incomesAdapter.setAll,
		setMany: incomesAdapter.setMany,
		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

const { actions } = incomes

export { actions }
export default incomes.reducer
