import { URLBuilder } from 'api/helpers'
import type { EntityId } from 'api/types/EntityId'
import axios from 'axios'
import type { Worker } from 'converters/worker'
import { worker } from 'converters/worker'

import type { Options } from './types/Options'

const baseUrl = '/v2/users'

export default {
	search: async ({
		size,
		sort,
		page,
		property,
		direction,
		filter,
	}: Options<{ enabled: boolean }>) => {
		const params = new URLBuilder()

		if (filter) {
			params.addFilter('enabled', filter.enabled, 'eq')
		}

		return axios
			.get<Worker.Main[]>(baseUrl, {
				params: {
					size,
					sort: property && direction ? [property, direction].join(',') : sort || undefined,
					page,
					filter: params.build('RSQL'),
				},
			})
			.then((response) => Object.assign({}, response, { data: response.data.map(worker.main) }))
	},

	get: (id: EntityId) =>
		axios
			.get<Worker.Main>(`${baseUrl}/${id}`)
			.then((response) => Object.assign({}, response, { data: worker.main(response.data) })),
}
