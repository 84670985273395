import { Component } from 'react'
import cx from 'clsx'
import PropTypes from 'prop-types'

import './Radio.scss'

class Radio extends Component {
	static propTypes = {
		label: PropTypes.string,
		disabled: PropTypes.bool,
		className: PropTypes.string,
		value: PropTypes.bool.isRequired,

		onChange: PropTypes.func.isRequired,
	}

	handleChange = () => {
		const { value, onChange } = this.props

		if (onChange) onChange(!value)
	}

	render() {
		const { className, label, value, disabled } = this.props

		const radioElementOptions = {
			className: cx('radio-control__element', {
				disabled,
				active: value,
			}),
			onClick: this.handleChange,
		}

		return (
			<div
				className={cx('radio-control', {
					[className]: !!className,
				})}
			>
				{label && <div className='radio-control__label'>{label}</div>}
				<div {...radioElementOptions} />
			</div>
		)
	}
}

export default Radio
