// documents
// -- constructor
export const DOCUMENTS_CONSTRUCTOR_INIT = 'DOCUMENTS_CONSTRUCTOR_INIT'
export const DOCUMENTS_CONSTRUCTOR_RESET = 'DOCUMENTS_CONSTRUCTOR_RESET'
export const DOCUMENTS_CONSTRUCTOR_PREVIEW = 'DOCUMENTS_CONSTRUCTOR_PREVIEW'
export const DOCUMENTS_CONSTRUCTOR_CATEGORY_TYPE = 'DOCUMENTS_CONSTRUCTOR_CATEGORY_TYPE'
export const DOCUMENTS_CONSTRUCTOR_SET_FETCHING_STATUS = 'DOCUMENTS_CONSTRUCTOR_SET_FETCHING_STATUS'
// -- categories
export const DOCUMENTS_CATEGORIES_RESET = 'DOCUMENTS_CATEGORIES_RESET'
export const DOCUMENTS_CATEGORIES_SET_DATA = 'DOCUMENTS_CATEGORIES_SET_DATA'
export const DOCUMENTS_CATEGORIES_ADD_CATEGORY = 'DOCUMENTS_CATEGORIES_ADD_CATEGORY'
export const DOCUMENTS_CATEGORIES_SET_ENTITY_ID = 'DOCUMENTS_CATEGORIES_SET_ENTITY_ID'
export const DOCUMENTS_CATEGORIES_SET_FETCHING_STATUS = 'DOCUMENTS_CATEGORIES_SET_FETCHING_STATUS'
// -- files
export const DOCUMENTS_FILES_RESET = 'DOCUMENTS_FILES_RESET'
export const DOCUMENTS_FILES_SET_DATA = 'DOCUMENTS_FILES_SET_DATA'
export const DOCUMENTS_FILES_ADD_FILES = 'DOCUMENTS_FILES_ADD_FILES'
export const DOCUMENTS_FILES_DELETE_FILE = 'DOCUMENTS_FILES_DELETE_FILE'
export const DOCUMENTS_FILES_REPLACE_FILE = 'DOCUMENTS_FILES_REPLACE_FILE'
export const DOCUMENTS_FILES_SET_FILE_URL = 'DOCUMENTS_FILES_SET_FILE_URL'
export const DOCUMENTS_FILES_SET_SIGNATURE = 'DOCUMENTS_FILES_SET_SIGNATURE'
export const DOCUMENTS_FILES_SET_SIMPLE_SIGNATURE = 'DOCUMENTS_FILES_SET_SIMPLE_SIGNATURE'
export const DOCUMENTS_FILES_SET_FETCHING_STATUS = 'DOCUMENTS_FILES_SET_FETCHING_STATUS'
// -- sign
export const DOCUMENTS_SIGN_SET_RESET = 'DOCUMENTS_SIGN_RESET'
export const DOCUMENTS_SIGN_SET_PARTICIPANTS = 'DOCUMENTS_SIGN_SET_PARTICIPANTS'
export const DOCUMENTS_SIGN_UPDATE_PARTICIPANT_SIGNATURES =
	'DOCUMENTS_SIGN_UPDATE_PARTICIPANT_SIGNATURES'
export const DOCUMENTS_SIGN_RESET = 'DOCUMENTS_SIGN_RESET'
