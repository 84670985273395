import * as actionTypes from 'const/actionTypes'
import Immutable from 'immutable'

import { immutableize } from 'helpers'

const initialState = Immutable.fromJS({
	variables: {},
	formValues: {},
	requestData: {},
	inputsOrder: [],
	categoryType: '',
	fetching: false,
})

const documentConstructor = (state = initialState, action) => {
	switch (action.type) {
		case actionTypes.DOCUMENTS_CONSTRUCTOR_SET_FETCHING_STATUS:
			return state.set('fetching', action.status)

		case actionTypes.DOCUMENTS_CONSTRUCTOR_INIT:
			return state.merge({
				variables: action.variables,
				formValues: action.formValues,
				inputsOrder: action.inputsOrder,
			})

		case actionTypes.DOCUMENTS_CONSTRUCTOR_CATEGORY_TYPE:
			return state.merge({
				categoryType: action.categoryType,
			})

		case actionTypes.DOCUMENTS_CONSTRUCTOR_RESET:
			return initialState

		default:
			return state
	}
}

export default immutableize(documentConstructor)
