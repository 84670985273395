import { DefaultValues } from 'helpers/convertHelper'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		savingId: model.string(['savingId']),
		number: model.string(['number']),
		cashbackCC: model.null<number>(['cashbackCC']),
		ndflCC: model.null<number>(['ndflCC']),
		cashbackOneC: model.null<number>(['cashbackOneC']),
		ndflOneC: model.null<number>(['ndflOneC']),
		depositor: {
			id: model.string(['depositor', 'id']),
			shortName: model.string(['depositor', 'shortName']),
		},
	}
}
