import { setDocsInCategories } from 'actions/document/categories'
import { replaceFile } from 'actions/document/files'
import { addServerError } from 'actions/serverErrors'
import { giveObjectKeys } from 'actions/uploadFiles'
import api from 'api'
import axios from 'axios'
import { KONTUR_CERTIFICATE_STATUSES } from 'const'
import * as actionTypes from 'const/actionTypes'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import converters from 'converters'
import moment from 'moment'
import { any, flatten, isEmpty, not, omit, pipe, prop, uniq } from 'ramda'

import { utils } from 'helpers'
import { getDictionarySignatures } from 'actions/dictionary/signatures'

const setParticipants = (data) => ({
	type: actionTypes.DOCUMENTS_SIGN_SET_PARTICIPANTS,
	data,
})

export const resetSign = () => ({
	type: actionTypes.DOCUMENTS_SIGN_RESET,
})

export const hasActiveCertificate = (data) => {
	if (data?.roles?.includes('INVESTOR')) return true

	if (!data || !utils.take(data, 'konturInfo.form')) {
		return false
	}

	if (
		utils.take(data, 'konturInfo.certificate') &&
		utils.take(data, 'konturInfo.form.state') === KONTUR_CERTIFICATE_STATUSES.RELEASED
	) {
		const { activeFrom, activeTo } = data.konturInfo.certificate

		return moment().isBetween(moment(activeFrom, 'YYYY-MM-DD'), moment(activeTo, 'YYYY-MM-DD'))
	}

	if (
		[KONTUR_CERTIFICATE_STATUSES.CREATED, KONTUR_CERTIFICATE_STATUSES.VALIDATION_ERROR].includes(
			utils.take(data, 'konturInfo.form.state')
		)
	) {
		return false
	}

	return false
}

export const findSignableDocuments = ({ documents, categories, entityType }) => {
	const signableDocs = []

	if (isEmpty(documents) || isEmpty(categories)) {
		return signableDocs
	}

	categories
		.filter((category) => category.signable)
		.forEach((category) =>
			documents.forEach((file) => {
				const isMatched = category.id === file.categoryId
				const hasCategory = isMatched || categories.some(({ id }) => id === file.categoryId)
				const isPDF = file.extension.toLowerCase() === 'pdf'
				const isXml = file.extension.toLowerCase() === 'xml'

				if (hasCategory) {
					if (isMatched && (isPDF || isXml)) signableDocs.push(file)
					return
				}

				if ((isPDF || isXml) && category.id === file.virtualCategory[entityType]) {
					signableDocs.push(file)
				}
			})
		)

	return signableDocs
}

const transformParticipant = (participant) => {
	const director = PARTICIPANTS_TYPES.ORGANIZATION
		? participant.participants?.find(({ roles }) => roles.includes('DIRECTOR'))
		: null

	return {
		name: utils.getFullName(participant) || null,
		clientId:
			(participant.$$type === PARTICIPANTS_TYPES.ORGANIZATION
				? director?.id
				: participant.$$type === PARTICIPANTS_TYPES.ENTREPRENEUR
				? participant.person?.id
				: null) || null,
		roles: participant?.roles || [],
		phone: participant?.phone || null,
		customerType: participant.$$type || null,
		customerId: participant.id || null,
		signableDocuments: [],
	}
}

export const convertDocument = (item, categories, entityType) => {
	const category =
		categories.find((category) => category.id === item.categoryId) ||
		categories.find((category) => category.id === item.virtualCategory[entityType])

	if (!category) return null

	return {
		title: category?.title,
		id: item.id,
		signatures: item.signatures,
		createdAt: item.createdAt ? new Date(item.createdAt) : null,
	}
}

export const fetchInitialDataToSign =
	(shouldCollectDocs = true) =>
	async (dispatch) => {
		try {
			await dispatch(getDictionarySignatures())
			const participants = await dispatch(defineParticipantsToSign(false))

			if (isEmpty(participants)) {
				throw 'Участники для подписи не найдены'
			}

			if (shouldCollectDocs) {
				await dispatch(collectParticipantsDocumetsToSign(participants))
			} else {
				dispatch(setParticipants(participants))
			}
		} catch (error) {
			console.error(error)
		}
	}

export const defineParticipantsToSign =
	(withActiveCertificate = true, shouldUpdateStore = false) =>
	async (dispatch, getState) => {
		const { person, organization, entrepreneur, document } = getState()

		const signersMap = flatten(
			document.files.data.map(({ signatures }) =>
				signatures.map(
					({
						signer: {
							subject: { id, type },
						},
					}) => ({ id, type })
				)
			)
		).reduce((acc, { id, type }) => {
			acc[id] = type
			return acc
		}, {})

		const personSingle = person.single.data ?? {}
		const personList = person.list.data

		const organizationSingle = organization.single.data ?? {}
		const organizationList = organization.list.data
		const organizationListPersons = flatten(
			organizationList.map(({ participants }) =>
				participants
					.filter(({ type }) => type === PARTICIPANTS_TYPES.PERSON)
					.map(({ participant, roles }) => ({ ...participant, roles }))
			)
		)

		const entrepreneurSingle = entrepreneur.single.data ?? {}
		const entrepreneurList = entrepreneur.list.data
		const entrepreneurListPersons = entrepreneurList.map(prop('person')).filter(Boolean)

		const transformedParticipants = []
		const mergedParticipants = []

		const isSingle = any(pipe(isEmpty, not), [personSingle, organizationSingle, entrepreneurSingle])

		const allParticipantsIds = [
			personSingle?.id,
			...personList.map(({ id }) => id),

			organizationSingle?.id,
			...organizationList.map(({ id }) => id),
			...organizationListPersons.map(({ id }) => id),

			entrepreneurSingle?.id,
			...entrepreneurList.map(({ id }) => id),
			...entrepreneurListPersons.map(({ id }) => id),
		].filter(Boolean)

		const outerSigners = Object.entries(signersMap).filter(
			([id]) => !allParticipantsIds.includes(id)
		)

		const checkCertificate = (data) =>
			withActiveCertificate ? data.filter(hasActiveCertificate) : data

		if (!isEmpty(outerSigners)) {
			const apiMap = {
				PERSON: api.person.search,
				ORGANIZATION: api.organization.search,
			}

			const groupedSignersByType = outerSigners.reduce((acc, [id, type]) => {
				if (apiMap[type]) acc[type] = acc[type] ? [...acc[type], id] : [id]

				return acc
			}, {})

			const data = await axios.all(
				Object.entries(groupedSignersByType).map(([type, ids]) =>
					apiMap[type]({ filter: { ids } })
						.then(({ data }) => data.content)
						.catch(() => null)
				)
			)

			transformedParticipants.push(
				...checkCertificate(flatten(data).filter(Boolean)).map(transformParticipant)
			)
		}

		if (
			!isEmpty(personSingle) &&
			isSingle &&
			(withActiveCertificate ? hasActiveCertificate(personSingle) : true)
		) {
			transformedParticipants.push(transformParticipant(personSingle))
		} else if (
			!isEmpty(organizationSingle) &&
			isSingle &&
			(withActiveCertificate ? hasActiveCertificate(organizationSingle) : true)
		) {
			transformedParticipants.push(transformParticipant(organizationSingle))
		} else if (
			!isEmpty(entrepreneurSingle) &&
			isSingle &&
			(withActiveCertificate ? hasActiveCertificate(entrepreneurSingle) : true)
		) {
			transformedParticipants.push(transformParticipant(entrepreneurSingle))
		}

		if (!isEmpty(entrepreneurList)) {
			transformedParticipants.push(
				...checkCertificate([...entrepreneurListPersons, ...entrepreneurList]).map(
					transformParticipant
				)
			)
		}

		if (!isEmpty(organizationList)) {
			transformedParticipants.push(
				...checkCertificate([...organizationListPersons, ...organizationList]).map(
					transformParticipant
				)
			)
		}

		if (!isEmpty(personList)) {
			transformedParticipants.push(...checkCertificate(personList).map(transformParticipant))
		}

		transformedParticipants.forEach((participant) => {
			const duplicate = transformedParticipants.find(
				(e) => e !== participant && e.customerId === participant.customerId
			)
			const isAlreadyAdded = mergedParticipants.find((e) => e.customerId === participant.customerId)

			if (duplicate && !isAlreadyAdded) {
				mergedParticipants.push({
					...participant,
					roles: uniq([...duplicate.roles, ...participant.roles]),
				})
			} else if (!isAlreadyAdded) {
				mergedParticipants.push(participant)
			}
		})

		const specs = await axios.all(
			transformedParticipants.map(({ customerId }) =>
				api.document
					.getPersonSpec(customerId)
					.then(({ data }) => data)
					.catch(() => null)
			)
		)

		const result = mergedParticipants.reduce((acc, curr) => {
			const signatureProvider =
				specs.filter(Boolean).find(({ personId }) => personId === curr.customerId)
					?.signatureProvider ?? null
			acc.push({ ...curr, signatureProvider })
			return acc
		}, [])

		if (isEmpty(result)) {
			return Promise.resolve([])
		}

		// uniq нужен, чтобы исключить одинаковые id для запроса,
		// иначе будет ошибка
		// const { data = {} } = await api.login.hasAccount(
		// 	uniq(
		// 		result.map((e) =>
		// 			e.customerType !== PARTICIPANTS_TYPES.PERSON ? e.clientId : e.customerId
		// 		)
		// 	)
		// )

		// const results = []

		// forEachObjIndexed(
		// 	(value, key) =>
		// 		result.forEach(
		// 			(e) =>
		// 				(e.customerType !== PARTICIPANTS_TYPES.PERSON ? e.clientId : e.customerId) === key &&
		// 				value &&
		// 				results.push(e)
		// 		),
		// 	data
		// )

		// if (!isEmpty(results)) {
		// 	return Promise.resolve(
		// 		results.map(omit(['clientId'])) // clientId больше не нужен
		// 	)
		// } else {
		// 	return Promise.resolve([])
		// }

		if (shouldUpdateStore) {
			dispatch(setParticipants(result.map(omit(['clientId']))))
		}

		return Promise.resolve(
			result.map(omit(['clientId'])) // clientId больше не нужен
		)
	}

const collectParticipantsDocumetsToSign = (participants) => async (dispatch) => {
	const categoriesToFetch = uniq(participants.map((participant) => participant.customerType))

	const fetchedCategories = await api.document
		.getCategories({
			filter: {
				out: 'ROSREESTR_DOCUMENTS',
				signable: true,
				ownerTypes: categoriesToFetch,
			},
		})
		.then(({ data }) => data.content)
		.catch((err) => {
			dispatch(
				addServerError({
					text: `Не удалось загрузить документы участника`,
					details: utils.getDetailsFromError(err),
				})
			)

			return []
		})

	if (isEmpty(fetchedCategories)) {
		return Promise.reject()
	}

	const documents = await api.document
		.search({
			filter: {
				extension: 'pdf',
				owners: participants.map((participant) => participant.customerId),
				categories: fetchedCategories.map((category) => category.id),
			},
		})
		.then(({ data }) => data.content)
		.catch((err) => {
			dispatch(
				addServerError({
					text: `Не удалось загрузить документы участника`,
					details: utils.getDetailsFromError(err),
				})
			)

			return []
		})

	const result = participants.map((participant) => {
		const documentsForParticipant = documents
			.filter((document) => document.owners.includes(participant.customerId))
			.map((document) => convertDocument(document, fetchedCategories))
			.filter(Boolean)

		return {
			...participant,
			signableDocuments: documentsForParticipant,
		}
	})

	dispatch(setParticipants(result))
}

export const sendExternalSign =
	({ file, ...form }) =>
	async (dispatch) => {
		try {
			const convertedForm = converters.documents.sign.external(form)
			const {
				data: [{ objectKey, url }],
			} = await dispatch(giveObjectKeys([file]))

			await api.document.uploadFile(url, file)

			const { data } = await api.document.addSignature({ ...convertedForm, objectKey: objectKey })

			const { data: document } = await api.document.get(data.id)

			dispatch(setDocsInCategories(document.id, document))
		} catch (error) {
			dispatch(
				addServerError({
					text: `Не удалось отправить документ на подпись`,
					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		}
	}

export const addSignerDocument = (form) => async (dispatch) => {
	try {
		const requestBody = converters.documents.sign.addDocumentToSign(form)

		await api.document[form.type === 'STANDARD' ? 'addSigner' : 'addSimpleSignatureSigner'](
			requestBody
		)

		const { data } = await api.document.search({ filter: { id: requestBody.documents } })

		data.content.forEach((item) => dispatch(replaceFile(item.id, item)))

		if (form.type === 'STANDARD') {
			dispatch(defineParticipantsToSign(false, true))
		}
	} catch (error) {
		dispatch(
			addServerError({
				text: `Не удалось отправить документ на подпись`,
				details: utils.getDetailsFromError(error),
			})
		)

		throw error
	}
}

export const sendSms = (form) => async (dispatch) => {
	try {
		const requestBody = converters.documents.sign.sendSms(form)

		const { data } = await api.document.sendSms(requestBody)

		return data
	} catch (error) {
		dispatch(
			addServerError({
				text: `Не удалось отправить СМС`,
				details: utils.getDetailsFromError(error),
			})
		)

		throw error
	}
}

export const confirmSms = (form) => async (dispatch) => {
	try {
		const requestBody = converters.documents.sign.confirmSms(form)

		await api.document.confirmSms(requestBody)

		const { data } = await api.document.get(form.documentId)

		dispatch(replaceFile(form.documentId, data))
	} catch (error) {
		dispatch(
			addServerError({
				text: `Не удалось подтвердить СМС`,
				details: utils.getDetailsFromError(error),
			})
		)

		throw error
	}
}

export const signAgreement = (payload) => async (dispatch) => {
	try {
		const [docId] = payload.documents

		await api.document.signAgreement(docId)

		const { data } = await api.document.get(docId)

		dispatch(replaceFile(docId, data))
	} catch (error) {
		dispatch(
			addServerError({
				text: `Не удалось подтвердить СМС`,
				details: utils.getDetailsFromError(error),
			})
		)

		throw error
	}
}
