import { DefaultValues } from 'helpers/convertHelper'

export const director = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		post: model.string(['post']),
		taxId: model.string(['taxId']),
		date: model.string(['date']),
		fullName: model.string(['fullName']),
		termOfOfficeMonths: model.null<number>(['termOfOfficeMonths']),
	}
}
