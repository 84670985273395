import { CALCULATION } from 'const/calculations'

import { DefaultValues } from 'helpers/convertHelper'

export const fixedInterest = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		type: 'FIXED_INTEREST' as const,
		unit: model.const(data?.unit, CALCULATION.UNIT.ENG),
		unitRus: model.const(data?.unit, CALCULATION.UNIT.RUS),
		minValue: model.null<number>(['minValue']),
		maxValue: model.null<number>(['maxValue']),
	}
}
