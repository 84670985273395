import { useFormik } from 'formik'
import * as yup from 'yup'

import Controls from 'components/common/Controls'
import Select from 'components/common/Select'

import styles from './SelectCategoryForm.module.scss'

interface SelectCategoryFormProps {
	options: {
		id: string
		label: string
		value: string
	}[]
	onSubmit: (value: string) => void
	onClose: () => void
}

export const SelectCategoryForm = ({ options, onSubmit, onClose }: SelectCategoryFormProps) => {
	const { values, isValid, handleSubmit, setFieldValue } = useFormik({
		initialValues: {
			selected: '',
		},
		validateOnMount: true,
		validateOnChange: true,
		validationSchema: yup.object().shape({
			selected: yup.string().required(),
		}),
		onSubmit: (values) => onSubmit(values.selected),
	})

	const selectCategoryProps: React.ComponentProps<typeof Select> = {
		placeholder: 'Нет данных',
		options: options,
		value: values.selected,
		onChange: ({ value }: { value: string }) => {
			setFieldValue('selected', value)
		},
		className: styles.select,
	}

	const controlsProps = {
		submitButtonProps: {
			title: 'Продолжить',
			disabled: !isValid,
		},
		cancelButtonProps: {
			onClick: onClose,
		},
	}

	return (
		<form onSubmit={handleSubmit}>
			<Select {...selectCategoryProps} />
			<Controls {...controlsProps} />
		</form>
	)
}
