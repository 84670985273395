import { useCallback, useRef } from 'react'
import api from 'api'
import { useFetch } from 'components/hooks'
import { KONTUR_CERTIFICATE_STATUSES } from 'const'
import { saveAs } from 'file-saver'
import moment from 'moment'
import PropTypes from 'prop-types'

import { utils } from 'helpers'

import styles from './CertificateBlock.module.scss'
import { Text } from '@creditclubteam/kit/ui-components'

const CreatedState = (props) => {
	const { onCreateStatement, onPreview, onSetKonturErrors, addServerError } = props

	const fileInputRef = useRef(null)

	const handlePreview = useCallback(
		(event) => {
			const size = event.target?.files[0]?.size / 1024 / 1024 // size in MB

			if (size > 10) {
				addServerError(
					{
						text: 'Размер файла не должен превышать 10 МБ',
						type: 'FILE_UPLOAD_LIMIT',
					},
					false
				)
			} else {
				onPreview(event.target.files[0])
			}

			if (fileInputRef.current) {
				fileInputRef.current.value = null
			}
		},
		[onPreview, addServerError]
	)

	const { request: useOnCreateStatement, isFetching: isDownloading } = useFetch(onCreateStatement, {
		onFailure: onSetKonturErrors,
	})

	return (
		<div className={styles.mainActions}>
			<div className={styles.actionBlock}>
				<div>Заявление на выпуск</div>
				<div className={styles.row}>
					<div className={styles.action} onClick={isDownloading ? undefined : useOnCreateStatement}>
						{isDownloading ? (
							<i className='zmdi zmdi-spinner' />
						) : (
							<i className='zmdi zmdi-cloud-download' />
						)}
						<span>Скачать</span>
					</div>
					<div className={styles.action}>
						<i className='zmdi zmdi-cloud-upload' />
						<label>
							<span>Загрузить подписанное</span>
							<input
								ref={fileInputRef}
								accept='application/pdf,image/jpeg,image/jpg,image/png,image/tiff'
								type='file'
								onChange={handlePreview}
							></input>
						</label>
					</div>
				</div>
			</div>
		</div>
	)
}

CreatedState.propTypes = {
	addServerError: PropTypes.func,
	onCreateStatement: PropTypes.func,
	onPreview: PropTypes.func,
	onSetKonturErrors: PropTypes.func,
}

const ReleasedState = (props) => {
	const { hasReleaseCertStatement, onDownloadReleaseCertStatement, konturInfo } = props

	const { request: downloadKonturDocument, isFetching: isDownloading } = useFetch(
		() => api.document.downloadKonturDocument(utils.take(konturInfo, 'form.id')),
		{
			onSuccess: ({ data }) => {
				saveAs(new File([data], 'document', { type: 'application/pdf;charset=utf-8' }))
			},
		}
	)

	return (
		<div className={styles.mainActions}>
			{hasReleaseCertStatement && (
				<div className={styles.actionBlock}>
					<div className={styles.action} onClick={onDownloadReleaseCertStatement}>
						<i className='zmdi zmdi-file' />
						<span>Заявление на выпуск</span>
					</div>
				</div>
			)}
			<div className={styles.actionBlock}>
				<div className={styles.action} onClick={isDownloading ? undefined : downloadKonturDocument}>
					{isDownloading ? (
						<i className='zmdi zmdi-spinner' />
					) : (
						<i className='zmdi zmdi-cloud-download' />
					)}
					<span>Расписка о получении</span>
				</div>
			</div>
		</div>
	)
}

ReleasedState.propTypes = {
	hasReleaseCertStatement: PropTypes.bool,
	konturInfo: PropTypes.object,

	onDownloadReleaseCertStatement: PropTypes.func,
}

const ValidatedState = () => {
	const link =
		window.__env__.BUILD_MODE === 'production'
			? 'https://i.kontur-ca.ru/Enter'
			: 'https://itest.kontur-ca.ru/Enter'

	return (
		<div className={styles.validatedState}>
			<a href={link} target='_blank' rel='noopener noreferrer'>
				Перейти в кабинет УЦ
			</a>
			<div>
				Вход в кабинет осуществляется с помощью смс-кода на телефон владельца сертификата, который
				был указан в данных формы.
			</div>
		</div>
	)
}

const HoverDeleteBtn = (props) => {
	const { onDeleteCertificateForm, onSetKonturErrors, hasCertificate } = props

	const { request, isFetching } = useFetch(onDeleteCertificateForm, {
		onFailure: onSetKonturErrors,
	})

	if (hasCertificate) {
		return null
	}

	return (
		<div className={styles.hoverActions}>
			<span onClick={isFetching ? undefined : request} className={styles.deleteBtn}>
				Удалить
			</span>
		</div>
	)
}

HoverDeleteBtn.propTypes = {
	hasCertificate: PropTypes.bool,
	onDeleteCertificateForm: PropTypes.func,
	onSetKonturErrors: PropTypes.func,
}

const ActiveDates = (props) => {
	const { certificate } = props

	const [activeFrom, activeTo] = [
		utils.take(certificate, 'activeFrom'),
		utils.take(certificate, 'activeTo'),
	]

	const formatDate = useCallback((date) => {
		return moment(date, 'YYYY MM DD').format('DD.MM.YYYY')
	}, [])

	if (!activeFrom || !activeTo) {
		return null
	}

	return (
		<div className={styles.activeDates}>
			{formatDate(activeFrom)} — {formatDate(activeTo)}
		</div>
	)
}

ActiveDates.propTypes = {
	certificate: PropTypes.object,
}

const CertificateActions = (props) => {
	const {
		onCreateStatement,
		konturInfo,
		addServerError,
		onPreview,
		onSetKonturErrors,
		onDeleteCertificateForm,
		hasReleaseCertStatement,
		onDownloadReleaseCertStatement,
	} = props

	const state = utils.take(konturInfo, 'form.state') || ''
	const certificate = utils.take(konturInfo, 'certificate')

	const createdStateProps = {
		addServerError,
		onCreateStatement,
		onPreview,
		onSetKonturErrors,
	}

	const hoverDeleteBtnProps = {
		hasCertificate: !!certificate,
		onDeleteCertificateForm,
		onSetKonturErrors,
	}

	const releasedStateProps = {
		onDownloadReleaseCertStatement,
		hasReleaseCertStatement,
		konturInfo,
	}

	const activeDatesProps = {
		certificate: utils.take(konturInfo, 'certificate'),
	}

	if (
		![
			KONTUR_CERTIFICATE_STATUSES.CREATED,
			KONTUR_CERTIFICATE_STATUSES.VALIDATED,
			KONTUR_CERTIFICATE_STATUSES.VALIDATION_ERROR,
			KONTUR_CERTIFICATE_STATUSES.RELEASED,
		].includes(state)
	) {
		return null
	}

	return (
		<div className={styles.certificateActions}>
			{[KONTUR_CERTIFICATE_STATUSES.CREATED, KONTUR_CERTIFICATE_STATUSES.VALIDATION_ERROR].includes(
				state
			) && (
				<>
					<CreatedState {...createdStateProps} />
					<HoverDeleteBtn {...hoverDeleteBtnProps} />
				</>
			)}
			{state === KONTUR_CERTIFICATE_STATUSES.VALIDATED && <ValidatedState />}
			{state === KONTUR_CERTIFICATE_STATUSES.RELEASED && (
				<>
					<ReleasedState {...releasedStateProps} />
					<ActiveDates {...activeDatesProps} />
				</>
			)}
		</div>
	)
}

CertificateActions.propTypes = {
	hasReleaseCertStatement: PropTypes.bool,
	konturInfo: PropTypes.object,

	onCreateStatement: PropTypes.func,
	onSetKonturErrors: PropTypes.func,
	onPreview: PropTypes.func,
	onDeleteCertificateForm: PropTypes.func,
	addServerError: PropTypes.func,
	onDownloadReleaseCertStatement: PropTypes.func,
}

export default CertificateActions
