import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

import type { DocumentsPackageDto } from './types'

type State = {
	data: Array<DocumentsPackageDto>
	isSignsLoading: boolean
}

const initialState: State = {
	data: [],
	isSignsLoading: false,
}

const sign = createSlice({
	name: 'sign',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<State['data']>) {
			state.data = payload
		},
	},
})

export default sign.reducer
export const { actions } = sign
