import { Fragment } from 'react'
import PropTypes from 'prop-types'

import Context from '../../Context'

import './StatusChange.scss'

const renderStatus = (prevStatus, status) => (
	<Fragment>
		{prevStatus}
		<span style={{ margin: '0 7px' }}>
			<i className='zmdi zmdi-long-arrow-right' />
		</span>
		{status}
	</Fragment>
)

const StatusChange = ({ $$date, metadata: { prevStatus, status } }) => {
	const contextOptions = {
		date: $$date.fullDate,
		className: 'history-status-change',
		title: (
			<Fragment>
				{'Смена статуса: '}
				{prevStatus ? renderStatus(prevStatus, status) : status}
			</Fragment>
		),
	}

	return <Context {...contextOptions} />
}

StatusChange.propTypes = {
	$$date: PropTypes.object.isRequired,
	metadata: PropTypes.shape({
		prevStatus: PropTypes.string,
		status: PropTypes.string.isRequired,
	}),
}

export default StatusChange
