import PropTypes from 'prop-types'

import styles from './DownloadableDocument.module.scss'

const DownloadableDocument = ({ isUploadSuccess, uploadProgress, fileOrigin }) => (
	<div className={styles.wrapper}>
		<div className={styles.title}>
			{`${isUploadSuccess ? 'Обработка' : 'Загрузка'}: ${fileOrigin.name}`}
		</div>
		<div className={styles.progress} style={{ width: `${uploadProgress}%` }} />
	</div>
)

DownloadableDocument.propTypes = {
	fileOrigin: PropTypes.shape({
		name: PropTypes.string.isRequired,
	}).isRequired,
	isUploadSuccess: PropTypes.bool.isRequired,
	uploadProgress: PropTypes.number.isRequired,
}

export default DownloadableDocument
