import { setPagination, solveParamsPagination } from 'actions/listParameters'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import { actions } from 'reducers/person/list'
import type { TAction } from 'types/redux'

import { utils } from 'helpers'
import { defaultToApi } from 'helpers/convertHelper'
import { DefaultValues } from 'helpers/convertHelper'
import { transformParticipantsWithPartToRequest } from 'helpers/participantsUpdating'

export const resetPersonList = actions.reset
export const setPersonListData = actions.setData
export const replacePersonInList = actions.replace
export const addPersonInList = actions.add
export const deletePersonInList = actions.delete
export const updatePersonInList = actions.update
export const personListChangeFilterParameter = actions.setFilter

/**
 * @description - Запрашивает пользователей и склеивает их с входным обьектом, далее записывает в стор
 * @param {array} minPersons - Входной массив
 */
export const fetchPersonsWithMerge =
	(minPersons: Record<string, any>[]): TAction<Promise<void>> =>
	async (dispatch) => {
		try {
			if (!utils.hasObjectLength(minPersons)) return
			dispatch(actions.setFetchingStatus(true))

			const ids = minPersons.filter((person) => !!person.id).map((person) => person.id)

			const { data } = await api.person.search({ filter: { ids }, size: minPersons.length })

			const personsList = data.content.map((person) => {
				const minPerson = minPersons.find((e) => e.id === person.id)

				const defaultValue = new DefaultValues(minPerson)

				return Object.assign({}, person, {
					mainContact: defaultValue.null(['mainContact']),
					part: defaultValue.null(['part']),
					roles: defaultValue.array(['roles']),
				})
			})

			dispatch(actions.setData(personsList))
			dispatch(actions.setFetchingStatus(false))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Ошибка загрузки ФЛ',
					details: utils.getDetailsFromError(error),
				})
			)

			dispatch(actions.setFetchingStatus(false))
		}
	}

export const getPersonList =
	(parameters = {}): TAction<Promise<void>> =>
	async (dispatch, getState) => {
		try {
			dispatch(actions.setFetchingStatus(true))

			const requestParameters = {
				...dispatch(solveParamsPagination('number')),
				...parameters,
				filter: getState().person.list.filter,
			}

			const { data } = await api.person.search(requestParameters)
			const { totalElements, number, totalPages } = data

			dispatch(actions.setData(data.content))

			dispatch(
				setPagination({
					totalPages,
					totalElements,
					paged: true,
					currentPage: number,
				})
			)
			dispatch(actions.setFetchingStatus(false))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Ошибка загрузки ФЛ',
					details: utils.getDetailsFromError(error),
				})
			)
		}
	}

export const changePersonalDataInList =
	(id: string, form: Record<string, any>): TAction<Promise<void>> =>
	async (dispatch, getState) => {
		try {
			const personToUpdate = getState().person.list.data.find((e) => id === e.id)

			const payload = {
				name: defaultToApi(form.name),
				surname: defaultToApi(form.surname),
				patronymic: defaultToApi(form.patronymic),
				phone: defaultToApi(form.phone),
			}

			await api.person.update(id, payload)

			try {
				const { data } = await api.person.get(id)

				dispatch(actions.replace({ id, value: { ...personToUpdate, ...data } }))
			} catch {
				//
			}
		} catch (error: any) {
			if (error.response.data.status === 400 && utils.isJSON(error.response.data.message)) {
				const person = JSON.parse(error.response.data.message)

				dispatch(
					addServerError({
						text: 'Человек с таким телефоном уже есть в базе',
						details: {
							message: `${utils.getFullName(person)}\n${utils.parsePhone(person.phone)}`,
						},
					})
				)
			} else {
				dispatch(
					addServerError({
						text: 'Не удалось обновить данные человека',
						details: utils.getDetailsFromError(error),
					})
				)
			}

			throw error
		}
	}

export const addPersonPreviousOwnerToFacility =
	(data: Record<string, any>): TAction<Promise<any>> =>
	async (dispatch, getState) => {
		const facility = getState().facility.single.data

		try {
			const participant = data.entity.dataForCreatingEntity
				? await api.person.create(data.entity.dataForCreatingEntity)
				: await api.person.get(data.entity.subject.id)

			await api.facility.update(facility!.id, {
				previousOwners: transformParticipantsWithPartToRequest({
					participants: facility!.previousOwners,
					newParticipant: {
						id: participant.data.id,
						part: defaultToApi(data.entity.part),
						type: PARTICIPANTS_TYPES.PERSON,
						startDate: data.startDate,
						endDate: data.endDate,
					},
				}),
			})
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Ошибка добавления собственника',
					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		}
	}
