import { addBreadcrumb } from 'actions/breadcrumbs'
import { getCreditProgram } from 'actions/creditProgram'
import { decisionActions } from 'actions/decision'
import { fetchEntrepreneursWithMerge, resetEntrepreneurList } from 'actions/entrepreneur'
import { fetchFacilitiesWithMerge, resetFacilityList } from 'actions/facility'
import { resetFilesInspector } from 'actions/filesInspector'
import { resetHistory } from 'actions/history'
import { interceptor } from 'actions/interceptor'
import { setDefaultLeadFilter } from 'actions/lead/list'
import { setModalShow } from 'actions/modalDialogs'
import { pushNotice } from 'actions/notices'
import { fetchOrganizationsWithMerge, resetOrganizationList } from 'actions/organization'
import { changePersonalData, fetchPersonsWithMerge, resetPersonList } from 'actions/person'
import { getPipeline } from 'actions/pipeline'
import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { push } from 'connected-react-router'
import { PARTICIPANT_ROLES } from 'const'
import { PERSON_ERRORS_CODES } from 'const/errorsCodes'
import { LEAD_STATUSES } from 'const/lead'
import { PARTICIPANTS_TYPES } from 'const/participantsTypes'
import converters from 'converters'
import { saveAs } from 'file-saver'
import { isEmpty, uniqWith } from 'ramda'
import { actions as facilityListActions } from 'reducers/facility/list'
import { managersSelector } from 'reducers/lead/managers'
import { actions } from 'reducers/lead/single'
import { workerSelector } from 'reducers/worker'
import url from 'routes/urls'

import { utils } from 'helpers'
import { defaultToApi, numberOrNull } from 'helpers/convertHelper'

import { getLeadManagers } from './managers'

const leadSingleResolveNextStatus = actions.resolveNextStatus

export const resetLeadPage = () => (dispatch) => {
	dispatch(actions.reset())
	dispatch(resetHistory())
	dispatch(resetPersonList())
	dispatch(resetFacilityList())
	dispatch(resetOrganizationList())
	dispatch(resetEntrepreneurList())
	dispatch(resetFilesInspector())
}

export const getLeadSingle = (id) => (dispatch, getState) => {
	dispatch(actions.setFetchingStatus(true))

	return api.lead
		.get(id)
		.then(async ({ data }) => {
			const leadManager = data.workers.find((el) => el.type === 'MANAGER')
			data.manager = leadManager?.id ? (await api.worker.get(leadManager.id)).data : {}

			dispatch(actions.setData(data))
			dispatch(addBreadcrumb(`Лид №${data.number}`))

			return data
		})
		.then(async (data) => {
			const pipeline = getState().pipeline.statuses.lead
			const managers = managersSelector.selectIds(getState())

			await dispatch(getCreditProgram(data.calculationV2?.creditProgramId))
			await dispatch(decisionActions.main.get({ entity: 'leads', entityId: id }))

			if (isEmpty(managers)) {
				await dispatch(getLeadManagers())
			}

			if (isEmpty(pipeline)) {
				await dispatch(getPipeline('lead'))

				dispatch(setDefaultLeadFilter())
			}

			return data
		})
		.then(async (data) => {
			const { participants, facilities } = data

			const persons = participants.filter((e) => e.type === PARTICIPANTS_TYPES.PERSON)
			const organizations = participants.filter((e) => e.type === PARTICIPANTS_TYPES.ORGANIZATION)
			const entrepreneurs = participants.filter((e) => e.type === PARTICIPANTS_TYPES.ENTREPRENEUR)

			const facilitiesWithId = facilities.filter(({ id }) => id).map(({ id }) => id)

			dispatch(fetchPersonsWithMerge(persons))
			dispatch(fetchOrganizationsWithMerge(organizations))
			dispatch(fetchEntrepreneursWithMerge(entrepreneurs))
			dispatch(fetchFacilitiesWithMerge(facilitiesWithId))

			dispatch(actions.setFetchingStatus(false))

			return data
		})
		.catch((error) => {
			dispatch(actions.setFetchingStatus(false))

			dispatch(
				addServerError({
					text: 'Не удалось загрузить лид',
					details: utils.getDetailsFromError(error),
				})
			)
		})
}

export const leadResolveNextStatus =
	(number, transition, comment, rejectionReasonId) => (dispatch, getState) => {
		return api.lead
			.resolveNextStatus(number, transition, comment, rejectionReasonId)
			.then(async (response) => {
				const lead = getState().lead.single.data
				const nextStatusID = utils.take(response, 'data.id', '')

				const resolveNextStatus = () => {
					dispatch(leadSingleResolveNextStatus(response.data))

					return response
				}

				/**
				 * Если лид перешёл в заявку
				 * осуществляем редирект на заявку, но бывают случаи когда заявка не создалась сразу
				 * поэтому сначала проверяем что она есть
				 */
				if (nextStatusID === LEAD_STATUSES.TO_APPLICATION) {
					try {
						const { data } = await api.lead.get(lead.id)
						const applicationId = utils.take(data, 'application.id', '')

						if (applicationId) {
							await new Promise((r) => setTimeout(r, 2000))
							const { data: application } = await api.application.get(applicationId)

							dispatch(push(url.application.path(application.id)))
						} else {
							throw null
						}
					} catch {
						return resolveNextStatus()
					}
				} else {
					return resolveNextStatus()
				}
			})
			.catch((e) => {
				const errorCode = utils.take(e, 'response.data.errorCode', '')

				if (errorCode.includes('VALIDATION')) {
					dispatch(interceptor('incompleteData', e.response.data.errors))
				} else if (errorCode.includes(PERSON_ERRORS_CODES.ALREADY_EXIST)) {
					const source = getState().lead.single.data.person

					dispatch(
						interceptor('fullNameConflict', {
							source,
							inBase: e.response.data.person,
							optionals: {
								number,
								comment,
								transition,
							},
						})
					)
				} else {
					dispatch(
						addServerError({
							text: 'Не удалось сменить статус',
							details: utils.getDetailsFromError(e),
						})
					)
				}

				throw e
			})
	}

export const leadAddCalculationV2 = (id, calculationV2) => (dispatch) => {
	return api.lead
		.update(id, { calculationV2 })
		.then(async () => {
			dispatch(getLeadSingle(id))
		})
		.catch((error) => {
			dispatch(
				addServerError({
					text: 'Не удалось обновить расчет',
					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		})
}

export const leadChangeWorker = (workerName, id) => async (dispatch, getState) => {
	const lead = getState().lead.single.data

	try {
		const newWorkers = uniqWith(
			(a, b) => a.id === b.id || a.type === b.type,
			[{ id, type: workerName }, ...lead.workers]
		)

		await api.lead.update(lead.id, {
			workers: newWorkers,
		})

		dispatch(actions.updateWorkers(newWorkers))
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось обновить лид',

				details: utils.getDetailsFromError(error),
			})
		)

		throw error
	}
}

export const leadUpdate = (form) => async (dispatch, getState) => {
	const { id } = getState().lead.single.data

	try {
		await api.lead.update(id, form)

		try {
			const { data } = await api.lead.get(id)

			const leadManager = data.workers.find((el) => el.type === 'MANAGER')
			data.manager = leadManager.id ? (await api.worker.get(leadManager.id)).data : {}

			dispatch(actions.setData(data))
		} catch {
			//
		}
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось обновить лид',
				details: utils.getDetailsFromError(error),
			})
		)

		throw error
	}
}

export const addLead = (lead) => (dispatch) => {
	return api.lead
		.create(lead)
		.then(async ({ data }) => {
			const finded = data.participants.find((el) => el.roles.includes(PARTICIPANT_ROLES.APPLICANT))
			let leadData

			if (finded) {
				leadData = await dispatch(mutateWithPersonData(finded.id, data))
			} else {
				leadData = { ...data, person: {} }
			}

			dispatch(actions.setData(leadData))

			return leadData
		})
		.then((data) => {
			if (data.status.pipeline) dispatch(getPipeline('lead'))

			return data
		})
		.catch((error) => {
			dispatch(
				addServerError({
					text: 'Не удалось добавить лид',

					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		})
}

export const leadFullNameConflict = (selected) => async (dispatch, getState) => {
	const { type } = selected
	const lead = getState().lead.single.data
	const { optionals } = getState().interceptor.fullNameConflict

	if (type === 'inBase') {
		await dispatch(
			leadUpdate({
				...lead,
				manager: lead.workers.MANAGER,
				personInfo: {
					...selected.inBase,
					email: selected.inBase.email || selected.source.email,
					phone: selected.inBase.phone || selected.source.phone,
				},
			})
		)
	}

	if (type === 'source') {
		await dispatch(changePersonalData(selected.inBase.id, selected.source, false))
	}

	if (type === 'custom') {
		await dispatch(
			leadUpdate({
				...lead,
				manager: lead.workers.MANAGER,
				personInfo: {
					...selected.custom,
					email: selected.inBase.email || selected.source.email,
					phone: selected.inBase.phone || selected.source.phone,
				},
			})
		)

		await dispatch(
			changePersonalData(
				selected.inBase.id,
				{
					...selected.custom,
					email: selected.inBase.email || selected.source.email,
					phone: selected.inBase.phone || selected.source.phone,
				},
				false
			)
		)
	}

	dispatch(leadResolveNextStatus(optionals.number, optionals.transition, optionals.comment))

	return Promise.resolve()
}

// helpers
const mutateWithPersonData =
	(participantId, participantType, data) => async (dispatch, getState) => {
		const newData = {
			...data,
			$$type: 'LEAD',
			$$personInBase: {},
		}

		const manager = data.workers.find((el) => el.type === 'MANAGER')
		const findWorker = workerSelector.selectById(getState(), manager?.id)

		const getParticipantData = async (type, id) => {
			switch (type) {
				case PARTICIPANTS_TYPES.PERSON: {
					const { data } = await api.person.get(id)

					return converters.person.single(data)
				}
				case PARTICIPANTS_TYPES.ORGANIZATION: {
					const { data } = await api.organization.get(id)

					return converters.organization.single(data)
				}
				case PARTICIPANTS_TYPES.ENTREPRENEUR: {
					const { data } = await api.entrepreneur.get(id)

					return converters.entrepreneur.single(data)
				}
			}
		}

		try {
			const data = await getParticipantData(participantType, participantId)

			newData.person = data
			newData.$$personInBase = data
			newData.manager = findWorker

			await dispatch(fetchPersonsWithMerge([{ id: data.id }]))
		} catch (e) {
			dispatch(
				addServerError({
					text: 'Не удалось загрузить персону',

					details: utils.getDetailsFromError(e),
				})
			)
		}
		return newData
	}

export const requestDocs = (leadId, docsType) => (dispatch, getState) => {
	const lead = getState().lead.single.data

	api.lead
		.requestDocs(leadId, docsType)
		.then(() => {
			dispatch(setModalShow(false, 'requestDocsForm'))

			dispatch(
				actions.setData({
					...lead,
					docRequestExist: true,
				})
			)
		})
		.catch((e) => {
			dispatch(
				addServerError({
					text: 'Не удалось запросить документы',

					details: utils.getDetailsFromError(e),
				})
			)
		})
}

export const assessFacilityLead = (facility) => async (dispatch, getState) => {
	const { facilities } = getState().lead.single.data

	try {
		const { data } = await api.assessment.assessment({
			type: facility.type,
			area: facility.area,
			address: facility.address,
		})

		const payload = facilities.map((item) =>
			item.pseudoId === facility.pseudoId
				? Object.assign({}, item, {
						estimatedPrice: converters.facility.assessment(data.assessment).averagePrice,
				  })
				: item
		)

		dispatch(actions.updateFacilities(payload))
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось оценить объект',

				details: utils.getDetailsFromError(error),
			})
		)

		throw error
	}
}

export const deleteFacilityLead =
	({ key, value }) =>
	async (dispatch, getState) => {
		const { facilities, id } = getState().lead.single.data

		try {
			await api.lead.update(id, {
				facilities: facilities
					.filter((item) => item[key] !== value)
					.map((item) => ({
						id: defaultToApi(item.id),
						type: defaultToApi(item.type),
						cadastralId: defaultToApi(item.cadastralId),
						address: defaultToApi(item.address),
						area: numberOrNull(item.area),
						estimatedPrice: numberOrNull(item.estimatedPrice),
					})),
			})

			dispatch(facilityListActions.delete(value))
			dispatch(actions.deleteFacility({ key, value }))
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось удалить объект',

					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		}
	}

export const addFacilityLead =
	({ base, rosreestr, custom }) =>
	async (dispatch, getState) => {
		const { facilities, id } = getState().lead.single.data

		try {
			const { data: facility } = base
				? await api.facility.get(base.id)
				: await api.facility.create(rosreestr || custom)

			await api.lead.update(id, {
				facilities: [].concat(
					facilities.map((facility) => ({
						id: defaultToApi(facility.id),
						type: defaultToApi(facility.type),
						cadastralId: defaultToApi(facility.cadastralId),
						address: defaultToApi(facility.address),
						area: numberOrNull(facility.area),
						estimatedPrice: numberOrNull(facility.estimatedPrice),
					})),
					{
						id: defaultToApi(facility.id),
						type: null,
						cadastralId: defaultToApi(facility.cadastralId),
						address: null,
						area: null,
						estimatedPrice: null,
					}
				),
			})

			try {
				const { data } = await api.lead.get(id)

				dispatch(facilityListActions.add(facility))
				dispatch(actions.updateFacilities(data.facilities))
			} catch {
				//
			}
		} catch (error) {
			dispatch(
				addServerError({
					text: 'Не удалось добавить объект',

					details: utils.getDetailsFromError(error),
				})
			)

			throw error
		}
	}

export const addCadastralIdToFacilityLead = (formData) => async (dispatch, getState) => {
	const { facilities, id } = getState().lead.single.data

	try {
		const { data: facility } = await api.facility.create({
			type: formData.type,
			cadastralId: formData.cadastralId,
		})

		await api.lead.update(id, {
			facilities: [].concat(
				facilities
					.filter(({ pseudoId }) => pseudoId !== formData.pseudoId)
					.map((facility) => ({
						id: defaultToApi(facility.id),
						type: defaultToApi(facility.type),
						cadastralId: defaultToApi(facility.cadastralId),
						address: defaultToApi(facility.address),
						area: numberOrNull(facility.area),
						estimatedPrice: numberOrNull(facility.estimatedPrice),
					})),
				{
					id: defaultToApi(facility.id),
					type: null,
					cadastralId: null,
					address: null,
					area: null,
					estimatedPrice: null,
				}
			),
		})

		try {
			const { data } = await api.lead.get(id)

			dispatch(facilityListActions.add(facility))
			dispatch(actions.updateFacilities(data.facilities))
		} catch {
			//
		}
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось присвоить кадастровый номер',

				details: utils.getDetailsFromError(error),
			})
		)

		throw error
	}
}

export const sendToMosInvest = (id, payload) => async (dispatch) => {
	try {
		dispatch(actions.setMosInvestFetching(true))
		await api.lead.sendToMosInvest(id, payload)

		dispatch(
			pushNotice({
				message: 'Лид успешно отправлен в МОСИНВЕСТФИНАНС',
			})
		)
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось отправить лид в МОСИНВЕСТФИНАНС',
				details: utils.getDetailsFromError(error),
			})
		)
	} finally {
		dispatch(actions.setMosInvestFetching(false))
	}
}

export const downloadPaymentSchedule = (id) => async (dispatch) => {
	try {
		const { data } = await api.finalDecision.getLeadPaymentSchedule(id)

		saveAs(data, 'Предварительный график платежей')
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось скачать предварительный график платежей',
				details: await utils.getDetailsFromErrorBlob(error),
			})
		)
	}
}

export const sendLeadEsiaAuthLink = (payload) => async (dispatch) => {
	try {
		await api.lead.sendEsiaAuthLink(payload)
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось отправить ссылку на верификацию',
				details: utils.getDetailsFromError(error),
			})
		)

		throw error
	}
}