import cx from 'clsx'
import { PARTICIPANT_ROLES } from 'const'
import PropTypes from 'prop-types'

import styles from './DocumentsOnSigning.module.scss'

const ParticipantBlock = (props) => {
	const {
		data: { participant, documents },
	} = props

	return (
		<div className={styles.block}>
			<div className={styles.participant}>
				<div className={styles.roles}>
					{participant.roles
						.map((role, index) =>
							index > 0
								? (PARTICIPANT_ROLES[role] || '').toLowerCase()
								: PARTICIPANT_ROLES[role] || ''
						)
						.join(', ')}
				</div>
				<div className={styles.participantName}>{participant.name}</div>
			</div>
			<div className={styles.documentList}>
				{documents.map((e, i) => (
					<div key={i} className={styles.document}>
						<i
							className={cx('zmdi zmdi-check-circle', { [styles.signed]: !!e.signature.signedAt })}
						/>
						{e.title}
					</div>
				))}
			</div>
		</div>
	)
}

ParticipantBlock.propTypes = {
	data: PropTypes.object,
}

const DocumentsOnSigning = (props) => {
	const { data } = props

	return (
		<div>
			<div className={styles.subTitle}>
				Список участников и документов, которые были отправлены на подпись.
			</div>
			{data.map((data, i) => (
				<ParticipantBlock key={i} data={data} />
			))}
		</div>
	)
}

DocumentsOnSigning.propTypes = {
	data: PropTypes.array,
}

export default DocumentsOnSigning
