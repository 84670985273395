import { DefaultValues } from 'helpers/convertHelper'

export const employeeMain = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		name: model.string(['name']),
		surname: model.string(['surname']),
		patronymic: model.string(['patronymic']),
		phone: model.string(['phone']),
		email: model.string(['email']),
		products: model.array<'APPLICATION'[]>(['products']),
		role: model.string<'OWNER' | 'ADMIN' | 'EMPLOYEE'>(['role']),
		status: model.string<'ACTIVE' | 'INVITED' | 'DEACTIVATED'>(['status']),
		accessToSignService: model.null<boolean>(['accessToSignService']),
	}
}
