import { DefaultValues } from 'helpers/convertHelper'

import { confirmationDocument } from './confirmationDocument'

export const source = <T extends Record<string, any>>(data: T) => {
	const model = new DefaultValues(data)

	const isTemplate = model.null<boolean>(['isTemplate']) ?? false

	return {
		id: model.string(['id']),
		isTemplate: model.null<boolean>(['isTemplate']),
		deletionMark: model.null<boolean>(['deletionMark']),
		confirmationDocument: confirmationDocument(data.confirmationDocument, isTemplate),
	}
}
