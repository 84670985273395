import { DefaultValues } from 'helpers/convertHelper'

export const assessment = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		accuracy: model.null<number>(['accuracy']),
		advertisements: model.array(['advertisements']),
		averagePrice: model.null<number>(['averagePrice']),
		averageSqmPrice: model.null<number>(['averageSqmPrice']),
		distanceToCenter: model.null<number>(['distanceToCenter']),
		location: model.null(['location']),
	}
}
