import { Fragment, useEffect } from 'react'
import Dropzone from 'react-dropzone'
import { connect } from 'react-redux'
import { activateParticipant, onDropzone } from 'actions/filesInspector'
import PropTypes from 'prop-types'

import { fileUtils, getClipboardFile } from '@creditclubteam/helpers'
import { Loading } from 'components/common'

import Controls from './Controls'
import FilesBucket from './FilesBucket'

const Bucket = ({ onlyBucket, fetching, initParticipantId, onDropzone, activateParticipant }) => {
	useEffect(() => {
		if (!onlyBucket) {
			activateParticipant(initParticipantId)
		}

		document.addEventListener('paste', onClipboard)

		return () => {
			document.removeEventListener('paste', onClipboard)
		}
	}, []) // eslint-disable-line

	const onClipboard = async (e) => {
		const results = await getClipboardFile(e)

		onDropzone([results.file])
	}

	const dropZoneOptions = {
		multiple: true,
		onDrop: onDropzone,
		className: 'files-inspector-dropzone',
		accept: fileUtils.getAllowMimeTypes(),
		activeClassName: 'files-inspector-dropzone--active',
		'data-testid': 'dropzone',
	}

	return (
		<Fragment>
			{fetching ? (
				<Loading />
			) : (
				<Fragment>
					<Dropzone {...dropZoneOptions}>
						<p>Перетащите сюда файлы</p>
						<div className='files-inspector-dropzone__overlay'>
							<i className='zmdi zmdi-cloud-download' />
						</div>
					</Dropzone>
					<FilesBucket onlyBucket={onlyBucket} />
					{!onlyBucket && <Controls />}
				</Fragment>
			)}
		</Fragment>
	)
}

Bucket.propTypes = {
	onlyBucket: PropTypes.bool,
	fetching: PropTypes.bool.isRequired,
	initParticipantId: PropTypes.string,

	onDropzone: PropTypes.func.isRequired,
	activateParticipant: PropTypes.func.isRequired,
}

const mapStateToProps = (state) => ({
	...state.filesInspector.bucket,
})

const mapDispatchToProps = {
	onDropzone,
	activateParticipant,
}

export default connect(mapStateToProps, mapDispatchToProps)(Bucket)
