import { DefaultValues } from 'helpers/convertHelper'

export const account = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		type: model.string<'SLACK' | 'MICROSOFT' | 'BEELINE' | 'WHATS_APP' | 'VOXIMPLANT'>(['type']),
		fields: model.null<Record<string, any>>(['fields']),
	}
}
