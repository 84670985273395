import { type Address } from '@creditclubteam/kit/legacy-types'
import { DefaultValues } from 'helpers/convertHelper'

export const entrepreneur = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		fullName: model.string(['fullName']),
		shortName: model.string(['shortName']),
		taxId: model.string(['taxId']),
		ogrn: model.string(['ogrn']),
		phone: model.string(['phone']),
		email: model.string(['email']),
		registrationAddress: model.null<Address>(['registrationAddress']),
		mailingAddress: model.null<Address>(['mailingAddress']),
	}
}
