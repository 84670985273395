import type { creditObligation } from './creditObligation'
import type { enforcementProceedingObligation } from './enforcementProceedingObligation'
import { main } from './main'

export const refinancing = {
	main,
}

export namespace Refinancing {
	export type Main = ReturnType<typeof main>
	export type CreditObligation = ReturnType<typeof creditObligation>
	export type EnforcementProceedingObligation = ReturnType<typeof enforcementProceedingObligation>
}
