import { Component } from 'react'
import { connect } from 'react-redux'
import ReactTooltip from 'react-tooltip'
import { downloadFile, getObjectKeys } from 'actions/document'
import { deleteDocument, onFileRefresh } from 'actions/document'
import { showFilesPreview } from 'actions/filesViewer'
import { addDownloadFileStatus, hideNotice } from 'actions/notices'
import cx from 'clsx'
import { saveAs } from 'file-saver'
import JSZip from 'jszip/dist/jszip'
import JSZipUtils from 'jszip-utils'
import PropTypes from 'prop-types'

import { fileUtils } from '@creditclubteam/helpers'
import { ConfirmDialog } from 'components/common'

import './Controls.scss'

function urlToPromise(url) {
	return new Promise(function (resolve, reject) {
		JSZipUtils.getBinaryContent(url, function (err, data) {
			if (err) {
				reject(err)
			} else {
				resolve(data)
			}
		})
	})
}

class Controls extends Component {
	static propTypes = {
		title: PropTypes.string,
		filesList: PropTypes.array,
		SIGFiles: PropTypes.array.isRequired,

		id: PropTypes.string.isRequired,
		objectKey: PropTypes.string.isRequired,
		entityId: PropTypes.string.isRequired,

		onDelete: PropTypes.func, // callback
		onFileRefresh: PropTypes.func.isRequired, // action
		deleteDocument: PropTypes.func.isRequired, // action
		showFilesPreview: PropTypes.func.isRequired,
		getObjectKeys: PropTypes.func.isRequired, // action
	}

	state = {
		opened: false,
	}

	handleShow = async () => {
		const { id, objectKey, title, filesList, onFileRefresh, showFilesPreview, entityId } =
			this.props

		const files = fileUtils.isImage(title)
			? filesList
					.filter((e) => fileUtils.isImage(e.title))
					.map(({ id, objectKey, title }) => ({
						id,
						objectKey,
						title,
					}))
			: {
					id,
					objectKey,
					title,
			  }

		showFilesPreview(files, id, onFileRefresh, entityId)
	}

	handleDelete = (isConfirmed) => {
		const { id, onDelete: deleteCallback, deleteDocument, entityId } = this.props

		if (isConfirmed) {
			deleteCallback ? deleteCallback(entityId, id) : deleteDocument(entityId, id)
			this.setState({ opened: false })
		}
	}

	handleConfirmDelete = () => {
		this.setState({ opened: true })
	}

	handleDownload = async () => {
		const { objectKey, title, id, getObjectKeys, hideNotice, downloadFile, SIGFiles } = this.props

		if (SIGFiles.length) {
			const [targetFile, ...SIGFilesKeys] = await getObjectKeys([objectKey, ...SIGFiles])
			const zip = new JSZip()

			zip.file(title, urlToPromise(targetFile.uri), { binary: true })

			SIGFilesKeys.forEach((file, index) => {
				zip.file(`Подпись-${index + 1}.sig`, urlToPromise(file.uri), {
					binary: true,
				})
			})

			zip.generateAsync({ type: 'blob' }).then(
				(blob) => {
					saveAs(blob, `${fileUtils.removeFileExtension(title)}.zip`)
				},
				(e) => {
					console.error(e)
				}
			)
		} else {
			downloadFile({
				objectKey,
				filename: title,
				onError: () => hideNotice(id),
			})
		}
	}

	render() {
		const { additionalControls, title } = this.props

		const confirmDialogProps = {
			opened: this.state.opened,
			onClose: () => this.setState({ opened: false }),
			onResult: this.handleDelete,
			text: `Удалить файл "${title}"?`,
		}

		return (
			<div className='document-module-files-category__controls'>
				<ConfirmDialog {...confirmDialogProps} />
				<ReactTooltip effect='solid' className='tooltip' />
				{additionalControls.map((e, i) => (
					<div
						{...(e.tip ? { 'data-tip': e.tip } : {})}
						key={i}
						onClick={e.onClick}
						className={cx('document-module-files-category__controls-item', {
							additional: true,
						})}
					>
						{e.icon}
					</div>
				))}
				<div
					data-tip='Посмотреть'
					onClick={this.handleShow}
					className='document-module-files-category__controls-item'
				>
					<i className='zmdi zmdi-eye' data-testid='document-preview-btn' />
				</div>
				<div
					data-tip='Скачать'
					onClick={this.handleDownload}
					className='document-module-files-category__controls-item'
				>
					<i className='zmdi zmdi-cloud-download' data-testid='document-download-btn' />
				</div>
				<div
					data-tip='Удалить'
					onClick={this.handleConfirmDelete}
					className='document-module-files-category__controls-item'
				>
					<i className='zmdi zmdi-delete' data-testid='document-delete-btn' />
				</div>
			</div>
		)
	}
}

const mapDispatchToProps = {
	hideNotice,
	downloadFile,
	onFileRefresh,
	deleteDocument,
	showFilesPreview,
	getObjectKeys,
	addDownloadFileStatus,
}

export default connect(null, mapDispatchToProps)(Controls)
