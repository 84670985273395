import { DefaultValues } from 'helpers/convertHelper'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		referralProgram: {
			id: model.string(['referralProgram', 'id']),
			name: model.string(['referralProgram', 'name']),
		},
		client: {
			id: model.string(['client', 'id']),
			shortName: model.string(['client', 'shortName']),
		},
		createdAt: model.string(['createdAt']),
		amount: model.null<number>(['amount']),
		ndfl: model.null<number>(['ndfl']),
	}
}