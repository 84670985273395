import axios from 'axios'
import { REQUEST_VERSION } from 'const/api'

export default {
	updateYD: (dates: Record<string, unknown>) => {
		const params = new URLSearchParams()

		Object.keys(dates).forEach((key) => params.append(key, dates[key] as string))

		return axios({
			method: 'POST',
			url: `/analytics/yandex-direct?${params.toString()}`,
			headers: {
				[REQUEST_VERSION]: '1.1',
			},
		})
	},
}
