import { DefaultValues } from 'helpers/convertHelper'

export const responseDataCbBlackList = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		result: model.null<boolean>(['result']),
		fullName: model.string(['fullName']),
		taxId: model.string(['taxId']),
		birthDate: model.string(['birthDate']),
		series: model.string(['series']),
		number: model.string(['number']),
	}
}
