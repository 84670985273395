import { main } from './main'
import { taxResidentPeriod } from './taxResidentPeriod'

export const investmentClient = {
	main,
	taxResidentPeriod,
}

export namespace InvestmentClientDto {
	export type Main = ReturnType<typeof main>
	export type TaxResidentPeriod = ReturnType<typeof taxResidentPeriod>
}
