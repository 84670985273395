import { main } from './main'
import { participant } from './participant'
import { status } from './status'

export const delivery = {
	main,
	status,
	participant,
}

export namespace Delivery {
	export type Main = ReturnType<typeof main>
	export type Participant = ReturnType<typeof participant>
	export type Status = ReturnType<typeof status>
}
