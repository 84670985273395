import { Component } from 'react'
import outsideClick from 'react-click-outside'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { setEventCompleted, setTooltip } from 'actions/calendar'
import cx from 'clsx'
import CALENDAR from 'const/calendar'
import moment from 'moment'
import PropTypes from 'prop-types'
import url from 'routes/urls'

import { utils } from 'helpers'

import Controls from './Controls'

import './Tooltip.scss'

class Tooltip extends Component {
	static propTypes = {
		event: PropTypes.object.isRequired,
		position: PropTypes.object.isRequired,

		setTooltip: PropTypes.func.isRequired,
		setEventCompleted: PropTypes.func.isRequired,
	}

	state = {
		show: false,
	}

	componentDidMount() {
		setTimeout(() => this.setState({ show: true }), 0)
	}

	handleClose = () => {
		const { setTooltip } = this.props

		setTimeout(() => setTooltip({}), 0)
	}

	handleClickOutside() {
		this.handleClose()
	}

	toggleEventCompleted = () => {
		const { event, setEventCompleted } = this.props

		this.handleClose()
		setEventCompleted(event.id)
	}

	calculatePosition = () => {
		const positions = {}
		const { position } = this.props

		// Начальные параметры
		positions.left = position.pageX
		positions.top = position.pageY - this.nodeElement.offsetHeight - 80

		// Предотвращаем выход за пределы календаря, отображаем в разных сторонах
		if (positions.top > 800) positions.top -= this.nodeElement.offsetHeight
		if (positions.left > 850) positions.left -= this.nodeElement.offsetWidth + 45

		// Дописываем пикселя
		positions.top = positions.top + 'px'
		positions.left = positions.left + 'px'

		return positions
	}

	renderTime() {
		const {
			event: { start, end, isAllDay },
		} = this.props

		const isDifferentYear = start.getFullYear() !== end.getFullYear()
		const isDifferentDay = start.getDay() !== end.getDay() && !isDifferentYear

		if (isAllDay) {
			const endDate = new Date(end)
			endDate.setDate(endDate.getDate() - 1)

			const dateIsEqual = utils.datesIsEqual(start, endDate)

			const formatYear = isDifferentYear ? 'D MMM YYYY' : 'D MMM'
			const formatEnd = !isDifferentDay ? '' : formatYear

			let results = moment(start).format(formatYear)

			if (!dateIsEqual) {
				results += ` - ${moment(endDate).format(formatEnd)}`
			}

			return `${results} (Весь день)`
		} else {
			const dateAndTimeIsEqual = utils.timeIsEqual(start, end) && utils.datesIsEqual(start, end)

			const formatYear = isDifferentYear ? 'D MMM YYYY LT' : 'D MMM LT'
			const formatEnd = !isDifferentDay ? 'LT' : formatYear

			let results = moment(start).format(formatYear)

			if (!dateAndTimeIsEqual) {
				results += ` - ${moment(end).format(formatEnd)}`
			}

			return results
		}
	}

	renderSubject() {
		const {
			event: { isAllDay, subject },
		} = this.props

		if (isAllDay && !subject) return 'Без названия'

		return subject
	}

	render() {
		const { show } = this.state
		const {
			event: { $$customParams, seriesMasterId },
		} = this.props

		const tooltipOptions = {
			ref: (el) => (this.nodeElement = el),
			className: cx('calendar-event-tooltip', {
				show,
				[$$customParams.eventType]: !!$$customParams.eventType,
			}),
			style: this.nodeElement && this.calculatePosition(),
		}

		const completedOptions = {
			onClick: this.toggleEventCompleted,
			title: $$customParams.isCompleted ? 'Отменить невыполненным' : 'Отменить выполненным',
			className: cx('calendar-event-tooltip__is-completed', {
				checked: $$customParams.isCompleted,
			}),
		}

		const eventType = CALENDAR.EVENT_TYPES.find(
			(event) => event.id === $$customParams.eventType || ''
		)

		return (
			<div {...tooltipOptions}>
				<div className='calendar-event-tooltip__label'>
					{eventType ? eventType.label : 'Событие без типа'}
				</div>
				<div title={this.renderSubject()} className='calendar-event-tooltip__title'>
					{utils.cutText(this.renderSubject(), 80)}
					<div {...completedOptions} />
				</div>
				<div className='calendar-event-tooltip__time'>
					<i className='zmdi zmdi-time' />
					<span>{this.renderTime()}</span>
				</div>
				{utils.hasObjectLength($$customParams.entity) && (
					<div className='calendar-event-tooltip__entity'>
						<div className='calendar-event-tooltip__label'>Связи</div>
						{$$customParams.entity.map((entity, i) => (
							<Link to={url[entity.path].path(entity.id)} key={i}>
								{entity.title}
							</Link>
						))}
					</div>
				)}
				{seriesMasterId && (
					<div className='calendar-event-tooltip__series'>
						<i className='zmdi zmdi-refresh-alt' />
						Это событие - часть серии
					</div>
				)}
				<Controls event={this.props.event} />
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	...state.calendar.events.tooltip,
})

const mapDispatchToProps = {
	setTooltip,
	setEventCompleted,
}

export default connect(mapStateToProps, mapDispatchToProps)(outsideClick(Tooltip))
