import { DefaultValues } from 'helpers/convertHelper'

export const taxResidentPeriod = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		startDate: model.string(['startDate']),
		taxRate: model.null<number>(['taxRate']),
	}
}
