import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'

type State = {
	query: string
	internal: Array<any>
	external: Array<any>
}

const initialState: State = {
	query: '',
	internal: [],
	external: [],
}

const search = createSlice({
	name: 'search',
	initialState,
	reducers: {
		setField(
			state,
			{ payload: { field, value } }: PayloadAction<{ field: keyof State; value: any }>
		) {
			state[field] = value
		},

		resetSearch(state, { payload = false }: PayloadAction<boolean | undefined>) {
			if (payload) {
				state.external = []
				state.internal = []
			} else {
				return initialState
			}
		},
	},
})

export const { actions } = search
export default search.reducer
