import { DefaultValues } from 'helpers/convertHelper'

import { brand } from './brand'
import { profile } from './profile'
import { smsBrand } from './smsBrand'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		managerId: model.string(['managerId']),
		createdAt: model.string(['createdAt']),
		profile: profile(data?.profile),
		brand: brand(data?.brand),
		smsBrand: smsBrand(data?.smsBrand),
		products: model.array<string[]>(['products']),
	}
}
