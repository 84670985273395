import { Component } from 'react'
import ReactDOM from 'react-dom'
import { connect } from 'react-redux'
import { deleteDocument, downloadFile } from 'actions/document'
import { deleteFile, removeFileInCategory } from 'actions/filesInspector'
import { closeFilesPreview, removeFileFromViewer, rotateImage } from 'actions/filesViewer'
import { addDownloadFileStatus, hideNotice } from 'actions/notices'
import cx from 'clsx'
import PropTypes from 'prop-types'

import { fileUtils } from '@creditclubteam/helpers'
import { ConfirmDialog } from 'components/common'

import ViewAudio from './ViewAudio'
import ViewDocs from './ViewDocs'
import ViewImages from './ViewImages'
import ViewPDF from './ViewPDF'
import ViewVideo from './ViewVideo'

import './FilesViewer.scss'

class FilesViewer extends Component {
	static propTypes = {
		filesType: PropTypes.string,
		error: PropTypes.bool.isRequired,
		opened: PropTypes.bool.isRequired,
		filesList: PropTypes.array.isRequired,
		index: PropTypes.number.isRequired,
		entityId: PropTypes.string,
		zoneId: PropTypes.string,

		// from store
		onRefresh: PropTypes.func,

		// from hoc
		hideNotice: PropTypes.func.isRequired,
		rotateImage: PropTypes.func.isRequired,
		closeFilesPreview: PropTypes.func.isRequired,
		addDownloadFileStatus: PropTypes.func.isRequired,
		deleteDocument: PropTypes.func.isRequired,
		removeFileFromViewer: PropTypes.func.isRequired,
	}

	state = {
		show: false,
		confirmOpened: false,
	}

	componentDidMount() {
		this.app = document.getElementById('root')
		this.container = document.createElement('div')
		this.app.appendChild(this.container)

		this.setState({ show: true })
	}

	componentWillUnmount() {
		if (this.container) this.app.removeChild(this.container)
	}

	handleClose = () => {
		const { closeFilesPreview } = this.props

		closeFilesPreview()
	}

	handleDownload = (indexImage = 0) => {
		const { filesList, downloadFile } = this.props

		const { objectKey = '', title } = filesList[indexImage] || {}

		downloadFile({
			objectKey,
			filename: title,
			disableActions: true,
		})
	}

	renderViewer() {
		const { opened, filesList, filesType, onRefresh, rotateImage, index, entityId } = this.props

		if (fileUtils.extensions.images.includes(filesType))
			return (
				<ViewImages
					opened={opened}
					images={filesList}
					rotateImage={rotateImage}
					index={index}
					downloadImage={this.handleDownload}
					allowSaveRotation={Boolean(onRefresh)}
					entityId={entityId}
					deleteImage={this.handleConfirmDelete}
				/>
			)
		else if (fileUtils.extensions.pdf.includes(filesType))
			return <ViewPDF file={filesList[index]} />
		else if (fileUtils.extensions.documents.includes(filesType))
			return <ViewDocs files={filesList} />
		else if (fileUtils.extensions.videos.includes(filesType)) return <ViewVideo files={filesList} />
		else if (fileUtils.extensions.audio.includes(filesType)) return <ViewAudio files={filesList} />

		return <div className='files-viewer-reject-format'>Недопустимый формат</div>
	}

	handleConfirmDelete = () => this.setState({ confirmOpened: true })

	handleConfirmClose = () => this.setState({ confirmOpened: false })

	handleDelete = () => {
		const {
			entityId,
			filesType,
			filesList,
			index,
			deleteDocument,
			deleteFile,
			removeFileFromViewer,
			zoneId,
		} = this.props

		if (entityId) {
			zoneId
				? deleteFile(filesList[index].id, zoneId)
				: deleteDocument(entityId, filesList[index].id)

			removeFileFromViewer(index)

			this.handleConfirmClose()

			if (fileUtils.extensions.pdf.includes(filesType)) {
				this.handleClose()
			}
		}
	}

	render() {
		const { opened, filesType, filesList, index } = this.props

		const { show } = this.state

		const custom = !fileUtils.extensions.images.includes(filesType)

		const wrapClassName = cx('files-viewer', { custom })

		const deleteConfirmDialogProps = {
			opened: this.state.confirmOpened,
			onClose: this.handleConfirmClose,
			onResult: this.handleDelete,
			text: `Удалить файл "${filesList[index]?.title}"?`,
		}

		if (opened && show && filesList?.length)
			return ReactDOM.createPortal(
				<>
					<ConfirmDialog {...deleteConfirmDialogProps} />
					<div
						className='files-viewer-close'
						data-testid='file-view-close-btn'
						onClick={this.handleClose}
					>
						<i className='zmdi zmdi-close' />
					</div>
					<div className={wrapClassName}>
						{custom && <div className='files-viewer-overlay' />}
						{this.renderViewer()}
						{custom && (
							<>
								<div onClick={() => this.handleDownload(index)} className='files-viewer-download'>
									<i className='zmdi zmdi-download' data-testid='file-view-download-btn' />
								</div>
								<div onClick={this.handleConfirmDelete} className='files-viewer-delete'>
									<i className='zmdi zmdi-delete' data-testid='file-view-delete-btn' />
								</div>
							</>
						)}
					</div>
				</>,
				this.container
			)

		return null
	}
}

const mapStateToProps = (state) => ({
	...state.filesViewer,
})

const mapDispatchToProps = {
	hideNotice,
	rotateImage,
	downloadFile,
	closeFilesPreview,
	addDownloadFileStatus,
	deleteDocument,
	deleteFile,
	removeFileFromViewer,
	removeFileInCategory,
}

export default connect(mapStateToProps, mapDispatchToProps)(FilesViewer)
