import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Deposit } from 'converters/deposit'
import { commonActions } from 'reducers/common'
import type { PayloadStatus } from 'types/redux'
import type { Maybe } from 'types/util'

const initialState = {
	data: null as Maybe<Deposit.Main>,
	paymentSchedule: null as Maybe<Deposit.PaymentSchedule>,
	status: 'pending' as PayloadStatus,
}

const single = createSlice({
	name: 'deposit/single',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Deposit.Main>) {
			state.data = payload
		},

		setPaymentSchedule(state, { payload }: PayloadAction<Deposit.PaymentSchedule>) {
			state.paymentSchedule = payload
		},

		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions: depositSingleReducerActions } = single
export default single.reducer
