import { DefaultValues } from 'helpers/convertHelper'

export const creditHistoryAgreement = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		startDate: model.string(['startDate']),
		endDate: model.string(['endDate']),
	}
}
