const PERSON_CATEGORY_ORDER = [
	'PASSPORT_MAIN',
	'PASSPORT_REGISTRATION',
	'PASSPORT',
	'MILITARY_ID',
	'SELFIE_WITH_PASSPORT',
	'UNIFIED_PERSONALIZED_CONSENT',
	'UNIFIED_IMPERSONAL_CONSENT',
	'SNILS',
	'TAX_ID',
	'INCOME_REFERENCE',
	'5ce2957c22758a0001dd10c3',
	'MARRIAGE_CERTIFICATE',
	'DIVORCE_CERTIFICATE',
	'MARRIAGE_CONTRACT',
	'OTHER',
]

const FACILITY_CATEGORY_ORDER = [
	'DOCUMENT_OF_OWNERSHIP',
	'PHOTOS',
	'EGRN',
	'CONSENT_OF_SPOUSE',
	'CURRENT_LENDER_DOCUMENTS',
	'5cb02a1900a1590001359610',
	'CERTIFICATE_OF_OWNERSHIP',
	'CERTIFICATE_OF_ACCEPTANCE',
	'INQUIRY_REGISTERED_PERSONS',
	'PROOF_OF_PAYMENT',
	'5cc82ef49594e10001ab3387',
	'OTHER',
]

export const INSPECTOR_CATEGORY_ORDER = {
	PERSON: PERSON_CATEGORY_ORDER,
	FACILITY: FACILITY_CATEGORY_ORDER,
}
