import type { SOURCE } from 'const/externalData'

import { utils } from 'helpers'
import { defaultToStr, DefaultValues } from 'helpers/convertHelper'

export const customerData = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		name: model.string(['name']),
		surname: model.string(['surname']),
		patronymic: model.string(['patronymic']),
		fullName: defaultToStr(utils.getFullName(data)),
		birthDate: model.string(['birthDate']),
		birthplace: model.string(['birthplace']),
		taxId: model.string(['taxId']),
		phone: model.string(['phone']),
		passport: {
			series: model.string(['passport', 'series']),
			number: model.string(['passport', 'number']),
			issueDate: model.string(['passport', 'issueDate']),
		},
		vehicleCert: {
			series: model.string(['vehicleCert', 'series']),
			number: model.string(['vehicleCert', 'number']),
			issueDate: model.string(['vehicleCert', 'issueDate']),
		},
		source: model.string<keyof typeof SOURCE>(['source']),
	}
}
