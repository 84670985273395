import { ENTITY_TYPES } from 'const'

import { utils } from 'helpers'
import { defaultToStr, DefaultValues } from 'helpers/convertHelper'

import { form } from './form'
import { fullName } from './fullName'
import { identification } from './identification'
import { linkedEntity } from './linkedEntity'
import { phoneNumber } from './phoneNumber'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		name: model.string(['name']),
		surname: model.string(['surname']),
		patronymic: model.string(['patronymic']),
		esiaIdDate: model.string(['esiaIdDate']),
		fullName: defaultToStr(utils.getFullName(data)),
		fullNameShort: defaultToStr(utils.getFullName(data, true)),
		phone: model.string(['phone']),
		signatureProvider: model.string(['signatureProvider']),
		form: form(data),
		part: model.null<number>(['part']),
		roles: model.array<string[]>(['roles']),
		mainContact: model.null<boolean>(['mainContact']),
		konturInfo: model.null<Record<string, any>>(['konturInfo']),
		linkedEntities: model.array(['linkedEntities']).map(linkedEntity),
		additionalPhoneNumbers: model.array(['additionalPhoneNumbers']).map(phoneNumber),
		previousFullNames: model.array(['previousFullNames']).map(fullName),
		identifications: model.array(['identifications']).map(identification),
		$$type: ENTITY_TYPES.PERSON,
	}
}
