import { DefaultValues } from 'helpers/convertHelper'

import { debtBalance } from './debtBalance'
import { overdueBalance } from './overdueBalance'
import { upcomingPayment } from './upcomingPayment'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		createdAt: model.string(['createdAt']),
		modifiedAt: model.string(['modifiedAt']),
		loanId: model.string(['loanId']),
		loanAmount: model.null<number>(['loanAmount']),
		loanDurationMonths: model.null<number>(['loanDurationMonths']),
		contractDate: model.string(['contractDate']),
		actualClosingDate: model.null(['actualClosingDate']),
		accountBalance: model.null<number>(['accountBalance']),
		annualInterestRate: model.null<number>(['annualInterestRate']),
		overdueDays: model.null<number>(['overdueDays']),
		overduePayments: model.null<number>(['overduePayments']),
		debtBalance: debtBalance(data.debtBalance),
		paymentType: model.const(data.paymentType, {
			ANNUITY: 'Аннуитетный',
			PERCENT: 'Процентный',
			MIXED: 'Смешанный',
		} as const),
		upcomingPayment: upcomingPayment(data.upcomingPayment),
		overdueBalance: overdueBalance(data.overdueBalance),
		version: model.null<number>(['version']),
	}
}
