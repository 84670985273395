import { DefaultValues } from 'helpers/convertHelper'

export const linkButton = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		title: model.string(['title']),
		url: model.string(['url']),
		type: model.string<'link-button'>(['type']),
	}
}
