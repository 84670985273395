import {
	always,
	anyPass,
	both,
	compose,
	defaultTo,
	equals,
	identity,
	ifElse,
	is,
	isEmpty,
	not,
	pathOr,
	trim,
} from 'ramda'

export class DefaultValues<T = Record<string, any>> {
	private data: T

	constructor(data: T) {
		this.data = data
	}

	null = <T extends number | boolean | Record<string, any> | any[]>(path: (string | number)[]) =>
		pathOr<T | null>(null, path, this.data)

	string = <T extends string>(path: (string | number)[]) => pathOr<T>('' as T, path, this.data)

	array = <T extends any[]>(path: (string | number)[]) => pathOr<T>([] as any, path, this.data)

	const = <T1 extends string, T2 extends Record<string, string>>(
		value: T1,
		map: T2
	): T2[keyof T2] => defaultToStr(map[value]) as any
}

export const defaultToStr = defaultTo('') as <T>(v?: T | null) => NonNullable<T> | string
export const defaultToNull = defaultTo(null)
export const defaultToArr = defaultTo([])
export const defaultToApi = ifElse(
	anyPass([
		Number.isInteger,
		both(is(String), compose(not, isEmpty, trim)),
		is(Boolean),
		is(Object),
	]),
	ifElse(is(String), trim, identity),
	always(null)
) as <T>(value: T) => any
export const numberOrNull = ifElse(equals(''), always(null), Number) as <T>(
	value: T
) => number | null
export const inInt = (n: number) => n % 1 === 0
export const numberToStr = (n: number, addRub?: boolean) => {
	const res = n.toLocaleString('ru-Ru')
	if (inInt(n)) {
		if (addRub) {
			return res + ',00\u00A0₽'
		}
		return res + ',00'
	}
	if (addRub) {
		return res + '\u00A0₽'
	}
	return res
}
