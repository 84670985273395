import PropTypes from 'prop-types'

import './Document.scss'

const Document = ({ url, caption }) => (
	<div className='history-whatsapp-document'>
		{url ? (
			<a href={url}>
				<i className='zmdi zmdi-file' />
				Документ
			</a>
		) : (
			<div>Ссылка на файл отсутствует</div>
		)}
		{caption && <div className='history-whatsapp-document__caption'>{caption}</div>}
	</div>
)

Document.propTypes = {
	caption: PropTypes.any,
	url: PropTypes.string,
}

export default Document
