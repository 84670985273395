import PropTypes from 'prop-types'

import { getFullCalculation } from 'helpers'

import Context from '../../Context'

import './Calculation.scss'

const Calculation = ({ $$date, metadata }) => {
	const calculation = getFullCalculation(metadata)

	const contextOptions = {
		date: $$date.fullDate,
		className: 'history-calculation',
		title: `Новый расчёт: ${calculation['сумма'] || '0 ₽'} на ${
			calculation['срок'] || '0 мес.'
		} под ${calculation['ставка'] || '0%'}`,
	}

	return <Context {...contextOptions} />
}

Calculation.propTypes = {
	$$date: PropTypes.object.isRequired,
	metadata: PropTypes.shape({
		calculation: PropTypes.object.isRequired,
	}),
}

export default Calculation
