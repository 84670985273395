import { brief } from './brief'
import { calculation } from './calculation'
import { collection } from './collection'
import { collectionEvent } from './collectionEvent'
import { commission } from './commission'
import { entityMetadata } from './entityMetadata'
import { eventCategories } from './eventCategories'
import { main } from './main'
import { participant } from './participant'
import { share } from './share'
import { status } from './status'
import { transition } from './transition'

export const loan = {
	main,
	collection,
	calculation,
	participant,
	entityMetadata,
	eventCategories,
	collectionEvent,
	transition,
	status,
	brief,
	share,
	commission,
}

export namespace Loan {
	export type Main = ReturnType<typeof main>
	export type Brief = ReturnType<typeof brief>
	export type Collection = ReturnType<typeof collection>
	export type Calculation = ReturnType<typeof calculation>
	export type Participant = ReturnType<typeof participant>
	export type Status = ReturnType<typeof status>
	export type Transition = ReturnType<typeof transition>
	export type EventCategories = ReturnType<typeof eventCategories>
	export type CollectionEvent = ReturnType<typeof collectionEvent>
	export type EntityMetadata = ReturnType<typeof entityMetadata>
	export type Share = ReturnType<typeof share>
	export type Commission = ReturnType<typeof commission>
	export type Application = ReturnType<typeof entityMetadata>
}
