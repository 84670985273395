import { Component } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import url from 'routes/urls'

import { utils } from 'helpers'

import './Entities.scss'

class Entities extends Component {
	static propTypes = {
		form: PropTypes.object.isRequired, // parent local state

		fetching: PropTypes.bool.isRequired,
		setCustomParams: PropTypes.func.isRequired,
	}

	handleDeleteEntity = (id) => {
		const { form, setCustomParams } = this.props

		const entities = form.$$customParams.entity
		const index = entities.findIndex((entity) => entity.id === id)

		if (index >= 0) {
			entities.splice(index, 1)
			setCustomParams('entity', entities)
		}
	}

	render() {
		const { form } = this.props

		return (
			<div className='calendar-event-form__entities'>
				<div className='calendar-event-form__entities-label'>Связи</div>
				{utils.hasObjectLength(form.$$customParams.entity) && (
					<div className='calendar-event-form__entities-items'>
						{form.$$customParams.entity.map((entity) => (
							<div className='calendar-event-form__entity' key={entity.id}>
								<Link to={url[entity.path].path(entity.id)}>{entity.title}</Link>
								<i className='zmdi zmdi-close' onClick={() => this.handleDeleteEntity(entity.id)} />
							</div>
						))}
					</div>
				)}
			</div>
		)
	}
}

const mapStateToProps = (state) => ({
	fetching: state.calendar.eventForm.fetching,
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Entities)
