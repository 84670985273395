import type { customerData } from './customerData'
import { main } from './main'
import type { proceedings } from './proceedings'
import type { request } from './request'
import type {
	creditsOverdue,
	responseDataCreditHistory,
	totalCreditsOverdue,
} from './responseDataCreditHistory'

export const externalData = {
	main,
}

export namespace ExternalData {
	export type Main = ReturnType<typeof main>
	export type Proceedings = ReturnType<typeof proceedings>
	export type CreditHistory = ReturnType<typeof responseDataCreditHistory>
	export type Request = ReturnType<typeof request>
	export type CustomerData = ReturnType<typeof customerData>
	export type CreditOverdue = ReturnType<typeof creditsOverdue>
	export type TotalCreditOverdue = ReturnType<typeof totalCreditsOverdue>
}
