import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { Loan } from 'converters/loan'
import { commonActions } from 'reducers/common'
import type { Maybe } from 'types/util'

export type LoanFilter = {
	status: Maybe<string[]>
	channel: Maybe<string[]>
	collectionStage: Maybe<string[]>
	participantId: Maybe<string>
	workerId: Maybe<string>
	minLtv: Maybe<number>
	interestPeriods: Maybe<[Maybe<number>, Maybe<number>]> // [min, max]
	facilityId: Maybe<string>
}

const filter: LoanFilter = {
	status: null,
	channel: null,
	interestPeriods: null,
	minLtv: null,
	collectionStage: null,
	participantId: null,
	workerId: null,
	facilityId: null,
}

const initialState = {
	data: [] as Loan.Main[],
	fetching: false,
	filter,
}

const list = createSlice({
	name: 'loan/list',
	initialState,
	reducers: {
		setData(state, { payload }: PayloadAction<Loan.Main[]>) {
			state.data = payload
		},

		// Обновление записи в списке
		updateRecord(
			state,
			{ payload }: PayloadAction<{ id: string; key: keyof Loan.Main; value: unknown }>
		) {
			const { id, key, value } = payload

			const recordIndex = state.data.findIndex((item) => item.id === id)

			if (recordIndex === -1) {
				return
			}

			// @ts-ignore
			// игнор бага, в котором левый операнд вычисляется как never
			state.data[recordIndex][key] = value
		},

		setFilter: commonActions.setFilter(),
		setFetchingStatus: commonActions.setFetchingStatus,
		reset: commonActions.reset(initialState),
	},
})

const { actions } = list

export { actions }
export default list.reducer
