import type { EntityId } from 'api/types/EntityId'
import axios from 'axios'
import { REQUEST_VERSION } from 'const/api'

export default {
	getAdvertisements: (ids: Array<EntityId>) => {
		const params = new URLSearchParams()

		ids.forEach((id) => params.append('id', id as string))

		return axios.get(`assessment/realty/search?${params}`, {
			headers: {
				[REQUEST_VERSION]: '1.1',
			},
		})
	},

	assessment: (data: unknown) =>
		axios.post('/assessment/', data, {
			headers: {
				[REQUEST_VERSION]: '1.1',
			},
		}),
}
