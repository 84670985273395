import cx from 'clsx'
import logo from 'images/only-logo.svg'
import styled from 'styled-components'

import styles from './PageLoading.module.scss'

export interface PageLoadingProps {
	min?: boolean
	max?: boolean
	white?: boolean
	fullHeight?: boolean
	fullVh?: boolean
	height?: number
	className?: string
	useLogo?: boolean
}

export const PageLoading = styled(
	({ min, max, fullHeight, fullVh, useLogo, white, className }: PageLoadingProps) => {
		if (useLogo)
			return (
				<div
					className={cx(
						styles.logoLoading,
						{
							[styles.fullHeight]: fullHeight,
							[styles.fullVh]: fullVh,
						},
						className
					)}
				>
					<img src={logo} className={cx(styles.logo)} />
				</div>
			)

		return (
			<div
				className={cx(
					styles.loading,
					{
						[styles.min]: min,
						[styles.max]: max,
						[styles.white]: white,
						[styles.fullHeight]: fullHeight,
						[styles.fullVh]: fullVh,
					},
					className
				)}
			>
				<div className={styles.ring}>
					<div />
					<div />
					<div />
					<div />
				</div>
			</div>
		)
	}
)(({ height }) => ({ height }))
