import { right } from './right'
import { DefaultValues } from 'helpers/convertHelper'

export const form = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		address: model.string(['address']),
		assignment: model.string(['assignment']),
		type: model.string<'BUILDING' | 'FLAT' | 'UNCOMPLETED' | 'PARCEL'>(['type']),
		subType: model.string(['subType']),
		maternalCapital: model.string(['maternalCapital']),
		wallMaterial: model.string(['wallMaterial']),
		registrator: model.string(['registrator']),
		utilization: model.string(['utilization']),
		otherNegative: model.string(['otherNegative']),
		acquiredInMarriage: model.string<'TRUE' | 'FALSE' | 'UNKNOWN'>(['acquiredInMarriage']),
		transferAcceptanceCertificateSigningDate: model.string([
			'transferAcceptanceCertificateSigningDate',
		]),
		usageType: model.string(['usageType']),
		lifetimeUseRightPersonsCount: model.null<number>(['lifetimeUseRightPersonsCount']),
		floor: model.null<number>(['floor']),
		floors: model.null<number>(['floors']),
		flatFloors: model.null<number>(['flatFloors']),
		builtYear: model.null<number>(['builtYear']),
		area: model.null<number>(['area']),
		lastRepairYear: model.null<number>(['lastRepairYear']),
		cadastralPrice: model.null<number>(['cadastralPrice']),
		collateralValue: model.null<number>(['collateralValue']),
		rights: model.array(['rights']).map(right),
		rightMovements: model.array<Record<string, any>[]>(['rightMovements']),
		egrnRecordMissing: model.string(['egrnRecordMissing']),
	}
}
