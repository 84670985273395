import type { AxiosResponse } from 'axios'
import axios from 'axios'
import type { CreditProgram } from 'converters/creditProgram'
import { creditProgram } from 'converters/creditProgram'
import { mergeDeepRight } from 'ramda'

import type { ApplicantType, MaxLoanAmountCalculationDto } from 'components/common/Calculator/types'

import type { Pageable } from './types/Pageable'
import { URLBuilder } from './helpers'

export default {
	calculate: (data: unknown) => axios.post('/v2/calculation/payment-schedules', data),

	program: {
		search: (type: ApplicantType) => {
			const params = new URLBuilder()

			params.addFilter('applicantTypes', type, 'eq')
			params.addFilter('enabled', true, 'eq')

			return axios
				.get(`/v2/credit-programs`, {
					params: {
						size: 50,
						filter: params.build('RSQL'),
					},
				})
				.then(
					(response) =>
						mergeDeepRight(response, {
							data: {
								content: response.data.content.map(creditProgram.main),
							},
						}) as AxiosResponse<Pageable<CreditProgram.Main>>
				)
		},

		get: (id: string) =>
			axios
				.get<CreditProgram.Main>(`/v2/credit-programs/${id}`)
				.then((response) =>
					Object.assign({}, response, { data: creditProgram.main(response.data) })
				),
	},

	maxLoanAmount: (data: unknown) =>
		axios.post<MaxLoanAmountCalculationDto>(`/v2/calculation/max-loan-amounts`, data),
}
