import { Component } from 'react'
import { connect } from 'react-redux'
import { initDocument, pollingDocuments } from 'actions/document'
import {
	addDocuments,
	addSigner,
	deleteDocument,
	fetchInitialDataToSign,
	resetSign,
} from 'actions/document'
import { setModalShow } from 'actions/modalDialogs'
import PropTypes from 'prop-types'
import { flatten } from 'ramda'

import ModalDialog from 'components/common/ModalDialog'
import { PollingRequest } from 'helpers'

import { selectSignableDocumentsForAggregator } from './converter'
import DocumentConstructor from './DocumentConstructor'
import DocumentModule from './DocumentModule'
import SignersController from './SignersController'
import { SynchronizationDocuments } from './SynchronizationDocuments'

import './Documents.scss'

class Documents extends Component {
	static propTypes = {
		children: PropTypes.any,

		entityType: PropTypes.string,
		sign: PropTypes.object.isRequired,
		disableConstructor: PropTypes.bool,
		allowSign: PropTypes.bool.isRequired,
		allowSynch: PropTypes.bool.isRequired,
		entityId: PropTypes.string.isRequired,
		ownerTypes: PropTypes.array.isRequired,
		modalDialogsShow: PropTypes.object.isRequired,
		enablePolling: PropTypes.bool,

		addSigner: PropTypes.func.isRequired,
		addDocuments: PropTypes.func.isRequired,
		setModalShow: PropTypes.func.isRequired,
		deleteDocument: PropTypes.func.isRequired,
		pollingDocuments: PropTypes.func.isRequired,
	}

	pollingRequest = null

	state = {
		category: {},
		pollingRequest: null,
	}

	static defaultProps = {
		allowSign: false,
		disableConstructor: false,
		enablePolling: false,
		allowSynch: false,
	}

	componentDidMount() {
		const { enablePolling } = this.props

		if (enablePolling) {
			this.initPolling()
		}
	}

	componentWillUnmount() {
		const { resetSign, enablePolling } = this.props

		resetSign()

		if (enablePolling) {
			this.disablePolling()
		}
	}

	// ACTIONS BEGIN

	handleCreate = (category) => {
		const { entityId, initDocument, entityType } = this.props

		this.setState({ category })

		initDocument({ entityId, category, entityType })
	}

	handleEdit = (documentId, category) => {
		const { initDocument, entityId, entityType } = this.props

		this.setState({ category, documentIdForEdit: documentId })

		initDocument({ entityId, category, entityType })
	}

	initPolling = () => {
		const { pollingDocuments, entityId } = this.props

		this.pollingRequest = new PollingRequest()

		this.pollingRequest
			.setIntervalTime(10000)
			.setRequest((cancelToken) => pollingDocuments(entityId, cancelToken))
			.startPolling()
	}

	disablePolling = () => {
		this.pollingRequest.stopPolling()
	}

	// ACTIONS END

	// HELPERS BEGIN

	setFetching = (param, value, callback) => {
		this.setState(
			{
				fetching: {
					...this.state.fetching,
					[param]: value,
				},
			},
			callback && callback()
		)
	}

	render() {
		const { category, documentIdForEdit } = this.state

		const {
			sign,
			children,
			entityId,
			allowSign,
			allowSynch,
			addSigner,
			ownerTypes,
			entityType,
			setModalShow,
			modalDialogsShow,
			participantsParent,
			disableConstructor,
			allowCreateDocuments,
			fetchInitialDataToSign,
			enablePolling,
		} = this.props

		const constructorCallbacks = !disableConstructor
			? {
					onEdit: this.handleEdit,
					onCreate: this.handleCreate,
			  }
			: {}

		const documentModuleProps = {
			sign,
			participants: {
				data: [],
				fetching: false,
			},
			participantsParent,
			entityId,
			allowSign,
			ownerTypes,
			entityType,
			allowCreateDocuments,
			fetchInitialDataToSign,
			...constructorCallbacks,
			enablePolling,
			initPolling: this.initPolling,
			disablePolling: this.disablePolling,
		}

		const onCloseForConstructorModalDialog = () => {
			setModalShow(false, 'documentConstructor')

			this.setState({ documentIdForEdit: '' })
		}

		const documentConstructorModalProps = {
			title: category.title || '',
			onClose: onCloseForConstructorModalDialog,
			opened: modalDialogsShow.documentConstructor,
		}

		const signerControllerProps = {
			aggregator: sign.aggregator,
			participants: sign.participants,
			contextId: entityId,
			contextType: entityType,

			addSigner,
		}

		const normalizeParticipants = ({ id, metadata }) => ({ id, label: metadata?.title, value: id })

		const warrantors = (participantsParent || []).filter(
			({ roles, type }) => roles && roles?.includes('WARRANTOR') && type === 'PERSON'
		)

		const persons = (participantsParent || []).filter(({ type }) => type === 'PERSON')

		const _warrantors = warrantors.map(normalizeParticipants)

		const _persons = persons.map(normalizeParticipants)

		const owners = (participantsParent || []).filter(
			({ roles, type }) => roles?.includes('OWNER') && type === 'PERSON'
		)

		const _owners = owners.map(normalizeParticipants)

		return (
			<>
				{allowSynch && <SynchronizationDocuments />}
				{allowSign && <SignersController {...signerControllerProps} />}
				{children}
				{!disableConstructor && (
					<ModalDialog {...documentConstructorModalProps}>
						<DocumentConstructor
							persons={_persons}
							entityId={entityId}
							entityType={entityType}
							warrantors={_warrantors}
							owners={_owners}
							documentIdForEdit={documentIdForEdit}
							constructorSpec={category.constructorSpec}
							onClose={onCloseForConstructorModalDialog}
							categoryId={category.id}
						/>
					</ModalDialog>
				)}
				<DocumentModule {...documentModuleProps} />
			</>
		)
	}
}

const mapStateToProps = (state, { entityType }) => ({
	sign: {
		...state.document.sign,
		aggregator: selectSignableDocumentsForAggregator({
			documents: state.document.files.data,
			categories: flatten(state.document.categories.data.map((category) => category.categories)),
			entityType,
		}),
	},
	modalDialogsShow: state.modalDialogs,
})

const mapDispatchToProps = {
	resetSign,
	addSigner,
	initDocument,
	addDocuments,
	setModalShow,
	deleteDocument,
	pollingDocuments,
	fetchInitialDataToSign,
}

export default connect(mapStateToProps, mapDispatchToProps)(Documents)
