import { utils } from 'helpers'
import { defaultToStr, DefaultValues } from 'helpers/convertHelper'

export const delivery = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		name: model.string(['name']),
		surname: model.string(['surname']),
		patronymic: model.string(['patronymic']),
		fullName: defaultToStr(utils.getFullName(data)),
		fullNameShort: defaultToStr(utils.getFullName(data, true)),
	}
}
