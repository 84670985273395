import { URLBuilder } from 'api/helpers'
import type { Options } from 'api/types/Options'
import axios from 'axios'
import type { MoneyMovementsFilter } from 'reducers/moneyMovements'

import type { MoneyMovements } from '../converters/moneyMovements'
import { moneyMovements } from '../converters/moneyMovements'

import type { Pageable } from './types/Pageable'

export default {
	search: async ({ filter, size = 10, page = 0 }: Options<MoneyMovementsFilter>) => {
		const params = new URLBuilder()

		params.addFilter('loanId', filter?.loanId, 'eq')

		return axios
			.get('/v1/loan-money-movements', {
				params: {
					size,
					page,
					filter: params.build('RSQL'),
				},
			})
			.then(
				(response) =>
					Object.assign(
						{},
						{
							content: response.data.content.map(moneyMovements.main),
						}
					) as Pageable<MoneyMovements.Main>
			)
	},
}
