import type { SOURCE } from 'const/externalData'

import { DefaultValues } from 'helpers/convertHelper'

export const request = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		lastRequestInterval: model.string(['lastRequestInterval']),
		requestsFor30Days: model.null<number>(['requestsFor30Days']),
		requestsFor2Years: model.null<number>(['requestsFor2Years']),
		totalRequests: model.null<number>(['totalRequests']),
		source: model.string<keyof typeof SOURCE>(['source']),
	}
}
