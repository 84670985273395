 
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { deleteSimpleSignatureSigner, deleteStandardSignatureSigner } from 'actions/document/files'
import cx from 'clsx'
import PropTypes from 'prop-types'
import { isEmpty } from 'ramda'

import { ConfirmDialog } from 'components/common'

import { isValidCertificate } from './helpers/isValidCertificate'

import styles from './SignCountInfo.module.scss'

const collectInfoAboutSignedDocs = (customers, signatures) => {
	const totalSignatures = signatures.length

	const [totalSignedSignatures, signers] = signatures.reduce(
		([totalSignedSignatures, signers], { signedAt, context, signer, certificate }) => {
			if (signedAt && certificate) {
				totalSignedSignatures++
			}

			if (signer.subject) {
				const isSigned = !!(signedAt && certificate)
				const isCertificateValid = certificate?.activeTo
					? isValidCertificate({ certificateActiveTo: certificate?.activeTo })
					: true

				const customer = customers.find(({ customerId }) => customerId === signer.subject.id)

				if (signer.subject.type === 'EXTERNAL') {
					signers.push({
						name: 'внешняя подпись',
						context: { id: context.id, type: context.type },
						isSigned,
						isCertificateValid,
					})
				} else if (customer) {
					signers.push({
						id: customer.customerId,
						context: { id: context.id, type: context.type },
						type: customer.customerType,
						name: customer.name,
						isSigned,
						isCertificateValid,
					})
				}
			}

			return [totalSignedSignatures, signers]
		},
		[0, []]
	)

	return {
		totalSignatures,
		totalSignedSignatures,
		signers,
	}
}

const getSimpleSignatureSigner = (data) => {
	const { customers, simpleSignature } = data

	const customer = customers?.find(
		(customer) => customer.customerId === simpleSignature?.signer?.subject?.id
	)

	return customer?.name || ''
}

const Signers = ({ data, handleClickSigner }) => {
	return (
		<div className={styles.signers}>
			{data.map((signer, index) => (
				<div
					key={`${signer.id}-${signer.type}-${index}`}
					onClick={!signer.isSigned ? () => handleClickSigner(signer) : undefined}
				>
					<span className={cx({ [styles.signed]: !signer.isSigned })}>
						{signer.name}
						{!signer.isCertificateValid && ' (истек)'}
					</span>
					{data[index + 1] ? <span>,&nbsp;</span> : null}
				</div>
			))}
		</div>
	)
}

const SignCountInfo = ({ customers, signatures, simpleSignatures, documentId }) => {
	const dispatch = useDispatch()
	const [isOpenModalDelSigner, setOpenModalDelSigner] = useState(false)
	const [deleteSimpleSignerData, setDeleteSimpleSignerData] = useState(null)
	const [deleteStandardSignerData, setDeleteStandardSignerData] = useState(null)

	if (isEmpty(signatures) && isEmpty(simpleSignatures)) return null

	const { signers, totalSignatures, totalSignedSignatures } = collectInfoAboutSignedDocs(
		customers,
		signatures
	)

	const simpleSignatureSigners = collectInfoAboutSignedDocs(customers, simpleSignatures)

	const handleClickSigner = (signatureType) => (signer) => {
		setOpenModalDelSigner(true)

		const data = {
			context: {
				contextId: signer.context.id,
				contextType: signer.context.type,
			},
			documents: [documentId],
			signer: {
				customerId: signer.id,
				customerType: signer.type,
			},
		}

		signatureType === 'SIMPLE' ? setDeleteSimpleSignerData(data) : setDeleteStandardSignerData(data)
	}

	if (!totalSignatures && !simpleSignatureSigners.totalSignatures) return null

	return (
		<div className={styles.wrap}>
			{!!totalSignatures && (
				<div className={styles.flex}>
					Подписали ({totalSignedSignatures}/{totalSignatures})
					{!!signers.length && (
						<>
							:&nbsp;
							<Signers data={signers} handleClickSigner={handleClickSigner('STANDARD')} />
						</>
					)}
				</div>
			)}
			<ConfirmDialog
				onResult={async () => {
					if (deleteSimpleSignerData) {
						await dispatch(deleteSimpleSignatureSigner(deleteSimpleSignerData))
						setDeleteSimpleSignerData(null)
					}
					if (deleteStandardSignerData) {
						await dispatch(deleteStandardSignatureSigner(deleteStandardSignerData))
						setDeleteStandardSignerData(null)
					}
				}}
				opened={isOpenModalDelSigner}
				title='Отозвать с подписания?'
				confirmButtonText='Да'
				onClose={() => setOpenModalDelSigner(false)}
			/>
			{!!simpleSignatureSigners.totalSignatures && (
				<div className={styles.flex}>
					Подписан ПЭП ({simpleSignatureSigners.totalSignedSignatures}/
					{simpleSignatureSigners.totalSignatures})
					{!!simpleSignatureSigners.signers.length && (
						<>
							:&nbsp;
							<Signers
								data={simpleSignatureSigners.signers}
								handleClickSigner={handleClickSigner('SIMPLE')}
							/>
						</>
					)}
				</div>
			)}
		</div>
	)
}

SignCountInfo.propTypes = {
	simpleSignatures: PropTypes.array,
	customersForSimpleSignature: PropTypes.array,
	customers: PropTypes.array,
	signatures: PropTypes.array,
}

export default SignCountInfo
