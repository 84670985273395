import { getObjectKeys } from 'actions/document'
import api from 'api'
import axios from 'axios'
import { isEmpty } from 'ramda'
import { changeIndex, removeFile, reset, setInfo } from 'reducers/filesViewer'

import { fileUtils } from '@creditclubteam/helpers'

export const removeFileFromViewer = removeFile
export const changeImageIndex = changeIndex

export const closeFilesPreview = () => (dispatch) => {
	if (document.body.style.overflow === 'hidden') {
		document.body.style.overflow = ''
	}

	dispatch(reset())
}

export const rotateImage =
	(index = 0, id, degree, entityId) =>
	(dispatch, getState) => {
		const { filesList, onRefresh } = getState().filesViewer

		return api.document
			.rotateImage(id, degree)
			.then(async () => {
				await new Promise((resolve) => setTimeout(resolve, 4000))

				return axios.all(filesList.map((e) => e.src)).then((data) => {
					const newList = []
					const { opened, filesType } = getState().filesViewer

					filesList.forEach((file, index) => {
						newList.push({
							...file,
							src: data[index] + `&`,
							url: data[index] + `&`,
						})
					})

					if (opened) {
						dispatch(
							setInfo({
								filesList: newList,
								entityId,
								filesType,
								index,
								onRefresh,
							})
						)
					}

					if (onRefresh) {
						onRefresh(newList[index])
					}
				})
			})
			.catch(console.error)
	}

export const showFilesPreview =
	(files, activeId, onRefresh, entityId = null, zoneId = null) =>
	async (dispatch) => {
		if (isEmpty(files)) return

		let activeIndex = 0
		const filesList = []

		try {
			const objectKeys = await dispatch(
				getObjectKeys(
					Array.isArray(files) && files.length
						? files.map(({ objectKey }) => objectKey)
						: [files.objectKey]
				)
			)

			const mappedData = objectKeys.reduce((acc, { uri, objectKey }) => {
				acc[objectKey] = uri

				return acc
			}, {})

			if (Array.isArray(files) && files.length > 0) {
				files.forEach((file, index) => {
					if (file.id === activeId) {
						activeIndex = index
					}

					filesList.push({ ...file, src: mappedData[file.objectKey] })
				})
			} else {
				filesList.push({ ...files, src: mappedData[files.objectKey] })
			}

			const filesType = fileUtils.getFileExtension(filesList[0].title)

			document.body.style.overflow = 'hidden'
			dispatch(
				setInfo({
					filesList,
					filesType,
					index: activeIndex,
					onRefresh,
					entityId,
					zoneId,
				})
			)
		} catch (error) {
			//
		}
	}
