import { ENTITY_TYPES } from 'const'
import { ROSREESTR_REGISTRATION } from 'const/rosreestrRegistration'

import { DefaultValues } from 'helpers/convertHelper'

import { declarant } from './declarant'
import { operation } from './operation'

type OPERATION_TYPES = typeof ROSREESTR_REGISTRATION.OPERATION_TYPES_RUS

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		loanId: model.string(['loanId']),
		facilityId: model.string(['facilityId']),
		applicationId: model.string(['applicationId']),
		type: model.const(data.type, ROSREESTR_REGISTRATION.TYPE),
		typeRus: model.const(data.type, ROSREESTR_REGISTRATION.TYPE_RUS),
		operationTypes: model.array<Array<keyof OPERATION_TYPES>>(['operationTypes']),
		createdAt: model.string(['createdAt']),
		contractNumber: model.string(['contractNumber']),
		state: model.const(data?.state, ROSREESTR_REGISTRATION.STATE),
		operations: model.array(['operations']).map(operation),
		stateRus: model.const(data.state, ROSREESTR_REGISTRATION.STATE_RUS),
		declarants: model.array(['declarants']).map(declarant),
		number: model.null<number>(['number']),
		$$type: ENTITY_TYPES.ROSREESTR_REGISTRATION,
	}
}
