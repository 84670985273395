import { keycloak } from 'client/keycloak/instance'
import { store } from 'client/store'
import { workerSelector } from 'reducers/worker'
import type { RootState } from 'types/redux'

import { DefaultValues } from './convertHelper'

interface KeycloakUser {
	entityId: string
	name: string
	surname: string
	username: string
	fullName: string
	email: string
}

const getKeycloakData = (): KeycloakUser => {
	const model = new DefaultValues(keycloak.tokenParsed)

	return {
		entityId: model.string(['sub']),
		name: model.string(['given_name']),
		surname: model.string(['family_name']),
		username: model.string(['preferred_username']),
		fullName: model.string(['name']),
		email: model.string(['email']),
	}
}

export const authorizedWorker = {
	getId: () => getKeycloakData().entityId,
	get: (state?: RootState) =>
		workerSelector.selectById(state ?? store.getState(), getKeycloakData().entityId),
}
