import type { PayloadAction } from '@reduxjs/toolkit'
import { createSlice } from '@reduxjs/toolkit'
import type { InvestmentClientDto } from 'converters/investmentClient'
import { commonActions } from 'reducers/common'
import type { TRedux } from 'types'
import type { Maybe } from 'types/util'

const initialState = {
	data: null as Maybe<InvestmentClientDto.Main>,
	status: 'pending' as TRedux.PayloadStatus,
}

const investmentClient = createSlice({
	name: 'person/investmentClient',
	initialState,
	reducers: {
		setData: (state, { payload }: PayloadAction<Maybe<InvestmentClientDto.Main>>) => {
			state.data = payload
		},

		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions: investmentClientReducerActions } = investmentClient
export default investmentClient.reducer
