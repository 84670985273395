import { DefaultValues } from 'helpers/convertHelper'

export const nationality = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		code: model.string(['code']),
		title: model.string(['title']),
		russian: model.null(['russian']) === false ? false : true,
	}
}
