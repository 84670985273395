import { DefaultValues } from 'helpers/convertHelper'
import { operation } from './operation'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		balance: model.null<number>(['balance']),
		operations: model.array(['operations']).map(operation),
	}
}
