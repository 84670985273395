import { DefaultValues } from 'helpers/convertHelper'

export const oneCPledge = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		ownerEntityId: model.string(['ownerEntityId']),
		ownerOneCId: model.string(['ownerOneCId']),
		pledgeOneCId: model.string(['pledgeOneCId']),
	}
}
