import { addServerError } from 'actions/serverErrors'
import { uploadFiles } from 'actions/uploadFiles'
import api from 'api'
import { isEmpty } from 'ramda'
import { actions } from 'reducers/filesInspector/bucket'

import { utils } from 'helpers'

export const setBucketData = actions.setData
export const deleteFileInBucket = actions.deleteFile
export const resetBucket = actions.reset
export const setFileBucketInfoAfterPost = actions.setFileAfterPost
export const carryFileFromQueueToBucket = actions.carryFromQueue
export const resetBucketPagination = actions.paginationReset
export const setBucketPagination = actions.paginationSetParamenters
export const paginationBucketChangePage = actions.paginationChangePage

export const fetchBucket = (entityId) => (dispatch, getState) => {
	dispatch(actions.setFetchingStatus(true))

	const page = getState().filesInspector.bucket.pagination.currentPage

	const options = {
		size: 100,
		page,
		filter: {
			categories: ['bucket'],
			owners: [entityId],
		},
	}

	api.document
		.search(options)
		.then(({ data }) => {
			if (!isEmpty(data.content)) {
				const bucketFiles = data.content.map((file) => ({ ...file, processing: false }))
				// dispatch(
				// 	setBucketPagination({
				// 		paged: data.pageable.paged,
				// 		totalPages: data.totalPages,
				// 		currentPage: page,
				// 		totalElements: data.totalElements,
				// 	})
				// )
				dispatch(setBucketData(bucketFiles))
			}
		})
		.catch((error) => {
			dispatch(
				addServerError({
					text: 'Ошибка загрузки корзины',
					details: utils.getDetailsFromError(error),
				})
			)
		})
		.finally(() => dispatch(actions.setFetchingStatus(false)))
}

export const onRefreshBucketElement = (file) => (dispatch, getState) => {
	const { files } = getState().filesInspector.bucket
	const fileIndex = files.findIndex((f) => f.id === file.id)

	if (fileIndex >= 0) {
		dispatch(actions.setFileBucketUrl({ fileId: file.id, newUrl: file.url }))
	}
}

export const onDropzone = (files, unsupportedFiles) => (dispatch, getState) => {
	const { entityId } = getState().filesInspector.main

	dispatch(
		uploadFiles({
			files,
			unsupportedFiles,
			ownerId: entityId,
			ownerType: 'LEAD',
			categoryId: 'bucket',
			onSuccess: (data, uploadOwnerId) => {
				if (getState().filesInspector.main.entityId === uploadOwnerId) {
					const newFiles = data.map((file) => ({ ...file, processing: false }))

					// При обновлении корзины после загрузки файла
					// во время переноса возникал баг когда
					// файл dragging возвращался обратно с ошибкой поэтому
					// если такие файлы есть добавляем обновлённые файлы в очередь
					// корзины и после onDragEnd их перебрасываем в корзину
					const { dragging } = getState().filesInspector.main
					dispatch(actions.update({ files: newFiles, purpose: dragging ? 'queue' : 'files' }))
				}
			},
		})
	)
}
