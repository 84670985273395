import * as Yup from 'yup'

import type { FormType } from './types'

export const validationSchema = Yup.object({
	loanAmount: Yup.number().min(1),
	paymentType: Yup.string().oneOf<FormType['paymentType']>(['ANNUITY', 'PERCENT', 'MIXED']),
	creditProgramId: Yup.string().required(),
	loanDurationMonths: Yup.number().min(1),
	applicantType: Yup.string()
		.oneOf<FormType['applicantType']>(['ORGANIZATION', 'PERSON'])
		.required(),
})
