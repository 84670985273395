import { DefaultValues } from 'helpers/convertHelper'

export const insurance = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		organizationId: model.string(['organizationId']),
		products: model.array(['products']),
	}
}
