import { addServerError } from 'actions/serverErrors'
import api from 'api'
import { actions } from 'reducers/application/workers'
import type { TAction } from 'types/redux'

import { utils } from 'helpers'

export const resetApplicationWorkers = actions.reset

export const getApplicationWorkers = (): TAction => async (dispatch) => {
	dispatch(actions.setFetchingStatus(true))

	try {
		const { data } = await api.application.workers()

		dispatch(actions.setAll(data.content))
	} catch (error) {
		dispatch(
			addServerError({
				text: 'Не удалось загрузить менеджеров',
				details: utils.getDetailsFromError(error),
			})
		)
	} finally {
		dispatch(actions.setFetchingStatus(false))
	}
}
