import { ENTITY_TYPES } from 'const'

import { DefaultValues } from 'helpers/convertHelper'

export const single = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		createdAt: model.string(['createdAt']),
		product: model.string(['product']),
		params: Object.assign({}, data?.params, {
			amount: model.null<number>(['params', 'amount']),
			duration: model.null<number>(['params', 'duration']),
		}),
		marketingData: model.null<Record<string, any>>(['marketingData']),
		contact: Object.assign({}, data?.contact, {
			name: model.string(['contact', 'name']),
			surname: model.string(['contact', 'surname']),
			patronymic: model.string(['contact', 'patronymic']),
			phone: model.string(['contact', 'phone']),
			email: model.string(['contact', 'email']),
		}),
		number: model.null<number>(['number']),
		status: {
			id: model.string(['status', 'id']),
			title: model.string(['status', 'title']),
		},
		workers: model.array<Array<{ id: string; type: 'BROKER' | 'MANAGER' }>>(['workers']),
		$$type: ENTITY_TYPES.INVESTMENT_LEAD,
	}
}
