import { useCallback, useState } from 'react'

type Props = {
	initial?: boolean
	onClose?: () => void
	onOpen?: () => void
	onToggle?: () => void
}

const useModalParams = ({
	onClose: closeCb,
	onOpen: openCb,
	onToggle: toggleCb,
	initial = false,
}: Props = {}) => {
	const [isOpen, setIsOpen] = useState(initial)

	const onOpen = useCallback(() => {
		setIsOpen(true)
		openCb && openCb()
	}, [openCb])

	const onClose = useCallback(() => {
		setIsOpen(false)
		closeCb && closeCb()
	}, [closeCb])

	const onToggle = useCallback(() => {
		setIsOpen((prev) => !prev)
		toggleCb && toggleCb()
	}, [toggleCb])

	return { isOpen, onOpen, onClose, onToggle }
}

export { useModalParams }
