import { account } from './account'
import { main } from './main'

export const worker = {
	main,
	account,
}

export namespace Worker {
	export type Main = ReturnType<typeof main>
	export type Account = ReturnType<typeof account>
}
