import { DefaultValues } from 'helpers/convertHelper'

export const CLIENT_SHARE_OPERATION_TYPE = {
	DEPOSIT: 'DEPOSIT',
	WITHDRAWAL: 'WITHDRAWAL',
} as const

export const CLIENT_SHARE_OPERATION_TYPE_RUS = {
	DEPOSIT: 'Внесение паёв',
	WITHDRAWAL: 'Вывод паёв',
} as const

export const operation = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		date: model.string(['date']),
		amount: model.null<number>(['amount']),
		type: model.const(data.type, CLIENT_SHARE_OPERATION_TYPE),
		typeRus: model.const(data.type, CLIENT_SHARE_OPERATION_TYPE_RUS),
	}
}
