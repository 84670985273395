import { DefaultValues } from 'helpers/convertHelper'

export const brief = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		nameForClient: model.string(['nameForClient']),
		verificationStatus: model.string<
			'NEW' | 'IN_PROGRESS' | 'ON_VERIFICATION' | 'VERIFIED' | 'REJECTED' | 'BLOCKED'
		>(['verificationStatus']),
		legalType: model.string<'PERSON' | 'ORGANIZATION' | 'ENTREPRENEUR'>(['legalType']),
		mainContact: model.string(['mainContact']),
		mainContactPhone: model.string(['mainContactPhone']),
		managerId: model.string(['managerId']),
		city: model.string(['city']),
		createdAt: model.string(['createdAt']),
	}
}
