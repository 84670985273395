import L from 'leaflet'

import Black from './black.png'
import Blue from './blue.png'
import Green from './green.png'
import Grey from './grey.png'
import Orange from './orange.png'
import Red from './red.png'
import Shadow from './shadow.png'
import Violet from './violet.png'
import Yellow from './yellow.png'

function createIcon(marker) {
	return new L.Icon({
		iconUrl: marker,
		shadowUrl: Shadow,
		iconSize: [25, 41],
		iconAnchor: [12, 41],
		popupAnchor: [1, -34],
		shadowSize: [41, 41],
	})
}

const icons = {
	blue: createIcon(Blue),
	green: createIcon(Green),
	grey: createIcon(Grey),
	black: createIcon(Black),
	red: createIcon(Red),
	violet: createIcon(Violet),
	yellow: createIcon(Yellow),
	orange: createIcon(Orange),
}

export default icons
