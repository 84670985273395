import { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import { addDownloadFileStatus, hideNotice } from 'actions/notices'
import moment from 'moment'
import PropTypes from 'prop-types'
import { workerSelector } from 'reducers/worker'

import { downloadFile } from '@creditclubteam/helpers'
import { AudioPlayer } from 'components/common'

import Context from '../../Context'

import './Beeline.scss'

class Beeline extends Component {
	static propTypes = {
		workers: PropTypes.array.isRequired,
		$$date: PropTypes.object.isRequired,
		metadata: PropTypes.shape({
			url: PropTypes.string.isRequired,
			workerId: PropTypes.string.isRequired,
			direction: PropTypes.string.isRequired,
		}),
	}

	handleDownload = () => {
		const { metadata, $$date, addDownloadFileStatus, hideNotice } = this.props
		const curDateFormatted = moment($$date.fullDate).format('DD.MM.YYYY hh:mm')
		const title = `${curDateFormatted}_${metadata.phone}.mp3`

		downloadFile(
			{
				url: metadata.url,
				onError: () => hideNotice(metadata.id),
				onDownloadProgress: (e) => addDownloadFileStatus(e, metadata.id, title),
			},
			title
		)
	}

	renderManager() {
		const {
			workers,
			metadata: { workerId, author },
		} = this.props

		const currentWorker = workers.find(({ id }) => id === workerId)

		if (currentWorker) {
			return `${currentWorker.surname || ''} ${currentWorker.name || ''}`
		} else if (author) {
			return author
		}	else return 'Менеджер не определён'
}

	render() {
		const {
			$$date,
			metadata: { url, direction, phone },
		} = this.props

		const isIncoming = direction === 'OUTBOUND'

		const contextOptions = {
			date: $$date.fullDate,
			avatar: (
				<div className='history-beeline__avatar'>
					<i className='zmdi zmdi-phone-in-talk' />
				</div>
			),
			title: (
				<Fragment>
					{isIncoming ? this.renderManager() : phone}
					<span style={{ margin: '0 7px' }}>
						<i className='zmdi zmdi-long-arrow-right' />
					</span>
					{isIncoming ? phone : this.renderManager()}
				</Fragment>
			),
		}

		return (
			<Context {...contextOptions}>
				{url ? (
					<AudioPlayer src={url} onDownload={this.handleDownload} />
				) : (
					'Запись разговора отсутствует'
				)}
			</Context>
		)
	}
}

const mapStateToProps = (state) => ({
	workers: workerSelector.selectAll(state),
})

const mapDispatchToProps = {
	hideNotice,
	addDownloadFileStatus,
}

export default connect(mapStateToProps, mapDispatchToProps)(Beeline)
