import axios from 'axios'
import type { LoanSellApplication } from 'converters/loanSellApplication'
import { loanSellApplication } from 'converters/loanSellApplication'
import type { Pipeline } from 'converters/pipeline'
import { pipeline } from 'converters/pipeline'

import type { Pageable } from './types/Pageable'
import { URLBuilder } from './helpers'

const baseUrl = '/v1/loan-sell-applications'

export default {
	get: (id: string) =>
		axios
			.get(`/v1/loan-sell-applications/${id}`)
			.then((response) =>
				Object.assign({}, response.data, { data: loanSellApplication.main(response.data) })
			),

	getPipeline: (type: 'ALL' | 'PIPELINE') =>
		axios.get('/v1/loan-sell-application-statuses', { params: { type } }),

	getTransitions: (id: string) =>
		axios.get<Pipeline.Transition[]>(`${baseUrl}/${id}/transitions`).then((response) =>
			Object.assign({}, response, {
				data: response.data.map(pipeline.transition),
			})
		),

	updateStatus: (id: string, data: Record<string, any>) =>
		axios
			.post(`${baseUrl}/${id}/change-status`, data)
			.then((response) =>
				Object.assign({}, response, { data: loanSellApplication.main(response.data) })
			),
	search: (options: Record<string, any>) => {
		const params = new URLBuilder()

		return axios
			.get('/v1/loan-sell-applications', {
				params: {
					filter: params.build('RSQL'),
					size: options.size,
					page: options.page,
					sort: options.sort,
				},
			})
			.then(
				(response) =>
					Object.assign({}, response.data, {
						content: response.data.content.map((el: LoanSellApplication.Main) => el),
					}) as Pageable<LoanSellApplication.Main>
			)
	},
}
