import { RISK_KEYS } from 'components/common/Report/Decision/Update/helpers'
import { array, object, string } from 'yup'

export const validationSchema = object({
	actualValue: array(
		object({
			value: string().required(),
			comment: string().test({
				test: (value, { parent }) => {
					if (parent.value === RISK_KEYS.FOUND) return !!value
					return true
				},
			}),
			creditPolicyIds: array(string()).min(1),
		})
	).required(),
	stopFactorMeeting: array(
		object({
			value: string().required(),
			comment: string().required(),
			creditPolicyIds: array(string()).min(1),
		})
	).required(),
	requiredActions: array(
		object({ value: string().required(), creditPolicyIds: array(string()).min(1) })
	).required(),
	conditions: array(
		object({ value: string().required(), creditPolicyIds: array(string()).min(1) })
	).required(),
})
