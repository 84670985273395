import { createEntityAdapter, createSlice } from '@reduxjs/toolkit'
import { type PartnerDto } from 'converters/partner'
import { commonActions } from 'reducers/common'
import type { PayloadStatus, RootState } from 'types/redux'

const partnerEmployeesAdapter = createEntityAdapter<PartnerDto.EmployeeBrief>()

export const selectPartnerEmployees = partnerEmployeesAdapter.getSelectors(
	(state: RootState) => state.partner.employees
)

const initialState = partnerEmployeesAdapter.getInitialState({
	status: 'pending' as PayloadStatus,
})

const employees = createSlice({
	name: 'partner/employees',
	initialState,
	reducers: {
		setAll: partnerEmployeesAdapter.setAll,
		setStatus: commonActions.setStatus,
		reset: commonActions.reset(initialState),
	},
})

export const { actions: partnerEmployeesReducerActions } = employees
export default employees.reducer
