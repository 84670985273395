import { DefaultValues } from 'helpers/convertHelper'

import { referralEntity } from './referralEntity'

export const bonusAccount = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		referrerId: model.string(['referrerId']),
		entryUrl: model.string(['entryUrl']),
		balance: model.null<number>(['balance']),
		operations: model.array(['operations']).map((_, i) => ({
			id: model.string(['operations', i, 'id']),
			date: model.string(['operations', i, 'date']),
			title: model.string(['operations', i, 'title']),
			amount: model.null<number>(['operations', i, 'amount']),
		})),
		referralEntities: model.array(['referralEntities']).map(referralEntity),
		referredByEntities: model.array(['referredByEntities']).map(referralEntity),
	}
}
