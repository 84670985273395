import { ENTITY_TYPES } from 'const'

import { DefaultValues } from 'helpers/convertHelper'

import { application } from './application'
import { brokerSpec } from './brokerSpec'
import { calculation } from './calculation'
import { calculationV2 } from './calculationV2'
import { facility } from './facility'
import { participant } from './participant'
import { status } from './status'
import { worker } from './worker'

export const main = (data: Record<string, any>) => {
	const model = new DefaultValues(data)

	return {
		id: model.string(['id']),
		createdAt: model.string(['createdAt']),
		traceNumber: model.string(['traceNumber']),
		number: model.null<number>(['number']),
		status: status(data.status),
		calculation: calculation(data.calculation),
		calculationV2: calculationV2(data.calculationV2),
		product: model.string(['product']),
		source: model.string(['source']),
		participants: model.array(['participants']).map(participant),
		calculations: model.array(['calculations']).map(calculation),
		facilities: model.array(['facilities']).map(facility),
		activeCreditPolicies: model.array<string[]>(['activeCreditPolicies']),
		workers: model.array(['workers']).map(worker),
		marketingData: model.null<Record<string, any>>(['marketingData']),
		application: application(data.application),
		brokerSpec: brokerSpec(data.brokerSpec),
		docRequestExist: model.null<boolean>(['docRequestExist']),
		rejectionReasons: model.array<string[]>(['rejectionReasons']),
		manager: data.manager,
		$$type: ENTITY_TYPES.LEAD,
	}
}
